export const buildDocData = (fbDoc) =>{
    const newDoc = {}

    const data = fbDoc.data();
    const docID = fbDoc.id;
    const ref = fbDoc.ref.path;

    newDoc.ref = ref;
    newDoc.docID = docID;
    newDoc.link = [ref , data.title]

    for (const key in data){
        // if(key === "title") continue
        newDoc[key] = data[key]
    }
    return newDoc
}
