import m from "mithril"

import { STRING, NUMBER, CHECK_BOOL } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, UNIQUE, MAX, DISABLED } from "../../../utils/constants/inputAttrs";
import { APARTMENTS, BUILDINGS, FLOORS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { db } from "../../../index";
import { buildInsertDoc } from "../../CRUD/utils";
import { booleanType } from "../../../utils/constants/statusTypes";
import { FloorDoc } from "./floorClass";
import { REG_URL_LAST_PART } from "../../../utils/constants/regex";

export class BuildingDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: BuildingDoc,
        });
        if (!this.isNew) this.listenToChildren()
        if (this.isNew) {
            this.autoTitle()
        }
    };
    async remove() {
        await Promise.resolve(super.remove())
        if (!this.isNew) {
            const project = this.getProject()
            const batch = db.batch()
            this.getApartments().forEach(doc => {
                doc.batchRemove(batch)
            })
            await Promise.resolve(batch.commit())
            await Promise.resolve(project.updateTotalApartments())
        }
        m.redraw()
    }

    getApartments() {
        const project = this.getProject()
        if (!project) throw "project not found"
        return project.getChildren(APARTMENTS, { include: { buildingFloorPath: doc => doc.docData.buildingFloorPath.startsWith(`buildings/${this.docData.docID}`) } })
    }
    /**
      * @override insert => insert default floors
      */
    async insert(path) {
        try {
            await Promise.resolve(super.insert(path))
            this.buildFloors()
            m.redraw()
        } catch (err) {
            console.error("error on insert section ", err);
        }
    }

    autoTitle() {
        this.docData.title = `בניין ${this.docData.index}`
    }
    
    buildFloors() {
        const colRef = `${this.docData.ref}/${FLOORS}`
        const { floorsCount, includeGroundFloor } = this.docData
        for (let i = 0; i < floorsCount; i++) {
            let title, index
            if (includeGroundFloor) {
                index = Number(i)
                if (i === 0) title = "קרקע"
                else title = index
            } else {
                index = Number(i)
                title = index + 1
            }
            const apartmentsCount = 1
            const fromIndex = parseInt(index) + 1
            const toIndex = parseInt(fromIndex) + apartmentsCount
            const newFloor = new FloorDoc({ index, title, colRef, fromIndex, toIndex, apartmentsCount })
        }
    }

    async insertFloors() {
        try {
            const batch = db.batch()
            const colRef = db.doc(this.docData.ref).collection(FLOORS)
            this.getChildren(FLOORS).map(floor => {
                const docRef = colRef.doc(floor.docData.docID)
                batch.set(docRef, buildInsertDoc(floor.docData))
            })
            await Promise.resolve(batch.commit())
            await Promise.resolve(project.updateTotalApartments())
            m.redraw()
        } catch (err) {
            console.error("Error on Project.insertFloors() ", err);
        }
    }

    toJSON({ clone, addDataOptions = {} } = {}) {
        //TODO:
        const json = {}
        let newParentRef = false
        if (addDataOptions.colRef && addDataOptions.colRef !== "") {
            newParentRef = addDataOptions.colRef.replace(REG_URL_LAST_PART, "")
        }
        const cloneBuilding = this.cloneDocData({ clone, newParentRef })
        json.data = cloneBuilding
        json.children = {}

        const floors = []
        const apartments = []
        this.getChildren(FLOORS).forEach(floor => {
            const cloneFloor = floor.cloneDocData({ clone, newParentRef: cloneBuilding.ref })
            floors.push(cloneFloor)
            floor.getApartments().forEach(apartment => {
                const cloneApartment = apartment.cloneDocData({ clone, newParentRef: cloneFloor.ref })
                apartments.push(cloneApartment)
            })
        })
        json.children = { [FLOORS]: floors, [APARTMENTS]: apartments }
        return json
    }

    static get meta() {
        return {
            id: BUILDINGS,
            routes: {
                collection: `projects/:projectID/${BUILDINGS}`,
                doc: `projects/:projectID/${BUILDINGS}/:buildingID`,
            }
        }
    }
    static get headers() {
        return {
            title: { label: "מבנה", defaultValue: "1", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "1" }, { [UNIQUE]: true }] },
            description: { label: 'תיאור והערות', defaultValue: "--ללא תיאור--", type: STRING },
            floorsCount: {
                label: "כמות קומות", type: NUMBER, defaultValue: 1,
                props: [
                    { [REQUIRED]: true },
                    { [MAX]: 100 },
                    { [DISABLED]: doc => !doc.isNew },
                ]
            },
            apartmentsCount: { label: "כמות דירות", defaultValue: 0, type: NUMBER, props: [{ [DISABLED]: true }] },
            includeGroundFloor: {
                label: "כולל קומת קרקע",
                //BUG: not working!!!
                props: [
                    { [DISABLED]: doc => !doc.isNew },
                    // { [HIDDEN]: doc => !doc.isNew }
                ],
                defaultValue: true,
                type: CHECK_BOOL,
                options: booleanType,
                // visibleLogic: {
                //     allowRoles_where_or: [{
                //         notRole: [], // all other roles
                //         is: [{ "doc.isNew": false }]
                //     }],
                // }
            },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
        }
    }
}