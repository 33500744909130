import m from "mithril"

import { Logo } from "../../components/logo/Logo"

import { BreadCrumbs } from "../breadCrumbs/BreadCrumbs"
import { getResourceID } from "../../../data/utils/utils"
import { UserBox } from "../userBox/UserBox"
import { isSuperAdmin } from "../../../data/utils/permission"
import { IconButton } from "../../components/icon/IconButton"
import { DocModel } from "../../../data/store/docModel"
import { dataStore } from "../../../data/store/dataStore"
import { VIEW_USERS } from "../../../data/dictionary/routeNames"
import { Tooltip } from "../../components/tooltip/Tooltip"
import { dateDiffDays, diffDays, formatDateDisplay2 } from "../../../utils/js"
import { auth } from "../../../index"

export const TopBar = node => {

    const toggleSystemMode = () => {
        if (m.route.param("systemMode") === true) {
            m.route.set(m.route.get(), { systemMode: false })
        } else {
            m.route.set(m.route.get(), { systemMode: true })
        }
    }
    // const changeGlobalProject = doc => {
    //     store.projects.current = doc
    //     store.load([CONTRACTS])
    //     node.state.listOpen = false;
    //     // if in view or details part then --> go to overview part (ex: projects/a/contracts/b --> contracts) :
    //     const route = m.route.get()
    //     const split = route.substring(5).replace(/\?.*$/, "").split("/");
    //     if (split.length > 1) {
    //         const resourceID = getResourceID(route) //overview page or dashboard
    //         console.log(resourceID)
    //         m.route.set(`/${resourceID}`)
    //         // m.route.set(m.route.get().replace(/\/[^\/]+$/, '')) // one level up
    //     }
    // }


    // const isMatch = (doc) => {
    //     let match = true
    //     if (node.state.projectSearch.trim().length > 2) {
    //         match = match && doc.title.indexOf(node.state.projectSearch) !== -1
    //     }
    //     return match
    // }
    return {
        projectSearch: "",
        listOpen: false,
        currentTab: "a",
        viewUsers: [],
        onbeforeupdate: vnode => {
            if (dataStore[VIEW_USERS].isListen) {
                vnode.state.viewUsers = DocModel.getChildren(VIEW_USERS, {
                    include: { path: m.route.get().replace(/[\/\?=]/g, "_").replace("/replace", ""), type: "checkIn" },
                    exclude: { createdBy: auth.currentUser.uid }
                })
            }
        },
        view: vnode => {
            const path = m.route.get().replace(/\?.*$/, "");
            const resource = getResourceID(path)
            const { viewUsers } = vnode.state
            return (
                m('.topBar', [
                    isSuperAdmin() && m(IconButton, { class: "systemMode", icon: "icon-tools", action: e => toggleSystemMode() }),
                    m(Logo),
                    m(".topBar__title",
                        vnode.attrs.title && m("h3.topBar__title-text", vnode.attrs.title)
                    ),
                    viewUsers.length > 0 && m(".topBar__usersView",
                        viewUsers
                            //less then hour
                            .filter(doc => (new Date() - new Date(doc.docData.createdAt)) / (100 * 60 * 60 * 24) < 1)
                            .map(doc => {
                                return m(".userView show [data-tooltip]", {
                                    onclick: e => console.log("%cTODO: show userView details", "background-color:#e6b800;color:#f0f0f0;")
                                },
                                    doc.docData.title,
                                    m(Tooltip, {
                                        text: `נראה לאחרונה:\n${formatDateDisplay2(new Date(doc.docData.createdAt), "useHours")}`,
                                        location: "bottom"
                                    })
                                )
                            }),
                    ),
                    m(UserBox),
                    m(BreadCrumbs)
                ])
            )
        }
    }
}