import { O_ARRAY, O_FUNCTION, O_OBJECT } from "../../utils/constants/objTypes"
import { DATE, STRING } from "../../utils/constants/types"
import { objType, uuid } from "../../utils/js"
import { defaultFields, internalFields } from "../dictionary/internalFields"

export const buildHeaders = (modelFields, headers, visibleLogic) => {
    // defaultFields THINK: if is neccery if injected on class
    const obj = {}
    if (!headers) {
        return modelFields
    } else {
        const type = objType(headers)
        if (type === O_OBJECT) {
            Object.entries(headers).forEach(([header, settingsField]) => {
                obj[header] = Object.assign(modelFields[header] || {}, defaultFields[header] || {}, settingsField || {})
            })
        } else if (type === O_ARRAY) {
            headers.forEach(header => {
                obj[header] = Object.assign({}, defaultFields[header] || {}, modelFields[header] || {})
            })
        }
        return obj
    }
}

export function buildField(settingsField) {
    if (objType(settingsField) === O_OBJECT) {
        return Object.assign({ label: "", type: STRING, defaultValue: "" }, settingsField)
    } else {
        throw `invalid type ${objType(settingsField)} for field settings [must be an object]`
    }
}

export function buildFieldData(settingsField, header, docData) {
    if (docData[header]) return docData[header]
    const { type = STRING, defaultValue = "", options = {}, val } = settingsField
    //TODO: switch by type and convert to exectly cast you like to
    if (defaultValue && objType(defaultValue) === O_FUNCTION) {
        return defaultValue(docData)
    } else {
        return defaultValue
    }
}

export function buildInsertDoc(docData) {
    const def = {}
    Object.entries(defaultFields).forEach(([header, settingsField]) => {
        def[header] = buildFieldData(settingsField, header, docData)
    })
    const dataToAdd = Object.assign({},
        docData,
        def
    )
    // Object.entries(internalFields).forEach(([_k, _v]) => delete dataToAdd[_v])
    Object.keys(dataToAdd).forEach(key => {
        if (key.endsWith("__old")) delete dataToAdd[key]
    })
    return dataToAdd
}

export function buildCloneDBDoc(colRef, doc, resets = {}) {
    const docID = uuid()
    const docData = Object.assign({}, doc.data(), {
        docID,
        colRef,
        ref: `${colRef}/${docID}`,
        cloneFrom: doc.ref.path
    }, resets)
    return docData
}