import m from "mithril"
import { Icon } from "../../components/icon/Icon"

export const CheckCell = node => {

    return {
        view: vnode => {
            return m(".checkCell", {
                class: vnode.attrs.class ? vnode.attrs.class : "",
                onclick: e => vnode.attrs.setCheck ? vnode.attrs.setCheck(e) : null
            },
                vnode.attrs.label && m(".checkCell__label", vnode.attrs.label),
                m(`.checkCell__cell${vnode.attrs.checked ? "--checked" : ""}`,
                    m(Icon, { icon: "icon-check" })
                ),
            )
        }
    }
}