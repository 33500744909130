import { STRING, NUMBER, VALID_UNIQUE } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, UNIQUE } from "../../../utils/constants/inputAttrs";
import { APARTMENT_MODELS, BUILDINGS, PROJECTS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";

export class ApartmentModelDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ApartmentModelDoc,
        });
        if (!this.isNew) this.listenToChildren()
    };

    static get meta() {
        return {
            id: APARTMENT_MODELS,
            routes: {
                routes: {
                    collection: `${PROJECTS}/:projectID/${BUILDINGS}/:buildingID/${APARTMENT_MODELS}`,
                    doc: `${PROJECTS}/:projectID/${BUILDINGS}/:buildingID/${APARTMENT_MODELS}/:apartmentModelID`,
                }
            },
            logic: [
                { type: VALID_UNIQUE, expression: "title", trigger: ["title"], msg: "ערך כבר קיים , שדה זה הינו ייחודי!" },
            ],
        }
    }
    static get headers() {
        return {
            title: { label: "דגם דירה", defaultValue: "", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "1" }, { [UNIQUE]: true }] },
            description: { label: 'תיאור והערות', defaultValue: "--ללא תיאור--", type: STRING },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
        }
    }
}