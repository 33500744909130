import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';

// DATA
import { VENDORS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { setHeaders } from "../../../data/utils/utils"
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { statusTypes } from '../../../utils/constants/statusTypes';
import { isSystemMode } from '../../../data/utils/viewUtils';
import { settingsStore } from '../../../data/store/settingsStore';
import { DATE_RANGE } from '../../../utils/constants/filtersTypes';
import { Table } from '../../commons/table/Table';
import { DocModel } from '../../../data/store/docModel';

// FUNCTIONS
export const Vendors = (node) => {

    const vendorsHeaders = [
        "title",
        "companyNumber",
        "commercialName",
        "contractorSAPNumber",
        "taxesEndDate",
        "taxPercent",
        "phone",
        "email",
        "status",
    ]

    window.onresize = (ev) => {
        setHeaders(vendorsHeaders, "headers", node)
        m.redraw()
    }

    return {
        snackbar: false,
        prompt: false,
        headers: [...vendorsHeaders],
        load: true,
        filters: [{ id: "status", param: "nonActive" }],
        searchTerm: "",
        settings: {
            navOnAdd: true
        },
        oninit: vnode => {

            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            sessionStorage.setItem('lastURL', m.route.get());

            vnode.state.snackbar = { msg: "טוען קבלנים..." }
            const { alreadyListen, unsubscribe } = dataStore[VENDORS].listen();
            if (alreadyListen) {
                vnode.state.load = false
            } else {
                setTimeout(() => {
                    vnode.state.load = false
                    m.redraw()
                }, 3000);
            }
            setHeaders(vendorsHeaders, "headers", node)
            m.redraw();
        },
        view: (vnode) => {
            return (
                m(PageLayout, { class: "vendors", title: "הקבלנים שלי" },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    m(Table, {
                        title: "טבלת קבלנים",
                        modelID: VENDORS,
                        colRef: VENDORS,
                        doc: DocModel,
                        tableHeaders: vnode.state.headers,
                        actions: { onRow: "redirect" },
                        // filterDataOptions: {},
                        sortOptions: { param: "timestamp" },
                        searchOptions: ["title", "commercialName", "email", "phone", "companyNumber", "contractorSAPNumber"],
                        filters: vnode.state.filters,
                        filterMap: [
                            { label: "סטטוס", header: "status", options: statusTypes },
                            { label: "תוקף ניכוי מס במקור", header: "taxesEndDate", type: DATE_RANGE, options: [{ id: "taxesEndDate:after", text: "מ", logic: "after" }, { id: "taxesEndDate:before", text: "עד", logic: "before" }] },
                        ]
                    }),
                )
            )
        }
    }
}