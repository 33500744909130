import m from 'mithril'
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots'

export function setActiveTab(tabObj, key, { node, stateKey } = {}) {
    if (node && stateKey) {
        node.state[stateKey] = key;
    }
    function setActive(el) {
        if (el.key === key) el.active = true
        else el.active = false
    }
    tabObj.forEach(setActive, this)
}

export const Nav = (node) => {

    //for test local only
    // const nav = [
    //     {
    //         label: "ביצוע",
    //         path: false,
    //         active: true,
    //         action: function () {
    //             nav.forEach(el => el.active = false)
    //             this.active = true
    //         }
    //     },
    //     {
    //         label: "הנהלת חשבונות",
    //         path: false,
    //         active: false,
    //         action: function () {
    //             nav.forEach(el => el.active = false)
    //             this.active = true
    //         }
    //     },
    // ];

    return {
        listOpen: false,
        isLoading: true,
        oninit: vnode => {
            vnode.state.isLoading = false
            //IF NEED TO LOAD:
            // setTimeout(() => {
            //     vnode.state.isLoading = false
            //     m.redraw()
            // }, 1000);
        },
        view: vnode => {
            const { nav } = vnode.attrs;
            return vnode.state.isLoading ?
                m(InlineLoaderDots) :
                m('.nav', {
                    class: vnode.attrs.class ? vnode.attrs.class : "",
                    style: `grid-template-columns: repeat(${nav.length},1fr);`,
                },
                    nav.map((el, ind) => [
                        m('.nav__link', {
                            class: el.active ? "nav__link--active" : "",
                            onclick: e => {
                                if (el.path) {
                                    m.route.set(el.path)
                                } else if (el.action) {
                                    el.action({ el, vnode, e })
                                }
                                // m.redraw()
                            },
                            "data-path": el.path,
                        },
                            // m("span", `[${ind + 1}]`), //THINK: maby use number to index elements
                            el.label,
                            el.icon ? m(Icon, { icon: el.icon }) : null,
                            el.notificationCount && m(".notification__count", el.notificationCount)
                        ),
                    ])
                )
        }
    }
}