import m from "mithril"
import { buildHeaders } from "../../../data/CRUD/utils";
import { dataStore } from "../../../data/store/dataStore";
import { CREATE } from "../../../data/store/permissionStore";
import { getDisplayValue } from "../../../data/utils/inputValidation";
import { isUserAllow } from "../../../data/utils/permission";
import { getAddOptions, handleAdd } from "../../../data/utils/utils";
import { O_FUNCTION } from "../../../utils/constants/objTypes";
import { objType, sortDocsBy, uuid } from "../../../utils/js";
import { Icon } from "../../components/icon/Icon";
import { IconButton } from "../../components/icon/IconButton";
import { Snackbar } from "../../components/snackbar/Snackbar";
import { Card } from "../cardLayout/Card";
import { CheckCell } from "../checkCell/CheckCell";
import { InputCell } from "../inputCell/InputCell";
import { Prompt } from "../prompt/Prompt";
import { TableCell } from "./TableCell";

export const TableInline = node => {

    const tableAddAction = (e) => {
        const { modelID, colRef, doc, actions = {}, filterDataOptions = {}, addDataOptions = {} } = node.attrs
        const { onAdd = "redirect" } = actions
        const Model = dataStore[modelID].model
        if (objType(onAdd) === O_FUNCTION) {
            return actions.onAdd(node, doc)
        } else {
            return handleAdd(e, node, Model, modelID, getAddOptions(colRef, filterDataOptions, addDataOptions))
        }
    }

    async function handleSaveAll() {
        const { doc, modelID, filterDataOptions = {} } = node.attrs
        try {
            const children = doc.getChildren(modelID, filterDataOptions)
            for (const child of children) {
                await Promise.resolve(child.save())
            }
            node.state.snackbar = { msg: "השינויים נשמרו בהצלחה" }
            m.redraw()
        } catch (err) {
            node.state.snackbar = { msg: `אירעה שגיאה בשמירת הנתונים \nתיאור:\n${err}`, isError: true }
            m.redraw()
        }
    }
    function resetAll() {
        const { doc, modelID, filterDataOptions = {} } = node.attrs
        const children = doc.getChildren(modelID, filterDataOptions)
        for (const child of children) {
            child.abortChanges()
        }
    }

    function toggleSelectAllRows() {
        const { modelID, doc, filterDataOptions = {} } = node.attrs
        if (node.state.selectedRows.length === 0) {
            node.state.selectedRows = []
            doc.getChildren(modelID, filterDataOptions)
                .forEach(doc => {
                    node.state.selectedRows.push(doc.docData.docID)
                })
        } else {
            node.state.selectedRows = []
        }
    }

    function toggleSelectRow(docID) {
        const selectedIndex = getSelectedRow(docID)
        if (selectedIndex > -1) node.state.selectedRows.splice(selectedIndex, 1)
        else node.state.selectedRows.push(docID)
    }

    function getSelectedRow(docID) {
        return node.state.selectedRows.indexOf(docID)
    }
    function getSelectedRows() {
        const { selectedRows } = node.state
        if (selectedRows === []) return 0
        else {
            const { modelID, doc, filterDataOptions = {} } = node.attrs
            const rows = doc.getChildren(modelID, filterDataOptions)
            if (selectedRows.length === rows.length) {
                return "all"
            } else {
                return selectedRows.length
            }
        }
    }

    return {
        editCell: false,
        prompt: false,
        snackbar: false,
        selectedRows: [],
        oninit: vnode => {
            const { doc, tableHeaders, modelID } = vnode.attrs
            const Model = dataStore[modelID].model
            vnode.state.tableHeaders = buildHeaders(Model.headers, tableHeaders)
        },
        view: vnode => {
            const { doc: instance, filterDataOptions = {}, modelID, title = "", sortOptions = { param: "timestamp" } } = vnode.attrs
            const { tableHeaders, editCell } = vnode.state
            return m(Card, { class: "tableCard" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                (instance && isUserAllow(modelID, CREATE)) && m("button.button tableCard__add", { onclick: e => tableAddAction(e) }, m(Icon, { icon: "icon-plus" }) /* "הוספת רשומה" */),
                m(".caption tableCard__caption",
                    title,
                    instance.childHasPendingChanges(modelID, filterDataOptions) && m(".tbl__actions",
                        m("button.button", { onclick: e => handleSaveAll() }, "שמור הכל"),
                        m("button.button button--gray", { onclick: e => resetAll() }, "בטל הכל"),
                    ),
                ),
                m(".tbl", { style: `grid-template-columns:min-content ${Object.keys(tableHeaders).map(() => "1fr").join(" ")};` },
                    // headers
                    m(".tbl__check", { onclick: e => toggleSelectAllRows(), key: `check__all` },
                        getSelectedRows() === "all" && m(Icon, { icon: `icon-check`, class: "all" }),
                        getSelectedRows() === 0 && m(Icon, { icon: `icon-minus` }),
                        (getSelectedRows() !== 0 && getSelectedRows() !== "all") && m(".count", getSelectedRows()),
                    ),
                    Object.entries(tableHeaders).map(([header, field]) => m(".tbl__header", { key: `header__${header}` }, field.label)),
                    //case empty
                    instance.getChildren(modelID, filterDataOptions).length === 0 && [
                        m(".tbl__cell", "-"), // check
                        Object.entries(tableHeaders).map(header => m(".tbl__cell", "---"))
                    ],
                    //rows
                    instance.getChildren(modelID, filterDataOptions)
                        .sort(sortDocsBy(sortOptions.param))
                        .map((doc, docInd) => {
                            const checked = getSelectedRow(doc.docData.docID) > -1
                            return [
                                m(`.tbl__cell--select ${checked ? "checked" : ""}`, { onclick: e => toggleSelectRow(doc.docData.docID), key: `check__${doc.docData.ref}` }, m(CheckCell, { checked })),
                                Object.entries(tableHeaders).map(([header, field], ind) => {
                                    const id = `${doc.docData.ref}/${header}`
                                    const index = Number(docInd + 1) + Number(ind + 1)
                                    if (field.val) {
                                        return m(TableCell, { key: uuid(), doc, header, settingsField: field, class: `tbl__cell--caption ${checked ? "checked" : ""}`, parent: vnode })
                                    }
                                    else return m(InputCell, {
                                        id, index, editCell, parent: vnode, key: id,
                                        class: `tbl__cell${doc.isNew ? "--new" : ""} ${checked ? "checked" : ""}`, field, id, index, header, doc,
                                        options: { ignoreLabel: true, ignoreCalculator: true }
                                    })
                                })
                            ]
                        }),
                ),
            )
        }
    }
}