import m from "mithril"

import { SWITCH, DATE, STRING, NUMBER, PERCENT, OBJECT, CURRENCY } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, MIN, MAX, READONLY, DISABLED, STEP } from "../../../utils/constants/inputAttrs";
import { PAYMENTS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { db, auth } from "../../../index";
import { dataStore } from "../dataStore";
import { paymentChannels, PAYMENT_CHANNELS } from "../../../utils/constants/paymentChannels";
import { paymentTypes, PAYMENT_TYPES } from "../../../utils/constants/paymentTypes";

export class PaymentDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: PaymentDoc,
        });
        //listen
        if (!this.isNew) this.listenToChildren()
    };

    static get meta() {
        return {
            id: PAYMENTS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/billings/payments",
                doc: "/projects/:projectID/contracts/:contractID/billings/:billingID/payments/:paymentID",
            }
        }
    }
    static get headers() {
        return {
            title: { label: 'תשלום', defaultValue: "--תשלום--", type: STRING },
            paymentDate: { label: "תאריך", defaultValue: new Date().toISOString(), type: DATE },
            paymentChannel: { label: "אמצעי תשלום", defaultValue: PAYMENT_CHANNELS.CHECK, type: SWITCH, options: paymentChannels },
            paymentType: { label: "סוג תשלום", defaultValue: PAYMENT_TYPES.ONGOING, type: SWITCH, options: paymentTypes },
            paymentIdentity: { label: "מזהה שיק", defaultValue: "", type: STRING },
            sum: { label: "סכום", defaultValue: 0, type: CURRENCY, /* props: [{ [DISABLED]: doc => !doc.isNew }] */ },
            description: { label: "הערות", defaultValue: "", type: STRING },
        }
    }
}