import m from "mithril"
import { CURRENCY, DATE, LIST, NUMBER, PERCENT, REF, STRING } from "../../../../utils/constants/types"

import { dataStore } from "../../../../data/store/dataStore"
import { DocModel } from "../../../../data/store/docModel"
import { VendorDoc } from "../../../../data/store/vendors/vendorClass"
import { Card } from "../../../commons/cardLayout/Card"
import { LocalList } from "../../../commons/inputCell/LocalList"

import { ReportLayout } from "../ReportLayout"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { Icon } from "../../../components/icon/Icon"
import { CONTRACTS, PROJECTS, VENDORS } from "../../../../data/dictionary/routeNames"
import { ProjectDoc } from "../../../../data/store/projects/projectClass"
import { ContractDoc } from "../../../../data/store/contracts/contractClass"
import { auth, db } from "../../../../index"
import { buildDoc, getReportFilters, loadDataAPI, exportReportAPI, getReportExportData } from "../utils"
import { NavClass } from "../../../../data/store/NavClass"
import { Nav } from "../../../commons/nav/Nav"
import { apiBaseUrl } from "../../../../firebase/api"
import { AccountDoc, confirmAccountFlow } from "../../../../data/store/contractsActuals/accountClass"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { buildHeaders } from "../../../../data/CRUD/utils"
import { objType, sortDocsEntries, getObjValue, addMonths, formatDate, sortEntries, buildMonthText } from "../../../../utils/js"
import { O_FUNCTION } from "../../../../utils/constants/objTypes"
import { getModelID } from "../../../../data/utils/utils"
import { Tooltip } from "../../../components/tooltip/Tooltip"
import { confirmHeaders, getAccountConfirmStatus, getAccountDiff, getContractAccountMonth, getSubTotalAccounts, getSubTotalContracts, getTotalPayments } from "../../../../data/reports/getContractData"
import { UserDoc } from "../../../../data/store/users/userClass"
import { DEFAULT_ACTION_NOT_AVILABLE } from "../../../../data/store/settingsStore"

export const VendorsReports = node => {

    //stages
    const APPLY_FILTERS = "applyFilters"
    const PREVIEW = "preview"
    const SHOW = "showReport"

    const previewReport = (reportID) => {
        const { vendor } = node.state
        return m.route.set(`/app/reports/vendors/${vendor.docData.docID}?report=${reportID}`)
    }

    const getReportParams = reportID => {
        const body = { grouping: "project", where: {} }
        // const url = `${apiBaseUrl_DEV}/v1/${reportID}/report` //dev only
        const url = `${apiBaseUrl}/v1/${reportID}/report` //prod only
        if (!node.state.project) {
            body.where = {
                contract: { contractorRef: node.state.vendor.docData.ref }
            }
        } else if (node.state.project && !node.state.contract) {
            body.where = {
                colRef: node.state.project.docData.ref,
                contract: { contractorRef: node.state.vendor.docData.ref }
            }
        } else if (node.state.project && node.state.contract) {
            body.where = {
                contract: {
                    contractorRef: node.state.vendor.docData.ref,
                    ref: node.state.contract.docData.ref
                }
            }
        }
        return { url, body }
    }

    const getReportData = (reportID) => {
        nextStage(SHOW)
        node.state.loadReportData = true
        const { url, body } = getReportParams(reportID)
        loadDataAPI(node, url, body)
    }



    const selectReportData = {
        docData: {
            vendor: "",
            project: "",
            contract: "",
            accounts: [],
        },
        headers: {
            vendor: {
                label: "בחר קבלן",
                type: LIST,
                options: {
                    data: dataStore[VENDORS].data,
                    load: () => dataStore[VENDORS].listen(),
                    model: VendorDoc,
                    formHeaders: ["title"],
                    change: ref => {
                        resetSelection()
                        if (ref) node.state.vendor = DocModel.getChild(VENDORS, ref)
                    },
                    remove: (ref) => {
                        resetSelection()
                        node.state.vendor = false
                    },
                },
            },
            project: {
                label: "בחר פרוייקט",
                type: LIST,
                options: {
                    data: dataStore[PROJECTS].data,
                    load: () => dataStore[PROJECTS].listen(),
                    model: ProjectDoc,
                    formHeaders: ["title"],
                    change: async (ref) => {
                        if (!ref) return
                        const project = DocModel.getChild(PROJECTS, ref)
                        // const contractsAll = project.getChildren(CONTRACTS)
                        // const contracts = project.getChildren(CONTRACTS, { include: { contractorRef: node.state.vendor.docData.ref } })
                        // console.log(contractsAll, contracts, node.state.vendor.docData.ref);
                        // console.log(contracts, node.state.vendor.docData.ref);
                        try {
                            const contracts = await db.doc(ref).collection(CONTRACTS)
                                .where("contractorRef", "==", node.state.vendor.docData.ref)
                                .where("isActive", "==", true)
                                .get()
                            if (contracts.size > 0) {
                                console.log(`found ${contracts.size} connected contracts on project ${ref}`);
                                node.state.project = project
                                node.state.contracts = contracts.docs.map(buildDoc)
                                node.state.errors = []
                            } else {
                                console.error(`no connected contracts on project ${ref}`);
                                node.state.errors.push(`לא נמצאו חוזים תואמים לקבלן בפרוייקט ${project.docData.title}`)
                                node.state.contracts = []
                                node.state.project = false
                            }
                        } catch (err) {
                            console.error(err);
                        } finally {
                            m.redraw()
                        }
                    },
                    remove: (ref) => {
                        node.state.project = false
                        node.state.contracts = []
                        node.state.errors = []
                    },
                },
            },
            contract: {
                label: "בחר חוזה",
                type: LIST,
                options: {
                    getData: () => node.state.contracts,
                    load: () => node.state.project ? node.state.project.listenToChildren([CONTRACTS]) : null,
                    model: ContractDoc,
                    formHeaders: ["title"],
                    change: ref => {
                        if (ref) node.state.contract = DocModel.getChild(CONTRACTS, ref)
                    },
                    remove: (ref) => {
                        node.state.contract = false
                    },
                },
            },
        }
    }
    const nextStage = next => {
        // if (next === PREVIEW) {
        //     filters.shrink
        //     preview.show
        //     report.hide
        // } else if (next === SHOW) {
        //     filters.shrink
        //     preview.hide
        //     report.show
        // } else if (next === APPLY_FILTERS) {
        //     filters.show
        //     preview.hide
        //     report.hide
        // }
        node.state.reportBuildStage = next
    }

    const removeMsg = ind => {
        node.state.errors.splice(ind, 1)
    }

    const getConfigLayout = reportID => {
        //base
        const configLayout = {
            header: { type: "header" },
            subHeader: { type: "subHeader" },
            data: { type: "data" },
            subTotal: { type: "subTotal" },
            total: { type: "total" },
        }


        //depend on reportID
        if (reportID === "confirms") {
            if (!node.state.project) configLayout.header.value = `פרוייקט X`
            else configLayout.header.value = `פרוייקט ${node.state.project.docData.title}`
            configLayout.data.value = "חוזה - סטטוס אישורים"
            configLayout.subHeader = null
            configLayout.subTotal = null
        } else if (reportID === "accounts") {
            //if !project [header=project,subHeader=contract]
            configLayout.data.value = "חשבון"
            if (!node.state.project) {
                configLayout.header.value = "פרוייקט X"
                configLayout.subHeader.value = "חוזה Y"
            } else if (node.state.project && !node.state.contract) {
                //if project [header=contract,subHeader=account]
                configLayout.header.value = `פרוייקט ${node.state.project.docData.title}`
                configLayout.subHeader.value = "חוזה X"
            } else if (node.state.project && node.state.contract) {
                //if contract [header=contract] {no subHeader}
                configLayout.header.value = `חוזה ${node.state.contract.docData.title}`
                configLayout.subHeader = null
                configLayout.subTotal = null
                // config.subHeader.value = "חשבון X"
            }
        } else if (reportID === reportIDs.apartments) {
            //if !project [header=project,subHeader=contract]
            configLayout.data.value = "דירה"
            configLayout.subHeader.value = "בניין X"
            if (!node.state.project) {
                configLayout.header.value = "פרוייקט X"
            } else if (node.state.project && !node.state.contract) {
                configLayout.header.value = `פרוייקט ${node.state.project.docData.title}`
            } else if (node.state.project && node.state.contract) {
                configLayout.header.value = `חוזה ${node.state.contract.docData.title}`
            }
        }
        return configLayout
    }

    const getReportLayout = reportID => {
        const configLayout = getConfigLayout(reportID)
        const layout = [
            configLayout.header,
            configLayout.subHeader,
            configLayout.data,
            configLayout.data,
            configLayout.data,
            configLayout.subTotal,
            configLayout.subHeader,
            configLayout.data,
            configLayout.data,
            configLayout.data,
            configLayout.subTotal,
            configLayout.total,
        ]
        return layout
    }
    const reportIDs = {
        confirms: "confirms",
        accounts: "accounts",
        contracts: "contracts",
        apartments: "apartments"
    }
    const navReports = new NavClass({
        settings: { stateKey: "selectedReport" },
        nav: [
            { label: "דוח ריכוז חוזים", key: reportIDs.contracts },
            { label: "דוח פירוט חשבונות", key: reportIDs.accounts },
            { label: "דוח מצב אישורים", key: reportIDs.confirms },
            { label: "דוח דירות", key: reportIDs.apartments },
        ]
    })


    const reportsHeaders = {
        accounts: {
            headers: {
                // indexedPercent: { label: "% הצמדה", visible: true, group: "indexed" },
                // totalIndexed: { label: "סכום הצמדה", visible: true, group: "indexed" },
                // totalAfterIndexed: { label: "סכום לאחר הצמדה", visible: true, group: "indexed" },
                // vatPercent: { label: "% מעמ", visible: true, group: "vat" },
                // taxPercent: { label: "% ניכוי מס", visible: true, group: "tax" },
                // period: {},
                month: { classStyle: 'ltr' },
                totalSections: { label: "קרן מאושר", type: CURRENCY },
                totalAddSub: { label: "תוספות וקיזוזים", type: CURRENCY },
                subTotal: { label: "סהכ לפני עיכבון", type: CURRENCY },
                totalDelay: { label: "עכבון" },
                totalAccountToPay: { label: "לתשלום" },
                totalVAT: { label: "סכום מעמ" },
                totalBeforeTax: { label: "לתשלום לפני ניכוי", type: CURRENCY },
                totalTax: { label: "ניכוי מס" },
                totalPay: { label: "לתשלום סופי" },
                // totalPayments: { label: "שולם", type: CURRENCY },
                payments: {
                    label: "תשלומים",
                    type: "collection",
                    fields: {
                        paymentDate: { type: DATE },
                        sum: { type: CURRENCY },
                        paymentIdentity: { type: STRING }
                    }
                }
            },
            subTotals: {
                title: { value: "סהכ" },
                // month: { value: "---" },
                totalSections: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalSections"), type: CURRENCY, func: "SUM" },
                totalAddSub: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalAddSub"), type: CURRENCY, func: "SUM" },
                total_subTotal: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "subTotal"), type: CURRENCY, func: "SUM" },
                total_totalDelay: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalDelay"), type: CURRENCY, func: "SUM" },
                total_totalAccountToPay: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalAccountToPay"), type: CURRENCY, func: "SUM" },
                total_totalVAT: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalVAT"), type: CURRENCY, func: "SUM" },
                total_totalBeforeTax: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalBeforeTax"), type: CURRENCY, func: "SUM" },
                total_totalTax: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalTax"), type: CURRENCY, func: "SUM" },
                total_totalPay: { val: (contractRef) => getSubTotalAccounts(node.state.reportData, contractRef, "totalPay"), type: CURRENCY, func: "SUM" },
                total_payments: { val: (contractRef) => getTotalPayments(node.state.reportData, contractRef), type: NUMBER },
                // total_payments: { val: (contractRef) => getTotalPayments(contractRef, "aggByMonth"), type: STRING },
                // total_payments: { val: (contractRef) => "---", type: STRING },
            },
            totals: {
                title: { value: "סהכ" },
                // month: { value: "---" },
                totalSections: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalSections"), type: CURRENCY, func: "SUM" },
                totalAddSub: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalAddSub"), type: CURRENCY, func: "SUM" },
                total_subTotal: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "subTotal"), type: CURRENCY, func: "SUM" },
                total_totalDelay: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalDelay"), type: CURRENCY, func: "SUM" },
                total_totalAccountToPay: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalAccountToPay"), type: CURRENCY, func: "SUM" },
                total_totalVAT: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalVAT"), type: CURRENCY, func: "SUM" },
                total_totalBeforeTax: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalBeforeTax"), type: CURRENCY, func: "SUM" },
                total_totalTax: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalTax"), type: CURRENCY, func: "SUM" },
                total_totalPay: { val: (projectRef) => getSubTotalContracts(node.state.reportData, projectRef, "totalPay"), type: CURRENCY, func: "SUM" },
                total_payments: { val: (projectRef) => getTotalPayments(node.state.reportData, projectRef), type: NUMBER },
                // total_payments: { val: (projectRef) => getTotalPayments(projectRef, "aggByMonth"), type: STRING }
                // total_payments: { val: (projectRef) => "---", type: STRING }
            },
        },
        confirms: confirmHeaders,
        apartments: {
            headers: {

            }
        }
    }
    const resetSelection = () => {
        selectReportData.docData = {
            vendor: "",
            project: "",
            contract: "",
            accounts: [],
        }
        node.state.vendor = false
        node.state.project = false
        node.state.contract = false
        node.state.contracts = []
        node.state.accounts = []
        node.state.reportData = null
        node.state.reportBuildStage = APPLY_FILTERS
        node.state.errors = []
        node.state.filters = []
        node.state.exportFile = null
        m.redraw()
    }

    const printAction = () => {
        const reportHeaders = getReportHeaders(node.state.selectedReport, false)
        if (!reportHeaders) node.state.prompt = { msg: "לא נמצאו נתונים", isError: true }
        exportReportAPI(node, getReportExportData(node, reportHeaders))
    }

    const getReportHeaders = (reportID, part = "headers") => {
        if (!part) return reportsHeaders[reportID]
        return reportsHeaders[reportID][part]
    }

    const layoutContains = partType => {
        const reportLayout = getReportLayout(navReports.getActiveKey())
        return reportLayout.some(el => el && el.type === partType)
    }

    return {
        vendor: false,
        project: false,
        contract: false,
        contracts: [],
        accounts: [],
        prompt: false,
        snackbar: false,
        errors: [],
        filters: [],
        selectedReport: "",
        reportBuildStage: APPLY_FILTERS,
        reportData: null,
        loadReportData: false,
        oninit: vnode => {
            navReports.setNode(vnode)
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get())
        },
        view: vnode => {
            const { vendor, project, contracts, contract, errors, reportData, loadReportData, reportBuildStage } = vnode.state
            return m(ReportLayout, {
                title: `דוח לקבלן ${vendor ? ` - "${vendor.docData.title}"` : ""}`,
                print: reportData ? true : false,
                printAction
            },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Card, { class: "formCard print" },
                    reportBuildStage === APPLY_FILTERS ? [
                        m(LocalList, { doc: selectReportData, header: "vendor", parent: vnode, class: "tableCard__select" }),
                        vendor && m(LocalList, { doc: selectReportData, header: "project", parent: vnode, class: "tableCard__select" }),
                        (vendor && project && contracts.length > 0) && m(LocalList, { doc: selectReportData, header: "contract", parent: vnode, class: "tableCard__select" }),
                        m(".formCard__actions no-print",
                            (errors.length === 0 && vendor) && m("button.button--icon", { onclick: e => nextStage(PREVIEW) }, "תצוגה מקדימה", m(Icon, { icon: "icon-eye" })),
                            vendor && m("button.button--icon button--gray", { onclick: e => resetSelection() }, "אפס", m(Icon, { icon: "icon-x" })),
                        ),
                        m(".errors",
                            errors.map((errorMsg, ind) => {
                                return m(".danger", { key: ind, onclick: e => removeMsg(ind) }, errorMsg)
                            })
                        )
                    ] : m(".",
                        m(".caption", "חתך נתונים לפי :"),
                        m(".breadCrumbs",
                            getReportFilters(vnode).map((filterItem, ind) => {
                                if (filterItem.path) return m("a.breadCrumbs__link [data-path]", { href: `/#!/app/${filterItem.path}`, target: "_blank" }, filterItem.title)
                                else return m(".breadCrumbs__link", filterItem.title)
                            }),
                            // vendor && m("a.breadCrumbs__link [data-path]", { href: `/#!/app/${vendor.docData.ref}`, target: "_blank" }, "קבלן: ", vendor.docData.title),
                            // !project && m(".breadCrumbs__link", "פרוייקטים: הכל"),
                            // project && m("a.breadCrumbs__link [data-path]", { href: `/#!/app/${project.docData.ref}`, target: "_blank" }, "פרוייקטים: ", project.docData.title),
                            // !contract && m(".breadCrumbs__link", "חוזים: הכל"),
                            // contract && m("a.breadCrumbs__link [data-path]", { href: `/#!/app/${contract.docData.ref}`, target: "_blank" }, "חוזים: ", contract.docData.title),
                        ),
                        m(".formCard__actions no-print",
                            vendor && m("button.button--icon button--gray", { onclick: e => resetSelection() }, "אפס", m(Icon, { icon: "icon-x" })),
                        ),
                    )
                ),
                (vendor && errors.length === 0 && reportBuildStage === PREVIEW) && m(Card, { class: "formCard" },
                    m(Nav, { nav: navReports.nav }),
                    navReports.isActiveState(reportIDs.contracts) && m(".preview",
                        m(".formCard__actions no-print",
                            m("button.button", { onclick: e => previewReport("contractsStatus") }, "הצג דוח")
                        )
                    ),
                    navReports.isActiveState(reportIDs.confirms) && m(".preview",
                        m(".preveiw__header", "תצוגה מקדימה של דוח  - סטטוס אישורים"),
                        m(".reportTable",
                            getReportLayout(reportIDs.confirms).map(row => {
                                if (!row) return
                                switch (row.type) {
                                    case "header": return m(".cell--header", row.value || "שורת כותרת")
                                    case "subHeader": return m(".cell--title", row.value || "כותרת משנה")
                                    case "subGroupHeader": return m(".cell--subtitle", row.value || "כותרת משנה")
                                    case "data": return m(".cell--value first", row.value || "נתונים שורה")
                                    case "subTotal": return m(".row__total--sub", row.value || "סיכום ביניים")
                                    case "total": return m(".row__total--main", row.value || "סיכום כולל")
                                    case "space": return m(".row__total--main", row.value || "סיכום כולל")
                                }
                            })
                        ),
                        m(".formCard__actions no-print",
                            m("button.button", { onclick: e => getReportData(reportIDs.confirms) }, "הצג דוח אישורים")
                        )
                    ),
                    navReports.isActiveState(reportIDs.accounts) && m(".preview show",
                        m(".preveiw__header", "תצוגה מקדימה של דוח  - סטטוס חשבונות"),
                        m(".reportTable",
                            getReportLayout(reportIDs.accounts).map(row => {
                                if (!row) return
                                switch (row.type) {
                                    case "header": return m(".cell--header", row.value || "שורת כותרת")
                                    case "subHeader": return m(".row cell--title", row.value || "כותרת משנה")
                                    case "subGroupHeader": return m(".cell--subtitle", row.value || "כותרת משנה")
                                    case "data": return m(".cell--value first", row.value || "נתונים שורה")
                                    case "subTotal": return m(".row__total--sub", row.value || "סיכום ביניים")
                                    case "total": return m(".row__total--main", row.value || "סיכום כולל")
                                    case "space": return m(".row__total--main", row.value || "סיכום כולל")
                                }
                            })
                        ),
                        m(".formCard__actions no-print",
                            m("button.button", { onclick: e => getReportData(reportIDs.accounts) }, "הצג דוח חשבונות")
                        )
                    ),
                    navReports.isActiveState(reportIDs.apartments) && m(".preview show",
                        m(".preveiw__header", "תצוגה מקדימה של דוח דירות לפי קבלן"),
                        m(".reportTable",
                            getReportLayout(reportIDs.apartments).map(row => {
                                if (!row) return
                                switch (row.type) {
                                    case "header": return m(".cell--header", row.value || "שורת כותרת")
                                    case "subHeader": return m(".row cell--title", row.value || "כותרת משנה")
                                    case "subGroupHeader": return m(".cell--subtitle", row.value || "כותרת משנה")
                                    case "data": return m(".cell--value first", row.value || "נתונים שורה")
                                    case "subTotal": return m(".row__total--sub", row.value || "סיכום ביניים")
                                    case "total": return m(".row__total--main", row.value || "סיכום כולל")
                                    case "space": return m(".row__total--main", row.value || "סיכום כולל")
                                }
                            })
                        ),
                        m(".formCard__actions no-print",
                            m("button.button", { onclick: e => getReportData(reportIDs.apartments) }, "הצג דוח דירות")
                        )
                    ),
                ),

                (reportBuildStage === SHOW) && m(Card, { class: "show fade-in" },
                    (loadReportData || !reportData) ? m(InlineLoaderDots) : [
                        //ACCOUNTS REPORTS
                        (navReports.isActiveState(reportIDs.accounts) && reportData) && m(".reportBody",
                            m(".caption", "דוח סיכום חשבונות לקבלן"),
                            //summery
                            m(".summery",
                                m(".summery__caption", "סהכ לדוח"),
                                m(".summery__box--black",
                                    m(".summery__label", "כמות"),
                                    m(".summery__total--multipart",
                                        m(".", { style: `display: flex;flex-direction: column-reverse;` },
                                            m("span", "פרוייקטים"),
                                            m("span", { style: "font-size: 2.4rem;font-weight:500;" }, getDisplayValue(reportData.total.projectsSize, NUMBER))
                                        ),
                                        m(".", { style: `display: flex;flex-direction: column-reverse;` },
                                            m("span", "חוזים"),
                                            m("span", { style: "font-size: 2.4rem;font-weight:500;" }, getDisplayValue(reportData.total.contractsSize, NUMBER))
                                        ),
                                    ),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "סכום הסכמים"),
                                    m(".summery__total", getDisplayValue(reportData.total.contractsSum, CURRENCY)),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "ביצוע מאושר הנהלה"),
                                    m(".summery__total", getDisplayValue(reportData.total.sectionsConfirmByManager, CURRENCY)),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "% ביצוע מהסכם"),
                                    m(".summery__total", getDisplayValue(reportData.total.totalBaseConfirmDone, PERCENT)),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "תוספות וקיזוזים מאושר הנהלה"),
                                    m(".summery__total", getDisplayValue(reportData.total.addSubTotalConfirmByManager, CURRENCY)),
                                ),
                                // m(".summery__box--black",
                                //     m(".summery__label", "סהכ ביצוע מאושר"),
                                //     m(".summery__total", getDisplayValue(reportData.total.subTotalConfirmByManager, CURRENCY)),
                                // ),
                                // m(".summery__box--black",
                                //     m(".summery__label", "% סהכ ביצוע מאושר"),
                                //     m(".summery__total", getDisplayValue(reportData.total.totalConfirmDone, PERCENT)),
                                // ),
                            ),
                            //tables
                            Object.entries(reportData.projects)
                                .map(([projectRef, projectData]) => {
                                    const headers = buildHeaders(AccountDoc.headers, getReportHeaders("accounts"))
                                    const subTotals = getReportHeaders("accounts", "subTotals")
                                    const totals = getReportHeaders("accounts", "totals")
                                    return m(".reportTable", { style: `grid-template-columns:${Object.keys(headers).map(row => "1fr").join(" ")}` },
                                        m(".reportTable__title", getDisplayValue(projectRef, REF, { param: "title" })),
                                        //headers                        
                                        Object.entries(headers).map(([header, field]) => {
                                            return m(".cell--header", field.label || header)
                                        }),
                                        //data
                                        Object.entries(projectData.contracts).map(([contractRef, contractData]) => {
                                            return [
                                                m("a.cell--title row ", { href: `/#!/app/${contractRef}`, target: "_blank" }, contractData.title || contractRef),
                                                Object.entries(contractData.accounts)
                                                    .sort(sortDocsEntries("period"))
                                                    .map(([accountRef, accountData]) => {
                                                        return Object.entries(headers).map(([header, field], ind) => {
                                                            if (field.type === "collection") {
                                                                return m(`.cell--value ${field.classStyle || ""}`, (!accountData[header] || !Array.isArray(accountData[header])) ?
                                                                    "--"
                                                                    :
                                                                    m("ul", { style: `font-size: 2rem;list-style-position:inside;` },
                                                                        // accountData[header].map(el => m("li", getDisplayValue(el.docData.sum, CURRENCY)))
                                                                        accountData[header].map(el => m("li", Object.entries(field.fields).map(([_header, _field]) => getDisplayValue(el.docData[_header], _field.type, _field.options)).join(" | ")))
                                                                    )
                                                                )
                                                            }
                                                            return m(`.cell--value ${field.classStyle || ""} ${ind === 0 ? "first" : ""}`, getDisplayValue(accountData.docData[header], field.type, field.options, accountData))
                                                        })
                                                    }),
                                                //sub totals [by contract]
                                                layoutContains("subTotal") && Object.entries(subTotals).map(([header, { value, val, type = STRING, options }]) => {
                                                    // console.assert(value || val, "must contain value || val!!")
                                                    let displayValue = "---"
                                                    if (val && objType(val) === O_FUNCTION) displayValue = getDisplayValue(val(contractRef), type, options)
                                                    if (value !== undefined) displayValue = getDisplayValue(value, type, options)
                                                    return m(".row__total--sub", displayValue)
                                                }),
                                            ]
                                        }),
                                        //totals
                                        Object.entries(totals).map(([header, { value, val, type = STRING, options }]) => {
                                            // console.assert(value || val, "must contain value || val!!")
                                            let displayValue = "---"
                                            if (val && objType(val) === O_FUNCTION) displayValue = getDisplayValue(val(projectRef, projectData.contracts), type, options)
                                            if (value !== undefined) displayValue = getDisplayValue(value, type, options)
                                            return m(".row__total--main", displayValue)
                                        }),
                                    )
                                })
                        ),

                        //CONFIRMS REPORT
                        (navReports.isActiveState(reportIDs.confirms) && reportData) && m(".reportBody",
                            m(".caption", "דוח מצב אישורים לקבלן"),
                            //summery
                            m(".summery",
                                m(".summery__caption", "סהכ לדוח"),
                                m(".summery__box--black",
                                    m(".summery__label", "כמות"),
                                    m(".summery__total--multipart",
                                        m(".", { style: `display: flex;flex-direction: column-reverse;` },
                                            m("span", "פרוייקטים"),
                                            m("span", { style: "font-size: 2.4rem;font-weight:500;" }, getDisplayValue(reportData.total.projectsSize, NUMBER))
                                        ),
                                        m(".", { style: `display: flex;flex-direction: column-reverse;` },
                                            m("span", "חוזים"),
                                            m("span", { style: "font-size: 2.4rem;font-weight:500;" }, getDisplayValue(reportData.total.contractsSize, NUMBER))
                                        ),
                                    ),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "סכום הסכמים"),
                                    m(".summery__total", getDisplayValue(reportData.total.contractsSum, CURRENCY)),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "ביצוע חוזים מאושר"),
                                    m(".summery__total", getDisplayValue(reportData.total.subTotal, CURRENCY)),
                                ),
                                m(".summery__box--black",
                                    m(".summery__label", "חודש חשבון נוכחי"),
                                    m(".summery__total", buildMonthText(addMonths(-1, new Date()))),
                                ),
                                // m(".summery__box--black",
                                //     m(".summery__label", "ביצוע חוזים בתהליך אישור"),
                                //     m(".summery__total", getDisplayValue(reportData.total.subTotalOnboarding, CURRENCY)),
                                // ),
                            ),
                            //tables
                            Object.entries(reportData.projects)
                                .map(([projectRef, projectData]) => {
                                    const headers = buildHeaders(ContractDoc.headers, getReportHeaders("confirms"))
                                    // const subTotals = getReportHeaders("confirms", "subTotals")
                                    const totals = getReportHeaders("confirms", "totals")
                                    return m(".reportTable", { style: `grid-template-columns:${Object.keys(headers).map(row => "1fr").join(" ")}` },
                                        m(".reportTable__title", getDisplayValue(projectRef, REF, { param: "title" })),
                                        //headers                        
                                        Object.entries(headers).map(([header, field]) => {
                                            return m(".cell--header", field.label || header)
                                        }),
                                        //data [contracts - accounts - confirms]
                                        Object.entries(projectData.contracts)
                                            .map(([contractRef, contractData]) => {
                                                return Object.entries(headers).map(([header, { label, val, type = STRING, options = {}, classStyle = "", action = () => null, href, target = "_blank" }], ind) => {
                                                    // const { value, classStyle = "", additionalInfo = "" } = val(doc)
                                                    let value, additionalInfo = { value: "" }, classStyles = classStyle
                                                    if (val) {
                                                        const { value: calcValue, classStyle: calcStyle = "", additionalInfo: calcAdditionalInfo = { value: "" } } = val(contractData)
                                                        value = calcValue
                                                        classStyles += calcStyle
                                                        additionalInfo = calcAdditionalInfo
                                                    } else value = getObjValue(contractData, header)
                                                    const displayValue = getDisplayValue(value, type, options, contractData)
                                                    const cellSelector = `${href ? "a" : "div"}.cell--value.table__cell ${classStyles} ${ind === 0 ? "first" : ""}`
                                                    return m(cellSelector, {
                                                        onclick: e => action(e, contractData),
                                                        href: href ? href(contractRef) : false,
                                                        "data-tooltip": true,
                                                        "data-info": (!additionalInfo || additionalInfo.value == "") ? "false" : "true",
                                                        "data-error": (additionalInfo && additionalInfo.isError) ? "true" : "false",
                                                        target
                                                    }, displayValue,
                                                        m(Tooltip, { text: `${label}:\n${displayValue}\r${additionalInfo ? additionalInfo.value : ""}`, options }),
                                                    )
                                                })
                                            }),
                                        //totals
                                        Object.entries(totals).map(([header, { value, val, type = STRING, options }]) => {
                                            // console.assert(value || val, "must contain value || val!!")
                                            let displayValue = "---"
                                            if (val && objType(val) === O_FUNCTION) displayValue = getDisplayValue(val(projectRef, projectData.contracts), type, options)
                                            if (value !== undefined) displayValue = getDisplayValue(value, type, options)
                                            return m(".row__total--main", displayValue)
                                        }),
                                    )
                                })
                        ),

                        //APARTMENTS REPORT
                        (navReports.isActiveState(reportIDs.apartments) && reportData) && m(".reportBody",
                            //tables
                            Object.entries(reportData.projects)
                                .map(([projectRef, projectData]) => {
                                    const { headers } = reportData
                                    return m(".reportTable", { style: `grid-template-columns:${Object.keys(headers).map(row => "1fr").join(" ")}` },
                                        m(".reportTable__title", getDisplayValue(projectRef, REF, { param: "title" })),
                                        //headers                        
                                        Object.entries(headers).map(([header, field]) => {
                                            return m(".cell--header", field.label || header)
                                        }),
                                        //data [building - apartemnts]
                                        Object.entries(projectData.buildings)
                                            .map(([buildingRef, buildingData]) => {
                                                return [
                                                    m(".cell--title row ", { onclick: e => console.log("TODO: show buiding") }, buildingData.title || buildingRef),
                                                    buildingData.apartments
                                                        .map(aparemtentData => {
                                                            return Object.entries(headers).map(([header, { label, val, type = STRING, options = {}, classStyle = "", action = () => null, href, target = "_blank" }], ind) => {
                                                                // const { value, classStyle = "", additionalInfo = "" } = val(doc)
                                                                let value, additionalInfo = { value: "" }, classStyles = classStyle
                                                                if (val) {
                                                                    const { value: calcValue, classStyle: calcStyle = "", additionalInfo: calcAdditionalInfo = { value: "" } } = val(aparemtentData)
                                                                    value = calcValue
                                                                    classStyles += calcStyle
                                                                    additionalInfo = calcAdditionalInfo
                                                                } else value = getObjValue(aparemtentData, header)
                                                                const displayValue = getDisplayValue(value, type, options, aparemtentData)
                                                                const cellSelector = `${href ? "a" : "div"}.cell--value.table__cell ${classStyles} ${ind === 0 ? "first" : ""}`
                                                                return m(cellSelector, {
                                                                    onclick: e => action(e, aparemtentData),
                                                                    href: href ? href(apartmentRef) : false,
                                                                    "data-tooltip": true,
                                                                    "data-info": (!additionalInfo || additionalInfo.value == "") ? "false" : "true",
                                                                    "data-error": (additionalInfo && additionalInfo.isError) ? "true" : "false",
                                                                    target
                                                                }, displayValue,
                                                                    m(Tooltip, { text: `${label}:\n${displayValue}\r${additionalInfo ? additionalInfo.value : ""}`, options }),
                                                                )
                                                            })
                                                        })
                                                ]
                                            }),
                                    )
                                })
                        )
                    ]
                )
            )
        }
    }
}
