import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';
import { Card } from '../../commons/cardLayout/Card';

// DATA
import { COMPANIES } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { CompanyDoc } from '../../../data/store/companies/companyClass';
import { uuid } from '../../../utils/js';
import { Prompt } from '../../commons/prompt/Prompt';
import { Icon } from '../../components/icon/Icon';
import { getDisplayValue } from '../../../data/utils/inputValidation';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots';
import { FilterTable } from '../../commons/filterTable/FilterTable';
import { statusTypes } from '../../../utils/constants/statusTypes';
import { isDocMatchTerm, isFiltered } from '../../../data/utils/search';
import { isSystemMode } from '../../../data/utils/viewUtils';
import { settingsStore } from '../../../data/store/settingsStore';
import { DocModel } from '../../../data/store/docModel';
import { Table } from '../../commons/table/Table';

// FUNCTIONS
export const Companies = (node) => {

    //TODO: create commons for all this functions : [handleCopy, handleDelete , handleAdd]

    const handleCopy = (e, company) => {
        e.stopPropagation();
        const cloneData = Object.assign({}, company.docData)
        cloneData.docID = uuid();
        cloneData.colRef = "companies";
        cloneData.title = `עותק של ${company.docData.title}`
        node.state.prompt = {
            title: "הוספת חברה  - עותק",
            msg: `האם ליצור חברה חדשה עותק של ${company.docData.title} ?`,
            actionText: "כן",
            action: e => {
                const newCompany = new CompanyDoc(cloneData, true);
                node.state.snackbar = { msg: "חברה חדש נוצרה! יש לערוך את החברה כדי לשמור" }
                node.state.prompt = false
                m.route.set(`/admin/companies/${newCompany.docData.docID}`)
            }
        }
    }

    const handleDelete = (e, company) => {
        e.stopPropagation();
        node.state.prompt = {
            title: "מחיקת חברה",
            msg: `האם למחוק את החברה ${company.docData.title} ?`,
            actionText: "מחק",
            action: e => {
                company.remove()
                node.state.snackbar = { msg: "החברה נמחקה!" }
                node.state.prompt = false
            }
        }
    }

    const handleAdd = (e) => {
        node.state.prompt = {
            title: "הוספת חברה",
            msg: "להוסיף חברה חדשה ?",
            actionText: "הוסף",
            action: e => {
                const newCompany = new CompanyDoc({ colRef: "companies" });
                node.state.snackbar = { msg: "חברה חדשה נוצר! יש לערוך את החברה כדי לשמור אותו" }
                node.state.prompt = false
                if (node.state.settings.navOnAdd) m.route.set(`/admin/companies/${newCompany.docData.docID}`)
            }
        }
    }

    return {
        snackbar: false,
        prompt: false,
        headers: ["title"],
        load: true,
        filters: [{ id: "status", param: "nonActive" }],
        searchTerm: "",
        settings: {
            navOnAdd: true
        },
        oninit: vnode => {
            vnode.state.snackbar = { msg: "טוען חברות..." }
            const { alreadyListen, unsubscribe } = dataStore[COMPANIES].listen();
            if (alreadyListen) {
                vnode.state.load = false
            } else {
                setTimeout(() => {
                    vnode.state.load = false
                    m.redraw()
                }, 3000);
            }
            if (window.innerWidth < 1000 && window.innerWidth >= 700) {
                vnode.state.headers = ["title", "address", "description", "status"]
            } else if (window.innerWidth < 700) {
                vnode.state.headers = ["title", "status"]
            }
            m.redraw();
        },
        view: (vnode) => {
            return (
                m(PageLayout, { class: "companies", title: "חברות מנוהלות" },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    m(Table, {
                        title: "טבלת חברות",
                        modelID: COMPANIES,
                        colRef: COMPANIES,
                        doc: DocModel,
                        tableHeaders: vnode.state.headers,
                        // filterDataOptions: {},
                        // sortOptions: { param: "timestamp" },
                        searchOptions: ["title"],
                        filters: vnode.state.filters,
                        // filterMap: [{
                        //     label: "סטטוס",
                        //     header: "status",
                        //     options: statusTypes
                        // }]
                    }),
                )
            )
        }
    }
}