import m from "mithril"

import { SWITCH, DATE, STRING, NUMBER, PERCENT, CURRENCY, VALID_CALC, LIST, VALID_COMPARE, REF } from "../../../utils/constants/types";
import { MIN, MAX, DISABLED, STEP } from "../../../utils/constants/inputAttrs";
import { REG_URL_LAST_PART } from "../../../utils/constants/regex"
import { CONTRACTS, WORKSPACES, PROJECTS, VENDORS, CONTRACT_SECTIONS, CONTRACT_ACCOUNTS, SECTION_MILESTONES, GROUPS, PAYMENTS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, CONTRACT_COMMENTS } from "../../dictionary/routeNames";
import * as _x from "../../../utils/constants/contractsExportsOptions"

import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";
import { db, auth } from "../../../index";
import { VendorDoc } from "../vendors/vendorClass";
import { addMonths, sortDocsBy, asNumber, objType, formatDateDisplay, sortDocsEntries, uuid } from "../../../utils/js";
import { booleanType, constractCommentsTags, constractStatusTypes } from "../../../utils/constants/statusTypes";
import { WorkspaceDoc } from "./workSpaceClass";
import { GroupDoc } from "./groupClass";
import { buildCloneDBDoc } from "../../CRUD/utils";
import { O_OBJECT } from "../../../utils/constants/objTypes";
import { getDisplayValue } from "../../utils/inputValidation";
import { SectionDoc } from "./sectionClass";
import { EXPORTS_API_SHEET } from "../../../firebase/api";

const contractTypeOptions = [
    { text: 'פאושלי', id: 'pauschal' },
    { text: 'כמותי', id: 'amount' },
    { text: 'שכירות', id: 'rent' },
    { text: 'חשבוניות', id: 'invoice' },
    { text: 'מטבחים ושיש', id: 'kitchen' },
    // { text: 'עבודות קטנות', id: 'oddJobs' },
]

const indexedFactorOptions = [
    { text: 'מדד המחירים לצרכן', id: '120010' },
    { text: 'מדד מחירי תשומה בבנייה למגורים', id: '200010' },
    { text: 'מדד מחירי תשומה בבנייה למסחר ולמשרדים', id: '800010' },
]

const visibleLogics = {
    indexedFactor: {
        allowRoles_where_or: [{
            notRole: [], // all other roles
            is: [{ "doc.docData.isIndexed": true }]
        }],
    }
}

export class ContractDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ContractDoc,
        });
        if (!this.isNew) {
            // this.listenToChildren([CONTRACT_ACCOUNTS])//TODO: remove me!
            this.listenToChildren([])//TESTME:
        }
    };
    async save() {
        if ("contractSum" in this.docChanges) {
            await this.setProjectTotalSum()
        }
        // if ("calculationMethod" in this.docChanges && this.docChanges.calculationMethod === "oddJobs") {
        //     this.edit({ status: "active" })
        // }
        await Promise.resolve(super.save())
    }

    async setProjectTotalSum() {
        let totalSum = 0
        this.getSiblings().forEach(contractDoc => totalSum += parseFloat(contractDoc.docData.contractSum))
        const project = this.getParent()
        project.saveLocal({ totalSum })
        await project.save()
        m.redraw()
    }

    //creates the default workspace + group
    insert(colPath, forceInsert) {
        const docPath = `${colPath}/${this.docData.docID}`
        Promise.resolve(super.insert(colPath, forceInsert))
            .then(() => {
                db.doc(`${docPath}/workspaces/default`)
                    .set({
                        title: WorkspaceDoc.headers.title.defaultValue,
                        description: "",
                        itemsName: "",
                        itemsCount: 1,
                        isActive: true,
                        createdAt: new Date().toISOString(),
                        createdBy: auth.currentUser.uid,
                        timestamp: +new Date()
                    }, { merge: true })
            })
            .then(() => {
                db.doc(`${docPath}/workspaces/default/groups/default`)
                    .set({
                        title: GroupDoc.headers.title.defaultValue,
                        description: "",
                        itemsName: "",
                        itemsCount: 1,
                        isActive: true,
                        createdAt: new Date().toISOString(),
                        createdBy: auth.currentUser.uid,
                        timestamp: +new Date()
                    }, { merge: true })
            })
            .catch(err => {
                console.error(err)
            })
    }

    async toJSON({ clone, addDataOptions = {} } = {}) {
        const json = {}
        let newParentRef = false
        if (addDataOptions.colRef && addDataOptions.colRef !== "") {
            newParentRef = addDataOptions.colRef.replace(REG_URL_LAST_PART, "")
        }
        const cloneContract = this.cloneDocData({
            clone, newParentRef, resets: {
                status: "plan",
                currentAccount: {},
                subTotalContract: 0,
                totalSection: 0,
                totalAdditions: 0,
                totalDelay: 0,
                totalSubtraction: 0,
            }
        })
        json.data = cloneContract
        json.children = {}

        const workspaces = []
        const groups = []
        const sections = []
        const milestones = []
        // GET workspaces
        const workspacesColRef = `${cloneContract.ref}/${WORKSPACES}`
        const workspacesCollection = (await db.collection(`${this.docData.ref}/${WORKSPACES}`).get()).docs
        for (const ws of workspacesCollection) {
            const cloneWs = buildCloneDBDoc(workspacesColRef, ws)
            workspaces.push(cloneWs)
            // GET groups
            const groupsColRef = `${cloneWs.ref}/${GROUPS}`
            const groupsCollection = (await db.collection(`${ws.ref.path}/${GROUPS}`).get()).docs
            for (const group of groupsCollection) {
                const cloneGroup = buildCloneDBDoc(groupsColRef, group)
                groups.push(cloneGroup)
                // GET sections
                const sectionsColRef = `${cloneContract.ref}/${CONTRACT_SECTIONS}`
                const sectionsCollection = (await db.collection(`${this.docData.ref}/${CONTRACT_SECTIONS}`).get()).docs
                for (const section of sectionsCollection) {
                    const cloneSection = buildCloneDBDoc(sectionsColRef, section, { subTotal: 0 })
                    cloneSection.workspacePath = `workspaces/${cloneWs.docID}/groups/${cloneGroup.docID}`
                    sections.push(cloneSection)
                    // GET milestones
                    const milestonesColRef = `${cloneSection.ref}/${SECTION_MILESTONES}`
                    const msCollection = (await db.collection(`${section.ref.path}/${SECTION_MILESTONES}`).get()).docs
                    for (const ms of msCollection) {
                        console.log("original: ", ms.data())
                        const cloneMs = buildCloneDBDoc(milestonesColRef, ms)
                        console.log("new: ", cloneMs)
                        milestones.push(cloneMs)
                    }
                }
            }
        }
        json.children = { [WORKSPACES]: workspaces, [GROUPS]: groups, [CONTRACT_SECTIONS]: sections, [SECTION_MILESTONES]: milestones }
        return json
    }

    pivotChildren(modelID, groupBy, filterDataOptions = {}) {
        const children = this.getChildren(modelID, filterDataOptions);
        const temp = {}
        children.forEach(doc => {
            const groupValue = doc.docData[groupBy]
            if (!temp[groupValue]) {
                temp[groupValue] = {
                    docID: uuid(),
                    title: getDisplayValue(groupValue, doc.headers[groupBy].type, doc.headers[groupBy].options, doc.docData)
                }
            }
            if (!temp[groupValue].children) temp[groupValue].children = []
            temp[groupValue].children.push(doc)
        })
        if (!this.views) this.views = {}
        this.views[`${modelID}_${groupBy}`] = temp

        return this.views[`${modelID}_${groupBy}`]
    }

    getActiveAccount() {
        const filterAccounts = this.getChildren(CONTRACT_ACCOUNTS, { dataOnly: true })
        if (filterAccounts.length > 0) {
            const last = filterAccounts.sort((a, b) => b.docData.period - a.docData.period)[0]
            const { stage } = last.docData
            if (stage !== "start" && stage !== "finish") {
                return last
            }
        }
    }


    getLastAccount(param) {
        const filterAccounts = this.getChildren(CONTRACT_ACCOUNTS, { dataOnly: true })
        if (filterAccounts.length === 0) return
        else {
            const lastAccount = filterAccounts.sort((a, b) => b.docData.period - a.docData.period)[0];
            if (param) return lastAccount.docData[param]
            return lastAccount
        }
    }

    getTotalAccounts(field = "subTotal", filterOptions = {}) {
        const accounts = this.getChildren(CONTRACT_ACCOUNTS, filterOptions)
        let total = 0;
        accounts.forEach(doc => {
            total += parseFloat(doc.docData[field] || 0)
        })
        return total
    }

    getPayments({ filterPayments = {}, filterAccounts = {} } = {}) {
        const accounts = this.getChildren(CONTRACT_ACCOUNTS, filterAccounts)
        let result = []
        accounts.forEach(account => {
            const payments = account.getChildren(PAYMENTS, filterPayments)
            if (payments.length > 0) result.push(...payments)
        })
        return result
    }

    getTotalPayments({ filterPayments = {}, filterAccounts = {} } = {}) {
        const accounts = this.getChildren(CONTRACT_ACCOUNTS, filterAccounts)
        let total = 0;
        accounts.forEach(account => {
            account.getChildren(PAYMENTS, filterPayments).forEach(payment => {
                total += parseFloat(payment.docData.sum)
            })
        })
        return total
    }

    getTotal(options = {}) {
        let result = 0
        // dataStore[CONTRACT_SECTIONS].data
        //     .filter(doc => doc.docData.colRef === `${this.docData.ref}/sections`)
        this.getChildren(CONTRACT_SECTIONS, options)
            .forEach(doc => result += parseFloat(doc.docData.totalSum))
        return result
    }

    calcLastTotalActuals({ accountRef } = {}) {
        let total = 0
        this.getChildren(CONTRACT_SECTIONS).forEach(section => {
            total += section.calcLastTotalActuals({ accountRef })
        })
        return total
    }

    calcTotalActuals({ accountRef } = {}) {
        let total = 0
        this.getChildren(CONTRACT_SECTIONS).forEach(section => {
            total += section.calcTotalActuals({ accountRef })
        })
        return total
    }
    pivotTotalActuals() {
        const total = { [CONTRACT_SECTIONS]: 0, [CONTRACT_ADDITIONS]: 0, [CONTRACT_SUBTRACTIONS]: 0 }
        Object.keys(total).forEach(modelID => {
            this.getChildren(modelID).forEach(section => {
                total[modelID] += parseFloat(section.docData.subTotal || 0)
            })
        })
        return total
    }

    calcSubTotal() {
        const totalActuals = parseFloat(this.calcTotalActuals())
        const delayPercentage = parseFloat(this.docData.delayPercentage / 100)
        const totalAdditions = this.calcTotalAdditionsOrSubtractions(false, CONTRACT_ADDITIONS)
        const totalSubtractions = this.calcTotalAdditionsOrSubtractions(false, CONTRACT_SUBTRACTIONS)
        const totalAddSub = totalAdditions - totalSubtractions
        const totalDelay = parseFloat((totalActuals + totalAddSub) * delayPercentage)
        return totalActuals + totalAddSub - totalDelay
    }


    calcTotalToPay() {
        const subTotalCumulative = this.calcSubTotal()

        const lastAccount = this.getLastAccount()
        if (lastAccount) {
            return subTotalCumulative - this.getTotalAccounts("subTotal", { exclude: { month: lastAccount.docData.month } })
        } else {
            return subTotalCumulative - this.getTotalAccounts("subTotal")
        }
    }
    
    getAccountDiffsBeforeTax(filterAccounts={}){
        let total = 0
        this.getChildren(CONTRACT_ACCOUNTS,filterAccounts)
            .forEach(account=>{
                const totalToPay = Number(account.docData.totalPay)
                const totalPayments = account.getTotalPayments()
                const diff = totalToPay - totalPayments
                const diffBeforeTax = diff / (1 - parseFloat(account.docData.taxPercent) / 100)
                total += diffBeforeTax
                // console.log(`total diff for month: ${account.docData.month} ${getDisplayValue(totalToPay,NUMBER)} - ${getDisplayValue(totalPayments,NUMBER)} = ${getDisplayValue(diffBeforeTax,NUMBER)}`);
            })
        return total
    }

    
    getBalance(){
        const lastAccount = this.getLastAccount()
        const historyBalance = this.getHistoryBalance()
        return historyBalance - lastAccount.getTotalPayments()
    }

    getHistoryBalance(){
        const lastAccount = this.getLastAccount()
        const historyDiffToPay = this.getAccountDiffsBeforeTax({ exclude: { month: lastAccount.docData.month } })
        const currentDiffToPay = historyDiffToPay * (1 - (Number(lastAccount.docData.taxPercent) / 100))
        return currentDiffToPay + parseFloat(lastAccount.docData.totalPay)
    }

    getTotalToPayCalculation() {
        const totalActuals = parseFloat(this.calcTotalActuals())
        const delayPercentage = parseFloat(this.docData.delayPercentage / 100)
        const totalAdditions = this.calcTotalAdditionsOrSubtractions(false, CONTRACT_ADDITIONS)
        const totalSubtractions = this.calcTotalAdditionsOrSubtractions(false, CONTRACT_SUBTRACTIONS)
        const totalAddSub = totalAdditions - totalSubtractions
        const totalDelay = parseFloat((totalActuals + totalAddSub) * delayPercentage)
        const subTotalCumulative = totalActuals + totalAddSub - totalDelay
        let totalHistoryBillings
        const lastAccount = this.getLastAccount()
        if (lastAccount) totalHistoryBillings = this.getTotalAccounts("subTotal", { exclude: { month: lastAccount.docData.month } })
        else totalHistoryBillings = this.getTotalAccounts("subTotal")

        const totalToPay = subTotalCumulative - totalHistoryBillings
        const percentDone = parseFloat(subTotalCumulative / this.docData.contractSum)
        return {
            totalActuals,
            delayPercentage,
            totalAdditions,
            totalSubtractions,
            totalAddSub,
            totalDelay,
            subTotalCumulative,
            totalHistoryBillings,
            totalToPay,
            percentDone
        }
    }

    getProjectType(param) {
        const projectRef = this.docData.colRef.replace(REG_URL_LAST_PART, "") //remove the contracts part of the url
        const project = [...dataStore[PROJECTS].data, ...dataStore[PROJECTS].new].find(doc => doc.docData.ref === projectRef)
        if (project) {
            return project.getProjectType(param || "id")
        }
        return PROJECTS
    }

    getAdditionsOrSubtractions(modelID) {
        return this.getChildren(modelID, { dataOnly: true })
    }

    //gets the total budget of all additions or subtraction sections
    getTotalAdditionsOrSubtractions(modelID) {
        const docs = this.getAdditionsOrSubtractions(modelID)
        let total = 0
        docs.forEach(doc => {
            total += doc.docData.totalSum
        })
        return total
    }

    //calcs the total price of all additions or subtraction sections
    calcTotalAdditionsOrSubtractions(accountRef, modelID) {
        const docs = this.getAdditionsOrSubtractions(modelID)
        let total = 0
        docs.forEach(doc => {
            total += doc.calcTotalActuals({ accountRef })
        })
        return total
    }

    //calcs the total price of all last additions or subtraction sections
    calcLastTotalAdditionsOrSubtractions(accountRef, modelID) {
        const docs = this.getAdditionsOrSubtractions(modelID)
        let total = 0
        docs.forEach(doc => {
            total += doc.calcLastTotalActuals({ accountRef })
        })
        return total
    }

    hasCommentOnSection(sectionRef, accountRef) {
        const filter = {
            include: { sectionRef }
        }
        if (accountRef) filter.include.accountRef = accountRef
        return this.getChildren(CONTRACT_COMMENTS, filter)[0] !== undefined
    }

    getExportData(which = [_x.ACCOUNTS_TOTAL], themeType = "color") {
        const _makeTotalFuncRow = (headersFields, part) => {
            return Object.keys(headersFields).map((fieldKey) => {
                let field = headersFields[fieldKey]
                if (part) field = field[part]
                if (field.value) return field.value
                else if (objType(field) === O_OBJECT) return Object.assign({}, field)
                else return "---"
            })
        }

        const getDocDataByHeaders = (doc, docHeaders) => {
            return Object.entries(docHeaders).map(([header, field]) => {
                let value = ''
                if (field.val) value = field.val(doc)
                else if (header in doc.docData) {
                    if (field.type) value = getDisplayValue(doc.docData[header], field.type, field.options, doc.docData)
                    else value = doc.docData[header]
                }
                return value
            })
        }

        const tableLayouts = {
            [_x.ACCOUNTS_TOTAL]: { color: "12653931", black: "1970764010" }, //TESTME:
            [_x.CONTRACT_PAYMENTS]: { color: "3637721", black: "3637721" }, //TODO:
            [_x.CONTRACT_SECTIONS_PLAN]: { color: "1738915360", black: "1738915360" }, //TODO:
            [_x.CONTRACT_SECTIONS_ACTUALS]: { color: "729810321", black: "729810321" }, //TODO:
            // [_x.SECTIONS_TOTAL]: { color: "", black: "" },
            // [_x.CONTRACT_ACTUALS]: { color: "", black: "" },
        }

        const reportName = `דוח חוזה - ${this.docData.title} - ${formatDateDisplay(new Date())}`
        const reportVars = {
            reportName,
            projectTitle: this.getParent("title"),
            contractTitle: this.docData.title,
        }
        const tables = {}

        if (which.some(el => el === _x.ACCOUNTS_TOTAL || el === _x.CONTRACT_PAYMENTS)) {
            const last = this.getLastAccount()

            const accountsHistoriesData = this.getChildren(CONTRACT_ACCOUNTS, { exclude: { ref: last.docData.ref } })
            const hasHistories = accountsHistoriesData.length > 0

            if (which.includes(_x.ACCOUNTS_TOTAL)) {
                //ACCOUNTS
                const accountsHeaders = {
                    period: { label: "מס' חשבון", totals: { value: "סהכ" } },
                    month: { label: "חודש ביצוע", totals: { func: "COUNT", textSymbol: "חשבונות" } },
                    totalSections: { label: "סעיפים ביצוע", totals: { func: "SUM" } },
                    totalAddSub: {
                        label: "תוספות / קיזוזים", totals: { func: "SUM" },
                        val: (doc) => {
                            return asNumber(doc.docData.totalAdditions) -
                                asNumber(doc.docData.totalSubtractions)
                        }
                    },
                    subTotal: { label: `סה"כ לפני עיכבון`, totals: { func: "SUM" } },
                    totalDelay: { label: "עכבון", totals: { func: "SUM" } },
                    totalAccountToPay: { label: "לתשלום", totals: { func: "SUM" } },
                    indexedPercent: { label: "% הצמדה", totals: {} },
                    totalIndexed: { label: "סכום הצמדה", totals: { func: "SUM" } },
                    totalAfterIndexed: { label: "סכום לאחר הצמדה", totals: { func: "SUM" } },
                    vatPercent: { label: "% מעמ", totals: {} },
                    totalVAT: { label: "מעמ", totals: { func: "SUM" } },
                    totalBeforeTax: {
                        label: "לתשלום לפני ניכוי", totals: { func: "SUM" },
                        val: (doc) => {
                            // return asNumber(doc.docData.totalPay) - asNumber(doc.docData.totalTax)
                            return asNumber(doc.docData.totalPay) + asNumber(doc.docData.totalTax)
                        }
                    },
                    taxPercent: { label: "% ניכוי מס", totals: {} },
                    totalTax: { label: "ניכוי מס", totals: { func: "SUM" } },
                    totalPay: { label: "לתשלום סופי", totals: { func: "SUM" } },
                    totalPayments: {
                        label: "שולם", totals: { func: "SUM" },
                        val: (doc) => this.getTotalPayments({ filterAccounts: { include: { ref: doc.docData.ref } } })
                    },
                }
                const accountsHistories = accountsHistoriesData
                    .sort(sortDocsBy("period"))
                    .map((account) => getDocDataByHeaders(account, accountsHeaders))
                const accountCurrent = [getDocDataByHeaders(last, accountsHeaders)]

                tables.accountsTotal = {
                    title: `דוח חשבונות מצטבר`,
                    headers: Object.keys(accountsHeaders).map((h) => accountsHeaders[h].label),
                    tableLayout: tableLayouts[_x.ACCOUNTS_TOTAL][themeType],
                    tableVars: {
                        project: this.getParent("title"),
                        contract: this.docData.title,
                        account: this.getLastAccount("month"),
                        vendor: getDisplayValue(this.docData.contractorRef, REF),
                        vendorID: getDisplayValue(this.docData.contractorRef, REF, { param: "contractorSAPNumber" }),
                    },
                    groups: {},
                    totalsFunc: _makeTotalFuncRow(accountsHeaders, "totals")
                }

                if (hasHistories) {
                    tables.accountsTotal.groups.history = {
                        title: "חשבונות קודמים",
                        items: accountsHistories,
                        subTotalsFunc: _makeTotalFuncRow(accountsHeaders, "totals")
                    }
                }
                tables.accountsTotal.groups.current = {
                    title: "נוכחי",
                    items: accountCurrent,
                    subTotalsFunc: _makeTotalFuncRow(accountsHeaders, "totals")
                }
            }

            if (which.includes(_x.CONTRACT_PAYMENTS)) {
                //PAYMENTS
                const paymentsHeaders = {
                    _month: {
                        label: "חודש", totals: { value: "סהכ" },
                        val: (doc) => doc.getParent("month")
                    },
                    _sumBeforeTax: {
                        label: "סכום לפני ניכוי מס", val: (doc) => {
                            // taxPercent
                            // return asNumber(doc.docData.sum)  + ( asNumber(doc.docData.sum) * last.docData.taxPercent / 100)
                            return asNumber(doc.docData.sum) / ( (100-asNumber(last.docData.taxPercent)) /100 )// TESTME:
                        }, totals: { func: "SUM" }
                    },
                    sum: { label: "סכום לתשלום", totals: { func: "SUM" } },
                    paymentDate: { label: "תאריך", type: DATE, totals: { value: "" } },
                    paymentIdentity: { label: "מזהה", type: STRING, options: { symbol: "\t" }, totals: { value: "" } },
                    description: { label: "הערות", totals: { value: "" } },
                }

                const paymentsHistory = this.getChildren(PAYMENTS, { exclude: { colRef: `${last.docData.ref}/${PAYMENTS}` } }, { subChild: true })
                    .sort(sortDocsBy("paymentDate", { type: DATE }))
                    .map((payment) => getDocDataByHeaders(payment, paymentsHeaders))

                let paymentsCurrent
                if (last) paymentsCurrent = last.getChildren(PAYMENTS)
                    .sort(sortDocsBy("paymentDate", { type: DATE }))
                    .map((payment) => getDocDataByHeaders(payment, paymentsHeaders))

                tables.payments = {
                    title: "דוח פירוט תשלומים",
                    tableLayout: tableLayouts[_x.CONTRACT_PAYMENTS][themeType],
                    headers: Object.keys(paymentsHeaders).map((h) => paymentsHeaders[h].label),
                    tableVars: {
                        project: this.getParent("title"),
                        contract: this.docData.title,
                        account: this.getLastAccount("month"),
                        vendor: getDisplayValue(this.docData.contractorRef, REF),
                        vendorID: getDisplayValue(this.docData.contractorRef, REF,{param:"contractorSAPNumber"}),
                        budgetbudgetaryItem: this.docData.budgetbudgetaryItem,
                    },
                    groups: {},
                    totalsFunc: _makeTotalFuncRow(paymentsHeaders, "totals")
                }

                if (hasHistories) {
                    tables.payments.groups.history = {
                        title: "חשבונות קודמים",
                        items: paymentsHistory,
                        subTotalsFunc: _makeTotalFuncRow(paymentsHeaders, "totals")
                    }
                }

                tables.payments.groups.current = {
                    title: "נוכחי",
                    items: paymentsCurrent,
                    subTotalsFunc: _makeTotalFuncRow(paymentsHeaders, "totals")
                }
            }
        }

        if (which.includes(_x.CONTRACT_SECTIONS_PLAN)) {
            const sectionsHeaders = {
                title: { label: SectionDoc.headers.title.label, totals: { value: "סהכ" }, },
                itemPrice: { label: SectionDoc.headers.itemPrice.label, totals: { value: "-" } },
                itemsCount: { label: SectionDoc.headers.itemsCount.label, totals: { value: "-" } },
                totalSum: { label: SectionDoc.headers.totalSum.label, totals: { func: "SUM" } },
            }

            // const sections = this.getChildren(CONTRACT_SECTIONS)
            //     .sort(sortDocsBy("index", { type: NUMBER }))
            //     .map((doc) => getDocDataByHeaders(doc, sectionsHeaders))

            tables[_x.CONTRACT_SECTIONS_PLAN] = {
                title: "דוח מבנה חוזה",
                headers: Object.keys(sectionsHeaders).map((h) => sectionsHeaders[h].label),
                tableVars: {
                    path: this.docData.ref,
                    project: this.getParent("title"),
                    contract: this.docData.title,
                    vendor: getDisplayValue(this.docData.contractorRef, REF),
                },
                parentGroups: {},
                totalsFunc: _makeTotalFuncRow(sectionsHeaders, "totals")
            }


            // sections each table
            this.getChildren(CONTRACT_SECTIONS).forEach(section => {

                const example = {
                    "sectionA": {
                        "title": "סעיף קומות",
                        "custom": "sectionMilestones",
                        "tableLayout": "1738915360",
                        "tableVars": {
                            "title": "קומות",
                            "index": 1,
                            "description": "סעיף קומות",
                            "calculationMethod": "פאושלי",
                            "calculationType": "אחוזים",
                            "amountType": "קומה",
                            "itemsCount": 20,
                            "itemsStartIndex": 1,
                            "itemPrice": 200000,
                            "totalSum": 4000000,
                            "workspacePath": "",
                        },
                        "children": {
                            "milestones": [
                                [
                                    "התחלה",
                                    "אמצע",
                                    "סוף"
                                ],
                                [
                                    0.2,
                                    0.3,
                                    0.5
                                ]
                            ]
                        }
                    }
                }

                const milestones = []
                const isPauschal = section.isPauschal()
                const isPercent = section.isPercent()
                const header = isPercent ? "weight" : "price"
                const calculationMethod = isPauschal
                    ? "פאושלי" :
                    "כמותי";
                const calculationType = isPercent
                    ? "אחוזים" :
                    "סכומים";
                section.getChildren(SECTION_MILESTONES)
                    .sort(sortDocsBy("index", { type: NUMBER }))
                    .forEach((ms, ind) => {
                        if (!milestones[0]) milestones[0] = []
                        if (!milestones[1]) milestones[1] = []
                        milestones[0][ind] = ms.docData.title
                        milestones[1][ind] = parseFloat(ms.docData[header]) / (isPercent ? 100 : 1)
                    })

                tables[section.docData.ref] = {
                    title: section.docData.title,
                    custom: "sectionMilestones",
                    tableLayout: tableLayouts[_x.CONTRACT_SECTIONS_PLAN][themeType],
                    tableVars: Object.assign({}, section.docData, { calculationMethod, calculationType }),
                    children: {
                        milestones
                    }
                }
            })

            // contract structure
            this.getChildren(WORKSPACES).forEach(ws => {
                const subGroups = {}
                ws.getChildren(GROUPS)
                    // .sort(sortDocsBy("index", { type: NUMBER }))
                    .forEach(group => {
                        if (!group.isEmpty()) {
                            subGroups[group.docData.ref] = {
                                title: group.docData.title,
                                subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "totals"),
                                items: group.getSections()
                                    .sort(sortDocsBy("index", { type: NUMBER }))
                                    .map((doc) => getDocDataByHeaders(doc, sectionsHeaders))
                            }
                        }
                    })

                tables[_x.CONTRACT_SECTIONS_PLAN].parentGroups[ws.docData.ref] = {
                    title: ws.docData.title,
                    subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "totals"),
                    subGroups
                }
            })
        }

        if (which.includes(_x.CONTRACT_SECTIONS_ACTUALS)) {

            //#region  contractData
            const { totalDelay, delayRelease, contractSum } = this.docData
            const currentAccount = this.getLastAccount()
            const { [CONTRACT_SECTIONS]: totalSections, [CONTRACT_ADDITIONS]: totalAdditions, [CONTRACT_SUBTRACTIONS]: totalSubtractions } = this.pivotTotalActuals()
            const contractTotalActuals = totalSections + totalAdditions - totalSubtractions

            //currentAccount data
            const accountRef = currentAccount.docData.ref
            const additions = {
                sum: this.getTotalAdditionsOrSubtractions(CONTRACT_ADDITIONS),
                current: currentAccount.calcTotalAdditions(),
                history: this.calcLastTotalAdditionsOrSubtractions(accountRef, CONTRACT_ADDITIONS),
            }

            //break by sections
            // console.assert(additions.current + additions.history === totalAdditions)
            const subtractions = {
                sum: this.getTotalAdditionsOrSubtractions(CONTRACT_SUBTRACTIONS),
                current: currentAccount.calcTotalSubtractions(),
                history: this.calcLastTotalAdditionsOrSubtractions(accountRef, CONTRACT_SUBTRACTIONS),
            }
            const addSub = {
                current: additions.current - subtractions.current,
                history: additions.history - subtractions.history,
                sum: additions.sum - subtractions.sum,
            }
            const sections = {
                history: this.calcLastTotalActuals({ accountRef }),
                current: currentAccount.calcTotalSections(),
            }
            sections.sum = sections.history + sections.current
            // console.assert(subtractions.current + subtractions.history === totalSubtractions)
            const contractTotals = {
                history: sections.history + additions.history - subtractions.history,
                current: sections.current + additions.current - subtractions.current,
            }
            // console.assert(contractTotals.current + contractTotals.history === contractTotalActuals)


            const totalDelayAccounts = this.getTotalAccounts("totalDelay") - this.getTotalAccounts("delayRelease")
            const totalDelayHistoryAccounts = this.getTotalAccounts("totalDelay", { exclude: { month: currentAccount.docData.month } }) - this.getTotalAccounts("delayRelease", { exclude: { month: currentAccount.docData.month } })

            const delayPercentageCalc = totalDelayAccounts / contractTotalActuals
            const delayPercentageHistoryCalc = totalDelayHistoryAccounts / contractTotals.history

            const contractBudget = parseFloat(contractSum) + additions.sum - subtractions.sum

            //#endregion

            const getSectionData = section => {
                let budget = parseFloat(section.docData.totalSum)
                let totalActuals = section.calcTotalActuals()
                let currentActuals = section.calcTotalActuals({ accountRef })
                let totalDelayCalc = parseFloat(totalActuals * delayPercentageCalc)
                let currentDelayCalc = parseFloat(currentActuals * delayPercentageCalc)
                let lastAccountsActuals = section.calcLastTotalActuals({ accountRef })
                let lastAccountsDelayCalc = parseFloat(lastAccountsActuals * delayPercentageHistoryCalc)
                let percentDone = totalActuals / budget
                let currentRelease = delayRelease * (totalActuals / contractTotalActuals)
                let currentCalcActuals = currentActuals - currentDelayCalc + currentRelease
                if (section.docData.ref.includes(CONTRACT_SUBTRACTIONS)) {
                    budget = -(budget)
                    totalActuals = -(totalActuals)
                    currentActuals = -(currentActuals)
                    totalDelayCalc = -(totalDelayCalc)
                    currentDelayCalc = -(currentDelayCalc)
                    lastAccountsActuals = -(lastAccountsActuals)
                    lastAccountsDelayCalc = -(lastAccountsDelayCalc)
                    // percentDone
                    currentRelease = -(currentRelease)
                    currentCalcActuals = -(currentCalcActuals)
                }
                return [
                    section.docData.title,
                    totalActuals,
                    totalDelayCalc,
                    lastAccountsActuals - lastAccountsDelayCalc,
                    currentCalcActuals,
                    budget,
                    percentDone
                ]
            }
            const sectionsHeaders = {
                title: {
                    label: "סעיף",
                    sectionsTotals: { func: "COUNTA", textSymbol: "סעיפים" },
                    subTotals: { value: "סהכ קבוצה" },
                    totals: { value: "סהכ חוזה" }
                },
                totalActuals: {
                    label: "תשלום מצטבר קרן",
                    sectionsTotals: { func: "SUM" },
                    subTotals: { func: "SUM" },
                    totals: { func: "SUM" }
                },
                totalDelayCalc: {
                    label: "עכבון מצטבר",
                    sectionsTotals: { func: "SUM" },
                    subTotals: { func: "SUM" },
                    totals: { func: "SUM" }
                },
                totalHistory: {
                    label: "תקופות קודמות",
                    sectionsTotals: { func: "SUM" },
                    subTotals: { func: "SUM" },
                    totals: { func: "SUM" }
                },
                currentTotal: {
                    label: "תקופה נוכחית",
                    sectionsTotals: { func: "SUM" },
                    subTotals: { func: "SUM" },
                    totals: { func: "SUM" }
                },
                totalSum: {
                    label: "סכום הסכם",
                    sectionsTotals: { func: "SUM" },
                    subTotals: { func: "SUM" },
                    totals: { func: "SUM" }
                },
                percentDone: {
                    label: "% ביצוע מהסכם",
                    sectionsTotals: { func: "SUM" },
                    subTotals: { value: '---' },
                    totals: { expr: `TEXT( ${parseFloat(contractTotalActuals)} / ${parseFloat(contractBudget)} , "?,? %")` },
                },
            }

            // const sections = this.getChildren(CONTRACT_SECTIONS)
            //     .sort(sortDocsBy("index", { type: NUMBER }))
            //     .map((doc) => getDocDataByHeaders(doc, sectionsHeaders))

            tables[_x.CONTRACT_SECTIONS_ACTUALS] = {
                tableLayout: tableLayouts[_x.CONTRACT_SECTIONS_ACTUALS][themeType],
                title: "דוח ביצוע מצטבר לחוזה",
                headers: Object.keys(sectionsHeaders).map((h) => sectionsHeaders[h].label),
                tableVars: {
                    path: this.docData.ref,
                    project: this.getParent("title"),
                    contract: this.docData.title,
                    vendor: getDisplayValue(this.docData.contractorRef, REF),
                },
                parentGroups: {},
                totalsFunc: _makeTotalFuncRow(sectionsHeaders, "totals")
            }

            this.getChildren(WORKSPACES).forEach(ws => {
                const subGroups = {}
                ws.getChildren(GROUPS)
                    // .sort(sortDocsBy("index", { type: NUMBER }))
                    .forEach(group => {
                        if (!group.isEmpty()) {
                            subGroups[group.docData.ref] = {
                                title: group.docData.title,
                                subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "sectionsTotals"),
                                items: group.getSections()
                                    .sort(sortDocsBy("index", { type: NUMBER }))
                                    .map((doc) => getSectionData(doc))
                            }
                        }
                    })

                tables[_x.CONTRACT_SECTIONS_ACTUALS].parentGroups[ws.docData.ref] = {
                    title: ws.docData.title,
                    subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "subTotals"),
                    subGroups
                }
            })

            tables[_x.CONTRACT_SECTIONS_ACTUALS].parentGroups.addSub = {
                title: "תוספות וקיזוזים",
                subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "subTotals"),
                subGroups: {
                    additions: {
                        title: "תוספות",
                        subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "subTotals"),
                        items: this.getChildren(CONTRACT_ADDITIONS)
                            .sort(sortDocsBy("index", { type: NUMBER }))
                            .map((doc) => getSectionData(doc))
                    },
                    subtractions: {
                        title: "קיזוזים",
                        subTotalsFunc: _makeTotalFuncRow(sectionsHeaders, "subTotals"),
                        items: this.getChildren(CONTRACT_SUBTRACTIONS)
                            .sort(sortDocsBy("index", { type: NUMBER }))
                            .map((doc) => getSectionData(doc))
                    },
                }
            }

        }

        const dataToExport = {
            // totalLayout,
            // tableLayout,
            templeteID: EXPORTS_API_SHEET,
            title: reportName,
            data: {
                reportVars,
                tables
            }
        }
        return dataToExport
    }



    static get meta() {
        return {
            id: CONTRACTS,
            routes: {
                collection: "srojects/:projectID/contracts",
                doc: "projects/:projectID/contracts/:contractID",
            },
            logic: [
                { type: VALID_CALC, target: "eWorkDate", expression: "func:addMonths(numberOfPeriods,sWorkDate)", trigger: ["numberOfPeriods"] },
                { type: VALID_CALC, target: "numberOfPeriods", expression: "func:monthDiff(sWorkDate,eWorkDate)", trigger: ["sWorkDate", "eWorkDate"] },
                { type: VALID_COMPARE, expression: "sWorkDate < eWorkDate", trigger: ["sWorkDate", "eWorkDate", "numberOfPeriods"], msg: "תאריך ההתחלה חייב להיות לפני תאריך הסיום!!!" }

            ],
            // // [test field]=> some..return true show else
            // visibleLogic: [
            //     {
            //         //rule to test field against
            //         is: [{ "doc.docData.calculationMethod": "oddJobs" }],
            //         //fields to hide if rule is met
            //         fields: ["_vendorSAPcode", "contractorRef", "isTamplate", "budgetbudgetaryItem", "paymentDelay", "isIndexed", "indexedFactor", "delayPercentage"]
            //     }
            // ],
            conditionalFormatting: [
                {
                    header: "status",
                    logic: VALID_COMPARE,
                    expression: `status == ${constractStatusTypes[0].id}`,
                    classStyle: "info",
                },
                {
                    header: "status",
                    logic: VALID_COMPARE,
                    expression: `status == ${constractStatusTypes[1].id}`,
                    classStyle: "pending",
                },
            ]
        }
    }
    static get headers() {
        const defatulNumberOfPeriods = 24
        return {
            title: { label: 'שם החוזה', defaultValue: "ללא שם", type: STRING },
            description: { label: 'תאור החוזה', defaultValue: "--ללא תיאור--", type: STRING },
            contractorRef: {
                label: 'קבלן מבצע',
                defaultValue: "",
                type: LIST,
                options: {
                    data: dataStore[VENDORS].data.sort(sortDocsBy("title", { type: STRING })),
                    load: () => dataStore[VENDORS].listen(),
                    model: VendorDoc,
                    addOnFlow: true,
                    addDataOptions: {
                        colRef: VENDORS,
                    },
                    formHeaders: ["title", "commercialName", "companyNumber", "companyType", "contractorSAPNumber", "phone", "email", "taxPercent", "taxesEndDate", "address"]
                }
            },
            budgetItemsObj: { label: "תקציב", defaultValue: [] },
            progressCommentTags: { label: "הערות תהליך", defaultValue: "", type: SWITCH, options: constractCommentsTags },
            budgetbudgetaryItem: { label: 'סעיף תקציבי', defaultValue: "" },
            contractSum: { label: 'סכום חוזה', defaultValue: 0, type: CURRENCY },
            paymentDelay: { label: 'תנאי תשלום (ש+)', defaultValue: 0, type: NUMBER, props: [{ [MIN]: 0 }] },
            isIndexed: { label: "האם מוצמד?", type: SWITCH, defaultValue: false, options: booleanType },
            indexedFactor: { label: "שער הצמדה", defaultValue: "200010", type: SWITCH, options: indexedFactorOptions, visibleLogic: visibleLogics.indexedFactor },
            calculationMethod: { label: 'סוג חוזה', defaultValue: "pauschal", type: SWITCH, options: contractTypeOptions },
            delayPercentage: { label: 'אחוז עיכבון', defaultValue: 0, type: PERCENT, props: [{ [MIN]: 0 }, { [MAX]: 5 }, { [STEP]: "any" }], options: { isFloat: true } },
            sWorkDate: { label: 'תחילת עבודה', defaultValue: new Date().toISOString(), type: DATE },
            numberOfPeriods: { label: 'מס תקופות מתוכנן', defaultValue: defatulNumberOfPeriods, type: NUMBER, props: [{ [MIN]: 6 }, { [MAX]: 100 }] },
            eWorkDate: { label: 'סיום עבודה', defaultValue: addMonths(defatulNumberOfPeriods).toISOString(), type: DATE },
            status: { label: 'סטטוס', defaultValue: "plan", type: SWITCH, options: constractStatusTypes },
            donePercentage: { label: "אחוז ביצוע", defaultValue: 0, type: PERCENT, props: [{ [STEP]: "1" }, { [MIN]: 0 }, { [MAX]: 100 }, { [DISABLED]: true }] },
            isTamplate: { label: "משמש כתבנית", defaultValue: false, type: SWITCH, options: booleanType },
        }
    }
}