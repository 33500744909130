

const config = {
    // apiKey: process.env.API_KEY,
    // authDomain: process.env.AUTH_DOMAIN,
    // databaseURL: process.env.DATABASE_URL,
    // projectId: process.env.PROJECT_ID,
    // storageBucket: process.env.STORAGE_BUCKET,
    // messagingSenderId: process.env.MESSAGING_SENDER_ID,
    // appId: process.env.APP_ID
    apiKey: "AIzaSyC3Cw7iibBIDx2zOFHalXDXA3tkwCoPJmw",
    authDomain: "b-cont.firebaseapp.com",
    databaseURL: "https://b-cont.firebaseio.com",
    projectId: "b-cont",
    storageBucket: "b-cont.appspot.com",
    messagingSenderId: "328281742985",
    appId: "1:328281742985:web:193ec6e7cf1afdc0"
};
firebase.initializeApp(config)