import { STRING, NUMBER, LIST, CURRENCY, REF } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, DISABLED } from "../../../utils/constants/inputAttrs";
import { BUDGET_ITEMS, CONTRACTS } from "../../dictionary/routeNames";
import { PROJECT_BUDGET } from "../../dictionary/viewOptions";

import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";
import { BudgetItemDoc } from "./budgetitemClass";
import { sortDocsBy } from "../../../utils/js";
import { ContractDoc } from "../contracts/contractClass";
import { db } from "../../../index";

export class ProjectBudgetDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ProjectBudgetDoc,
        });
    };

    static get meta() {
        return {
            id: PROJECT_BUDGET,
            routes: {
                collection: "projects/:projectID/projectBudget",
                doc: "projects/:projectID/projectBudget/:projectBudgetID",
            },
        }
    }
    static get headers() {
        return {
            budgetRef: {
                label: "סעיף תקציבי",
                type: LIST,
                defaultValue: "",
                options: {
                    data: dataStore[BUDGET_ITEMS].data.sort(sortDocsBy("index", { type: NUMBER })),
                    load: () => {
                        // dataStore[BUDGET_CHAPTERS].data.forEach(doc => {
                        //     doc.listenToChildren(doc.docData.ref)
                        // })
                    },
                    model: BudgetItemDoc,
                    addOnFlow: false,
                    formHeaders: ["title", "number"],
                    params: ["number", "title"]
                }
            },
            contractRef: {
                label: "שיוך לחוזה",
                type: REF,
                defaultValue: "",
                options: {
                    param: "title",
                    path: "projects/:projectID/contracts/:contractID",
                }
            },
            title: { label: "שם הסעיף", defaultValue: "--סעיף תקציב חדש--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            sum: { label: "סכום", defaultValue: 0, type: CURRENCY },
            description: { label: "תיאור והערות", defaultValue: "--ללא תיאור--", type: STRING },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
        }
    }
}