import m from "mithril"
import { ReportLayout } from "./reports/ReportLayout"

export const pagePrinter = node => {
    const getData = () => {
        const data = []
        for (let i = 0; i < 100; i++) {
            data.push({ label: String.fromCharCode(i), value: i })
        }
        return data
    }
    return {
        view: vnode => {
            return m(ReportLayout,
                m(".printPage",
                    m("table",
                        m("thead",
                            m("tr",
                                m("th", "key"),
                                m("th", "value"),
                            )
                        ),
                        m("tbody",
                            getData().map(row => {
                                return m("tr",
                                    m("td", row.label),
                                    m("td", row.value)
                                )
                            })
                        )
                    )
                )
            )
        }
    }
}