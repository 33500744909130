import m from 'mithril';
import { editValue, getDisplayValue, validateField } from '../../../data/utils/inputValidation';
import { SWITCH, LIST, NUMBER, PERCENT, CURRENCY, CHECK_BOOL, REF, OBJECT, MULTICHOICE_LIST, STRING } from '../../../utils/constants/types';
import { Icon } from '../../components/icon/Icon';
import { IconError } from '../iconError/IconError';
import { Prompt } from '../prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { InputField } from './InputField';
import { FormEditDoc } from '../prompt/FormEditDoc';
import { dataStore } from '../../../data/store/dataStore';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { isDocMatchTerm, isListMatchTerm } from '../../../data/utils/search';
import { objType, preventDefaults, uuid } from '../../../utils/js';
import { Calculator } from '../../pages/calculator/Calculator';
import { DISABLED, HIDDEN } from '../../../utils/constants/inputAttrs';
import { isFieldVisible, isUserAllow } from '../../../data/utils/permission';
import { CheckCell } from '../checkCell/CheckCell';
import { DisplayCell } from './DisplayCell';
import { UPDATE } from '../../../data/store/permissionStore';
import { O_ARRAY, O_FUNCTION, O_OBJECT, O_STRING } from '../../../utils/constants/objTypes';
import { db } from '../../../index';


export const InputCell = node => {

    const isAvailableOpt = opt => {
        const options = node.state.field.options
        const currentStatus = options.find(option => option.id === node.state.currentValue)
        if (currentStatus && currentStatus.next) {
            if (currentStatus.next.includes(opt.id)) return true
            else return false
        }
        return true
    }

    const getOptionStyle = opt => {
        let style = ""
        if (opt && opt.color) {
            if (opt.color.text) style += `color:${opt.color.text};`
            if (opt.color.bg) style += `background-color:${opt.color.bg};`
        }
        return style
    }

    const showEditForm = () => {
        const { field, value } = node.attrs
        const docModel = field.options.model
        const doc = dataStore[docModel.meta.id].data.find(doc => doc.docData.ref === value)
        const headers = field.options.formHeaders
        if (doc) {
            node.state.prompt = {
                title: "טופס",
                class: "full-width",
                form: () => {
                    return m(FormEditDoc, { doc, headers, parent: node })
                }
            }
        }
    }
    const promptObjectField = () => {
        const { header, field: _field, doc } = node.attrs
        // const field = _field ? _field : doc.headers[header]
        const value = doc.docData[header]
        const isObject = objType(value) === O_OBJECT
        const isArray = objType(value) === O_ARRAY && objType(value[0] !== O_OBJECT)
        const isArrayOfObjects = objType(value) === O_ARRAY && objType(value[0] === O_OBJECT)
        // const schema = field.options.schema
        const parseArray = (val) => {
            return val.map((el, ind) => {
                return { key: ind, value: el, type: STRING }
            })
        }
        const parseObj = (val) => {
            return Object.entries(val).map(([key, value]) => {
                return { key, value, type: STRING }
            })
        }
        const groups = []
        if (isArrayOfObjects) { // [{}]
            // throw "invalid type"
            value.forEach((group, ind) => {
                const dataObj = Object.entries(value).map(([key, value]) => {
                    return { key, value, type: STRING }
                })
                groups.push({ dataObj, label: ind })
            })
        } else if (isArray) groups[0] = { dataObj: parseArray(value) }// []
        else if (isObject) groups[0] = { dataObj: parseObj(value) } // {}

        node.state.prompt = {
            title: "עריכת שדה אובייקט",
            form: () => {
                return m(".",
                    groups.map((grp) => {
                        return m("fieldset",
                            m("lagend", grp.label || ""),
                            grp.dataObj.map(({ key, value }) => {
                                return m(".row",
                                    m(".input__label", key),
                                    m("input.input__field", {
                                        oncreate: el => el.dom.value = value,
                                        oninput: e => {
                                            if (isArray) {
                                                doc.edit({ [header]: [e.target.value] })
                                            }
                                        }
                                    })
                                )
                            })
                        )
                    })
                )
            }
        }
        // }
    }

    const addNewRecord = (_field) => {
        const { doc } = node.attrs
        const docModel = _field.options.model
        let addDataOptions = {}
        if (objType(_field.options.addDataOptions) === O_FUNCTION) addDataOptions = _field.options.addDataOptions(doc)
        else if (objType(_field.options.addDataOptions) === O_OBJECT) addDataOptions = _field.options.addDataOptions
        else throw "must provide addDataOptions {} || Function to create new record on flow"
        const newDoc = new docModel(addDataOptions)
        let headers = ["title"]
        if (_field.options.addDataHeaders) headers = _field.options.addDataHeaders
        else if (docModel.meta.basicHeaders) headers = docModel.meta.basicHeaders
        node.state.prompt = {
            title: "",
            form: () => m(FormEditDoc, { doc: newDoc, parent: node, headers })
        }
    }

    const openCalculator = (e) => {
        preventDefaults(e);
        let isEditable = true
        if (node.state.field.props) {
            node.state.field.props.forEach(prop => {
                const [[key, value]] = Object.entries(prop)
                if (key === DISABLED && value == true) {
                    isEditable = false
                }
            })
        }
        node.state.prompt = {
            title: "מחשבון",
            form: () => {
                return [
                    m(Calculator, { parent: node }),
                    node.state.tempCalc && m(".", { style: "font-size:1.4rem;border-top: 1px solid gray;" }, getDisplayValue(node.state.tempCalc, NUMBER, { isFloat: true })),
                    (node.state.tempCalc && isEditable) && m("button.button", {
                        style: "max-width:auto;",
                        onclick: e => {
                            const { header, doc } = node.attrs
                            const value = node.state.tempCalc;
                            if (value && !isNaN(parseFloat(value) && value !== 0)) {
                                editValue(node.state.oldValue, value, header, doc)
                            }
                            node.state.prompt = false
                        }
                    }, "השתמש בתוצאה כערך")
                ]
            }
        }
    }

    const togglePendingMenu = () => node.state.pendingMode = !node.state.pendingMode
    const isPendingMode = () => node.state.pendingMode

    const searchItem = e => {
        e.preventDefault();
        e.stopPropagation();
        node.state.searchTerm = e.target.value
    }
    const isDisabledField = (doc, field) => {
        // [{a:"a"}].entries()
        if (field.props) {
            return field.props.some(prop => {
                // const key = Object.keys(prop)[0];
                // const val = prop[key];
                const [[key, val]] = Object.entries(prop)
                if (key === DISABLED) {
                    if (objType(val) === O_FUNCTION) {
                        const isDisabled = val(doc)
                        return isDisabled == true
                    } else {
                        return val == true
                    }
                }
            })
        }
    }
    const getListData = options => {
        const { doc } = node.attrs
        if (options.data) return options.data
        else if (options.getData) {
            return options.getData(doc)
        }

    }
    const isHidden = (field, doc) => {
        if (field.props) {
            const hiddenProp = field.props.find(el => Object.keys(el)[0] === HIDDEN)
            if (hiddenProp) {
                const [[_key, val]] = Object.entries(hiddenProp)
                if (objType(val) === O_FUNCTION) return val(doc) === true
                return val === true
            }
        }
    }

    return {
        listOpen: false,
        oldValue: "",
        error: false,
        prompt: false,
        searchTerm: "",
        currentValue: "",
        oninit: vnode => {
            const { doc, header, field: _field, id: _id } = vnode.attrs
            vnode.state.currentValue = node.attrs.doc.docData[node.attrs.header]
            const field = _field ? _field : doc.headers[header] //TESTME: #DOC_HEADERS
            if (field.options && field.options.load) field.options.load(doc)
            vnode.state.displayValue = "" + getDisplayValue(doc.docData[header], field.type, field.options, doc.docData)
            vnode.state.id = _id ? _id : `${doc.docData.ref}/${header}` || uuid()
            vnode.state.field = field

            const { valid, errorMsgs } = validateField(doc, header, doc.docData[header]);
            if (!valid) vnode.state.error = errorMsgs.join("\n")
        },
        onupdate: vnode => {
            const { doc, header } = vnode.attrs
            const { field } = vnode.state
            vnode.state.displayValue = "" + getDisplayValue(doc.docData[header], field.type, field.options, doc.docData)
        },
        view: vnode => {
            const { header, editCell, doc, parent, index, options = {}, class: classStyle, cellStyle = "" } = vnode.attrs
            const { id, displayValue, field } = vnode.state
            const Model = doc.model
            const value = doc.docData[header]
            if (!field || value === undefined || !isFieldVisible(field, doc, header) || isHidden(field, doc)) return
            const isUserAllowEdit = doc.isNew || (isUserAllow(Model.meta.id, UPDATE, Object.assign({}, doc, { docChanges: { [header]: "_ANY_" } })) && !isDisabledField(doc, field))
            const { ignoreLabel, editToggle = true, ignoreCalculator = false, save = null } = options
            const { search = true, tooltip = true, link = false } = Object.assign({}, options, field.fieldOptions)
            if (!isUserAllowEdit) {
                return m(DisplayCell, { field, doc, header, value, options, classStyle, cellStyle })
            }
            return m(`.cell [data-tooltip]`, { class: classStyle || "", style: cellStyle },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || "הוספה של רשומה חדשה"),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                ),
                tooltip && m(Tooltip, { text: `${field.label}:\n ${displayValue}` }),
                m(IconError, { error: vnode.state.error }),
                doc.hasChanges && doc.hasChanges(header) && m(".cell__pending", {
                    onclick: e => {
                        preventDefaults(e)
                        togglePendingMenu()
                    }
                },
                    isPendingMode() && m(".actions[tabindex=10000]",
                        m(Icon, { icon: "icon-x", action: e => doc.abortChanges(header) }),
                    ),
                    "x"
                ),
                field.type === SWITCH ?
                    m(".cell__input input input--select",
                        m(".input__field cell__field cell__field--select", {
                            onclick: e => vnode.state.listOpen = !vnode.state.listOpen,
                            onfocus: e => vnode.state.oldValue = value,
                            // onblur: e => vnode.state.listOpen = !vnode.state.listOpen,

                            style: getOptionStyle(field.options.find(opt => opt.id === value)),
                            tabindex: index + 1,
                            id
                        },
                            m(Icon, { icon: `icon-triangle-${vnode.state.listOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                            vnode.state.listOpen && m(".cell__list",
                                search && m(".cell__search", { onclick: e => preventDefaults(e) },
                                    m("input[autofocus][placeholder='חיפוש']", { value: vnode.state.searchTerm, oninput: e => searchItem(e) }),
                                    m(Icon, { icon: "icon-search" })
                                ),
                                field.options.map(opt => {
                                    if (!isAvailableOpt(opt)) return
                                    if (!isListMatchTerm(vnode, opt.text)) return
                                    return m(".cell__list-item", {
                                        style: getOptionStyle(opt),
                                        onclick: e => {
                                            editValue(vnode.state.oldValue, opt.id, header, doc)
                                            vnode.state.currentValue = opt.id
                                            if (vnode.attrs.settings && vnode.attrs.settings.autoSave && !doc.isNew) {
                                                doc.saveOne(header, opt.id);
                                                vnode.state.snackbar = { msg: "data saved!" }
                                            }
                                        }
                                    }, opt.text)
                                })
                            ),
                            // displayValue //FIXME:
                            getDisplayValue(vnode.state.currentValue, SWITCH, field.options, doc.docData)
                        ),
                        !ignoreLabel && m("label.input__label cell__label", { for: id, onclick: e => parent.state.editCell = id }, field.label)
                    )
                    :
                    field.type === LIST ?
                        m(".cell__input input input--select",
                            m(".input__field cell__field cell__field--select", {
                                "data-list": "list",
                                onclick: e => vnode.state.listOpen = !vnode.state.listOpen,
                                onfocus: e => vnode.state.oldValue = value,
                                tabindex: index + 1,
                                id
                            },
                                m(Icon, { icon: `icon-triangle-${vnode.state.listOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                                vnode.state.listOpen && m(".cell__list",
                                    getListData(field.options).length > 0 && m(".cell__search", { onclick: e => preventDefaults(e) },
                                        m("input[autofocus][placeholder='חיפוש']", { value: vnode.state.searchTerm, oninput: e => searchItem(e) }),
                                        m(Icon, { icon: "icon-search" })
                                    ),
                                    getListData(field.options).map(opt => {
                                        if (!isDocMatchTerm(vnode, opt, field.options.params || ["title"])) return
                                        return m(".cell__list-item", {
                                            onclick: e => {
                                                editValue(vnode.state.oldValue, opt.docData.ref, header, doc)
                                                vnode.state.currentValue = opt.docData.ref
                                            }
                                        },
                                            getDisplayValue(opt.docData.ref, LIST, field.options, opt.docData)//FIXME:
                                        )
                                    }),
                                    field.options.addOnFlow && m(".cell__list-item .cell__list-item--add", { onclick: e => addNewRecord(field) }, "+ הוסף חדש")
                                ),
                                displayValue,
                                value !== null && m(Icon, {
                                    icon: `icon-x`, class: "icon--remove", action: e => {
                                        e.stopPropagation()
                                        editValue(vnode.state.oldValue, null, header, doc)
                                    }
                                }),
                            ),
                            !ignoreLabel && m("label.input__label cell__label link", {
                                for: id,
                                onclick: e => {
                                    parent.state.editCell = id;
                                    showEditForm()
                                }
                            }, field.label)
                        )
                        :
                        field.type === MULTICHOICE_LIST ?
                            m(".cell__input input input--select ",
                                m(".input__field cell__field cell__field--select", {
                                    "data-list": "list",
                                    onclick: e => vnode.state.listOpen = !vnode.state.listOpen,
                                    onfocus: e => vnode.state.oldValue = value,
                                    tabindex: index + 1,
                                    id
                                },
                                    m(Icon, { icon: `icon-triangle-${vnode.state.listOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                                    vnode.state.listOpen && m(".cell__list",
                                        field.options.getData(doc).length > 0 && m(".cell__search", { onclick: e => preventDefaults(e) },
                                            m("input[autofocus][placeholder='חיפוש']", { value: vnode.state.searchTerm, oninput: e => searchItem(e) }),
                                            m(Icon, { icon: "icon-search" })
                                        ),
                                        field.options.getData(doc)
                                            .map(opt => {
                                                const isSelected = value.indexOf(opt.docData.ref) > -1
                                                if (!isDocMatchTerm(vnode, opt, field.options.params || ["title"])) return
                                                return m(".cell__list-item", {
                                                    class: isSelected ? "cell__list-item--selected" : "",
                                                    onclick: e => {
                                                        if (!isSelected) { //add
                                                            const newValue = [...value, opt.docData.ref].filter(el => el)
                                                            editValue(vnode.state.oldValue, newValue, header, doc)
                                                            vnode.state.currentValue = newValue
                                                        }
                                                    }
                                                },
                                                    getDisplayValue(opt.docData.ref, REF, field.options)
                                                )
                                            }),
                                        field.options.addOnFlow && m(".cell__list-item .cell__list-item--add", { onclick: e => addNewRecord(field) }, "+ הוסף חדש")
                                    ),
                                    m(".multiple",
                                        vnode.state.currentValue.map(ref => {
                                            if (!ref || ref == "") return
                                            return m(".multiple__item",
                                                getDisplayValue(ref, REF, field.options),
                                                m(Icon, {
                                                    icon: `icon-x`,
                                                    class: "icon--remove",
                                                    action: e => {
                                                        preventDefaults(e)
                                                        const newValue = value.filter(el => el && el !== ref)
                                                        editValue(vnode.state.oldValue, newValue, header, doc)
                                                        vnode.state.currentValue = newValue
                                                    }
                                                })
                                            )
                                        })
                                    )
                                ),
                                !ignoreLabel && m("label.input__label cell__label link", {
                                    for: id,
                                    onclick: e => {
                                        parent.state.editCell = id;
                                        showEditForm()
                                    }
                                }, field.label)
                            )
                            :
                            field.type === CHECK_BOOL ?
                                m(CheckCell, {
                                    checked: doc.docData[header] == true,
                                    setCheck: () => editValue(value == false ? false : true, value == false ? true : false, header, doc),
                                    label: ignoreLabel ? false : field.label
                                })
                                :
                                field.type === OBJECT ?
                                    m(".cell__input input", {
                                        onclick: e => promptObjectField()
                                    },
                                        m(".input__field", displayValue),
                                        m(".input__label", field.label)
                                    )
                                    :
                                    field.type === REF ?
                                        m(".cell__input input",
                                            m(".input__field", displayValue),
                                            m(".input__label", field.label)
                                        )
                                        :
                                        m(".cell__input input",
                                            (editCell === id || editToggle == false) ?
                                                m(InputField, {
                                                    parent: vnode,
                                                    value, field, id, index, header, doc,
                                                    options: {
                                                        autoSave: vnode.attrs.settings && vnode.attrs.settings.autoSave
                                                    },
                                                    error: vnode.state.error,
                                                    class: "cell__field",
                                                    setError: (err) => vnode.state.error = err
                                                })
                                                :
                                                m(".input__field input__field--text cell__field--text", {
                                                    class: vnode.state.error ? "input__field--error" : "",
                                                    tabindex: index + 1,
                                                    onfocus: e => parent.state.editCell = id,
                                                    onclick: e => parent.state.editCell = id
                                                },
                                                    displayValue,
                                                ),
                                            link && m("a", { href: "", target: "_blank" }),
                                            !ignoreLabel && m(`label.input__label cell__label`, {
                                                class: vnode.state.error ? "input__label--error" : "",
                                                for: id,
                                                onclick: e => {
                                                    // console.log(field)
                                                    parent.state.editCell = id;
                                                    if (vnode.state.error) {
                                                        vnode.state.showError = !vnode.state.showError
                                                    }
                                                },
                                                "data-error": vnode.state.error
                                            }, field.label),
                                            !ignoreCalculator && [NUMBER, PERCENT, CURRENCY].includes(field.type) && m(Icon, { icon: "icon-dots-vertical", class: "menuButton", action: e => openCalculator(e) })
                                        )
            )
        }
    }
}



// const navigateList = (e) => {
//     const rows = vnode.dom.querySelectorAll('.actionList__row');
//     if (rows.length == 0) return;
//     if (e.code == 'ArrowDown' || e.code == 'ArrowUp') {
//         rows.forEach(row => row.dataset.active = false)
//         if (vnode.state.listInd == rows.length || vnode.state.listInd < 0) vnode.state.listInd = 0;
//         const row = rows[vnode.state.listInd];
//         row.dataset.active = true;
//         switch (e.code) {
//             case 'ArrowDown':
//                 vnode.state.listInd++;
//                 break;
//             case 'ArrowUp':
//                 vnode.state.listInd--;
//                 break;
//         }
//     } else if (e.code == 'Enter') {
//         let activeRow = vnode.dom.querySelector('.actionList__row[data-active="true"]');
//         if (!activeRow) return;
//         const id = activeRow;
//         const value = activeRow.querySelector('.actionList__item').innerText
//         setSelected([id, value])
//     }
// }