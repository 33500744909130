import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';

// DATA
import { USERS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { isSystemMode } from '../../../data/utils/viewUtils';
import { auth } from '../../../index';
import { detectNonAdminUser } from '../../../data/utils/utils';
import { settingsStore } from '../../../data/store/settingsStore';
import { userRoles } from '../../../utils/constants/userRoles';
import { Table } from '../../commons/table/Table';
import { DocModel } from '../../../data/store/docModel';

// FUNCTIONS


export const Users = (node) => {

    return {
        snackbar: false,
        prompt: false,
        headers: [
            "displayName",
            "email",
            // "phone",
            //  "address",
            "role",
            "status"
        ],
        load: true,
        filters: [{ id: "role", param: "system" }],
        settings: {
            navOnAdd: true
        },
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            vnode.state.snackbar = { msg: "טוען משתמשים..." }
            const { alreadyListen, unsubscribe } = dataStore[USERS].listen();
            const currentUser = auth.currentUser
            if (alreadyListen && currentUser !== null) {
                detectNonAdminUser()
                vnode.state.load = false
            } else {
                setTimeout(() => {
                    detectNonAdminUser()
                    vnode.state.load = false
                    m.redraw()
                }, 3000);
            }
            if (window.innerWidth < 1000 && window.innerWidth >= 700) {
                vnode.state.headers = ["displayName", "role", "email", "phone"]
            } else if (window.innerWidth < 700) {
                vnode.state.headers = ["displayName", "role"]
            }
            m.redraw();
        },
        view: (vnode) => {
            return (
                m(PageLayout, { class: "users", title: "המשתמשים שלי" },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    m(Table, {
                        title: "טבלת משתמשי מערכת",
                        modelID: USERS,
                        colRef: USERS,
                        doc: DocModel,
                        tableHeaders: vnode.state.headers,
                        filters: vnode.state.filters,
                        actions: { onRow: "redirect", onAdd: "redirect" },
                        searchOptions: ["displayName", "title", "email", "phone"],
                        filterMap: [{
                            label: "תפקיד",
                            header: "role",
                            options: userRoles
                        }]
                    })
                )
            )
        }
    }
}