import m from 'mithril'

import { Link } from './Link';
import { getResourceID, toggleTimeout } from '../../../data/utils/utils';
import { NavMainSidebar } from '../../../data/navigation/mainSidebar';
// import { store } from '../../../data/models/store';
import { PROJECTS, CONTRACTS, USERS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { CREATE, permissionTree, READ } from '../../../data/store/permissionStore';
import { isSuperAdmin, isUserAllow } from '../../../data/utils/permission';


export const Sidebar = (node) => {

    // const isRestrictAction = (elID, type) => {
    //     if (!type) return false
    //     const currentUser = store.state.currentTab;
    //     return store.userTypeView[currentUser].permissions[elID] &&
    //         store.userTypeView[currentUser].permissions[elID][type] === false
    // }

    return {
        oninit: (vnode) => {
            const fullPath = m.route.get();
            const path = m.route.get().replace(/\?.*$/, "");
            const resource = getResourceID(path)
            let hasMatch = false;
            NavMainSidebar.forEach(el => {
                if (el.seperetor) return
                if (vnode.attrs.module && vnode.attrs.module === el.id) {
                    el.isActive = true
                    hasMatch = true
                } else if (fullPath === el.pagePath) {
                    el.isActive = true
                    hasMatch = true
                }
                // else if (path.startsWith(el.pagePath)) {
                //     el.isActive = true
                //     hasMatch = true
                // }
                else el.isActive = false
                if (el.sub) {
                    el.sub.forEach(sub => {
                        if (sub.pagePath === path) {
                            sub.isActive = true
                            hasMatch = true
                        }
                        else sub.isActive = false
                    })
                }
            })
            if (!hasMatch) {
                const globalFound = NavMainSidebar.find(el => el.id === resource)
                if (globalFound) globalFound.isActive = true;
            }
        },


        view: (vnode) => {
            return (
                m('aside.sidebar.fade-in-left',
                    m('nav.sidebar__nav', [
                        dataStore[USERS].current && [...NavMainSidebar].map((el, ind) => {
                            if (!isUserAllow(el.modelID, READ, el.doc)) return
                            if (el.adminOnly && !isSuperAdmin()) return
                            return [
                                el.seperetor ?
                                    m('.sidebar__hr')
                                    : // else
                                    m(Link, { link: el, ind }),
                                // el.sub && (el.isActive || el.sub.findIndex(sub => sub.isActive) !== -1)
                                //     ?
                                //     m('.sidebar__sub', [
                                //         [...el.sub].filter(sub => !isRestrictAction(el.id, sub.action)).map((el, ind) => m(Link, { isSub: true, link: el, ind }))
                                //     ])
                                //     : null
                            ]
                        })
                    ])
                )
            )
        }
    }
}
