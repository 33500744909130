import { O_STRING, O_DATE, O_NUMBER, O_FUNCTION } from './constants/objTypes';
import { REG_IS_DATE } from './constants/regex';
import { DATE, NUMBER, STRING } from './constants/types';

//FORM sumbiting handler
export const getFormValues = (form) => {
    let data = {};
    let elements = form.elements;
    for (let i in elements) {
        let el = elements[i]
        if (el.name && el.value) {
            data[el.name] = el.value || ""
        }
    }
    if (data == {}) return null;
    return data;
}


//DOM travarse
export const closestByClass = (el, cls) => {
    while (!el.classList.contains(cls)) {
        el = el.parentNode;
        if (!el) {
            return null;
        }
    }
    return el;
}

export const copyToClipboard = async content => {
    return await window.navigator.clipboard.writeText(content)
}


//Objects
export const mapToObj = (mapObj) => {
    let obj = {};
    mapObj.forEach(el => obj[el.id] = el);
    return obj;
}

export function findObjParents(obj, key) {
    for (let i = 0; i < Object.keys(obj).length; i++) {
        const prop = Object.keys(obj)[i]
        const jsonString = JSON.stringify(obj[prop])
        const containsKey = jsonString.includes(key)
        if (containsKey) {
            if (jsonString.split(key).length > 2) return
            return [].concat(prop, findObjParents(obj[prop], key)).filter(el => el)
        }
        findObjParents(obj[prop], key)
    }
}


//Math
export function asNumber(value) {
    if (isNaN(parseFloat(value))) return 0
    else return parseFloat(value)
}

export const uuid = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

export const randomIndex = (index) => Math.floor(Math.random() * 100000) + index



export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === O_FUNCTION;
}

export const preventDefaults = e => {
    e.preventDefault()
    e.stopPropagation();
}


//Dates 
export const distDays = (d1, d2) => parseInt(parseInt(new Date(d2).setHours(0, 0, 0, 0) - new Date(d1).setHours(0, 0, 0, 0)) / (24 * 3600 * 1000))
export const dateAfter = (d1, d2) => distDays(d2, d1) > 0
export const dateBefore = (d1, d2) => distDays(d2, d1) < 0
export const dateEq = (d1, d2) => distDays(d2, d1) === 0
export const isDue = date => distDays(new Date(), date) < 0

export const calcPaymentDelay = (dateStart, paymentDelay = "30") => {
    const [monthsDays, days = 0] = parseFloat(paymentDelay).toString().split(".")
    const months = parseInt(parseFloat(monthsDays) / 30)
    const date = addMonths(months, dateStart)
    return addDays(date, days)
}

export const monthDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
}
export const addMonths = (monthsToAdd, d = new Date()) => new Date(d.getFullYear(), d.getMonth() + parseInt(monthsToAdd), d.getDate());
export const dateDiffDays = (d1, d2) => {
    if (!d2) d2 = new Date();
    const dist = distDays(d1, d2)
    if (dist === 0) {
        return 'היום'
    } else if (dist > 0) {
        return `נותר עוד ${Math.abs(dist)} ימים`
    } else {
        return `עברו ${Math.abs(dist)} ימים`
    }
}



export const diffDays = (date1, date2) => {
    return distDays(date1, date2) // alias functin.... THINK: if has diffents using parseInt()
    // const d1 = +date1;
    // const d2 = date2 ? +date2 : +new Date();
    // const diff = d2 - d1;
    // return diff / (24 * 3600 * 1000); //24 hours * 3600 milliseconds in hour * 1000 ms in minute = (diff on ms) / ms in day = days 
}

export const addDays = (date, d) => {
    const newDate = new Date(date);
    newDate.setTime(date.getTime() + (parseInt(d) * 24 * 3600 * 1000));
    return newDate;
}

export const subtractDays = (date, d) => {
    const newDate = new Date(date);
    newDate.setTime(date.getTime() - (parseInt(d) * 24 * 3600 * 1000));
    return newDate;
}

export const addHours = (date, h) => {
    const newDate = new Date(date);
    newDate.setTime(date.getTime() + (parseInt(h) * 1000 * 60 * 60));
    return newDate;
}

export const formatTime = (date) => {
    let h = date.getHours();
    if (h < 10) h = "0" + h;
    let m = date.getMinutes();
    if (m < 10) m = "0" + m;
    return h + ":" + m;
}

export const formatDate = (date) => {
    const y = date.getFullYear();
    let m = date.getMonth(); m++;
    if (Number(m).toString() < 10) m = "0" + m
    let d = date.getDate();
    if (Number(d).toString() < 10) d = "0" + d
    const output = y + "-" + m + "-" + d;
    return output;
}

export const formatDateDisplay = (date, options = {}) => {
    if (isNaN(date.getMilliseconds())) return "----/--/--"

    const y = date.getFullYear();
    let m = date.getMonth(); m++;
    if (Number(m).toString() < 10) m = "0" + m
    let d = date.getDate();
    if (Number(d).toString() < 10) d = "0" + d

    const outputFormat = options.outputFormat || "dd/mm/yyyy"
    let output
    if (outputFormat === "dd/mm/yyyy") {
        output = d + "/" + m + "/" + y;
    } else if (outputFormat === "dd/mm/yy") {
        output = d + "/" + m + "/" + y.toString().substring(2);
    } else if (outputFormat === "dd=>mm") {
        const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"]
        const monthName = months[date.getMonth() % 12]
        output = d + " ל" + monthName
    }
    if (options.useHours) {
        let h = date.getHours();
        let mm = date.getMinutes();
        if (h < 10) h = `0${h}`
        if (mm < 10) mm = `0${mm}`
        output += ` ${h}:${mm}`
    }
    return output;
}

//#region 
export const formatDateDisplay2 = (date, displayType) => {
    const y = date.getFullYear();
    let m = date.getMonth(); m++;
    if (Number(m).toString() < 10) m = "0" + m
    let d = date.getDate();
    if (Number(d).toString() < 10) d = "0" + d
    if (isNaN(y) || isNaN(m) || isNaN(d)) return "--ללא--";

    const today = new Date();
    const dist = distDays(today, new Date(date));
    const absDist = Math.abs(dist)
    let output = ""
    if (absDist < 7) {
        if (absDist === 0) output = "היום"
        else {
            switch (dist) {
                case 1: output = "מחר"
                    break;
                case 2: output = "+יומיים"
                    break;
                case -1: output = "אתמול"
                    break;
                case -2: output = "שלשום"
                    break;
                default:
                    if (dist < 0) {
                        output = `לפני ${absDist} ימים`
                    } else {
                        output = `${dist} ימים`
                    }
            }
        }
    } else {
        if (displayType === "datesOnly") {
            output += d + "/" + m + "/" + y.toString().substring(2);
        } else if (displayType === "weeks") {
            const weeks = Math.floor(absDist / 7)
            const days = absDist % 7;
            if (dist < 0) output += "לפני "
            output += `${weeks}ש`;
            if (days > 0) {
                output += ` (+${days})`
            }
        } else {
            if (dist < 0) output += "לפני "
            else output += "עוד "
            output += `${absDist} יום`
        }
    }
    if (displayType === "useHours") {
        const h = date.getHours();
        let mm = date.getMinutes();
        if (mm.toString() < 10) mm = "0" + mm
        output += ` ${h}:${mm}`
    }
    return output;
}

export function buildMonthText(date = new Date()) {
    const month = date.getMonth();
    const year = date.getFullYear();
    let monthText = (month + 1).toString();
    if (monthText.length === 1) monthText = "0" + monthText;
    // console.log("monthText = ", monthText + " " + year)
    return monthText + " " + year;
}

export const getDefaultMonth = (lastMonth) => {
    const [_month, _year] = lastMonth.split(" ");
    let defaultYear = parseInt(_year)
    let defaultMonth = parseInt(_month) - 1
    return { defaultYear, defaultMonth }
}

export const textToDate = (monthText, day = 1) => {
    const [_month, _year] = monthText.split(" ");
    return new Date(parseInt(_year), parseInt(_month) - 1, day)
}

//#endregion

export const timestamp = (date) => {
    let y = date.getFullYear();
    let m = date.getMonth(); m++;
    let d = date.getDate();
    let time = formatTime(date)

    y = y.toString().substring(2, 4)
    if (Number(m).toString() < 10) m = "0" + m
    if (Number(d).toString() < 10) d = "0" + d

    const output = d + "/" + m + "/" + y + " " + time;
    return output;
}

export const sortBy = (param, order) => {
    const myOrder = order || 'asc'
    if (myOrder === 'desc') {
        if (param) return (a, b) => b[param] - a[param]
        else return (a, b) => b - a
    } else {
        if (param) return (a, b) => a[param] - b[param]
        else return (a, b) => a - b
    }
}

//TODO:
// https://en.proft.me/2015/11/14/sorting-array-objects-number-string-date-javascrip/#:~:text=sort(function(a%2C%20b)%20%7B%20var%20dateA%20%3D,converted%20to%20numeric%20representations%20first.
export const sortDocsBy = (param, { order = "asc", type = NUMBER } = {}) => {
    if (order === "desc") {
        switch (type) {
            case STRING: return (a, b) => (b.docData[param] > a.docData[param]) ? 1 : -1
            case DATE: return (a, b) => new Date(b.docData[param]) - new Date(a.docData[param])
            case NUMBER: return (a, b) => parseFloat(b.docData[param]) - parseFloat(a.docData[param])
        }
    } else { //asc
        switch (type) {
            case STRING: return (a, b) => (a.docData[param] > b.docData[param]) ? 1 : -1
            case DATE: return (a, b) => new Date(a.docData[param]) - new Date(b.docData[param])
            case NUMBER: return (a, b) => parseFloat(a.docData[param]) - parseFloat(b.docData[param])
        }
    }
}
export const getObjValue = (object, notation) => notation.split('.').reduce((a, b) => a[b], object)

export const sortEntries = (param, { order = "asc", type = NUMBER } = {}) => {
    if (order === "desc") {
        switch (type) {
            case STRING: return ([_aKey, aDoc], [_bKey, bDoc]) => (bDoc[param] > aDoc[param]) ? 1 : -1
            case DATE: return ([_aKey, aDoc], [_bKey, bDoc]) => new Date(bDoc[param]) - new Date(aDoc[param])
            case NUMBER: return ([_aKey, aDoc], [_bKey, bDoc]) => parseFloat(bDoc[param]) - parseFloat(aDoc[param])
        }
    } else { //asc
        switch (type) {
            case STRING: return ([_aKey, aDoc], [_bKey, bDoc]) => (aDoc[param] > bDoc[param]) ? 1 : -1
            case DATE: return ([_aKey, aDoc], [_bKey, bDoc]) => new Date(aDoc[param]) - new Date(bDoc[param])
            case NUMBER: return ([_aKey, aDoc], [_bKey, bDoc]) => parseFloat(aDoc[param]) - parseFloat(bDoc[param])
        }
    }
}
export const sortDocsEntries = (param, { order = "asc", type = NUMBER } = {}) => {
    if (order === "desc") {
        switch (type) {
            case STRING: return ([aKey, aDoc], [bKey, bDoc]) => (bDoc.docData[param] > aDoc.docData[param]) ? 1 : -1
            case DATE: return ([aKey, aDoc], [bKey, bDoc]) => new Date(bDoc.docData[param]) - new Date(aDoc.docData[param])
            case NUMBER: return ([aKey, aDoc], [bKey, bDoc]) => parseFloat(bDoc.docData[param]) - parseFloat(aDoc.docData[param])
        }
    } else { //asc
        switch (type) {
            case STRING: return ([aKey, aDoc], [bKey, bDoc]) => (aDoc.docData[param] > bDoc.docData[param]) ? 1 : -1
            case DATE: return ([aKey, aDoc], [bKey, bDoc]) => new Date(aDoc.docData[param]) - new Date(bDoc.docData[param])
            case NUMBER: return ([aKey, aDoc], [bKey, bDoc]) => parseFloat(aDoc.docData[param]) - parseFloat(bDoc.docData[param])
        }
    }
}


export const arrToList = (arr, key = "ref", listKeys = []) => {
    const list = {}
    arr.forEach(doc => {
        const ref = doc[key];
        list[ref] = {}
        if (listKeys.length) listKeys.forEach(k => list[ref][k] = doc[k])
        else list[ref] = doc;
    })
    return list
}

/**
 * @param {[[]]} arr first row is headers and others are values 
 */
export const arrToJSON = (arr) => {
    const array = [...arr]
    const result = []
    const headers = array.shift()
    array.forEach(row => {
        const obj = {}
        headers.forEach((header, ind) => {
            if (ind <= header.length - 1) {
                obj[header] = row[ind]
            }
        })
        result.push(obj)
    })
    return result
}

export const arraySwitch = (el, ind) => {
    if (Array.isArray(el)) {
        const index = ind && ind <= el.length - 1 ? ind : 0;
        return el[index]
    } else {
        return el
    }
}

export const pathToObj = (url, templete) => {
    let base = url;
    if (base.startsWith("/app")) base = base.substring(4)
    if (url.includes("?")) {
        const [_base] = base.split("?")
        base = _base;
    };
    const params = parseQueryUrl(url);
    let docRef = templete
    if (templete) {
        const splitT = templete.substring(1).split("/");
        const splitUrl = base.substring(1).split("/");
        // console.log('using templete : ',splitT )
        // console.log('using base url : ',splitUrl)
        const replcad = splitT.map((part, ind) => {
            if (part === splitUrl[ind]) {
                // console.log('no_replace: 👍', part, " === ", splitUrl[ind])
                // console.log('no_replace: 👍', part, " === ", splitUrl[ind])
                return part;
            } else if (part.startsWith(":")) {
                // console.log('TODO:  👍', part, " should be replaced to ", params[paramID] || splitUrl[ind]);
                // if (part === ":projectID") return store[PROJECTS].current.docID
                const paramID = part.substring(1);
                docRef = docRef.replace(part, paramID)
                if (params[paramID]) {
                    //exist in queryString
                    return params[paramID]
                } else if (splitUrl[ind]) {
                    //exist in url
                    // console.log(splitUrl[ind])
                    params[part.substring(1)] = splitUrl[ind];
                    return splitUrl[ind];
                } else {
                    return "all"
                }
            } else {
                return part;
            }
        })
        base = `/` + replcad.join("/")
    }
    return { path: base, params, docRef }
}

export const parseQueryUrl = (url) => {
    const params = {};
    if (url.includes("?")) {
        const [_base, _args] = url.split("?");
        if (_args.length >= 3) {
            const parts = _args.split("&");
            parts.forEach(part => {
                if (part.includes("=")) {
                    const [k, v] = part.split("=");
                    params[k] = v;
                }
            })
        }
    }
    return params
}

export const objType = obj => {
    const toString = Object.prototype.toString;
    return toString.call(obj); // [object Object]
}

export const parseToType = (value, type = O_STRING) => {
    switch (type) {
        case O_STRING:
            if (value) return value
            else return 0
        case O_DATE:
            if (value) return new Date(value).getTime()
            else return new Date().getTime()
        case O_NUMBER:
            if (value) return Number(value)
            else return 0
        default:
            if (value) return value
            else return ''
    }
}

export const formatNumber = (input, { isFloat, digits = 2 } = {}) => {

    if (isNaN(parseFloat(input))) {
        return "0"
    }

    const isNegative = parseInt(input) < 0
    let result = Math.abs(Math.round(input));
    if (isFloat) {
        result = Math.abs(parseInt(input));
        var floatingPart = `${parseFloat(input)}`.split(".")[1] || "00";
        floatingPart = floatingPart.substring(0, digits)
    }
    result = result.toString(); //"12345678"
    const length = result.length - 1
    const split = result.split("").reverse(); //[8,7,6,5,4,3,2,1]
    const splitData = split.join("");
    const splitR = splitData.split(""); //[8,7,6,5,4,3,2,1]
    if (split.length > 3) {
        let inserts = 0;
        split.forEach((el, ind) => {
            const i = ind + 1
            if (i % 3 === 0 && ind > 0 && ind < length) {
                splitR.splice(i + inserts, 0, ",")  //[8,7,6, |","|  5,4,3,2,1]
                inserts++;
            }
        })
    }
    result = splitR.reverse().join("");
    if (isFloat) {
        // 8321=₁
        // let subscriptFloationg = floatingPart.toString().split("").map(n => String.fromCharCode(8320 + Number(n))).join("")
        // result = `${result}.${subscriptFloationg}`
        result = `${result}.${floatingPart}`
    }
    if (isNegative) {
        result = `( ${result} -)`
    }
    return result
}

/**
 * Check the element if it is text-overflow
 * @param elementId
 */
export function isTextOverflow(elementId) {
    const elem = document.getElementById(elementId);
    if (elem) {
        return (elem.offsetWidth < elem.scrollWidth);
    }
    else {
        return false;
    }
}

export function convertToNumber(number) {
    try {
        let result;
        if (typeof (number) === 'string') {
            result = parseFloat(number);
            if (result === NaN) {
                throw `number (${number}) cant be converted to number`;
            }
            return result;
        }
        return number;
    } catch (err) {
        console.error('Error at convertToNumber', err)
        return false;
    }
}

export function isOverflowY({ scrollHeight, clientHeight }) {
    return scrollHeight > clientHeight
}
export function isOverflowX({ scrollWidth, clientWidth }) {
    return scrollWidth > clientWidth;
}
export function isOverflow({ scrollWidth, clientWidth, scrollHeight, clientHeight }) {
    return scrollWidth > clientWidth || scrollHeight, clientHeight;
}