import m from "mithril"

import { getDisplayValue } from "../../../data/utils/inputValidation"
import { isDocMatchTerm } from "../../../data/utils/search"
import { LIST } from "../../../utils/constants/types"
import { preventDefaults } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"

export const LocalList = node => {

    const editLocalValue = (value) => {
        node.state.value = value

        const { doc, header, key, parent } = node.attrs
        parent.state[key || header] = value

        const { change } = doc.headers[header].options
        if (change) {
            return change(value)
        }
    }

    const removeLocalValue = (value) => {
        node.state.value = null

        const { doc, header, key, parent } = node.attrs
        parent.state[key || header] = null

        const { remove } = doc.headers[header].options
        if (remove) {
            return remove(value)
        }
    }

    const getListData = options => {
        const { doc } = node.attrs
        if (options.data) return options.data
        else if (options.getData) {
            return options.getData(doc)
        }

    }

    const searchItem = e => {
        preventDefaults(e)
        node.state.searchTerm = e.target.value
    }

    return {
        listOpen: false,
        value: "",
        searchTerm: "",
        view: vnode => {
            const { doc, header, key, class: styleClass } = vnode.attrs
            const field = doc.headers[header]
            const { options, ignoreLabel } = field
            const { value } = vnode.state
            const id = key || header
            return m(".cell__input input input--select", {
                class: styleClass ? styleClass : ""
            },
                m(".input__field cell__field cell__field--select", {
                    onclick: e => vnode.state.listOpen = !vnode.state.listOpen,
                    oninit: el => options.load(),
                },
                    m(Icon, { icon: `icon-triangle-${vnode.state.listOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                    vnode.state.listOpen && m(".cell__list",
                        getListData(field.options).length > 0 && m(".cell__search", { onclick: e => preventDefaults(e) },
                            m("input[autofocus][placeholder='חיפוש']", { value: vnode.state.searchTerm, oninput: e => searchItem(e) }),
                            m(Icon, { icon: "icon-search" })
                        ),
                        // field.options.data.map(opt => {
                        //     if (!isDocMatchTerm(vnode, opt, ["title"])) return
                        //     return m(".cell__list-item", {
                        //         onclick: e => editLocalValue(opt.docData.ref)
                        //         // }, opt.docData.title)
                        //     }, getDisplayValue(opt.docData.ref, LIST, field.options, opt.docData))
                        // }),
                        getListData(field.options).map(opt => {
                            if (!isDocMatchTerm(vnode, opt, field.options.params || ["title"])) return
                            return m(".cell__list-item", {
                                onclick: e => {
                                    editLocalValue(opt.docData.ref)
                                    vnode.state.currentValue = opt.docData.ref
                                }
                            },
                                getDisplayValue(opt.docData.ref, LIST, field.options, opt.docData)//FIXME:
                            )
                        }),
                    ),
                    getDisplayValue(value, LIST, field.options, doc.docData),
                    value !== null && m(Icon, {
                        icon: `icon-x`, class: "icon--remove", action: e => {
                            e.stopPropagation()
                            removeLocalValue(value)
                        }
                    }),
                ),
                !ignoreLabel && m("label.input__label cell__label", { for: id }, field.label)
            )
        }
    }
}
