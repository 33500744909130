import m from 'mithril'

import * as utils from '../../../data/utils/utils'
import { Icon } from '../../components/icon/Icon'
import { settingsStore } from '../../../data/store/settingsStore'
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots'
import { Snackbar } from '../../components/snackbar/Snackbar'
import { Prompt } from '../prompt/Prompt'
import { sortDocsBy } from '../../../utils/js'
import { STRING } from '../../../utils/constants/types'
import { db } from '../../../index'

export const BreadCrumbs = (node) => {

    const promptNavTable = el => {
        node.state.prompt = {
            class: "full-width",
            title: "ניווט",
            form: () => {
                return m(".splash__nav",
                    el.list
                        .sort(sortDocsBy("title", { type: STRING }))
                        .map(doc => {
                            const isActive = m.route.get().includes(doc.docData.ref)
                            return m(".", {
                                key: doc.docData.ref,
                                "data-ref": doc.docData.ref,
                                class: isActive ? "splash__elem--active" : "splash__elem",
                                onclick: e => {
                                    const { isSame, replaced } = utils.testRouteReplace(doc.docData.ref)
                                    if (isSame) {
                                        let newPath = replaced
                                        if (m.route.get().includes("/replace")) newPath = newPath.replace("/replace", "")
                                        else newPath = `${newPath}/replace`
                                        m.route.set(newPath)
                                    } else m.route.set(`/app/${doc.docData.ref}`)
                                    m.redraw()
                                    node.state.prompt = false
                                }
                            }, doc.docData.title)
                        })
                )
            }
        }
    }

    return {
        listOpen: false,
        isLoading: true,
        snackbar: false,
        prompt: false,
        oninit: async vnode => {
            vnode.state.routes = utils.urlToRoutes();
            // for (const [ind, route] of vnode.state.routes.entries()) {
            //     const { docRef } = route
            //     if (docRef) {
            //         const docData = (await db.doc(docRef).get()).data()
            //         console.log(vnode.state.routes)
            //         if (docData) {
            //             vnode.state.routes[ind] = Object.assign(route, { result: docData.title })
            //         }
            //     }
            // }
            // console.log(vnode.state.routes)
            setTimeout(() => {
                utils.arrangeBreadCrumbs()
                vnode.state.isLoading = false
                m.redraw()
            }, 2500);
        },
        view: vnode => {
            // const { routes } = vnode.state
            const path = settingsStore.pathBreadCrumbs;
            return vnode.state.isLoading ?
                m(InlineLoaderDots) :
                m('.breadCrumbs',
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode, class: vnode.state.prompt.class || "" },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        vnode.state.prompt.msg && m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    // routes.map((el, ind, arr) => [
                    //     el.store && [
                    //         m('.breadCrumbs__link [data-path=true]', {
                    //             onclick: e => m.route.set(`/${el.store.prefix}`)
                    //         }, el.store.text),
                    //         m(Icon, { icon: "icon-triangle-left" })
                    //     ],
                    //     el.collectionID && [
                    //         m('.breadCrumbs__link', el.collectionID),
                    //         m(Icon, { icon: "icon-triangle-left" })
                    //     ],
                    //     el.result ? [
                    //         m('.breadCrumbs__link', el.docID),
                    //         ind < arr.length && m(Icon, { icon: "icon-triangle-left" })
                    //     ] : el.docID && [
                    //         m('.breadCrumbs__link', el.docID),
                    //         ind < arr.length && m(Icon, { icon: "icon-triangle-left" })
                    //     ]
                    // ])
                    path.map(el => [
                        m('.breadCrumbs__link', {
                            onclick: e => {
                                if (el.path) {
                                    m.route.set(el.path)
                                } else if (el.list) {
                                    promptNavTable(el)
                                }
                                m.redraw()
                            },
                            "data-path": (el.path || el.list || el.action) ? true : false,
                        },
                            el.label || "",
                            el.list ? m(Icon, { icon: "icon-triangle-down", class: "breadCrumbs__iconList" }) : "",
                        ),
                        m(Icon, {
                            icon: "icon-triangle-left",
                            action: e => vnode.state.listOpen = !vnode.state.listOpen
                        }),
                    ])
                )
        }
    }
}