import { GROUPS, CONTRACT_SECTIONS, SECTION_MILESTONES } from "../../dictionary/routeNames";
import { STRING, NUMBER } from "../../../utils/constants/types";

import m from "mithril"
import { db } from "../../../index";
import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";

import { getDocIDbyModelID } from "../../utils/utils";
import { buildInsertDoc } from "../../CRUD/utils";

export class GroupDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: GroupDoc,
        });
    };

    getSections() {
        const ws = this.getParent()
        const contract = ws.getParent()
        return dataStore[CONTRACT_SECTIONS].data.filter(section => section.docData.colRef === `${contract.docData.ref}/sections` && section.isInWorkspace(ws.docData.docID) && section.isInGroup(this))
    }

    getWorkspacePath() {
        return this.docData.ref.split("/").splice(-4).join("/")
    }

    isEmpty() {
        const sections = this.getSections()
        return sections.length === 0
    }

    async duplicate() {
        try {
            const batch = db.batch()
            const { data, children } = this.toJSON({ clone: true })
            const groupRef = db.doc(data.ref)

            data.title = `עותק של ${this.docData.title}`
            batch.set(groupRef, buildInsertDoc(data), { merge: true })
            const { milestones, sections } = children
            sections.forEach(section => {
                const sectionRef = db.doc(section.ref)
                batch.set(sectionRef, buildInsertDoc(section), { merge: true })
            })
            milestones.forEach(ms => {
                const msRef = db.doc(ms.ref)
                batch.set(msRef, buildInsertDoc(ms), { merge: true })
            })
            await batch.commit()
            console.log("success!!", data, children)
            m.redraw()
        } catch (err) {
            console.error(err)
        }
    }

    toJSON({ clone } = {}) {
        const json = {}
        const cloneGroup = this.cloneDocData({ clone })
        json.data = cloneGroup
        const sections = []
        const milestones = []
        this.getSections().forEach(section => {
            const clonSection = section.cloneDocData()
            const wsID = getDocIDbyModelID("workspaces", clonSection.workspacePath)
            clonSection.workspacePath = `workspaces/${wsID}/groups/${cloneGroup.docID}`
            sections.push(clonSection)
            section.getChildren(SECTION_MILESTONES, { dataOnly: true })
                .forEach(ms => {
                    const cloneMilestone = ms.cloneDocData({ clone, newParentRef: clonSection.ref })
                    milestones.push(cloneMilestone)
                })
        })
        json.children = { sections, milestones }
        return json
    }

    static get meta() {
        return {
            id: GROUPS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/workspaces/:workspaceID/groups",
                doc: "/projects/:projectID/contracts/:contractID/workspaces/:workspaceID/groups/:groupID",
            }
        }
    }
    static get headers() {
        return {
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
            title: { label: 'קבוצת סעיפים', defaultValue: "", type: STRING },
            description: { label: 'תאור', defaultValue: "--ללא תיאור--", type: STRING },
            itemsName: { label: 'שם יחידה', defaultValue: "--ללא שם--" },
            itemsCount: { label: 'כמות יחידות', defaultValue: 1, type: NUMBER },
        }
    }
}