import m from "mithril"
import { ACTUALS, CONFIRMS, CONTRACTS, CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, SECTION_MILESTONES, VENDORS } from "../../../../data/dictionary/routeNames"
import { CURRENCY, NUMBER, REF, STRING } from "../../../../utils/constants/types"

import { db } from "../../../../index"

import { Card } from "../../../commons/cardLayout/Card"

import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { findRepeat, getModelID } from "../../../../data/utils/utils"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { ReportLayout } from "../ReportLayout"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { DocModel } from "../../../../data/store/docModel"
import { buildDoc, getChildren, getLastAccount, getParent, getSubChildren } from "../utils"

export const VendorAccountsReport = node => {

    async function loadVendorReportData(ref) {
        try {
            node.state.collectionData = []
            node.state.load = true
            const docs = (await db.collectionGroup(CONTRACTS).where("contractorRef", "==", ref).where("isActive", "==", true).get()).docs
            docs.forEach(async fbDoc => {
                const doc = buildDoc(fbDoc)
                const { ref } = doc.docData
                const project = await getParent(ref)
                if (project.docData.isActive == true) {
                    doc.parent = project
                    //direct children
                    const accounts = await getChildren(CONTRACT_ACCOUNTS, ref)
                    // const sections = await getChildren(CONTRACT_SECTIONS, ref)
                    // const additions = await getChildren(CONTRACT_ADDITIONS, ref)
                    // const subtractions = await getChildren(CONTRACT_SUBTRACTIONS, ref)
                    //sub children
                    // const actuals = await getSubChildren(accounts, ACTUALS)
                    const confirms = await getSubChildren(accounts, CONFIRMS)
                    // const milestones = await getSubChildren(sections, SECTION_MILESTONES)
                    doc.children = {
                        accounts,
                        // sections,
                        // additions,
                        // subtractions,
                        // milestones,
                        // actuals,
                        confirms
                    }
                    node.state.collectionData = [...node.state.collectionData, doc]
                    m.redraw()
                }
            })
            node.state.load = false
        } catch (err) {
            console.error(err);
        }
    }

    const reportHeaders = {
        projectTitle: {
            label: "שם הפרוייקט",
            nav: doc => {
                return `/#!/app/${doc.parent.docData.ref}`
            },
            prompt: doc => {
                console.log("TODO: prompt something...");
            },
            val: doc => {
                return { value: doc.parent.docData.title }
            }
        },
        projectManager: {
            label: "מנהל פרוייקט",
            type: REF,
            options: { param: "displayName" },
            val: doc => {
                return { value: doc.parent.docData.manager }
            }
        },
        // seniorManager
        contractTitle: {
            label: "שם החוזה",
            val: doc => {
                return { value: doc.docData.title }
            }
        },
        contractTotal: {
            label: "סכום חוזה",
            type: CURRENCY,
            val: doc => {
                return { value: doc.docData.contractSum }
            }
        },
        contractActualsConfirm: {
            label: "סכום ביצוע מצטבר",
            type: CURRENCY,
            val: doc => {
                const sections = parseFloat(doc.docData.totalSections)
                const additions = parseFloat(doc.docData.totalAdditions)
                const subtractions = parseFloat(doc.docData.totalSubtractions)
                const total = sections + additions - subtractions
                return { value: total }
            }
            //OLD:
            // val: doc => {
            //     let totalActuals = 0
            //     doc.children.sections.forEach(section => {
            //         const isPercent = section.docData.calculationType === 'percent'
            //         const isPauschal = section.docData.calculationMethod === "pauschal"
            //         doc.children.milestones
            //             .filter(ms => ms.docData.ref.startsWith(section.docData.ref))
            //             .forEach(ms => {
            //                 let totalActual = 0
            //                 doc.children.actuals
            //                     .filter(act => act.docData.sectionRef === ms.docData.ref)
            //                     .forEach(act => totalActual += parseFloat(act.docData.value))
            //                 const price = isPercent ? (parseFloat(ms.docData.weight) / 100) * parseFloat(section.docData.itemPrice) : parseFloat(ms.docData.price);
            //                 if (isPauschal) {
            //                     totalActuals += (parseFloat(totalActual) / 100) * price;
            //                 } else {
            //                     totalActuals += parseFloat(totalActual) * price;
            //                 }
            //             })
            //     })
            //     const calcTotalAdditionsOrSubtractions = modelID => {
            //         const sections = doc.children[modelID] // additions, subtractions
            //         let additionResult = 0
            //         sections.forEach(section => {
            //             let totalActual = 0
            //             doc.children.actuals
            //                 .filter(act => act.docData.sectionRef === section.docData.ref)
            //                 .forEach(act => totalActual += parseFloat(act.docData.value))
            //             additionResult += parseFloat(section.docData.totalSum) * (parseFloat(totalActual) / 100)
            //         })
            //         return additionResult
            //     }
            //     const delayPercentage = parseFloat(doc.docData.delayPercentage / 100)
            //     const totalAdditions = calcTotalAdditionsOrSubtractions(CONTRACT_ADDITIONS)
            //     const totalSubtractions = calcTotalAdditionsOrSubtractions(CONTRACT_SUBTRACTIONS)
            //     const totalAddSub = totalAdditions - totalSubtractions
            //     const totalDelay = parseFloat((totalActuals + totalAddSub) * delayPercentage)
            //     const subTotalCumulative = totalActuals + totalAddSub - totalDelay
            //     // m.redraw() // cause promblem duplicate rows//BUG:
            //     return { value: subTotalCumulative }
            // }
        },
        contractAccountsSubTotals: {
            label: "סכום חשבונות מאושרים",
            type: CURRENCY,
            val: doc => {
                //TODO: create a filter based on confirm stage
                const value = doc.children.accounts.reduce((acc, doc) => acc += parseFloat(doc.docData.subTotal), 0)
                return { value }
            }
        },
        lastAccountMonth: {
            label: "חשבון אחרון",
            type: STRING,
            val: doc => {
                let lastAccount = "---"
                const last = getLastAccount(doc)
                if (last) lastAccount = last.docData.month
                return { value: lastAccount }
            }
        },
        confirmStatus: {
            label: "סטטוס אישורים",
            type: STRING,
            val: doc => {
                let lastConfirm = "---"
                const last = getLastAccount(doc)
                if (last) {
                    if (last.docData.stage === "finish") lastConfirm = "הסתיים"
                    else if (last.docData.stage === "start") lastConfirm = "ממתין לאתחול"
                    else {
                        const findConfirm = doc.children.confirms.find(confirm => confirm.docData.id === last.docData.stage)
                        if (findConfirm) lastConfirm = findConfirm.docData.title
                    }
                }
                return { value: lastConfirm }
            }
        },
        currenSubTotal: {
            label: "סכום ביצוע נוכחי",
            type: CURRENCY,
            val: doc => {
                let total = "---"
                const last = getLastAccount(doc)
                if (last) {
                    const account = doc.children.accounts.find(account => account.docData.month === last.docData.month)
                    if (account) total = account.docData.subTotal
                }
                return { value: total }
            }
        },

    }

    return {
        collectionData: [],
        history: [],
        prompt: false,
        snackbar: false,
        oninit: async vnode => {
            const ref = `vendors/${vnode.attrs.vendorID}`
            let findVendor
            const findFunc = () => findVendor = DocModel.getChild(VENDORS, ref)
            await findRepeat(findFunc, findVendor != undefined)
            if (findVendor) {
                node.state.vendor = findVendor
                await loadVendorReportData(ref)
            } else {
                m.route.set("/app/reports/vendors")
            }
        },
        view: vnode => {
            const { collectionData = [], load, vendor } = vnode.state
            return m(ReportLayout, { title: `סטטוס חוזים לקבלן ${vendor ? ` - "${vendor.docData.title}"` : ""}` },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Card, { class: "tableCard" },
                    m(".table",
                        //HEADERS
                        m(".table__row.table__headers", { style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);` },
                            Object.entries(reportHeaders).map(([header, { label }]) => m(".table__cell", label))
                        ),
                        load && m(InlineLoaderDots),
                        //DATA
                        collectionData.map(doc => {
                            const styleColumns = `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`
                            return m(".table__row", { style: styleColumns, key: doc.docData.ref },
                                Object.entries(reportHeaders).map(([header, { val, type = STRING, options = {}, nav, prompt }]) => {
                                    return m(`${nav ? "a" : "div"}.table__cell`, {
                                        class: nav ? "link" : "",
                                        href: nav ? nav(doc) : "#",
                                        target: "_blank",
                                        "data-header": header,
                                        onclick: e => {
                                            if (prompt) {

                                            }
                                        }
                                    },
                                        getDisplayValue(val(doc).value, type, options)
                                    )
                                })
                            )
                        }),
                        //TOTALS row
                        (collectionData.length > 0 && vendor && !load) && m(".table__totals", {
                            style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`,
                        },
                            Object.entries(reportHeaders).map(([header, fieldOptions]) => {
                                if (header === "title") {
                                    return m(".total", `${getDisplayValue(collectionData.length, NUMBER)} שורות`)
                                }
                                if (fieldOptions.totals) {
                                    console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                                    const totalValue = fieldOptions.totals.val(collectionData)
                                    return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                                }
                                if (fieldOptions.type === NUMBER || fieldOptions.type === CURRENCY) {
                                    let total = 0
                                    if (fieldOptions.val) {
                                        total = collectionData.reduce((acc, doc) => {
                                            return acc += parseFloat(fieldOptions.val(doc).value)
                                        }, 0)
                                    } else {
                                        total = ""
                                    }
                                    return m(".total", getDisplayValue(total, fieldOptions.type, fieldOptions))
                                }
                                return m(".total", "---")
                            })
                        )
                    )//END table
                ) //END tableCard
            )
        }
    }
}