import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Card } from '../../../commons/cardLayout/Card';
import { Prompt } from '../../../commons/prompt/Prompt';
import { Icon } from '../../../components/icon/Icon';
import { MonthSelector } from '../../../commons/confirmBlock/MonthSelect';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { Snackbar } from '../../../components/snackbar/Snackbar';
import { TableFiles } from '../../../commons/fileUpload/TableFiles';
import { MilestoneUnitActuals } from './MilestoneUnitActuals';
import { Table } from '../../../commons/table/Table';
import { DisplayCell } from '../../../commons/inputCell/DisplayCell';
import { SectionActual } from '../contractActual/SectionActual';
import { AdditionSection } from './AdditionSection';

//DATA
import { CURRENCY, LIST, NUMBER, PERCENT, REF, STRING } from '../../../../utils/constants/types';
import { PROJECTS, CONTRACTS, CONTRACT_SECTIONS, WORKSPACES, CONTRACT_ACCOUNTS, GROUPS, ACTUALS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, CONTRACT_COMMENTS, FILES, CONFIRMS, PAYMENTS } from '../../../../data/dictionary/routeNames';
import { REG_URL_LAST_PART$NQ } from '../../../../utils/constants/regex';
import { CREATE, UPDATE } from '../../../../data/store/permissionStore';
import { DATE_RANGE } from '../../../../utils/constants/filtersTypes';
import { dataStore } from '../../../../data/store/dataStore';
import { optionalFilelHeaders } from '../../../../data/store/commons/fileClass';
import { ContractDoc } from '../../../../data/store/contracts/contractClass';
import { AdditionDoc } from '../../../../data/store/contracts/additionClass';
import { UserDoc } from '../../../../data/store/users/userClass';
import { NavClass } from '../../../../data/store/NavClass';
import { SubtractionDoc } from '../../../../data/store/contracts/subtractionClass';

// FUNCTIONS
import { toggleCommentView, handleConfirmStage, changeAccount, defaultdDayDisplay, handleAddAccount, handleAddComment, handleEditComment, promptComments, togglePinComment, toggleActiveWorkspace } from '../utils';
import { getDisplayValue, editValue } from '../../../../data/utils/inputValidation';
import { uuid, formatDateDisplay, sortDocsBy, diffDays, addMonths, formatDateDisplay2, textToDate } from '../../../../utils/js';
import { Nav } from '../../../commons/nav/Nav';
import { isFieldVisible, isUserAllow } from '../../../../data/utils/permission';
import { buildField, buildHeaders } from '../../../../data/CRUD/utils';
import { SectionDoc } from '../../../../data/store/contracts/sectionClass';
import { DocModel } from '../../../../data/store/docModel';
import { FormEditDoc } from '../../../commons/prompt/FormEditDoc';
import { InputCell } from '../../../commons/inputCell/InputCell';
import { LocalInput } from '../../../commons/inputCell/LocalInput';
import { ContractTotalActualsTable } from './ContractTotalActualsTable';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { logRouteChangeUser } from '../../../../data/utils/utils';
import { exportReportAPI } from '../../reports/utils';
import { CONTRACT_SECTIONS_PLAN } from '../../../../utils/constants/contractsExportsOptions';

export const ContractDashboard = node => {

    const findContract = () => {
        // const contract = dataStore[CONTRACTS].data.find(doc => doc.docData.docID === node.attrs.contractID)
        const contract = DocModel.getChild(CONTRACTS, `projects/${node.attrs.projectID}/contracts/${node.attrs.contractID}`)
        if (contract) {
            node.state.contract = contract
            contract.listenToChildren()
            m.redraw()
            return true
        } else {
            const navToPath = m.route.get().replace(REG_URL_LAST_PART$NQ, "")
            m.route.set(navToPath);
            m.redraw()
            return false
        }
        // return dataStore[CONTRACTS].data.find(doc => doc.docData.docID === node.attrs.contractID)
    }

    const contratcSectionHeaders = [
        "title",
        "calculationMethod",
        "calculationType",
        "amountType",
        "itemsCount",
        "itemsStartIndex",
        "itemPrice",
        "totalSum",
        "description",
    ]
    const contractHeaders = {
        title: {},
        contractorRef: {},
        _vendorSAPcode: {
            label: "קוד בסאפ",
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) {
                    value = vendorDoc.docData.contractorSAPNumber
                }
                return { value }
            }
        },
        budgetbudgetaryItem: {},
        isIndexed: {},
        indexedFactor: {},
        contractSum: {},
        paymentDelay: {},
        calculationMethod: {},
        delayPercentage: {},
        sWorkDate: {},
        eWorkDate: {},
        status: {},
        description: {},
    }
    // const contractHeaders = [
    //     'title',
    //     "contractorRef",
    //     // TODO: create this fields as mirror type
    //     // 'contractorName',
    //     // 'commercialName',
    //     // 'companyNumber',
    //     // 'taxesEndDate',
    //     // 'contractorSAPNumber',
    //     'budgetbudgetaryItem',
    //     "isIndexed",
    //     "indexedFactor",
    //     'contractSum',
    //     'paymentDelay',
    //     'calculationMethod',
    //     'delayPercentage',
    //     'sWorkDate',
    //     'eWorkDate',
    //     // 'donePercentage',
    //     'status',
    //     'description',
    // ]

    const additionHeaders = [
        "title",
        "description",
        "amountType",
        "itemsCount",
        "itemPrice",
        "totalSum",
    ]
    const additionHeadersObj = {
        title: {},
        description: {},
        amountType: {},
        itemsCount: {},
        itemPrice: {},
        totalSum: {},
        _totalActual: {
            label: "סכום ביצוע",
            val: doc => {
                return { value: doc.calcTotalActuals() }
            },
            type: CURRENCY
        }
    }

    const navStage = [
        {
            label: "ביצוע",
            // path: m.route.get().replace("/billing", "/actual"),
            active: true,
        },
        {
            label: "הנהלת חשבונות",
            path: m.route.get().replace("/actual", "/billing"),
            active: false,
        },
    ]

    const navToAdditions = (modelID = "ws_additionsWs") => {
        return Promise.resolve(navPageView.setActiveKey(navPageView_OPTIONS.SECTIONS))
            .then(() => {
                const wsAddEl = document.getElementById("ws_additionsWs")
                if (wsAddEl !== null) wsAddEl.scrollIntoView(true)
            })
            .then(() => m.redraw())
    }

    const promptAccountPayments = (account, filters, addDataOptions = {}) => {
        const { contractorRef, sectionRef } = filters
        if (!contractorRef) return node.state.snackbar = { msg: "לא ניתן להציג תשלומים שאינם משוייכים לקבלן", isError: true }
        const filterDataOptions = { include: {} }
        Object.entries(filters).forEach(([filterKey, filterValue]) => {
            filterDataOptions.include[filterKey] = filterValue
        })
        let title = `פירוט תשלומים לתקופה ['${account.docData.month}']`
        node.state.prompt = {
            class: "full-width",
            title,
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${account.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${account.getContract("title")}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${account.docData.month}`),
                    ),
                    m(Table, {
                        doc: account,
                        title,
                        sortOptions: { param: "paymentDate" },
                        colRef: `${account.docData.ref}/${PAYMENTS}`,
                        actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false, add: true },
                        filterDataOptions,
                        addDataOptions,
                        modelID: PAYMENTS,
                        tableHeaders: ["paymentDate", "paymentChannel", "paymentType", "paymentIdentity", "sum"]
                    })
                )
            }
        }
    }

    const handleAddPaymentOnGroup = (e, groupKey) => {
        const { currentAccount: account } = node.state
        node.state.prompt = {
            title: "הוספת תשלום",
            form: () => {
                return m(".",
                    m(Table, {
                        title: "תשלומים לקבלן  - חוזה עבודות קטנות",
                        colRef: `${account.docData.ref}/${PAYMENTS}`,
                        modelID: PAYMENTS,
                        doc: account,
                        sortOptions: { param: "paymentDate" },
                        actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false, add: true },
                        tableHeaders: ["paymentDate", "paymentChannel", "paymentType", "paymentIdentity", "sum"],
                        filterDataOptions: {
                            include: { contractorRef: groupKey }
                        },
                        addDataOptions: {
                            contractorRef: groupKey
                        }
                    })
                )
            }
        }
    }

    // const promptDelayWizard = () => {
    //     const { contract, currentAccount } = node.state
    //     const localChangesDoc = {
    //         docData: {
    //             delayPercentage: contract.docData.delayPercentage
    //         },
    //         headers: {
    //             delayPercentage: {
    //                 type: PERCENT,
    //                 label: "% עיכבון",
    //                 options: {
    //                     change: (value) => {
    //                         // currentAccount.edit({ delayPercentage: parseFloat(value) })
    //                         editValue(currentAccount.docData.delayPercentage, parseFloat(value), "delayPercentage", currentAccount);
    //                         // editValue(contract.docData.delayPercentage, parseFloat(value), "delayPercentage", contract) // THINK: whith automations
    //                         contract.edit({ delayPercentage: parseFloat(value) }) // THINK: without automations
    //                     },
    //                 }
    //             }
    //         }

    //     }
    //     node.state.prompt = {
    //         title: "ניהול עכבון",
    //         class: "full-width delayWizard",
    //         form: () => m(".",
    //             m(Table, {
    //                 sortOptions: { param: "period", order: "desc", type: NUMBER },
    //                 title: "פירוט עכבון לפי תקופות חשבון",
    //                 doc: contract,
    //                 modelID: CONTRACT_ACCOUNTS,
    //                 colRef: `${contract.docData.ref}/${CONTRACT_ACCOUNTS}`,
    //                 tableHeaders: {
    //                     month: {},
    //                     subTotal: {},
    //                     delayPercentage: {},
    //                     totalDelay: {},
    //                     delayRelease: {}
    //                 },
    //                 viewOptions: { add: false, filters: false, download: false },
    //                 actions: { add: false, remove: false, copy: false, move: false, enlarge: false },
    //             }),
    //             m(".",
    //                 m(".summery__box", { key: uuid() },
    //                     m(".summery__label", "סהכ יתרת עיכבון"),
    //                     m(".summery__total", getDisplayValue(contract.getTotalAccounts("totalDelay") - contract.getTotalAccounts("delayRelease"), CURRENCY)),
    //                 ),
    //                 m(".formEdit",
    //                     m(".caption formEdit__caption", "שינוי הגדרות עיכבון"),
    //                     m(InputCell, {
    //                         doc: currentAccount,
    //                         header: "delayRelease", index: 1,
    //                         id: `${currentAccount.docData.ref}/delayRelease`,
    //                         parent: node,
    //                         editCell: node.state.editCell
    //                     }),
    //                     m(LocalInput, {
    //                         doc: localChangesDoc,
    //                         header: "delayPercentage", index: 2,
    //                         id: `${currentAccount.docData.ref}/delayPercentage`,
    //                         parent: node,
    //                         editCell: node.state.editCell
    //                     }),
    //                     m(".formEdit__actions",
    //                         (currentAccount.hasChanges("delayRelease") || (currentAccount.hasChanges("delayPercentage") && contract.hasChanges("delayPercentage"))) && m("button.button", {
    //                             onclick: async (e) => {
    //                                 try {
    //                                     await Promise.resolve(currentAccount.save())
    //                                     await Promise.resolve(contract.save())
    //                                 } catch (err) {
    //                                     console.error(err);
    //                                 } finally {
    //                                     m.redraw()
    //                                 }
    //                             }
    //                         }, "שמור שינויים")
    //                     ),
    //                 )
    //             )
    //         )
    //     }
    // }

    function isPlanAction() {
        //contractPlan OR contractActual
        if (node.state.contract && node.state.contract.docData.status === 'plan') {
            // alert("עובר למסך הקמת חוזה")
            m.route.set(m.route.get().replace("actual", "plan"))
            m.redraw()
        }
    }

    const promptSubtractionSection = (tableNode, doc) => {
        if (doc.isNew) {
            tableNode.state.prompt = {
                title: "הוספת סעיף קיזוז",
                class: "full-width",
                form: () => m(FormEditDoc, {
                    doc, tableNode, headers: Object.keys(tableNode.state.tableHeaders),
                    actions: {
                        onSave: () => {
                            console.log("TODO: do not close only replace state");
                            doc.isNew = false
                            promptSubtractionSection(tableNode, doc)
                        }
                    },
                })
            }
        } else {
            tableNode.state.prompt = {
                title: "עריכת סעיף קיזוז",
                class: "full-width",
                form: () => m(AdditionSection, {
                    Model: SubtractionDoc,
                    doc,
                    contract: tableNode.attrs.parent.state.contract,
                    currentAccount: tableNode.attrs.parent.state.currentAccount,
                    parent: node
                })
            }
        }
    }
    const promptAdditionSection = (vnode, doc) => {
        if (doc.isNew) {
            vnode.state.prompt = {
                title: "הוספת סעיף תוספת",
                class: "full-width",
                form: () => m(FormEditDoc, {
                    doc, vnode, headers: Object.keys(vnode.state.tableHeaders),
                    actions: {
                        onSave: () => {
                            console.log("TODO: do not close only replace state");
                            doc.isNew = false
                            vnode.state.prompt = false
                            promptAdditionSection(vnode, doc)
                        }
                    },
                })
            }
        } else {
            vnode.state.prompt = {
                title: "עריכת סעיף תוספת",
                class: "full-width",
                form: () => m(AdditionSection, {
                    Model: AdditionDoc,
                    doc,
                    contract: vnode.attrs.parent.state.contract,
                    currentAccount: vnode.attrs.parent.state.currentAccount,
                    parent: vnode
                })
            }
        }
    }

    function contractEditPlan() {
        node.state.prompt = {
            title: "עבור לעריכת חוזה",
            msg: `האם אתה בטוח כי ברצונך לעבור לעריכת חוזה ?`,
            actions: [
                {
                    text: "כן",
                    action: e => {
                        node.state.snackbar = { msg: "עובר לעריכת חוזה" }
                        node.state.contract.saveLocal({ status: "plan" })
                        node.state.contract.save()
                        node.state.prompt = false
                        m.route.set(m.route.get().replace("/actual", "/plan"))
                    }
                }
            ]
        }
    }


    const navCommentView = [
        {
            label: "צ'אט",
            active: true,
            key: "chat",
            action: function () {
                node.state.commentsView = this.key
                navCommentView.forEach(el => el.active = false)
                this.active = true
            }
        },
        {
            label: "טבלה",
            active: false,
            key: "table",
            action: function () {
                node.state.commentsView = this.key
                navCommentView.forEach(el => el.active = false)
                this.active = true
            }
        },
    ]


    // promptDelayWizard
    // toggleActiveWorkspace
    // toggleActiveGroup
    // promptSection
    // navToAdditions

    const navPageView_OPTIONS = {
        OVERVIEW: "overview",
        SECTIONS: "sections",
        PERIODS: "periods",
        FILES: "files",
    }
    const navPageView = new NavClass({
        nav: [
            {
                label: "דשבורד חוזה",
                key: navPageView_OPTIONS.OVERVIEW,
            },
            {
                label: "סעיפים",
                key: navPageView_OPTIONS.SECTIONS,
                action: () => {
                    if (node.state.currentAccount) navPageView.setActiveKey(navPageView_OPTIONS.SECTIONS)
                    else node.state.snackbar = { msg: "לא ניתן להציג תצוגה זו , יש לפתוח חשבון תחילה", isError: true }
                }
            },
            {
                label: "חשבונות",
                key: navPageView_OPTIONS.PERIODS,
                action: () => {
                    if (node.state.currentAccount) navPageView.setActiveKey(navPageView_OPTIONS.PERIODS)
                    else node.state.snackbar = { msg: "לא ניתן להציג תצוגה זו , יש לפתוח חשבון תחילה", isError: true }
                }
            },
            {
                label: "צרופות",
                key: navPageView_OPTIONS.FILES,
            },
        ],
    })


    async function exportContractTable() {
        const { contract } = node.state
        // vnode.state.snackbar = { msg: DEFAULT_ACTION_NOT_AVILABLE };
        await exportReportAPI(node, contract.getExportData([ACCOUNTS_TOTAL]))
    }


    const promptActualReport = () => {
        const { contract, currentAccount } = node.state
        if (contract && currentAccount) {
            node.state.prompt = {
                title: "דוח ביצוע מצטבר",
                class: "full-width",
                form: () => m(ContractTotalActualsTable, { contract, currentAccount })
            }
        } else {
            node.state.snackbar = { msg: "לא נמצאו נתונים", isError: true }
        }
    }

    const isRemoved = () => node.state.contract && node.state.contract.isRemoved

    return {
        loadContract: true,
        selectedMonth: "",
        collaspeSection: [],
        activeGroup: false,
        activeWorkspace: "default",
        sectionsView: "cards",
        commentsView: "chat",
        prompt: false,
        snackbar: false,
        showCurrentComments: false,
        oninit: vnode => {
            navPageView.setNode(vnode)
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get());
            sessionStorage.setItem('lastURL', m.route.get());

            logRouteChangeUser("checkIn");

            const projectPath = `projects/${vnode.attrs.projectID}`
            dataStore[PROJECTS].listen()
            const { alreadyListen } = dataStore[CONTRACTS].listen(projectPath)
            const contractPath = `projects/${vnode.attrs.projectID}/contracts/${vnode.attrs.contractID}`
            if (alreadyListen) {
                findContract()
                vnode.state.loadContract = false;
                const { alreadyListen: isListenToAccounts } = dataStore[CONTRACT_ACCOUNTS].listen(contractPath)
                vnode.state.currentAccount = vnode.state.contract.getLastAccount()
                // if (isListenToAccounts) getCurrentAccount(vnode)
                // else setTimeout(() => getCurrentAccount(vnode), 3000);
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען פרטי חוזה...", loading: true }
                setTimeout(() => {
                    findContract()
                    vnode.state.loadContract = false
                    const { alreadyListen: isListenToAccounts } = dataStore[CONTRACT_ACCOUNTS].listen(contractPath)
                    vnode.state.currentAccount = vnode.state.contract.getLastAccount()
                    // if (isListenToAccounts) getCurrentAccount(vnode)
                    // else setTimeout(() => getCurrentAccount(vnode), 3000);
                    m.redraw()
                }, 3000)
            }
        },
        // onbeforeremove: vnode => {
        //     return logRouteChangeUser("checkOut");
        // },
        onremove: vnode => {
            console.log("remove '/contractDashboard'")
            return logRouteChangeUser("checkOut");
        },
        view: vnode => {
            const { commentsView, contract, currentAccount } = vnode.state
            return (
                m(PageLayout, {
                    class: "contract",
                    title: contract ?
                        `${contract.getProjectType("text")} / דשבורד ביצוע חוזה`
                        : "דשבורד ביצוע חוזה",
                    module: contract ? contract.getProjectType() : ""
                },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode, class: vnode.state.prompt.class || "" },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        vnode.state.prompt.msg && m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.selectMonth && m(MonthSelector, {
                            lastMonth: vnode.state.prompt.selectMonth,
                            contract: contract,
                            type: vnode.state.prompt.type || "newAccount",
                            label: vnode.state.prompt.label || "תקופה חדשה",
                            setSelected: month => vnode.state.selectedMonth = month
                        }),

                        vnode.state.prompt.form && vnode.state.prompt.form(),

                        vnode.state.prompt.showActuals && m(MilestoneUnitActuals, {
                            milestone: vnode.state.prompt.showActuals.milestone,
                            unit: vnode.state.prompt.showActuals.unit,
                            accountRef: vnode.state.prompt.showActuals.accountRef
                        }),

                        vnode.state.prompt.note && m("textarea.prompt__note [placeholder='הוסף הערות']", {
                            rows: 4,
                            oninput: e => { if (e.target.value.trim() !== "") vnode.state.note = e.target.value }
                        }),

                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    isRemoved() ? "חוזה זה נמחק" : m(Card, { class: "formCard contract__details no-print" },
                        (contract && isUserAllow(CONTRACTS, UPDATE, contract)) && m(".iconEdit", { onclick: e => contractEditPlan() }, m(Icon, { icon: "icon-edit" }), m("span", "ערוך חוזה")),
                        m("h3.formCard__caption caption", "פרטי החוזה"),
                        vnode.state.loadContract && m(InlineLoaderDots),
                        contract && Object.entries(buildHeaders(ContractDoc.headers, contractHeaders, ContractDoc.meta.visibleLogic)).map(([header, settingsField], index) => {
                            const value = settingsField.val ? settingsField.val(contract).value : contract.docData[header]
                            return m(DisplayCell, { value, field: settingsField, doc: contract, header, index })
                        }),
                    ),
                    !isRemoved() && m("hr"),
                    (!isRemoved() && contract && currentAccount && contract.docData.calculationMethod !== "oddJobs") && m(Nav, {
                        class: "contract__nav", nav: navStage
                    }),
                    m("br"),
                    (contract && !isRemoved()) && m(".allAccounts", { style: "display:none" },
                        contract.getChildren(CONTRACT_ACCOUNTS)
                            .sort(sortDocsBy("period", { order: "desc", type: NUMBER }))
                            .map((doc, ind) => {
                                if (ind === 0) {
                                    return [
                                        m(".caption", { oninit: el => vnode.state.currentAccount = doc }, "חשבון פעיל"),
                                        m(".current", doc.docData.month)
                                    ]
                                } else {
                                    return m(".old", doc.docData.month)
                                }
                            })
                    ),
                    (contract && !currentAccount) && m(Card, { class: "contract__accounts no-print" },
                        m(".info", "לא נמצאו חשבונות בחוזה זה"),
                        m(".button", {
                            onclick: e => handleAddAccount(vnode),
                            style: "grid-column: 1 / -1;justify-self: center;min-width: fit-content;"
                        },
                            "צור חשבון תקופתי חדש"),
                    ),
                    currentAccount && m(Card, { class: "contract__accounts no-print" },
                        m("h3.caption", "סטטוס אישורי ביצוע"),
                        (contract && !isRemoved() && (currentAccount.docData.stage === "finish" || currentAccount.docData.stage === "start")) && m("button.button", {
                            onclick: e => handleConfirmStage(vnode),
                            style: "justify-self: flex-end;"
                        }, currentAccount.docData.stage === "finish" ?
                            "יצירת תקופה חדשה"
                            : "אתחול"
                        ),
                        vnode.state.loadContract ?
                            m(InlineLoaderDots) :
                            m(".period",
                                m(".period__label", "תקופה"),
                                m(".period__label", { dir: "ltr", onclick: e => changeAccount(vnode) }, currentAccount.docData.month)
                            ),
                        m(".confirm", /* { style: `grid-template-columns: repeat(${currentAccount.getFlow("operation").length},1fr);` }, */ //THINK: no need to create columns grid-column created by defining their area
                            currentAccount.getChildren(CONFIRMS, { include: { type: "operation" } })
                                .sort(sortDocsBy("index", { type: NUMBER }))
                                .map((doc, ind) => {
                                    const { id, due, confirm, confirmAt, confirmBy } = doc.docData
                                    // console.log(doc.docData);
                                    const dueDiff = diffDays(addMonths(1, textToDate(currentAccount.docData.month, due)), new Date())
                                    const isDelay = dueDiff > 0 && confirm !== true
                                    return [
                                        m(".confirm__header confirm__cell", { style: `grid-area:1/${ind + 1}` }, doc.docData.title),
                                        m(`.confirm__label confirm__cell ${isDelay ? "danger" : ""}`, { style: `grid-area:2/${ind + 1}` }, defaultdDayDisplay(currentAccount, due), isDelay && m("span", { style: "font-weight:600;font-size:1.4rem" }, `לפני ${parseInt(dueDiff)} יום`)),
                                        m(".confirm__value confirm__cell", { style: `grid-area:3/${ind + 1}` }, (confirm == true && confirmAt && confirmAt !== "") ? formatDateDisplay(new Date(confirmAt), { useHours: true }) : "----"),
                                        m(".confirm__value confirm__cell", { style: `grid-area:4/${ind + 1}` }, (confirm == true && confirmBy && confirmBy !== "") ? UserDoc.getUserByID(confirmBy, "displayName") : "----")
                                    ]
                                }),
                        )
                    ),
                    (contract && !isRemoved() && currentAccount) && m(Card, { class: "contract__comments card--scroll no-print" },
                        m(Icon, { class: "icon--highlight", icon: "icon-enlarge", action: e => promptComments(vnode) }),
                        m(Nav, { nav: navCommentView }),
                        m("h3.caption",
                            vnode.state.showCurrentComments ?
                                "לוג הערות לתקופה" :
                                "לוג הערות",
                            m(".toggleComments", { onclick: e => toggleCommentView(vnode) },
                                vnode.state.showCurrentComments ?
                                    "הצג הכל" :
                                    "הצג תקופה נוכחית" ,
                            ),
                        ),
                        (commentsView === "chat" && contract) && m(".comments",
                            contract.getChildren(CONTRACT_COMMENTS, vnode.state.showCurrentComments ?
                                { include: { accountRef: currentAccount.docData.ref }, exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } } :
                                { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } }
                            )
                                .sort(sortDocsBy("timestamp", { type: NUMBER }))
                                .map(doc => {
                                    return m(".comment",
                                        doc.isNew && m(".flag flag--new", "לא נשמר"),
                                        !doc.isNew && m(".flag", UserDoc.getUserByID(doc.docData.createdBy, "displayName")),
                                        m(".comment__title", { onclick: e => handleEditComment(vnode, doc) },
                                            doc.docData.title,
                                            m(Icon, { icon: "icon-pin", class: `comment__pin${doc.docData.isPinned ? "--pinned" : "--unpinned"}`, action: e => togglePinComment(e, vnode, doc) })
                                        ),
                                        m(".comment__stage", getDisplayValue(doc.docData.accountRef, LIST, { data: dataStore[CONTRACT_ACCOUNTS].data, param: "month" })),
                                        m(".comment__content", doc.docData.description),
                                        m(".comment__info", formatDateDisplay2(new Date(doc.docData.createdAt), "useHours")),
                                    )
                                }
                                ),
                            m(".comment--add", { onclick: e => handleAddComment(vnode) }, "+ הוסף הערה חדשה")
                        ),
                        (commentsView === "table") && m(Table, {
                            title: "הערות חוזה",
                            modelID: CONTRACT_COMMENTS,
                            colRef: `${contract.docData.ref}/${CONTRACT_COMMENTS}`,
                            doc: contract,
                            tableHeaders: ["title", "description", "isPinned"],
                            filterDataOptions: vnode.state.showCurrentComments ?
                                { include: { accountRef: currentAccount.docData.ref }, exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } } :
                                { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } },
                            addDataOptions: {
                                accountRef: currentAccount.docData.ref,
                                confirmRef: `${currentAccount.docData.ref}/${CONFIRMS}/${currentAccount.getCurrentStage().stage.id}`
                            },
                            actions: { onAdd: "prompt", onRow: "prompt" },
                            viewOptions: { nav: false, filters: false, add: true, totals: false },
                            sortOptions: { param: "timestamp" },
                            searchOptions: ["title", "description"],
                            filters: [],
                            // filterMap: []
                        })
                    ),


                    (contract && !isRemoved() && !currentAccount) && m(Card, { class: "contract__comments card--scroll no-print" },
                        m(Icon, { class: "icon--highlight", icon: "icon-enlarge", action: e => promptComments(vnode) }),
                        m(Nav, { nav: navCommentView }),
                        m("h3.caption", "לוג הערות לחוזה"),
                        (commentsView === "chat") && m(".comments",
                            contract.getChildren(CONTRACT_COMMENTS, { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } })
                                .sort(sortDocsBy("timestamp", { type: NUMBER }))
                                .map(doc => {
                                    return m(".comment",
                                        doc.isNew && m(".flag flag--new", "לא נשמר"),
                                        !doc.isNew && m(".flag", UserDoc.getUserByID(doc.docData.createdBy, "displayName")),
                                        m(".comment__title", { onclick: e => handleEditComment(vnode, doc) },
                                            doc.docData.title,
                                            m(Icon, { icon: "icon-pin", class: `comment__pin${doc.docData.isPinned ? "--pinned" : "--unpinned"}`, action: e => togglePinComment(e, vnode, doc) })
                                        ),
                                        m(".comment__stage", getDisplayValue(doc.docData.accountRef, LIST, { data: dataStore[CONTRACT_ACCOUNTS].data, param: "month" })),
                                        m(".comment__content", doc.docData.description),
                                        m(".comment__info", formatDateDisplay2(new Date(doc.docData.createdAt), "useHours")),
                                    )
                                }
                                ),
                            m(".comment--add", { onclick: e => handleAddComment(vnode) }, "+ הוסף הערה חדשה")
                        ),
                        (commentsView === "table") && m(Table, {
                            title: "הערות חוזה",
                            modelID: CONTRACT_COMMENTS,
                            colRef: `${contract.docData.ref}/${CONTRACT_COMMENTS}`,
                            doc: contract,
                            tableHeaders: ["title", "description", "isPinned"],
                            filterDataOptions: { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } },
                            actions: { onAdd: "prompt", onRow: "prompt" },
                            viewOptions: { nav: false, filters: false, add: true, totals: false },
                            sortOptions: { param: "timestamp" },
                            searchOptions: ["title", "description"],
                            filters: [],
                            // filterMap: []
                        })
                    ),

                    (!isRemoved() && contract) && m(Nav, {
                        onupdate: () => {
                            const countOpen = contract.getChildren(FILES, { include: { status: "open" } }).length
                            if (countOpen > 0) navPageView.updateNavElem(navPageView_OPTIONS.FILES, "notificationCount", countOpen)
                            else navPageView.updateNavElem(navPageView_OPTIONS.FILES, "notificationCount", false)
                        },
                        class: "contract__nav",
                        nav: navPageView.nav,
                    }),

                    (contract && !isRemoved() && !currentAccount && navPageView.isActiveState(navPageView_OPTIONS.OVERVIEW)) && m(Card, { class: "contractPlan__totals contract__totals reportTable", columns: 4 },
                        m("h3.formCard__caption caption",
                            "סיכום",
                            m(Icon, { icon: "icon-print", action: e => exportContractTable() }),
                            // m(Icon, { icon: "icon-download", action: e => vnode.state.contract.exportSheet() }),
                        ),
                        (vnode.state.loadContract || vnode.state.loadSections) ?
                            m(InlineLoaderDots) :
                            [
                                m(".cell cell--header", "סעיף"),
                                m(".cell cell--header", "מחיר יח"),
                                m(".cell cell--header", "כמות יח"),
                                m(".cell cell--header", "סהכ"),
                                dataStore[WORKSPACES].data
                                    .filter(ws => ws.docData.colRef === `${vnode.state.contract.docData.ref}/workspaces`)
                                    .sort(sortDocsBy("timestamp", { type: NUMBER }))
                                    .map((ws, wsIndex) => {
                                        return [
                                            m(".row cell cell--title", ws.docData.title),
                                            dataStore[GROUPS].data
                                                .filter(group => group.docData.colRef === `${ws.docData.ref}/groups` && !group.isEmpty(vnode.state.contract, ws))
                                                .map((group, groupIndex) => {
                                                    return [
                                                        m(".row cell cell--subtitle", group.docData.title),
                                                        dataStore[CONTRACT_SECTIONS].data
                                                            .filter(section => section.docData.colRef === `${vnode.state.contract.docData.ref}/sections` && section.isInWorkspace(ws.docData.docID) && section.isInGroup(group))
                                                            .map(section => {
                                                                return [
                                                                    m(".cell cell--value", section.docData.title),
                                                                    m(".cell cell--value", getDisplayValue(parseInt(section.docData.itemPrice), CURRENCY)),
                                                                    m(".cell cell--value", getDisplayValue(parseInt(section.docData.itemsCount), NUMBER)),
                                                                    m(".cell cell--value", getDisplayValue(parseInt(section.docData.totalSum), CURRENCY)),
                                                                ]
                                                            })
                                                    ]
                                                }),
                                        ]
                                    }),
                                m(".row row__total", 'סה"כ : ', getDisplayValue(parseInt(vnode.state.contract.getTotal()), CURRENCY)),
                            ]
                    ),

                    (!isRemoved() && contract && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.OVERVIEW)) && m("button.button button--text [data-tooltip]", { onclick: e => promptActualReport(), style: "justify-self: flex-end;min-width: 5rem;" },
                        m(Icon, { icon: "icon-new-tab" }),
                        m(Tooltip, { text: "הצג דוח בחלון להדפסה" })
                    ),
                    (!isRemoved() && contract && contract.docData.calculationMethod !== "oddJobs" && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.OVERVIEW)) && m(ContractTotalActualsTable, { contract, currentAccount, navPageView_OPTIONS, navPageView, loadContract: vnode.state.loadContract, parent: vnode, class: "no-print" }),

                    (contract && contract.docData.calculationMethod === "oddJobs" && !isRemoved() && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.OVERVIEW)) && m(Card, { class: "contractPlan__totals contract__totals reportTable card--scroll", columns: 7 },
                        m("h3.formCard__caption caption",
                            "עבודות קטנות",
                            // m(Icon, { icon: "icon-print", action: e => exportContractTable() }),
                            // m(Icon, { icon: "icon-download", action: e => vnode.state.contract.exportSheet() }),
                        ),
                        m(".cell cell--header", "סעיף"),
                        m(".cell cell--header", "סהכ מצטבר"),
                        m(".cell cell--header", "סהכ תקופות קודמות מצטבר"),
                        m(".cell cell--header", "סהכ תקופה נוכחית"),
                        m(".cell cell--header", "סהכ חשבון נוכחי כולל מעמ"),
                        m(".cell cell--header", "סהכ שולם"),
                        m(".cell cell--header", "סהכ יתרה"),
                        Object.entries(contract.pivotChildren(CONTRACT_SECTIONS, "contractorRef"))
                            .map(([contractorRef, group]) => {
                                const childLengh = group.children.length
                                const showSubTotal = childLengh > 1
                                return [
                                    m(".row cell cell--title", { key: contractorRef }, group.title),
                                    group.children.map(section => {
                                        const sectionRef = section.docData.ref
                                        const totalActuals = section.calcTotalActuals();
                                        const totalHistory = section.calcLastTotalActuals({ accountRef: currentAccount.docData.ref });
                                        const totalCurrent = section.calcTotalActuals({ accountRef: currentAccount.docData.ref });
                                        // const cumulativeTotal = totalActuals - totalHistory
                                        const totalToPay = totalCurrent * (100 + parseFloat(currentAccount.docData.vatPercent)) / 100;
                                        const totalFees = currentAccount.getTotalPayments({ include: { sectionRef, contractorRef } })
                                        const diffToPay = totalFees - totalToPay
                                        return [
                                            m(".cell cell--value first clickable", { onclick: e => m.route.set(`/app/${section.docData.ref}`) }, section.docData.title),
                                            m(".cell cell--value", getDisplayValue(totalActuals, CURRENCY)),
                                            m(".cell cell--value", getDisplayValue(totalHistory, CURRENCY)),
                                            m(".cell cell--value", getDisplayValue(totalCurrent, CURRENCY)),
                                            m(".cell cell--value", getDisplayValue(totalToPay, CURRENCY)),
                                            m(".cell cell--value clickable", { onclick: e => promptAccountPayments(currentAccount, { sectionRef, contractorRef }, { sum: -(diffToPay) }) }, getDisplayValue(totalFees, CURRENCY)),
                                            m(".cell cell--value", getDisplayValue(diffToPay, CURRENCY)),
                                        ]
                                    }),
                                    m(".row__total--sub", showSubTotal ? `${childLengh} סעיפים` : "---"),
                                    m(".row__total--sub", showSubTotal ? "---" : "---"),
                                    m(".row__total--sub", showSubTotal ? "---" : "---"),
                                    m(".row__total--sub", showSubTotal ? "---" : "---"),
                                    m(".row__total--sub", showSubTotal ? "---" : "---"),
                                    m(".row__total--sub clickable", { onclick: e => console.log("%cTODO:", "background-color:#e6b800;color:#f0f0f0;") }, showSubTotal ? "---" : "---"),
                                    m(".row__total--sub", showSubTotal ? "---" : "---"),

                                ]
                            }),
                        m(".row__total--main", "---"),
                        m(".row__total--main", "---"),
                        m(".row__total--main", "---"),
                        m(".row__total--main", "---"),
                        m(".row__total--main", "---"),
                        m(".row__total--main clickable", { onclick: e => console.log("%cTODO:", "background-color:#e6b800;color:#f0f0f0;") }, "---"),
                        m(".row__total--main", "---"),
                    ),

                    (contract && !isRemoved() && navPageView.isActiveState(navPageView_OPTIONS.FILES)) && m(TableFiles, {
                        colRef: `${contract.docData.ref}/${FILES}`,
                        title: "צרופות חוזה",
                        doc: contract,
                        addDataOptions: { accountRef: currentAccount ? currentAccount.docData.ref : "", attachmentID: "", sectionRef: "" },
                        // formHeaders: {
                        //     title: {}, description: {}, status: {}, attachmentID: {},
                        //     sectionRef: {
                        //         label: "קישור לסעיף",
                        //         defaultValue: "",
                        //         type: LIST,
                        //         options: {
                        //             data: contract.getChildren(CONTRACT_SECTIONS),
                        //             load: () => dataStore[CONTRACT_SECTIONS].listen(contract.docData.ref),
                        //             model: SectionDoc,
                        //             addOnFlow: false,
                        //         }
                        //     }
                        // },
                        tableHeaders: {
                            title: {}, description: {}, status: {}, createdAt: {},
                            accountRef: buildField(optionalFilelHeaders.accountRef),
                            attachmentID: buildField(optionalFilelHeaders.attachmentID),
                            sectionRef: {
                                label: "קישור לסעיף",
                                defaultValue: "",
                                type: LIST,
                                options: {
                                    data: contract.getChildren(CONTRACT_SECTIONS),
                                    load: () => dataStore[CONTRACT_SECTIONS].listen(contract.docData.ref),
                                    model: SectionDoc,
                                    addOnFlow: false,
                                }
                            },
                        },
                        modelID: FILES,
                        actions: {
                            onRow: "prompt",
                        }
                    }),

                    (contract && !isRemoved() && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.SECTIONS)) && m(Table, {
                        title: "סעיפי חוזה",
                        modelID: CONTRACT_SECTIONS,
                        colRef: `${contract.docData.ref}/${CONTRACT_SECTIONS}`,
                        doc: contract,
                        tableHeaders: {
                            title: {},
                            calculationMethod: {},
                            calculationType: {},
                            amountType: {},
                            itemsCount: {},
                            itemPrice: {},
                            totalSum: {},
                            description: {},
                            // workspacePath: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals({ accountRef: currentAccount.docData.ref }) }
                                },
                                type: CURRENCY
                            },
                            _totalActual: {
                                label: "סכום ביצוע",
                                val: doc => {
                                    return { value: doc.calcTotalActuals() }
                                },
                                type: CURRENCY
                            },
                        },
                        class: "contract__sections",
                        actions: {
                            onAdd: "prompt",
                            onRow: (node, doc) => {
                                if (doc.isNew) {
                                    return node.state.prompt = {
                                        title: "הוספת סעיף",
                                        class: "full-width",
                                        form: () => m(FormEditDoc, { doc, node, headers: contratcSectionHeaders })
                                    }
                                } else {
                                    return node.state.prompt = {
                                        title: "עריכת סעיף",
                                        class: "full-width",
                                        form: () => m(SectionActual, {
                                            isPrompt: true,
                                            parent: node,
                                            section: doc,
                                            contract: node.attrs.parent.state.contract,
                                            currentAccount: node.attrs.parent.state.currentAccount,
                                        })
                                    }
                                }
                            },
                        },
                        parent: vnode,
                        aggregationOptions: { groupBy: "workspacePath" },
                        viewOptions: { nav: false, filters: true, add: false, totals: true },
                        // filterDataOptions: {},
                        sortOptions: { param: "index" },
                        searchOptions: ["title"],
                        filters: [],
                        // filterMap: []
                    }),

                    //ADDITION + SUBTRACTION : 
                    (contract && !isRemoved() && contract.docData.calculationMethod !== "oddJobs" && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.SECTIONS)) && m("h3.workspace ws__title#ws_additionsWs", "תוספת וקיזוזים"),
                    (contract && !isRemoved() && contract.docData.calculationMethod !== "oddJobs" && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.SECTIONS)) && m(Table, {
                        title: "תוספות",
                        modelID: CONTRACT_ADDITIONS,
                        colRef: `${contract.docData.ref}/${CONTRACT_ADDITIONS}`,
                        doc: contract,
                        tableHeaders: additionHeadersObj,
                        class: "contract__additions",
                        actions: {
                            onAdd: "prompt",
                            onRow: (tableNode, doc) => promptAdditionSection(tableNode, doc),
                            remove: false,
                            duplicate: false,
                        },
                        parent: vnode,
                        viewOptions: { nav: false, filters: true, add: true, title: true, totals: true },
                        sortOptions: { param: "timestamp" },
                        searchOptions: ["title", "description", "amountType"],
                        filters: [],
                        // filterMap: [
                        //     { label: "תאריך יצירה", header: "createdAt", type: DATE_RANGE },
                        // ]
                    }),
                    (contract && !isRemoved() && contract.docData.calculationMethod !== "oddJobs" && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.SECTIONS)) && m(Table, {
                        title: "קיזוזים",
                        modelID: CONTRACT_SUBTRACTIONS,
                        colRef: `${contract.docData.ref}/${CONTRACT_SUBTRACTIONS}`,
                        doc: contract,
                        tableHeaders: additionHeadersObj,
                        class: "contract__subtractions",
                        actions: {
                            onAdd: "prompt",
                            onRow: (tableNode, doc) => promptSubtractionSection(tableNode, doc),
                            remove: false,
                            copy: false,
                        },
                        parent: vnode,
                        viewOptions: { nav: false, filters: true, add: true, title: true, totals: true },
                        sortOptions: { param: "timestamp" },
                        searchOptions: ["title", "description", "amountType"],
                        filters: [],
                        filterMap: [
                            { label: "תאריך יצירה", header: "createdAt", type: DATE_RANGE },
                        ]
                    }),

                    ((!isRemoved() && contract && currentAccount && navPageView.isActiveState(navPageView_OPTIONS.PERIODS))) && m(Table, {
                        title: "ביצוע לפי תקופות",
                        modelID: CONTRACT_ACCOUNTS,
                        colRef: `${contract.docData.ref}/${CONTRACT_ACCOUNTS}`,
                        doc: contract,
                        tableHeaders: {
                            period: {
                                options: {
                                    totals: {
                                        val: (data) => {
                                            const start = data[0].docData.period
                                            const endIndex = Number(data.length) - 1
                                            const end = data[endIndex].docData.period
                                            return `${start} - ${end}`
                                        },
                                        type: STRING,
                                        // tooltip : false //TODO:
                                    }
                                }
                            },
                            month: {},
                            _actuals: {
                                label: "סכום ביצוע",
                                val: doc => {
                                    return {
                                        value: doc.getParent().calcTotalActuals({ accountRef: doc.docData.ref })
                                    }
                                },
                                type: CURRENCY
                            }
                        },
                        class: "contract__sections",
                        parent: vnode,
                        actions: {
                            onRow: false,
                            remove: false,
                            duplicate: false,
                        },
                        viewOptions: { nav: false, filters: true, add: false, totals: true },
                        // filterDataOptions: {},
                        sortOptions: { param: "period" },
                        searchOptions: ["month"],
                        filters: [],
                        // filterMap: []
                    }),

                    (contract && !isRemoved() && currentAccount) && m("hr"),
                    (contract && !isRemoved() && currentAccount && vnode.state.currentAccount.getCurrentStage().stage.type === "operation" && vnode.state.currentAccount.docData.stage !== "start" && vnode.state.currentAccount.docData.stage !== "finish") && m("button.button", { onclick: e => handleConfirmStage(vnode), style: "justify-self:center;margin-top: 4rem;grid-column:1/-1" }, vnode.state.currentAccount.getCurrentStage().stage.text),
                    (contract && !isRemoved() && currentAccount && vnode.state.currentAccount.getCurrentStage().stage.type === "accounting") && m(".row row__info no-print", `סבב אישורים בשלב הביצוע הסתיים `, m("span.link", { key: uuid(), onclick: e => m.route.set(m.route.get().replace("/actual", "/billing")) }, "עבור למסך הנהח"))
                ) //END pageLayout
            ) //END return
        }
    }
}