
export const PAYMENT_CHANNELS = {
    CHECK: "check",
    CASH: "cash",
    CREDIT: "credit",
    BANK_TRANSFER: "bankTransfer",
    BANK_TRANSACTION: "bankTransaction",
    OTHER: "other",
}

export const paymentChannels = [
    { id: PAYMENT_CHANNELS.CHECK, text: "צ'ק" },
    { id: PAYMENT_CHANNELS.CASH, text: "מזומן" },
    { id: PAYMENT_CHANNELS.CREDIT, text: "אשראי" },
    { id: PAYMENT_CHANNELS.BANK_TRANSFER, text: "העברה בנקאית" },
    { id: PAYMENT_CHANNELS.BANK_TRANSACTION, text: "מסב" },
    { id: PAYMENT_CHANNELS.OTHER, text: "אחר" },
]