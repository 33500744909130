import m from "mithril"
import { CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS } from "../../../data/dictionary/routeNames"
import { DocModel } from "../../../data/store/docModel"
import { REG_URL_LAST_PART } from "../../../utils/constants/regex"
import { FullPageLayout } from "../../commons/pageLayout/FullPage"
import { SectionActual } from "./contractActual/SectionActual"
import { SectionPlan } from "./contractPlan/SectionPlan"
import { SectionPreview } from "./contractPlan/SectionPreview"

export const SectionPage = node => {

    const togglePreviewEdit = () => {
        const { previewMode } = node.state
        if (previewMode === "edit") node.state.previewMode = "preview"
        else node.state.previewMode = "edit"
    }

    return {
        oninit: vnode => {
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get());
            let sectionRef
            const routePath = m.route.get()
            if (routePath.includes(CONTRACT_ADDITIONS) || routePath.includes(CONTRACT_SUBTRACTIONS)) {

            }
            sectionRef = `projects/${vnode.attrs.projectID}/contracts/${vnode.attrs.contractID}/sections/${vnode.attrs.sectionID}`
            const section = DocModel.getDoc(sectionRef)
            if (section) {
                const contract = section.getParent()
                const currentAccount = contract.getLastAccount()

                vnode.state.section = section
                vnode.state.sectionTitle = section.docData.title
                vnode.state.contract = contract
                vnode.state.currentAccount = currentAccount
            }
            else m.route.set(`/app/${sectionRef.replace(REG_URL_LAST_PART, "")}`)
        },
        onupdate: vnode => {
            vnode.state.sectionTitle = vnode.state.section.docData.title
        },
        previewMode: "edit",
        view: vnode => {
            const { section, contract, currentAccount, sectionTitle, previewMode } = vnode.state
            return m(FullPageLayout, {
                title: `סעיף ${sectionTitle}`,
                back: `/app/${contract.docData.ref}/${contract.docData.status === "plan" ? "plan" : "actual"}`
            },
                contract.docData.status === "active" && m(SectionActual, { section, contract, currentAccount }),
                (contract.docData.status === "plan" && previewMode === "edit") && m(SectionPlan, { section, contract, togglePreviewEdit }),
                (contract.docData.status === "plan" && previewMode === "preview") && m(SectionPreview, { section, contract, togglePreviewEdit })
            )
        }
    }
}