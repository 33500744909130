import m from "mithril"
import { Icon } from "../../components/icon/Icon"
import { preventDefaults, uuid } from "../../../utils/js"
import { Prompt } from "../prompt/Prompt"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots"
import { MIME_TYPES } from "../../../utils/constants/mimeTypes"
import { promptFile, saveFile, saveLocalBlob, handleRemoveFile } from "./utils"
import { dataStore } from "../../../data/store/dataStore"
import { getAddOptions } from "../../../data/utils/utils"
import { REG_URL_LAST_PART } from "../../../utils/constants/regex"
import { buildHeaders } from "../../../data/CRUD/utils"
import { FileDoc } from "../../../data/store/commons/fileClass"


export const FilesUpload = node => {

    return {
        // files: [],
        prompt: false,
        snackbar: false,
        fileInputID: uuid(),
        formHeaders: ["title"],
        loader: false,
        oninit: vnode => {
            const parentRef = vnode.attrs.colRef.replace(REG_URL_LAST_PART, "")
            const { unsubscribe, alreadyListen } = dataStore[vnode.attrs.modelID].listen(parentRef)

            vnode.state.formHeaders = buildHeaders(FileDoc.headers, vnode.attrs.formHeaders)
        },
        view: vnode => {
            const {
                title = "קבצים וצרופות",
                doc: instance,
                accept = [MIME_TYPES.PDF, MIME_TYPES.IMAGE],
                modelID,
                colRef,
                filterDataOptions = {},
                addDataOptions = {},
                multiple = false
            } = vnode.attrs
            const { fileInputID, loader } = vnode.state
            const Model = dataStore[modelID].model
            return m(".filesUpload",
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(".caption caption--sub", title),
                m(".filesUpload__thumbs", {
                    class: vnode.state.drag ? "droparea" : vnode.state.hover ? "hover" : "",
                    onmouseenter: e => { vnode.state.drag ? "" : vnode.state.hover = true },
                    onmouseleave: e => { vnode.state.hover = false },
                    onclick: e => { e.stopPropagation() },
                    ondragover: e => { vnode.state.drag = true; preventDefaults(e) },
                    ondragenter: e => { vnode.state.drag = true; preventDefaults(e) },
                    ondragleave: e => { vnode.state.drag = false; preventDefaults(e) },
                    ondrop: e => {
                        vnode.state.drag = false;
                        preventDefaults(e);
                        saveLocalBlob(e.dataTransfer.files, colRef, modelID, getAddOptions(colRef, filterDataOptions, addDataOptions), vnode)
                    }
                },
                    vnode.state.loading && m(InlineLoaderDots),
                    instance.getChildren(modelID, filterDataOptions).map(file => {
                        return m(".filesUpload__thumb",
                            m("img", { src: file.docData.url }),
                            m(Icon, { icon: "icon-x", class: "remove", action: e => handleRemoveFile(e, file, vnode) }),
                            m(".link", { onclick: e => promptFile(file, vnode) }, file.docData.title),
                            file.isNew && m("button.button", { onclick: e => saveFile(e, file) }, "שמור"),
                        )
                    }),
                    (multiple || instance.getChildren(modelID, filterDataOptions).length === 0) && m(`label[for=${fileInputID}].filesUpload__thumb`,
                        "העלה קובץ +",
                        m(Icon, { icon: "icon-attachment", class: "icon--xl attach" })
                    ),
                ),
                m(`input.[type=file][hidden]#${fileInputID}[${multiple ? "multiple" : ""}]`, {
                    onchange: e => saveLocalBlob(e.target.files, colRef, modelID, getAddOptions(colRef, filterDataOptions, addDataOptions), vnode),
                    accept: accept.join(",")
                }),
            )
        }
    }
}