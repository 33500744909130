import m from "mithril"
import { auth } from "../../../../index"
import { apiBaseUrl, apiBaseUrl_DEV } from "../../../../firebase/api"
import { CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, PAYMENTS } from "../../../../data/dictionary/routeNames"
import { CURRENCY, DATE, NUMBER, PERCENT, REF, USER } from "../../../../utils/constants/types"
import { DISABLED } from "../../../../utils/constants/inputAttrs"
import { PAYMENT_TYPES } from "../../../../utils/constants/paymentTypes"

import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { sortDocsBy } from "../../../../utils/js"
import { exportReportAPI } from "../../../pages/reports/utils"
import { promptExportAccountsReport } from "../utils"

import { Card } from "../../../commons/cardLayout/Card"
import { FormEditDoc } from "../../../commons/prompt/FormEditDoc"
import { Table } from "../../../commons/table/Table"
import { Icon } from "../../../components/icon/Icon"
import { IconButton } from "../../../components/icon/IconButton"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { ContractTotalActualsTable } from "./ContractTotalActualsTable"
import { CheckCell } from "../../../commons/checkCell/CheckCell"
import { ACCOUNTS_TOTAL, CONTRACT_PAYMENTS, CONTRACT_SECTIONS_ACTUALS, CONTRACT_SECTIONS_PLAN } from "../../../../utils/constants/contractsExportsOptions"

// attrs:
// navPageView, 
// navPageView_OPTIONS
// contract, 
// loadContract, 
// currentAccount
// parent

export const ContractTotalBillingTable = node => {


    const billingDashboardHeaders = {
        period: { label: "מס' חשבון", visible: true, group: "" },
        month: { label: "חודש ביצוע", visible: true, group: "" },
        baseTotal: { label: "קרן מאושר", visible: true, group: "" },
        totalAddSub: { label: "תוספות / קיזוזים", visible: true, group: "" },
        subTotal: { label: `סה"כ לפני עיכבון`, visible: true, group: "" },
        totalDelay: { label: "עכבון", visible: true, group: "" },
        totalAccountToPay: { label: "לתשלום", visible: true, group: "" },
        indexedPercent: { label: "% הצמדה", visible: true, group: "indexed" },
        totalIndexed: { label: "סכום הצמדה", visible: true, group: "indexed" },
        totalAfterIndexed: { label: "סכום לאחר הצמדה", visible: true, group: "indexed" },
        vatPercent: { label: "% מעמ", visible: true, group: "vat" },
        totalVAT: { label: "מעמ", visible: true, group: "vat" },
        totalBeforeTax: { label: "לתשלום לפני ניכוי", visible: true, group: "" },
        taxPercent: { label: "% ניכוי מס", visible: true, group: "tax" },
        totalTax: { label: "ניכוי מס", visible: true, group: "tax" },
        totalPay: { label: "לתשלום סופי", visible: true, group: "" },
        totalPayments: { label: "שולם", visible: true, group: "" },
    }

    const promptActualReport = () => {
        const { contract, currentAccount } = node.attrs.parent.state
        if (contract && currentAccount) {
            node.attrs.parent.state.prompt = {
                title: "דוח ביצוע מצטבר",
                class: "full-width",
                form: () => m(ContractTotalActualsTable, { contract, currentAccount })
            }
        } else {
            node.attrs.parent.state.snackbar = { msg: "לא נמצאו נתונים", isError: true }
        }
    }

    // const promptSelfForPrinting = async () => {
    //OLD:
    // const { currentAccount, contract } = node.attrs.parent.state
    // node.attrs.parent.state.prompt = {
    //     title: "דוח חשבונות מצטבר",
    //     form: () => {
    //         return m(".",
    //             m(".breadCrumbs",
    //                 m(".breadCrumbs__link", `פרוייקט: ${currentAccount.getProject("title")}`),
    //                 m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
    //                 m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
    //                 m(".breadCrumbs__link", `חשבון: ${currentAccount.docData.month}`),
    //             ),
    //             m(ContractTotalBillingTable, { parent: node.attrs.parent, isPreview: true })
    //         )
    //     }
    // }
    // }

    const toggleFilterColumn = group => {
        const { active, index } = isGroupActive(group)
        Object.entries(billingDashboardHeaders).map(([header, field]) => {
            if (field.group === group) billingDashboardHeaders[header].visible = !active
        })
        if (active) node.attrs.parent.state.activeColumnsGroup.splice(index, 1)
        else node.attrs.parent.state.activeColumnsGroup.push(group)
    }

    const isGroupActive = group => {
        const index = node.attrs.parent.state.activeColumnsGroup.findIndex(el => el === group)
        return { active: index > -1, index }
    }

    const promptContractPayments = ({ historyOnly = false } = {}) => {
        const { contract, currentAccount } = node.attrs.parent.state
        let title = `פירוט תשלומים לחוזה '${contract.docData.title}'`
        if (historyOnly) title += " (חשבונות קודמים)"
        const filterDataOptions = historyOnly ? { parentExclude: { month: currentAccount.docData.month } } : {}
        node.attrs.parent.state.prompt = {
            class: "full-width",
            title,
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${currentAccount.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${currentAccount.docData.month}`),
                    ),
                    m(Table, {
                        sortOptions: { param: "paymentDate" },
                        doc: contract,
                        title,
                        colRef: `${currentAccount.docData.ref}/${PAYMENTS}`,
                        actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false, add: false },
                        modelID: PAYMENTS,
                        filterDataOptions,
                        filterSettings: { subChild: true },
                        tableHeaders: ["paymentDate", "paymentChannel", "paymentType", "paymentIdentity", "sum"]
                    })
                )
            }
        }
    }
    const promptAccountPayments = (account, filterType) => {
        let title = `פירוט תשלומים לתקופה ['${account.docData.month}']`
        let filterDataOptions = {}
        if (filterType === PAYMENT_TYPES.DOWN_PAYMENT) {
            title += " (מקדמות)"
            filterDataOptions = { include: { paymentType: PAYMENT_TYPES.DOWN_PAYMENT } }
        } else if (filterType === PAYMENT_TYPES.ONGOING) {
            title += " (שוטף)"
            filterDataOptions = { include: { paymentType: PAYMENT_TYPES.ONGOING } }
        }
        node.attrs.parent.state.prompt = {
            class: "full-width",
            title,
            // form: () => m(TablePayments, { payments: account.getChildren(CONTRACT_PAYMENTS) }),
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${account.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${account.getContract("title")}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(account.getContract("contractorRef"), REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${account.docData.month}`),
                    ),
                    m(Table, {
                        doc: account,
                        title,
                        sortOptions: { param: "paymentDate" },
                        colRef: `${account.docData.ref}/${PAYMENTS}`,
                        actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false, add: false },
                        filterDataOptions,
                        modelID: PAYMENTS,
                        tableHeaders: ["paymentDate", "paymentChannel", "paymentType", "paymentIdentity", "sum"]
                    })
                )
            }
        }
    }
    const promptAccount = account => {
        node.attrs.parent.state.prompt = {
            title: `חשבון תקופתי ${account.docData.month}`,
            class: "full-width",
            form: () => m(FormEditDoc, {
                formStyleAreas: `
                    "title title title"
                    "month month billingDate"
                    "subTotal subTotal subTotal"
                    "delayPercentage totalDelay totalDelay"
                    "delayRelease delayRelease delayRelease"
                    "totalAccountToPay totalAccountToPay totalAccountToPay"
                    "indexedPercent totalIndexed totalIndexed"
                    "vatPercent totalVAT totalVAT"
                    "taxPercent totalTax totalTax"
                    "totalPay totalPay totalPay"
                `,
                doc: account,
                headers: {
                    month: { props: [{ [DISABLED]: true }] },
                    billingDate: { props: [{ [DISABLED]: true }] },
                    subTotal: { props: [{ [DISABLED]: true }] },
                    // subTotal: {}, //ADMIN ONLY
                    vatPercent: {},
                    delayPercentage: {},
                    totalDelay: { props: [{ [DISABLED]: true }] },
                    delayRelease: { props: [{ [DISABLED]: true }] },
                    totalAccountToPay: { props: [{ [DISABLED]: true }] },
                    totalVAT: { props: [{ [DISABLED]: true }] },
                    indexedPercent: {},
                    totalIndexed: { props: [{ [DISABLED]: true }] },
                    taxPercent: {},
                    totalTax: { props: [{ [DISABLED]: true }] },
                    totalPay: { props: [{ [DISABLED]: true }] },
                },
                parent: node
            })
        }
    }


    const promptTableSectionActuals = accountFilter => {
        const { contract, currentAccount } = node.attrs.parent.state
        const filterDataOptions = accountFilter ? {
            hasActuals: doc => {
                return doc.calcTotalActuals({ accountRef: accountFilter.docData.ref }) > 0
            }
        } : {}
        const account = accountFilter || currentAccount
        node.attrs.parent.state.prompt = {
            title: `פירוט ביצוע לפי סעיפים ${accountFilter ? accountFilter.docData.month : ""}`,
            class: "full-width",
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${account.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${account.docData.month}`),
                    ),
                    m(Table, {
                        title: "פירוט סעיפי ביצוע - ללא תוספות וקיזוזים",
                        doc: contract,
                        colRef: `${contract.docData.ref}/${CONTRACT_SECTIONS}`,
                        modelID: CONTRACT_SECTIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: true, add: false, nav: false, totals: true },
                        filterDataOptions,
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals(accountFilter ? { accountRef: account.docData.ref } : {}) }
                                },
                                type: CURRENCY
                            },
                            _totalActualDone: {
                                label: "% ביצוע מתוך הסכם",
                                val: doc => {
                                    return { value: doc.calcTotalActuals(accountFilter ? { accountRef: account.docData.ref } : {}) / doc.docData.totalSum * 100 }
                                },
                                type: PERCENT
                            },
                        }
                    })
                )
            }
        }
    }
    const promptTableSectionHisotryActuals = () => {
        const { contract, currentAccount: account } = node.attrs.parent.state
        const title = "פירוט ביצוע חשבונות קודמים לפי סעיפים - ללא תוספות וקיזוזים"
        const filterDataOptions = {
            hasActuals: doc => {
                return doc.calcLastTotalActuals({ accountRef: account.docData.ref }) > 0
            }
        }
        node.attrs.parent.state.prompt = {
            title,
            class: "full-width",
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${account.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${account.docData.month}`),
                    ),
                    m(Table, {
                        doc: contract,
                        title,
                        colRef: `${contract.docData.ref}/${CONTRACT_SECTIONS}`,
                        modelID: CONTRACT_SECTIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: true, add: false, nav: false, totals: true },
                        filterDataOptions,
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcLastTotalActuals({ accountRef: account.docData.ref }) }
                                },
                                type: CURRENCY
                            },
                            _totalActualDone: {
                                label: "% ביצוע מתוך הסכם",
                                val: doc => {
                                    return { value: doc.calcLastTotalActuals({ accountRef: account.docData.ref }) / doc.docData.totalSum * 100 }
                                },
                                type: PERCENT
                            },
                        }
                    })
                )
            }
        }
    }
    const promptTableAdditionHistoryActuals = () => {
        const { contract, currentAccount: account } = node.attrs.parent.state
        const filterDataOptions = {
            hasActuals: doc => {
                return doc.calcLastTotalActuals({ accountRef: account.docData.ref }) > 0
            }
        }
        node.attrs.parent.state.prompt = {
            title: "פירוט חשבונות קודמים - תוספות וקיזוזים",
            class: "full-width",
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${account.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${account.docData.month}`),
                    ),
                    m(Table, {
                        title: "תוספות חשבונות קודמים",
                        doc: node.attrs.parent.state.contract,
                        colRef: `${node.attrs.parent.state.contract.docData.ref}/${CONTRACT_ADDITIONS}`,
                        modelID: CONTRACT_ADDITIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, title: true },
                        filterDataOptions,
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcLastTotalActuals({ accountRef: account.docData.ref }) }
                                },
                                type: CURRENCY
                            },
                            donePercentage: {},
                        }
                    }),
                    m(Table, {
                        title: "קיזוזים חשבונות קודמים",
                        doc: node.attrs.parent.state.contract,
                        colRef: `${node.attrs.parent.state.contract.docData.ref}/${CONTRACT_SUBTRACTIONS}`,
                        modelID: CONTRACT_SUBTRACTIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, title: true },
                        filterDataOptions,
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals({ accountRef: account.docData.ref }) }
                                },
                                type: CURRENCY
                            },
                            donePercentage: {},
                        }
                    }),
                )
            }
        }
    }
    const promptTableAdditionActuals = accountFilter => {
        const { contract, currentAccount } = node.attrs.parent.state
        const filterDataOptions = account ? {
            include: {
                hasActuals: doc => {
                    return doc.calcTotalActuals({ accountRef: account.docData.ref }) > 0
                }
            }
        } : {}
        const account = accountFilter || currentAccount
        node.attrs.parent.state.prompt = {
            title: `פירוט תוספות וקיזוזים ${accountFilter ? accountFilter.docData.month : ""}`,
            class: "full-width",
            form: () => {
                return m(".",
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${account.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${account.docData.month}`),
                    ),
                    m(Table, {
                        title: "תוספות",
                        doc: node.attrs.parent.state.contract,
                        colRef: `${node.attrs.parent.state.contract.docData.ref}/${CONTRACT_ADDITIONS}`,
                        modelID: CONTRACT_ADDITIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, title: true },
                        filterDataOptions,
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals(accountFilter ? { accountRef: account.docData.ref } : {}) }
                                },
                                type: CURRENCY
                            },
                            donePercentage: {},
                        }
                    }),
                    m(Table, {
                        title: "קיזוזים",
                        doc: node.attrs.parent.state.contract,
                        colRef: `${node.attrs.parent.state.contract.docData.ref}/${CONTRACT_SUBTRACTIONS}`,
                        modelID: CONTRACT_SUBTRACTIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, title: true },
                        filterDataOptions,
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals(accountFilter ? { accountRef: account.docData.ref } : {}) }
                                },
                                type: CURRENCY
                            },
                            donePercentage: {},
                        }
                    }),
                )
            }
        }
    }

    return {
        oninit: vnode => {
            // const { contract } = vnode.attrs.parent.state
            // contract.listenToChildren()
        },
        view: vnode => {
            const { contract, loadSections, currentAccount } = vnode.attrs.parent.state
            return m(Card, { class: `contract__totals reportTable card--scroll ${vnode.attrs.isPreview ? "print" : "no-print"}`, columns: Object.entries(billingDashboardHeaders).filter(([_, { visible }]) => visible).length },
                !vnode.attrs.isPreview && m(IconButton, { action: e => promptExportAccountsReport(node.attrs.parent), icon: "icon-print" }),
                m(".caption accounting__caption",
                    "דוח חשבונות מצטבר",
                    !vnode.attrs.isPreview && m("button.button button--text", { onclick: e => promptActualReport() }, "הצג דוח ביצוע", m(Icon, { icon: "icon-new-tab" })),
                ),
                !vnode.attrs.isPreview && m(".filterColumns no-print",
                    // indexed
                    m(".filterColumns__button", { class: isGroupActive("indexed").active ? "active" : "", onclick: e => toggleFilterColumn("indexed") }, "הצמדה"),
                    // vat
                    m(".filterColumns__button", { class: isGroupActive("vat").active ? "active" : "", onclick: e => toggleFilterColumn("vat") }, "מעמ"),
                    // tax
                    m(".filterColumns__button", { class: isGroupActive("tax").active ? "active" : "", onclick: e => toggleFilterColumn("tax") }, "ניכוי מס"),
                ),
                (!contract || loadSections || !currentAccount) ?
                    m(InlineLoaderDots) :
                    [
                        Object.entries(billingDashboardHeaders).map(([header, { label, visible }]) => {
                            if (visible) {
                                return m(".cell cell--header", { key: header }, label)
                            }
                        }),

                        m(".cell row cell--title", "חשבונות קודמים"),

                        contract.getChildren(CONTRACT_ACCOUNTS)
                            .sort(sortDocsBy("period", { type: NUMBER }))
                            .map((account, accountIndex, arr) => {
                                const isCurrent = account.docData.month === currentAccount.docData.month
                                const accountRef = account.docData.ref
                                const delayPercentage = parseFloat(contract.docData.delayPercentage / 100)
                                //total
                                const totalActuals = contract.getTotalAccounts("totalSections")
                                const totalAdditions = contract.getTotalAccounts("totalAdditions")
                                const totalSubtractions = contract.getTotalAccounts("totalSubtractions")
                                const totalAddSub = totalAdditions - totalSubtractions
                                const totalDelay = contract.getTotalAccounts("totalDelay") - contract.getTotalAccounts("delayRelease")
                                const totalAccountsToPay = contract.getTotalAccounts("totalAccountToPay")
                                const totalIndexed = contract.getTotalAccounts("totalIndexed")
                                const totalAfterIndexed = contract.getTotalAccounts("totalAfterIndexed")
                                const totalTax = contract.getTotalAccounts("totalTax")
                                const totalVAT = contract.getTotalAccounts("totalVAT")
                                const totalPay = contract.getTotalAccounts("totalPay")
                                const totalBeforeTax = totalPay + totalTax
                                //current
                                const currentActuals = parseFloat(account.docData.totalSections)
                                const currentAdditions = parseFloat(account.docData.totalAdditions)
                                const currentSubtractions = parseFloat(account.docData.totalSubtractions)
                                const currentAddSub = currentAdditions - currentSubtractions
                                const currentDelay = parseFloat(account.docData.totalDelay) - parseFloat(account.docData.delayRelease)
                                const currentAccountToPay = parseFloat(account.docData.totalAccountToPay)
                                //history
                                const historyActuals = totalActuals - currentActuals
                                const historyAdditions = totalAdditions - currentAdditions
                                const historySubtractions = totalSubtractions - currentSubtractions
                                const historyAddSub = historyAdditions - historySubtractions
                                const historyAfterIndexed = totalAfterIndexed - parseFloat(account.docData.totalAfterIndexed)
                                const historyDelay = totalDelay - currentDelay
                                const historySubTotal = historyActuals + historyAddSub - historyDelay
                                const historyVAT = totalVAT - parseFloat(account.docData.totalVAT)
                                const historyIndexed = totalIndexed - parseFloat(account.docData.totalIndexed)
                                const historyTax = totalTax - parseFloat(account.docData.totalTax)
                                const historyTotalPay = totalPay - parseFloat(account.docData.totalPay)
                                const historyBeforeTax = historyTotalPay + historyTax

                                const isZeroIndexed = parseFloat(account.docData.indexedPercent) === 100;
                                let displayIndexedValue = getDisplayValue(account.docData.indexedPercent, PERCENT)
                                if (isZeroIndexed) displayIndexedValue = "-"

                                const showTax = vnode.attrs.isPreview ? true : isGroupActive("tax").active
                                const showVAT = vnode.attrs.isPreview ? true : isGroupActive("vat").active
                                const showIndexed = vnode.attrs.isPreview ? true : isGroupActive("indexed").active


                                if (isCurrent) {
                                    return [
                                        //SUBTOTAL HISTORY: 
                                        //מס' חשבון - כמות שורות
                                        m(".row__total--sub ", "סהכ"),
                                        //חודש ביצוע- תקופה
                                        m(".row__total--sub", "----"/* `${1 + monthDiff(new Date(contract.docData.sWorkDate), textToDate(currentAccount.docData.month))} חודשים` */),
                                        //"מצטבר קרן" TESTME:
                                        m(".row__total--sub clickable", { onclick: e => promptTableSectionHisotryActuals() }, getDisplayValue(historyActuals, CURRENCY)),
                                        //"מצטבר תוספות / קיזוזים" TESTME:
                                        m(".row__total--sub clickable", { onclick: e => promptTableAdditionHistoryActuals() }, getDisplayValue(historyAddSub, CURRENCY)),
                                        //"סה"כ מצטבר"
                                        m(".row__total--sub", getDisplayValue(historyActuals + historyAddSub, CURRENCY)),
                                        //"עכבון"
                                        m(".row__total--sub", getDisplayValue(historyDelay, CURRENCY)),
                                        //"לתשלום"
                                        m(".row__total--sub", getDisplayValue(historySubTotal, CURRENCY)),
                                        // "% הצמדה"
                                        showIndexed && m(`.row__total--sub`, "-"),
                                        // "סכום הצמדה"
                                        showIndexed && m(`.row__total--sub`, getDisplayValue(historyIndexed, CURRENCY)),
                                        // "סכום לאחר הצמדה"
                                        showIndexed && m(`.row__total--sub`, getDisplayValue(historyAfterIndexed, CURRENCY)),
                                        // "% מעמ"
                                        showVAT && m(`.row__total--sub`, "-"),
                                        // "מעמ"
                                        showVAT && m(`.row__total--sub`, getDisplayValue(historyVAT, CURRENCY)),
                                        // "לתשלום לפני ניכוי"
                                        m(`.row__total--sub bold`, getDisplayValue(historyBeforeTax, CURRENCY)),
                                        //"% ניכוי מס"
                                        showTax && m(`.row__total--sub`, "-"),
                                        // "ניכוי מס"
                                        showTax && m(`.row__total--sub`, getDisplayValue(historyTax, CURRENCY)),
                                        // "לתשלום סופי"
                                        m(`.row__total--sub`, getDisplayValue(historyTotalPay, CURRENCY)),
                                        // "שולם"
                                        m(`.row__total--sub clickable`, { onclick: e => promptContractPayments({ historyOnly: true }) }, getDisplayValue(contract.getTotalPayments({ filterAccounts: { exclude: { month: currentAccount.docData.month } } }), CURRENCY)),

                                        //CURRENT: 
                                        m(".cell row cell--title", "נוכחי"),
                                        //"מס' חשבון"
                                        m(`.cell cell--value current`, account.docData.period),
                                        //`חודש ביצוע`
                                        m(`.cell cell--value current clickable [dir=ltr]`, { onclick: e => promptAccount(account) }, account.docData.month),
                                        //`מצטבר קרן`
                                        m(`.cell cell--value clickable current`, { onclick: e => promptTableSectionActuals(account) }, getDisplayValue(currentActuals, CURRENCY)),
                                        //`מצטבר תוספות / קיזוזים`
                                        m(`.cell cell--value clickable current`, { onclick: e => promptTableAdditionActuals(account) }, getDisplayValue(currentAddSub, CURRENCY)),
                                        //`סה`כ מצטבר`
                                        m(`.cell cell--value current`, getDisplayValue(currentActuals + currentAddSub, CURRENCY)),
                                        //`עכבון`
                                        m(`.cell cell--value current`, getDisplayValue(currentDelay, CURRENCY)),
                                        //`לתשלום`
                                        m(`.cell cell--value current`, getDisplayValue(currentAccountToPay, CURRENCY)),
                                        // "% הצמדה"
                                        showIndexed && m(`.cell cell--value current`, displayIndexedValue),
                                        // "לאחר הצמדה"
                                        showIndexed && m(`.cell cell--value current`, getDisplayValue(account.docData.totalIndexed, CURRENCY)),
                                        // "סכום לאחר הצמדה"
                                        showIndexed && m(`.cell cell--value current`, getDisplayValue(account.docData.totalAfterIndexed, CURRENCY)),
                                        // "% מעמ"
                                        showVAT && m(`.cell cell--value current`, getDisplayValue(account.docData.vatPercent, PERCENT)),
                                        // "מעמ"
                                        showVAT && m(`.cell cell--value current`, getDisplayValue(account.docData.totalVAT, CURRENCY)),
                                        // "לתשלום לפני ניכוי"
                                        m(`.cell cell--value current`, getDisplayValue(parseFloat(account.docData.totalPay) + parseFloat(account.docData.totalTax), CURRENCY)),
                                        //"% ניכוי מס"
                                        showTax && m(`.cell cell--value current`, getDisplayValue(account.docData.taxPercent, PERCENT)),
                                        // "ניכוי מס"
                                        showTax && m(`.cell cell--value current`, getDisplayValue(account.docData.totalTax, CURRENCY)),
                                        // "לתשלום סופי"                                                                                                    
                                        m(`.cell cell--value current`, getDisplayValue(account.docData.totalPay, CURRENCY)),
                                        // "שולם"
                                        m(`.cell cell--value current clickable`, { onclick: e => promptAccountPayments(account) }, getDisplayValue(account.getTotalPayments(), CURRENCY)),

                                        //TOTAL MAIN: 
                                        //מס' חשבון - כמות שורות
                                        m(".row__total--main ", "סהכ לכל התקופות"),
                                        //חודש ביצוע- תקופה
                                        m(".row__total--main", "----"/* `${1 + monthDiff(new Date(contract.docData.sWorkDate), textToDate(currentAccount.docData.month))} חודשים` */),
                                        //"מצטבר קרן" TESTME: prompt on clic
                                        m(".row__total--main clickable", { onclick: e => promptTableSectionActuals() }, getDisplayValue(totalActuals, CURRENCY)),
                                        //"מצטבר תוספות / קיזוזים"  TESTME: prompt on clic
                                        m(".row__total--main clickable", { onclick: e => promptTableAdditionActuals() }, getDisplayValue(totalAddSub, CURRENCY)),
                                        //"סה"כ מצטבר"
                                        m(".row__total--main", getDisplayValue(totalActuals + totalAddSub, CURRENCY)),
                                        //"עכבון"
                                        m(".row__total--main", getDisplayValue(totalDelay, CURRENCY)),
                                        //"לתשלום"
                                        m(".row__total--main", getDisplayValue(totalAccountsToPay, CURRENCY)),
                                        // "% הצמדה"
                                        showIndexed && m(`.row__total--main`, "-"),
                                        // "סכום הצמדה"
                                        showIndexed && m(`.row__total--main`, getDisplayValue(totalIndexed, CURRENCY)),
                                        // "סכום לאחר הצמדה"
                                        showIndexed && m(`.row__total--main`, getDisplayValue(totalAfterIndexed, CURRENCY)),
                                        // "% מעמ"
                                        showVAT && m(`.row__total--main`, "-"),
                                        // "מעמ"
                                        showVAT && m(`.row__total--main`, getDisplayValue(totalVAT, CURRENCY)),
                                        // "לתשלום לפני ניכוי"
                                        m(`.row__total--main bold`, getDisplayValue(totalBeforeTax, CURRENCY)),
                                        //"% ניכוי מס"
                                        showTax && m(`.row__total--main`, "-"),
                                        // "ניכוי מס"
                                        showTax && m(`.row__total--main`, getDisplayValue(totalTax, CURRENCY)),
                                        // "לתשלום סופי"
                                        m(`.row__total--main`, getDisplayValue(totalPay, CURRENCY)),
                                        // "שולם"
                                        m(`.row__total--main clickable`, { onclick: e => promptContractPayments() }, getDisplayValue(contract.getTotalPayments(), CURRENCY)),
                                    ]
                                } else {
                                    return [
                                        //"מס' חשבון"
                                        m(`.cell cell--value`, account.docData.period),
                                        //`חודש ביצוע`
                                        m(`.cell cell--value clickable [dir=ltr]`, { onclick: e => promptAccount(account) }, account.docData.month),
                                        //`מצטבר קרן`
                                        m(`.cell cell--value clickable`, { onclick: e => promptTableSectionActuals(account) }, getDisplayValue(currentActuals, CURRENCY)),
                                        //`מצטבר תוספות / קיזוזים`
                                        m(`.cell cell--value clickable`, { onclick: e => promptTableAdditionActuals(account) }, getDisplayValue(currentAddSub, CURRENCY)),
                                        //`סה`כ מצטבר`
                                        m(`.cell cell--value`, getDisplayValue(currentActuals + currentAddSub, CURRENCY)),
                                        //`עכבון`
                                        m(`.cell cell--value`, getDisplayValue(currentDelay, CURRENCY)),
                                        //`לתשלום`
                                        m(`.cell cell--value`, getDisplayValue(currentActuals + currentAddSub - currentDelay, CURRENCY)),
                                        // "% הצמדה"
                                        showIndexed && m(`.cell cell--value`, displayIndexedValue),
                                        // "סכום הצמדה"
                                        showIndexed && m(`.cell cell--value`, getDisplayValue(account.docData.totalIndexed, CURRENCY)),
                                        // "לאחר הצמדה"
                                        showIndexed && m(`.cell cell--value`, getDisplayValue(account.docData.totalAfterIndexed, CURRENCY)),
                                        // "% מעמ"
                                        showVAT && m(`.cell cell--value`, getDisplayValue(account.docData.vatPercent, PERCENT)),
                                        // "מעמ"
                                        showVAT && m(`.cell cell--value`, getDisplayValue(account.docData.totalVAT, CURRENCY)),
                                        // "לתשלום לפני ניכוי"
                                        m(`.cell cell--value`, getDisplayValue(parseFloat(account.docData.totalPay) + parseFloat(account.docData.totalTax), CURRENCY)),
                                        //"% ניכוי מס"
                                        showTax && m(`.cell cell--value`, getDisplayValue(account.docData.taxPercent, PERCENT)),
                                        // "ניכוי מס"
                                        showTax && m(`.cell cell--value`, getDisplayValue(account.docData.totalTax, CURRENCY)),
                                        // "לתשלום סופי"
                                        m(`.cell cell--value`, getDisplayValue(account.docData.totalPay, CURRENCY)),
                                        // "שולם"
                                        m(`.cell cell--value clickable`, { onclick: e => promptAccountPayments(account) }, getDisplayValue(account.getTotalPayments(), CURRENCY)),
                                    ]
                                }
                            })
                    ],
            ) // END TOTALS CARD
        }
    }
}