import m from "mithril"

import { SWITCH, STRING, REF } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, HIDDEN } from "../../../utils/constants/inputAttrs";
import { FILES, USERS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { fileStatusTypes } from "../../../utils/constants/statusTypes";
import { auth } from "../../../index";
import { isSuperAdmin } from "../../utils/permission";
import { getModelID } from "../../utils/utils";

export const optionalFilelHeaders = {
    attachmentID: {
        label: "מזהה אסמכתא",
        defaultValue: "",
        type: STRING,
    },
    sectionRef: {
        label: "קישור לסעיף",
        defaultValue: "",
        type: REF,
        options: { param: "title", filters: {} }
    },
    accountRef: {
        label: "קישור לחשבון",
        defaultValue: "",
        type: REF,
        options: { param: "month", filters: {} }
    },
}

export class FileDoc extends DocModel {

    constructor(data, isNew = true, _model) {
        const model = _model || FileDoc
        super({
            data,
            isNew,
            model,
        });
    };
    async remove() {
        if (!this.isNew && isSuperAdmin()) {
            try {
                const childRef = this.docData.ref
                // gs://b-cont.appspot.com/{{childRef}}
                const child = firebase.storage().ref().child(childRef)
                await child.delete()
            } catch (err) {
                console.error("error on FileDoc.remove() \n", err);
            } finally {
                m.redraw()
            }
        }
        super.remove()
    }
    async save() {
        if (this.isNew) {
            this.docData.uploadValue = 0
            this.docData.progress = true
            const child = firebase.storage().ref().child(`${this.docData.ref}`)
            const metadata = {
                customMetadata: {
                    "fileName": this.docData.name,
                    'createdAt': +new Date(),
                    'createdBy': auth.currentUser.uid,
                    'ref': this.docData.ref
                }
            }
            const task = child.put(this.docData.file, metadata)
            delete this.docData.file

            const next = _snap => {
                const progress = (_snap.bytesTransferred / _snap.totalBytes) * 100;
                this.docData.uploadValue = progress
                console.log('Upload is ' + progress + '% done');
            }
            const error = _error => {
                console.error("error on file upload: ", _error)
            }
            const complete = async () => {
                try {
                    const url = await task.snapshot.ref.getDownloadURL()
                    this.saveLocal({ url })
                    this.docData.progress = false
                    //FILE UPLOAD AUTOMATION
                    if (getModelID(this.getParent("ref")) === USERS) {
                        const parent = this.getParent()
                        parent.saveLocal({ photoURL: url })
                        await parent.save()
                    }
                    return super.save()
                } catch (err) {
                    console.error(err);
                }
            }
            task.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                next,
                error,
                complete
            )
        } else {
            try {
                const fileRef = firebase.storage().ref().child(this.docData.ref)
                const metadata = {
                    customMetadata: {
                        "fileName": this.docData.title,
                        'updatedAt': +new Date(),
                        'updatedBy': auth.currentUser.uid
                    }
                }
                await fileRef.updateMetadata(metadata)
                m.redraw()
            } catch (err) {
                console.error(err)
            }
            super.save()
        }
    }

    static get meta() {
        return {
            id: FILES,
        }
    }
    static get headers() {
        return {
            title: { label: "שם קובץ", defaultValue: "--קובץ--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            description: { label: 'הערות', defaultValue: "", },
            status: { label: 'סטטוס', defaultValue: "open", type: SWITCH, options: fileStatusTypes, fieldOptions: { search: false } },
            contentType: { label: "סוג קובץ", defaultValue: "", props: [{ [HIDDEN]: true }] },
            size: { label: "גודל", defaultValue: "", props: [{ [HIDDEN]: true }] },
            url: { label: "URL", defaultValue: "", props: [{ [HIDDEN]: true }] },
            file: { label: "fileBlob", defaultValue: "", props: [{ [HIDDEN]: true }] },
            //ref to account [contract-attachments]...
        }
    }
}
