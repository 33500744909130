import m from "mithril";


import { dataStore } from "../store/dataStore"
import { auth, db } from "../../index";
import { settingsStore } from "../store/settingsStore";

/**
 * @param {String} modelID source id on store object - ex: projects
 * @return {Function} unsubscribe function which you could use later
 */
export function queryDataListener(modelID, query, docBuilder, refQry) {
    const unsubscribe = query.onSnapshot(snap => {
        // if (snap.empty) return
        snap.docChanges().forEach(change => {
            //build doc as class
            const doc = change.doc
            let parent = doc.ref.parent
            if (parent == null) parent = doc.ref //root folder
            const docData = Object.assign({},
                doc.data(),
                {
                    colRef: parent.path,
                    ref: doc.ref.path,
                    docID: doc.id
                }
            )
            try {
                const docClass = new docBuilder(docData, false)
                //sync to store:data[]
                const dataIndex = dataStore[modelID].data.findIndex((elem) => elem.docData.ref === docData.ref)
                // console.log(`change.type=${change.type}`);
                if (change.type === "added" || change.type === "modified") {
                    if (dataIndex !== -1) dataStore[modelID].data[dataIndex] = docClass
                    else dataStore[modelID].data.push(docClass)
                } else if (change.type === "removed") {
                    if (dataIndex !== -1) {
                        const docToRemove = dataStore[modelID].data[dataIndex]
                        docToRemove.isRemoved = true
                        dataStore[modelID].data.splice(dataIndex, 1)
                    }
                }
                //remove from store:new[]
                const newIndex = dataStore[modelID].new.findIndex((elem) => elem.docData.ref === docData.ref)
                if (newIndex !== -1) dataStore[modelID].new.splice(newIndex, 1)
            } catch (e) {
                console.error(e)
            }

        })
        m.redraw();
    })
    // console.log('model ',modelID," after listen: \n",dataStore[modelID])
    settingsStore.listeners.push({ modelID, unsubscribe, path: refQry })
    return unsubscribe
}