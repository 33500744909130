import { SWITCH, DATE, STRING, NUMBER, PERCENT, VALID_COMPARE } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, MIN, MAX, PATTERN, UNIQUE } from "../../../utils/constants/inputAttrs";
import { VENDORS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { REG_COMPANY_NUMBER_PATTERN, REG_PHONE_PATTERN, REG_EMAIL_PATTERN } from "../../../utils/constants/regex";
import { statusTypes } from "../../../utils/constants/statusTypes";

export class VendorDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: VendorDoc,
        });
        //listen to contracts
        if (!this.isNew) this.listenToChildren()
    };
    static get meta() {
        return {
            id: VENDORS,
            routes: {
                collection: "/vendors",
                doc: "/vendors/:vendorID",
            },
            basicHeaders: ["title", "commercialName"],
            conditionalFormatting: [
                {
                    header: "taxesEndDate",
                    logic: VALID_COMPARE,
                    expression: "taxesEndDate > _NOW_",
                    classStyle: "danger"
                }
            ],
        }
    }
    static get headers() {
        return {
            title: { label: "קבלן", defaultValue: "--קבלן חדש--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }, { [UNIQUE]: true }] },
            commercialName: { label: 'שם מסחרי', defaultValue: "--ללא שם--" },
            companyNumber: { label: 'ח.פ.', defaultValue: "", props: [{ [PATTERN]: REG_COMPANY_NUMBER_PATTERN }, { [UNIQUE]: true }] },
            companyType: { label: "סוג עוסק", defaultValue: "company", type: SWITCH, options: [{ text: "חברה בעמ", id: "company" }, { text: "עוסק מורשה", id: "licensedDealer" }] },
            contractorSAPNumber: { label: 'קוד קבלן בסאפ', defaultValue: "11111" },
            phone: { label: 'טלפון', defaultValue: "", props: [{ [PATTERN]: REG_PHONE_PATTERN }] },
            email: { label: 'אימייל', defaultValue: "", props: [{ [PATTERN]: REG_EMAIL_PATTERN }] },
            taxPercent: { label: "% ניכוי מס", defaultValue: 0, type: PERCENT, props: [{ [MIN]: 0 }, { [MAX]: 50 }] },
            taxesEndDate: { label: 'תוקף ניכוי מס במקור', defaultValue: new Date().toISOString(), type: DATE },
            address: { label: 'כתובת', defaultValue: "--ללא כתובת--", },
            description: { label: 'הערות', defaultValue: "", },
            status: { label: 'סטטוס', defaultValue: "active", type: SWITCH, options: statusTypes },
        }
    }
}