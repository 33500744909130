import m from "mithril";
import { preventDefaults } from "../../../utils/js";

import { getHebDate } from "../../../utils/libs";

import { Icon } from "../icon/Icon";
import { InlineLoaderDots } from "../inlineLoaderDots/InlineLoaderDots";

export const Tooltip = node => {


    const toggleShow = e => {
        preventDefaults(e)
        node.state.show = !node.state.show;
    }

    return {
        show: true,
        // hebDate: false,
        // oninit: vnode => {
        //     const { options = {} } = vnode.attrs
        //     const { date } = options
        //     if (date) {
        //         node.state.hebDate = getHebDate(date)
        //     }
        // },
        view: vnode => {
            const { options = {}, text, location = "" } = vnode.attrs
            const { email, date } = options
            // const { hebDate } = vnode.state
            return m(`.tooltip ${vnode.state.show ? 'show' : 'hide'} ${location}`, {
                onclick: e => preventDefaults(e)
            },
                // m(Icon, { icon: "icon-x", class: "tooltip__close icon--md", action: e => toggleShow(e) }),
                email && m('a.tooltip__text', { href: `mailto:${email}?subject=Mail%20from%20Bcont`, target: "_blank", onclick: e => e.stopPropagation() }, text),
                date && m('.tooltip__text', text),
                // (date && hebDate) && m('.tooltip__text', hebDate),
                Object.keys(options).length === 0 && m('p.tooltip__text', /* { onclick: e => preventDefaults(e) }, */ text),
            )
        }
    }
}