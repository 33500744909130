import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Card } from '../../../commons/cardLayout/Card';
import { Prompt } from '../../../commons/prompt/Prompt';
import { Icon } from '../../../components/icon/Icon';
import { CURRENCY, DATE, DIFF, LIST, NUMBER, PERCENT, STRING } from '../../../../utils/constants/types';
import { MonthSelector } from '../../../commons/confirmBlock/MonthSelect';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { Snackbar } from '../../../components/snackbar/Snackbar';

//DATA
import { PROJECTS, CONTRACTS, CONTRACT_SECTIONS, CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, CONTRACT_BILLINGS, VENDORS, PAYMENTS, CONTRACT_COMMENTS, BILLINGS, CONTRACT_PAYMENTS, CONFIRMS } from '../../../../data/dictionary/routeNames';
import { REG_URL_LAST_PART$NQ, REG_URL_QUERY_PART } from '../../../../utils/constants/regex';
import { dataStore } from '../../../../data/store/dataStore';
import { ContractDoc } from '../../../../data/store/contracts/contractClass';

// FUNCTIONS
import { listenToMilestones, handleAddComment, handleEditComment, toggleCommentView, getCurrentAccount, defaultdDayDisplay, handleConfirmStage, handleAddAccount, changeAccount, promptComments, togglePinComment } from '../utils';
import { DisplayCell } from '../../../commons/inputCell/DisplayCell';
import { editValue, getDisplayValue } from '../../../../data/utils/inputValidation';
import { formatDateDisplay, sortDocsBy, diffDays, uuid, addMonths, formatDateDisplay2, textToDate } from '../../../../utils/js';
import { Nav } from '../../../commons/nav/Nav';
import { PaymentDoc } from '../../../../data/store/contractsActuals/paymentClass';
import { FormEditPayment } from './FormEditPayment';
import { AccountingForm } from './AccountingForm';
import { isUserAllow } from '../../../../data/utils/permission';
import { CREATE, UPDATE } from '../../../../data/store/permissionStore';
import { DEFAULT_USER_NOT_ALLOW_MSG, settingsStore } from '../../../../data/store/settingsStore';
import { UserDoc } from '../../../../data/store/users/userClass';
import { Table } from '../../../commons/table/Table';
import { DocModel } from '../../../../data/store/docModel';
import { FormEditDoc } from '../../../commons/prompt/FormEditDoc';
import { DISABLED } from '../../../../utils/constants/inputAttrs';
import { PAYMENT_TYPES } from '../../../../utils/constants/paymentTypes';
import { buildHeaders } from '../../../../data/CRUD/utils';
import { isSystemMode } from '../../../../data/utils/viewUtils';
import { paymentDisplayReport } from './paymentReport';
import { auth } from '../../../../index';
import { ContractTotalBillingTable } from './ContractTotalBillingTable';
import { logRouteChangeUser } from '../../../../data/utils/utils';

export const ContractBilling = node => {

    const findContract = () => {
        // const contract = dataStore[CONTRACTS].data.find(doc => doc.docData.docID === node.attrs.contractID)
        const contract = DocModel.getChild(CONTRACTS, `projects/${node.attrs.projectID}/contracts/${node.attrs.contractID}`)
        if (contract) {
            node.state.contract = contract
        } else {
            const navToPath = m.route.get().replace(REG_URL_LAST_PART$NQ, "").replace(REG_URL_QUERY_PART, "")
            m.route.set(navToPath);
            m.redraw()
        }
    }
    const contractHeaders = {
        title: {},
        contractorRef: {},
        _vendorSAPcode: {
            label: "קוד בסאפ",
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) {
                    value = vendorDoc.docData.contractorSAPNumber
                }
                return { value }
            }
        },
        _vendorCompayNumber: {
            label: "מספר עוסק / ח.פ",
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) value = vendorDoc.docData.companyNumber
                return { value }
            }
        },
        _vendorTaxPercent: {
            label: "% ניכוי מס של קבלן",
            type: PERCENT,
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) value = vendorDoc.docData.taxPercent
                return { value }
            }
        },
        _vendorTaxesEndDate: {
            label: "תוקף ניכוי מס",
            type: DATE,
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) value = vendorDoc.docData.taxesEndDate
                return { value }
            }
        },
        budgetbudgetaryItem: {},
        isIndexed: {},
        indexedFactor: {},
        contractSum: {},
        paymentDelay: {},
        calculationMethod: {},
        delayPercentage: {},
        sWorkDate: {},
        eWorkDate: {},
        status: {},
        description: {},
    }

    const navStage = [
        {
            label: "ביצוע",
            path: m.route.get().replace("/billing", "/actual"),
            active: false,
        },
        {
            label: "הנהלת חשבונות",
            // path: m.route.get().replace("/actual", "/billing"),
            active: true,
        },
    ]

    // const promptActualReport = () => {
    //     const { contract, currentAccount } = node.state
    //     if (contract && currentAccount) {
    //         node.state.prompt = {
    //             title: "דוח ביצוע מצטבר",
    //             class: "full-width",
    //             form: () => m(ContractTotalActualsTable, { contract, currentAccount })
    //         }
    //     } else {
    //         node.state.snackbar = { msg: "לא נמצאו נתונים", isError: true }
    //     }
    // }
    // const promptBillingReport = () => {
    //     const { contract, currentAccount } = node.state
    //     if (contract && currentAccount) {
    //         node.state.prompt = {
    //             title: "דוח חשבונות מצטבר",
    //             class: "full-width",
    //             form: () => m(ContractTotalBillingTable, { parent: node })
    //         }
    //     } else {
    //         node.state.snackbar = { msg: "לא נמצאו נתונים", isError: true }
    //     }
    // }
    const promptPaymentReport = () => {
        const { contract, currentAccount: account } = node.state
        const vendor = DocModel.getDoc(contract.docData.contractorRef)
        const project = contract.getParent()
        if (vendor && project && contract && account) {
            node.state.prompt = {
                title: "צור הוראת תשלום",
                class: "full-width",
                form: () => m(paymentDisplayReport, { vendor, contract, account, vendor, project })
            }
        } else {
            node.state.snackbar = { msg: "לא נמצאו נתונים", isError: true }
        }
    }
    const promptContractPayments = ({ historyOnly = false } = {}) => {
        const { contract, currentAccount } = node.state
        let title = `פירוט תשלומים לחוזה '${contract.docData.title}'`
        if (historyOnly) title += " (חשבונות קודמים)"
        const filterDataOptions = historyOnly ? { parentExclude: { month: currentAccount.docData.month } } : {}
        node.state.prompt = {
            class: "full-width",
            title,
            form: () => {
                return m(Table, {
                    sortOptions: { param: "paymentDate" },
                    doc: contract,
                    title,
                    colRef: `${currentAccount.docData.ref}/${PAYMENTS}`,
                    actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false, add: false },
                    modelID: PAYMENTS,
                    filterDataOptions,
                    filterSettings: { subChild: true },
                    tableHeaders: ["paymentDate", "paymentChannel", "paymentType", "paymentIdentity", "sum"]
                })
            }
        }
    }
    const promptAccountPayments = (account, filterType) => {
        let title = `פירוט תשלומים לתקופה ['${account.docData.month}']`
        let filterDataOptions = {}
        if (filterType === PAYMENT_TYPES.DOWN_PAYMENT) {
            title += " (מקדמות)"
            filterDataOptions = { include: { paymentType: PAYMENT_TYPES.DOWN_PAYMENT } }
        } else if (filterType === PAYMENT_TYPES.ONGOING) {
            title += " (שוטף)"
            filterDataOptions = { include: { paymentType: PAYMENT_TYPES.ONGOING } }
        }
        node.state.prompt = {
            class: "full-width",
            title,
            // form: () => m(TablePayments, { payments: account.getChildren(CONTRACT_PAYMENTS) }),
            form: () => {
                return m(Table, {
                    doc: account,
                    title,
                    sortOptions: { param: "paymentDate" },
                    colRef: `${account.docData.ref}/${PAYMENTS}`,
                    actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false, add: false },
                    filterDataOptions,
                    modelID: PAYMENTS,
                    tableHeaders: ["paymentDate", "paymentChannel", "paymentType", "paymentIdentity", "sum"]
                })
            }
        }
    }
    const promptAccount = account => {
        node.state.prompt = {
            title: `חשבון תקופתי ${account.docData.month}`,
            class: "full-width",
            form: () => m(FormEditDoc, {
                formStyleAreas: `
                    "title title title"
                    "month month billingDate"
                    "subTotal subTotal subTotal"
                    "delayPercentage totalDelay totalDelay"
                    "delayRelease delayRelease delayRelease"
                    "totalAccountToPay totalAccountToPay totalAccountToPay"
                    "indexedPercent totalIndexed totalIndexed"
                    "vatPercent totalVAT totalVAT"
                    "taxPercent totalTax totalTax"
                    "totalPay totalPay totalPay"
                `,
                doc: account,
                headers: {
                    month: { props: [{ [DISABLED]: true }] },
                    billingDate: { props: [{ [DISABLED]: true }] },
                    subTotal: { props: [{ [DISABLED]: true }] },
                    // subTotal: {}, //ADMIN ONLY
                    vatPercent: {},
                    delayPercentage: {},
                    totalDelay: { props: [{ [DISABLED]: true }] },
                    delayRelease: { props: [{ [DISABLED]: true }] },
                    totalAccountToPay: { props: [{ [DISABLED]: true }] },
                    totalVAT: { props: [{ [DISABLED]: true }] },
                    indexedPercent: {},
                    totalIndexed: { props: [{ [DISABLED]: true }] },
                    taxPercent: {},
                    totalTax: { props: [{ [DISABLED]: true }] },
                    totalPay: { props: [{ [DISABLED]: true }] },
                },
                parent: node
            })
        }
    }


    const promptTableSectionActuals = account => {
        node.state.prompt = {
            title: "פירוט ביצוע לפי סעיפים",
            class: "full-width",
            form: () => {
                return m(Table, {
                    doc: node.state.contract,
                    colRef: `${node.state.contract.docData.ref}/${CONTRACT_SECTIONS}`,
                    modelID: CONTRACT_SECTIONS,
                    actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                    viewOptions: { filters: true, add: false, nav: false, totals: true },
                    filterDataOptions: {
                        include: {
                            hasActuals: doc => {
                                return doc.calcTotalActuals({ accountRef: account.docData.ref }) > 0
                            }
                        }
                    },
                    tableHeaders: {
                        title: {},
                        totalSum: {},
                        _totalCurrent: {
                            label: "סכום נוכחי",
                            val: doc => {
                                return { value: doc.calcTotalActuals({ accountRef: account.docData.ref }) }
                            },
                            type: CURRENCY
                        },
                        _totalActual: {
                            label: "סכום כולל ביצוע",
                            val: doc => {
                                return { value: doc.calcTotalActuals() }
                            },
                            type: CURRENCY
                        },
                    }
                })
            }
        }
    }
    const promptTableAdditionActuals = account => {
        node.state.prompt = {
            title: "פירוט תוספות וקיזוזים",
            class: "full-width",
            form: () => {
                return m(".",
                    m(Table, {
                        title: "תוספות",
                        doc: node.state.contract,
                        colRef: `${node.state.contract.docData.ref}/${CONTRACT_ADDITIONS}`,
                        modelID: CONTRACT_ADDITIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, title: true },
                        filterDataOptions: {
                            include: {
                                hasActuals: doc => {
                                    return doc.calcTotalActuals({ accountRef: account.docData.ref }) > 0
                                }
                            }
                        },
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals({ accountRef: account.docData.ref }) }
                                },
                                type: CURRENCY
                            },
                            _totalActual: {
                                label: "סכום כולל ביצוע",
                                val: doc => {
                                    return { value: doc.calcTotalActuals() }
                                },
                                type: CURRENCY
                            },
                        }
                    }),
                    m(Table, {
                        title: "קיזוזים",
                        doc: node.state.contract,
                        colRef: `${node.state.contract.docData.ref}/${CONTRACT_SUBTRACTIONS}`,
                        modelID: CONTRACT_SUBTRACTIONS,
                        actions: { onAdd: false, onRow: false, copy: false, remove: false, move: false, enlarge: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, title: true },
                        filterDataOptions: {
                            include: {
                                hasActuals: doc => {
                                    return doc.calcTotalActuals({ accountRef: account.docData.ref }) > 0
                                }
                            }
                        },
                        tableHeaders: {
                            title: {},
                            totalSum: {},
                            _totalCurrent: {
                                label: "סכום נוכחי",
                                val: doc => {
                                    return { value: doc.calcTotalActuals({ accountRef: account.docData.ref }) }
                                },
                                type: CURRENCY
                            },
                            _totalActual: {
                                label: "סכום כולל ביצוע",
                                val: doc => {
                                    return { value: doc.calcTotalActuals() }
                                },
                                type: CURRENCY
                            },
                        }
                    }),
                )
            }
        }
    }

    const displayPaymentsHeaders = [
        "paymentDate",
        "paymentChannel",
        "paymentType",
        "paymentIdentity",
        "sum",
    ]



    function isPlanAction() {
        //contractPlan OR contractActual
        if (node.state.contract && node.state.contract.docData.status === 'plan') {
            // alert("עובר למסך הקמת חוזה")
            m.route.set(m.route.get().replace("actual", "plan"))
            m.redraw()
        }
    }

    const handleEditPayments = () => {
        const { contract, currentAccount } = node.state
        node.state.prompt = {
            title: `עריכת תשלום - ${currentAccount.docData.month} - חוזה ${contract.docData.title}`,
            form: () => m(FormEditPayment, { contract, currentAccount }),
            class: "paymentFormBox full-width"
            // actionText: "סגור",
            // action: () => {
            //     node.state.prompt = false
            // }
        }
    }
    // const resetAccountSubTotal = () => {
    //     const { contract, currentAccount } = node.state
    //     const subTotal = currentAccount.calcTotalActuals()
    //     console.log(subTotal)
    // }

    const getContactorTaxPercent = contractorRef => {
        const vendor = dataStore[VENDORS].data.find(doc => doc.docData.ref === contractorRef)
        if (vendor) {
            return vendor.docData.taxPercent || 0
        } else {
            return 0
        }
    }

    const navCommentView = [
        {
            label: "צ'אט",
            active: true,
            key: "chat",
            action: function () {
                node.state.commentsView = this.key
                navCommentView.forEach(el => el.active = false)
                this.active = true
            }
        },
        {
            label: "טבלה",
            active: false,
            key: "table",
            action: function () {
                node.state.commentsView = this.key
                navCommentView.forEach(el => el.active = false)
                this.active = true
            }
        },
    ]

    function contractEditPlan() {
        node.state.prompt = {
            title: "עבור לעריכת חוזה",
            msg: `האם אתה בטוח כי ברצונך לעבור לעריכת חוזה ?`,
            actions: [
                {
                    text: "עבור לעריכה",
                    action: e => {
                        node.state.snackbar = { msg: "עובר לעריכת חוזה" }
                        node.state.contract.saveLocal({ status: "plan" })
                        node.state.contract.save();
                        node.state.prompt = false
                        m.route.set(m.route.get().replace("/billing", "/plan"))
                    }
                }
            ]
        }
    }

    const billingDashboardHeaders = {
        period: { label: "מס' חשבון", visible: true, group: "" },
        month: { label: "חודש ביצוע", visible: true, group: "" },
        baseTotal: { label: "קרן מאושר", visible: true, group: "" },
        totalAddSub: { label: "תוספות / קיזוזים", visible: true, group: "" },
        subTotal: { label: `סה"כ לפני עיכבון`, visible: true, group: "" },
        delay: { label: "עכבון", visible: true, group: "" },
        totalAccountToPay: { label: "לתשלום", visible: true, group: "" },
        indexedPercent: { label: "% הצמדה", visible: true, group: "indexed" },
        totalIndexed: { label: "סכום הצמדה", visible: true, group: "indexed" },
        totalAfterIndexed: { label: "סכום לאחר הצמדה", visible: true, group: "indexed" },
        vatPercent: { label: "% מעמ", visible: true, group: "vat" },
        totalVAT: { label: "מעמ", visible: true, group: "vat" },
        totalBeforeTax: { label: "לתשלום לפני ניכוי", visible: true, group: "" },
        taxPercent: { label: "% ניכוי מס", visible: true, group: "tax" },
        totalTax: { label: "ניכוי מס", visible: true, group: "tax" },
        totalPay: { label: "לתשלום סופי", visible: true, group: "" },
        totalPayments: { label: "שולם", visible: true, group: "" },
    }

    const toggleFilterColumn = group => {
        const { active, index } = isGroupActive(group)
        Object.entries(billingDashboardHeaders).map(([header, field]) => {
            if (field.group === group) billingDashboardHeaders[header].visible = !active
        })
        if (active) node.state.activeColumnsGroup.splice(index, 1)
        else node.state.activeColumnsGroup.push(group)
    }

    const isGroupActive = group => {
        const index = node.state.activeColumnsGroup.findIndex(el => el === group)
        return { active: index > -1, index }
    }

    const getProjectTotal = async () => {
        const url = `http://localhost:8080/b-cont/europe-west1/api/v1/projects/totals` //TEST LOCALY
        console.log(`POST API-v1 ${url}`);
        const apiHeaders = {
            "Authorization": `Bearer ${await auth.currentUser.getIdToken()}`,
            "Content-Type": "application/json",
        }
        const body = JSON.stringify({
            "where": {
                "project": "projects/03quax2847lp8nt6p804l5u"
            }
        });

        const requestOptions = {
            method: 'POST',
            headers: apiHeaders,
            body,
        };
        try {
            const response = await fetch(url, requestOptions)
            const result = await response.json()
            console.log(result)
        } catch (error) {
            console.log('error', error)
        }
    }

    const isRemoved = () => node.state.contract && node.state.contract.isRemoved

    return {
        loadContract: true,
        loadSections: true,
        selectedMonth: "",
        collaspeSection: [],
        activeGroup: false,
        activeWorkspace: "default",
        prompt: false,
        snackbar: false,
        commentsView: "chat",
        showCurrentComments: false,
        activeColumnsGroup: ["indexed", "vat", "tax"],
        oninit: vnode => {
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get());
            const projectPath = `projects/${node.attrs.projectID}`
            const contractPath = `${projectPath}/contracts/${vnode.attrs.contractID}`
            dataStore[PROJECTS].listen()
            const { alreadyListen } = dataStore[CONTRACTS].listen(projectPath)
            if (alreadyListen) {
                findContract()
                isPlanAction()
                vnode.state.loadContract = false;

                const { alreadyListen: isListenToAccounts } = dataStore[CONTRACT_ACCOUNTS].listen(contractPath)
                if (isListenToAccounts) getCurrentAccount(node)
                else setTimeout(() => getCurrentAccount(node), 3000);

                dataStore[CONTRACT_SECTIONS].listen(contractPath)
                dataStore[CONTRACT_ADDITIONS].listen(contractPath)
                dataStore[CONTRACT_SUBTRACTIONS].listen(contractPath)
                vnode.state.loadSections = false

                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען פרטי חוזה...", loading: true }
                setTimeout(() => {
                    findContract()
                    isPlanAction()
                    vnode.state.loadContract = false

                    const { alreadyListen: isListenToAccounts } = dataStore[CONTRACT_ACCOUNTS].listen(contractPath)
                    if (isListenToAccounts) getCurrentAccount(node)
                    else setTimeout(() => getCurrentAccount(node), 3000);

                    dataStore[CONTRACT_SECTIONS].listen(contractPath)
                    dataStore[CONTRACT_ADDITIONS].listen(contractPath)
                    dataStore[CONTRACT_SUBTRACTIONS].listen(contractPath)

                    vnode.state.loadSections = false

                    m.redraw()
                }, 3500)
            }
            sessionStorage.setItem('lastURL', m.route.get());
            logRouteChangeUser("checkIn");
        },
        onremove: vnode => {
            return logRouteChangeUser("checkOut");
        },
        view: vnode => {
            const { currentAccount, showCurrentBilling, commentsView, contract } = vnode.state
            return (
                m(PageLayout, {
                    class: "contract",
                    title: contract ?
                        `${contract.getProjectType("text")} / דשבורד חוזה - כספים`
                        : "דשבורד חוזה - כספים",
                    module: contract ? contract.getProjectType() : ""
                },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.selectMonth && m(MonthSelector, {
                            lastMonth: vnode.state.prompt.selectMonth,
                            contract: contract,
                            type: vnode.state.prompt.type || "newAccount",
                            label: vnode.state.prompt.label || "תקופה חדשה",
                            setSelected: month => vnode.state.selectedMonth = month
                        }),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.note && m("textarea.prompt__note [placeholder='הוסף הערות']", {
                            rows: 4,
                            oninput: e => { if (e.target.value.trim() !== "") vnode.state.note = e.target.value }
                        }),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    isSystemMode(vnode) && m(".settingsRow no-print",
                        contract && m("button.button", { onclick: e => importPayments({ contract: contract.docData.ref }) }, "ייבא תשלומים לחשבון זה"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree"),
                        m(`button.button`, { onclick: e => getProjectTotal() }, "GET_PROJECT_TOTALS")
                    ),
                    isRemoved() ? "חוזה זה נמחק" : m(Card, { class: "formCard contract__details no-print" },
                        (contract && isUserAllow(CONTRACTS, UPDATE, contract)) && m(".iconEdit", { onclick: e => contractEditPlan() }, m(Icon, { icon: "icon-edit" }), m("span", "ערוך חוזה")),
                        m("h3.formCard__caption caption", "פרטי החוזה"),
                        vnode.state.loadContract && m(InlineLoaderDots),
                        contract && Object.entries(buildHeaders(ContractDoc.headers, contractHeaders)).map(([header, settingsField], index) => {
                            const value = settingsField.val ? settingsField.val(contract).value : contract.docData[header]
                            return m(DisplayCell, { value, field: settingsField, doc: contract, header, index })
                        }),
                    ),

                    !isRemoved() && m("hr"),
                    !isRemoved() && m(Nav, { class: "contract__nav", nav: navStage }),
                    m("br"),

                    currentAccount && m(Card, { class: "contract__accounts no-print" },
                        m("h3.caption", "סטטוס אישורי הנהח"),
                        // (currentAccount && currentAccount.getCurrentStage().stage.type === "accounting" && currentAccount.docData.stage === "accounts") && m("button.button", {
                        //     style: "justify-self: end;",
                        //     onclick: e => resetAccountSubTotal()
                        // }, "עדכן חשבון תשלום"),
                        (vnode.state.loadContract || !currentAccount) ?
                            m(InlineLoaderDots) :
                            m(".period",
                                m(".period__label", "תקופה"),
                                m(".period__label", { dir: "ltr", onclick: e => changeAccount(vnode) }, currentAccount.docData.month)
                            ),
                        currentAccount && m(".confirm", { style: `grid-template-columns: repeat(${currentAccount.getFlow("accounting").length},1fr);` },
                            currentAccount.getChildren(CONFIRMS, { include: { type: "accounting" } })
                                .sort(sortDocsBy("index", { type: NUMBER }))
                                .map((doc, ind) => {
                                    const { id, due, confirm, confirmAt, confirmBy } = doc.docData
                                    // console.log(doc.docData);
                                    const dueDiff = diffDays(addMonths(1, textToDate(currentAccount.docData.month, due)), new Date())
                                    const isDelay = dueDiff > 0 && confirm !== true
                                    return [
                                        m(".confirm__header confirm__cell", { style: `grid-area:1/${ind + 1}` }, doc.docData.title),
                                        m(`.confirm__label confirm__cell ${isDelay ? "danger" : ""}`, { style: `grid-area:2/${ind + 1}` }, defaultdDayDisplay(currentAccount, due), isDelay && m("span", { style: "font-weight:600;font-size:1.4rem" }, `לפני ${parseInt(dueDiff)} יום`)),
                                        m(".confirm__value confirm__cell", { style: `grid-area:3/${ind + 1}` }, (confirm == true && confirmAt && confirmAt !== "") ? formatDateDisplay(new Date(confirmAt), { useHours: true }) : "----"),
                                        m(".confirm__value confirm__cell", { style: `grid-area:4/${ind + 1}` }, (confirm == true && confirmBy && confirmBy !== "") ? UserDoc.getUserByID(confirmBy, "displayName") : "----")
                                    ]
                                }),
                        )
                    ),
                    (contract && !isRemoved() && currentAccount) && m(Card, { class: "contract__comments card--scroll no-print" },
                        m(Icon, { class: "icon--highlight", icon: "icon-enlarge", action: e => promptComments(vnode) }),
                        m(Nav, { nav: navCommentView }),
                        m("h3.caption",
                            vnode.state.showCurrentComments ?
                                "לוג הערות לתקופה" :
                                "לוג הערות",
                            m(".toggleComments", { onclick: e => toggleCommentView(vnode) },
                                vnode.state.showCurrentComments ?
                                    "הצג הכל" :
                                    "הצג תקופה נוכחית" ,
                            ),
                        ),
                        (commentsView === "chat" && contract) && m(".comments",
                            contract.getChildren(CONTRACT_COMMENTS, vnode.state.showCurrentComments ?
                                { include: { accountRef: currentAccount.docData.ref }, exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } } :
                                { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } }

                            )
                                .sort(sortDocsBy("timestamp", { type: NUMBER }))
                                .map(doc => m(".comment",
                                    doc.isNew && m(".flag flag--new", "לא נשמר"),
                                    !doc.isNew && m(".flag", UserDoc.getUserByID(doc.docData.createdBy, "displayName")),
                                    m(".comment__title", { onclick: e => handleEditComment(vnode, doc) },
                                        doc.docData.title,
                                        m(Icon, { icon: "icon-pin", class: `comment__pin${doc.docData.isPinned ? "--pinned" : "--unpinned"}`, action: e => togglePinComment(e, vnode, doc) })
                                    ),
                                    m(".comment__content", doc.docData.description),
                                    m(".comment__stage", getDisplayValue(doc.docData.accountRef, LIST, { data: dataStore[CONTRACT_ACCOUNTS].data, param: "month" })),
                                    m(".comment__info", formatDateDisplay2(new Date(doc.docData.createdAt), "useHours")),
                                )),
                            m(".comment--add", { onclick: e => handleAddComment(vnode) }, "+ הוסף הערה חדשה")
                        ),
                        (commentsView === "table") && m(Table, {
                            title: "הערות חוזה",
                            modelID: CONTRACT_COMMENTS,
                            colRef: `${contract.docData.ref}/${CONTRACT_COMMENTS}`,
                            doc: contract,
                            tableHeaders: ["title", "description", "isPinned"],
                            filterDataOptions: vnode.state.showCurrentComments ?
                                { include: { accountRef: currentAccount.docData.ref }, exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } } :
                                { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } },
                            addDataOptions: {
                                accountRef: currentAccount.docData.ref,
                                confirmRef: `${currentAccount.docData.ref}/${CONFIRMS}/${currentAccount.getCurrentStage().stage.id}`
                            },
                            actions: { onAdd: "prompt", onRow: "prompt" },
                            viewOptions: { nav: false, filters: false, add: false },
                            sortOptions: { param: "timestamp" },
                            searchOptions: ["title", "description"],
                            filters: [],
                            // filterMap: []
                        })
                    ),

                    !isRemoved() && m(ContractTotalBillingTable, { parent: vnode }),
            
                    (!isRemoved() && currentAccount) && m(Card, { class: "contract__totals reportTable accounting row card--scroll paymentsCard no-print" },
                        m(".caption accounting__caption", "חשבון נוכחי לתשלום"),
                        (!contract || vnode.state.loadSections || !currentAccount) ? m(InlineLoaderDots) : [
                            m(AccountingForm, { account: currentAccount, contract }),
                            m(".accounting__billings", { style: `grid-template-columns:${displayPaymentsHeaders.map(() => "1fr").join(" ")}` },
                                m(".caption", "חלוקת תשלומים"),
                                contract.getBalance() < 0 && m(`.row danger`, `עודף תשלום : ${getDisplayValue(contract.getBalance(), CURRENCY, { isFloat: true })}`),
                                contract.getBalance() > 0 && m(`.row info`, `יתרה לשלם : ${getDisplayValue(contract.getBalance(), CURRENCY, { isFloat: true })}`),
                                m("button.button accounting__add", {
                                    onclick: e => handleEditPayments()
                                }, "עריכת תשלום"),
                                // //headers
                                displayPaymentsHeaders.map(header => {
                                    const { label } = PaymentDoc.headers[header]
                                    return m(".cell cell--header", label)
                                }),
                                //case empty
                                currentAccount.getChildren(PAYMENTS).length === 0 && displayPaymentsHeaders.map(header => m(".cell cell--value", "---")),
                                //rows
                                currentAccount.getChildren(PAYMENTS)
                                    .sort(sortDocsBy("paymentDate", { type: DATE }))
                                    .map(payment => {
                                        return displayPaymentsHeaders.map(header => {
                                            const { type, options } = PaymentDoc.headers[header]
                                            return m(".cell cell--value", getDisplayValue(payment.docData[header], type || STRING, options))
                                        })
                                    }),
                            )//END ACCOUNTING-BILLING
                        ]
                    ),
                    m("button.button", { onclick: e => promptPaymentReport() }, "הפק הוראת תשלום"),
                    (currentAccount && vnode.state.currentAccount.getCurrentStage().stage.type === "operation") && m(".row row__info no-pring",
                        `סבב אישורים בשלב הנהח עדיין לא התחיל `,
                        m("span.link", { onclick: e => m.route.set(m.route.get().replace("/billing", "/actual")) }, "עבור למסך ביצוע")
                    ),
                    (currentAccount && vnode.state.currentAccount.docData.stage === "finish") && m(".row row__info no-print",
                        `סבב אישורים בשלב הנהח הסתיים `,
                        m("span.link", { onclick: e => m.route.set(m.route.get().replace("/billing", "/actual")) }, "עבור למסך ביצוע")
                    ),
                    (currentAccount && vnode.state.currentAccount.getCurrentStage().stage.type === "accounting" && currentAccount.docData.stage !== "finish") && m("button.button", { onclick: e => handleConfirmStage(vnode), key: uuid() }, vnode.state.currentAccount.getCurrentStage().stage.text)
                ) //END pageLayout
            ) //END return
        }
    }
}