import m from "mithril"
import { getDisplayValue } from "../../../data/utils/inputValidation";
import { NUMBER } from "../../../utils/constants/types";


export const Calculator = node => {

    const calcResult = inputArray => {
        const strInput = inputArray.join("")
        return eval(strInput);

        //THINK: if use recursion way should define operators priority
        // let val = inputArray.shift()
        // val = parseFloat(val)
        // if (inputArray.length == 0) {
        //     return val
        // } else {
        //     if (!isNaN(val)) {
        //         let operation = inputArray.shift()
        //         switch (operation) {
        //             case "+": return val + calcResult(inputArray);
        //             case "-": return val - calcResult(inputArray);
        //             case "*": return val * calcResult(inputArray);
        //             case "/": return val / calcResult(inputArray);
        //             default:
        //                 return val
        //         }
        //     }
        // }
    }

    const updateInputOperation = operation => {
        if (node.state.isResult) node.state.isResult = false
        node.state.result += operation
    }

    const updateInputNumber = num => {
        if (node.state.isResult) {
            node.state.result = num
            node.state.isResult = false
        } else {
            node.state.result += num
        }
    }


    const showResult = () => {
        let inputStr = node.state.result.replace(/\s+|,+/g, '');
        const inputArray = inputStr.match(/[\*\+\-\/]|[0-9\.,]+/g);
        const resultValue = calcResult(inputArray)
        node.state.result = getDisplayValue(resultValue, NUMBER, { isFloat: true, digits: 4 })
        if (node.attrs.parent) {
            node.attrs.parent.state.tempCalc = resultValue
        }
        node.state.isResult = true
    }

    return {
        result: 0,
        isResult: true,
        view: vnode => {
            return m(".calculator",
                m("input.result", {
                    value: vnode.state.result,
                    oninput: (e) => {
                        const { target, data, inputType } = e
                        const validOperation = ["deleteContentBackward"]
                        if (validOperation.includes(inputType)) vnode.state.result = target.value
                        switch (data) {
                            case "0": case "1": case "2": case "3": case "4": case "5": case "6": case "7": case "8": case "9":
                                if (vnode.state.isResult) vnode.state.result = data
                                else updateInputNumber(data)
                                break;
                            case "+": case "-": case "/": case "*": case ".": case ",": case " ":
                                updateInputNumber(data)
                                break;
                            // case "(":
                            //     vnode.state.result = target.value + ")";
                            //     target.setSelectionRange(pos, pos);
                            //     break;
                            default:
                            // console.error("invalid key ", data, inputType, e);
                        }
                        if (vnode.state.isResult) { node.state.isResult = false }
                    },
                    onkeypress: ({ key, target }) => { if (key == "Enter") showResult(); }
                }),
                m(".eq", { onclick: e => showResult() }, "="),
                m(".op", { onclick: e => updateInputOperation(" + ") }, "+"),
                m(".op", { onclick: e => updateInputOperation(" * ") }, m.trust("&times;")),
                m(".op", { onclick: e => updateInputOperation(" / ") }, m.trust("&divide;")),
                ["9", "8", "7", "6", "5", "4", "3", "2", "1"].map(num => {
                    return m(".number", { onclick: e => updateInputNumber(num) }, num)
                }),
                m(".op--red", { onclick: e => vnode.state.result = 0 }, "C"),
                m(".number", { onclick: e => updateInputOperation(".") }, "."),
                m(".number--span-2", { onclick: e => updateInputNumber("0") }, 0),
            )
        }
    }
}