import m from "mithril"
import { ACTUALS, CONTRACTS, CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, SECTION_MILESTONES, VENDORS } from "../../../../data/dictionary/routeNames"
import { CURRENCY} from "../../../../utils/constants/types"

import { db } from "../../../../index"

import { findRepeat } from "../../../../data/utils/utils"
import { ReportLayout } from "../ReportLayout"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { DocModel } from "../../../../data/store/docModel"
import { buildDoc, getChildren, getParent, getSubChildren } from "../utils"
import { TableReport } from "../TableReport"

export const VendorActualsReport = node => {

    async function loadVendorReportData(ref) {
        try {
            node.state.collectionData = []
            node.state.load = true
            const docs = (await db.collectionGroup(CONTRACTS).where("contractorRef", "==", ref).where("isActive", "==", true).get()).docs
            docs.forEach(async fbDoc => {
                const doc = buildDoc(fbDoc)
                const { ref } = doc.docData
                const project = await getParent(ref)
                if (project.docData.isActive == true) {
                    doc.parent = project
                    //direct children
                    const accounts = await getChildren(CONTRACT_ACCOUNTS, ref)
                    const sections = await getChildren(CONTRACT_SECTIONS, ref)
                    const additions = await getChildren(CONTRACT_ADDITIONS, ref)
                    const subtractions = await getChildren(CONTRACT_SUBTRACTIONS, ref)
                    //sub children
                    const actuals = await getSubChildren(accounts, ACTUALS)
                    const milestones = await getSubChildren(sections, SECTION_MILESTONES)
                    doc.children = { accounts, sections, additions, subtractions, milestones, actuals }
                    node.state.collectionData = [...node.state.collectionData, doc]
                    m.redraw()
                }
            })
            node.state.load = false
        } catch (err) {
            console.error(err);
        }
    }

    const reportHeaders = {
        sectionName: {
            label: "סעיף",
            val: doc => {
                return { value: doc.docData.title }
            }
        },
        sectionPrice: {
            label: "סכום הסכם",
            type: CURRENCY,
            val: doc => {
                return { value: doc.docData.totalSum }
            }
        },
        sectionCalcActuals: {
            label: "סכום ביצוע מאושר",
            type: CURRENCY,
            val: (section, mainDoc) => {
                let result = 0
                if (section.docData.colRef.endsWith("sections")) {
                    const isPercent = section.docData.calculationType === 'percent'
                    const isPauschal = section.docData.calculationMethod === "pauschal"
                    mainDoc.children.milestones
                        .filter(ms => ms.docData.ref.startsWith(section.docData.ref))
                        .forEach(ms => {
                            let totalActual = 0
                            mainDoc.children.actuals
                                .filter(act => act.docData.sectionRef === ms.docData.ref)
                                .forEach(act => totalActual += parseFloat(act.docData.value))
                            const price = isPercent ? (parseFloat(ms.docData.weight) / 100) * parseFloat(section.docData.itemPrice) : parseFloat(ms.docData.price);
                            if (isPauschal) {
                                result += (parseFloat(totalActual) / 100) * price;
                            } else {
                                result += parseFloat(totalActual) * price;
                            }
                        })
                } else if (section.docData.colRef.endsWith("additions") || section.docData.colRef.endsWith("subtractions")) {
                    let totalActual = 0
                    mainDoc.children.actuals
                        .filter(act => act.docData.sectionRef === section.docData.ref)
                        .forEach(act => totalActual += parseFloat(act.docData.value))
                    result += parseFloat(section.docData.totalSum) * (parseFloat(totalActual) / 100)
                } else {
                    throw "unknown colRef " + section.docData.colRef
                }
                return { value: result }
            }
        }
    }

    return {
        collectionData: [],
        history: [],
        prompt: false,
        snackbar: false,
        oninit: async vnode => {
            const ref = `vendors/${vnode.attrs.vendorID}`
            let findVendor
            const findFunc = () => findVendor = DocModel.getChild(VENDORS, ref)
            await findRepeat(findFunc, findVendor != undefined)
            if (findVendor) {
                node.state.vendor = findVendor
                await loadVendorReportData(ref)
            } else {
                m.route.set("/app/reports/vendors")
            }
        },
        view: vnode => {
            const { collectionData = [], load, vendor } = vnode.state
            return m(ReportLayout, { title: `דוח פירוט ביצוע לקבלן ${vendor ? ` - "${vendor.docData.title}"` : ""}` },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                collectionData && collectionData.map(contract => {
                    return m(TableReport, {
                        load,
                        collectionData: [
                            ...contract.children.sections,
                            ...contract.children.additions,
                            ...contract.children.subtractions]
                            .filter(doc => doc.docData.ref.startsWith(contract.docData.ref)),
                        reportHeaders,
                        groupBy: ["colRef"],
                        title: `${contract.parent.docData.title} /${contract.docData.title}`,
                        mainDoc: contract,
                        instance: vendor
                    })
                })
                // m(Card, { class: "tableCard" },
                //     m(".table",
                //         //HEADERS
                //         m(".table__row.table__headers", { style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);` },
                //             Object.entries(reportHeaders).map(([header, { label }]) => m(".table__cell", label))
                //         ),
                //         load && m(InlineLoaderDots),
                //         //DATA
                //         collectionData.map(doc => {
                //             const styleColumns = `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`
                //             return m(".table__row", { style: styleColumns, key: doc.docData.ref },
                //                 Object.entries(reportHeaders).map(([header, { val, type = STRING, options = {}, nav, prompt }]) => {
                //                     return m(`${nav ? "a" : "div"}.table__cell`, {
                //                         class: nav ? "link" : "",
                //                         href: nav ? nav(doc) : "#",
                //                         target: "_blank",
                //                         "data-header": header,
                //                         onclick: e => {
                //                             if (prompt) {

                //                             }
                //                         }
                //                     },
                //                         getDisplayValue(val(doc).value, type, options)
                //                     )
                //                 })
                //             )
                //         }),
                //         //TOTALS row
                //         (collectionData.length > 0 && vendor && !load) && m(".table__totals", {
                //             style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`,
                //         },
                //             Object.entries(reportHeaders).map(([header, fieldOptions]) => {
                //                 if (header === "title") {
                //                     return m(".total", `${getDisplayValue(collectionData.length, NUMBER)} שורות`)
                //                 }
                //                 if (fieldOptions.totals) {
                //                     console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                //                     const totalValue = fieldOptions.totals.val(collectionData)
                //                     return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                //                 }
                //                 if (fieldOptions.type === NUMBER || fieldOptions.type === CURRENCY) {
                //                     let total = 0
                //                     if (fieldOptions.val) {
                //                         total = collectionData.reduce((acc, doc) => {
                //                             return acc += parseFloat(fieldOptions.val(doc).value)
                //                         }, 0)
                //                     } else {
                //                         total = ""
                //                     }
                //                     return m(".total", getDisplayValue(total, fieldOptions.type, fieldOptions))
                //                 }
                //                 return m(".total", "---")
                //             })
                //         )
                //     )//END table
                // ) //END tableCard
            )
        }
    }
}