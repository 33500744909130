import { RESIDENTIAL, ENTREPRENEURSHIP, PUBLIC_SPACE } from "../../utils/constants/projectTypes"
import { VENDORS, PROJECTS } from "../dictionary/routeNames"

export const NavMainSidebar = [
    // { seperetor: true },
    // { id: "app", icon: "icon-home", text: "דשבורד", isActive: true, pagePath: "/app" },
    // { id: "reports", icon: "icon-reports", text: "דוחות", pagePath: "/app/reports" },
    // sub: [
    // { action: "add", text: "+ פרוייקט חדש", pagePath: "/app/projects/new" },
    // { text: "רשימת פרוייקטים", pagePath: "/app/projects/list" }
    //   /  ]

    {
        id: RESIDENTIAL,
        modelID: PROJECTS,
        doc: { docData: { projectType: RESIDENTIAL } },
        icon: "icon-residential",
        text: "מגורים",
        pagePath: "/app/projects?projectType=residential"
    },
    {
        id: PUBLIC_SPACE,
        modelID: PROJECTS,
        doc: { docData: { projectType: PUBLIC_SPACE } },
        icon: "icon-publicSpace",
        text: "ציבורי",
        pagePath: "/app/projects?projectType=publicSpace",
    },
    {
        id: ENTREPRENEURSHIP,
        modelID: PROJECTS,
        doc: { docData: { projectType: ENTREPRENEURSHIP } },
        icon: "icon-entrepreneurship",
        text: "יזמות",
        pagePath: "/app/projects?projectType=entrepreneurship",
    },

    { seperetor: true },
    // {
    // id: CONTRACTS, icon: "icon-contracts", text: "חוזים", pagePath: "/app/contracts",
    // sub: [
    // { action: "add", text: "+ חוזה חדש", pagePath: "/app/contracts/new" },
    // ]
    // },
    // { id: "executions", icon: "icon-tools", text: "ביצוע", pagePath: "/app/executions" },
    // { id: CONTRACT_ACCOUNTS, icon: "icon-accounts", text: "חשבונות", pagePath: "/app/accounts" },
    // { id: PAYMENTS, icon: "icon-payments", text: "תשלומים", pagePath: "/app/payments" },
    // { seperetor: true },
    // {  text: "הרשמת קבלנים", pagePath: "/app/register" },
    // { id: SETTINGS, icon: "icon-cog", text: "הגדרות", pagePath: "/app/settings" },

    { id: VENDORS, modelID: VENDORS, icon: "icon-vendors", text: "קבלנים", pagePath: "/app/vendors" },

    { seperetor: true },
    { id: "settings", icon: "icon-cog", text: "הגדרות", pagePath: "/settings", adminOnly: true },
    { id: "tools", icon: "icon-tools", text: "כלים", pagePath: "/utils/tools", adminOnly: true },
    { id: "reports", icon: "icon-reports", text: "דוחות", pagePath: "/app/reports" },

]