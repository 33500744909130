import m from 'mithril';

// DATA
import { PROJECTS } from '../../../data/dictionary/routeNames';
import { DATE_RANGE } from '../../../utils/constants/filtersTypes';
import { dataStore } from '../../../data/store/dataStore';
import { settingsStore } from '../../../data/store/settingsStore';
import { projectTypes } from '../../../utils/constants/projectTypes';
import { statusTypes } from '../../../utils/constants/statusTypes';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';

// FUNCTIONS
import { isSystemMode } from '../../../data/utils/viewUtils';
import { formatDate, addDays } from '../../../utils/js';
import { DocModel } from '../../../data/store/docModel';
import { Table } from '../../commons/table/Table';
import { logRouteChangeUser, setHeaders } from '../../../data/utils/utils';
import { ColumnChart } from '../../commons/columnChart/ColumnChart';

export const Projects = (node) => {

    const projectsHeaders = ["title", "projectType", "sDate", "eDate", "totalSum", "subTotal", "address"]

    window.onresize = (ev) => {
        setHeaders(projectsHeaders, "projectsHeaders", node)
        m.redraw()
    }

    return {
        snackbar: false,
        prompt: false,
        projectsHeaders: [...projectsHeaders],
        load: true,
        filters: [
            { id: "status", param: "nonActive" },
        ],
        settings: { navOnAdd: true },
        projectType: false,
        oninit: vnode => {
            window.scrollTo(0, 0);
            sessionStorage.setItem('lastURL', m.route.get());
            console.log("routeChange", m.route.get());
            logRouteChangeUser("checkIn");

            const projectType = projectTypes.find(p => p.id === vnode.attrs.projectType)
            if (!projectType) m.route.set("/app")
            vnode.state.projectType = projectType

            vnode.state.snackbar = { msg: "טוען פרוייקטים...", loading: true }

            const { alreadyListen, unsubscribe } = dataStore[PROJECTS].listen();
            if (alreadyListen) {
                vnode.state.load = false
            } else {
                setTimeout(() => {
                    vnode.state.load = false
                    m.redraw()
                }, 3000);
            }
            m.redraw();
        },
        onremove: vnode => {
            console.log("remove '/projects'")
            return logRouteChangeUser("checkOut");
        },
        // onbeforeremove: vnode => {
        // },
        view: (vnode) => {
            return (
                m(PageLayout, { class: "projects", title: `פרוייקטים - ${vnode.state.projectType && vnode.state.projectType.text}` },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.settings.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    m(Table, {
                        title: "טבלת פרוייקטים",
                        modelID: PROJECTS,
                        colRef: PROJECTS,
                        doc: DocModel,
                        tableHeaders: vnode.state.projectsHeaders,
                        filterDataOptions: { include: { projectType: node.attrs.projectType } },
                        actions: { onRow: "redirect" },
                        searchOptions: ["title", "description", "address"],
                        filters: vnode.state.filters,
                        filterMap: [
                            { label: "סטטוס", header: "status", options: statusTypes },
                            { label: "תאריך התחלה", header: "sDate", type: DATE_RANGE, options: [{ id: "sDate:after", text: "מ", logic: "after" }, { id: "sDate:before", text: "עד", logic: "before" }] },
                            { label: "תאריך סיום", header: "eDate", type: DATE_RANGE, options: [{ id: "eDate:after", text: "מ", logic: "after" }, { id: "eDate:before", text: "עד", logic: "before" }] }
                        ]
                    }),
                    // m(ColumnChart, {
                    //     title: "פרוייקטים פעילים - ביצוע מאושר למול הסכם",
                    //     data: DocModel.getChildren(PROJECTS, { include: { projectType: node.attrs.projectType, status: "active" } })
                    //         .map(doc => {
                    //             return {
                    //                 total: doc.docData.totalSum,
                    //                 done: doc.docData.subTotal || 0,
                    //                 title: doc.docData.title,
                    //                 key: doc.docData.ref
                    //             }
                    //         })
                    // })
                )
            )
        }
    }
}