import m from "mithril"

import { PROJECTS, FILES, CONTRACTS, CONTRACT_SECTIONS, CONTRACT_ACCOUNTS, SECTION_MILESTONES, CONFIRMS, VENDORS, WORKSPACES, USERS, GROUPS, COMPANIES, VENDOR_CONTACTS, ACTUALS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, PAYMENTS, CONTRACT_COMMENTS, USERS_PERMISSION, BUDGET_CHAPTERS, BUDGET_ITEMS, NOTIFICATION, FIELDS_SWITCH_OPTIONS, APARTMENTS, FLOORS, BUILDINGS, APARTMENT_MODELS, VIEW_USERS, ODD_JOBS } from "../dictionary/routeNames"
import { db, auth } from "../../index"
import { queryDataListener } from "../CRUD/dataListener"
import { ProjectDoc } from "./projects/projectClass";
import { ContractDoc } from "./contracts/contractClass";
import { SectionDoc } from "./contracts/sectionClass";
import { MilestoneDoc } from "./contracts/milestoneClass";
import { VendorDoc } from "./vendors/vendorClass";
import { WorkspaceDoc } from "./contracts/workSpaceClass";
import { UserDoc } from "./users/userClass";
import { GroupDoc } from "./contracts/groupClass";
import { AccountDoc } from "./contractsActuals/accountClass";
import { CompanyDoc } from "./companies/companyClass";
import { ContactDoc } from "./vendors/contactClass";
import { ConfirmDoc } from "./contractsActuals/confirmClass";
import { ActualDoc } from "./contractsActuals/actualClass";
import { AdditionDoc } from "./contracts/additionClass";
import { SubtractionDoc } from "./contracts/subtractionClass";
import { PaymentDoc } from "./contractsActuals/paymentClass";
import { ContractCommentDoc } from "./contractsActuals/contractCommentClass";
import { FileDoc } from "./commons/fileClass";
import { BudgetDoc } from "./budget/budgetClass";
import { BudgetItemDoc } from "./budget/budgetitemClass";
import { PROJECT_BUDGET } from "../dictionary/viewOptions";
import { ProjectBudgetDoc } from "./budget/projectBudgetClass";
import { NotificationDoc } from "./commons/notificationClass";
import { permissionTree } from "./permissionStore";
import { matchPermissionListeners } from "../utils/permission";
import { FieldSwitchOptDoc } from "./commons/fieldSwitchClass";
import { ApartmentDoc } from "./projectBuildings/apartmentClass";
import { BuildingDoc } from "./projectBuildings/BuildingClass";
import { FloorDoc } from "./projectBuildings/floorClass";
import { ApartmentModelDoc } from "./projectBuildings/apartmentModelClass";
import { ViewUserDoc } from "./commons/viewUserClass";
import { OddJobFile } from "./commons/oddJobFileClass";


export const dataStore = {
    [PROJECTS]: {
        new: [],
        data: [],
        children: [PROJECT_BUDGET, APARTMENTS, BUILDINGS, ODD_JOBS],
        isListen: false,
        model: ProjectDoc,
        text: "פרוייקטים",
        listen: async function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                const currentUser = await UserDoc.setCurrentUser()
                if (currentUser) {
                    // console.log("🖇...listen to PROJECTS")
                    let qry = db.collection("projects")
                    qry = qry.where("isActive", "==", true)
                    matchPermissionListeners(permissionTree.projects.read.listen_where, currentUser.docData.role)
                        .forEach(({ field, op, value }) => {
                            if (value === "currentUser") {
                                qry = qry.where(field, op, currentUser.docData.ref)
                            } else {
                                qry = qry.where(field, op, value)
                            }
                        })
                    unsubscribe = queryDataListener(PROJECTS, qry, ProjectDoc, PROJECTS)
                    this.isListen = true
                } else {
                    setTimeout(() => {
                        this.listen()
                    }, 2000);
                }
            } else {
                // console.log("ℹ already listen to PROJECTS")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [BUDGET_CHAPTERS]: {
        new: [],
        data: [],
        children: [BUDGET_ITEMS],
        isListen: false,
        model: BudgetDoc,
        text: "פרקי תקציב",
        listen: function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                this.isListen = true
                // console.log("🖇...listen to BUDGET_CHAPTERS")
                const colRef = `settings/system/${BUDGET_CHAPTERS}`
                const qry = db.collection(colRef).where("isActive", "==", true)
                unsubscribe = queryDataListener(BUDGET_CHAPTERS, qry, BudgetDoc, colRef)
            } else {
                // console.log("ℹ already listen to BUDGET_CHAPTERS")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [BUDGET_ITEMS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: BudgetItemDoc,
        listen: function (parentPath) {
            let alreadyListen = this.isListen[parentPath] === true
            let unsubscribe
            if (!alreadyListen) {
                // console.log("🖇...listen to BUDGET_ITEMS => on: ", parentPath)
                const colRef = `${parentPath}/${BUDGET_ITEMS}`
                const qry = db.collection(colRef).where("isActive", "==", true)
                unsubscribe = queryDataListener(BUDGET_ITEMS, qry, BudgetItemDoc, colRef)
                this.isListen = true
            } else {
                // console.log("ℹ already listen to  BUDGET_ITEMS => on: ", parentPath)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [VIEW_USERS]: {
        data: [],
        new: [],
        isListen: false,
        model: ViewUserDoc,
        listen: function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                this.isListen = true
                console.log("🖇...listen to VIEW_USERS")
                const qry = db.collection(VIEW_USERS)
                //TODO: update qry to match only new logs based on time range...THINK:
                unsubscribe = queryDataListener(VIEW_USERS, qry, ViewUserDoc)
            } else {
                console.log("ℹ already listen to VIEW_USERS")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [USERS]: {
        new: [],
        data: [],
        children: [],
        current: false,
        isListen: false,
        model: UserDoc,
        listen: function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                this.isListen = true
                // console.log("🖇...listen to USERS")
                const qry = db.collection(USERS).where("isActive", "==", true)
                unsubscribe = queryDataListener(USERS, qry, UserDoc)
                UserDoc.setCurrentUser()
            } else {
                // console.log("ℹ already listen to USERS")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [NOTIFICATION]: {
        new: [],
        data: [],
        children: [],
        isListen: false,
        model: NotificationDoc,
        // listen: function () {
        //     let alreadyListen = this.isListen === true
        //     let unsubscribe
        //     if (!alreadyListen) {
        //         this.isListen = true
        //         // console.log("🖇...listen to NOTIFICATION")
        //         const qry = db.collection(NOTIFICATION).where("isActive", "==", true).where("user", "==", auth.currentUser.uid)
        //         unsubscribe = queryDataListener(NOTIFICATION, qry, NotificationDoc)
        //     } else {
        //         // console.log("ℹ already listen to NOTIFICATION")
        //     }
        //     return { alreadyListen, unsubscribe }
        // },
        listen: async function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                const currentUser = await UserDoc.setCurrentUser()
                if (currentUser) {
                    // console.log("🖇...listen to PROJECTS")
                    let qry = db.collection(NOTIFICATION)
                    qry = qry.where("isActive", "==", true)
                    matchPermissionListeners(permissionTree.notification.read.listen_where, currentUser.docData.role)
                        .forEach(({ field, op, value }) => {
                            if (value == "currentUser") {
                                qry = qry.where(field, op, currentUser.docData.ref)
                            } else {
                                qry = qry.where(field, op, value)
                            }
                        })
                    unsubscribe = queryDataListener(NOTIFICATION, qry, NotificationDoc)
                    this.isListen = true
                } else {
                    setTimeout(() => {
                        this.listen()
                    }, 2000);
                }
            } else {
                // console.log("ℹ already listen to PROJECTS")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    // [USERS_PERMISSION]: {
    //     new: [],
    //     data: [],
    //     children: [],
    //     isListen: false,
    //     model: UserDoc,
    //     listen: function () {
    //         let alreadyListen = this.isListen === true
    //         let unsubscribe
    //         if (!alreadyListen) {
    //             this.isListen = true
    //             // console.log("🖇...listen to USERS_PERMISSION")
    //             const qry = db.collection(USERS_PERMISSION).where("isActive", "==", true)
    //             unsubscribe = queryDataListener(USERS_PERMISSION, qry, UserDoc)
    //         } else {
    //             // console.log("ℹ already listen to USERS_PERMISSION")
    //         }
    //         return { alreadyListen, unsubscribe }
    //     },
    // },
    [FILES]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: FileDoc,
        listen: function (parentPath) {
            let alreadyListen = this.isListen[parentPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to FILES => on: ", parentPath)
            } else {
                // console.log("🖇...listen to FILES => on: ", parentPath)
                this.isListen[parentPath] = true
                const qry = db.doc(parentPath).collection(FILES).where("isActive", "==", true)
                unsubscribe = queryDataListener(FILES, qry, FileDoc, `${parentPath}/${FILES}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [ODD_JOBS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: OddJobFile,
        listen: function (parentPath) {
            let alreadyListen = this.isListen[parentPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to FILES => on: ", parentPath)
            } else {
                // console.log("🖇...listen to FILES => on: ", parentPath)
                this.isListen[parentPath] = true
                const qry = db.doc(parentPath).collection(ODD_JOBS).where("isActive", "==", true)
                unsubscribe = queryDataListener(ODD_JOBS, qry, OddJobFile, `${parentPath}/${ODD_JOBS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [VENDORS]: {
        new: [],
        data: [],
        children: [VENDOR_CONTACTS],
        isListen: false,
        model: VendorDoc,
        listen: function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                this.isListen = true
                // console.log("🖇...listen to VENDORS")
                const qry = db.collection("vendors").where("isActive", "==", true)
                unsubscribe = queryDataListener(VENDORS, qry, VendorDoc)
            } else {
                // console.log("ℹ already listen to VENDORS")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [VENDOR_CONTACTS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: ContactDoc,
        listen: function (vendorPath) {
            let alreadyListen = this.isListen[vendorPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to VENDOR_CONTACTS => on: ", vendorPath)
            } else {
                // console.log("🖇...listen to VENDOR_CONTACTS => on: ", vendorPath)
                this.isListen[vendorPath] = true
                const qry = db.doc(vendorPath).collection("contacts").where("isActive", "==", true)
                unsubscribe = queryDataListener(VENDOR_CONTACTS, qry, ContactDoc, `${vendorPath}/contacts`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [COMPANIES]: {
        new: [],
        data: [],
        children: [],
        isListen: false,
        model: CompanyDoc,
        listen: function () {
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (!alreadyListen) {
                this.isListen = true
                // console.log("🖇...listen to COMPANIES")
                const qry = db.collection("companies").where("isActive", "==", true)
                unsubscribe = queryDataListener(COMPANIES, qry, CompanyDoc)
            } else {
                // console.log("ℹ already listen to COMPANIES")
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [FIELDS_SWITCH_OPTIONS]: {
        new: [],
        data: [],
        children: [],
        isListen: false,
        title: "שדות בחירה במערכת",
        model: FieldSwitchOptDoc,
        listen: function () {
            const path = `settings/system/${FIELDS_SWITCH_OPTIONS}`
            let alreadyListen = this.isListen === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to FIELDS_SWITCH_OPTIONS")
            } else {
                // console.log("🖇...listen to FIELDS_SWITCH_OPTIONS")
                this.isListen = true
                const qry = db.collection(path).where("isActive", "==", true)
                unsubscribe = queryDataListener(FIELDS_SWITCH_OPTIONS, qry, FieldSwitchOptDoc, path)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [PROJECT_BUDGET]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: ProjectBudgetDoc,
        listen: function (projectPath) {
            let alreadyListen = this.isListen[projectPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to PROJECT_BUDGET => on: ", projectPath)
            } else {
                // console.log("🖇...listen to PROJECT_BUDGET => on: ", projectPath)
                this.isListen[projectPath] = true
                const qry = db.doc(projectPath).collection(PROJECT_BUDGET).where("isActive", "==", true)
                unsubscribe = queryDataListener(PROJECT_BUDGET, qry, ProjectBudgetDoc, `${projectPath}/${PROJECT_BUDGET}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONTRACTS]: {
        new: [],
        data: [],
        children: [WORKSPACES, CONTRACT_ACCOUNTS, CONTRACT_SECTIONS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS/*,  CONTRACT_BILLINGS */, CONTRACT_COMMENTS, FILES],
        isListen: {},
        model: ContractDoc,
        listen: function (projectPath) {
            let alreadyListen = this.isListen[projectPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to CONTRACTS => on: ", projectPath)
            } else {
                // console.log("🖇...listen to CONTRACTS => on: ", projectPath)
                this.isListen[projectPath] = true
                const qry = db.doc(projectPath).collection("contracts").where("isActive", "==", true)
                unsubscribe = queryDataListener(CONTRACTS, qry, ContractDoc, `${projectPath}/contracts`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [APARTMENTS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: ApartmentDoc,
        listen: function (projectPath) {
            let alreadyListen = this.isListen[projectPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to APARTMENTS => on: ", projectPath)
            } else {
                // console.log("🖇...listen to APARTMENTS => on: ", projectPath)
                this.isListen[projectPath] = true
                const qry = db.doc(projectPath).collection(APARTMENTS).where("isActive", "==", true)
                unsubscribe = queryDataListener(APARTMENTS, qry, this.model, `${projectPath}/${APARTMENTS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [BUILDINGS]: {
        new: [],
        data: [],
        children: [FLOORS, APARTMENT_MODELS],
        isListen: {},
        model: BuildingDoc,
        listen: function (projectPath) {
            let alreadyListen = this.isListen[projectPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to BUILDINGS => on: ", projectPath)
            } else {
                // console.log("🖇...listen to BUILDINGS => on: ", projectPath)
                this.isListen[projectPath] = true
                const qry = db.doc(projectPath).collection(BUILDINGS).where("isActive", "==", true)
                unsubscribe = queryDataListener(BUILDINGS, qry, this.model, `${projectPath}/${BUILDINGS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [APARTMENT_MODELS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: ApartmentModelDoc,
        listen: function (buildingPath) {
            let alreadyListen = this.isListen[buildingPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to APARTMENT_MODELS => on: ", buildingPath)
            } else {
                // console.log("🖇...listen to APARTMENT_MODELS => on: ", buildingPath)
                this.isListen[buildingPath] = true
                const qry = db.doc(buildingPath).collection(APARTMENT_MODELS).where("isActive", "==", true)
                unsubscribe = queryDataListener(APARTMENT_MODELS, qry, this.model, `${buildingPath}/${APARTMENT_MODELS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [FLOORS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: FloorDoc,
        listen: function (projectPath) {
            let alreadyListen = this.isListen[projectPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to FLOORS => on: ", projectPath)
            } else {
                // console.log("🖇...listen to FLOORS => on: ", projectPath)
                this.isListen[projectPath] = true
                const qry = db.doc(projectPath).collection(FLOORS).where("isActive", "==", true)
                unsubscribe = queryDataListener(FLOORS, qry, this.model, `${projectPath}/${FLOORS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [WORKSPACES]: {
        new: [],
        data: [],
        children: [GROUPS],
        isListen: {},
        model: WorkspaceDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to WORKSPACES => on: ", contractPath)
            } else {
                // console.log("🖇...listen to WORKSPACES => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection("workspaces").where("isActive", "==", true)
                unsubscribe = queryDataListener(WORKSPACES, qry, WorkspaceDoc, `${contractPath}/workspaces`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [GROUPS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: GroupDoc,
        listen: function (wsPath) {
            let alreadyListen = this.isListen[wsPath] === true
            let unsubscribe
            if (alreadyListen) {
                // console.log("ℹ already listen to GROUPS => on: ", wsPath)
            } else {
                // console.log("🖇...listen to GROUPS => on: ", wsPath)
                this.isListen[wsPath] = true
                const qry = db.doc(wsPath).collection("groups").where("isActive", "==", true)
                unsubscribe = queryDataListener(GROUPS, qry, GroupDoc, `${wsPath}/groups`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONTRACT_ACCOUNTS]: {
        new: [],
        data: [],
        children: [CONFIRMS, ACTUALS, PAYMENTS],
        isListen: {},
        model: AccountDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (this.isListen[contractPath]) {
                // console.log("ℹ already listen to CONTRACT_ACCOUNTS => on: ", contractPath)
            } else {
                // console.log("🖇...listen to CONTRACT_ACCOUNTS => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection("accounts").where("isActive", "==", true)
                unsubscribe = queryDataListener(CONTRACT_ACCOUNTS, qry, AccountDoc)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [ACTUALS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: ActualDoc,
        listen: function (accountPath) {
            let alreadyListen = this.isListen[accountPath] === true
            let unsubscribe
            if (this.isListen[accountPath]) {
                // console.log("ℹ already listen to ACTUALS => on: ", accountPath)
            } else {
                // console.log("🖇...listen to ACTUALS => on: ", accountPath)
                this.isListen[accountPath] = true
                const qry = db.doc(accountPath).collection(ACTUALS).where("isActive", "==", true)
                unsubscribe = queryDataListener(ACTUALS, qry, ActualDoc, `${accountPath}/${ACTUALS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONFIRMS]: {
        new: [],
        data: [],
        children: [],
        isListen: {},
        model: ConfirmDoc,
        listen: function (accountPath) {
            let alreadyListen = this.isListen[accountPath] === true
            let unsubscribe
            if (this.isListen[accountPath]) {
                // console.log("ℹ already listen to CONFIRMS => on: ", accountPath)
            } else {
                // console.log("🖇...listen to CONFIRMS => on: ", accountPath)
                this.isListen[accountPath] = true
                const qry = db.doc(accountPath).collection(CONFIRMS).where("isActive", "==", true)
                unsubscribe = queryDataListener(CONFIRMS, qry, ConfirmDoc, `${accountPath}/${CONFIRMS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONTRACT_COMMENTS]: {
        new: [],
        data: [],
        isListen: {},
        children: [],
        model: ContractCommentDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (this.isListen[contractPath]) {
                // console.log("ℹ already listen to CONTRACT_COMMENTS => on: ", contractPath)
            } else {
                // console.log("🖇...listen to CONTRACT_COMMENTS => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection(CONTRACT_COMMENTS).where("isActive", "==", true)
                unsubscribe = queryDataListener(CONTRACT_COMMENTS, qry, ContractCommentDoc, `${contractPath}/${CONTRACT_COMMENTS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONTRACT_SECTIONS]: {
        new: [],
        data: [],
        isListen: {},
        children: [SECTION_MILESTONES],
        model: SectionDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (this.isListen[contractPath]) {
                // console.log("ℹ already listen to CONTRACT_SECTIONS => on: ", contractPath)
            } else {
                // console.log("🖇...listen to CONTRACT_SECTIONS => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection("sections").orderBy("index", "asc").where("isActive", "==", true)
                unsubscribe = queryDataListener(CONTRACT_SECTIONS, qry, SectionDoc, `${contractPath}/sections`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONTRACT_ADDITIONS]: {
        new: [],
        data: [],
        isListen: {},
        children: [],
        model: AdditionDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (this.isListen[contractPath]) {
                // console.log("ℹ already listen to CONTRACT_ADDITIONS => on: ", contractPath)
            } else {
                // console.log("🖇...listen to CONTRACT_ADDITIONS => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection("additions").where("isActive", "==", true)
                unsubscribe = queryDataListener(CONTRACT_ADDITIONS, qry, AdditionDoc, `${contractPath}/additions`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [CONTRACT_SUBTRACTIONS]: {
        new: [],
        data: [],
        isListen: {},
        children: [],
        model: SubtractionDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (this.isListen[contractPath]) {
                // console.log("ℹ already listen to CONTRACT_SUBTRACTIONS => on: ", contractPath)
            } else {
                // console.log("🖇...listen to CONTRACT_SUBTRACTIONS => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection(CONTRACT_SUBTRACTIONS).where("isActive", "==", true)
                unsubscribe = queryDataListener(CONTRACT_SUBTRACTIONS, qry, SubtractionDoc, `${contractPath}/${CONTRACT_SUBTRACTIONS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    // [CONTRACT_BILLINGS]: {
    //     new: [],
    //     data: [],
    //     isListen: {},
    //     children: [PAYMENTS],
    //     model: BillingDoc,
    //     listen: function (contractPath) {
    //         let alreadyListen = this.isListen[contractPath] === true
    //         let unsubscribe
    //         if (this.isListen[contractPath]) {
    //             // console.log("ℹ already listen to CONTRACT_BILLINGS => on: ", contractPath)
    //         } else {
    //             // console.log("🖇...listen to CONTRACT_BILLINGS => on: ", contractPath)
    //             this.isListen[contractPath] = true
    //             const qry = db.doc(contractPath).collection(CONTRACT_BILLINGS).where("isActive", "==", true)
    //             unsubscribe = queryDataListener(CONTRACT_BILLINGS, qry, BillingDoc, `${contractPath}/${CONTRACT_BILLINGS}`)
    //         }
    //         return { alreadyListen, unsubscribe }
    //     },
    // },
    [PAYMENTS]: {
        new: [],
        data: [],
        isListen: {},
        children: [],
        model: PaymentDoc,
        listen: function (contractPath) {
            let alreadyListen = this.isListen[contractPath] === true
            let unsubscribe
            if (this.isListen[contractPath]) {
                // console.log("ℹ already listen to PAYMENTS => on: ", contractPath)
            } else {
                // console.log("🖇...listen to PAYMENTS => on: ", contractPath)
                this.isListen[contractPath] = true
                const qry = db.doc(contractPath).collection(PAYMENTS).where("isActive", "==", true)
                unsubscribe = queryDataListener(PAYMENTS, qry, PaymentDoc, `${contractPath}/${PAYMENTS}`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
    [SECTION_MILESTONES]: {
        new: [],
        data: [],
        isListen: {},
        children: [],
        model: MilestoneDoc,
        listen: function (sectionPath) {
            let alreadyListen = this.isListen[sectionPath] === true
            let unsubscribe
            if (this.isListen[sectionPath]) {
                // console.log("ℹ already listen to SECTION_MILESTONES => on: ", sectionPath)
            } else {
                // console.log("🖇...listen to SECTION_MILESTONES => on: ", sectionPath)
                this.isListen[sectionPath] = true
                const qry = db.doc(sectionPath).collection("milestones").where("isActive", "==", true)
                unsubscribe = queryDataListener(SECTION_MILESTONES, qry, MilestoneDoc, `${sectionPath}/milestones`)
            }
            return { alreadyListen, unsubscribe }
        },
    },
}