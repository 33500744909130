import m from "mithril"

import { SWITCH, DATE, STRING, EMAIL, FILE } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, PATTERN, AUTOCOMPLETE } from "../../../utils/constants/inputAttrs";
import { VENDOR_CONTACTS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { REG_PHONE_PATTERN, REG_EMAIL_PATTERN } from "../../../utils/constants/regex";
import { statusTypes } from "../../../utils/constants/statusTypes";

export const contactRoles = [
    {
        text: 'בעלים',
        id: 'owner',
    },
    {
        text: "מנכל",
        id: 'ceo',
    },
    {
        text: "מהנדס חברה",
        id: 'companyEngineer',
    },
    {
        text: "חשב/ת",
        id: 'budgeter',
    },
    {
        text: "מזכיר/ה",
        id: 'secretary',
    },
    {
        text: "הנהלת חשבונות",
        id: 'accounts',
    },
    {
        text: "מנהל פרוייקט בכיר",
        id: 'areaManager',
    },
    {
        text: "מנהל ביצוע",
        id: 'projectManager',
    },
    {
        text: "מנהל עבודה",
        id: 'siteManager',
    },
    {
        text: 'טכני',
        id: 'system',
    },
]


export class ContactDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ContactDoc,
        });
    };

    static get meta() {
        return {
            id: VENDOR_CONTACTS,
            routes: {
                collection: "vendors/:vendorID/contacts",
                doc: "vendors/:vendorID/contacts/:contactID",
            }
        }
    }
    static get headers() {
        return {
            title: { label: "שם מלא", defaultValue: "--ללא שם--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            email: { label: "אימייל", type: EMAIL, defaultValue: "", props: [{ [PATTERN]: REG_EMAIL_PATTERN }, { [AUTOCOMPLETE]: false }] },
            phone: { label: 'טלפון', type: STRING, defaultValue: "", props: [{ [PATTERN]: REG_PHONE_PATTERN }] },
            dob: { label: 'תאריך לידה', type: DATE, defaultValue: "" },
            address: { label: 'כתובת', type: STRING, defaultValue: "--ללא כתובת--", },
            photoURL: { label: "תמונה", type: FILE, defaultValue: "" },
            role: { label: 'תפקיד', defaultValue: "owner", type: SWITCH, options: contactRoles },
            status: { label: 'סטטוס', defaultValue: "active", type: SWITCH, options: statusTypes },
        }
    }
}