import m from "mithril"

import { STRING, NUMBER, PERCENT, CURRENCY } from "../../../utils/constants/types";
import { REQUIRED, MIN, MAX, STEP } from "../../../utils/constants/inputAttrs";
import { SECTION_MILESTONES, CONTRACT_SECTIONS, ACTUALS, CONTRACT_ACCOUNTS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";
import { ActualDoc } from "../contractsActuals/actualClass";

import { resolveDelay } from "../../../data/utils/utils"

export class MilestoneDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: MilestoneDoc,
        });
    };

    isPercent() {
        const section = this.getSection()
        if (!section) throw 'section not found'
        return section.isPercent()
    }
    isPauschal() {
        const section = this.getSection()
        if (!section) throw 'section not found'
        return section.isPauschal()
    }
    getAmountType() {
        return this.getParent("amountType")
    }

    // calcTotalActualsValue(){

    // }

    calcTotalPlan() {
        const section = this.getSection()
        if (!section) throw "section not found"
        const { itemsCount } = section.docData
        return this.isPercent() ?
            this.getParent('totalSum') * (parseFloat(this.docData.weight) / 100)
            :
            parseFloat(this.docData.price) * itemsCount
    }

    getSection() {
        return [...dataStore[CONTRACT_SECTIONS].data, ...dataStore[CONTRACT_SECTIONS].new]
            .filter(section => section.docData.ref === this.docData.colRef.replace("/milestones", ""))[0]
    }

    getActuals({ unit, accountRef } = {}) {
        return [...dataStore[ACTUALS].data, ...dataStore[ACTUALS].new]
            .filter(actual => actual.docData.sectionRef === this.docData.ref &&
                (unit !== undefined ? actual.docData.unit == unit : true) &&
                (accountRef ? actual.docData.colRef === `${accountRef}/${ACTUALS}` : true)
            )
    }
    getTotalActuals({ unit, accountRef } = {}) {
        let result = 0
        this.getActuals({ unit, accountRef }).forEach(act => {
            result += parseFloat(act.docData.value)
        })
        if (Number.isNaN(result)) return 0
        return result
    }
    getLastTotalActuals({ unit, accountRef } = {}) {
        let result = 0
        this.getLastActuals({ unit, accountRef }).forEach(act => {
            result += parseFloat(act.docData.value)
        })
        if (Number.isNaN(result)) return 0
        return result
    }

    getLastActuals({ unit, accountRef } = {}) {
        if (!accountRef) throw `cannot call milestone.getLastActuals() whithout accountRef`
        return dataStore[ACTUALS].data
            .filter(actual => actual.docData.sectionRef === this.docData.ref &&
                actual.docData.colRef !== `${accountRef}/${ACTUALS}` &&
                (unit !== undefined ? actual.docData.unit == unit : true)
            )
    }
    getActualDocInAccount({ accountRef, unit } = {}) {
        //for each account-ms-unit there is one Doc
        // console.log("getActualDocInAccount(accountRef= ", accountRef, " , unit= ", unit, ")")
        // console.assert(accountRef !== undefined && accountRef !== "" && unit !== undefined && unit !== "")
        let actual = this.getActuals({ accountRef, unit })[0]
        if (!actual) {
            const accountDoc = DocModel.getChild(CONTRACT_ACCOUNTS, accountRef)
            let title = this.docData.title
            if (accountDoc) title += ` - ${accountDoc.docData.month}`
            actual = new ActualDoc({
                title,
                sectionRef: this.docData.ref,
                colRef: `${accountRef}/${ACTUALS}`,
                unit,
            }, true);
        }
        return actual
    }

    saveLocal(changes, old) {
        super.saveLocal(changes, old)
        const isPercent = this.isPercent()
        if ("price" in changes && !isPercent) {
            this.setSectionPrice()
        }
    }
    setSectionPrice() {
        const totalMilestones = this.getTotalDocs(this.model.meta.id, {}, { siblings: true }, "price")
        const section = this.getParent()
        section.edit({
            itemPrice: totalMilestones,
            totalSum: totalMilestones * section.docData.itemsCount
        })
    }

    /**
     * @override remove => remove milestone + setSectionPrice
     */
    async remove(path) {
        try {
            await Promise.resolve(super.remove(path))
            m.redraw()
        } catch (err) {
            console.error("error on insert section ", err);
        }
        await resolveDelay()
        this.setSectionPrice()
        m.redraw()
    }


    static get meta() {
        return {
            id: SECTION_MILESTONES,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/sections/:sectionID/milestones",
                doc: "/projects/:projectID/contracts/:contractID/sections/:sectionID/milestones/:milestoneID",
            }
        }
    }
    static get headers() {
        return {
            index: { label: "#", type: NUMBER, defaultValue: 1, props: [{ [REQUIRED]: true }], },
            title: { label: "אבן דרך של יחידה", type: STRING, defaultValue: "[ביצוע]", props: [{ [REQUIRED]: true }] },
            weight: { label: " משקל באחוזים", type: PERCENT, defaultValue: 100, props: [{ [STEP]: "any" }, { [REQUIRED]: true }, { [MIN]: 0 }] },
            price: { label: "סכום", type: CURRENCY, defaultValue: 0, props: [{ [REQUIRED]: true }, { [MIN]: 0 }, { [STEP]: "any" }] },
            totalDone: { label: "סהכ בוצע", defaultValue: 0 }
        }
    }
}