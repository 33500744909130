import { SWITCH, STRING, NUMBER } from "../../../utils/constants/types";
import { userRoles } from "../../../utils/constants/userRoles";

import { DocModel } from "../docModel";
import { CONFIRMS } from "../../dictionary/routeNames";
import { ACCOUNTING, confirmAccountFlow, OPERATION } from "./accountClass";

export class ConfirmDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ConfirmDoc,
        });
    };

    static get meta() {
        return {
            id: CONFIRMS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/accounts/:accountID/confirms",
                doc: "/projects/:projectID/contracts/:contractID/accounts/:accountID/confirms/:confirmID",
            }
        }
    }

    save() {
        if ("confirm" in this.docChanges && this.docChanges.confirm !== "") {
            const parent = this.getParent()
            return parent.confirm(this.docChanges.confirm,this.docData.id)
        } else {
            super.save()
        }
        // if ("confirm" in this.docChanges && this.docChanges.confirm !== "") {
        //     console.log("ConfirmDoc.save() middleware - [TESTME: update {confirmBy,confirmAt | rejectedBy,rejectedAt}]", this.docChanges.confirm);
        //     //false,true,hold,reject
        //     const options = {}
        //     if (this.docChanges.confirm == true) {
        //         options.confirmAt = new Date().toISOString()
        //         options.confirmBy = auth.currentUser.uid
        //     } else if (this.docChanges.confirm == false) {
        //     } else if (this.docChanges.confirm == "hold") {
        //         options.holdAt = new Date().toISOString()
        //         options.holdBy = auth.currentUser.uid
        //     } else if (this.docChanges.confirm == "reject") {
        //         options.rejectedAt = new Date().toISOString()
        //         options.rejectedBy = auth.currentUser.uid
        //     } else {
        //         console.error("unknown option for cofirm field");
        //     }
        //     Object.entries(options).forEach(([key, val]) => {
        //         this.docData[key] = val
        //         this.docChanges[key] = val
        //     })
        //     console.log("ConfirmDoc.save() middleware - [2] set AccountStage to next stage:", this.docData.id, this.docData.next);
        //     // [2] set AccountStage to current:
        //     const parentAccount = this.getParent()
        //     parentAccount.saveLocal({ stage: this.docData.next || "finish" })
        //     parentAccount.save()
        // }
        // super.save()
    }
    /**   EXAMPLE:
    *  
    * new ConfirmDoc({
    *   colRef:"/projects/[glilYam]/contracts/[sheled]/accounts/[partial1]/confirms",
    *   index:1
    *   role:["projectManager"],
    *   next:["mainEngineer"] || if is last next:false
    *   confirm:false,
    * })
    * 
    *   AFTER CONFIRM:
    * {
    *   confirm:true
    *   confirmAt:"2020-01-01", 
    *   confirmBy:"BRjKVmVajjg62VO3K3aJGxmmccq1", 
    * } 
    */
    static get headers() {
        return {
            title: { label: 'כותרת', defaultValue: "--אישור חשבון--", type: STRING },
            index: { label: "מס", defaultValue: 1, type: NUMBER },
            role: { label: "גורם מאשר", defaultValue: "", type: SWITCH, options: userRoles, multiple: true },
            id: { label: "מזהה", defaultValue: "", type: STRING },
            due: { label: "יום בחודש", defaultValue: 10, type: NUMBER },
            type: { label: "סוג", defaultValue: OPERATION, type: SWITCH, options: [{ id: OPERATION, text: "ביצוע" }, { id: ACCOUNTING, text: "הנהח" }] },
            next: { label: "גורם הבא בסבב", defaultValue: "", type: SWITCH, options: confirmAccountFlow, multiple: false },
            confirm: { label: "אישור", defaultValue: false, type: SWITCH, options: [{ id: false, text: "ממתין לאישור" }, { id: true, text: "מאושר" }, { id: "hold", text: "בהקפאה" }, { id: "reject", text: "נדחה" }] },
            confirmAt: { label: "אושר ב", defaultValue: "" },
            confirmBy: { label: "אישור", defaultValue: "" }
        }
    }
}