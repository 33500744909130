import m from "mithril"
import { ACTUALS, CONTRACTS, CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, PROJECTS, SECTION_MILESTONES, VENDORS } from "../../../../data/dictionary/routeNames"
import { CURRENCY, NUMBER, PERCENT, REF, STRING } from "../../../../utils/constants/types"

import { db } from "../../../../index"

import { Card } from "../../../commons/cardLayout/Card"

import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { findRepeat, getModelID } from "../../../../data/utils/utils"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { ReportLayout } from "../ReportLayout"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { DocModel } from "../../../../data/store/docModel"
import { buildDoc, getChildren, getLastAccount, getParent, getSubChildren } from "../utils"
import { sortDocsBy, uuid } from "../../../../utils/js"
import { Tooltip } from "../../../components/tooltip/Tooltip"

export const ProjectAccountsReport = node => {

    async function loadReportData(ref) {
        try {
            node.state.collectionData = []
            node.state.load = true
            let qry = db.doc(`projects/${node.attrs.projectID}`).collection(CONTRACTS).where("isActive", "==", true)
            const docs = (await qry.get()).docs
            for (const fbDoc of docs) {
                const doc = buildDoc(fbDoc)
                const { ref } = doc.docData
                const project = await getParent(ref)
                if (project.docData.isActive == true) {
                    doc.parent = project
                    //direct children
                    const accounts = await getChildren(CONTRACT_ACCOUNTS, ref)
                    doc.children = { accounts }
                    node.state.collectionData = [...node.state.collectionData, doc]
                    m.redraw()
                }
            }
            node.state.load = false
        } catch (err) {
            console.error(err);
        }
    }


    const temp = {}
    const calcAccountData = (doc) => {
        if (temp[doc.docData.ref]) return temp[doc.docData.ref]

        temp[doc.docData.ref] = {}
        const getHistoryBilling = () => {
            let result = 0
            doc.children.accounts.forEach(account => {
                const last = getLastAccount(doc)
                if (last && account.docData.period === last.docData.month) { } //SKIP
                else result += parseFloat(account.docData.subTotal)
            })
            return result
        }
        const delayPercentage = parseFloat(doc.docData.delayPercentage / 100)

        const totalActuals = parseFloat(doc.docData.totalSections)
        const totalAdditions = parseFloat(doc.docData.totalAdditions)
        const totalSubtractions = parseFloat(doc.docData.totalSubtractions)

        const totalAddSub = totalAdditions - totalSubtractions

        const totalDelay = parseFloat((totalActuals + totalAddSub) * delayPercentage)

        const subTotalCumulative = totalActuals + totalAddSub - totalDelay
        const totalHistoryBillings = getHistoryBilling()
        const totalToPay = subTotalCumulative - totalHistoryBillings
        const percentDone = parseFloat(subTotalCumulative / doc.docData.contractSum)

        temp[doc.docData.ref].delayPercentage = delayPercentage
        temp[doc.docData.ref].totalAdditions = totalAdditions
        temp[doc.docData.ref].totalSubtractions = totalSubtractions
        temp[doc.docData.ref].totalAddSub = totalAddSub
        temp[doc.docData.ref].totalDelay = totalDelay
        temp[doc.docData.ref].subTotalCumulative = subTotalCumulative
        temp[doc.docData.ref].totalToPay = totalToPay
        temp[doc.docData.ref].percentDone = percentDone
        return temp[doc.docData.ref]
    }

    const reportHeaders = {
        title: {
            label: "שם החוזה",
            nav: doc => {
                return `/#!/app/${doc.docData.ref}`
            },
            prompt: doc => {
                console.log("TODO: prompt something...");
            },
            val: doc => {
                return { value: doc.docData.title }
            }
        },
        _lastAccountMonth: {
            label: "חשבון נוכחי",
            val: doc => {
                let lastAccount = "---"
                const last = getLastAccount(doc)
                if (last) lastAccount = last.docData.month
                return { value: lastAccount }
            },
            type: STRING,
            options: {
                ltr: true
            }
        },
        _totalCumulative: {
            label: "חשבון מצטבר",
            val: doc => {
                return { value: calcAccountData(doc).subTotalCumulative }
            },
            type: CURRENCY
        },
        _totalAdditionSubtractionActual: {
            label: "ביצוע - תוספות וקיזוזים",
            val: doc => {
                const totalAdditions = calcAccountData(doc).totalAdditions
                const totalSubtractions = calcAccountData(doc).totalSubtractions
                return { value: parseFloat(totalAdditions - totalSubtractions), additionalInfo: { value: `=[תוספות:${getDisplayValue(totalAdditions, NUMBER)}] - [קיזוזים:${getDisplayValue(totalSubtractions, NUMBER)}]` } }
            },
            type: CURRENCY
        },
        _totalDelay: {
            label: "עיכבון מצטבר",
            val: doc => {
                return { value: calcAccountData(doc).totalDelay }
            },
            type: CURRENCY
        },
        _totalToPay: {
            label: "לתשלום תק נוכחית",
            val: doc => {
                return { value: calcAccountData(doc).totalToPay }
            },
            type: CURRENCY
        },
        _totalContract: {
            label: "סכום הסכם",
            val: doc => {
                return { value: doc.docData.contractSum }
            },
            type: CURRENCY
        },
        _totalAdditionSubrtaction: {
            label: "תוספות וקיזוזים - סיכום",
            val: doc => {
                const totalAddition = calcAccountData(doc).totalAdditions
                const totalSubtraction = calcAccountData(doc).totalSubtractions
                return { value: parseFloat(totalAddition - totalSubtraction), additionalInfo: { value: `=[תוספות:${getDisplayValue(totalAddition, NUMBER)}] - [קיזוזים:${getDisplayValue(totalSubtraction, NUMBER)}]` } }
            },
            type: CURRENCY
        },
        _donePercentage: {
            label: "% ביצוע",
            val: doc => {
                return { value: calcAccountData(doc).percentDone }
            },
            type: PERCENT
        }
    }

    return {
        collectionData: [],
        history: [],
        prompt: false,
        snackbar: false,
        oninit: async vnode => {
            const ref = `projects/${vnode.attrs.projectID}`
            let findProject
            const findFunc = () => findProject = DocModel.getChild(PROJECTS, ref)
            await findRepeat(findFunc, findProject != undefined)
            if (findProject) {
                node.state.project = findProject
                await loadReportData(ref)
            } else {
                m.route.set("/app/reports/projects")
            }
        },
        view: vnode => {
            const { collectionData = [], load, project } = vnode.state
            return m(ReportLayout, { title: `סטטוס חשבונות לפרוייקט ${project ? ` - "${project.docData.title}"` : ""}` },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Card, { class: "tableCard" },
                    m(".table",
                        //HEADERS
                        m(".table__row.table__headers", { style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);` },
                            Object.entries(reportHeaders).map(([header, { label }]) => m(".table__cell", label))
                        ),
                        load && m(InlineLoaderDots),
                        //DATA
                        collectionData
                            .sort(sortDocsBy("title", { type: STRING }))
                            .map(doc => {
                                const styleColumns = `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`
                                return m(".table__row", { style: styleColumns, key: uuid() },
                                    Object.entries(reportHeaders).map(([header, { label, val, type = STRING, options = {}, nav, prompt }]) => {
                                        const { value, classStyle = "", additionalInfo = "" } = val(doc)
                                        const displayValue = getDisplayValue(value, type, options)
                                        return m(`${nav ? "a" : "div"}.table__cell`, {
                                            key: `${doc.docData.ref}/${header}`,
                                            class: nav ? "link" : "",
                                            href: nav ? nav(doc) : "#",
                                            target: "_blank",
                                            "data-header": header,
                                            onclick: e => {
                                                if (prompt) {

                                                }
                                            }
                                        },
                                            displayValue,
                                            m(Tooltip, { text: `${label}:\n${displayValue}\r${additionalInfo.value}`, options }),
                                        )
                                    })
                                )
                            }),
                        //TOTALS row
                        (collectionData.length > 0 && project && !load) && m(".table__totals", {
                            style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`,
                        },
                            Object.entries(reportHeaders).map(([header, fieldOptions]) => {
                                if (header === "title") {
                                    return m(".total", `${getDisplayValue(collectionData.length, NUMBER)} שורות`)
                                }
                                if (fieldOptions.totals) {
                                    console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                                    const totalValue = fieldOptions.totals.val(collectionData)
                                    return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                                }
                                if (fieldOptions.type === NUMBER || fieldOptions.type === CURRENCY) {
                                    let total = 0
                                    if (fieldOptions.val) {
                                        total = collectionData.reduce((acc, doc) => {
                                            return acc += parseFloat(fieldOptions.val(doc).value)
                                        }, 0)
                                    } else {
                                        total = ""
                                    }
                                    return m(".total", getDisplayValue(total, fieldOptions.type, fieldOptions))
                                }
                                return m(".total", "---")
                            })
                        )
                    )//END table
                ) //END tableCard
            )
        }
    }
}