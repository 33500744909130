import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';

// DATA
import { Prompt } from '../../commons/prompt/Prompt';
import { Icon } from '../../components/icon/Icon';
import { Snackbar } from '../../components/snackbar/Snackbar';

export const tools = [
    {
        path: "/utils/tools/indexCalcultor",
        icon: "tools",
        text: "הצמדה למדד"
    },
    {
        path: "/utils/tools/importCSV",
        icon: "tools",
        text: "ייבוא נתונים"
    },
    {
        path: "/utils/tools/calculator",
        icon: "tools",
        text: "מחשבון"
    },
]



export const Tools = (node) => {


    return {
        snackbar: false,
        prompt: false,
        load: true,
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
        },
        view: (vnode) => {
            return m(PageLayout, { class: "tools", title: "כלים" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                ),
                m(".tools__nav",
                    tools.map(tool => {
                        return m(".tools__path", { onclick: e => m.route.set(tool.path) }, m(Icon, { icon: `icon-${tool.icon}` }), tool.text)
                    })
                )
            )
        }
    }
}