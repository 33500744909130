export const AUTOCOMPLETE = "autocomplete";
export const AUTOFOCUS = "autofocus";
export const CHECKED = "checked";
export const DISABLED = "disabled";
export const READONLY = "readonly";
export const REQUIRED = "required";
export const MAX = "max";
export const MIN = "min";
export const MINLENGTH = "minlength";
export const MAXLENGTH = "maxlength";
export const MULTIPLE = "multiple";
export const PATTERN = "pattern";
export const PLACEHOLDER = "placeholder";
export const STEP = "step";
export const HIDDEN = "hidden";

export const UNIQUE = "unique"