import m from "mithril"
import { SWITCH, DATE, STRING, NUMBER, PERCENT, CURRENCY, VALID_CALC } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, MIN, MAX, READONLY, DISABLED, STEP } from "../../../utils/constants/inputAttrs";
import { ACTUALS, CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, FILES, SECTION_MILESTONES } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";
import { sortBy, sortDocsBy } from "../../../utils/js";
import { db } from "../../../index";
import { ActualDoc } from "../contractsActuals/actualClass";
import { O_FUNCTION } from "../../../utils/constants/objTypes";

export class SubtractionDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: SubtractionDoc,
        });
        // this.setIndex();
    };

    getProject(param) {
        const [p, pID] = this.docData.ref.split("/")
        const project = dataStore.projects.data.find(doc => doc.docData.docID === pID)
        if (project) {
            if (param) return project.docData[param]
            return project
        } else {
            throw `project ${pID} not found!!!`
        }
    }

    isPauschal() {
        return true
    }
    getAmountType() {
        return this.docData.amountType
    }

    getActualDocInAccount({ accountRef, unit = 1 } = {}) {
        let actual = this.getActuals({ accountRef })[0]
        if (!actual) {
            const accountDoc = DocModel.getChild(CONTRACT_ACCOUNTS, accountRef)
            let title = this.docData.title
            if (accountDoc) title += ` - ${accountDoc.docData.month}`
            actual = new ActualDoc({
                title,
                sectionRef: this.docData.ref,
                colRef: `${accountRef}/${ACTUALS}`,
                unit,
            }, true);
        }
        return actual
    }

    getTotalActuals(accountRef) {
        let result = 0
        this.getActuals({ accountRef }).forEach(act => {
            result += parseFloat(act.docData.value)
        })
        return result
    }
    getFiles() {
        const contract = this.getParent()
        return contract.getChildren(FILES, { include: { sectionRef: this.docData.ref } })
    }

    getLastActuals({ accountRef } = {}) {
        if (!accountRef) throw `cannot call Subtraction.getLastActuals() whithout accountRef`
        return dataStore[ACTUALS].data
            .filter(actual => actual.docData.sectionRef === this.docData.ref && actual.docData.colRef !== `${accountRef}/${ACTUALS}`)
    }

    getActuals({ accountRef, filterOptions = {} } = {}) {
        const { exclude, include } = filterOptions
        const excludeFilter = exclude ? Object.entries(exclude) : false
        const includeFilter = include ? Object.entries(include) : false
        const includeFunction = (doc, param, value) => {
            if (objType(value) === O_FUNCTION) {
                return value(doc)
            }
            return doc.docData[param] === value
        }
        return [...dataStore[ACTUALS].data, ...dataStore[ACTUALS].new]
            .filter(doc => {
                return doc.docData.sectionRef.startsWith(this.docData.ref) &&
                    (accountRef ? doc.docData.colRef === `${accountRef}/${ACTUALS}` : true) &&
                    (includeFilter ? includeFilter.every(([param, value]) => includeFunction(doc, param, value)) : true) &&
                    (excludeFilter ? excludeFilter.every(([param, value]) => doc.docData[param] !== value) : true)
            })
    }

    getLastActualsValue({ accountRef } = {}) {
        let result = 0
        this.getLastActuals({ accountRef }).forEach(act => {
            result += parseFloat(act.docData.value)
        })
        return result
    }

    getActualsValue({ accountRef } = {}) {
        let result = 0
        this.getLastActuals({ accountRef }).forEach(act => {
            result += parseFloat(act.docData.value)
        })
        return result
    }

    calcTotalActualsValue({ accountRef } = {}) {
        let total = 0
        this.getActuals({ accountRef }).forEach(act => {
            total += parseFloat(this.docData.totalSum) * (parseFloat(act.docData.value) / 100)
        })
        return total
    }

    calcTotalActuals({ accountRef, forceCalc = false } = {}) {
        let total = 0
        this.getActuals({ accountRef }).forEach(act => {
            if (act.hasChanges() || forceCalc) {
                const { currentTotal, calc } = act.calcSubTotal(false, this)
                total += currentTotal
            } else {
                total += parseFloat(act.docData.currentTotal)
            }
        })
        return total
    }

    calcLastTotalActuals({ accountRef } = {}) {
        let total = 0
        this.getLastActuals({ accountRef }).forEach(act => {
            total += parseFloat(this.docData.totalSum) * (parseFloat(act.docData.value) / 100)
        })
        return total
    }

    static get meta() {
        return {
            id: CONTRACT_SUBTRACTIONS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/subtraction",
                doc: "/projects/:projectID/contracts/:contractID/subtraction/:subtractioID",
            },
            logic: [
                { type: VALID_CALC, target: "totalSum", expression: "itemsCount * itemPrice", trigger: ["itemsCount", "itemPrice"] },
            ],

        }
    }
    static get headers() {
        return {
            title: { label: "כותר", type: STRING, defaultValue: "[קיזוז]", props: [{ [REQUIRED]: true }] },
            index: { label: "אינדקס", type: NUMBER, defaultValue: 1 },
            description: { label: 'תאור', defaultValue: "--ללא תיאור--", type: STRING },
            amountType: { label: "סוג יח'", type: STRING, defaultValue: "יח" },
            itemsCount: { label: "כמות יחידות", type: NUMBER, defaultValue: 0, props: [{ [REQUIRED]: true }, { [MIN]: 0 }] },
            itemPrice: { label: "מחיר יח'", type: CURRENCY, defaultValue: 0, props: [{ [REQUIRED]: true }, { [MIN]: 0 }] },
            totalSum: { label: "סכום הסכם", type: CURRENCY, defaultValue: 0, validation: [{ expression: "itemsCount * itemPrice" }], totals: "sum", props: [{ [STEP]: "1" }, { [MIN]: 0 }, { [DISABLED]: true }] },
            //THINK: donePercentage? calc by actuals
            donePercentage: { label: "אחוז ביצוע", defaultValue: 0, type: PERCENT, props: [{ [STEP]: "any" }, { [MIN]: 0 }, { [MAX]: 100 }, { [DISABLED]: true }] },
        }
    }
}
