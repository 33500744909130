import m from "mithril"
import { APARTMENTS, APARTMENT_MODELS, CONTRACTS, FLOORS } from "../../../data/dictionary/routeNames"
import { DocModel } from "../../../data/store/docModel"
import { NavClass } from "../../../data/store/NavClass"
import { STRING } from "../../../utils/constants/types"
import { Card } from "../../commons/cardLayout/Card"
import { Nav } from "../../commons/nav/Nav"
import { FullPageLayout } from "../../commons/pageLayout/FullPage"
import { FormEditDoc } from "../../commons/prompt/FormEditDoc"
import { Table } from "../../commons/table/Table"
import { TableInline } from "../../commons/table/TableInline"

export const BuildingPage = node => {

    const apartmentTableHeaders = {
        _floor: {
            label: "קומה",
            val: doc => {
                return { value: doc.getFloor("title") || "---" }
            },
            type: STRING
        },
        title: {},
        description: {},
        contractsRefs: {},
        apartmentModel: {},
    }
    // const navBuidlingViewOptions = {
    //     APARTMENTS: "apartments",
    //     FLOORS: "floors",
    //     MODELS: "models",
    // }
    // const navBuildingView = new NavClass({
    //     nav: [
    //         {
    //             label: "דירות",
    //             key: navBuidlingViewOptions.APARTMENTS,
    //         },
    //         {
    //             label: "קומות",
    //             key: navBuidlingViewOptions.FLOORS,
    //         },
    //         {
    //             label: "דגמי דירות",
    //             key: navBuidlingViewOptions.MODELS,
    //         },
    //     ],
    //     settings: { defaultActiveKey: navBuidlingViewOptions.APARTMENTS }
    // })

    return {
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            // navBuildingView.setNode(vnode)
            try {
                const buildingRef = `projects/${vnode.attrs.projectID}/buildings/${vnode.attrs.buildingID}`
                const building = DocModel.getDoc(buildingRef)
                if (!building) throw "building not found"
                vnode.state.building = building
                const project = building.getParent()
                if (!project) throw "project not found"
                vnode.state.project = project
            } catch (err) {
                console.error(err);
                m.route.set(`/app/projects/${vnode.attrs.projectID}`)
                m.redraw()
            }
        },
        view: vnode => {
            const { building, project } = vnode.state
            return m(FullPageLayout, {
                title: `בניין ${building.docData.title}`,
                back: `/app/${project.docData.ref}/${APARTMENTS}`
            },
                m(Card,
                    m(FormEditDoc, { doc: building, headers: ["title", "description", "floorsCount", "apartmentsCount", "includeGroundFloor",] }),
                ),

                // m(Nav, { nav: navBuildingView.nav }),
                // (project && building && navBuildingView.isActiveState(navBuidlingViewOptions.APARTMENTS)) && m(Table, {
                //     title: "דירות בבנין",
                //     modelID: APARTMENTS,
                //     colRef: `${project.docData.ref}/${APARTMENTS}`,
                //     doc: project,
                //     tableHeaders: apartmentTableHeaders,
                //     actions: { onRow: "redirect" },
                //     viewOptions: { add: false },
                //     filterDataOptions: { include: { buildingFloorPath: doc => doc.docData.buildingFloorPath.includes(building.docData.docID) } },
                //     sortOptions: { param: "index" },
                //     searchOptions: ["title", "index", "description"],
                // }),
                // (project && building && navBuildingView.isActiveState(navBuidlingViewOptions.FLOORS)) && m(Table, {
                //     title: "קומות",
                //     modelID: FLOORS,
                //     colRef: `${building.docData.ref}/${FLOORS}`,
                //     doc: building,
                //     tableHeaders: ["title", "description", "apartmentsCount", "fromIndex", "toIndex"],
                //     sortOptions: { param: "index" },
                //     searchOptions: ["title", "index", "description"],
                // }),
                // (project && building && navBuildingView.isActiveState(navBuidlingViewOptions.MODELS)) && m(Table, {
                (project && building) && m(Table, {
                    title: "דגמי דירות",
                    modelID: APARTMENT_MODELS,
                    colRef: `${building.docData.ref}/${APARTMENT_MODELS}`,
                    doc: building,
                    tableHeaders: ["title", "description"],
                    sortOptions: { param: "index" },
                    searchOptions: ["title", "index", "description"],
                }),
            )
        }
    }
}