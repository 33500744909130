import { objType } from "./js";
import { O_FUNCTION } from "./constants/objTypes";
import { DISABLED } from "./constants/inputAttrs";

export const setPropetiesToComponent = (el, props, doc, childIndex) => {
    if (props) {
        props.forEach(prop => {
            const [[key, val]] = Object.entries(prop)
            let value = val;
            if (objType(val) === O_FUNCTION && doc) {
                value = val(doc)
            }

            let elem = el.dom;
            if (childIndex !== undefined) elem = elem.children[childIndex];

            const has = key.toString() in elem.__proto__;
            // console.log(`TODO: setProperty (${key},${value} to ${elem})`)
            if (has) {
                if (key === DISABLED) {
                    if (value == true) elem.setAttribute(key, value)
                } else {
                    elem.setAttribute(key, value)
                }
            }
        })
    }
}