// import "dotenv"
// const result = dotenv.config()

// if (result.error) {
//   throw result.error
// }

// console.log(result.parsed)

import './firebase/init';
import m from 'mithril';
const root = document.body;
import './index.scss';

//DATA
import { settingsStore } from './data/store/settingsStore';

//FUNCTIONS
export const auth = firebase.auth();
auth.onAuthStateChanged(user => {
    setTimeout(() => {
        if (user) {
            UserDoc.setCurrentUser()
        } else {
            const ref = m.route.get() !== "/login" ? { ref: m.route.get() } : {}
            m.route.set("/login", ref);
        }
        m.redraw()
    }, 1000);
})
export const db = firebase.firestore();
// m.route.prefix('?')

//VIEWS
import { LoginPage } from './views/pages/login/LoginPage';
import { Splash } from './views/pages/splash/Splash';
import { Projects } from './views/pages/projects/Projects';
import { Project } from './views/pages/project/Project';
import { User } from './views/pages/userPage/UserPage';
import { ContractPlan } from './views/pages/contract/contractPlan/ContractPlan';
import { ContractDashboard } from './views/pages/contract/contractActual/ContractDashboard';
import { ContractAdmin } from './views/pages/contract/contractActual/ContractAdmin';
import { ContractBilling } from './views/pages/contract/contractActual/ContractBilling';
import { dataStore } from './data/store/dataStore';
import { Users } from './views/pages/users/Users';
import { projectTypes } from './utils/constants/projectTypes';
import { Vendors } from './views/pages/vendors/Vendors';
import { Vendor } from './views/pages/vendors/vendor/Vendor';
import { VendorContact } from './views/pages/vendors/vendor/VendorContact';
import { Companies } from './views/pages/companies/Companies';
import { Company } from './views/pages/companies/company/Company';
import { IndexedCalculatorPage } from './views/pages/indexedCalculator/IndexedCalculatorPage';
import { ImportCSV } from './views/pages/testImportCSV';
import { CalculatorPage } from './views/pages/calculator/CalculatorPage';
import { Tools } from './views/pages/splashTools/Tools';
import { Settings } from './views/pages/splashSettings/Settings';
import { BudgetChapters } from './views/pages/budget/BudgetChapters';
// import { SettingsPage } from './views/pages/settings/SettingsPage';
// import Contract from './views/pages/Contract/Contract';
// import Section from './views/pages/SectionDesign/SectionDesign';
import { SectionPage } from './views/pages/contract/SectionPage';
import { StyleGuide } from './views/commons/StyleGuide/StyleGuide';
import { BudgetChapter } from './views/pages/budget/BudgetChapter';
import { BudgetInput } from './views/commons/inputCell/BudgetInput';
import { PageLayout } from './views/commons/pageLayout/Page';
import { UserDoc } from './data/store/users/userClass';
import { ReportBuilder } from './views/pages/reports/ReportBuilder';
import { isSystemMode } from './data/utils/viewUtils';
import { DocModel } from './data/store/docModel';
import { CONTRACTS } from './data/dictionary/routeNames';
import { VendorsReports } from './views/pages/reports/vendors/VendorsReport';
import { VendorAccountsReport } from './views/pages/reports/vendors/VendorAccountsReport';
import { VendorActualsReport } from './views/pages/reports/vendors/VendorActualsReport';
import { SplashReports } from './views/pages/splash/SplashReports';
import { Tasks } from './views/pages/tasks/Tasks';
import { SystemFields } from './views/pages/system/systemFields';
import { Apartments } from './views/pages/apartments/Apartments';
import { ProjectsReports } from './views/pages/reports/projects/ProjectsReports';
import { ApartmentPage } from './views/pages/apartments/Apartment';
import { BuildingPage } from './views/pages/apartments/Building';
import { ProjectAccountsReport } from './views/pages/reports/projects/ProjectAccountsReport';
import { ProjectConfirmsReport } from './views/pages/reports/projects/ProjectConfirmsReport';
import { ChannelsListenters } from './views/pages/system/ChannelsListenters';
import { pagePrinter } from './views/pages/testPrintPage';
import { pageEmbedSheet } from './views/pages/testEmbedSheetFile';

export const APP_VERSION = "01.03.10"

window.onbeforeunload = evt => sessionStorage.setItem('lastURL', m.route.get());
try {
    setTimeout(() => {
        if (auth.currentUser !== null) {
            UserDoc.setCurrentUser(true)
                .then(() => {
                    if (isSystemMode()) {
                        console.log("not loading listeners in system mode");
                    } else {
                        settingsStore.defaultListeners.map(modelID => {
                            dataStore[modelID].listen()
                        })
                    }
                })
        }
        m.redraw()
    }, 3000);
} catch (err) {
    console.error("error on defaultListeners", err)
}


m.route(root, '/login', {
    '/login': LoginPage,

    // '/app/settings': SettingsPage,

    '/app': Splash,

    // __PROJECTS__:
    // '/app/projects': Projects,

    "/app/projects": {
        onmatch: (args) => {
            const { projectType } = args
            if (projectType) {
                const type = projectTypes.find(p => p.id === projectType)
                if (type) return { view: () => m(Projects, { projectType }) }
                else console.error(`type ${projectType} not found`);
            }
            m.route.set("/app")
        }
    },

    "/projects": {
        onmatch: (args) => {
            if (projectType in args) {
                m.route.set("/app/projects", { projectType: args.projectType })
            } else {
                m.route.set("/app")
            }
        }
    },

    // __PROJECT__:
    '/app/projects/:projectID': Project,
    "/app/projects/:projectID/replace": {
        onmatch: ({ projectID }) => {
            return { view: () => m(Project, { projectID }) }
        }
    },
    "/app/projects/:projectID/contracts": { onmatch: (args) => m.route.set("/app/projects/:projectID", { fromRef: "contracts", projectID: args.projectID }) },
    "/projects/:projectID": { onmatch: (args) => m.route.set("/app/projects/:projectID", { projectID: args.projectID }) },
    // "/projects/new": { onmatch: () => m.route.set("/app/projects/new") },

    // __APARTMENTS__:
    "/app/projects/:projectID/apartments": Apartments,
    "/projects/:projectID/apartments": { onmatch: (args) => m.route.set("/app/projects/:projectID/apartments", { projectID: args.projectID }) },
    '/app/projects/:projectID/buildings/:buildingID': BuildingPage,
    "/projects/:projectID/buildings/:buildingID": { onmatch: ({ projectID, buildingID }) => m.route.set(`/app/projects/${projectID}/buildings/${buildingID}`) },
    '/app/projects/:projectID/apartments/:apartmentID': ApartmentPage,
    "/projects/:projectID/apartments/:apartmentID": { onmatch: ({ projectID, apartmentID }) => m.route.set(`/app/projects/${projectID}/apartments/${apartmentID}`) },



    // __CONTRACT__:
    "/app/projects/:projectID/contracts/:contractID/plan": ContractPlan,
    "/app/projects/:projectID/contracts/:contractID/actual": ContractDashboard,
    "/app/projects/:projectID/contracts/:contractID/billing": ContractBilling,
    "/app/projects/:projectID/contracts/:contractID/:any/replace": {
        onmatch: ({ projectID, contractID, any }, path) => {
            if (any === "plan") return { view: () => m(ContractPlan, { projectID, contractID, any }) }
            else if (any === "actual") return { view: () => m(ContractDashboard, { projectID, contractID, any }) }
            else if (any === "billing") return { view: () => m(ContractBilling, { projectID, contractID, any }) }
        }
    },
    "/app/projects/:projectID/contracts/:contractID": {
        onmatch: (args) => {
            // console.log("ROUTE RESOLVE:  '/app/projects/:projectID/contracts/:contractID' => ", args)
            const contractDoc = DocModel.getChild(CONTRACTS, `projects/${args.projectID}/contracts/${args.contractID}`)
            if (contractDoc) {
                if (contractDoc.docData.status === "plan") {
                    m.route.set("/app/projects/:projectID/contracts/:contractID/plan", { projectID: args.projectID, contractID: args.contractID })
                } else {
                    m.route.set("/app/projects/:projectID/contracts/:contractID/actual", { projectID: args.projectID, contractID: args.contractID })
                }
            } else {
                if (args.actual == true) {
                    m.route.set("/app/projects/:projectID/contracts/:contractID/actual", { projectID: args.projectID, contractID: args.contractID })
                } else if (args.plan == true) {
                    m.route.set("/app/projects/:projectID/contracts/:contractID/plan", { projectID: args.projectID, contractID: args.contractID })
                } else if (args.billing == true) {
                    m.route.set("/app/projects/:projectID/contracts/:contractID/billing", { projectID: args.projectID, contractID: args.contractID })
                } else {
                    m.route.set("/app/projects/:projectID/contracts/:contractID/actual", { projectID: args.projectID, contractID: args.contractID })
                }
            }
        }
    },
    "/app/projects/:projectID/contracts/:contractID/sections": {
        onmatch: (args) => {
            if (args.actual == true) {
                m.route.set("/app/projects/:projectID/contracts/:contractID/actual", { projectID: args.projectID, contractID: args.contractID })
            } else if (args.plan == true) {
                m.route.set("/app/projects/:projectID/contracts/:contractID/plan", { projectID: args.projectID, contractID: args.contractID })
            } else {
                m.route.set("/app/projects/:projectID/contracts/:contractID/actual", { projectID: args.projectID, contractID: args.contractID })
            }
        }
    },

    // __SECTION__:
    '/app/projects/:projectID/contracts/:contractID/sections/:sectionID/replace': {
        onmatch: ({ projectID, contractID, sectionID }) => {
            return { view: () => m(SectionPage, { projectID, contractID, sectionID }) }
        }
    },
    '/app/projects/:projectID/contracts/:contractID/sections/:sectionID': SectionPage,
    "/projects/:projectID/contracts/:contractID/sections/:sectionID": { onmatch: ({ projectID, contractID, sectionID }) => m.route.set(`/app/projects/${projectID}/contracts/${contractID}/sections/${sectionID}`) },
    // '/app/projects/:projectID/contracts/:contractID/sections/:sectionID': AdditionSection,
    // '/app/projects/:projectID/contracts/:contractID/sections/:sectionID': SectionPage,
    // "/projects/:projectID/contracts/:contractID/sections/:sectionID": { onmatch: ({ projectID, contractID, sectionID }) => m.route.set(`/app/projects/${projectID}/contracts/${contractID}/sections/${sectionID}`) },

    //TODO: resolve route start with admin : "/admin/:any": onmatch=>check if user is admin... then navToPage

    // __TASKS__:
    "/app/tasks": Tasks,

    // __REPORTS__:
    "/app/reports": SplashReports,
    "/app/reports/vendors": VendorsReports,
    "/app/reports/projects": ProjectsReports,
    "/app/reports/projects/:projectID": {
        onmatch: (args) => {
            switch (args.report) {
                case "contractsStatus":
                    return ProjectAccountsReport
                case "contractsConfirms":
                    return ProjectConfirmsReport
                default: //contractsStatus
                    return SplashReports
            }
        }
    },
    "/app/reports/vendors/:vendorID": {
        onmatch: (args) => {
            switch (args.report) {
                case "contractsActuals":
                    return VendorActualsReport
                default: //contractsStatus
                    return VendorAccountsReport
            }
        }
    },
    "/app/reports/new": ReportBuilder,

    // __SETTINGS__:
    "/settings": Settings,
    "/settings/fieldSwitchOptions": SystemFields,
    "/settings/budgetChapters": BudgetChapters,
    "/settings/budgetChapters/:chapterID": BudgetChapter,
    "/settings/system/budgetChapters": { onmatch: () => m.route.set("/settings/budgetChapters") },
    "/settings/companies": Companies,
    "/settings/companies/:companyID": Company,
    "/settings/users": Users,
    "/settings/system/:collectionID/:docID": {
        onmatch: ({ collectionID, docID }) => {
            // console.log(`TESTME: resolve to this route: /settings/${collectionID}/${docID}`);
            m.route.set(`/settings/${collectionID}/${docID}`)
        }
    },

    // __USERS__:
    "/admin/users": Users,
    "/app/users/:userID": User,
    "/settings/users/:userID": User,

    // __VENDORS__:
    "/app/vendors": Vendors,
    "/app/vendors/:vendorID": Vendor,
    "/app/vendors/:vendorID/contacts": Vendor,
    "/app/vendors/:vendorID/contacts/:contactID": VendorContact,

    // __COMPANIES__:
    "/admin/companies": Companies,
    "/admin/companies/:companyID": Company,

    // __CONTRACT ADMIN__:
    "/admin/projects/:projectID/contracts/:contractID": ContractAdmin,
    "/admin/projects/:projectID/contracts/:contractID/:any": ContractAdmin,


    // __TOOLS__:
    "/utils": Tools,
    "/utils/tools": Tools,
    "/utils/tools/indexCalcultor": IndexedCalculatorPage,
    "/utils/tools/importCSV": ImportCSV,
    "/utils/tools/calculator": CalculatorPage,

    // "/contracts": { onmatch: (args) => m.route.set("/app/projects/:projectID/contracts", { projectID: store[PROJECTS].current.docID || m.route.param("projectID") }) },
    // "/app/contracts": { onmatch: (args) => m.route.set("/app/projects/:projectID/contracts", { projectID: store[PROJECTS].current.docID || m.route.param("projectID") }) },
    // "/contracts/new": { onmatch: (args) => m.route.set("/app/projects/:projectID/contracts/new", { projectID: store[PROJECTS].current.docID }) },
    // "/app/contracts/new": { onmatch: (args) => m.route.set("/app/projects/:projectID/contracts/new", { projectID: store[PROJECTS].current.docID }) },
    // "/contracts/:contractID": { onmatch: (args) => m.route.set("/app/projects/:projectID/contracts/:contractID", { projectID: store[PROJECTS].current.docID, contractID: args.contractID }) },
    // "/app/contracts/:contractID": { onmatch: (args) => m.route.set("/app/projects/:projectID/contracts/:contractID", { projectID: store[PROJECTS].current.docID, contractID: args.contractID }) },
    // // OVERVIEW:
    // // NEW:
    // "/app/projects/:projectID/contracts/new": { view: () => m(View, { isNew: true, modelID: CONTRACTS }) },
    // // VIEW:
    // "/app/projects/:projectID/contracts/:contractID": { view: (vnode) => m(View, { modelID: CONTRACTS, docID: vnode.attrs.contractID }) },

    // "/app/accounts": { view: () => m(OverviewPage, { modelID: AccountsM }) },
    // "/app/payments": { view: () => m(OverviewPage, { modelID: Payments }) },

    // // // VIEW / NEW / LIST
    // "/app/projects/list": { view: () => m(NestedForm, { modelID: PROJECTS }) },

    // "/app/vendors/new": { view: () => m(View, { isNew: true, modelID: VendorsM }) },
    // "/app/vendors/:id": { view: () => m(View, { modelID: Vendors }) },

    // "/app/contracts/list": { view: () => m(NestedForm, { modelID: CONTRACTS, listModel: { projectRef: ProjectsM, vendorAssignRef: VendorsM } }) },

    // // //DETAILS
    // "/app/projects/:projectID/contracts/:constractID/sections": { view: () => m(NestedForm, { modelID: ContractSections, listModel: { measurmentType: { data: ["כמותי", "פאושלי"] } } }) },
    // "/app/projects/:projectID/contracts/:contractID/sections/list": { view: () => m(NestedForm, { modelID: ContractSections, listModel: { measurmentType: { data: ["כמותי", "פאושלי"] } } }) },
    // "/app/projects/:projectID/contracts/:contractID/sections/:id": { onmatch: (args) => m.route.set(`/app/projects/${args.projectID}/contracts/${args.contractID}/sections`, { sectionID: args.id }) },

    // //DEV
    "/test/budgetField": { view: () => m(PageLayout, m(BudgetInput)) },
    '/styleGuide': StyleGuide,
    '/dev/channels': ChannelsListenters,
    '/dev/testPrint': pagePrinter,
    '/dev/embed': pageEmbedSheet,
    // "/test/progressStage": { view: () => m(PageLayout, m(ProgressBlock)) },
    // "/test/checkList": { view: () => m(PageLayout, m(CheckListBlock)) },
    // "/test/chat": { view: () => m(PageLayout, m(ChatBlock)) },
    // "/test/sidebar": { view: () => m(PageLayout, { ignore: ["sidebar"] }, m(SubSidebar)) },
    // "/test/confirmStatus": { view: () => m(PageLayout, m(ConfirmBlock)) },
    // "/test/loader": { view: () => m(InlineLoaderDots) },
    // "/test/sort": Sort
});

