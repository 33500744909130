import m from 'mithril';
import { InlineLoaderDots } from '../inlineLoaderDots/InlineLoaderDots';

export const Snackbar = node => {
    return {
        oninit: vnode => {
            setTimeout(() => {
                vnode.attrs.parent.state.snackbar = false
                m.redraw()
            }, node.attrs.time || 3500);
        },
        onbeforeremove: vnode => {
            vnode.dom.classList.remove("fadein")
            vnode.dom.classList.add("fadeout")
            return new Promise(resolve => vnode.dom.addEventListener("animationend", resolve()))
        },
        view: vnode => {
            const { isError = false, loading = false, msg } = vnode.attrs.snackbar
            return (
                m(`.snackbar.fadein ${isError ? "snackbar--error" : ""}`,
                    m(`span.snackbar__text`, msg),
                    loading && m(InlineLoaderDots, { class: "white" })
                )
            )
        }
    }
}