import m from "mithril"
import { DocModel } from "../../../data/store/docModel"
import { logRouteChangeUser } from "../../../data/utils/utils"
import { Card } from "../../commons/cardLayout/Card"
import { FullPageLayout } from "../../commons/pageLayout/FullPage"
import { FormEditDoc } from "../../commons/prompt/FormEditDoc"

export const ApartmentPage = node => {

    return {
        oninit: vnode => {
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get())
            logRouteChangeUser("checkIn");
            try {
                const apartmentRef = `projects/${vnode.attrs.projectID}/apartments/${vnode.attrs.apartmentID}`
                const apartment = DocModel.getDoc(apartmentRef)
                if (!apartment) throw "apartment not found"
                vnode.state.apartment = apartment
                const building = apartment.getBuilding()
                if (!building) throw "building not found"
                vnode.state.building = building
            } catch (err) {
                console.error(err);
                m.route.set(`/app/projects/${vnode.attrs.projectID}`)
                m.redraw()
            }
        },
        // onbeforeremove: vnode => {
        // return logRouteChangeUser("checkOut");
        // },
        onremove: vnode => {
            console.log("remove '/projects'")
            return logRouteChangeUser("checkOut");
        },
        view: vnode => {
            const { apartment, building } = vnode.state
            return m(FullPageLayout, { title: `דירה ${apartment.docData.title}` },
                m(Card,
                    m(FormEditDoc, { doc: apartment, headers: ["title", "description", "contractsRefs", "apartmentModel"] }),
                )
            )
        }
    }
}