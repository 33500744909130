import * as jsUtils from '../../utils/js'
import m from 'mithril';

import { SUM, COUNT, AVERAGE } from '../../utils/constants/mathFunctions'
import { dataStore } from '../store/dataStore';
import { getDictionaryUI, settingsStore } from '../store/settingsStore';
import { projectTypes } from '../../utils/constants/projectTypes';
import { getPathArgs } from './getPathOptions';
import { isSuperAdmin } from './permission';
import { tools } from '../../views/pages/splashTools/Tools';
import { FormEditDoc } from '../../views/commons/prompt/FormEditDoc';
import { DocModel } from '../store/docModel';
import { PROJECTS, CONTRACTS, VIEW_USERS } from '../dictionary/routeNames';
import { REG_URL_FIRST_PART, REG_URL_QUERY_PART } from '../../utils/constants/regex';
import { getDisplayValue } from './inputValidation';
import { auth, db } from '../../index';
import { buildInsertDoc } from '../CRUD/utils';
import { CURRENCY, DATE, NUMBER, PERCENT, USER } from '../../utils/constants/types';


export const logRouteChangeUser = async (type = "checkIn", path) => {
    try {
        if (auth.currentUser) {
            let routePath = path || sessionStorage.getItem('lastURL');
            routePath = routePath.replace(/[\/\?=]/g, "_").replace("/replace", "");
            let displayUser = getDisplayValue(auth.currentUser.uid, USER)
            if (displayUser === "---" || displayUser === undefined) displayUser = auth.currentUser.email
            const doc = db.doc(`${VIEW_USERS}/${auth.currentUser.uid}_${routePath}`)
            await doc.set(buildInsertDoc({
                path: routePath,
                title: displayUser,
                type
            }), { merge: true })
        }
    } catch (err) {
        console.error(err);
    } finally {
        m.redraw()
    }
}

export const getAddOptions = (colRef, filterDataOptions = {}, addDataOptions = {}) => {
    const options = {}
    options.colRef = colRef
    if (filterDataOptions.include) {
        Object.entries(filterDataOptions.include).forEach(([key, val]) => {
            options[key] = val
        })
    }
    Object.entries(addDataOptions).forEach(([key, val]) => {
        options[key] = val
    })
    return options
}

export function resolveDelay(milliseconsds = 2000) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, milliseconsds);
    });
}
export async function findRepeat(callFunc, condition, TIMES = 3, MS = 1000) {
    let timesToFind = 0
    while (timesToFind <= TIMES && !condition) {
        await Promise.resolve(setTimeout(() => { }, MS))
        callFunc()
        timesToFind++
    }
}

export const testRouteReplace = (_path) => {
    let replaced = m.route.get()
    let routePath = m.route.get().replace(REG_URL_FIRST_PART, "").replace(REG_URL_QUERY_PART, "")
    const suffixOptions = ["/plan", "/actual", "/billing", "/replace"]
    suffixOptions.forEach(suffix => {
        routePath = routePath.replace(suffix, "")
    })
    const parts1 = routePath.split("/")
    const parts2 = _path.split("/")
    if (parts1.length !== parts2.length) return { isSame: false }
    for (let i = 0; i < parts1.length; i++) {
        const isCollection = i % 2 === 0
        if (isCollection) {
            if (parts1[i] !== parts2[i]) return { isSame: false }
        } else {
            const collectionPart = parts1[i - 1]
            const newDocPart = parts2[i]
            const regStr = `${collectionPart}\\/\\w+`
            const colDocRegex = new RegExp(regStr)
            replaced = replaced.replace(colDocRegex, `${collectionPart}/${newDocPart}`)
        }
    }
    return { isSame: true, replaced }
}

export const arrangeBreadCrumbs = () => {
    let pathChain = []
    const { projects, contracts, vendors, vendorContacts, users } = dataStore;

    let path = m.route.get()
    const prefixes = ["/app", "/admin", "/utils", "/settings"]
    prefixes.forEach(prefix => {
        if (path.startsWith(prefix)) path = path.substring(prefix.length + 1)
    })

    const pathSplit = path.replace(/\?.*$/, "").split("/");
    const args = getPathArgs(path)


    if (pathSplit[0] == "projects") { // [projects]
        const { projectType } = args
        if (projectType) {
            const currentType = projectTypes.find(p => p.id === projectType)
            if (currentType) {
                pathChain.push({
                    label: `פרוייקטים:${currentType.text}`,
                    // list: projects.data.filter(p => p.docData.projectType === currentType.id),
                    path: `/app/projects?projectType=${currentType.id}`
                })
            }
        }

        // [projects , ...]
        if (pathSplit.length > 1) {
            const projectID = pathSplit[1]
            const project = DocModel.getChild(PROJECTS, `projects/${projectID}`)
            // const project = [].concat(projects.data, projects.new).find(doc => doc.docData.docID == projectID)
            if (project) {
                // [projects , :projectID]
                if (!projectType) {
                    const currentType = projectTypes.find(p => p.id === project.docData.projectType)
                    if (currentType) {
                        pathChain.push({
                            label: `פרוייקטים:${currentType.text}`,
                            // list: projects.data.filter(p => p.docData.projectType === currentType.id),
                            path: `/app/projects?projectType=${currentType.id}`
                        })
                    }
                }

                pathChain.push({
                    label: project.docData.title,
                    // path: `/app/projects/${projectID}`,
                    list: DocModel.getChildren(PROJECTS, { projectType: project.docData.projectType })
                })

                // [projects , :projectID , contracts , :contractID]
                if (pathSplit.length >= 4 && pathSplit[2] == "contracts") {
                    const projectContracts = project.getChildren(CONTRACTS)
                    //THINK: maby add base route and if found add title info etc ex: פרוייקטים.projectID=פרוייקט||projectTitle
                    if (projectContracts.length > 0) {
                        pathChain.push({ label: "חוזים", path: `/app/projects/${projectID}` })
                        const contractID = pathSplit[3]
                        const contract = projectContracts.find(doc => doc.docData.docID === contractID)
                        if (contract) {
                            pathChain.push({
                                label: contract.docData.title,
                                list: projectContracts
                                // path: `/app/projects/${projectID}/contracts/${contractID}/${contract.docData.status === 'plan' ? "plan" : "actual"}`,
                            })

                            // [projects , :projectID , contracts , :contractID , plan]
                            if (pathSplit.length == 5 && pathSplit[4] == "plan") {
                                pathChain.push({ label: "בהקמה", path: `/app/projects/${projectID}/contracts/${contractID}/plan` })

                                // [projects , :projectID , contracts , :contractID , actual]
                            } else if (pathSplit.length == 5 && pathSplit[4] == "actual") {
                                pathChain.push({ label: "ביצוע", path: `/app/projects/${projectID}/contracts/${contractID}/actual` })
                                // [projects , :projectID , contracts , :contractID , actual]
                            } else if (pathSplit.length == 5 && pathSplit[4] == "billing") {
                                pathChain.push({ label: "הנהח", path: `/app/projects/${projectID}/contracts/${contractID}/billing` })
                            }
                        } else {
                            console.error("cannot find contractID ", contractID, " on data store")
                        }
                    }
                }
            }
        }
    } else if (pathSplit[0] === "vendors") {
        pathChain.push({ label: "קבלנים", path: `/app/vendors` })
        if (pathSplit.length > 1) {
            const vendorID = pathSplit[1]
            const vendor = [].concat(vendors.data, vendors.new).find(doc => doc.docData.docID == vendorID)
            if (vendor) {
                // [vendors , :vendorID]
                pathChain.push({ label: vendor.docData.title, path: `/app/vendors/${vendorID}` })
                const contacts = [].concat(vendorContacts.data, vendorContacts.new)
                if (contacts.length > 0) {
                    pathChain.push({ label: "אנשי קשר", path: `/app/vendors/${vendorID}/contacts`, list: contacts })
                }
                if (pathSplit.length === 4 && pathSplit[2] == "contacts") {
                    // [vendors , :vendorID , contacts ,contactID ]
                    const contactID = pathSplit[3]
                    const contact = contacts.find(doc => doc.docData.docID == contactID)
                    if (contact) {
                        pathChain.push({ label: contact.docData.title, path: `/app/vendors/${vendorID}/contacts/${contactID}` })
                    }
                }
            }
        }
    } else if (pathSplit[0] === "users") {
        pathChain.push({ label: "משתמשים", path: `/admin/users` })
        if (pathSplit.length > 1) {
            const userID = pathSplit[1]
            const user = [].concat(users.data, users.new).find(doc => doc.docData.docID == userID)
            if (user) {
                // [users , :userID]
                pathChain.push({ label: user.docData.displayName, path: `/app/users/${userID}` })
            }
        }
    } else if (pathSplit[0] === "tools") {
        pathChain.push({ label: "כלים", path: `/utils/tools` })
        if (pathSplit.length > 1) {
            const toolID = pathSplit[1]
            const tool = tools.find(el => el.path === `/utils/tools/${toolID}`)
            if (tool) {
                const { text: label, path } = tool
                pathChain.push({ label, path })
            }
        }
    }
    settingsStore.pathBreadCrumbs = [...pathChain];
    m.redraw()
}

export const setHeaders = (defaultHeaders, headersKey, vnode) => {
    // console.log(defaultHeaders, window.innerWidth);
    if (window.innerWidth < 1000 && window.innerWidth >= 700) {
        vnode.state[headersKey] = defaultHeaders.filter((v, i) => i < 4)
    } else if (window.innerWidth < 700) {
        vnode.state[headersKey] = defaultHeaders.filter((v, i) => i < 2)
    } else {
        vnode.state[headersKey] = defaultHeaders
    }
}

export const copyAction = (e, node, doc, addDataOptions = {}, actionsDict) => {
    try {
        e.target.disabled = true
        let newDoc
        if (doc.toJSON) newDoc = doc.duplicate(addDataOptions)
        else newDoc = doc.copy(addDataOptions)

        node.state.snackbar = { msg: actionsDict.do.msg || "משכפל נתונים... עלול לקחת מעט זמן" }
        node.state.prompt = false
        if (node.state.settings && node.state.settings.navOnAdd && newDoc) {
            m.route.set(`/app/${newDoc.docData.ref}`)
        }
    } catch (err) {
        node.state.prompt = false
        node.state.snackbar = { msg: err }
    }
}

export const handleCopy = (e, node, doc, routeID) => {
    e.stopPropagation();
    const { title, msg, actions: actionsDict } = getDictionaryUI(routeID, "copy")
    node.state.prompt = {
        title: title,
        msg: msg.replace("${title}", doc.docData.title),
        actions: [
            {
                key: "do",
                text: actionsDict.do.text,
                action: e => {
                    copyAction(e, node, doc, {}, actionsDict)
                }
            },
            {
                key: "undo",
                color: "gray",
                text: actionsDict.undo.text,
                action: e => {
                    node.state.prompt = false
                }
            }
        ],
    }
}

export const handleAdd = (e, node, DocBuilder, routeID, options = {}) => {
    e.stopPropagation();
    const { title, msg, actions: actionsDict } = getDictionaryUI(routeID, "add")
    const { settings = {} } = node.state

    node.state.prompt = {
        title,
        msg,
        actions: [
            {
                key: "do",
                text: actionsDict.do.text,
                action: e => {
                    try {
                        const data = Object.assign({}, options)
                        if (options.parent) {
                            delete data.parent
                            data.colRef = `${options.parent.docData.ref}/${DocBuilder.meta.id}`
                        }
                        const newDoc = new DocBuilder(data);
                        node.state.snackbar = { msg: actionsDict.do.msg }
                        node.state.prompt = false
                        if (settings.navOnAdd || options.navOnAdd) {
                            m.route.set(`/app/${newDoc.docData.ref}`)
                        } else if (settings.openEditDocForm || options.openEditDocForm) {
                            node.state.prompt = {
                                title: "עריכה",
                                class: "full-width",
                                form: () => {
                                    return m(FormEditDoc, { doc: newDoc, headers: node.state.settings.openEditDocForm.formHeaders, parent: node })
                                }
                            }
                        }
                    } catch (err) {
                        console.error(err)
                        node.state.prompt = false
                        node.state.snackbar = { msg: err || err.message, isError: true }
                    }
                }
            },
            {
                key: "undo",
                color: "gray",
                text: actionsDict.undo.text,
                action: e => {
                    node.state.prompt = false
                }
            }
        ],
    }
}

export const handleDelete = (e, node, doc, modelID) => {
    e.stopPropagation();
    const { title, msg, actions: actionsDict } = getDictionaryUI(modelID, "delete")
    node.state.prompt = {
        title,
        msg: msg.replace("${title}", doc.docData.title),
        actions: [
            {
                //DO
                key: "do",
                text: actionsDict.do.text || "מחק",
                color: "red",
                action: e => {
                    doc.remove()
                    node.state.snackbar = { msg: actionsDict.do.msg }
                    node.state.prompt = false
                },
            },
            {
                //UNDO
                key: "undo",
                color: "gray",
                text: actionsDict.undo.text || "ביטול",
                action: e => node.state.prompt = false
            }
        ]
    }
}


export const detectNonAdminUser = () => {
    if (!isSuperAdmin()) {
        alert("only admin can login to this route")
        m.route.set("/app")
    }
}

export const toggleTimeout = (triggerBool, action, timeoutms = 2000, ...args) => {
    if (triggerBool) {
        return setTimeout(() => {
            action(...args)
            m.redraw()
        }, timeoutms);
    } else {
        return action(...args)
    }
}

export const pathToRoutes = (_path) => {
    let path = _path
    if (_path.startsWith("/")) path = _path.substring(1)
    const parts = path.split("/");
    if (parts.length === 1) {
        return [{ collectionID: parts[0], type: "collection" }]
    } else {
        const result = []
        for (let i = 0; i < parts.length; i++) {
            const isCollection = i % 2 === 0
            let type, where = []
            if (isCollection) {
                if (!parts[i + 1]) {
                    const collectionID = parts[i]
                    where = result.filter(({ type }) => type === "doc").map(({ collectionID, docID, level }) => { return { collectionID, docID, level } })
                    if (i > 1) type = "collectionGroup"
                    else type = "collection" // top level routes
                    result.push({ collectionID, type, where, level: parseInt(Number(i + 1) / 2) })
                }
            } else {
                const collectionID = parts[i - 1]
                const docID = parts[i]
                where = result.filter(({ type }) => type === "doc").map(({ collectionID, docID, level }) => { return { collectionID, docID, level } })
                if (docID.startsWith(":")) {
                    if (i > 1) type = "collectionGroup"
                    else type = "collection" // top level routes
                } else type = "doc"
                result.push({ collectionID, type, where, docID, level: Number(i + 1) / 2 })
            }
        }
        return result
    }
}
export const urlToRoutes = () => {
    let path = m.route.get()
    if (path.startsWith("/")) path = path.substring(1)
    let store = ""
    Object.entries(settingsStore.stores).forEach(([prefix, { text }]) => {
        if (path.startsWith(prefix)) {
            store = { prefix, text }
            path = path.substring(prefix.length + 1)
        }
    })

    const parts = path.replace(/\?.*$/, "").split("/");
    const args = getPathArgs(path)
    if (parts.length === 1) {
        return [{ collectionID: parts[0], type: "collection", args, store }]
    } else {
        const result = []
        let ref = ""
        for (let i = 0; i < parts.length; i++) {
            const isCollection = i % 2 === 0
            if (isCollection) {
                //skip
            } else {
                const collectionID = parts[i - 1]
                const docID = parts[i]
                if (i > 0) ref += "/"
                ref += `${collectionID}/${docID}`
                const docRef = String(ref)
                const partSettings = { collectionID, type: "doc", docID, docRef, args }
                if (i === 1) partSettings.store = store
                result.push(partSettings)
            }
        }
        return result
    }
}


export const getModelParents = (modelID) => {
    return jsUtils.findObjParents(settingsStore.dbTree, modelID)
}
export const getModelChildrens = (parentModelID) => {
    const directChildren = []
    const findDirectChildren = (obj, key) => {
        for (const [prop, subObj] of Object.entries(obj)) {
            if (prop === key) return directChildren.push(...Object.keys(subObj))
            findDirectChildren(subObj, key)
        }
    }
    findDirectChildren(settingsStore.dbTree, parentModelID)
    return directChildren
}

export const getTypeOfResource = (path) => {
    const split = path.split('/');
    const type = split.length % 2 === 0 ? 'DOC' : 'COL'  // -> 'DOC' | 'COL'
    // console.log(split, split.length, type)
    return type;
}

export const getModelID = (ref) => {
    let _ref = ref
    if (_ref.startsWith("/")) _ref = _ref.substring(1)
    const split = _ref.split("/");
    if (split.length === 1) {
        return _ref
    } else {
        if (split.length % 2 === 0) {
            return split[split.length - 2]
        } else {
            return split[split.length - 1]
        }
    }
}
export const getDocIDbyModelID = (modelID, path) => {
    const parts = path.split("/");
    let result
    if (parts.length === 1) {
        throw 'must have at least two parts in path to get the docID'
    } else {
        parts.forEach((part, ind) => {
            if (part === modelID) {
                if (parts[ind + 1]) {
                    result = parts[ind + 1]
                }
            }
        })
    }
    return result
}


export const getResourceID = (path) => {
    const type = getTypeOfResource(path.replace(/\/app\//, ""))
    let parsed;
    let resource = 'notFound';
    if (type === 'DOC') {
        parsed = /\/(\w+)(\/\w+)?$/g.exec(path);   // -> (projects)/projectID , (vendors)/vendorID , OR vendors/vendorID/(contacts)/contactID
        if (parsed) resource = parsed[1];
    } else if (type === 'COL') {
        parsed = /\/(\w+)$/g.exec(path);   // -> (projects) , (vendors) , OR vendors/vendorID/(contacts)
        if (parsed) resource = parsed[1];
    }
    return resource;
}

export const getResourceKey = (_path) => {
    const val = Array.isArray(_path) ? _path[0] : _path
    const path = val.startsWith("/") ? val.substring(1) : val
    const type = getTypeOfResource(path)
    const split = path.split("/")
    if (type === "DOC") return split[split.length - 2]
    else return split[split.length - 1]
}

// /**
//  * @param {InputEvent} e onInput event that fires oninput on form element
//  * @param {HTMLAllCollection} inputs form element on which function is running
//  * @param {m.Vnode} vnode Vnode component which use this event
//  * @param {any[]} settingCalc {target:"targetField",expression:"f1 * f2",trigger:"fieldName"}
//  */
// export const calcInputs = (e, inputs, vnode, model) => {
//     e.redraw = false;
//     const input = e.target;
//     const isValid = validateInput(model, input);
//     if (!isValid) input.value = input.oldVal;

//     const statement = jsUtils.triggerStatement(model.meta.logic, input);
//     if (statement) {
//         const result = jsUtils.calcFieldRes(statement, inputs);
//         vnode.state.calcMsg = `recalculate ${statement.target} ℹ : \n ${statement.expression} = \n ${result} `
//         inputs[statement.target].value = result;
//         // alert(vnode.state.calcMsg);
//     }
// }


export const initParent = (settings, elemID, parentData) => {
    let value;
    if (settings) {
        const parentField = settings.parentField;
        const parentID = elemID.replace(/\/[^\/]+\/[^\/]+$/, '');
        const filter = parentData.filter(el => el.id === parentID);
        if (filter[0] !== undefined) {
            const parent = filter[0];
            value = parent[parentField];
        }
    }
    return value;
}

export const calcTotal = (header, logic, data) => {
    // console.log(`TODO: calc total for ${header} -> ${logic} - on \n${JSON.stringify(data)}`)
    let result = 0;
    let count = 0;
    switch (logic) {
        case SUM:
            data.forEach(doc => {
                if (header in doc) {
                    result += Number(doc[header])
                }
            });
            // result = formatNumber(result)
            break;
        case COUNT:
            data.forEach(doc => result += 1)
            break;
        case AVERAGE:
            data.forEach(doc => {
                if (header in doc) {
                    result += Number(doc[header]);
                    count++;
                }
            });
            result = result / count;
            break;
    }
    return result;
}

const mapFormats = {
    [DATE]: "s1",
    [PERCENT]: "s2",
    [NUMBER]: "s3",
}

export function buildSheetXMLTable(title, headers, data) {
    let XML = `
        <?xml version="1.0"?>
        <ss:Workbook 
            xmlns:x="urn:schemas-microsoft-com:office:excel" 
            xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" 
            xmlns:html="https://www.w3.org/TR/html401/"
        >
        <ss:Styles>
            <ss:Style ss:ID="0">
                <ss:Font ss:FontName="Verdana" />
            </ss:Style>
            <ss:Style ss:ID="1">
                <ss:Font ss:Bold="1"/>
            </ss:Style>
            ${getXMLstyles()}
        </ss:Styles>
    `;
    XML += `<ss:Worksheet ss:Name="${title}">
            <ss:Table>`;

    //add columns
    Object.keys(headers).forEach((header, ind) => {
        XML += `
        <Column ss:Index="${Number(ind) + 1}" ss:AutoFitWidth="1" ss:Width="200"/>
        `
    })


    //headers
    XML += `<ss:Row ss:StyleID="1">`
    Object.entries(headers).forEach(([header, field]) => {
        const label = field.label || header
        XML += `
            <ss:Cell>
                <ss:Data ss:Type="String">${label}</ss:Data>
            </ss:Cell>
        `
    })
    XML += `</ss:Row>`//END headers

    //rows
    data.forEach(doc => {
        XML += `<ss:Row>` //START row
        Object.entries(headers).forEach(([header, field]) => {
            const value = doc.docData[header] || ""
            const displayValue = getDisplayValue(doc.docData[header], field.type, field.options, doc.docData)
            let type = "String" //TODO: number and date type switch
            // if (field.type === DATE) type = "Date"
            // else if (field.type === NUMBER || field.type === CURRENCY) type = "Number"
            // else if (field.type === PERCENT) type = "Percent"
            const styleID = mapFormats[field.type] || "0" //TODO: format + styling  => ss:StyleID="${styleID}"
            XML += `
            <ss:Cell ss:StyleID="${styleID}">
                <ss:Data ss:Type="${type}">${value}</ss:Data>
            </ss:Cell>
            `
        })
        XML += `</ss:Row>`//END row
    })
    XML += `</ss:Table>
        </ss:Worksheet>`
    XML += "</ss:Workbook>";
    return XML
}

export function buildSheetXML(title, headers, data, children = {}) {
    let XML = `
        <?xml version="1.0"?>
        <ss:Workbook 
            xmlns:x="urn:schemas-microsoft-com:office:excel" 
            xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" 
            xmlns:html="https://www.w3.org/TR/html401/"
        >
        <ss:Styles>
            <ss:Style ss:ID="1">
                <ss:Font ss:Bold="1"/>
            </ss:Style>
            ${getXMLstyles()}
        </ss:Styles>
    `;
    //main sheet
    XML += `
        <ss:Worksheet ss:Name="${title}">
            <ss:Table>
                ${Object.keys(headers).map((header, ind) => {
        return `<Column ss:Index="${ind}" ss:AutoFitWidth="0" ss:Width="150"/>`
    }).join("\n")}
                <ss:Row ss:StyleID="1">
                    ${Object.keys(data).map(header => {
        const headerField = headers[header]
        return `
                            <ss:Cell>
                                <ss:Data ss:Type="String">${headerField ? headerField.label : header}</ss:Data>
                            </ss:Cell>`}).join("\n")}
                </ss:Row>
                <ss:Row>
                    <!--map over rows:-->
                    ${Object.keys(data).map(header => `
                    <ss:Cell>
                        <ss:Data ss:Type="String">${data[header]}</ss:Data>
                    </ss:Cell>`).join("\n")}
                </ss:Row>
            </ss:Table>
        </ss:Worksheet>
        `;
    //other sheets
    Object.entries(children).forEach(([childKey, childArray]) => {
        if (childKey && childArray && childArray.length > 0) {
            XML += `
                    <ss:Worksheet ss:Name="${childKey}">
                    <ss:Table>
                        <ss:Row ss:StyleID="1">
                        <!--map over headers:-->
                        ${Object.keys(childArray[0]).map(header => {
                const headerField = dataStore[childKey].model.headers[header]
                return `
                            <ss:Cell>
                                <ss:Data ss:Type="String">${headerField ? headerField.label : header}</ss:Data>
                            </ss:Cell>`})
                    .join("\n")
                }
                        </ss:Row>
                        ${childArray.map(doc => {
                    return `
                            <ss:Row>
                                ${Object.keys(doc).map(docHeader => {
                        const value = doc[docHeader]
                        return `
                                    <ss:Cell>
                                        <ss:Data ss:Type="String">${value || ""}</ss:Data>
                                    </ss:Cell>`}).join("\n")}
                                })}
                            </ss:Row>
                            `
                }).join("\n")}
                    </ss:Table>
                </ss:Worksheet>`;
        }
    });
    XML += "</ss:Workbook>";
    return XML
}

function getXMLstyles() {
    return `
        <Style ss:ID="s1">
            <NumberFormat ss:Format="d/m/yy"/>
            <Font ss:FontName="Verdana"/>
        </Style>
        <Style ss:ID="s2">
            <NumberFormat ss:Format="d/m/yy h:mm AM/PM"/>
            <Font ss:FontName="Verdana"/>
        </Style>
        <Style ss:ID="s3">
        <NumberFormat ss:Format="_(&quot;$&quot;* #,##0.00_);_(&quot;$&quot;* (#,##0.00);_(&quot;$&quot;* &quot;-&quot;??_);_(@_)"/>
            <Alignment ss:Horizontal="Right" ss:Vertical="Bottom"/>
            <Font ss:FontName="Verdana"/>
        </Style>
    `
}

// <?xml version="1.0" encoding="UTF-8"?>
// <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
// xmlns:x="urn:schemas-microsoft-com:office:excel"
// xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
// xmlns:html="http://www.w3.org/TR/REC-html40">
// <Worksheet ss:Name="Sheet1">
// <Table>
// <Column ss:Index="1" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="2" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="3" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="4" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="5" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="6" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="7" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="8" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="9" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="10" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="11" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="12" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="13" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="14" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="15" ss:AutoFitWidth="0" ss:Width="150"/>
// <Column ss:Index="16" ss:AutoFitWidth="0" ss:Width="150"/>
// <Row>
// <Cell ss:StyleID="s26"><Data ss:Type="String">id</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">created_time</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">ad_id</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">ad_name</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">adset_id</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">adset_name</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">campaign_id</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">campaign_name</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">form_id</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">form_name</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">is_organic</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">platform</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">email</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">full_name</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">city</Data></Cell>
// <Cell ss:StyleID="s26"><Data ss:Type="String">phone_number</Data></Cell>
// </Row>
// </Table>
// </Worksheet>
// </Workbook>


