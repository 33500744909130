import m from "mithril"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { PERCENT } from "../../../utils/constants/types"

export const PercentClock = node => {
    return {
        view: vnode => {
            const { fill = "none", stroke = "#ccc", value = 0, size = "md" } = vnode.attrs
            return m(".percent",
                m("svg", { viewBox: "0 0 36 36", class: `${size}` },
                    m("path", {
                        d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",
                        fill: "none",
                        stroke: "#80808040",
                        "stroke-width": "2.5",
                    }),
                    m("path", {
                        d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",
                        fill,
                        stroke,
                        "stroke-width": "3",
                        "stroke-dasharray": (value >= 0) ? `${value}, 100` : "0,100",
                    }),
                ),
                m(".percent__value", { "data-over": value < 0 || value > 100 },
                    getDisplayValue(value, PERCENT)
                ),
            )
        }
    }
}

