import m from "mithril"
import { Card } from "../../commons/cardLayout/Card";
import { PageLayout } from "../../commons/pageLayout/Page";
import { Calculator } from "./Calculator";


export const CalculatorPage = node => {

    return {
        result:0,
        view: vnode => {
            return m(PageLayout,
                m(Card, { class: "calculatorCard" },
                    m(Calculator)
                )
            )
        }
    }
}