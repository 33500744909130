export const USERS = "users"
export const USERS_PERMISSION = "usersPermission"
export const COMPANIES = "companies"
export const VENDORS = "vendors"
export const VENDOR_CONTACTS = "vendorContacts"
export const FILES = "files"
export const ODD_JOBS = "oddJobs"
export const NOTIFICATION = "notification"
export const VIEW_USERS = "viewUsers"


export const PROJECTS = "projects"
export const PROJECT = "project"

export const BUILDINGS = "buildings"
export const FLOORS = "floors"
export const APARTMENT_MODELS = "apartmentModels"
export const APARTMENTS = "apartments"
export const CONTRACTS = "contracts"

export const CONTRACT_SECTIONS = "sections"
export const CONTRACT_ADDITIONS = "additions"
export const CONTRACT_SUBTRACTIONS = "subtractions"

export const CONTRACT_ACCOUNTS = "accounts"
export const ACTUALS = "actuals"
export const CONFIRMS = "confirms"
export const PAYMENTS = "payments"
// export const CONTRACT_BILLINGS = "billings"
export const CONTRACT_COMMENTS = "contractComments"

export const WORKSPACES = "workspaces"
export const GROUPS = "groups"
export const SECTION_MILESTONES = "milestones"

export const BUDGET_CHAPTERS = "budgetChapters"
export const FIELDS_SWITCH_OPTIONS = "fieldSwitchOptions"
export const BUDGET_ITEMS = "budgetItem"


export const SETTINGS = "settings"
