import m from 'mithril';
import { Icon } from '../../components/icon/Icon';
import { Card } from '../cardLayout/Card';
import { PageLayout } from '../pageLayout/Page';

export const ICONS_LIST = [
    "icon-export", "icon-print", "icon-account_tree", "icon-pivot_table", "icon-columns", "icon-reload", "icon-exit", "icon-alert", "icon-bell", "icon-contracts", "icon-bug", "icon-dots-vertical", "icon-dots-horizontal", "icon-eye-off", "icon-eye", "icon-stopwatch", "icon-close", "icon-x", "icon-check", "icon-edit", "icon-save", "icon-delete", "icon-search", "icon-filter", "icon-filter-open", "icon-filter-close", "icon-settings", "icon-payments", "icon-contacts", "icon-vendors", "icon-profile", "icon-home", "icon-accounts", "icon-download", "icon-attachment", "icon-reports", "icon-chat", "icon-plus-circle", "icon-cog", "icon-link", "icon-mail", "icon-phone", "icon-plus", "icon-minus", "icon-plus-square", "icon-tools", "icon-pin", "icon-send", "icon-send", "icon-merge", "icon-projects", "icon-publicSpace", "icon-entrepreneurship", "icon-residential", "icon-right", "icon-left", "icon-copy", "icon-folder-open", "icon-folder-close", "icon-enlarge", "icon-arrow-thin-up", "icon-arrow-thin-down", "icon-arrow-thin-left", "icon-arrow-thin-right", "icon-circle-down", "icon-circle-left", "icon-circle-right", "icon-circle-up", "icon-triangle-down", "icon-triangle-left", "icon-triangle-right", "icon-triangle-up", "icon-arrow-left", "icon-arrow-right", "icon-arrow-down", "icon-arrow-up"
]

export const StyleGuide = (node) => {

    return {
        view: (vnoder) => {
            return m(PageLayout,
                m('a.link', { href: 'http://getbem.com/naming/', target: '_blank' }, m('div', 'We style using BEM')),
                m(Card, { class: "iconsBlock" },
                    m("a.caption link", { href: "#", target: "_blank" }, "my icons"),
                    m(".icons",
                        ICONS_LIST.map(icon => {
                            return m(".icons__icon",
                                m(".id", icon),
                                m(Icon, { icon }),
                            )
                        })
                    )
                )
            )
        }
    }
}
