import m from "mithril"
import { Icon } from "../../components/icon/Icon"
import { userRoles } from "../../../utils/constants/userRoles"
import { DATE_RANGE } from "../../../utils/constants/filtersTypes"
import { CheckCell } from "../checkCell/CheckCell"
import { Prompt } from "../prompt/Prompt"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { FilterForm } from "../../pages/reports/FilterForm"
import { isSuperAdmin } from "../../../data/utils/permission"



export const FilterTable = node => {

    const toggleSearchMode = () => {
        if (node.state.localSearchTerm.trim().length === 0) {
            node.state.searchMode = !node.state.searchMode
        }
    }
    const toggleShowFilters = () => node.state.showFilters = !node.state.showFilters

    const toggleShowFilterRow = (filterID) => {
        const index = node.state.showFilterRow.indexOf(filterID)
        if (index > -1) node.state.showFilterRow.splice(index, 1)
        else node.state.showFilterRow.push(filterID)
    }

    //DATE FILTER
    const getFilterDate = (id, logic) => {
        const find = node.attrs.filters.find(filter => filter.id === id && filter.logic === logic)
        return find ? find.param : ""
    }

    const toggleFilterDate = (id, logic, param) => {
        const filterIndex = node.attrs.filters.findIndex(filter => filter.id === id && filter.logic === logic)
        if (filterIndex > -1) {
            // exist=> update value
            node.attrs.filters[filterIndex] = { id, param, logic, type: DATE_RANGE }
        } else {
            //not exist=> push
            node.attrs.filters.push({ id, param, logic, type: DATE_RANGE })
        }
    }

    //BUTTON FILTER
    const isButtonFilterd = (id, param) => {
        return node.attrs.filters.find(filter => filter.id === id && filter.param === param)
    }

    const toggleFilterItem = (id, param) => {
        const filterIndex = node.attrs.filters.findIndex(filter => filter.id === id && filter.param === param)
        if (filterIndex > -1) {
            node.attrs.filters.splice(filterIndex, 1)
        } else {
            node.attrs.filters.push({ id, param })
        }
    }

    const removeFilterItems = (id, options) => {
        options.forEach(opt => {
            const findIndex = node.attrs.filters.findIndex(filter => filter.id === id && filter.param === opt.id)
            if (findIndex > -1) {
                node.attrs.filters.splice(findIndex, 1)
            }
        })
    }
    const addFilterItems = (id, options) => {
        options.forEach(opt => {
            const find = node.attrs.filters.find(filter => filter.id === id && filter.param === opt.id)
            if (!find) {
                node.attrs.filters.push({ id, param: opt.id })
            }
        })
    }

    const toggleCheckAll = (id, options) => {
        if (node.state.localCheck[id]) {
            addFilterItems(id, options)
            node.state.localCheck[id] = false
        } else {
            removeFilterItems(id, options)
            node.state.localCheck[id] = true
        }
    }

    const countActiveFilters = () => node.attrs.filters.filter(({ param }) => param !== "" && param !== null && param !== undefined).length
    const hasActiveFilters = () => node.attrs.filters.length > 0 && countActiveFilters() > 0



    const isActiveSearch = () => node.state.searchMode && node.state.localSearchTerm.trim().length > 0

    return {
        showFilters: false,
        searchMode: false,
        snackbar: false,
        prompt: false,
        showFilterRow: [],
        localCheck: {},
        localInputs: {},
        localSearchTerm: node.attrs.searchTerm,
        view: vnode => {
            return m(".filters",
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Icon, {
                    icon: `icon-filter-${vnode.state.showFilters ? "open" : "close"}`,
                    class: vnode.state.showFilters ? "icon--active icon--lg" : "icon--lg",
                    action: e => toggleShowFilters()
                }),
                hasActiveFilters() && m(".notification__count--gray static", countActiveFilters()),
                m(`.filters__search ${isActiveSearch() ? "filters__search--active" : ""}`,
                    vnode.state.searchMode && m("input.input__field", {
                        oncreate: el => el.dom.focus(),
                        value: vnode.attrs.searchTerm,
                        oninput: e => {
                            vnode.state.localSearchTerm = e.target.value
                            vnode.attrs.updateSearchTerm(e.target.value)
                        },
                    }),
                    m(Icon, { icon: `icon-search`, class: "icon--lg", action: e => toggleSearchMode() }),
                ),
                vnode.state.showFilters && m(".filters__rows",
                    (!vnode.attrs.filterMap || vnode.attrs.filterMap.lenth === 0) ?
                        m(".filters__row empty", "לא נבחרו פילטרים לטבלה זו , פנה למנהל המערכת בכדי ליצור")
                        :
                        vnode.attrs.filterMap.map(filter => {
                            if (filter.type && filter.type === DATE_RANGE) {
                                const options = filter.options || [
                                    { id: `${filter.header}:after`, text: `מ`, logic: `after` },
                                    { id: `${filter.header}:before`, text: "עד", logic: "before" }
                                ]
                                return m(".filters__row",
                                    m(".filters__caption", {
                                        onclick: e => toggleShowFilterRow(filter.header),
                                        class: vnode.state.showFilterRow.includes(filter.header) ? "filters__caption--open" : ""
                                    },
                                        m(Icon, { icon: `icon-arrow-thin-${vnode.state.showFilterRow.includes(filter.header) ? "down" : "left"}` }),
                                        filter.label
                                    ),
                                    vnode.state.showFilterRow.includes(filter.header) && m(".filters__buttons",
                                        options.map(opt => {
                                            return m(".filters__input input",
                                                m("input.input__field[type=date]", {
                                                    oncreate: el => {
                                                        const value = getFilterDate(filter.header, opt.logic)
                                                        el.dom.value = value
                                                        vnode.state.localInputs[opt.id] = value
                                                    },
                                                    oninput: e => {
                                                        vnode.state.localInputs[opt.id] = e.target.value
                                                        toggleFilterDate(filter.header, opt.logic, e.target.value)
                                                    }
                                                }),
                                                m("label.input__label", opt.text)
                                            )
                                        })
                                    ),
                                )
                            } else {
                                return m(".filters__row",
                                    m(".filters__caption", {
                                        onclick: e => toggleShowFilterRow(filter.header),
                                        class: vnode.state.showFilterRow.includes(filter.header) ? "filters__caption--open" : ""
                                    },
                                        m(Icon, { icon: `icon-arrow-thin-${vnode.state.showFilterRow.includes(filter.header) ? "down" : "left"}` }),
                                        filter.label
                                    ),
                                    vnode.state.showFilterRow.includes(filter.header) && m(".filters__buttons",
                                        filter.options.map(opt => m(".filters__button", {
                                            class: isButtonFilterd(filter.header, opt.id) ? "" : "filters__button--active",
                                            onclick: e => toggleFilterItem(filter.header, opt.id)
                                        }, opt.text))
                                    ),
                                    vnode.state.showFilterRow.includes(filter.header) && m(".filters__toggleButtons", "הכל",
                                        m(CheckCell, { checked: vnode.state.localCheck[filter.header], setCheck: e => toggleCheckAll(filter.header, filter.options) }),
                                    )
                                )
                            }
                        }),
                    (node.attrs.modelID && isSuperAdmin()) && m("button.button button--text", {
                        onclick: e => {
                            vnode.state.prompt = {
                                title: "הוספת פילטר",
                                class: "full-width",
                                form: () => m(FilterForm, { modelID: node.attrs.modelID, parent: node }),
                            }
                        }
                    }, "+ הוסף פילטר")
                )
            )
        }
    }
}