import m from "mithril"
import { FullPageLayout } from "../commons/pageLayout/FullPage"

export const pageEmbedSheet = node => {
    const back = () => {
        const lastURL = sessionStorage.getItem("lastURL");
        if (lastURL !== null) m.route.set(lastURL)
        else m.route.set("/app")
    }

    return {
        url: "https://docs.google.com/spreadsheets/d/1rMHxb76frLwn-r873_8tUTEwhf4z3oPXa8yhTJ7f_uc/htmlembed?headers=true&widget=true",
        view: vnode => {
            const { url } = vnode.state
            return m(FullPageLayout, { back },
                m(".",
                    m("iframe#embed", {
                        onclick: e => e.stopPropagation(),
                        name: "embed",
                        title: "תצוגה מקדימה",
                        src: url,
                        width: "1050",
                        height: "800",
                        align: "center",
                        allow: "fullscreen",
                        scrolling: "yes",
                        frameborder: "0",
                        oncreate: el => {
                            const styleTag = document.createElement("style");
                            frames['embed'].document.head.appendChild(styleTag);
                            styleTag.setAttribute("type", "text/css");
                            const css = document.createTextNode(`.grid-container:{direction:ltr !important}`)
                            styleTag.appendChild(css)
                        }
                    },
                    )
                )
            )
        }
    }
}