import { NOTIFICATION, USERS, VIEW_USERS } from "../../dictionary/routeNames";
import { STRING, DATE, REF, VALID_COMPARE, SWITCH, LIST } from "../../../utils/constants/types";
import { DocModel } from "../docModel";
import { taskStatsM } from "../../models/app/taskState";
import { auth } from "../../../index";
import { UserDoc } from "../users/userClass";
import { dateAfter, dateBefore, sortDocsBy } from "../../../utils/js";
import { dataStore } from "../dataStore";



export class ViewUserDoc extends DocModel {
    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ViewUserDoc,
        });
    };

    static get meta() {
        return {
            id: VIEW_USERS,
            routes: {
                collection: `/${VIEW_USERS}`,
                doc: `/${VIEW_USERS}/:viewID`,
            },
        }
    }


    static get headers() {
        return {
            title: { label: 'כותרת', defaultValue: "", type: STRING },
            fromTime: { label: 'מ', defaultValue: "", type: DATE },
            toTime: { label: 'עד', defaultValue: "", type: DATE },
            path: { label: 'נתיב', defaultValue: "", type: STRING },
            user: {
                label: 'משתמש',
                type: LIST,
                defaultValue: () => `users/${auth.currentUser.uid}`,
                options: {
                    data: dataStore[USERS].data.sort(sortDocsBy("displayName", { type: STRING })),
                    load: () => dataStore[USERS].listen(),
                    model: UserDoc,
                    formHeaders: ["title", "displayName"]
                }
            },
        }
    }
}