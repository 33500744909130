import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Card } from '../../../commons/cardLayout/Card';

//DATA
import { VENDORS, VENDOR_CONTACTS, FILES, PROJECTS, CONTRACTS } from '../../../../data/dictionary/routeNames';
import { VendorDoc } from '../../../../data/store/vendors/vendorClass';
import { dataStore } from '../../../../data/store/dataStore';

// FUNCTIONS
import { Prompt } from '../../../commons/prompt/Prompt';
import { Snackbar } from '../../../components/snackbar/Snackbar';
import { InputCell } from '../../../commons/inputCell/InputCell';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { isSystemMode } from '../../../../data/utils/viewUtils';
import { getDisplayValue } from '../../../../data/utils/inputValidation';
import { settingsStore } from '../../../../data/store/settingsStore';
import { isUserAllow } from '../../../../data/utils/permission';
import { UPDATE } from '../../../../data/store/permissionStore';
import { DisplayCell } from '../../../commons/inputCell/DisplayCell';
import { TableFiles } from '../../../commons/fileUpload/TableFiles';
import { ContractDoc } from '../../../../data/store/contracts/contractClass';
import { LIST } from '../../../../utils/constants/types';
import { ProjectDoc } from '../../../../data/store/projects/projectClass';
import { LocalList } from '../../../commons/inputCell/LocalList';
import { Table } from '../../../commons/table/Table';
import { NavClass } from '../../../../data/store/NavClass';
import { Nav } from '../../../commons/nav/Nav';
import { Icon } from '../../../components/icon/Icon';

export const Vendor = node => {

    const selectProject = {
        // model: ProjectDoc,
        docData: {
            project: ""
        },
        headers: {
            project: {
                label: "בחר פרוייקט",
                type: LIST,
                options: {
                    data: dataStore[PROJECTS].data,
                    load: () => dataStore[PROJECTS].listen(),
                    model: ProjectDoc,
                    formHeaders: ["title"],
                    change: ref => {
                        if (!dataStore[CONTRACTS].isListen[ref]) {
                            const { unsubscribe, alreadyListen } = dataStore[CONTRACTS].listen(ref)
                        }
                    },
                },
                data: []
            }
        }
    }

    const findVendor = () => {
        const data = [].concat(dataStore[VENDORS].data, dataStore[VENDORS].new)
        return data.find(doc => doc.docData.docID === node.attrs.vendorID)
    }

    const confirmChanges = () => {
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: "האם לשמור את השינויים שבוצעו בקבלן",
            action: e => {
                node.state.snackbar = { msg: "שומר..." };
                node.state.vendor.save();
                node.state.prompt = false
                node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                // m.redraw()
            },
            actionText: "שמור"
        }
    }

    const isRemoved = () => node.state.vendor && node.state.vendor.isRemoved


    const headers = [
        "title",
        "commercialName",
        "companyNumber",
        // "companyType",
        "contractorSAPNumber",
        "phone",
        "email",
        "address",
        "taxesEndDate",
        "taxPercent",
        "description",
        "status",
    ]
    const contactHeaders = [
        "title",
        "phone",
        "email",
        "role",
        "status",
    ]
    const navDetailsOptions = {
        CONTACTS: "contacts",
        FILES: "files",
        CONTRACTS: "contracts",
        REPORTS: "reports",
    }
    const navDetailsView = new NavClass({
        nav: [
            { label: "אנשי קשר", key: navDetailsOptions.CONTACTS },
            { label: "צרופות", key: navDetailsOptions.FILES },
            { label: "חוזים", key: navDetailsOptions.CONTRACTS },
            // { label: "דוחות", key: navDetailsOptions.REPORTS }
        ],
        defaultActiveKey: navDetailsOptions.CONTACTS
    })

    const previewReport = (reportID) => {
        const { vendor } = node.state
        return m.route.set(`/app/reports/vendors/${vendor.docData.docID}?report=${reportID}`)
    }

    return {
        load: true,
        prompt: false,
        snackbar: false,
        searchTerm: "",
        filters: [],
        settings: { navOnAdd: false },
        contactHeaders: [...contactHeaders],
        contractsRefHeaders: ["title"],
        oninit: vnode => {
            navDetailsView.setNode(vnode)
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            sessionStorage.setItem('lastURL', m.route.get());

            const { alreadyListen: isListenToVendors, unsubscribe: unsubscribeVendors } = dataStore[VENDORS].listen();
            if (isListenToVendors) {
                vnode.state.load = false
                vnode.state.vendor = findVendor();
                if (!vnode.state.vendor) m.route.set("/app/vendors");
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען..." }
                setTimeout(() => {
                    vnode.state.vendor = findVendor();
                    if (!vnode.state.vendor) m.route.set("/app/vendors");
                    vnode.state.load = false;
                    m.redraw()
                }, 3000)
            }
        },
        view: vnode => {
            return (
                m(PageLayout, { class: "vendor", title: "פרטי קבלן" },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    m(Card, { class: "formCard vendor__details" },
                        m("h3.caption formCard__caption", "פרטי הקבלן"),
                        vnode.state.load && m(InlineLoaderDots),
                        (vnode.state.vendor && vnode.state.vendor.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                        vnode.state.vendor && headers.map((header, index) => {
                            const docData = vnode.state.vendor.docData
                            const value = docData[header]
                            const field = VendorDoc.headers[header]
                            if (isUserAllow(VENDORS, UPDATE)) {
                                return m(InputCell, {
                                    header, value, field, index, id: `vendor__${header}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.vendor,
                                    parent: vnode
                                })
                            } else {
                                return m(DisplayCell, { header, field, value, doc: vnode.state.vendor })
                            }
                        }),
                        vnode.state.vendor && vnode.state.vendor.hasChanges() && m(".formCard__actions",
                            m("button.button formCard__save", { onclick: e => confirmChanges() }, "שמור שינויים"),
                            m("button.button formCard__abort", { onclick: e => vnode.state.vendor.abortChanges() }, "בטל שינויים"),
                        )
                    ),
                    (!isRemoved() && vnode.state.vendor) && m(Nav, { nav: navDetailsView.nav }),

                    (vnode.state.vendor && !isRemoved() && !vnode.state.vendor.isNew && navDetailsView.isActiveState(navDetailsOptions.FILES)) && m(TableFiles, {
                        colRef: `${vnode.state.vendor.docData.ref}/${FILES}`,
                        title: "צרופות קבלן",
                        doc: vnode.state.vendor,
                        modelID: FILES,
                        tableHeaders: ["title", "description", "createdAt", "createdBy"]
                    }),
                    (vnode.state.vendor && !isRemoved() && !vnode.state.vendor.isNew && navDetailsView.isActiveState(navDetailsOptions.CONTACTS)) && m(Table, {
                        title: "אנשי קשר",
                        modelID: VENDOR_CONTACTS,
                        colRef: `${vnode.state.vendor.docData.ref}/${VENDOR_CONTACTS}`,
                        doc: vnode.state.vendor,
                        tableHeaders: contactHeaders,
                        filters: vnode.state.filters,
                    }),
                    (vnode.state.vendor && !isRemoved() && !vnode.state.vendor.isNew && navDetailsView.isActiveState(navDetailsOptions.CONTRACTS)) && m(Card, { class: "tableCard" },
                        m("h3.caption tableCard__caption", "חוזים"),
                        m(LocalList, { doc: selectProject, header: "project", parent: vnode, key: "selectProject" }),
                        vnode.state.vendor && dataStore[CONTRACTS].data
                            .filter(doc => doc.docData.contractorRef === vnode.state.vendor.docData.ref && doc.docData.colRef === `${vnode.state.selectProject}/${CONTRACTS}`)
                            .map(doc => {
                                return m(".table__row", {
                                    style: `grid-template-columns: repeat(${vnode.state.contractsRefHeaders.length},1fr);`,
                                    onclick: e => m.route.set(`/app/${doc.docData.ref}`)
                                },
                                    vnode.state.contractsRefHeaders.map(h => m(".table__cell", getDisplayValue(doc.docData[h], ContractDoc.headers[h].type, ContractDoc.headers[h].options))),
                                )
                            })
                    ),
                    // (vnode.state.vendor && !isRemoved() && !vnode.state.vendor.isNew && navDetailsView.isActiveState(navDetailsOptions.REPORTS)) && m(Card, { class: "tableCard" },
                        // m("h3.caption tableCard__caption", "דוחות מערכת"),
                        // m("button.button", { onclick: e => previewReport("contractsStatus") }, "הצג דוח סטטוס חוזים", m(Icon, { icon: "icon-eye" })),
                        // m("button.button", { onclick: e => previewReport("contractsActuals") }, "הצג דוח פירוט ביצוע", m(Icon, { icon: "icon-eye" }))
                    // )
                )
            )
        }
    }
}