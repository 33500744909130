//DATA
import { O_ARRAY, O_OBJECT, O_STRING } from "../../utils/constants/objTypes";

//FUNCTIONS
import { objType, uuid } from "../../utils/js";

export class NavClass {
    constructor({ nav = [], node, settings = {} } = {}) {
        if (!nav || nav.length < 2) throw "invalid nav property , must include at least to parts [key,key] | [{key,label},{key,label}]"
        this.nav = []
        const { defaultActiveKey, isToggle = false, isMultiple, stateKey } = settings
        this.isToggle = isToggle
        this.isMultiple = isMultiple || false
        if (defaultActiveKey && objType(defaultActiveKey) === O_ARRAY && isMultiple === undefined) {
            this.isMultiple = true
        }
        if (node) this.node = node
        this.stateKey = stateKey || uuid()

        nav.forEach((part, ind) => {
            const objPart = {}
            if (objType(part) === O_OBJECT) {
                const { label, key, active, icon, color, action } = part
                objPart.key = key || label || uuid()
                objPart.label = label || objPart.key
                Array.from([{ icon }, { color }, { action }]).forEach(prop => {
                    const [[key, val]] = Object.entries(prop)
                    if (val !== undefined) objPart[key] = val
                })
                if (active === true) {
                    objPart.active = true
                } else if (active === false) {
                    objPart.active = false
                } else if (defaultActiveKey && NavClass.isValidKeyTypeToSet(defaultActiveKey)) {
                    //nothing to do yet... do it after finish build all nav parts
                } else {
                    objPart.active = false
                }
            } else if (objType(part) === O_STRING && part.trim() !== "") {
                objPart.key = part
                objPart.label = part
            } else {
                throw "inavlid part property, must be: {} | String"
            }
            if (ind === 0) objPart.active = true
            if (!objPart.action) {
                objPart.action = () => {
                    this.setActiveKey(objPart.key)
                }
            }
            this.nav.push(objPart)
        })
        if (defaultActiveKey) this.setActiveKey(defaultActiveKey)
        if (node) {
            const activeKey = this.getActiveKey()
            if (activeKey) this.setActiveState(activeKey)
        }
    }



    static isValidKeyTypeToSet(keyToSet) {
        if ((objType(keyToSet) === O_STRING && keyToSet.trim() !== "") || objType(keyToSet) === O_ARRAY) return true
        else throw 'invalid keyToSet... must be : string | []'
    }

    updateNavElem(elemKey, part, value) {
        const findNavElem = this.nav.find(el => el.key === elemKey)
        if (findNavElem) findNavElem[part] = value
    }

    setNode(node, stateKey) {
        if (node && node.state) {
            this.node = node
            if (stateKey) this.stateKey = stateKey
            const activeKey = this.getActiveKey()
            this.setActiveState(activeKey)
        }
    }

    /**
     * @returns String[] | String | undefined
     */
    getActiveKey() {
        if (this.isMultiple) {
            return this.nav.filter(part => part.active).map(part => part.key)
        } else {
            return this.nav.filter(part => part.active)[0].key
        }
    }


    isValidKeyToSet(keyToSet) {
        if (objType(keyToSet) === O_STRING && keyToSet.trim() !== "") {
            return this.nav.some(part => part.key === keyToSet)
        } else if (objType(keyToSet) === O_ARRAY) {
            return keyToSet.some(_keyToSet => this.nav.some(part => part.key === _keyToSet))
        }
        else throw 'invalid keyToSet... must be : string | []'
    }

    isActiveState(key) {
        return this.node.state[this.stateKey] === key
    }

    setActiveState(keyToSet) {
        if (this.node) {
            if (this.isMultiple) {
                let _keyToSet = []
                if (objType(keyToSet) === O_ARRAY) {
                    _keyToSet = keyToSet
                } else if (objType(keyToSet) === O_STRING) {
                    _keyToSet.push(keyToSet)
                } else {
                    throw `invalid keyToSet type ${keyToSet}`
                }
                this.node.state[this.stateKey] = _keyToSet
            } else {
                if (objType(keyToSet) === O_STRING) {
                    this.node.state[this.stateKey] = keyToSet
                } else {
                    throw `invalid keyToSet type ${keyToSet}`
                }
            }
        }
    }

    setActiveKey(keyToSet) {
        function setActive(part, keyToSet) {
            if (part.key === keyToSet) part.active = true
            else part.active = false
        }
        if (this.isValidKeyToSet(keyToSet)) {
            if (objType(keyToSet) === O_STRING || (objType(keyToSet) === O_ARRAY && !this.isMultiple)) {
                let _keyToSet = objType(keyToSet) === O_ARRAY ? keyToSet[0] : keyToSet
                if (this.node) this.node.state[this.stateKey] = keyToSet
                this.nav.forEach(part => setActive(part, _keyToSet))
            } else if (objType(keyToSet) === O_ARRAY) {
                keyToSet.forEach(_keyToSet => {
                    if (objType(_keyToSet === O_STRING)) {
                        const indexToSet = this.nav.findIndex(({ key }) => key === _keyToSet)
                        if (indexToSet > -1) this.nav[indexToSet].active = true
                    } else {
                        throw "invalid key to set"
                    }
                })
            }
        } else {
            throw "invalid keyToSet"
        }

        // console.log("nav after setActiveKey: ", this.nav)
        // if (this.node) {
        //     console.log("node after setActiveKey: ", this.node.state[this.stateKey])
        // }
    }
}