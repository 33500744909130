import m from "mithril"
import { auth, db } from "../../../index";
import { DocModel } from "../docModel";

import { SWITCH, DATE, STRING, EMAIL, FILE, VALID_UNIQUE } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, PATTERN, AUTOCOMPLETE } from "../../../utils/constants/inputAttrs";
import { USERS } from "../../dictionary/routeNames";
import { REG_PHONE_PATTERN, REG_EMAIL_PATTERN } from "../../../utils/constants/regex";
import { userRoles, USER_ROLES } from "../../../utils/constants/userRoles";

import { dataStore } from "../dataStore";
import { internalFields } from "../../dictionary/internalFields";
import { arrangeBreadCrumbs } from "../../utils/utils";
import { buildDocData } from "../../utils/buildNewDoc";


var TIMES_TO_CHECK = 3
var times = 1;
var timesToGetUser = 1
export class UserDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: UserDoc,
        });
    };

    static getCurrentUser() {
        if (dataStore[USERS].current) return dataStore[USERS].current
        return dataStore[USERS].data.find(user => user.docData.docID === auth.currentUser.uid)
    };

    static getUserByID(uid, param) {
        const user = dataStore[USERS].data.find(user => user.docData.docID === uid)
        if (user) {
            if (param) return user.docData[param]
            else return user
        } else {
            console.error(`user ${uid} not found!`)
            return ""
        }
    };

    static async setCurrentUser(forceReplace = false) {
        const curr = UserDoc.getCurrentUser()
        if (curr && !forceReplace) return curr
        if (auth.currentUser == null) {
            setTimeout(() => {
                if (times > TIMES_TO_CHECK) return
                UserDoc.setCurrentUser()
                times++
            }, 1000);
        } else {
            try {
                const user = await db.doc(`users/${auth.currentUser.uid}`).get()
                if (user.exists) {
                    // const userDoc = new UserDoc(user.data())
                    // userDoc.remove()
                    const userDoc = { docData: buildDocData(user) }
                    dataStore[USERS].current = userDoc;
                    // console.log(dataStore[USERS].current)
                } else {
                    throw "user not found"
                }
            } catch (err) {
                console.error(err)
            } finally {
                m.redraw()
            }
        }
        times = 0
        return dataStore[USERS].current
    };

    updatePassword(newPassword) {
        if (newPassword.lenth > 6 && auth.currentUser !== null && auth.currentUser.uid === this.docData.docID) {
            auth.currentUser.updatePassword(newPassword)
                .then(() => {
                    console.log("updatePassword success!")
                })
                .catch((err) => {
                    console.error("updatePassword fail! ", err)
                })
        }
    }
    resetPassword() {
        if (auth.currentUser !== null && (auth.currentUser.uid === this.docData.docID || auth.currentUser.uid === 'BRjKVmVajjg62VO3K3aJGxmmccq1')) {
            auth.sendPasswordResetEmail(this.docData.email)
                .then(() => {
                    console.log("resetPassword success!")
                })
                .catch((err) => {
                    console.error("resetPassword fail! ", err)
                })
        }
    }

    save() {
        if (this.hasChanges()) {
            const path = `users/${this.docData.docID}`
            console.time(`SAVE ⏲ ${path}`)
            if (this.isNew) throw 'doc is new , use insert instead!'
            const dataToSave = Object.assign({},
                this.docChanges,
                {
                    lastUpdate: new Date().toISOString(),
                    lastUpdatedBy: firebase.auth().currentUser.uid
                })
            let email
            if (email in dataToSave) {
                email = dataToSave.email
                delete dataToSave.email
            }
            Object.entries(internalFields).forEach(([_k, _v]) => delete dataToSave[_v])
            Object.keys(dataToSave).forEach(key => {
                if (key.endsWith("__old")) delete dataToSave[key]
            })
            db.doc(`/users/${this.docData.docID}`).update(dataToSave)
                .then(res => {
                    if (email && auth.currentUser !== null && auth.currentUser.uid === this.docData.docID) {
                        auth.currentUser.updateEmail(email)
                    }
                    console.log('update success!')
                    arrangeBreadCrumbs()
                    this.docChanges = {}
                })
                .catch(err => { console.error(err) })
                .finally(() => {
                    console.timeEnd(`SAVE ⏲ ${path}`)
                    m.redraw()
                })
        }
    }
    insert(password, forceInsert = false) {
        if (!this.isNew && forceInsert !== true) throw "cannot add document that already exist"
        const colPath = "users"
        console.time(`INSERT ⏲ ${colPath}`)
        const dataToAdd = Object.assign({},
            this.docData,
            {
                // password, //THINK:
                isActive: true,
                createdAt: new Date().toISOString(),
                createdBy: auth.currentUser.uid
            })
        const lastUser = auth.currentUser
        Object.entries(internalFields).forEach(([_k, _v]) => delete dataToAdd[_v])
        auth.createUserWithEmailAndPassword(this.docData.email, password)
            .then(cred => cred.user.uid)
            .then(uid => {
                db.doc(`users/${uid}`).set(dataToAdd, { merge: true })
                    .then(res => {
                        this.docChanges = {}
                        auth.updateCurrentUser(lastUser).then(() => m.redraw())
                        this.remove() // beacuse its craeted new user via listener with its unique id
                        arrangeBreadCrumbs();
                        console.log('add user success!')
                    })
                    .catch(err => { throw err })
                    .finally(() => {
                        console.timeEnd(`INSERT ⏲ ${colPath}`)
                        m.redraw()
                    })
            })
    }

    static get meta() {
        return {
            id: USERS,
            routes: {
                collection: "/users",
                doc: "/users/:userID",
            },
            logic: [
                { type: VALID_UNIQUE, expression: "displayName", trigger: ["displayName"], msg: "ערך כבר קיים , שדה זה הינו ייחודי!" },
                { type: VALID_UNIQUE, expression: "email", trigger: ["email"], msg: "משתמש עם אימייל זה כבר קיים , שדה זה הינו ייחודי!" },
            ],
        }
    }
    static get headers() {
        return {
            title: { label: "שם מלא", defaultValue: "", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }, { [AUTOCOMPLETE]: "off" }] },
            displayName: { label: "שם קצר", defaultValue: "--משתמש חדש--", permission: { allowRoles: [USER_ROLES.SYSTEM] }, type: STRING, props: [{ [REQUIRED]: true, [MINLENGTH]: 4 }, { [AUTOCOMPLETE]: "off" }] },
            email: { label: "אימייל", type: EMAIL, permission: { allowRoles: [USER_ROLES.SYSTEM] }, props: [{ [REQUIRED]: true }, { [PATTERN]: REG_EMAIL_PATTERN }, { [AUTOCOMPLETE]: "off" }] },
            phone: { label: 'טלפון', type: STRING, defaultValue: "", props: [{ [PATTERN]: REG_PHONE_PATTERN }] },
            dob: { label: 'תאריך לידה', type: DATE, defaultValue: "" },
            address: { label: 'כתובת', type: STRING, defaultValue: "--ללא כתובת--", },
            photoURL: { label: "תמונה", type: FILE, defaultValue: "/img/noUser.jpg" },
            role: { label: 'תפקיד', defaultValue: "projectManager", type: SWITCH, options: userRoles, permission: { allowRoles: [USER_ROLES.SYSTEM, USER_ROLES.BUDGETER, USER_ROLES.COMPANY_ENGINEER] } },
            status: { label: 'סטטוס', defaultValue: "active", type: SWITCH, permission: { allowRoles: [USER_ROLES.SYSTEM, USER_ROLES.BUDGETER, USER_ROLES.COMPANY_ENGINEER] }, options: [{ text: 'פעיל', id: 'active' }, { text: 'לא פעיל', id: 'nonActive' }] },
        }
    }
}