import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Card } from '../../../commons/cardLayout/Card';

//DATA
import { COMPANIES } from '../../../../data/dictionary/routeNames';
import { dataStore } from '../../../../data/store/dataStore';
import { CompanyDoc } from '../../../../data/store/companies/companyClass';

// FUNCTIONS
import { Prompt } from '../../../commons/prompt/Prompt';
import { InputCell } from '../../../commons/inputCell/InputCell';
import { Snackbar } from '../../../components/snackbar/Snackbar';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { isSystemMode } from '../../../../data/utils/viewUtils';
import { settingsStore } from '../../../../data/store/settingsStore';

export const Company = node => {

    const findCompany = () => {
        const data = [].concat(dataStore[COMPANIES].data, dataStore[COMPANIES].new)
        return data.find(doc => doc.docData.docID === node.attrs.companyID)
    }

    const confirmChanges = (e) => {
        e.target.disabled = true
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: "האם לשמור את השינויים שבוצעו בחברה",
            action: async e => {
                node.state.snackbar = { msg: "שומר..." };
                await node.state.company.save();
                e.target.disabled = false
                node.state.prompt = false
                node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                // m.redraw()
            },
            actionText: "שמור"
        }
    }

    const isRemoved = () => node.state.company && node.state.company.isRemoved

    const headers = [
        "title",
        "commercialName",
        "companyNumber",
        "companyType",
        "phone",
        "email",
        "taxesEndDate",
        "address",
        "description",
        "status",
    ]


    return {
        load: true,
        prompt: false,
        snackbar: false,
        searchTerm: "",
        filters: [],
        settings: { navOnAdd: false },
        oninit: vnode => {
            const { alreadyListen: isListenToVendors, unsubscribe: unsubscribeVendors } = dataStore[COMPANIES].listen();
            if (isListenToVendors) {
                vnode.state.load = false
                vnode.state.company = findCompany();
                if (!vnode.state.company) m.route.set("/app/companies");
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען..." }
                setTimeout(() => {
                    vnode.state.company = findCompany();
                    if (!vnode.state.company) m.route.set("/app/companies");
                    vnode.state.load = false;
                    m.redraw()
                }, 3000)
            }
        },
        view: vnode => {
            return (
                m(PageLayout, { class: "company", title: "פרטי חברה" },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    m(Card, { class: "formCard company__details" },
                        m("h3.caption formCard__caption", "פרטי החברה"),
                        vnode.state.load && m(InlineLoaderDots),
                        (vnode.state.company && isRemoved()) ? "החברה נמחקה" : [
                            (vnode.state.company && vnode.state.company.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                            vnode.state.company && headers.map((header, index) => {
                                const docData = vnode.state.company.docData
                                const value = docData[header]
                                const field = CompanyDoc.headers[header]
                                return m(InputCell, {
                                    header, value, field, index, id: `company__${header}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.company,
                                    parent: vnode
                                })
                            }),
                            vnode.state.company && Object.keys(vnode.state.company.docChanges).length > 0 && m("button.button formCard__save", { onclick: e => confirmChanges(e) }, "שמור שינויים")
                        ]
                    ),
                )
            )
        }
    }
}