import { CONTRACT_COMMENTS } from "../../dictionary/routeNames";
import { CHECK_BOOL, REF, STRING } from "../../../utils/constants/types";
import { DocModel } from "../docModel";
import { booleanType } from "../../../utils/constants/statusTypes";
import { CheckCell } from "../../../views/commons/checkCell/CheckCell";



export class ContractCommentDoc extends DocModel {
    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ContractCommentDoc,
        });
    };

    static get meta() {
        return {
            id: CONTRACT_COMMENTS,
            routes: {
                collection: `/projects/:projectID/contracts/:contractID/${CONTRACT_COMMENTS}`,
                doc: `/projects/:projectID/contracts/:contractID/${CONTRACT_COMMENTS}/:commentID`,
            }
        }
    }

    static get headers() {
        return {
            title: { label: 'כותר', defaultValue: "", type: STRING },
            description: { label: 'תאור', defaultValue: "", type: STRING },
            accountRef: { label: 'קישור לחשבון', defaultValue: "", type: REF, options: { param: "month" } },
            confirmRef: { label: 'קישור לשלב אישור', defaultValue: "", type: REF },
            sectionRef: { label: 'קישור לסעיף ביצוע', defaultValue: "", type: REF },
            isPinned: { label: "נעוץ", defaultValue: false, type: CHECK_BOOL, options: booleanType },
            // isTask: { label: "משימה", defaultValue: false, type: CHECK_BOOL, options: booleanType }
        }
    }
}