import m from "mithril"
/**
 *  get hebrew date
 *
 * @copyright https://www.hebcal.com/home/219/hebrew-date-converter-rest-api
 * @param {String} dateValue The ISO string value.
 * @return get hebrew date
 */
export async function getHebDate(dateValue) {
    const d = new Date(dateValue)
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const date = d.getDate()
    const url = `https://www.hebcal.com/converter?cfg=json&gy=${year}&gm=${month}&gd=${date}&g2h=1`;
    let content = {}
    try {
        const result = await fetch(url)
        content = await result.json()
    } catch (err) {
        console.error(err);
    } finally{
        m.redraw()
        return content.hebrew
    }
}