import m from "mithril"
import { buildHeaders } from "../../../data/CRUD/utils"

import { FileDoc } from "../../../data/store/commons/fileClass"
import { MIME_TYPES } from "../../../utils/constants/mimeTypes"
import { InputCell } from "../inputCell/InputCell"


export const FilePrompt = node => {

    return {
        headers: {},
        oninit: vnode => {
            node.state.headers = buildHeaders(FileDoc.headers, node.attrs.headers)
        },
        view: vnode => {
            const { headers } = vnode.state
            const { file } = vnode.attrs
            const { contentType, size, created, url } = file.docData
            return m(".filePrompt",
                m(".filePrompt__form",
                    Object.entries(headers).map(([header, field], index) => {
                        return m(InputCell, {
                            doc: file,
                            header, field, index,
                            id: `${file.docData.ref}/${header}`,
                            parent: vnode,
                            options: { editToggle: false }
                        })
                    }),
                ),
                m(".filePrompt__preview",
                    // (url && (contentType === MIME_TYPES.EXCEL || contentType === MIME_TYPES.OLD_EXCEL)) && m("a", m(".link center", "הצג במסך מלא"), m("iframe.showFile", { onclick: e => e.stopPropagation(), title: "תצוגה מקדימה", name: "תצוגה מקדימה", src: `https://view.officeapps.live.com/op/embed.aspx?src=${url}`, width: "400", height: "500", align: "center", allow: "fullscreen", frameborder: "0" })),
                    (url && !file.isNew) && m("a.link center", { href: url, target: "_blank" }, "הצג במסך מלא"),
                    (url && contentType === "application/pdf") && m("iframe.showFile", { onclick: e => e.stopPropagation(), title: "תצוגה מקדימה", name: "תצוגה מקדימה", src: url, width: "750", height: "800", align: "center", allow: "fullscreen", frameborder: "0" }),
                    (url && contentType.startsWith("image/")) && m("img.showFile", { onclick: e => e.stopPropagation(), src: url, alt: "אין תצוגה מקדימה", width: "100%" }),
                    m("span", `size: ${size} | created: ${created}`),
                ),
                m(".formCard__actions",
                    (file.hasChanges() || file.isNew) && m("button.button", { onclick: e => file.save() }, "שמור"),
                    m("button.button button--gray", { onclick: e => node.attrs.parent.state.prompt = false }, "סגור")
                )
            )
        }
    }
}