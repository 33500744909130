import m from "mithril"
import { buildHeaders } from "../../../data/CRUD/utils"
import { getAccount } from "../../../data/reports/getContractData"
import { ContractDoc } from "../../../data/store/contracts/contractClass"
import { AccountDoc } from "../../../data/store/contractsActuals/accountClass"
import { UserDoc } from "../../../data/store/users/userClass"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { getModelID } from "../../../data/utils/utils"
import { EXPORTS_API_SCRIPT, EXPORTS_API_SHEET } from "../../../firebase/api"
import { auth, db } from "../../../index"
import { O_OBJECT } from "../../../utils/constants/objTypes"
import { CURRENCY, DATE, REF, STRING } from "../../../utils/constants/types"
import { getObjValue, objType, sortDocsEntries } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"
import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots"

export const getReportFilters = node => {
    const { vendor, project, contract } = node.state
    const result = []
    if ("vendor" in node.state) {
        result.push({
            key: "vendorFilter",
            title: vendor ? `קבלן : ${vendor.docData.title}` : "",
            path: vendor ? vendor.docData.ref : "",
        })
    }
    if ("project" in node.state) {
        result.push({
            key: "projectsFilter",
            title: project ?
                `פרוייקטים : ${project.docData.title}` :
                "פרוייקטים: הכל",
            path: project ? project.docData.ref : "",
        })
    }
    if ("contract" in node.state) {
        result.push({
            key: "contractsFilter",
            title: contract ?
                `חוזים : ${contract.docData.title}` :
                "חוזים: הכל",
            path: contract ? contract.docData.ref : "",
        })
    }
    return result
}

export const getReportExportData = (node, reportHeaders, options = {}) => {
    const _makeTotalFuncRow = headersFields => {
        return headersFields.map(field => {
            if (field.value) return field.value
            else if (objType(field) === O_OBJECT) return Object.assign({}, field)
            else return "---"
        })
    }
    const getDocDataByHeaders = (doc, docHeaders) => {
        return Object.entries(docHeaders).map(([header, field]) => {
            let value = ''
            if (field.val) value = field.val(doc)
            else if (header in doc.docData) {
                if (field.type) value = getDisplayValue(doc.docData[header], field.type, field.options, doc.docData)
                else value = doc.docData[header]
            }
            return value
        })
    }

    const {
        themeColor = "black"
    } = options

    let fileName = "דוח ייצוא"
    let totalLayout = "0"
    let tableLayout
    const reportVars = node.state.reportData.total
    reportVars.user = UserDoc.getCurrentUser().docData.displayName
    getReportFilters(node).forEach(filterItem => {
        reportVars[filterItem.key] = filterItem.title
    })
    const tables = {}
    if (node.state.selectedReport === "accounts") {
        if (node.state.vendor) fileName = `דוח חשבונות קבלן - ${node.state.vendor.docData.title}`
        else if (node.state.project) fileName = `דוח חשבונות פרוייקט - ${node.state.project.docData.title}`
        else fileName = "דוח חשבונות"
        totalLayout = "0"
        if (themeColor === "black") tableLayout = "1672756317" //black and white
        else tableLayout = "241875127" //custom for accountPayment report

        reportVars.reportName = fileName
        const headers = buildHeaders(AccountDoc.headers, reportHeaders.headers)
        const subTotalsFunc = _makeTotalFuncRow(Object.values(reportHeaders.subTotals))
        const totalsFunc = _makeTotalFuncRow(Object.values(reportHeaders.totals))
        Object.entries(node.state.reportData.projects).forEach(([projectRef, projectData]) => {
            tables[projectRef] = {
                title: getDisplayValue(projectRef, REF),
                headers: Object.values(headers).map(header => header.label),
                totalsFunc,
                groups: Object.entries(projectData.contracts)
                    // .sort{//THINK:
                    .map(([contractRef, contractData]) => {
                        const group = {}
                        group.title = contractData.title
                        group.subTotalsFunc = subTotalsFunc
                        group.items = Object.entries(contractData.accounts)
                            .sort(sortDocsEntries("period"))
                            .map(([accountRef, accountData]) => {
                                const item = []
                                Object.entries(headers).forEach(([header, field], ind) => {
                                    let value = ""
                                    if (field.type === "collection") value = (!accountData[header] || !Array.isArray(accountData[header])) ? "--" : accountData[header].map(el => Object.entries(field.fields).map(([_header, _field]) => getDisplayValue(el.docData[_header], _field.type, _field.options)).join(" | ")).join("\n")
                                    else value = accountData.docData[header]
                                    item.push(value)
                                })
                                return item
                            })
                        return group
                    })
            }
        })

        const paymentHeaders = {
            _month: {
                label: "חודש",
                val: (doc) => getAccount(node.state.reportData, doc.docData.colRef.split("/").slice(0, -1).join("/"), "month")
            },
            paymentDate: { type: DATE, label: "תאריך" },
            sum: { type: CURRENCY, label: "סכום" },
            paymentIdentity: { type: STRING, label: "מזהה שיק", options: { symbol: "\t" }, },
            description: { type: STRING, label: "הערות" },
        }

        const paymentsTable = {
            title: "כלל התשלומים",
            headers: Object.values(paymentHeaders).map(field => field.label),
            totalsFunc: ["סהכ", "", { func: "SUM" }, "", ""],
            tableLayout: themeColor === "black" ? "1878509538" : "2083999166", //black and white

            // items: node.state.reportData.payments.map(doc => {
            //     return Object.entries(paymentHeaders).map(([header, field]) => doc.docData[header] || "")
            // })
            items: node.state.reportData.payments.map(doc => getDocDataByHeaders(doc, paymentHeaders))
        }
        tables.payments = paymentsTable

    } else if (node.state.selectedReport === "confirms") {
        if (node.state.vendor) fileName = `דוח מצב אישורים קבלן - ${node.state.vendor.docData.title}`
        else if (node.state.project) fileName = `דוח מצב אישורים פרוייקט - ${node.state.project.docData.title}`
        else fileName = "דוח מצב אישורים"
        totalLayout = "849668482"
        tableLayout = "70224797"

        reportVars.reportName = fileName
        const headers = buildHeaders(ContractDoc.headers, reportHeaders.headers)
        const totalsFunc = _makeTotalFuncRow(Object.values(reportHeaders.totals))
        Object.entries(node.state.reportData.projects).forEach(([projectRef, projectData]) => {
            tables[projectRef] = {
                title: getDisplayValue(projectRef, REF),
                headers: Object.values(headers).map(header => header.label),
                totalsFunc,
                items: Object.entries(projectData.contracts)
                    // .sort{//THINK:
                    .map(([contractRef, contractData]) => {
                        const item = []
                        Object.entries(headers).forEach(([header, { val, type, options }], ind) => {
                            let value
                            if (val) {
                                const { value: calcValue } = val(contractData)
                                value = calcValue
                            } else value = getObjValue(contractData, header)
                            const displayValue = getDisplayValue(value, type, options, contractData)
                            item.push(displayValue)
                        })
                        return item
                    })
            }
        })
    }
    return {
        totalLayout,
        tableLayout,
        templeteID: EXPORTS_API_SHEET,
        title: fileName,
        data: {
            reportVars,
            tables
        }
    }
}

export const exportReportAPI = async (node, dataToExport) => {
    node.state.exportFile = null
    const preferences = {
        portrait: false, // false => landspace
        size: "A4",
        gridlines: false
    }
    const editExportPrefernces = (changes = {}) => {
        Object.entries(changes).forEach(([key, value]) => {
            const existURL = node.state.exportFile.pdf
            const matchReg = new RegExp(`(.*&${key}=)(\\w+)(.*)`);
            const newURL = existURL.replace(matchReg, `$1${value}$3`);
            node.state.exportFile.pdf = newURL;
            preferences[key] = value
        })
    }
    node.state.prompt = {
        title: "ייצוא דוח להדפסה",
        form: () => {
            return m(".",
                node.state.exportFile ?
                    m(".", { style: "display:grid;gap:1rem" },
                        m(".caption", "העדפות הדפסה"),
                        m(".buttonSwitch",
                            m(".", { class: preferences.portrait === true ? "selected" : "", onclick: e => editExportPrefernces({ portrait: true }) }, "לאורך"),
                            m(".", { class: preferences.portrait === false ? "selected" : "", onclick: e => editExportPrefernces({ portrait: false }) }, "לרוחב"),
                        ),
                        m(".buttonSwitch",
                            m(".", { class: preferences.size === "A4" ? "selected" : "", onclick: e => editExportPrefernces({ size: "A4" }) }, "A4"),
                            m(".", { class: preferences.size === "A3" ? "selected" : "", onclick: e => editExportPrefernces({ size: "A3" }) }, "A3"),
                        ),
                        m(".buttonSwitch",
                            m(".", { class: preferences.gridlines === true ? "selected" : "", onclick: e => editExportPrefernces({ gridlines: true }) }, "ללא קווי רשת"),
                            m(".", { class: preferences.gridlines === false ? "selected" : "", onclick: e => editExportPrefernces({ gridlines: false }) }, "הצג קווי רשת"),
                        ),
                        m("a.button--icon", { href: node.state.exportFile.pdf, target: "_blank" }, "הדפס דוח", m(Icon, { icon: "icon-print" })),
                        //TODO:BUG: it should be shown not printed
                        // m("iframe.showFile", { onclick: e => e.stopPropagation(), title: "תצוגה מקדימה", name: "תצוגה מקדימה", src: node.state.exportFile.pdf, width: "750", height: "800", align: "center", allow: "fullscreen", frameborder: "0" }),
                        m("hr"),
                        m("a.button--icon", { href: node.state.exportFile.xls, target: "_blank" }, "ייצא לאקסל", m(Icon, { icon: "icon-download" })),
                    )
                    :
                    m(".",
                        m(InlineLoaderDots),
                        m(".info", `מכין דוח לייצוא - עלול לקחת מעט זמן`)
                    )
            )
        }
    }
    const url = EXPORTS_API_SCRIPT
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(dataToExport)
    };
    const response = await fetch(url, requestOptions)
    const result = await response.json();
    if (result && result.status === "success" && result.data) {
        const resultURL = result.data.url
        let xls = `${resultURL}/export`
        let pdf = xls
        pdf += `?exportFormat=pdf&format=pdf`
        pdf += `&size=${preferences.size}`                               // paper size legal / letter / A4
        pdf += `&portrait=${preferences.portrait}`                      // orientation, false for landscape
        pdf += `&fitw=true`                         // fit to page width, false for actual size
        pdf += `&sheetnames=false`                  // hide sheetNames
        pdf += `&printtitle=false`                   // hide optional headers and footers
        pdf += `&pagenum=CENTER`
        pdf += `&gridlines=${preferences.gridlines}`                  // hide gridlines
        pdf += `&fzr=true`                         // do not repeat row headers (frozen rows) on each page
        pdf += `&top_margin=0.45&left_margin=0.25&right_margin=0.25&bottom_margin=0.5`; // margins
        node.state.exportFile = { pdf, xls };
        m.redraw();
        if (!node.state.prompt) {
            console.log("TODO: prompt user report ready")
            node.state.prompt = {
                title: "הדוח מוכן לייצוא",
                form: () => {
                    return m(".",
                        m(".", { style: "display:grid;gap:1rem" },
                            m("a.button--icon", { href: node.state.exportFile.pdf, target: "_blank" }, "הדפס דוח", m(Icon, { icon: "icon-print" })),
                            m("a.button--icon", { href: node.state.exportFile.xls, target: "_blank" }, "ייצא לאקסל", m(Icon, { icon: "icon-download" })),
                        )
                    )
                }
            }
        }
    }
}

export const loadDataAPI = async (node, url, body) => {
    try {
        console.log(`POST API-v1 ${url}`);
        const apiHeaders = {
            "Authorization": `Bearer ${await auth.currentUser.getIdToken()}`,
            "Content-Type": "application/json",
        }
        const requestOptions = {
            method: 'POST',
            headers: apiHeaders,
            body: JSON.stringify(body)
        };
        const response = await fetch(url, requestOptions)
        const result = await response.json()
        if (result.status === "success") node.state.reportData = result.pivotData
        else if (result.status === "error") throw Error(result.error)
    } catch (err) {
        console.error(err);
        node.state.snackbar = { msg: `אירעה שגיאה בטעינת הדוח , תיאור : \n${err}`, isError: true }
        node.state.reportBuildStage = "preview"
        node.state.loadReportData = false
        m.redraw()
    } finally {
        node.state.loadReportData = false
        m.redraw()
    }
}

export function buildDoc(fbDoc) {
    let parent = fbDoc.ref.parent
    if (parent == null) parent = doc.ref //root folder
    const ref = fbDoc.ref.path
    return {
        modelID: getModelID(ref),
        docData:
            Object.assign({},
                fbDoc.data(),
                {
                    colRef: parent.path,
                    ref,
                    docID: fbDoc.id
                }
            )
    }
}
export async function getParent(ref) {
    const splitRef = ref.split("/")
    if (splitRef.length >= 4) {
        const parentRef = splitRef.slice(0, -2).join("/")//remove last 2 parts
        return buildDoc(await db.doc(parentRef).get())
    } else {
        return null
    }
}
export async function getChildren(modelID, ref) {
    try {
        const docs = (await db.doc(ref).collection(modelID).where("isActive", "==", true).get()).docs
        return docs.map(buildDoc)
    } catch (err) {
        console.error(err);
    }
}
export async function getSubChildren(arr, modelID) {
    const result = []
    for (const doc of arr) {
        result.push(...await getChildren(modelID, doc.docData.ref))
    }
    return await Promise.resolve(result)
}
/**
 * @param {ContractDoc} doc 
 * @returns return last AccountDoc for provided contract
 */
export const getLastAccount = doc => {
    const accounts = doc.children.accounts
    return accounts.sort((a, b) => b.docData.period - a.docData.period)[0];
}
