import m from "mithril"
import { BUDGET_CHAPTERS, BUDGET_ITEMS, CONTRACTS, PROJECTS } from "../../../data/dictionary/routeNames"
import { PROJECT_BUDGET } from "../../../data/dictionary/viewOptions"
import { BudgetItemDoc } from "../../../data/store/budget/budgetitemClass"
import { ProjectBudgetDoc } from "../../../data/store/budget/projectBudgetClass"
import { ContractDoc } from "../../../data/store/contracts/contractClass"
import { dataStore } from "../../../data/store/dataStore"
import { CREATE, REMOVE } from "../../../data/store/permissionStore"
import { ProjectDoc } from "../../../data/store/projects/projectClass"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { isUserAllow } from "../../../data/utils/permission"
import { handleAdd, handleCopy, handleDelete } from "../../../data/utils/utils"
import { CURRENCY, LIST, NUMBER } from "../../../utils/constants/types"
import { randomIndex, sortDocsBy } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { Card } from "../cardLayout/Card"
import { FormEditDoc } from "../prompt/FormEditDoc"
import { Prompt } from "../prompt/Prompt"
import { InputCell } from "./InputCell"
import { LocalInput } from "./LocalInput"
import { LocalList } from "./LocalList"

export const BudgetInput = node => {

    const selectProject = {
        // model: ProjectDoc,
        docData: {
            project: ""
        },
        headers: {
            project: {
                label: "בחר פרוייקט",
                type: LIST,
                options: {
                    data: dataStore[PROJECTS].data,
                    load: () => dataStore[PROJECTS].listen(),
                    model: ProjectDoc,
                    formHeaders: ["title"],
                    change: ref => {
                        if (ref) {
                            node.state.project = dataStore[PROJECTS].data.find(doc => doc.docData.ref === ref)
                        }
                    },
                    remove: () => node.state.project = false,
                },
                // data: []
            }
        }
    }
    const selectContract = {
        // model: ProjectDoc,
        docData: {
            contract: ""
        },
        headers: {
            contract: {
                label: "בחר חוזה",
                type: LIST,
                options: {
                    data: (node.state && node.state.project) ? node.state.project.getChildren(CONTRACTS) : [],
                    load: () => {
                        if (node.state.project) {
                            dataStore[CONTRACTS].listen(node.state.project.docData.ref)
                            setTimeout(() => {
                                selectContract.headers.contract.options.data = node.state.project.getChildren(CONTRACTS)
                                m.redraw()
                            }, 2000);
                        }
                    },
                    model: ContractDoc,
                    formHeaders: ["title"],
                    change: ref => {
                        if (ref) {
                            node.state.contract = dataStore[CONTRACTS].data.find(doc => doc.docData.ref === ref)
                        }
                    },
                    remove: () => node.state.contract = false,
                },
                // data: []
            }
        }
    }
    const selectBudgetItem = {
        docData: {
            budgetRef: "",
            sum: "",
        },
        headers: {
            budgetRef: {
                label: "סעיף",
                type: LIST,
                options: {
                    data: dataStore[BUDGET_ITEMS].data,
                    load: () => {
                        dataStore[BUDGET_CHAPTERS].listen()
                        setTimeout(() => {
                            selectBudgetItem.headers.budgetRef.options.data = dataStore[BUDGET_ITEMS].data
                            m.redraw()
                        }, 2000);
                    },
                    model: BudgetItemDoc,
                    formHeaders: ["number", "title"],
                    params: ["number", "title"],
                    change: ref => {
                        if (ref) {
                            selectBudgetItem.docData.budgetRef = ref
                        }
                    },
                    remove: () => selectBudgetItem.docData.budgetRef = ""
                },
            },
            sum: {
                label: "סכום",
                type: CURRENCY,
            }
        }
    }

    const getBudgetDisplayValue = (project, contractRef) => {
        const budgetItems = project.getChildren(PROJECT_BUDGET, { include: { contractRef } })
        if (budgetItems.length > 0) {
            return budgetItems.map(doc => {
                const { budgetRef } = doc.docData
                const budgetDoc = dataStore[BUDGET_ITEMS].data.find(d => d.docData.ref === budgetRef)
                if (budgetDoc) return `${budgetDoc.docData.number} - ${budgetDoc.docData.title}`
            }).join(" | ")
        } else {
            return "---"
        }
    }

    const promptEditBudget = () => {
        const { project, projectBudgetHeaders } = node.state
        node.state.prompt = {
            title: "בחר סעיף תקציבי",
            form: () => {
                return [
                    project && isUserAllow(PROJECT_BUDGET, CREATE) && m("button.button tableCard__add", { onclick: e => handleAdd(e, node, ProjectBudgetDoc, PROJECT_BUDGET, { parent: node.state.project }) }, "הוסף סעיף"),
                    project && m(".table",
                        //HEADERS
                        m('.table__headers table__row', { style: `grid-template-columns: repeat(${projectBudgetHeaders.length},1fr);` },
                            projectBudgetHeaders.map(h => m('.table__cell', ProjectBudgetDoc.headers[h].label)),
                        ),
                        (project && project.getChildren(PROJECT_BUDGET).length === 0) && m(".center", { style: "padding:3rem" }, "לא נמצאו סעיפים , כדאי להתחיל להוסיף!"),
                        //DATA
                        project.getChildren(PROJECT_BUDGET)
                            .sort(sortDocsBy("index", { type: NUMBER }))
                            .map((doc, index) => {
                                // if (isFiltered(vnode, doc, "filterBudgetItems") || !isDocMatchTerm(vnode, doc, ["title"], "searchTermBudgetItems")) return
                                return m(".table__row", {
                                    "data-ref": doc.docData.ref,
                                    class: doc.isNew ? "table__row--new" : "",
                                    style: `grid-template-columns: repeat(${projectBudgetHeaders.length},1fr);`,
                                },
                                    // getDisplayValue(doc.docData[h], ProjectBudgetDoc.headers[h].type, ProjectBudgetDoc.headers[h].options)
                                    projectBudgetHeaders.map((h, index) => {
                                        return m(".table__cell", m(InputCell, {
                                            header: h,
                                            field: ProjectBudgetDoc.headers[h],
                                            index: randomIndex(index),
                                            id: `${doc.docData.ref}/${h}`,
                                            editCell: node.state.editCell,
                                            doc,
                                            parent: node,
                                            options: { ignoreLabel: true }
                                        }))
                                    }),
                                    m(".table__cell table__cell--actions",
                                        isUserAllow(PROJECT_BUDGET, CREATE) && m(".table__action", { onclick: e => handleCopy(e, node, doc, PROJECT_BUDGET) }, m(Icon, { icon: "icon-copy" })),
                                        isUserAllow(PROJECT_BUDGET, REMOVE) && m(".table__action", { onclick: e => handleDelete(e, node, doc, PROJECT_BUDGET) }, m(Icon, { icon: "icon-delete" })),
                                    )
                                )
                            }),
                    )
                ]
            }
        }
    }

    const handleAddBudgetItem = (e) => {
        const colRef = `${node.state.project.docData.ref}/${PROJECT_BUDGET}`
        const { budgetRef, sum } = selectBudgetItem.docData
        const contractRef = node.state.contract.docData.ref
        const newDoc = new ProjectBudgetDoc({ colRef })
        newDoc.saveLocal({ budgetRef, sum, contractRef })
        try {
            newDoc.save()
        } catch (err) {
            console.error(err)
        }
        selectBudgetItem.docData.sum = ""
        selectBudgetItem.docData.budgetRef = ""
    }

    const projectBudgetHeaders = ["budgetRef", "sum", "description",]

    return {
        projectBudgetHeaders: [...projectBudgetHeaders],
        settings: {
            openEditDocForm: {
                formHeaders: ["budgetRef", "sum", "description"]
            },
        },
        prompt: false,
        snackbar: false,
        project: false,
        contract: false,
        editCell: "",
        view: vnode => {
            const { project, contract, projectBudgetHeaders } = vnode.state
            return [
                m(Card, { class: "tableCard" },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    // m(FilterTable, {
                    //     searchTerm: vnode.state.searchTermBudgetItems,
                    //     filters: vnode.state.filterBudgetItems,
                    //     updateSearchTerm: term => vnode.state.searchTermBudgetItems = term,
                    //     filterMap: []
                    // }),

                    // m("button.button", { onclick: e => promptEditBudget() },"עריכת תקציב"),
                    m("h3.caption tableCard__caption", "תקציב פרוייקט"),
                    m(LocalList, { doc: selectProject, header: "project", parent: vnode, key: "selectProject", class: "tableCard__select" }),
                    project && isUserAllow(PROJECT_BUDGET, CREATE) && m("button.button tableCard__add", { onclick: e => handleAdd(e, vnode, ProjectBudgetDoc, PROJECT_BUDGET, { parent: vnode.state.project }) }, "הוסף סעיף"),
                    project && m(".table",
                        //HEADERS
                        m('.table__headers table__row', { style: `grid-template-columns: repeat(${projectBudgetHeaders.length},1fr);` },
                            projectBudgetHeaders.map(h => m('.table__cell', ProjectBudgetDoc.headers[h].label)),
                        ),
                        (project && project.getChildren(PROJECT_BUDGET).length === 0) && m(".center", { style: "padding:3rem" }, "לא נמצאו סעיפים , כדאי להתחיל להוסיף!"),
                        //DATA
                        project.getChildren(PROJECT_BUDGET)
                            .sort(sortDocsBy("index", { type: NUMBER }))
                            .map((doc, index) => {
                                // if (isFiltered(vnode, doc, "filterBudgetItems") || !isDocMatchTerm(vnode, doc, ["title"], "searchTermBudgetItems")) return
                                return m(".table__row", {
                                    "data-ref": doc.docData.ref,
                                    class: doc.isNew ? "table__row--new" : "",
                                    style: `grid-template-columns: repeat(${projectBudgetHeaders.length},1fr);`,
                                    onclick: e => {
                                        vnode.state.prompt = {
                                            title: "עריכה",
                                            class: "full-width",
                                            form: () => {
                                                return m(FormEditDoc, { doc, headers: projectBudgetHeaders, parent: vnode })
                                            }
                                        }
                                    }
                                },
                                    projectBudgetHeaders.map(h => m(".table__cell", getDisplayValue(doc.docData[h], ProjectBudgetDoc.headers[h].type, ProjectBudgetDoc.headers[h].options))),
                                    m(".table__cell table__cell--actions",
                                        isUserAllow(PROJECT_BUDGET, CREATE) && m(".table__action", { onclick: e => handleCopy(e, vnode, doc, PROJECT_BUDGET) }, m(Icon, { icon: "icon-copy" })),
                                        isUserAllow(PROJECT_BUDGET, REMOVE) && m(".table__action", { onclick: e => handleDelete(e, vnode, doc, PROJECT_BUDGET) }, m(Icon, { icon: "icon-delete" })),
                                    )
                                )
                            }),
                    )
                ), //END budget table
                project && m(Card, { class: "formCard" },
                    m("h3.caption formCard__caption", "תקציב חוזה מלמטה"),
                    m(LocalList, { doc: selectContract, header: "contract", parent: vnode, key: "selectContract", class: "tableCard__select" }),
                    contract && m(".input",
                        m(".input__field", getBudgetDisplayValue(node.state.project, contract.docData.ref)),
                        m(".input__label", "סעיף תקציבי")
                    ),
                    contract && m("hr"),
                    contract && m(".", {
                        style: `display: grid;grid-template-columns: 1fr 1fr 1fr;align-items: end;gap: 3rem;padding: 2rem;border: 5px solid var(--color-bg-layout);`
                    },
                        m(".caption", { style: "grid-column:1/-1" }, "הוספת סעיף תקציבי לחוזה"),
                        m(LocalList, { doc: selectBudgetItem, header: "budgetRef", parent: vnode, key: "selectBudgetItem" }),
                        m(LocalInput, { doc: selectBudgetItem, header: "sum", parent: vnode, key: "selectBudgetItem" }),
                        m("button.button", { onclick: e => handleAddBudgetItem(e) }, "הוסף")
                    )
                )
            ]
        }
    }
}