import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Card } from '../../../commons/cardLayout/Card';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { Icon } from '../../../components/icon/Icon';
import { Prompt } from '../../../commons/prompt/Prompt';
import { Snackbar } from '../../../components/snackbar/Snackbar';
import { InputCell } from '../../../commons/inputCell/InputCell';

//DATA
import { PROJECTS, CONTRACTS, CONTRACT_SECTIONS, WORKSPACES, GROUPS, FILES, CONTRACT_ACCOUNTS, CONTRACT_COMMENTS } from '../../../../data/dictionary/routeNames';
import { dataStore } from '../../../../data/store/dataStore';
import { ContractDoc } from '../../../../data/store/contracts/contractClass';
import { SectionDoc } from '../../../../data/store/contracts/sectionClass';

// FUNCTIONS
import { REG_URL_LAST_PART$NQ } from '../../../../utils/constants/regex';
import { getDisplayValue, editValue } from '../../../../data/utils/inputValidation';
import { uuid, sortDocsBy, formatDateDisplay2, preventDefaults } from '../../../../utils/js';
import { CURRENCY, LIST, NUMBER } from '../../../../utils/constants/types';

import { handleAddComment, handleEditComment, listenToMilestones } from '../utils';
import { WorkspaceDoc } from '../../../../data/store/contracts/workSpaceClass';
import { GroupDoc } from '../../../../data/store/contracts/groupClass';
import { SectionPlan } from './SectionPlan';
import { SectionPreview } from './SectionPreview';
import { CheckCell } from '../../../commons/checkCell/CheckCell';
import { isSystemMode } from '../../../../data/utils/viewUtils';
import { DEFAULT_ACTION_NOT_AVILABLE, DEFAULT_USER_NOT_ALLOW_MSG, settingsStore } from '../../../../data/store/settingsStore';
import { isSuperAdmin, isUserAllow } from '../../../../data/utils/permission';
import { CREATE, READ, REMOVE, UPDATE } from '../../../../data/store/permissionStore';
import { DisplayCell } from '../../../commons/inputCell/DisplayCell';
import { TableFiles } from '../../../commons/fileUpload/TableFiles';
import { PROJECT_BUDGET } from '../../../../data/dictionary/viewOptions';
import { InputCards } from '../../../commons/inputCell/InputCards';
import { DocModel } from '../../../../data/store/docModel';
import { exportReportAPI } from '../../reports/utils';
import { CONTRACT_SECTIONS_PLAN } from '../../../../utils/constants/contractsExportsOptions';
import { FormEditDoc } from '../../../commons/prompt/FormEditDoc';
import { Table } from '../../../commons/table/Table';
import { TableInline } from '../../../commons/table/TableInline';
import { NavClass } from '../../../../data/store/NavClass';
import { Nav } from '../../../commons/nav/Nav';
import { IconButton } from '../../../components/icon/IconButton';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { logRouteChangeUser } from '../../../../data/utils/utils';
import { LocalList } from '../../../commons/inputCell/LocalList';

export const ContractPlan = node => {

    const findContract = () => {
        // const contract = [].concat(dataStore[CONTRACTS].data, dataStore[CONTRACTS].new).find(doc => doc.docData.docID === node.attrs.contractID)
        const contract = DocModel.getChild(CONTRACTS, `projects/${node.attrs.projectID}/contracts/${node.attrs.contractID}`)
        if (contract) {
            node.state.contract = contract
        } else {
            //THINK: maby nav to lastURL ... 
            const navToPath = m.route.get().replace(REG_URL_LAST_PART$NQ, "").replace(REG_URL_LAST_PART$NQ, "")
            m.route.set(navToPath);
            m.redraw()
        }
    }

    function makeActive() {
        if (!isUserAllow(CONTRACTS, UPDATE, node.state.contract)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }

        const { contractSum } = node.state.contract.docData
        const sectionsSum = node.state.contract.getTotal({ dataOnly: false })
        console.log(`${sectionsSum > contractSum ? "invalid " : "valid "}`, sectionsSum, contractSum);
        if (sectionsSum > contractSum) {
            node.state.snackbar = { msg: `לא ניתן להפוך חוזה לפעיל! \n סכום סעיפים ${getDisplayValue(sectionsSum, NUMBER)} גדול מסכום הסכם ${getDisplayValue(contractSum, NUMBER)}`, isError: true }
            node.state.prompt = {
                title: "הפוך חוזה לפעיל",
                msg: `סכום סעיפים גדול מסכום החוזה , לא ניתן להפוך חוזה לפעיל 
                סכום חוזה [${getDisplayValue(contractSum, NUMBER)}] <=> סכום סעיפים [${getDisplayValue(sectionsSum, NUMBER)}]
                האם ברצונך לעדכן את סכום החוזה כך שיתאים לסכום הסעיפים ?`,
                actions: [{
                    text: "עדכן סכום חוזה",
                    action: async e => {
                        editValue(contractSum, sectionsSum, "contractSum", node.state.contract)
                        await node.state.contract.save()
                        node.state.snackbar = { msg: "סכום החוזה עודכן ל " + getDisplayValue(sectionsSum, NUMBER) }
                        node.state.prompt = false
                    }
                }, {
                    text: "בטל",
                    color: "gray",
                    action: e => node.state.prompt = false
                }]
            }
        } else {
            const hasPendingChanges = node.state.contract.childHasPendingChanges(CONTRACT_SECTIONS)
            const actions = []
            if (hasPendingChanges) {
                actions.push({
                    text: "הפוך לפעיל ומחק שינויים",
                    action: async e => {
                        node.state.contract.getChildren(CONTRACT_SECTIONS).forEach(section => section.abortChanges())
                        node.state.snackbar = { msg: "עובר לדשבורד חוזה" }
                        node.state.contract.saveLocal({ status: "active" });
                        await node.state.contract.save()
                        node.state.prompt = false
                        m.route.set(m.route.get().replace("plan", "actual"))
                    }
                }, {
                    text: "הפוך לפעיל ושמור שינויים",
                    action: async (e) => {
                        //TESTME:TODO:THINK: detect changes for each one of the sections => maby use ACID batch set opertion
                        const sections = node.state.contract.getChildren(CONTRACT_SECTIONS)
                        for (const section of sections) {
                            await section.save()
                        }
                        node.state.snackbar = { msg: "עובר לדשבורד חוזה" }
                        node.state.contract.saveLocal({ status: "active" });
                        await node.state.contract.save()
                        node.state.prompt = false
                        m.route.set(m.route.get().replace("plan", "actual"))
                    }
                })
            } else {
                actions.push({
                    text: "הפוך לפעיל",
                    action: async e => {
                        node.state.snackbar = { msg: "עובר לדשבורד חוזה" }
                        node.state.contract.saveLocal({ status: "active" });
                        await node.state.contract.save()
                        node.state.prompt = false
                        m.route.set(m.route.get().replace("plan", "actual"))
                    }
                })
            }
            node.state.prompt = {
                title: "הפוך חוזה לפעיל",
                msg: `${hasPendingChanges ?
                    "בחר את האפשרות שברצונך בכדי להפוך את החוזה לפעיל:" :
                    "הפיכת החוזה לפעיל"}`,
                actions: actions.concat({
                    text: "בטל",
                    color: "gray",
                    action: e => node.state.prompt = false
                })
            }
        }
    }


    const navPageView_OPTIONS = {
        OVERVIEW: "overview",
        WORKSPACES: "workspaces",
        COMMENTS: "comments",
        BUDGET: "budget",
        FILES: "files",
    }
    const navPageView = new NavClass({
        nav: [
            {
                label: "מבנה חוזה",
                key: navPageView_OPTIONS.OVERVIEW,
            },
            {
                label: "איזורי עבודה",
                key: navPageView_OPTIONS.WORKSPACES,
            },
            {
                label: "לוג הערות",
                key: navPageView_OPTIONS.COMMENTS,
            },
            {
                label: "תקציב חוזה",
                key: navPageView_OPTIONS.BUDGET,
            },
            {
                label: "צרופות",
                key: navPageView_OPTIONS.FILES,
            },
        ],
    })

    const confirmChanges = () => {
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: "האם לשמור את השינויים שבוצעו בחוזה?",
            actions: [
                {
                    text: "שמור",
                    action: async e => {
                        node.state.snackbar = { msg: "שומר..." };
                        await Promise.resolve(node.state.contract.save());
                        node.state.prompt = false
                        node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                        m.redraw()
                        isActualAction()
                    },

                }
            ]
        }
    }

    const handleAddSection = (e, workspacePath) => {
        preventDefaults(e)
        if (!isUserAllow(CONTRACT_SECTIONS, CREATE)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        if (workspacePath) {
            node.state.prompt = {
                title: "הוספת סעיף",
                msg: "להוסיף סעיף חדש לחוזה ?",
                actions: [
                    {
                        text: "הוסף",
                        action: e => {
                            const colRef = `${node.state.contract.docData.ref}/${CONTRACT_SECTIONS}`
                            let contractCalculationMethod = node.state.contract.docData.calculationMethod
                            let calculationType, calculationMethod
                            if (contractCalculationMethod === 'pauschal') {
                                calculationMethod = 'pauschal'
                                calculationType = 'percent'
                            } else {
                                calculationMethod = 'amount'
                                calculationType = 'number'
                            }
                            const newSection = new SectionDoc({ colRef, workspacePath, calculationType, calculationMethod });
                            node.state.snackbar = { msg: "סעיף חדש נוצר!" }
                            node.state.prompt = false
                            m.route.set(`/app/${newSection.docData.ref}`)
                        }
                    }

                ]
            }
        } else {
            const { contract } = node.state
            const selectWorkspacePath = {
                docData: {
                    workspace: "",
                    group: "",
                },
                headers: {
                    workspace: {
                        label: "בחר איזור עבודה",
                        type: LIST,
                        options: {
                            data: [],
                            load: () => selectWorkspacePath.headers.workspace.options.data = contract.getChildren(WORKSPACES),
                            change: ref => selectWorkspacePath.docData.workspace = ref,
                            remove: ref => {
                                selectWorkspacePath.docData.group = ""
                                selectWorkspacePath.docData.workspace = ""
                            },
                            model: WorkspaceDoc,
                            formHeaders: ["title"],
                        },
                    },
                    group: {
                        label: "בחר קבוצת סעיפים",
                        type: LIST,
                        options: {
                            data: [],
                            load: () => selectWorkspacePath.headers.group.options.data = contract.getChildren(GROUPS, {
                                include: { colRef: `${selectWorkspacePath.docData.workspace}/${GROUPS}` }
                            }, { subChild: true }),
                            change: ref => selectWorkspacePath.docData.group = ref,
                            model: GroupDoc,
                            formHeaders: ["title"]
                        },
                    }
                }
            }
            node.state.prompt = {
                title: "הוספת סעיף",
                class: "full-width",
                form: () => {
                    return m(".formEdit",
                        m(LocalList, { parent: node, doc: selectWorkspacePath, header: "workspace" }),
                        selectWorkspacePath.docData.workspace && m(LocalList, { parent: node, doc: selectWorkspacePath, header: "group" }),
                        (selectWorkspacePath.docData.workspace && selectWorkspacePath.docData.group) && m("button.button", {
                            onclick: e => {
                                const { group } = selectWorkspacePath.docData
                                const path = group.split("/").splice(-4).join("/")
                                handleAddSection(e, path)
                            }
                        }, "הוספה")
                    )
                }
            }
        }
    }


    // const handleAddSection = (e, ws) => {
    //     if (!isUserAllow(CONTRACT_SECTIONS, CREATE)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
    //     if (!node.state.activeGroup) {
    //         node.state.snackbar = { msg: "לא נמצאה קבוצת סעיפים!", isError: true }
    //         return
    //     }
    //     node.state.prompt = {
    //         title: "הוספת סעיף",
    //         msg: "להוסיף סעיף חדש לחוזה ?",
    //         actions: [
    //             {
    //                 text: "כן",
    //                 action: e => {
    //                     const colRef = `${node.state.contract.docData.ref}/sections`
    //                     const workspacePath = `workspaces/${ws.docData.docID}/groups/${node.state.activeGroup.docData.docID}`
    //                     let contractCalculationMethod = node.state.contract.docData.calculationMethod
    //                     let calculationType, calculationMethod
    //                     if (contractCalculationMethod === 'pauschal') {
    //                         calculationMethod = 'pauschal'
    //                         calculationType = 'percent'
    //                     } else {
    //                         calculationMethod = 'amount'
    //                         calculationType = 'number'
    //                     }
    //                     const newSection = new SectionDoc({ colRef, workspacePath, calculationType, calculationMethod });
    //                     node.state.snackbar = { msg: "סעיף חדש נוצר , על מנת לערוך את הסעיף יש לבצע בו שינויים " }
    //                     node.state.prompt = false
    //                 }
    //             }

    //         ]
    //     }
    // }


    //  WORKSPACE
    const handleEditWorkspace = (e, ws) => {
        node.state.prompt = {
            title: "עריכת איזור עבודה",
            class: "full-width",
            form: () => {
                return m(FormEditDoc, {
                    doc: ws,
                    parent: node,
                    headers: ["title", "description", "itemsName", "itemsCount", "buildingRef",]
                },
                    m(Table, {
                        title: "קבוצות סעיפים",
                        tableHeaders: ["title", "description", "itemsName", "itemsCount",],
                        doc: ws,
                        colRef: `${ws.docData.ref}/${GROUPS}`,
                        modelID: GROUPS,
                        sortOptions: { param: "index" },
                        actions: { copy: false, remove: false, move: true, enlarge: false, redirect: false, onRow: "prompt" },
                        viewOptions: { filters: false, add: true, nav: false, totals: false, download: false },
                    })
                )
            }
        }
        // const isAllow = isUserAllow(CONTRACTS, UPDATE, ws)
        // const headers = [
        //     "title",
        //     "itemsName",
        //     "itemsCount",
        //     "description",
        //     "buildingRef"
        // ]
        // const actions = []
        // if (isAllow) {
        //     actions.push({
        //         text: "שמור",
        //         action: e => {
        //             try {
        //                 ws.save();
        //                 node.state.snackbar = { msg: "הפרטים נשמרו" }
        //             } catch (err) {
        //                 node.state.snackbar = { msg: `אירעה שגיאה ${err}`, isError: true }
        //             }
        //             node.state.prompt = false
        //         }
        //     }, {
        //         text: "מחק",
        //         action: e => {
        //         }
        //     }, {
        //         text: "שכפל",
        //         action: e => {
        //         }
        //     })
        // } else {
        //     actions.push({
        //         text: "סגור",
        //         action: e => node.state.prompt = false
        //     })
        // }
        // node.state.prompt = {
        //     title: "עריכת איזור",
        //     msg: "ערוך את פרטי איזור העבודה",
        //     class: "prompt--form",
        //     actions,
        //     form: () => {
        //         return m(".workspace__form",
        //             headers.map((header, index) => {
        //                 const field = WorkspaceDoc.headers[header]
        //                 const value = ws.docData[header]
        //                 if (isAllow) {
        //                     return m(InputCell, {
        //                         header, value, field, index: index + 50, id: `workspace__${header}`,
        //                         editCell: node.state.editCell,
        //                         doc: ws,
        //                         parent: node
        //                     })
        //                 } else {
        //                     return m(DisplayCell, { field, value, doc: ws, header })
        //                 }
        //             })
        //         )
        //     },
        // }
    }

    const handleDeleteWorkspace = (e, ws) => {
        if (!isUserAllow(CONTRACTS, UPDATE)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }

        e.stopPropagation();
        if (ws.docData.docID === 'default') {
            node.state.snackbar = { msg: "לא ניתן למחוק איזור עבודה זה!", isError: true }
            return
        }
        if (ws.hasSections()) {
            node.state.snackbar = { msg: "לא ניתן למחוק איזור עבודה המכיל סעיפים !", isError: true }
            return
        }
        node.state.prompt = {
            title: "מחיקת איזור עבודה",
            msg: `האם למחוק את איזור העבודה ${ws.docData.title} ?`,
            actions: [
                {
                    text: "כן",
                    action: e => {
                        try {
                            ws.remove()
                            node.state.snackbar = { msg: "איזור העבודה נמחק!" }
                        } catch (error) {
                            console.error(error)
                            node.state.snackbar = { msg: error, isError: true }
                        }
                        node.state.prompt = false
                    }
                }
            ]
        }
    }

    // GROUP :
    const handleAddGroupOrWorkspace = (e, ws) => {
        if (!isUserAllow(CONTRACTS, UPDATE)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }

        node.state.prompt = {
            title: "הוספה",
            msg: "האם להוסיף איזור עבודה , או קבוצת סעיפים ?",
            form: () => {
                return m(".workspace__switchAdd",
                    m("button.button", {
                        onclick: e => {
                            const colRef = `${node.state.contract.docData.ref}/workspaces`;
                            try {
                                const newWorkspace = new WorkspaceDoc({ colRef }).insert(colRef, true);
                                node.state.snackbar = { msg: "איזור חדש נוצר , ניתן לערוך אותו על ידי לחיצה על כפתור העריכה" }
                            } catch (err) {
                                node.state.snackbar = { msg: `אירעה שגיאה ${err}`, isError: true }
                            }
                            node.state.prompt = false
                        }
                    }, "הוסף איזור עבודה"),
                    m("button.button", {
                        onclick: e => {
                            const colRef = `${node.state.contract.docData.ref}/workspaces/${ws.docData.docID}/groups`
                            const newGroup = new GroupDoc({ colRef }).insert(colRef, true);
                            node.state.snackbar = { msg: "קבוצה נוצרה בהצלחה" }
                            node.state.prompt = false
                            toggleActiveWorkspace(ws.docData.docID)
                            setTimeout(() => {
                                node.state.activeGroup = newGroup
                                m.redraw()
                            }, 1000);
                            //OPEN WORKSPACE
                        }
                    }, "הוסף קבוצת סעיפים")
                )
            },
        }
    }

    const handleDuplicateGroup = (e, group) => {
        if (!isUserAllow(CONTRACTS, UPDATE, node.state.contract)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        e.stopPropagation();
        try {
            group.duplicate()
        } catch (err) {
            console.error(err)
        }
    }

    const handleDuplicateWorkspace = (e, ws) => {
        if (!isUserAllow(CONTRACTS, UPDATE, node.state.contract)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        e.stopPropagation();
        try {
            ws.duplicate()
        } catch (err) {
            console.error(err)
        }
    }

    const handleEditGroup = (e, group) => {
        const { contract } = node.state
        const workspacePath = group.getWorkspacePath()
        node.state.prompt = {
            title: "עריכת קבוצת סעיפים",
            class: "full-width",
            form: () => {
                return m(FormEditDoc, {
                    doc: group,
                    parent: node,
                    headers: ["title", "description", "itemsName", "itemsCount"]
                },
                    m(Table, {
                        title: "סעיפים",
                        tableHeaders: ["title", "calculationMethod", "calculationType", "amountType", "itemsCount", "itemPrice", "totalSum", "description"],
                        doc: contract,
                        colRef: `${contract.docData.ref}/${CONTRACT_SECTIONS}`,
                        filterDataOptions: { include: { workspacePath } },
                        modelID: CONTRACT_SECTIONS,
                        sortOptions: { param: "index" },
                        actions: { copy: false, remove: false, enlarge: false, redirect: false, onRow: false },
                        viewOptions: { filters: false, add: false, nav: false, totals: true, download: false },
                    })
                )
            }
        }
        // const isAllow = isUserAllow(CONTRACTS, UPDATE, node.state.contract)
        // const headers = [
        //     "title",
        //     "itemsName",
        //     "itemsCount",
        //     "description",
        // ]
        // const actions = []
        // if (isAllow) {
        //     actions.push({
        //         text: "שמור",
        //         action: e => {
        //             try {
        //                 group.save();
        //                 node.state.snackbar = { msg: "הפרטים נשמרו" }
        //             } catch (err) {
        //                 node.state.snackbar = { msg: `אירעה שגיאה ${err}`, isError: true }
        //             }
        //             node.state.prompt = false
        //         }
        //     })
        // } else {
        //     actions.push({
        //         text: "סגור",
        //         action: e => node.state.prompt = false
        //     })
        // }
        // node.state.prompt = {
        //     title: "עריכת קבוצה",
        //     msg: "ערוך את פרטי הקבוצה",
        //     class: "prompt--form",
        //     actions,
        //     form: () => {
        //         return m(".workspace__form",
        //             headers.map((header, index) => {
        //                 const field = GroupDoc.headers[header]
        //                 const value = group.docData[header]
        //                 if (isAllow) {
        //                     return m(InputCell, {
        //                         header, value, field, index: index + 50, id: `workspace__${header}`,
        //                         editCell: node.state.editCell,
        //                         doc: group,
        //                         parent: node
        //                     })
        //                 } else {
        //                     return m(DisplayCell, { field, value, doc: group, header })
        //                 }
        //             })
        //         )
        //     },
        // }
    }

    const handleDeleteGroup = (e, group, sections) => {
        if (!isUserAllow(CONTRACTS, UPDATE, node.state.contract)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        e.stopPropagation();
        if (group.docData.docID === 'default') {
            node.state.snackbar = { msg: "לא ניתן למחוק קבוצת סעיפים זו!", isError: true }
            return
        }
        //TODO:find which section are in group before delete
        node.state.prompt = {
            title: "מחיקת קבוצת סעיפים",
            msg: `האם למחוק את קבוצת הסעיפים ${group.docData.title} ?`,
            actions: [
                {
                    text: "כן",
                    action: e => {
                        try {
                            group.remove()
                            node.state.snackbar = { msg: "הסעיפים נמחקו" }
                        } catch (error) {
                            console.error(error)
                            node.state.snackbar = { msg: error, isError: true }
                        }
                        node.state.prompt = false
                    }
                }
            ]
        }
    }

    /**
     * if status is not plan => nav to contract/actual
     */
    function isActualAction() {
        //contractPlan OR contractActual
        if (node.state.contract && node.state.contract.docData.status !== 'plan') {
            // alert("עובר למסך הקמת חוזה")
            m.route.set(m.route.get().replace("plan", "actual"))
            m.redraw()
        }
    }

    async function exportContractTable() {
        const { contract } = node.state
        // vnode.state.snackbar = { msg: DEFAULT_ACTION_NOT_AVILABLE };
        await exportReportAPI(node, contract.getExportData([CONTRACT_SECTIONS_PLAN]))
    }



    const contractHeaders = [
        'title',
        "contractorRef",
        // TODO: craete this fields as mirror type
        // 'contractorName',
        // 'commercialName',
        // 'companyNumber',
        // 'taxesEndDate',
        // 'contractorSAPNumber',
        "isIndexed",
        "indexedFactor",
        'budgetbudgetaryItem',
        'contractSum',
        'paymentDelay',
        'calculationMethod',
        'delayPercentage',
        'sWorkDate',
        'eWorkDate',
        // 'status', //TODO: move to bottom as button
        'description',
    ]
    const sectionHeaders = [
        "title",
        "calculationMethod",
        "calculationType",
        "amountType",
        "itemsCount",
        "itemsStartIndex",
        "itemPrice",
        "totalSum",
        "description",
        // "donePercentage",
    ]

    const isPreviewMode = sectionID => node.state.sectionPreveiewMode[sectionID] && node.state.sectionPreveiewMode[sectionID] === "preview"
    const togglePreviewEdit = sectionID => {
        // node.state.snackbar = { msg: "לא פעיל כרגע", isError: true }
        if (isPreviewMode(sectionID)) {
            node.state.sectionPreveiewMode[sectionID] = "edit"
        } else {
            node.state.sectionPreveiewMode[sectionID] = "preview"
        }
    }

    const isRemoved = () => node.state.contract && node.state.contract.isRemoved

    const isActiveGroup = group => node.state.activeGroup && group.docData.ref === node.state.activeGroup.docData.ref
    const toggleActiveGroup = (group, forceActive = false) => {
        if (forceActive) {
            node.state.activeGroup = group
        } else {
            // toggle
            if (isActiveGroup(group)) node.state.activeGroup = false
            else node.state.activeGroup = group
        }
    }
    const isActiveWorkspace = wsID => node.state.activeWorkspace && wsID === node.state.activeWorkspace
    const toggleActiveWorkspace = (wsID, forceActive = false) => {
        if (forceActive) {
            node.state.activeWorkspace = wsID
        } else {
            //toggle
            if (isActiveWorkspace(wsID)) node.state.activeWorkspace = false
            else node.state.activeWorkspace = wsID
        }
    }

    const toggleTamplate = () => {
        if (isUserAllow(CONTRACTS, UPDATE, node.state.contract)) {
            editValue("", !node.state.contract.docData.isTamplate, "isTamplate", node.state.contract)
        }
    }


    return {
        loadContract: true,
        loadSections: true,
        loadMilestones: {},
        collaspeSection: [],
        sectionPreveiewMode: {}, //sectionRef:preview|edit
        activeGroup: false,
        activeWorkspace: "default",
        contractHeaders: [...contractHeaders],
        prompt: false,
        snackbar: false,
        oninit: vnode => {
            navPageView.setNode(vnode)
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get());
            sessionStorage.setItem('lastURL', m.route.get());
            logRouteChangeUser("checkIn");

            const projectPath = `projects/${node.attrs.projectID}`
            dataStore[PROJECTS].listen()
            const { alreadyListen } = dataStore[CONTRACTS].listen(projectPath)
            if (alreadyListen) {
                findContract()
                isActualAction()
                vnode.state.loadContract = false;

                dataStore[WORKSPACES].listen(vnode.state.contract.docData.ref)
                const { alreadyListen: isListenToSections } = dataStore[CONTRACT_SECTIONS].listen(vnode.state.contract.docData.ref)
                listenToMilestones(vnode, isListenToSections)
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען פרטי חוזה...", loading: true }
                setTimeout(() => {
                    findContract()
                    isActualAction()
                    vnode.state.loadContract = false

                    dataStore[WORKSPACES].listen(vnode.state.contract.docData.ref)

                    const { alreadyListen: isListenToSections } = dataStore[CONTRACT_SECTIONS].listen(vnode.state.contract.docData.ref)
                    listenToMilestones(vnode, isListenToSections)
                    m.redraw()
                }, 3000)

            }
        },
        onbeforeremove: vnode => {
            return logRouteChangeUser("checkOut");
        },
        view: vnode => {
            return (
                m(PageLayout, {
                    class: "contractPlan contract",
                    title:
                        vnode.state.contract ?
                            `${vnode.state.contract.getProjectType("text")} / מסך עריכת חוזה`
                            : "מסך עריכת חוזה",
                    module: vnode.state.contract ? vnode.state.contract.getProjectType() : ""
                },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        ),
                        // vnode.state.prompt.action && m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    vnode.state.contract && isSystemMode(vnode) && m(".settingsRow",
                        // m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.settings.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(Promise.resolve(vnode.state.contract.toJSON({ clone: true })).then(r => console.log(r))) }, "TO_JSON"),
                        m(`button.button`, { onclick: e => vnode.state.contract.exportSheet() }, "DOWNLOAD_SHEET"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree"),
                        m(".permission",
                            m(Card,
                                m(".caption", CONTRACTS),
                                [CREATE, READ, UPDATE, REMOVE].map(action => {
                                    return m(".permission__action", { style: `display: grid;grid-auto-flow: column;gap: 1rem;align-items: center;justify-content: center;border: 1px solid gray;padding: .5rem;border-radius: .5rem;background: var(--color-bg-layout);` },
                                        m(".caption", action),
                                        m(".allow", isUserAllow(CONTRACTS, action, node.state.contract) ? "ALLOW ✔" : "NOT ALLOW ⛔"),
                                    )
                                })
                            )
                        )
                    ),
                    vnode.state.contract && m(`.toggleCheck${vnode.state.contract.docData.isTamplate == true ? "--checked" : ""}`, { onclick: e => toggleTamplate() },
                        ContractDoc.headers.isTamplate.label,
                        m(CheckCell, { checked: vnode.state.contract.docData.isTamplate == true })
                    ),
                    isRemoved() ? "חוזה זה נמחק" : m(Card, { class: "formCard contractPlan__details" },
                        m("h3.formCard__caption caption", "פרטי החוזה"),
                        vnode.state.loadContract && m(InlineLoaderDots),
                        (vnode.state.contract && vnode.state.contract.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                        vnode.state.contract && vnode.state.contractHeaders.map((header, index) => {
                            // const value = vnode.state.contract.docData[header]
                            const field = ContractDoc.headers[header]
                            return m(InputCell, {
                                header, field, index, id: `contract_${header}`,
                                editCell: vnode.state.editCell,
                                doc: vnode.state.contract,
                                parent: vnode
                            })
                        }),
                        vnode.state.contract && vnode.state.contract.hasChanges() && m(".formCard__actions",
                            m("button.button formCard__save", { onclick: e => confirmChanges() }, "שמור שינויים"),
                            m("button.button formCard__abort", { onclick: e => vnode.state.contract.abortChanges() }, "בטל שינויים"),
                        )
                    ),
                    (!isRemoved() && vnode.state.contract) && m(Nav, { class: "contract__nav", nav: navPageView.nav }),
                    (vnode.state.contract && navPageView.isActiveState(navPageView_OPTIONS.WORKSPACES)) && m(Table, {
                        tableHeaders: ["title", "description", "itemsName", "itemsCount", "buildingRef"],
                        title: "איזורי עבודה",
                        modelID: WORKSPACES,
                        colRef: `${vnode.state.contract.docData.ref}/${WORKSPACES}`,
                        doc: vnode.state.contract,
                        actions: { onAdd: "prompt" },
                    }),
                    (vnode.state.contract && navPageView.isActiveState(navPageView_OPTIONS.BUDGET)) && m(InputCards, {
                        headers: { budgetRef: {}, sum: { style: "font-size:3.2rem" }, description: {} },
                        title: "סעיף תקציבי",
                        modelID: PROJECT_BUDGET,
                        colRef: `projects/${vnode.attrs.projectID}/${PROJECT_BUDGET}`,
                        doc: vnode.state.contract.getParent(),
                        filterDataOptions: { include: { contractRef: vnode.state.contract.docData.ref } }
                    }),
                    (vnode.state.contract && navPageView.isActiveState(navPageView_OPTIONS.COMMENTS)) && m(Card, { class: "contract__comments card--scroll" },
                        m("h3.caption", "לוג הערות"),
                        vnode.state.loadContract && m(InlineLoaderDots),
                        (vnode.state.contract && !isRemoved()) && m(".comments",
                            vnode.state.contract
                                .getChildren(CONTRACT_COMMENTS)
                                .sort(sortDocsBy("timestamp", { type: NUMBER }))
                                .map(doc => m(".comment",
                                    doc.isNew && m(".flag flag--new", "לא נשמר"),
                                    m(".comment__title", { onclick: e => handleEditComment(vnode, doc) }, doc.docData.title),
                                    m(".comment__content", doc.docData.description),
                                    m(".comment__info", formatDateDisplay2(new Date(doc.docData.timestamp), "useHours")),
                                )),
                            m(".comment--add", { onclick: e => handleAddComment(vnode) }, "+ הוסף הערה חדשה")
                        )
                    ),

                    (vnode.state.contract && !isRemoved() && navPageView.isActiveState(navPageView_OPTIONS.FILES)) && m(TableFiles, {
                        colRef: `${vnode.state.contract.docData.ref}/${FILES}`,
                        title: "צרופות חוזה",
                        doc: vnode.state.contract,
                        actions: { onRow: "prompt", onAdd: "prompt" },
                        formHeaders: ["title", "description", "status"],
                        tableHeaders: ["title", "description", "status", "size", "created", "contentType"],
                        modelID: FILES
                    }),

                    (!isRemoved() && vnode.state.contract && navPageView.isActiveState(navPageView_OPTIONS.OVERVIEW)) && m(Card, { class: "contractPlan__totals contract__totals reportTable card--scroll", columns: 4 },
                        m("h3.formCard__caption caption",
                            "סיכום",
                            m(Icon, { icon: "icon-print", action: e => exportContractTable() }),
                            // m(Icon, { icon: "icon-download", action: e => vnode.state.contract.exportSheet() }),
                        ),
                        (vnode.state.loadSections) ?
                            m(InlineLoaderDots) :
                            [
                                m(".cell cell--header", "סעיף"),
                                m(".cell cell--header", "מחיר יח"),
                                m(".cell cell--header", "כמות יח"),
                                m(".cell cell--header", "סהכ"),
                                vnode.state.contract.getChildren(WORKSPACES)
                                    .sort(sortDocsBy("index", { type: NUMBER }))
                                    .map((ws, wsIndex) => {
                                        return [
                                            m(".row cell cell--title", { onclick: e => handleEditWorkspace(e, ws) }, ws.docData.title),
                                            ws.getChildren(GROUPS)
                                                .sort(sortDocsBy("index", { type: NUMBER }))
                                                .map((group, groupIndex) => {
                                                    return [
                                                        m(".row cell cell--subtitle", {
                                                            onclick: e => handleEditGroup(e, group)
                                                        },
                                                            group.docData.title,
                                                            m(".cell__actions", { style: "z-index: 1;position: absolute;left: 0;display:flex;" },
                                                                (isUserAllow(CONTRACT_SECTIONS, CREATE)) && m("button.button button--gray", {
                                                                    style: "margin: 0px;border-radius: 50%;",
                                                                    'data-tooltip': true,
                                                                    onclick: e => handleAddSection(e, group.getWorkspacePath())
                                                                },
                                                                    m(Icon, { icon: "icon-plus" }),
                                                                    m(Tooltip, { text: `הוסף סעיף` })
                                                                ),
                                                                (isUserAllow(CONTRACT_SECTIONS, CREATE)) && m("button.button button--gray", {
                                                                    style: "margin: 0px;border-radius: 50%;",
                                                                    'data-tooltip': true,
                                                                    onclick: e => handleDuplicateGroup(e, group)
                                                                },
                                                                    m(Icon, { icon: "icon-copy" }),
                                                                    m(Tooltip, { text: `שכפל קבוצת סעיפים` })
                                                                ),
                                                            ),
                                                        ),
                                                        dataStore[CONTRACT_SECTIONS].data
                                                            .filter(section => section.docData.colRef === `${vnode.state.contract.docData.ref}/sections` && section.isInWorkspace(ws.docData.docID) && section.isInGroup(group))
                                                            .map(section => {
                                                                return [
                                                                    m(".cell cell--value clickable", { onclick: e => m.route.set(`/app/${section.docData.ref}`) }, section.docData.title),
                                                                    m(".cell cell--value", getDisplayValue(parseInt(section.docData.itemPrice), CURRENCY)),
                                                                    m(".cell cell--value", getDisplayValue(parseInt(section.docData.itemsCount), NUMBER)),
                                                                    m(".cell cell--value", getDisplayValue(parseInt(section.docData.totalSum), CURRENCY)),
                                                                ]
                                                            })
                                                    ]
                                                }),
                                        ]
                                    }),
                                m(".row row__total", 'סה"כ : ', getDisplayValue(parseInt(vnode.state.contract.getTotal()), CURRENCY)),
                                m('button.button', { style: ``, onclick: e => handleAddSection(e) }, "הוסף סעיף"),
                            ]
                    ),


                    // (!isRemoved() && vnode.state.contract) && dataStore[WORKSPACES].data
                    //     .filter(ws => ws.docData.colRef === `${vnode.state.contract.docData.ref}/workspaces`)
                    //     .sort(sortDocsBy("timestamp", { type: NUMBER }))
                    //     .map((ws, wsIndex) => {
                    //         const isCollaspeWs = !isActiveWorkspace(ws.docData.docID)
                    //         return m(".workspace", { key: wsIndex },
                    //             m(".ws", { key: `ws_${wsIndex}`, id: `ws_${ws.docData.docID}`, class: isCollaspeWs ? "ws--collaspe" : "" },
                    //                 m("h3.ws__title", `${ws.docData.title}${(isActiveWorkspace(ws.docData.docID) && vnode.state.activeGroup) ? ` / ${vnode.state.activeGroup.docData.title}` : ""}`),
                    //                 isCollaspeWs && m(".action show", m(Icon, { icon: "icon-edit", action: e => handleEditWorkspace(e, ws) })),
                    //                 isCollaspeWs && m(".action show", m(Icon, { icon: "icon-copy", action: e => handleDuplicateWorkspace(e, ws) })),
                    //                 isCollaspeWs && m(".action show", m(Icon, { icon: "icon-delete", action: e => handleDeleteWorkspace(e, ws) })),
                    //                 isCollaspeWs && m(".action show", m(Icon, { icon: "icon-plus", action: e => handleAddGroupOrWorkspace(e, ws) })),
                    //                 m(".action show", m(Icon, { icon: `icon-arrow-thin-${isCollaspeWs ? "up" : "down"}`, action: e => toggleActiveWorkspace(ws.docData.docID) })),
                    //             ),
                    //             !isCollaspeWs && m(".groups", { key: `groups_${wsIndex}` },
                    //                 dataStore[GROUPS].data
                    //                     .filter(group => group.docData.colRef === `${ws.docData.ref}/groups`)
                    //                     .map(group => {
                    //                         return m(".group", {
                    //                             id: group.docData.ref,
                    //                             key: group.docData.ref,
                    //                             class: isActiveGroup(group) ? "group--open" : "group--close",
                    //                             "data-ref": group.docData.ref,
                    //                             onclick: e => toggleActiveGroup(group)
                    //                         },
                    //                             m(".group__actions",
                    //                                 m(".group__action", { onclick: e => handleEditGroup(e, group) }, m(Icon, { icon: "icon-edit" }), "ערוך"),
                    //                                 m(".group__action", { onclick: e => handleDuplicateGroup(e, group) }, m(Icon, { icon: "icon-copy" }), "שכפל"),
                    //                                 m(".group__action", { onclick: e => handleDeleteGroup(e, group) }, m(Icon, { icon: "icon-delete" }), "מחק"),
                    //                             ),
                    //                             m(Icon, { class: "group__folder", icon: `icon-folder-${isActiveGroup(group) ? "open" : "close"}` }),
                    //                             m(".group__title", group.docData.title),
                    //                         )
                    //                     }),
                    //             ),

                    //             (!isCollaspeWs && vnode.state.activeGroup) && m(".sections", { key: `sections_${wsIndex}` },
                    //                 [].concat(dataStore[CONTRACT_SECTIONS].data, dataStore[CONTRACT_SECTIONS].new)
                    //                     .filter(section => section.docData.colRef === `${vnode.state.contract.docData.ref}/sections` && section.isInWorkspace(ws.docData.docID) && section.isInGroup(vnode.state.activeGroup))
                    //                     .sort(sortDocsBy("index", { type: NUMBER }))
                    //                     .map((section, sectionIndex, sectionsArr) => {
                    //                         const isPreview = isPreviewMode(section.docData.docID)
                    //                         if (isPreview) {
                    //                             return m(SectionPreview, { sectionIndex, wsIndex, section, sectionsArr, parentState: vnode.state, togglePreviewEdit })
                    //                         } else {
                    //                             return m(SectionPlan, { sectionIndex, wsIndex, section, sectionsArr, parentState: vnode.state, togglePreviewEdit })
                    //                         }
                    //                     }), //END sections map
                    //             ),
                    //             (!isCollaspeWs && vnode.state.activeGroup && isUserAllow(CONTRACTS, CREATE)) && m(Card, { key: uuid(), class: "card--add", action: e => handleAddSection(e, ws) },
                    //                 m(".add", "+ הוסף סעיף")
                    //             )
                    //         ) //End workspace
                    //     }), //End workspaces map

                    (!isRemoved() && vnode.state.contract && !vnode.state.contract.isNew) && m("button.button", { onclick: e => makeActive(), style: "justify-self:center;margin-top: 4rem;" }, "הפוך חוזה לפעיל")
                ) //END pageLayout
            ) //END return
        }
    }
}