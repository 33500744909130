export const statusTypes = [
    { text: 'פעיל', id: 'active', color: { bg: "#6495ed82", text: "#00008b" } },
    { text: 'לא פעיל', id: 'nonActive', color: { bg: "#ea2e6330", text: "#eb2f64" } }
]
export const fileStatusTypes = [
    { text: 'פתוח', id: 'open', next: ["done", "archive"], color: { bg: "#ea2e6330", text: "#eb2f64" } },
    //TODO: delete me after some time
    { text: 'טופל', id: 'confirm', next: ["done", "archive"], color: { bg: "#006400", text: "#fff" } },
    { text: 'טופל', id: 'done', next: ["archive"], color: { bg: "#006400", text: "#fff" } },
    { text: 'ארכיון', id: 'archive', next: ["open"], color: { bg: "#888888", text: "#fff" } }
]
export const oddJobsStatus = [
    { text: 'פתוח', id: 'open', next: ["confirm1", "notConfirm", "archive"], color: { bg: "#ea2e6330", text: "#eb2f64" } },
    { text: 'מאושר מנהל פרוייקט', id: 'confirm1', next: ["confirm2", "archive"], color: { bg: "#006400", text: "#fff" } },
    { text: 'מאושר הנהלה בכירה', id: 'confirm2', next: ["done", "donePartial", "archive"], color: { bg: "#006400", text: "#fff" } },
    { text: 'נסגר ושולם חלקי', id: 'donePartial', next: ["archive", "open"], color: { bg: "#6495ed84", text: "#00008b" } },
    { text: 'נסגר ושולם מלא', id: 'done', next: ["archive"], color: { bg: "#6af26799", text: "#00008b" } },
    { text: 'לא אושר לתשלום', id: 'notConfirm', next: ["archive", "open"], color: { bg: "#ff642e", text: "#fff" } },
    { text: 'ארכיון', id: 'archive', next: ["open"], color: { bg: "#888888", text: "#fff" } }
]

export const constractStatusTypes = [
    { text: 'בהקמה', id: 'plan', next: ['active'] },
    { text: 'פעיל', id: 'active', next: ['finish', 'plan'] },
    { text: 'הסתיים', id: "finish" }
]
export const constractCommentsTags = [
    { text: '---', id: 'none' },
    { text: 'חסר אישור מנהפ', id: 'a' },
    { text: 'חסר אישור מנכל', id: 'b' },
    { text: 'בטיפול הנהח', id: "c" },
    { text: 'בטיפול כספים', id: "d" },
    { text: 'עבר לשחרור עכבון', id: "e" },
    { text: 'לא שוחרר עכבון', id: "f" },
    { text: 'שוחרר עכבון', id: "g" },
    { text: 'התקבל העדר תביעות', id: "h" },
]

export const booleanType = [{ id: false, text: "לא" }, { id: true, text: "כן" }]

