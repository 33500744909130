import m from 'mithril';
import { settingsStore } from '../../../data/store/settingsStore';
import { db } from '../../../index';
import { sortBy } from '../../../utils/js';
import { Card } from '../../commons/cardLayout/Card';
import { PageLayout } from '../../commons/pageLayout/Page';

export const ChannelsListenters = node => {

    const promptChannel = (e, lis) => {
        console.log(lis)
    }

    return {
        view: vnode => {
            return m(PageLayout,
                m(Card,
                    settingsStore.listeners
                        .sort(sortBy("modelID"))
                        .map((lis, ind) => {
                            return m("button.button", { onclick: e => promptChannel(e, lis) }, `[${ind}] - ${lis.modelID}`, m("span", lis.path))
                        })
                )
            )
        }
    }
}
