import m from 'mithril'

//DATA

//FUNCTIONS

//COMPONENTS
import { Sidebar } from '../sidebar/Sidebar'
import { TopBar } from '../topBar/TopBar'
import { Icon } from '../../components/icon/Icon'
import { APP_VERSION } from '../../../index'

export const PageLayout = (node) => {
    return {
        view: (vnode) => {
            return m('div.layout',
                m(TopBar, { title: vnode.attrs.title }),
                m('.page.show',
                    m(Sidebar, { module: vnode.attrs.module }),
                    m('main.content', { class: vnode.attrs.class || "" }, vnode.children),
                ),
                m("footer.footer",
                    m("p.copy",
                        `${String.fromCharCode(169)} כל הזכויות שמורות לביקונט בע"מ 2020
                            [version:${APP_VERSION}] `,
                        m.trust("&beta;")
                    ),
                    // m("img", { src: "/img/zikaron.gif", style: `max-height: 6rem; border-radius: 1rem;` }),
                    m("a.bug", {
                        target: "_blank",
                        href: "https://forms.monday.com/forms/17766a3159a34dfe4dad302678ec99cf"
                    }, m(Icon, { icon: "icon-bug" }))
                )
            )
        }
    }
}
