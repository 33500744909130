import m from "mithril"
import { addMonths, monthDiff,getDefaultMonth, buildMonthText } from "../../../utils/js"
import { CONTRACT_ACCOUNTS } from "../../../data/dictionary/routeNames";
import { dataStore } from "../../../data/store/dataStore";


export const MonthSelector = node => {
    function setSelected(item) {
        node.state.selected = item;
        node.attrs.setSelected(item.text)
    }

    return {
        selected: false,
        list: false,
        listOpen: false,
        active: false,
        oninit: vnode => {
            const now = new Date();
            const { defaultMonth, defaultYear } = getDefaultMonth(vnode.attrs.lastMonth);
            const lastAccountDate = new Date(defaultYear, defaultMonth, 1);
            const nextAccountDate = addMonths(1, lastAccountDate)
            if (vnode.attrs.type === "newAccount") {
                setSelected({
                    text: buildMonthText(nextAccountDate),
                    year: defaultYear,
                    month: defaultMonth
                })
                // if => diff between now and nextAccountDate (or dateCreatedContract)
                // then => create list of option
                let diff = monthDiff(now, nextAccountDate);
                // console.log(diff, "if diff < 0 , should add all delta");
                if (diff < 0) {
                    const list = [];
                    let periodMonth = now.getMonth();
                    let periodYear = now.getFullYear();
                    // const DAYS_IN_MONTH_TO_DECREASE = 15
                    // if (now.getDate() < DAYS_IN_MONTH_TO_DECREASE) periodMonth--
                    while (diff !== 1) {
                        const periodDate = new Date(periodYear, periodMonth, 1);
                        list.push({ text: buildMonthText(periodDate), date: periodDate });
                        if (periodMonth === -1) { //end of year
                            periodYear--;
                            periodMonth = 11
                        } else {
                            periodMonth--
                        }
                        diff++;
                    }
                    node.state.list = list
                }
            } else {
                setSelected({
                    text: buildMonthText(lastAccountDate),
                    year: defaultYear,
                    month: defaultMonth
                })
                // const filterAccounts = vnode.attrs.contract.getChildren() //REFACTOR TO THIS ONE TODO:
                const filterAccounts = dataStore[CONTRACT_ACCOUNTS].data.filter(account => account.docData.colRef === `${node.attrs.contract.docData.ref}/accounts`)
                const list = []
                filterAccounts.forEach(account => {
                    const [periodMonth, periodYear] = account.docData.month.split(" ")
                    const periodDate = new Date(periodYear, periodMonth, 1);
                    list.push({ text: account.docData.month, date: periodDate });
                })
                node.state.list = list
            }
        },
        view: vnode => {
            const { list, selected, listOpen } = vnode.state
            const { label } = vnode.attrs
            return m(".monthSelect",
                m("label.monthSelect__label", label || "תקופה חדשה"),
                m(".monthSelect__input", { tabindex: 1 },
                    m(".monthSelect__selected", { tabindex: 2, onclick: e => vnode.state.listOpen = !listOpen }, selected ? selected.text : "--בחר תקופה--"),
                    //in case there is diff between last period and current last month create list of option
                    (list && listOpen && list.length > 1) && m(".monthSelect__list",
                        list.map(item => m(".monthSelect__item", {
                            onclick: e => {
                                if (item.text !== selected.text) {
                                    setSelected(item);
                                    vnode.state.listOpen = false;
                                }
                            },
                            class: item.text === selected.text ? "monthSelect__item--disabled" : ""
                        }, item.text))
                    )
                ),
                (vnode.state.list.length <= 1 && vnode.attrs.type === "goToAccount") && m(".monthSelect__info", "נראה שאין תקופות קודמות , נסה במקום זה ליצור אחת חדשה")
            )
        }
    }
}