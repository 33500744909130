import { SWITCH, DATE, STRING, NUMBER } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, MIN, MAX, PATTERN } from "../../../utils/constants/inputAttrs";
import { VENDORS, COMPANIES } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { REG_COMPANY_NUMBER_PATTERN, REG_PHONE_PATTERN, REG_EMAIL_PATTERN } from "../../../utils/constants/regex";
import { statusTypes } from "../../../utils/constants/statusTypes";

export class CompanyDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: CompanyDoc,
        });
    };
    static get meta() {
        return {
            id: COMPANIES,
            routes: {
                collection: "/companies",
                doc: "/companies/:companyID",
            },
        }
    }
    static get headers() {
        return {
            title: { label: "חברה", defaultValue: "--חברה חדשה--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            commercialName: { label: 'שם מסחרי', defaultValue: "--ללא שם--" },
            companyNumber: { label: 'ח.פ.', defaultValue: "", props: [{ [PATTERN]: REG_COMPANY_NUMBER_PATTERN }] },
            companyType: { label: "סוג עוסק", defaultValue: "company", type: SWITCH, options: [{ text: "חברה בעמ", id: "company" }, { text: "עוסק מורשה", id: "licensedDealer" }] },
            phone: { label: 'טלפון', defaultValue: "", props: [{ [PATTERN]: REG_PHONE_PATTERN }] },
            email: { label: 'אימייל', defaultValue: "", props: [{ [PATTERN]: REG_EMAIL_PATTERN }] },
            taxesEndDate: { label: 'תוקף ניכוי מס במקור', defaultValue: new Date().toISOString(), type: DATE },
            address: { label: 'כתובת', defaultValue: "--ללא כתובת--", },
            description: { label: 'הערות', defaultValue: "", },
            status: { label: 'סטטוס', defaultValue: "active", type: SWITCH, options: statusTypes },
        }
    }
}