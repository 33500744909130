import m from 'mithril';
import { getDisplayValue, validateField } from '../../../data/utils/inputValidation';
import { isFieldVisible } from '../../../data/utils/permission';
import { HIDDEN } from '../../../utils/constants/inputAttrs';
import { O_FUNCTION } from '../../../utils/constants/objTypes';
import { SWITCH, LIST, CHECK_BOOL } from '../../../utils/constants/types';
import { objType } from '../../../utils/js';
import { Icon } from '../../components/icon/Icon';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { CheckCell } from '../checkCell/CheckCell';
import { IconError } from '../iconError/IconError';

export const DisplayCell = node => {
    const isHidden = (field, doc) => {
        if (field.props) {
            const hiddenProp = field.props.find(el => Object.keys(el)[0] === HIDDEN)
            if (hiddenProp) {
                const [[_key, val]] = Object.entries(hiddenProp)
                if (objType(val) === O_FUNCTION) return val(doc) === true
                return val === true
            }
        }
    }

    const getOptionStyle = (field, value) => {
        let style = ""
        const { options = [] } = field
        const findOption = options.find(el => el.id === value)
        if (findOption && findOption.color) {
            if (findOption.color.text) style += `color:${findOption.color.text};`
            if (findOption.color.bg) style += `background-color:${findOption.color.bg};`
        }
        return style
    }
    return {
        // listOpen: false,
        // oldValue: "",
        error: false,
        oninit: vnode => {
            const { value, doc, header } = vnode.attrs
            const { valid, errorMsgs } = validateField(doc, header, value);
            if (!valid) vnode.state.error = errorMsgs.join("\n")
        },
        view: vnode => {
            const { value, field, doc, classStyle, header, options = {}, cellStyle = "" } = vnode.attrs
            if (!field) {
                console.log(value, field, doc, header, options)
                return
            }
            const { ignoreLabel, tooltip = true } = options
            if (!isFieldVisible(field, doc, header)) return
            if (isHidden(field, doc)) return
            return m(`.cell cell--display [data-tooltip] ${classStyle ? classStyle : ""}`, { style: cellStyle },
                tooltip && m(Tooltip, { text: `${!ignoreLabel ? `${field.label}:\n` : ""}${getDisplayValue(value, field.type, field.options, doc.docData)}` }),
                m(IconError, { error: vnode.state.error }),
                field.type === SWITCH ?
                    m(".cell__input input input--select",
                        m(".input__field cell__field cell__field--select", { style: getOptionStyle(field, value) },
                            getDisplayValue(value, field.type, field.options, doc.docData)
                        ),
                        !ignoreLabel && m("label.input__label cell__label", field.label)
                    )
                    :
                    field.type === CHECK_BOOL ?
                        m(CheckCell, {
                            checked: doc.docData[header] == true,
                            label: ignoreLabel ? false : field.label
                        })
                        :
                        field.type === LIST ?
                            m(".cell__input input input--select",
                                m(".input__field cell__field cell__field--select", {
                                    oninit: el => field.options.load(doc),
                                },
                                    getDisplayValue(value, field.type, field.options, doc.docData)
                                ),
                                !ignoreLabel && m("label.input__label cell__label", field.label)
                            )
                            :
                            m(".cell__input input",
                                //NO NEED TO TOGGLE INPUT
                                m(".input__field input__field--text cell__field--text", {
                                    class: vnode.state.error ? "input__field--error" : "",
                                    "data-type": field.type
                                    // tabindex: index + 1,
                                    // onfocus: e => parent.state.editCell = id,
                                    // onclick: e => parent.state.editCell = id
                                },
                                    getDisplayValue(value, field.type, field.options, doc.docData)
                                ),
                                !ignoreLabel && m("label.input__label cell__label", {
                                    class: vnode.state.error ? "input__label--error" : "",
                                    // for: id,
                                    onclick: e => {
                                        // parent.state.editCell = id;
                                        if (vnode.state.error) {
                                            vnode.state.showError = !vnode.state.showError
                                        }
                                    },
                                    "data-error": vnode.state.error
                                }, field.label),

                            )
            )
        }
    }
}