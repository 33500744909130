import m from "mithril"
import { APARTMENTS, BUILDINGS, CONTRACTS, FLOORS, PROJECTS } from "../../../data/dictionary/routeNames"
import { DocModel } from "../../../data/store/docModel"
import { NavClass } from "../../../data/store/NavClass"
import { CREATE } from "../../../data/store/permissionStore"
import { BuildingDoc } from "../../../data/store/projectBuildings/BuildingClass"
import { FloorDoc } from "../../../data/store/projectBuildings/floorClass"
import { ProjectDoc } from "../../../data/store/projects/projectClass"
import { isUserAllow } from "../../../data/utils/permission"
import { logRouteChangeUser } from "../../../data/utils/utils"
import { NUMBER } from "../../../utils/constants/types"
import { preventDefaults, sortDocsBy } from "../../../utils/js"
import { Card } from "../../commons/cardLayout/Card"
import { DisplayCell } from "../../commons/inputCell/DisplayCell"
import { LocalList } from "../../commons/inputCell/LocalList"
import { Nav } from "../../commons/nav/Nav"

import { PageLayout } from "../../commons/pageLayout/Page"
import { FormEditDoc } from "../../commons/prompt/FormEditDoc"
import { Prompt } from "../../commons/prompt/Prompt"
import { Table } from "../../commons/table/Table"
import { TableInline } from "../../commons/table/TableInline"
import { Icon } from "../../components/icon/Icon"
import { IconButton } from "../../components/icon/IconButton"
import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { Tooltip } from "../../components/tooltip/Tooltip"
import { BuildingDraw } from "./BuildingDraw"

export const Apartments = node => {

    const projectHeaders = [
        "title",
        "address",
        "sDate",
        "numberOfPeriods",
        "eDate",
        "description",
        "manager",
        "seniorManager",
        "numberOfBuildings",
        "basements",
        "numberOfApatrments",
        "projectType",
        "status"
    ]

    const navProjectViewOptions = {
        OVERVIEW: "overview",
        APARTMENTS: "apartments",
    }
    const navProjectView = new NavClass({
        nav: [
            {
                label: "דשבורד פרוייקט", key: navProjectViewOptions.OVERVIEW,
                action: () => {
                    m.route.set(`/app/projects/${node.attrs.projectID}`)
                }
            },
            {
                label: "אינדקס תכולת פרוייקט",
                key: navProjectViewOptions.APARTMENTS,
            },
        ],
        settings: { defaultActiveKey: navProjectViewOptions.APARTMENTS }
    })
    const buildingHeaders = ["title", "description", "floorsCount", "apartmentsCount", "includeGroundFloor"]
    const floorHeaders = ["title", "description", "apartmentsCount"]

    const handleCopyBuilding = (e, building) => {
        preventDefaults(e)
        building.duplicate()
    }
    const handleAddBuilding = (e) => {
        const newBuilding = new BuildingDoc({ colRef: `projects/${node.attrs.projectID}/${BUILDINGS}` })
        promptBuilding(e, newBuilding)
    }
    const promptBuilding = (e, building) => {
        preventDefaults(e)
        if (building.isNew) {
            node.state.prompt = {
                class: "full-width",
                title: building.docData.title,
                form: () => m(FormEditDoc, {
                    doc: building, headers: buildingHeaders, parent: node, actions: { onSave: "open" }
                },
                    !building.isNew && m(TableInline, {
                        title: "קומות",
                        modelID: FLOORS,
                        colRef: `${building.docData.ref}/${FLOORS}`,
                        doc: building,
                        tableHeaders: ["title", "apartmentsCount", "fromIndex", "toIndex"],
                        // filterDataOptions: { newOnly: true },
                        filterDataOptions: {},
                        sortOptions: { param: "index" },
                        searchOptions: ["title", "index", "description"],
                    })
                )
            }
        } else {
            m.route.set(`/app/${building.docData.ref}`)
        }
    }
    const handleAddFloor = (e, building) => {
        const newFloor = new FloorDoc({ colRef: `${building.docData.ref}/${FLOORS}` })
        promptFloor(e, newFloor, building)
    }
    const promptFloor = (e, floor, building) => {
        preventDefaults(e)
        const { project } = node.state
        const buildingFloorPath = floor.docData.ref.split("/").splice(2, 4).join("/")
        node.state.prompt = {
            class: "full-width",
            title: `מבנה: ${building.docData.title} / קומה: ${floor.docData.title}`,
            form: () => m(FormEditDoc, {
                doc: floor,
                headers: floorHeaders,
                parent: node,
                actions: { onSave: "open" }
            },
                !floor.isNew && m(TableInline, { //TABLE ?
                    title: "דירות",
                    modelID: APARTMENTS,
                    colRef: `projects/${node.attrs.projectID}/${APARTMENTS}`,
                    doc: project,
                    tableHeaders: [
                        "title",
                        "description",
                        "contractsRefs",
                        "apartmentModel"
                    ],
                    actions: {
                        onAdd: (node, project) => floor.addApartment(),
                        // onRow: "prompt", //THINK: redirect ? or prompt ? or inline edit
                        copy: false, remove: false, move: true, enlarge: false, redirect: true,
                    },
                    viewOptions: { filters: false, add: true, nav: false, totals: false, download: false },
                    filterDataOptions: { include: { buildingFloorPath } },
                    // searchOptions: ["title", "description"],
                })
            )
        }
    }

    return {
        snackbar: false,
        prompt: false,
        oninit: vnode => {
            window.scrollTo(0, 0);
            navProjectView.setNode(vnode)
            const projectPath = `projects/${vnode.attrs.projectID}`
            const project = DocModel.getChild(PROJECTS, projectPath)
            if (project) {
                vnode.state.project = project
                project.listenToChildren([CONTRACTS, APARTMENTS, BUILDINGS])
                vnode.state.contracts = project.getChildren(CONTRACTS)
                sessionStorage.setItem('lastURL', m.route.get());
            }
            else m.route.set(`/app/${projectPath}`)
            console.log("routeChange", m.route.get());
            logRouteChangeUser("checkIn");
        },
        // onbeforeremove: vnode => {
        //     return logRouteChangeUser("checkOut");
        // },
        onremove: vnode => {
            console.log("remove '/apartments'")
            return logRouteChangeUser("checkOut");
        },
        view: vnode => {
            const { project, contracts } = vnode.state
            return m(PageLayout, {},
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Nav, { nav: navProjectView.nav }),
                m(Card, { class: "formCard project__details" },
                    m("h3.caption formCard__caption", "פרטי הפרוייקט"),
                    !project && m(InlineLoaderDots),
                    project && projectHeaders.map((header, index) => {
                        const value = project.docData[header]
                        const field = ProjectDoc.headers[header]
                        return m(DisplayCell, { field, header, value, doc: project })
                    })
                ),
                (!project && !contracts) && m(InlineLoaderDots),
                (project && contracts) && m(Card, { class: "buildingsCard" },
                    m(".caption tableCard__caption", "מבנים"),
                    m(".buildings",
                        project.getChildren(BUILDINGS)
                            .sort(sortDocsBy("index", { type: NUMBER }))
                            .map(building => {
                                return m(BuildingDraw, {
                                    building,
                                    actions: {
                                        buildingClick: promptBuilding,
                                        floorClick: promptFloor,
                                        addFloor: handleAddFloor
                                    },
                                    viewOptions: { floorAddButton: true }
                                })
                            }),
                    ),
                    isUserAllow(BUILDINGS, CREATE) && m("button.button tableCard__add", {
                        'data-tooltip': true,
                        onclick: e => handleAddBuilding(e)
                    },
                        m(Icon, { icon: "icon-plus" }),
                        m(Tooltip, { text: "הוספת מבנה" })
                    ),
                ),
            )
        }
    }
}