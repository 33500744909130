import m from 'mithril';
import { auth } from '../../../index';

import { USERS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';

// import { UserDoc } from '../../../data/store/users/userClass';
import { Icon } from '../../components/icon/Icon';
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots';
import { distDays } from '../../../utils/js';

export const UserBox = node => {

    const userActions = [
        {
            text: "התראות ומשימות",
            key: "tasks",
            action: (evt, vnode, id) => {
                evt.stopPropagation();
                m.route.set("/app/tasks")
            },
            icon: "icon-bell"
        },
        {
            text: "הפרופיל שלי",
            key: "profile",
            action: (evt, vnode, id) => {
                evt.stopPropagation();
                m.route.set(`/app/users/${auth.currentUser.uid}`);
                m.redraw()
            },
            icon: "icon-profile"
        },
        {
            text: "התנתק",
            key: "singOut",
            action: (evt, vnode, id) => {
                evt.stopPropagation();
                return auth.signOut()
                    .then(() => m.redraw())
            },
            icon: "icon-exit"
        },
    ]

    const filterDueDone = msgDoc => (msgDoc.docData.status !== "finish" && distDays(new Date(), new Date(msgDoc.docData.due)) <= 0)

    const getUserImage = () => {
        let user
        if (dataStore[USERS].current) user = dataStore[USERS].current
        else user = dataStore[USERS].data.find(user => user.docData.docID === auth.currentUser.uid)
        if (user) {
            return [
                m('img.userBox__img', { src: user.docData.photoURL || "/img/noUser.jpg" }),
                m("span.userBox__name", user.docData.displayName || user.docData.email.split("@")[0]),
            ]
        } else {
            return [
                m('img.userBox__img', { src: '/img/noUser.jpg' }),
                m("span.userBox__name", auth.currentUser.email.split("@")[0])
            ]
        }
    }
    return {
        listOpen: false,
        view: vnode => {
            return (
                auth.currentUser === null && m(InlineLoaderDots),
                auth.currentUser != null && m('.userBox', { onclick: e => vnode.state.listOpen = !vnode.state.listOpen },
                    getUserImage(),
                    dataStore.notification.data.filter(filterDueDone).length > 0 && m(".notification__count", dataStore.notification.data.filter(filterDueDone).length),
                    vnode.state.listOpen && m(".userBox--open",
                        getUserImage(),
                        userActions.map(el => {
                            return m('.actionList__row', { onclick: e => el.action(e, vnode, vnode.attrs.id) }, [
                                el.text ? el.text : null,
                                el.icon ? m(Icon, { icon: el.icon }) : null,
                                el.key === "tasks" && dataStore.notification.data.filter(filterDueDone).length > 0 && m(".notification__count", dataStore.notification.data.filter(filterDueDone).length),
                            ])
                        })
                    )
                )
            )
        }
    }
}