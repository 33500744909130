import m from "mithril";
//DATA
import { dataStore } from "./dataStore"
import { internalFields, defaultFields } from "../dictionary/internalFields"
import { VALID_CALC, VALID_UNIQUE } from "../../utils/constants/types";
import { REG_URL_LAST_PART } from "../../utils/constants/regex";
import { BUILDINGS, PROJECTS, USERS } from "../dictionary/routeNames";
import { CREATE } from "./permissionStore";
import { O_FUNCTION } from "../../utils/constants/objTypes";

//FUNCTIONS
import { db } from "../../index"
import { objType, uuid } from "../../utils/js"
import { arrangeBreadCrumbs, buildSheetXML, getModelID } from "../utils/utils";
import { getTriggerStatements, calcFieldRes, editValue } from "../utils/inputValidation";
import { buildInsertDoc } from "../CRUD/utils";
import { isSystemMode } from "../utils/viewUtils";
import { isSuperAdmin, isUserAllow } from "../utils/permission";

export class DocModel {
    constructor({ isNew = true, data = {}, model, headers = {} }) {
        this.headers = Object.assign(model.headers, headers, defaultFields)

        //TESTME: refactor
        const colRef = data.colRef
        const docID = data.docID || uuid()
        const ref = data.ref || `${colRef}/${docID}`
        this.docData = DocModel.buildDocData(this.headers, Object.assign({ colRef, docID, ref }, this.docData, data))

        // //BUILD Doc.docData: 
        // //[step 1] build Doc from data object (also when have missing headers)
        // Object.entries(data).forEach(([key, val]) => this.docData[key] = val)

        // const getDefaultValue = (field) => {
        //     if (field.defaultValue !== undefined) {
        //         if (objType(field.defaultValue) === O_FUNCTION) return field.defaultValue(this)
        //         return field.defaultValue
        //     }
        //     return ""
        // }
        // //[step 2] override data that not include in headers by using headers
        // Object.entries(this.headers).forEach(([key, field]) => {
        //     if (!data.hasOwnProperty(key)) {
        //         // console.log(`key ${key} not in data - TODO: getDefaultValue() => `, Object.keys(this.headers), data, getDefaultValue(field));
        //         this.docData[key] = getDefaultValue(field)
        //     }
        // })


        this.model = model
        this.isNew = isNew
        this.docChanges = {}
        this.children = {}

        // if (!isNew) {
        //     this.docData = Object.assign({}, data)
        // }
        if (this.isNew) {
            this.docData.timestamp = +new Date()
            if ("index" in this.headers && !("index" in data)) this.setIndex()

            if (model.meta.id !== USERS && !isUserAllow(model.meta.id, CREATE, this)) {
                console.error("TODO: block user!!! ⛔\n", " user cannot ", CREATE, " doc in model ", model.meta.id);
            } else {
                dataStore[model.meta.id].new.push(this)
            }
        } // else listener take care for this

    }

    //*********************************************
    //      STATIC METHODS: USE IN ROOT DOCS
    //*********************************************
    static getChildren(modelID, options = {}) {
        const { dataOnly, exclude, include } = options
        const excludeFilter = exclude ? Object.entries(exclude) : false
        const includeFilter = include ? Object.entries(include) : false
        return [].concat(dataStore[modelID].data)
            .concat(dataOnly ? [] : dataStore[modelID].new)
            .filter(doc => {
                return (excludeFilter ? excludeFilter.every(([param, value]) => doc.docData[param] !== value) : true) &&
                    (includeFilter ? includeFilter.every(([param, value]) => doc.docData[param] === value) : true)
            })
    }
    static getParent() {
        return false // root models do not have parents
    }

    static buildDocData(headers, data = {}) {
        const result = {}
        //[1]
        Object.entries(data).forEach(([key, val]) => result[key] = val)

        //[2]
        const getDefaultValue = (field) => {
            if (field.defaultValue !== undefined) {
                if (objType(field.defaultValue) === O_FUNCTION) return field.defaultValue(result)
                return field.defaultValue
            }
            return ""
        }
        Object.entries(headers).forEach(([key, field]) => {
            if (!data.hasOwnProperty(key)) {
                result[key] = getDefaultValue(field)
            }
        })
        return result
    }

    static getChild(modelID, ref) {
        if (modelID.length > 0 && modelID.length > 0 && modelID in dataStore) {
            return [...dataStore[modelID].data, ...dataStore[modelID].new].find(doc => doc.docData.ref === ref)
        }
    }
    static getDoc(docRef, param) {
        if (docRef) {
            const modelID = getModelID(docRef)
            const doc = this.getChild(modelID, docRef)
            if (doc && param) return doc.docData[param]
            return doc
        }
    }
    static getChildModel(childModelID) {
        return dataStore[childModelID].model
    }
    static getTotalDocs(modelID, filterOptions = {}, filterSettings = {}, fieldTotal) {
        const docs = DocModel.getChildren(modelID, filterOptions, filterSettings)
        let result = 0
        docs.forEach(doc => {
            result += parseFloat(doc.docData[fieldTotal])
        })
        return result
    }

    //*****************************
    //      INSTANCE METHODS:
    //*****************************

    getTotalDocs(modelID, filterOptions = {}, filterSettings = {}, fieldTotal) {
        let docs
        if (filterSettings.siblings) {
            docs = this.getSiblings(filterOptions)
        } else {
            docs = this.getChildren(modelID, filterOptions, filterSettings)
        }
        let result = 0
        docs.forEach(doc => {
            result += parseFloat(doc.docData[fieldTotal])
        })
        return result
    }

    setIndex(replaceWith, direction = "after") {
        if (replaceWith) {
            if (this.isNew) {
                throw "cannot setIndex(replaceWith) to new element please save it and then change order"
            } else {
                if (this.docData.ref === replaceWith) return
                const replaceDoc = this.getSibling(replaceWith)
                if (!replaceDoc) throw `doc to replace not found`
                let thisIndex = this.docData.index
                let replaceIndex = replaceDoc.docData.index
                if (replaceIndex == thisIndex) {
                    if (direction === "before") {
                        if (thisIndex == 1) replaceIndex = 2
                        else thisIndex = parseInt(replaceIndex) - 1
                    } else/*  if (direction==="after") */ {
                        thisIndex = parseInt(replaceIndex) + 1
                    }
                }
                this.docData.index = replaceIndex
                replaceDoc.docData.index = thisIndex
                const batch = db.batch()
                const docToReplace = db.doc(replaceWith)
                const currDoc = db.doc(this.docData.ref)
                batch.set(currDoc, { index: replaceIndex }, { merge: true })
                batch.set(docToReplace, { index: thisIndex }, { merge: true })
                batch.commit()
                    .then(() => {
                        console.log("setIndex success");
                    })
                    .catch(err => console.error(err))
                    .finally(() => m.redraw())
            }
        } else {
            if (this.isNew) {
                // console.log(this);
                this.docData.index = this.getMax("index") + 1
            } else {
                this.saveLocal({ index: this.getMax("index") + 1 })
                Promise.resolve(this.save())
                    .catch((err) => console.error(err))
                    .finally(() => m.redraw())
            }
        }
    }

    getMax(param) {
        const filterSiblings = this.getSiblings()
        let max = 0
        if (filterSiblings.length > 0) max = parseInt(filterSiblings[0].docData[param]);
        if (isNaN(max)) throw `param ${param} must be a number`
        for (let i = 1; i < filterSiblings.length; ++i) {
            if (parseInt(filterSiblings[i].docData[param]) > max) {
                max = parseInt(filterSiblings[i].docData[param]);
            }
        }
        return max
    }
    getMin(param) {
        const filterSiblings = this.getSiblings()
        let min = parseInt(filterSiblings[0].docData[param]);
        if (isNaN(min)) throw `param ${param} must be a number`
        for (let i = 1; i < filterSiblings.length; ++i) {
            if (parseInt(filterSiblings[i].docData[param]) < min) {
                min = parseInt(filterSiblings[i].docData[param]);
            }
        }
        return min
    }
    getAfter(param) {
        const filterSiblings = this.getSiblings({ exclude: { ref: this.docData.ref } })
        let afters = []
        for (let i = 0; i < filterSiblings.length; ++i) {
            const initial = parseInt(filterSiblings[i].docData[param])
            if (isNaN(initial)) throw `param ${param} cannot be a number`
            if (initial > parseInt(this.docData[param])) afters.push(filterSiblings[i])
        }
        if (afters.length) {
            return afters.reduce((a, b) => {
                if (parseInt(a.docData[param]) < parseInt(b.docData[param])) return a
                else return b
            })
        }
    }
    getBefore(param) {
        const filterSiblings = this.getSiblings()
        let befores = []
        for (let i = 0; i < filterSiblings.length; ++i) {
            const initial = parseInt(filterSiblings[i].docData[param])
            if (isNaN(initial)) throw `param ${param} cannot be a number`
            if (initial < parseInt(this.docData[param])) befores.push(filterSiblings[i])
        }
        if (befores.length) {
            return befores.reduce((a, b) => {
                if (parseInt(a.docData[param]) > parseInt(b.docData[param])) return a
                else return b
            })
        }
    }
    getFirst(param) {
        const filterSiblings = this.getSiblings()
        let elem = filterSiblings[0]
        let min = parseInt(elem.docData[param])
        if (isNaN(min)) throw `param ${param} cannot be a number`
        for (let i = 1; i < filterSiblings.length; ++i) {
            if (parseInt(filterSiblings[i].docData[param]) < min) {
                min = parseInt(filterSiblings[i].docData[param]);
                elem = filterSiblings[i]
            }
        }
        return elem
    }
    isFirst(param, options = {}) {
        const filterSiblings = this.getSiblings(options)
        return filterSiblings.every(el => parseInt(el.docData[param]) >= parseInt(this.docData[param]))
    }
    isLast(param, options = {}) {
        const filterSiblings = this.getSiblings(options)
        return filterSiblings.every(el => parseInt(el.docData[param]) <= parseInt(this.docData[param]))
        // return filterSiblings.reduce((prev, next, ind, arr) => parseInt(arr[ind].docData[param]) <= parseInt(this.docData[param]))
    }
    getLast(param, options = {}, settings = {}) {
        let filterDocs = []
        if (settings.childModelID) filterDocs = this.getChildren(childModelID, options)
        else filterDocs = this.getSiblings(options)
        let elem = filterDocs[0]
        if (!elem) return
        let max = parseInt(elem.docData[param])
        if (isNaN(max)) throw `param ${param} cannot be a number`
        for (let i = 1; i < filterDocs.length; ++i) {
            if (parseInt(filterDocs[i].docData[param]) > max) {
                max = parseInt(filterDocs[i].docData[param]);
                elem = filterDocs[i]
            }
        }
        return elem
    }

    hasChanges(param) {
        if (param) {
            return this.docChanges[param] !== undefined
        }
        return Object.keys(this.docChanges).length > 0
    }

    removeChange(param) {
        if (param && this.docChanges[param] !== undefined && this.docChanges[`${param}__old`] !== undefined) {
            const oldValue = this.docChanges[`${param}__old`]
            // console.log(`save oldValue(${oldValue}) and remove change\n ON:\n`, this.docChanges)
            editValue(oldValue, oldValue, param, this)
            delete this.docChanges[param]
            delete this.docChanges[`${param}__old`]
            // this.docData[param] = oldValue
            // console.log(` after delete `, this.docChanges,this.docData)
        } else {
            // console.info(`cannot removeChange on param ${param} at docChanges: \n`, this.docChanges)
        }
    }

    abortChanges(param) {
        if (param) {
            this.removeChange(param)
        } else {
            //recursive call
            Object.keys(this.docChanges).map(header => {
                const [_header, isOld] = header.split("__")
                if (!isOld) {
                    this.removeChange(_header)
                }
            })
            this.docChanges = {}
        }
        m.redraw()
    }

    childHasPendingChanges(modelID, options = {}) {
        return this.getChildren(modelID, options).some(doc => doc.hasChanges())
    }

    listenToChildren(list) {
        if (list) {
            list.forEach(child => {
                dataStore[child].listen(this.docData.ref)
            })
        } else { //all
            dataStore[this.model.meta.id].children.forEach(child => {
                dataStore[child].listen(this.docData.ref)
            })
        }
    }
    isListenToChildren(modelID) {
        return dataStore[modelID].isListen[this.docData.ref]
    }

    getSibling(ref) {
        return [...dataStore[this.model.meta.id].data, ...dataStore[this.model.meta.id].new].find(doc => doc.docData.ref === ref)
    }

    getSiblings(options = {}) {
        const { dataOnly, exclude, include } = options
        const excludeFilter = exclude ? Object.entries(exclude) : false
        const includeFilter = include ? Object.entries(include) : false
        return [].concat(dataStore[this.model.meta.id].data)
            .concat(dataOnly ? [] : dataStore[this.model.meta.id].new)
            .filter(doc => {
                return doc.docData.colRef === this.docData.colRef &&
                    (excludeFilter ? excludeFilter.every(([param, value]) => doc.docData[param] !== value) : true) &&
                    (includeFilter ? includeFilter.every(([param, value]) => doc.docData[param] === value) : true)
            })
    }

    cloneDocData({ clone = true, newParentRef, resets = {} } = {}) {
        const cloneData = Object.assign({}, this.docData, resets)
        const docID = uuid()
        let colRef;
        //THINK: clone index 

        if (clone && newParentRef) colRef = `${newParentRef}/${this.model.meta.id}`
        else colRef = this.docData.colRef //same parent

        cloneData.docID = docID
        cloneData.ref = `${colRef}/${docID}`
        if (clone) {
            cloneData.cloneFrom = this.docData.ref
        }
        return cloneData
    }

    // getChildModel(modelID){
    //     // return 
    // }

    getChildByID(modelID, docID, { subChild = false } = {}) {
        const colRefFunction = doc => {
            if (subChild) return doc.docData.colRef.startsWith(this.docData.ref)
            else return doc.docData.colRef === `${this.docData.ref}/${modelID}`
        }
        return [...dataStore[modelID].data, ...dataStore[modelID].new].find(doc => colRefFunction(doc) && doc.docData.docID === docID)
    }

    //THINK: maby use getChildren for subcollection by define subTree filter
    getChildren(modelID, filterOptions = {}, filterSettings = {}) {
        const { dataOnly, newOnly, exclude, include, parentInclude, parentExclude } = filterOptions
        const { subChild = false } = filterSettings
        const excludeFilter = exclude ? Object.entries(exclude) : false
        const includeFilter = include ? Object.entries(include) : false
        const parent_includeFilter = parentInclude ? Object.entries(parentInclude) : false
        const parent_excludeFilter = parentExclude ? Object.entries(parentExclude) : false

        const includeFunction = (doc, param, value) => {
            if (objType(value) === O_FUNCTION) {
                return value(doc)
            }
            return doc.docData[param] === value
        }
        const colRefFunction = doc => {
            if (subChild) return doc.docData.colRef.startsWith(this.docData.ref)
            else return doc.docData.colRef === `${this.docData.ref}/${modelID}`
        }
        return [].concat(newOnly ? [] : dataStore[modelID].data)
            .concat(dataOnly ? [] : dataStore[modelID].new)
            .filter(doc => {
                // return doc.docData.colRef === `${this.docData.ref}/${modelID}` &&
                return colRefFunction(doc) &&
                    (parent_includeFilter ? parent_includeFilter.every(([param, value]) => doc.getParent(param) === value) : true) &&
                    (parent_excludeFilter ? parent_excludeFilter.every(([param, value]) => doc.getParent(param) !== value) : true) &&
                    (includeFilter ? includeFilter.every(([param, value]) => includeFunction(doc, param, value)) : true) &&
                    (excludeFilter ? excludeFilter.every(([param, value]) => !includeFunction(doc, param, value)) : true)
            })
    }

    getContract(param) {
        const [p, pID, c, cID] = this.docData.ref.split("/")
        const contract = dataStore.contracts.data.find(doc => doc.docData.docID === cID)
        if (contract) {
            if (param) return contract.docData[param]
            return contract
        } else {
            throw `contract ${cID} not found!!!`
        }
    }
    getProject(param) {
        const [p, pID] = this.docData.ref.split("/")
        const project = dataStore.projects.data.find(doc => doc.docData.docID === pID)
        if (project) {
            if (param) return project.docData[param]
            return project
        } else {
            throw `project ${pID} not found!!!`
        }
    }

    getParent(param) {
        const parentRef = this.docData.colRef.replace(REG_URL_LAST_PART, "")
        const parentID = getModelID(parentRef);
        let parent
        if (parentRef.length > 0 && parentID.length > 0 && parentID in dataStore) {
            parent = [...dataStore[parentID].data, ...dataStore[parentID].new].find(doc => doc.docData.ref === parentRef)
        }
        if (parent) {
            if (param) return parent.docData[param]
            else return parent
        } else {
            // `parent not found!`
        }
    }

    /**
     * print doc data
     * @param {Array} props [optional] if provided print props: data,meta,header,isNew etc. 
     */
    print(props = []) {
        if (props.length > 0) {
            props.forEach(prop => console.log(this[prop]))
        } else {
            console.log(this.docData)
        }
    }

    //copy doconly
    copy(addDataOptions = {}) {
        // const cloneData = Object.assign({}, this.docData)
        // cloneData.colRef = this.docData.colRef
        // cloneData.docID = uuid();
        // cloneData.ref = `${cloneData.colRef}/${cloneData.docID}`

        let newParentRef = false
        if (addDataOptions.colRef && addDataOptions.colRef !== "") {
            newParentRef = addDataOptions.colRef.replace(REG_URL_LAST_PART, "")
        }
        const cloneData = this.cloneDocData({ clone: true, newParentRef })
        cloneData.title = `עותק של ${this.docData.title}`
        const DocModel = this.model
        return new DocModel(cloneData, true);
    }

    //deep duplicate
    async duplicate(addDataOptions = {}) {
        try {
            if (!this.toJSON) {
                throw `cannot duplicate item without toJSON property`
            }
            const batch = db.batch()
            const { data, children } = await this.toJSON({ clone: true, addDataOptions })
            const docRef = db.doc(data.ref)
            data.title = `עותק של ${this.docData.title}`
            batch.set(docRef, buildInsertDoc(data), { merge: true })

            let batchIndex = 0
            let operationCounter = 1;
            const batchArray = [batch];

            Object.entries(children).forEach(([childKey, childData]) => {
                childData.forEach(childDoc => {
                    console.log(`batch copy childDoc :\n`, childDoc, "\non: ", childKey)
                    operationCounter++;
                    if (operationCounter === 499) {
                        batchArray.push(db.batch());
                        batchIndex++;
                        operationCounter = 0;
                    }
                    const childRef = db.doc(childDoc.ref)
                    batchArray[batchIndex].set(childRef, buildInsertDoc(childDoc), { merge: true })
                })
            })
            for (const b of batchArray) {
                await b.commit()
            }
            // await batch.commit();
            console.log("success!!", data, children)
            m.redraw()
        } catch (err) {
            console.error(err)
        }
    }

    async exportSheet() {
        const title = this.docData.title
        const fileName = `${title}-download-${+new Date()}.xls`;

        const { data, children } = await this.toJSON()

        const XML = buildSheetXML(title, this.headers, data, children)
        const blob = new Blob([XML], { type: 'text/plain' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
        link.draggable = true;
        link.classList.add('dragout');
        link.click();
    }

    /** save doc locally to store
     * @returns docChanges as object
     */
    saveLocal(changes, old) {
        // console.log(`${this.docData.title}.saveLocal(${JSON.stringify(changes)})`)
        const msgs = []
        Object.entries(changes).forEach(([key, value]) => {
            if (!this.headers.hasOwnProperty(key)) {
                //TESTME: inject outside values to doc
                // throw `cannot set value: "${value}" to "${key}" that not in headers definition`
            }
            if (!this.hasChanges(key)) {
                this.docChanges[`${key}__old`] = this.docData[key]
            }
            this.docChanges[key] = value
            this.docData[key] = value
        })

        //CALC TRIGGER STATEMENTS:
        Object.entries(changes).forEach(([changeHeader, value]) => {
            if (this.model.meta.logic) {
                const calcStatements = getTriggerStatements(this.model.meta.logic, changeHeader, VALID_CALC)
                if (calcStatements) {
                    calcStatements.forEach(statement => {
                        const result = calcFieldRes(this, statement, changeHeader);
                        const msg = `recalculate ${statement.target} ℹ : \n ${statement.expression} = \n ${result} `;
                        msgs.push(msg)
                        this.docData[statement.target] = result
                        this.docChanges[statement.target] = result
                    })
                }
            }
        })

        const source = this.isNew ? "new" : "data"
        const index = dataStore[this.model.meta.id][source].findIndex(doc => doc.docData.ref === this.docData.ref)
        if (index > -1) {
            dataStore[this.model.meta.id][source][index] = this
        } else {
            dataStore[this.model.meta.id][source].push(this)
        }
        return { changes: Object.assign({}, this.docChanges), msg: msgs.join("\n") }
    }

    // duplicate(forceInsert){
    //     const cloneDoc = Object.assign({}, this.docData)
    //     cloneDoc.docID = uuid();
    //     cloneDoc.colRef = this.docData.colRef
    //     cloneDoc.ref = `${cloneSection.colRef}/${cloneSection.docID}`
    //     cloneDoc.title = `עותק של ${this.docData.title}`

    //     const builder = this.model
    //     const newDoc = new builder(cloneDoc)
    //     if(forceInsert){
    //         newDoc.remove()
    //         newDoc.insert(cloneDoc.colRef , true)
    //     }else{
    //         newDoc.save()
    //     }
    // }

    edit(changes = {}) {
        Object.entries(changes).map(([key, val]) => {
            if (!key in this.headers) throw `key ${key} not in headers`
            this.docChanges[key] = val
            this.docData[key] = val
        })
    }

    /** insert doc to firebase
    */
    insert(colPath, forceInsert = false) {
        if (!this.isNew && forceInsert !== true) throw "cannot add document that already exist"
        const timestamp = +new Date()
        console.time(`INSERT ⏲ ${colPath} - ${timestamp}`)
        const dataToAdd = buildInsertDoc(this.docData)
        db.collection(colPath)
            .doc(this.docData.docID).set(dataToAdd)
            .then(res => {
                this.docChanges = {}
                this.isNew = false
                arrangeBreadCrumbs();
                this.listenToChildren()
            })
            .catch(err => { console.error("INSERT error: ", err) })
            .finally(() => {
                console.timeEnd(`INSERT ⏲ ${colPath} - ${timestamp}`)
                m.redraw()
            })
    }
    /** insert doc to firebase
    */
    batchInsert(batch) {
        if (!batch) throw "must provide batch to Doc.batchInsert()"
        try {
            const timestamp = +new Date()
            const colPath = this.docData.colRef
            console.time(`INSERT-batch ⏲ ${colPath} - ${timestamp}`)
            const dataToAdd = buildInsertDoc(this.docData)
            const docRef = db.collection(colPath).doc(this.docData.docID)
            batch.set(docRef, dataToAdd)
            this.docChanges = {}
            this.isNew = false
            arrangeBreadCrumbs();
            this.listenToChildren()
            console.timeEnd(`INSERT-batch ⏲ ${colPath} - ${timestamp}`)
        } catch (err) {
            console.error("Error on Doc.batchInsert ", err);
        }
    }

    async saveOne(header, value) {
        try {
            if (this.isNew) return
            else {
                if (this.hasChanges(header)) {
                    console.time(`SAVE-one-${header} ⏲ ${this.docData.ref}`)
                    const dataToSave = Object.assign({},
                        { [header]: value },
                        {
                            lastUpdate: new Date().toISOString(),
                            lastUpdatedBy: firebase.auth().currentUser.uid
                        })
                    await db.doc(this.docData.ref).update(dataToSave)
                    console.log('update one success!')
                    delete this.docChanges[header]
                    console.time(`SAVE-one-${header} ⏲ ${this.docData.ref}`)
                }
            }
        } catch (err) {
            console.error("Error on Doc.saveOne ", err);
        }
    }
    /** update docChanges to firebase
    */
    save() {
        const path = this.docData.colRef
        if (this.isNew) return this.insert(path)
        else {
            if (this.hasChanges()) {
                console.time(`SAVE ⏲ ${path}`)

                const dataToSave = Object.assign({},
                    this.docChanges,
                    {
                        lastUpdate: new Date().toISOString(),
                        lastUpdatedBy: firebase.auth().currentUser.uid
                    })
                Object.entries(internalFields).forEach(([_k, key]) => delete dataToSave[key])
                Object.keys(dataToSave).forEach(key => {
                    if (key.endsWith("__old")) delete dataToSave[key]
                })
                db.collection(path).doc(this.docData.docID).update(dataToSave)
                    .then(res => {
                        console.log('update success!')
                        arrangeBreadCrumbs()
                        this.docChanges = {}
                    })
                    .catch(err => { console.error(err) })
                    .finally(() => {
                        console.timeEnd(`SAVE ⏲ ${path}`)
                        m.redraw()
                    })
            }
        }
    }
    batchSave(batch) {
        if (!batch) throw "must provide batch to Doc.batchSave()"
        const path = this.docData.colRef
        try {
            if (this.isNew) return this.batchInsert(batch)
            else {
                if (this.hasChanges()) {
                    console.time(`SAVE-batch ⏲ ${path}`)
                    const dataToSave = Object.assign({},
                        this.docChanges,
                        {
                            lastUpdate: new Date().toISOString(),
                            lastUpdatedBy: firebase.auth().currentUser.uid
                        })
                    Object.entries(internalFields).forEach(([_k, key]) => delete dataToSave[key])
                    Object.keys(dataToSave).forEach(key => {
                        if (key.endsWith("__old")) delete dataToSave[key]
                    })
                    const docRef = db.collection(path).doc(this.docData.docID)
                    batch.update(docRef, dataToSave)
                    console.log('update success!')
                    arrangeBreadCrumbs()
                    this.docChanges = {}
                    console.timeEnd(`SAVE-batch ⏲ ${path}`)
                }
            }
        } catch (err) {
            console.error("Error on Doc.batchSave ", err);
        }
    }

    batchRemove(batch) {
        if (!batch) throw "must provide batch to Doc.batchRemove()"
        try {
            if (isSuperAdmin()) {
                const docPath = `${this.docData.ref}`
                console.time(`DELETE (admin) ⏲ ${docPath}`)
                batch.delete(db.doc(docPath))
                this.docChanges = {}
                arrangeBreadCrumbs()
                console.log('deleted successfully!')
                console.timeEnd(`DELETE (admin) ⏲ ${docPath}`)
            } else {
                const docPath = `${this.docData.ref}`
                console.time(`ARCHIVE ⏲ ${docPath}`)
                const dataToArchive = Object.assign({},
                    this.docData,
                    {
                        isActive: false,
                        archivedAt: new Date().toISOString(),
                        archivedBy: firebase.auth().currentUser.uid
                    })
                batch.set(db.doc(docPath), dataToArchive, { merge: true })
                this.docChanges = {}
                arrangeBreadCrumbs()
                console.log('archived successfully!')
                console.timeEnd(`ARCHIVE ⏲ ${docPath}`)
            }
        } catch (err) {
            console.error("Error on Doc.batchRemove ", err);
        }
    }

    /**
     * 
     */
    remove() {
        if (this.isNew) {
            // removeLocaly from <new>Array
            // console.log("remove locally only");
            const newIndex = dataStore[this.model.meta.id].new.findIndex(doc => doc.docData.ref === this.docData.ref)
            if (newIndex > -1) dataStore[this.model.meta.id].new.splice(newIndex, 1)
            this.docChanges = {}
            m.redraw()
        } else {

            // remove from <data>Array: dataLIstener should take care for this
            // const dataIndex = dataStore[this.model.meta.id].data.findIndex(doc => doc.docData.docID === this.docData.docID)
            // if (dataIndex > -1) dataStore[this.model.meta.id].data.splice(dataIndex, 1)

            if (isSuperAdmin()) {
                const docPath = `${this.docData.ref}`
                //delete from db + add to archive
                console.time(`DELETE (admin) ⏲ ${docPath}`)
                db.doc(docPath).delete()
                    .then(res => {
                        this.docChanges = {}
                        arrangeBreadCrumbs()
                        console.log('deleted successfully!')
                    })
                    .catch(err => { console.error(err) })
                    .finally(() => {
                        console.timeEnd(`DELETE (admin) ⏲ ${docPath}`)
                        m.redraw()
                    })
            } else {
                const docPath = `${this.docData.ref}`
                //delete from db + add to archive
                console.time(`ARCHIVE ⏲ ${docPath}`)
                const dataToArchive = Object.assign({},
                    this.docData,
                    {
                        isActive: false,
                        archivedAt: new Date().toISOString(),
                        archivedBy: firebase.auth().currentUser.uid
                    })
                db.doc(docPath).set(dataToArchive, { merge: true })
                    .then(res => {
                        this.docChanges = {}
                        arrangeBreadCrumbs()
                        console.log('archived successfully!')
                    })
                    .catch(err => { console.error(err) })
                    .finally(() => {
                        console.timeEnd(`ARCHIVE ⏲ ${docPath}`)
                        m.redraw()
                    })
            }
        }

        //ANOTHER WAY: 
        // const docPath = `${this.model.meta.routes.collection}/${this.docData.docID}`
        // const archivePath = `/archive/any${docPath}`;
        // if (this.isNew) {
        //     // removeLocaly
        //     console.log("remove locally only");
        //     const newIndex = dataStore[this.model.meta.id].new.findIndex(doc => doc.docData.ref === this.docData.ref)
        //     dataStore[this.model.meta.id].new.splice(newIndex, 1)
        //     this.docChanges = {}
        // } else {
        //     //delete from db + add to archive
        //     console.time(`DELETE ⏲ ${docPath}`)
        //     const dataToArchive = Object.assign({},
        //         this.docData,
        //         {
        //             archivedAt: new Date().toISOString(),
        //             archivedBy: firebase.auth().currentUser.uid
        //         })
        //     Object.entries(internalFields).forEach(([_k, _v]) => delete dataToArchive[_v])
        //     db.doc(archivePath).set(dataToArchive, { merge: true })
        //         .then(() => db.doc(docPath).delete())
        //         .then(res => {
        //             this.docChanges = {}
        //             this.isNew = false
        //             console.log('removed successfully!')
        //         })
        //         .catch(err => { console.error(err) })
        //         .finally(() => {
        //             console.timeEnd(`DELETE ⏲ ${docPath}`)
        //             // m.redraw()
        //         })
        // }
    }
}