import m from "mithril"

import { CURRENCY, NUMBER, STRING } from "../../../utils/constants/types"

import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots"
import { Card } from "../../commons/cardLayout/Card"

import { getDisplayValue } from "../../../data/utils/inputValidation"

export const TableReport = vnode => {
    return {
        view: vnode => {
            const { load, collectionData, reportHeaders, mainDoc, instance, title } = vnode.attrs
            return m(Card, { class: "tableCard" },
                title && m(".caption", title),
                m(".table",
                    //HEADERS
                    m(".table__row.table__headers", { style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);` },
                        Object.entries(reportHeaders).map(([header, { label }]) => m(".table__cell", label))
                    ),
                    load && m(InlineLoaderDots),
                    //DATA
                    collectionData.map(doc => {
                        const styleColumns = `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`
                        return m(".table__row", { style: styleColumns, key: doc.docData.ref },
                            Object.entries(reportHeaders).map(([header, { val, type = STRING, options = {}, href = "#", prompt }]) => {
                                return m(`${href ? "a" : "div"}.table__cell`, {
                                    class: href ? "link" : "",
                                    href,
                                    target: "_blank",
                                    "data-header": header,
                                    onclick: e => {
                                        if (prompt) {

                                        }
                                    }
                                },
                                    getDisplayValue(val(doc, mainDoc).value, type, options)
                                )
                            })
                        )
                    }),
                    //TOTALS row
                    (collectionData.length > 0 && instance && !load) && m(".table__totals", {
                        style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`,
                    },
                        Object.entries(reportHeaders).map(([header, fieldOptions]) => {
                            if (header === "title") {
                                return m(".total", `${getDisplayValue(collectionData.length, NUMBER)} שורות`)
                            }
                            if (fieldOptions.totals) {
                                console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                                const totalValue = fieldOptions.totals.val(collectionData, mainDoc)
                                return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                            }
                            if (fieldOptions.type === NUMBER || fieldOptions.type === CURRENCY) {
                                let total = 0
                                if (fieldOptions.val) {
                                    total = collectionData.reduce((acc, doc) => {
                                        return acc += parseFloat(fieldOptions.val(doc, mainDoc).value)
                                    }, 0)
                                } else {
                                    total = ""
                                }
                                return m(".total", getDisplayValue(total, fieldOptions.type, fieldOptions))
                            }
                            return m(".total", "---")
                        })
                    )
                )//END table
            ) //END tableCard
        }
    }
}