import m from "mithril"
import { SWITCH, DATE, STRING, NUMBER, PERCENT, CURRENCY, VALID_CALC, DIRECTORY, LIST } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, MIN, MAX, READONLY, DISABLED, STEP, HIDDEN } from "../../../utils/constants/inputAttrs";
import { ACTUALS, APARTMENTS, CONTRACT_SECTIONS, SECTION_MILESTONES, VENDORS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";
import { sortDocsBy, uuid } from "../../../utils/js";
import { db } from "../../../index";
import { MilestoneDoc } from "./milestoneClass";
import { buildInsertDoc } from "../../CRUD/utils";
import { O_FUNCTION } from "../../../utils/constants/objTypes";
import { VendorDoc } from "../vendors/vendorClass";


export const MAX_PAUSCHAL_ITEMS_ALLOWED = 100
const coreFields = ["calculationMethod", "calculationType", "itemsStartIndex", "itemPrice"]

export class SectionDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: SectionDoc,
        });
        if (!this.isNew) this.listenToChildren()
    };

    async hasActualsConflict() {
        let detectActualsChanges = false
        if (coreFields.some(el => el in this.docChanges)) {
            const actualsCollection = await db.collectionGroup(ACTUALS)
                .where("isActive", "==", true)
                .where("sectionRef", ">=", this.docData.ref)
                .where("sectionRef", "<=", this.docData.ref + '\uf8ff')
                .get();
            console.log("%cTHINK:", "background-color:#c62168;color:#f0f0f0;", actualsCollection.docs.map(doc => {
                return {
                    title: doc.data().title,
                    ref: doc.data().ref,
                    sectionRef: doc.data().sectionRef
                }
            }));
            detectActualsChanges = actualsCollection.size > 0
        }
        return detectActualsChanges
    }

    abortConflicts() {
        coreFields.forEach(field => this.abortChanges(field))
    }

    async save(forceSave = false) {
        const detectActualsChanges = !forceSave && await this.hasActualsConflict()
        if (detectActualsChanges) {
            window.alert("לא ניתן לערוך שינויי ליבה בסעיף שיש בו ביצוע")
        } else {
            super.save()
        }
    }

    saveLocal(...params) {
        //if has actuals do not allow changes
        super.saveLocal(...params)
        if ("title" in this.docChanges) {
            this.autoTitleIndexing()
        }
        if ("itemPrice" in this.docChanges && !this.isPercent() && !this.isNew) {
            this.setMilestonePrice()
        }
    }

    setMilestonePrice() {
        const milestones = this.getChildren(SECTION_MILESTONES)
        if (milestones.length === 1) {
            milestones[0].docData.price = this.docData.itemPrice
            milestones[0].docChanges.price = this.docData.itemPrice
        } else {
            milestones.forEach((ms, ind, arr) => {
                ms.docData.price = parseFloat(this.docData.itemPrice) / arr.length
                ms.docChanges.price = parseFloat(this.docData.itemPrice) / arr.length
            })
        }
    }

    abortActualChanges({ accountRef, unit } = {}) {
        const actuals = this.getActuals({ accountRef, unit })
        actuals.forEach(act => {
            if (act.hasChanges()) {
                act.abortChanges()
            }
        })
        m.redraw()
    }

    /**
     * get only workspacePath scope sections
     * @override 
     */
    getSiblings() {
        return super.getSiblings({ include: { workspacePath: this.docData.workspacePath } })
    }

    getWorkspaceRef() {
        const contractRef = this.docData.ref.split("/").slice(0, 4).join("/")
        const workspacePathPart = this.docData.workspacePath.split("/").slice(0, 2).join("/")
        return `${contractRef}/${workspacePathPart}`
    }
    getWorkspaceDoc() {
        return DocModel.getDoc(this.getWorkspaceRef())
    }

    getWorkspaceBuildings() {
        const wsDoc = this.getWorkspaceDoc()
        if (wsDoc && wsDoc.docData.buildingRef && Array.isArray(wsDoc.docData.buildingRef)) return wsDoc.docData.buildingRef
        return []
    }


    /**
     * @override remove => remove default milestone
     */
    async remove(path) {
        try {
            await Promise.resolve(super.remove(path))
        } catch (err) {
            console.error("error on insert section ", err);
        }
        this.setMilestonePrice()
    }
    /**
     * @override insert => insert default milestone
     */
    async insert(path) {
        try {
            const batch = db.batch()
            this.batchInsert(batch)
            const colRef = `${this.docData.ref}/${SECTION_MILESTONES}`
            const msData = { colRef }
            if (!this.isPercent()) {
                console.log(this)
                msData.price = this.docData.itemPrice
                console.log("TESTME: auto add price to ms as in section: ", this.docData.itemPrice);
            }
            const ms = new MilestoneDoc(msData);
            ms.batchInsert(batch)
            await batch.commit()
        } catch (err) {
            console.error("error on insert section -> milestone: ", err)
        }
    }

    async duplicate({ workspacePath, docID } = {}) {
        const cloneSection = Object.assign({}, this.docData)
        cloneSection.docID = docID || uuid();
        cloneSection.colRef = this.docData.colRef
        cloneSection.ref = `${cloneSection.colRef}/${cloneSection.docID}`
        cloneSection.title = `עותק של ${this.docData.title}`
        cloneSection.workspacePath = workspacePath ? workspacePath : this.docData.workspacePath
        delete cloneSection.index // use default for this
        try {
            const batch = db.batch()
            const newSection = new SectionDoc(cloneSection, true)
            const sectionRef = db.doc(cloneSection.ref)
            batch.set(sectionRef, buildInsertDoc(newSection.docData), { merge: true })
            this.getChildren(SECTION_MILESTONES).forEach(ms => {
                const cloneMsData = ms.cloneDocData({ clone: true, newParentRef: cloneSection.ref })
                const msRef = db.doc(cloneMsData.ref)
                batch.set(msRef, buildInsertDoc(cloneMsData), { merge: true })
            })
            await batch.commit()
            m.redraw()
        } catch (err) {
            throw err
        }
    }

    isInWorkspace(wsID) {
        const [_ws, _wsID, _gr, _groupID] = this.docData.workspacePath.split("/")
        // console.log("wsID === workspaceDoc.docData.docID ? ", wsID === workspaceDoc.docData.docID, " \nwsID= ", wsID, " \nworkspaceDoc.docData.docID= ", workspaceDoc.docData.docID)
        return _wsID === wsID
    }

    isInGroup(group) {
        const [_ws, _wsID, _gr, _groupID] = this.docData.workspacePath.split("/")
        // console.log("wsID === workspaceDoc.docData.docID ? ", _groupID === workspaceDoc.docData.docID, " \n_groupID= ", _groupID, " \nworkspaceDoc.docData.docID= ", workspaceDoc.docData.docID)
        return group && _groupID === group.docData.docID
    }

    hasActuals() {
        const actuals = this.getActuals()
        return actuals.length > 0
    }

    //ACTUALS
    getActuals({ accountRef, filterOptions = {} } = {}) {
        const { exclude, include } = filterOptions
        const excludeFilter = exclude ? Object.entries(exclude) : false
        const includeFilter = include ? Object.entries(include) : false
        const includeFunction = (doc, param, value) => {
            if (objType(value) === O_FUNCTION) {
                return value(doc)
            }
            return doc.docData[param] === value
        }
        return [...dataStore[ACTUALS].data, ...dataStore[ACTUALS].new]
            .filter(doc => {
                return doc.docData.sectionRef.startsWith(this.docData.ref) &&
                    (accountRef ? doc.docData.colRef === `${accountRef}/${ACTUALS}` : true) &&
                    (includeFilter ? includeFilter.every(([param, value]) => includeFunction(doc, param, value)) : true) &&
                    (excludeFilter ? excludeFilter.every(([param, value]) => doc.docData[param] !== value) : true)
            })
    }
    getLastActuals({ accountRef } = {}) {
        if (!accountRef) throw `cannot call Section.getLastActuals() without accountRef`
        return [...dataStore[ACTUALS].data, ...dataStore[ACTUALS].new]
            .filter(actual => actual.docData.sectionRef.startsWith(this.docData.ref) && actual.docData.colRef !== `${accountRef}/${ACTUALS}`)
    }


    getTotalActuals({ accountRef } = {}) {
        let result = 0
        this.getActuals({ accountRef }).forEach(act => {
            result += parseFloat(act.docData.value)
        })
        return result
    }

    calcTotalActuals({ accountRef, forceCalc = false } = {}) {
        let total = 0
        this.getActuals({ accountRef }).forEach(act => {
            if (act.hasChanges() || forceCalc) {
                const { currentTotal, calc } = act.calcSubTotal(true, this)
                total += currentTotal
            } else {
                total += parseFloat(act.docData.currentTotal)
            }
        })
        return total
    }
    calcLastTotalActuals({ accountRef, unit } = {}) {
        if (!accountRef) throw "must contain accountRef"
        let total = 0
        this.getLastActuals({ accountRef }).forEach(act => {
            if (act.hasChanges()) {
                const { currentTotal, calc } = act.calcSubTotal(true, this)
                total += currentTotal
            } else {
                total += parseFloat(act.docData.currentTotal)
            }
        })
        return total
    }

    autoTitleIndexing() {
        const title = this.docData.title
        const regex = new RegExp(/[a-zA-Zא-ת]+\s*(\d+)-\s*(\d+)/, "g")
        const reg = regex.exec(title)
        if (reg !== null) {
            const [input, start, end] = reg
            // console.log({ input, start, end })
            const from = parseInt(start)
            const total = parseInt(end) - from + 1

            this.docData.itemsStartIndex = from
            this.docChanges.itemsStartIndex = from

            this.docData.itemsCount = total
            this.docChanges.itemsCount = total
        }
    }
    isPauschal() {
        return this.docData.calculationMethod === "pauschal"
    }
    isApartment() {
        return this.docData.calculationMethod === "apartments"
    }
    isPercent() {
        return this.docData.calculationType === 'percent'
    }
    getApartments() {
        const contract = this.getParent()
        return contract.getParent().getChildren(APARTMENTS, { include: { contractsRefs: doc => doc.docData.contractsRefs.includes(this.docData.ref) } })
    }

    buildVirtualUnits() {
        let startIndex = this.docData.itemsStartIndex
        if (startIndex === undefined) startIndex = 1
        const units = this.docData.itemsCount || 1
        const isPauschal = this.isPauschal()
        const startFrom = parseInt(startIndex)
        const totalUnits = parseInt(units) + startFrom
        const arr = []
        if (isPauschal) {
            for (let i = startFrom; i < totalUnits; i++) {
                arr.push(Number(i))
            }
        } else { //if not pauschal should be only one unit
            arr.push(1)
        }
        return arr
    }


    static get meta() {
        return {
            id: CONTRACT_SECTIONS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/sections",
                doc: "/projects/:projectID/contracts/:contractID/sections/:sectionID",
            },
            logic: [
                { type: VALID_CALC, target: "totalSum", expression: "itemsCount * itemPrice", trigger: ["itemsCount", "itemPrice"] },
            ],

        }
    }
    static get headers() {
        return {
            title: { label: "סעיף", type: STRING, defaultValue: "[סעיף חדש]", props: [{ [REQUIRED]: true }] },
            index: { label: "מספר סעיף", type: NUMBER, defaultValue: 1 },
            description: { label: 'תאור', defaultValue: "--ללא תיאור--", type: STRING },
            calculationMethod: { label: 'שיטת חישוב', defaultValue: "pauschal", type: SWITCH, options: [{ text: 'פאושלי', id: 'pauschal' }, { text: 'כמותי', id: 'amount' }, { text: "דירות", id: "apartments" }], props: [{ [DISABLED]: doc => doc.hasActuals() }] },
            calculationType: { label: 'סוג אבן דרך', defaultValue: "percent", type: SWITCH, options: [{ text: 'אחוזים', id: 'percent' }, { text: 'סכומים', id: 'number' }], props: [{ [DISABLED]: doc => doc.hasActuals() }] },
            amountType: { label: "סוג יח'", type: STRING, defaultValue: "יח" },
            itemsCount: {
                label: "כמות יחידות", type: NUMBER, defaultValue: 0,
                props: [
                    { [REQUIRED]: true },
                    { [MIN]: 0 },
                    {
                        [MAX]: doc => {
                            if (doc.isPauschal()) return MAX_PAUSCHAL_ITEMS_ALLOWED
                            else return "none"
                        }
                    },
                ]
            },
            contractorRef: {
                label: 'קבלן מבצע',
                defaultValue: "",
                type: LIST,
                options: {
                    data: dataStore[VENDORS].data.sort(sortDocsBy("title", { type: STRING })),
                    load: () => dataStore[VENDORS].listen(),
                    model: VendorDoc,
                    addOnFlow: true,
                    addDataOptions: {
                        colRef: VENDORS,
                    },
                    formHeaders: ["title", "commercialName", "companyNumber", "companyType", "contractorSAPNumber", "phone", "email", "taxPercent", "taxesEndDate", "address"]
                },
                props: [{
                    [HIDDEN]: doc => {
                        if (doc.getParent("calculationMethod") === "oddJobs") return false
                        return true
                    }
                }]
            },
            itemsStartIndex: { label: "אינדקס יחידה", type: NUMBER, defaultValue: 1, props: [{ [REQUIRED]: true }, { [DISABLED]: doc => doc.hasActuals() }, { [MIN]: 0 }] }, //hasActuals=>true[disabled-true]
            itemPrice: { label: "מחיר יח'", type: CURRENCY, defaultValue: 0, props: [{ [REQUIRED]: true }, { [MIN]: 0 }, { [DISABLED]: doc => doc.hasActuals() }] },
            totalSum: { label: "סכום הסכם", type: CURRENCY, defaultValue: 0, validation: [{ expression: "itemsCount * itemPrice" }], totals: "sum", props: [{ [STEP]: "1" }, { [MIN]: 0 }, { [DISABLED]: true }] },

            //THINK: donePercentage? calc by actuals

            subTotal: { label: "סכום ביצוע", defaultValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },
            donePercentage: { label: "אחוז ביצוע", defaultValue: 0, type: PERCENT, props: [{ [STEP]: "any" }, { [MIN]: 0 }, { [MAX]: 100 }, { [DISABLED]: true }] },
            workspacePath: { label: "מיקום בתיקייה", defaultValue: "workspaces/default/groups/default", type: DIRECTORY },
        }
    }
}
