import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';
import { Card } from '../../commons/cardLayout/Card';

//DATA
import { FILES, USERS } from '../../../data/dictionary/routeNames';
import { UserDoc } from '../../../data/store/users/userClass';
import { dataStore } from '../../../data/store/dataStore';

// FUNCTIONS
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { InputCell } from '../../commons/inputCell/InputCell';
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots';
import { auth } from '../../../index';
import { arrangeBreadCrumbs, handleCopy, toggleTimeout } from '../../../data/utils/utils';
import { REG_PASSWORD_PATTERN, REG_EMAIL_PATTERN } from '../../../utils/constants/regex';
import { PASSWORD } from '../../../utils/constants/types';
import { Icon } from '../../components/icon/Icon';
import { isSystemMode } from '../../../data/utils/viewUtils';
import { settingsStore } from '../../../data/store/settingsStore';
import { isFieldEditable } from '../../../data/utils/permission';
import { DisplayCell } from '../../commons/inputCell/DisplayCell';
import { FilesUpload } from '../../commons/fileUpload/FilesUpload';
import { MIME_TYPES } from '../../../utils/constants/mimeTypes';
import { IconError } from '../../commons/iconError/IconError';

export const User = node => {

    const findUser = () => {
        const data = [].concat(dataStore[USERS].data, dataStore[USERS].new)
        return data.find(doc => doc.docData.docID === node.attrs.userID)
    }

    const handleChange = () => {
        const { title, msg, actions: actionsDict } = settingsStore.dictionaryUI[USERS].change
        node.state.prompt = {
            title,
            msg,
            actions: [
                {
                    //DO
                    key: "do",
                    text: actionsDict.do.text || "שמור",
                    action: e => {
                        node.state.snackbar = { msg: "שומר..." };
                        node.state.user.save();
                        node.state.prompt = false
                        node.state.snackbar = { msg: actionsDict.do.msg };
                    },
                },
                {
                    //UNDO
                    key: "undo",
                    color: "gray",
                    text: actionsDict.undo.text || "ביטול",
                    action: e => node.state.prompt = false
                }
            ]
        }
    }
    //TODO:refactor
    const confirmAddUser = () => {
        node.state.prompt = {
            title: "יצירת משתמש",
            msg: "האם ליצור משתמש חדש ?",
            actions: [
                {
                    text: "צור",
                    action: e => {
                        node.state.snackbar = { msg: "יוצר משתמש..." };
                        try {
                            node.state.user.insert(node.state.newPassword, true);
                        } catch (err) {
                            node.state.snackbar = { msg: `אירעה שגיאה בהוספת המשתמש \n תיאור: ${err}`, isError: true };
                        }
                        node.state.snackbar = { msg: "משתמש חדש נוצר בהצלחה" };
                        node.state.prompt = false
                        if (node.state.settings.navOnAdd) {
                            m.route.set(`/admin/users`)
                        }
                        m.redraw()
                    },
                }, {
                    text: "בטל",
                    color: "gray",
                    action: e => node.state.prompt = false
                }
            ]
        }
    }


    const handleResetPassword = () => {
        node.state.prompt = {
            title: "איפוס סיסמא",
            msg: "האם אתה בטוח כי ברצונך לאפס סיסמא!",
            actions: [
                {
                    text: "אפס",
                    action: e => {
                        node.state.snackbar = { msg: "שומר..." };
                        try {
                            node.state.user.updatePassword(node.state.newPassword)
                            node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                        } catch (err) {
                            node.state.snackbar = { msg: `חלה שגיאה בשמירת הנתונים  , ${err}`, isError: true };
                        }
                        node.state.prompt = false
                        // m.redraw()
                    },
                },
                {
                    text: "בטל",
                    color: "gray",
                    action: e => node.state.prompt = false
                }
            ],
        }
    }


    const headers = [
        "displayName",
        "email",
        "title",
        "phone",
        "dob",
        "address",
        // "photoURL",
        "role",
        "status",
    ]

    const isValidEmail = () => {
        return node.state.user &&
            node.state.user.docData.email !== "" &&
            node.state.user.docData.email.match(REG_EMAIL_PATTERN) !== null
    }
    const isValidPassword = () => {
        return node.state.newPassword.length > 6 &&
            node.state.newPassword === node.state.confirmNewPassword &&
            node.state.newPassword.match(REG_PASSWORD_PATTERN) !== null
    }
    const toggleShowPass = pass => {
        if (node.state.showPass === pass) {
            node.state.showPass = false
        } else {
            node.state.showPass = pass
        }
    }

    const passwordInput = (passwordKey, label) => {
        return m(".input password", [
            m("input.input__field [minlength=7]", {
                value: node.state[passwordKey],
                type: node.state.showPass === passwordKey ? "text" : PASSWORD,
                value: node.state[passwordKey],
                oninput: e => node.state[passwordKey] = e.target.value
            }),
            m("label.input__label", label),
            m(Icon, {
                class: "show",
                icon: node.state.showPass === passwordKey ? "icon-eye-off" : "icon-eye",
                action: e => { e.preventDefault(); toggleShowPass(passwordKey) }
            })
        ])
    }

    const isSelfUser = () => {
        const user = node.state.user
        const userID = node.attrs.userID
        return toggleTimeout(auth.currentUser === null, () => {
            const authUserID = auth.currentUser.uid
            return user && authUserID == userID
        })
    }

    return {
        load: true,
        prompt: false,
        snackbar: false,
        newPassword: "",
        confirmNewPassword: "",
        showPass: false,
        settings: { navOnAdd: true },
        oninit: vnode => {
            arrangeBreadCrumbs()
            const { alreadyListen: isListenToUsers, unsubscribe: unsubscribeUsers } = dataStore[USERS].listen();
            if (isListenToUsers) {
                vnode.state.load = false
                toggleTimeout(auth.currentUser === null, () => {
                    vnode.state.user = findUser();
                    if (!vnode.state.user) m.route.set("/admin/users");
                    m.redraw()
                })
            } else {
                vnode.state.snackbar = { msg: "טוען..." }
                setTimeout(() => {
                    vnode.state.user = findUser();
                    if (!vnode.state.user) m.route.set("/admin/users");
                    vnode.state.load = false;
                    m.redraw()
                }, 3000)
            }
        },
        view: vnode => {
            return (
                m(PageLayout, { class: "user", title: "פרטי משתמש" },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    m(Card, { class: "formCard user__details" },
                        // (vnode.state.user && vnode.state.user.docData.photoURL !== "") && m("img.user__img", { src: vnode.state.user.docData.photoURL }),
                        m("h3.caption formCard__caption", "פרטי המשתמש"),
                        vnode.state.load && m(InlineLoaderDots),
                        (vnode.state.user && vnode.state.user.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                        vnode.state.user && headers.map((header, index) => {
                            const docData = vnode.state.user.docData
                            const value = docData[header]
                            const field = UserDoc.headers[header]
                            if (isFieldEditable(field)) {
                                return m(InputCell, {
                                    header, value, field, index, id: `user__${index}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.user,
                                    parent: vnode
                                })
                            } else {
                                return m(DisplayCell, { header, value, field, doc: vnode.state.user })
                            }
                        }),
                        (vnode.state.user && vnode.state.user.isNew) && [
                            m(".caption", "יצירת סיסמא"),
                            passwordInput("newPassword", "סיסמא חדשה"),
                            vnode.state.newPassword.length >= 7 && passwordInput("confirmNewPassword", "ווידוא סיסמא"),
                        ],
                        (vnode.state.user && vnode.state.user.hasChanges()) && m(".formCard__actions",
                            vnode.state.user.isNew && isValidPassword() && isValidEmail() && m("button.button formCard__save", { onclick: e => confirmAddUser() }, "צור משתמש"),
                            (!vnode.state.user.isNew) && m("button.button formCard__save", { onclick: e => handleChange() }, "שמור שינויים"),
                            m("button.button formCard__abort", { onclick: e => vnode.state.user.abortChanges() }, "בטל שינויים"),
                        )
                    ),
                    vnode.state.user && m(Card,
                        m(FilesUpload, {
                            title: "תמונת פרופיל",
                            multiple: false,
                            accept: [MIME_TYPES.IMAGE],
                            formHeaders: ["title", "createdAt", "createdBy"],
                            addDataOptions: { isUserPhoto: true },
                            colRef: `${vnode.state.user.docData.ref}/${FILES}`,
                            modelID: FILES,
                            doc: vnode.state.user
                        }),
                    ),
                    isSelfUser() &&
                    m(Card, { class: "formCard user__actions" },
                        m(".caption", "איפוס סיסמא"),
                        passwordInput("newPassword", "סיסמא חדשה"),
                        vnode.state.newPassword.length >= 7 && passwordInput("confirmNewPassword", "ווידוא סיסמא"),
                        isValidPassword() && m("button.button", { onclick: e => handleResetPassword() }, "איפוס סיסמא"),
                    )
                )
            )
        }
    }
}
