import m from "mithril"

//DATA
import { MilestoneDoc } from "../../../../data/store/contracts/milestoneClass"
import { CURRENCY, PERCENT, NUMBER } from "../../../../utils/constants/types"

//FUNCTIONS
import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { isValidTotal, showTotalSectionPlan, confirmSectionChanges, getTotalMilestones, setItemPrice } from "../utils"

//COMPONENTS
import { Card } from "../../../commons/cardLayout/Card"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { InputCell } from "../../../commons/inputCell/InputCell"
import { Icon } from "../../../components/icon/Icon"
import { IconError } from "../../../commons/iconError/IconError"
import { SectionDoc } from "../../../../data/store/contracts/sectionClass"
import { objType, preventDefaults, sortDocsBy, uuid } from "../../../../utils/js"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { CONTRACT_SECTIONS, SECTION_MILESTONES } from "../../../../data/dictionary/routeNames"
import { isUserAllow } from "../../../../data/utils/permission"
import { CREATE, REMOVE, UPDATE } from "../../../../data/store/permissionStore"
import { DisplayCell } from "../../../commons/inputCell/DisplayCell"
import { DEFAULT_USER_NOT_ALLOW_MSG } from "../../../../data/store/settingsStore"
import { Tooltip } from "../../../components/tooltip/Tooltip"
import { O_FUNCTION } from "../../../../utils/constants/objTypes"
import { REG_URL_LAST_PART } from "../../../../utils/constants/regex"

export const SectionPlan = node => {

    //SECTION HANDLER
    const moveSectionIndex = (section, replaceWith) => {
        toggleCollaspeSection(section.docData.docID)
        Promise.resolve(section.setIndex(replaceWith)).then(() => {
            m.redraw()
        }).catch(err => console.error(err))
    }

    const handleCopySection = (e, section, milestones) => {
        if (!isUserAllow(CONTRACT_SECTIONS, CREATE))
            return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        e.stopPropagation();
        node.state.prompt = {
            title: "הוספת סעיף  - עותק",
            msg: `האם ליצור סעיף חדש עותק של ${section.docData.title} ?`,
            actions: [
                {
                    text: "שכפל",
                    action: async e => {
                        try {
                            const docID = uuid()
                            await Promise.resolve(section.duplicate({ docID }))
                            node.state.snackbar = { msg: "הסעיף שוכפל בהצלחה!" }
                            let navTo = `/app/${section.docData.ref.replace(REG_URL_LAST_PART, "/" + docID)}`
                            if (m.route.get().includes("/replace")) navTo = navTo.replace("/replace", "")
                            else navTo += "/replace"
                            console.log(navTo)
                            m.route.set(navTo)
                        } catch (err) {
                            node.state.snackbar = { msg: "אירעה שגיאה: \nתיאור:\n" + err, isError: true }
                        } finally {
                            m.redraw()
                            node.state.prompt = false
                        }
                    }
                }
            ],
        }
    }

    const handleDeleteSection = (e, section) => {
        if (!isUserAllow(CONTRACT_SECTIONS, REMOVE)) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        e.stopPropagation();
        const contractRef = section.docData.ref.split("/").splice(0, 4).join("/")
        node.state.prompt = {
            title: "מחיקת סעיף",
            msg: `האם למחוק את הסעיף ${section.docData.title} ?`,
            // TODO:
            // actions: [
            //     {
            //         text: "",
            //         action:e=>{}
            //     },
            // ],
            actionText: "כן",
            action: e => {
                try {
                    section.remove()
                    node.state.snackbar = { msg: "הסעיף נמחק!" }
                    m.route.set(`/app/${contractRef}`)
                } catch (error) {
                    console.error(error)
                    node.state.snackbar = { msg: error, isError: true }
                }
                node.state.prompt = false
            }
        }
    }

    // MILESTONE | UNIT
    const addMilestone = (colRef) => {
        if (isUserAllow(CONTRACT_SECTIONS, CREATE)) {
            new MilestoneDoc({ colRef })
        } else {
            node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        }
    }

    const handleRemoveMilestone = (section, milestones, milestone) => {
        if (!isUserAllow(CONTRACT_SECTIONS, REMOVE))
            return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        node.state.prompt = {
            title: "מחיקת אבן דרך",
            msg: `האם למחוק את אבן הדרך ${milestone.docData.title}?`,
            actionText: "כן",
            action: e => {
                Promise.resolve(milestone.remove())
                    .then(() => {
                        if (!isValidTotal(section, milestones)) {
                            if (section.isPercent()) {
                                node.state.snackbar = { msg: "יש פחות מ100% אבני דרך , נא לעדכן בהתאם", isError: true }
                            } else {
                                node.state.snackbar = { msg: "יש חוסר התאמה בין סכום היחידה לסכום אבני הדרך , נא לעדכן בהתאם", isError: true }
                                setItemPrice(section, milestones)
                            }
                        } else {
                            node.state.snackbar = { msg: "אבן הדרך נמחקה!" }
                        }
                    })
                node.state.prompt = false
            }
        }
    }

    const addUnit = doc => {
        if (!isUserAllow(CONTRACT_SECTIONS, CREATE))
            return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        const oldUnit = doc.docData.itemsCount
        const newUnit = parseInt(oldUnit) + 1
        doc.saveLocal({ "itemsCount": newUnit })
    }

    //VIEW TYPES

    const toggleCollaspeSection = (sectionID) => {
        const ind = node.state.collaspeSection.indexOf(sectionID)
        if (ind > -1) node.state.collaspeSection.splice(ind, 1)
        else node.state.collaspeSection.push(sectionID)
    }
    const isCollaspeSection = sectionID => node.state.collaspeSection.includes(sectionID)

    // togglePreviewEdit [attrs]

    const milestoneHeaders = {
        unit: { label: "#", info: "יחידה" }
    }

    const sectionHeaders = [
        "title",
        "contractorRef",
        "calculationMethod",
        "calculationType",
        "amountType",
        "itemsCount",
        "itemsStartIndex",
        "itemPrice",
        "totalSum",
        "description",
        // "donePercentage",
    ]

    return {
        editCell: "",
        snackbar: false,
        prompt: false,
        collaspeSection: [],
        // oninit: vnode => {
        //     const { section } = vnode.attrs
        //     vnode.state.isPauschal = section.isPauschal()
        //     vnode.state.isPercent = section.isPercent()
        // },
        // onupdate: vnode => {
        //     const { section } = vnode.attrs
        //     vnode.state.isPauschal = section.isPauschal()
        //     vnode.state.isPercent = section.isPercent()
        // },
        view: vnode => {
            // const { isPauschal, isPercent } = vnode.state
            const { sectionIndex, wsIndex, section, sectionsArr, parentState } = vnode.attrs
            const isCollaspe = isCollaspeSection(section.docData.docID)
            const isPauschal = section.isPauschal()
            const isPercent = section.isPercent()
            const milestones = section.getChildren(SECTION_MILESTONES).sort(sortDocsBy("index", { type: NUMBER }))
            const totalMilesones = getTotalMilestones(section, milestones, isPercent)
            return [
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    ),
                    (vnode.state.prompt.action && objType(vnode.state.prompt.action) === O_FUNCTION) && m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                ),
                m(Card, {
                    // key: "section_" + sectionIndex,
                    class: "section",
                    id: section.docData.docID,
                    autofocus: "autofocus",
                    // tabindex: wsIndex + sectionIndex
                },
                    section.isNew && m(".section__flag flag flag--new", "לא נשמר עדיין"),
                    m(".caption", {
                        // onclick: e => toggleCollaspeSection(section.docData.docID),
                        // class: isCollaspe ? ".caption--collaspe" : ""
                    },
                        `${section.docData.title}`,
                        // m(Icon, { icon: `icon-triangle-${isCollaspe ? "up" : "down"}`, class: "icon-collaspe" })
                    ),
                    !isCollaspe && [
                        sectionHeaders.map((header, index) => {
                            if (header === "itemsStartIndex" && !isPauschal) return
                            // if (header === "itemPrice" && !isPercent) return
                            const settings = {}
                            const allowEdit = isUserAllow(CONTRACT_SECTIONS, UPDATE)
                            if (header === "calculationMethod" || header === "calculationType") settings.autoSave = true
                            const key = `${section.docData.ref}__${header}`
                            if (!allowEdit) {
                                return m(DisplayCell, {
                                    key,
                                    header,
                                    value: section.docData[header],
                                    field: SectionDoc.headers[header],
                                    doc: section,
                                    settings
                                })
                            } else {
                                return m(InputCell, {
                                    // id: key,
                                    key,
                                    // index: wsIndex + sectionIndex,
                                    header,
                                    doc: section,
                                    value: section.docData[header],
                                    field: SectionDoc.headers[header],
                                    editCell: vnode.state.editCell,
                                    parent: vnode,
                                    settings
                                })
                            }
                        }),
                        (parentState && parentState.loadMilestones[section.docData.ref] === true) ? m(InlineLoaderDots) :
                            (!section.isNew && milestones && milestones.length > 0) && m(".section__milestones", {
                                style: `grid-template-columns: repeat(${Object.keys(milestoneHeaders).length},min-content) repeat(${milestones.length},1fr) 1fr;`,
                                // key: uuid(),
                            },
                                //headers + titles + totalColumn
                                Object.entries(milestoneHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", header.label)),
                                milestones.map((milestone, msInd) => {
                                    const header = "title"
                                    const id = `${milestone.docData.ref}__${header}`
                                    const allowEdit = isUserAllow(CONTRACT_SECTIONS, UPDATE)
                                    const field = MilestoneDoc.headers[header]
                                    const value = milestone.docData[header]
                                    return m(".section__milestone section__milestone--title [data-tooltip]", {
                                        key: 'ms_title_' + msInd,
                                        draggable: allowEdit && true,
                                        ondragstart: e => {
                                            if (allowEdit) e.dataTransfer.setData("text", milestone.docData.ref);
                                        },
                                        ondragover: e => {
                                            if (allowEdit) {
                                                const msRef = e.dataTransfer.getData("text")
                                                if (milestone.docData.ref !== msRef) {
                                                    e.preventDefault()
                                                    e.currentTarget.style.border = "1px dashed gray"
                                                }
                                            }
                                        },
                                        ondragleave: e => {
                                            if (allowEdit) {
                                                const msRef = e.dataTransfer.getData("text")
                                                if (milestone.docData.ref !== msRef) {
                                                    e.currentTarget.style.border = "none"
                                                }
                                            }
                                        },
                                        ondragexit: e => {
                                            if (allowEdit) {
                                                const msRef = e.dataTransfer.getData("text")
                                                if (milestone.docData.ref !== msRef) {
                                                    e.currentTarget.style.border = "none"
                                                }
                                            }
                                        },
                                        ondrop: e => {
                                            if (allowEdit) {
                                                const msRef = e.dataTransfer.getData("text")
                                                if (milestone.docData.ref !== msRef) {
                                                    e.preventDefault()
                                                    e.currentTarget.style.border = "none"
                                                    milestone.setIndex(msRef)
                                                    // m.redraw()
                                                }
                                                e.dataTransfer.clearData();
                                            }
                                        },
                                    },
                                        m(Tooltip, { text: milestone.docData.title }),
                                        (milestones.length > 1 && allowEdit) && m(".action", { onclick: e => handleRemoveMilestone(section, milestones, milestone, isPauschal) }, m(Icon, { icon: "icon-delete" })),
                                        milestone.isNew && m(".flag flag--new", "חדש"),
                                        allowEdit && m(InputCell, {
                                            key: id,
                                            id, header,
                                            doc: milestone,
                                            field, value,
                                            editCell: vnode.state.editCell,
                                            parent: vnode,
                                            class: "section__milestone section__milestone--title",
                                            options: { ignoreLabel: true, tooltip: false }
                                        }),
                                        !allowEdit && m(DisplayCell, { header, key: id, field, value, doc: milestone, options: { ignoreLabel: true }, classStyle: "section__milestone section__milestone--title" })
                                    )
                                }),
                                m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"), //totalColumn

                                //weight | price row
                                m(".section__milestone section__milestone--unit", section.docData.itemsCount > 1 ? `X ${section.docData.itemsCount}` : "-"),
                                milestones.map((milestone, index) => {
                                    const header = isPercent ? "weight" : "price"
                                    return m(InputCell, {
                                        key: `${milestone.docData.ref}__${header}`,
                                        id: `${milestone.docData.ref}__weightPrice`,
                                        doc: milestone,
                                        header,
                                        editCell: vnode.state.editCell,
                                        parent: vnode,
                                        class: "section__milestone section__milestone--value",
                                        options: { ignoreLabel: true },
                                    })
                                    // const id = `${milestone.docData.ref}__weightPrice`
                                    // const allowEdit = isUserAllow(CONTRACT_SECTIONS, UPDATE)
                                    // const header = isPercent ? "weight" : "price"
                                    // const field = MilestoneDoc.headers[header]
                                    // const value = milestone.docData[header]
                                    // if (allowEdit) {
                                    //     return m(InputCell, {
                                    //         // id,
                                    //         key: id,
                                    //         doc: milestone,
                                    //         header,
                                    //         editCell: vnode.state.editCell,
                                    //         parent: vnode,
                                    //         class: "section__milestone section__milestone--value",
                                    //         options: { ignoreLabel: true },
                                    //     })
                                    // } else {
                                    //     return m(DisplayCell, { header, field, value, doc: milestone, options: { ignoreLabel: true }, classStyle: "section__milestone section__milestone--value" })
                                    // }
                                }),
                                m(".section__milestone section__milestone--total section__milestone--columnTotal", {
                                    "data-error": !isValidTotal(section, milestones, isPercent) ? "true" : "false"
                                },
                                    !isValidTotal(section, milestones, isPercent) && m(IconError, { error: 'חייב להגיע ל100%' }),
                                    getDisplayValue(totalMilesones, isPercent ? PERCENT : CURRENCY),
                                ),

                                //total rows
                                m(".section__milestone section__milestone--total", "סהכ"),
                                //TODO: create MilestoneDoc.calcTotalPlan()
                                milestones
                                    .map(ms => isPercent ? parseInt(ms.docData.weight) / 100 * section.docData.itemPrice : parseInt(ms.docData.price))
                                    .map((total, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, `${getDisplayValue(total, CURRENCY)}${section.docData.itemsCount > 1 ? ` / ${getDisplayValue(total * section.docData.itemsCount, CURRENCY)}` : ""}`)),

                                m(".section__milestone section__milestone--total section__milestone--columnTotal", showTotalSectionPlan(section)),
                            ),

                        (Object.keys(section.docChanges).length > 0 || milestones.some(ms => ms.hasChanges())) && m("button.button section__save", { onclick: e => confirmSectionChanges(vnode, section, milestones) }, "שמור שינויים"),
                        m(".section__actions",
                            m(".section__action", { onclick: e => handleDeleteSection(e, section) }, "מחק", m(Icon, { icon: "icon-delete" })),
                            m(".section__action", { onclick: e => handleCopySection(e, section, milestones) }, "שכפל", m(Icon, { icon: "icon-copy" })),
                            (!section.isNew) && [
                                // (sectionIndex > 0) && m(".section__action", { onclick: e => moveSectionIndex(section, sectionsArr[sectionIndex - 1].docData.ref) }, "למעלה", m(Icon, { icon: "icon-arrow-up" })),
                                // (sectionIndex < sectionsArr.length - 1) && m(".section__action", { onclick: e => moveSectionIndex(section, sectionsArr[sectionIndex + 1].docData.ref) }, "למטה", m(Icon, { icon: "icon-arrow-down" })),
                                m(".section__action", { onclick: e => addMilestone(`${section.docData.ref}/milestones`) }, "הוסף אבן דרך", m(Icon, { icon: "icon-plus" })),
                                m(".section__action", { onclick: e => addUnit(section) }, "הוסף יחידה", m(Icon, { icon: "icon-plus" })),
                                m(".section__action", { onclick: e => vnode.attrs.togglePreviewEdit(section.docData.docID) }, "תצוגה מקדימה", m(Icon, { icon: "icon-eye" })),
                            ]
                        ),
                    ],
                ) //END section    
            ]
        }
    }
}