import m from "mithril"
import { STRING, NUMBER } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, UNIQUE, MAX, DISABLED } from "../../../utils/constants/inputAttrs";
import { APARTMENTS, BUILDINGS, FLOORS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { ApartmentDoc } from "./apartmentClass";
import { buildInsertDoc } from "../../CRUD/utils";
import { db } from "../../../index";
import { uuid } from "../../../utils/js";

export class FloorDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: FloorDoc,
        });
        if (!this.isNew) this.listenToChildren()
        if (this.isNew) {
            if (!("fromIndex" in data)) {
                const lastFloor = this.getLast("index", { exclude: { ref: this.docData.ref } })
                let startIndex = 1
                if (lastFloor) startIndex = parseInt(lastFloor.docData.toIndex) + 1
                this.edit({
                    fromIndex: startIndex,
                    toIndex: startIndex,
                    apartmentsCount: 1
                })
            }
            if (!("title" in data)) this.autoTitle()
        }
    };

    /**
      * @override remove => remove apartments by floor count+index
      */
    async remove() {
        try {
            const project = this.getProject()
            if (!project) throw "project not found"
            await Promise.resolve(super.remove())
            const batch = db.batch()
            //[1] REMOVE APARTMENTS
            this.getApartments().forEach(doc => {
                doc.batchRemove(batch)
            })
            await Promise.resolve(batch.commit())
            await Promise.resolve(project.updateTotalApartments())
            m.redraw()
        } catch (err) {
            console.error('Error on Floor.remove(): ', err);
        }
    }

    /**
      * @override insert => insert apartments by floor count+index
      */
    async insert(path) {
        try {
            const { ref, apartmentsCount, fromIndex, title, toIndex } = this.docData
            const project = this.getProject()
            if (!project) throw "project not found"
            const batch = db.batch()
            const colRef = `${project.docData.ref}/${APARTMENTS}`
            const buildingFloorPath = this.getBuildingFloorPath()
            //[1] INSERT FLOOR
            batch.set(db.doc(ref), buildInsertDoc(this.docData))
            this.isNew = false
            this.docChanges = {}
            //[2] INSERT APARTMENTS
            for (let i = 0; i < apartmentsCount; i++) {
                const index = parseInt(fromIndex) + Number(i)
                console.log(`floor ${title} - [from:${fromIndex}][to:${toIndex}]  - apartemnt ${index}`);
                const newApratmentData = DocModel.buildDocData(ApartmentDoc.headers, { colRef, buildingFloorPath, index, title: index })
                batch.set(db.doc(`${colRef}/${uuid()}`), buildInsertDoc(newApratmentData))
            }
            //[3] UPDATE BUILDING
            const building = this.getParent()
            if (!building) throw `building not found`
            batch.set(db.doc(building.docData.ref), { apartmentsCount: parseInt(building.docData.apartmentsCount) + parseInt(apartmentsCount) }, { merge: true })
            await Promise.resolve(batch.commit())

            await Promise.resolve(project.updateTotalApartments())
            m.redraw()
        } catch (err) {
            console.error("error on insert apartment ", err);
        }
    }

    getApartments() {
        const project = this.getProject()
        if (!project) throw "project not found"
        return project.getChildren(APARTMENTS, { include: { buildingFloorPath: this.getBuildingFloorPath() } })
    }
    getBuildingFloorPath() {
        return this.docData.ref.split("/").slice(-4,).join("/")
    }

    async addApartment() {
        try {
            const batch = db.batch()

            const project = this.getProject()
            if (!project) throw "project not found"

            const { apartmentsCount, toIndex, ref } = this.docData
            const colRef = `${project.docData.ref}/${APARTMENTS}`
            const buildingFloorPath = this.getBuildingFloorPath()
            const newIndex = parseInt(toIndex) + 1

            this.edit({
                apartmentsCount: parseInt(apartmentsCount) + 1,
                toIndex: newIndex,
            })

            //[-] SAVE THIS
            batch.set(db.doc(ref), this.docData, { merge: true })
            this.docChanges = {}

            //[1] INSERT NEW APARTMENT
            const newApratmentData = DocModel.buildDocData(ApartmentDoc.headers, { colRef, buildingFloorPath, index: newIndex, title: newIndex })
            batch.set(db.doc(`${colRef}/${uuid()}`), buildInsertDoc(newApratmentData))


            let nextFloor = this.getAfter("index")
            let nextStart = parseInt(newIndex) + 1

            //[2] UPDATE NEXT FLOORS + NEXT APARTMENTS
            while (nextFloor) {
                nextFloor.edit({
                    fromIndex: parseInt(nextStart),
                    toIndex: parseInt(nextStart) + parseInt(nextFloor.docData.apartmentsCount) - 1
                })

                batch.set(db.doc(nextFloor.docData.ref), nextFloor.docData,{merge:true})
                nextFloor.docChanges = {}
                const apartemnts = nextFloor.getApartments()
                apartemnts.forEach(apartment => {
                    const index = parseInt(apartment.docData.index) + 1
                    const title = index
                    batch.set(db.doc(apartment.docData.ref), { title, index }, { merge: true })
                })
                nextStart = nextFloor.docData.toIndex + 1
                nextFloor = nextFloor.getAfter("index")
            }
            //[3] UPDATE BUILDING
            const building = this.getParent()
            if (!building) throw `building not found`
            batch.set(db.doc(building.docData.ref), { apartmentsCount: parseInt(building.docData.apartmentsCount) + 1 }, { merge: true })
            await Promise.resolve(batch.commit())

            await Promise.resolve(project.updateTotalApartments())
            m.redraw()
        } catch (err) {
            console.error("error on addApartment apartment ", err);
        }
    }

    saveLocal(changes, old) {
        super.saveLocal(changes, old)
        if ("toIndex" in changes) {
            const { toIndex } = changes
            this.edit({ apartmentsCount: parseInt(toIndex) - parseInt(this.docData.fromIndex) + 1 })
        } else if ("apartmentsCount" in changes) { //TODO:
            const { apartmentsCount } = changes
            this.edit({ toIndex: parseInt(this.docData.fromIndex) + parseInt(apartmentsCount) - 1 })
        }
        let next = this.getAfter("index")
        let nextStart = parseInt(this.docData.toIndex) + 1
        while (next) {
            next.edit({
                fromIndex: parseInt(nextStart),
                toIndex: parseInt(nextStart) + parseInt(next.docData.apartmentsCount)
            })
            nextStart = parseInt(nextStart) + parseInt(next.docData.apartmentsCount) + 1
            next = next.getAfter("index")
        }
    }

    autoTitle() {
        const isGroundFloor = this.getParent("includeGroundFloor") && this.isFirst("index")
        if (isGroundFloor) {
            this.docData.title = "קרקע"
        } else {
            this.docData.title = this.docData.index
        }
    }

    static get meta() {
        return {
            id: FLOORS,
            routes: {
                collection: `projects/:projectID/${BUILDINGS}/:buildingID/${FLOORS}`,
                doc: `projects/:projectID/${BUILDINGS}/:buildingID/${FLOORS}/:floorID`,
            }
        }
    }
    static get headers() {
        return {
            title: { label: "קומה", defaultValue: "1", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "1" }, { [UNIQUE]: true }] },
            description: { label: 'תיאור והערות', defaultValue: "--ללא תיאור--", type: STRING },
            apartmentsCount: {
                label: "כמות דירות", type: NUMBER, defaultValue: 0,
                props: [
                    { [REQUIRED]: true },
                    { [MAX]: 10 },
                    { [DISABLED]: doc => !doc.isNew },
                ]
            },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
            fromIndex: { label: "מ", type: NUMBER, defaultValue: 1, props: [{ [DISABLED]: true }] },
            toIndex: { label: "עד", type: NUMBER, defaultValue: 1, props: [{ [DISABLED]: doc => !doc.isNew }] },
        }
    }
}