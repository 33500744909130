import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';

// DATA
import { Prompt } from '../../commons/prompt/Prompt';
import { Icon } from '../../components/icon/Icon';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { USERS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { isSuperAdmin } from '../../../data/utils/permission';

// FUNCTIONS
export const SplashReports = (node) => {
    const navReports = [
        {
            path: "/app/reports/vendors",
            icon: "vendors",
            text: "קבלנים"
        },
        {
            path: "/app/reports/projects",
            icon: "projects",
            text: "פרוייקטים",
        },
        {
            path: "/app/reports/new",
            icon: "tools",
            text: "מחולל דוחות",
            adminOnly: true,
        },
    ]

    return {
        snackbar: false,
        prompt: false,
        load: true,
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            sessionStorage.setItem('lastURL', m.route.get());
        },
        view: (vnode) => {
            return m(PageLayout, { class: "splash", title: "דוחות" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                ),
                m(".splash__nav",
                    dataStore[USERS].current && navReports.map(elem => {
                        if (elem.adminOnly && !isSuperAdmin()) return
                        return m(".splash__elem", { onclick: e => m.route.set(elem.path) }, m(Icon, { icon: `icon-${elem.icon}` }), elem.text)
                    })
                )
            )
        }
    }
}