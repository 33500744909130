import { STRING, DATE, NUMBER, PERCENT, CURRENCY, VALID_CALC, VALID_COMPARE, VALID_UNIQUE, SWITCH, LIST, DIRECTORY, REF, USER, MULTICHOICE_LIST, MULTICHOICE_SWITCH } from "../../utils/constants/types"
import { O_DATE, O_NUMBER, O_STRING, O_OBJECT, O_ARRAY, O_FUNCTION } from "../../utils/constants/objTypes"
import { REG_URL_LAST_PART } from "../../utils/constants/regex"
import { DATEDIF } from "../../utils/constants/mathFunctions"
import { UPDATE } from "../store/permissionStore"
import * as props from "../../utils/constants/inputAttrs"
import * as mathOperators from "../../utils/constants/mathOperators"
import * as ralationalOperators from "../../utils/constants/ralationalOperators"

import { formatDate, parseToType, diffDays, formatDateDisplay, formatNumber, distDays, addMonths, monthDiff, dateAfter, dateBefore, objType } from "../../utils/js"
import { DocModel } from "../store/docModel"
import { isUserAllow } from "./permission"
import { dataStore } from "../store/dataStore"
import { USERS } from "../dictionary/routeNames"
import { DEFAULT_USER_NOT_ALLOW_MSG } from "../store/settingsStore"

export const getTriggerStatements = (logic, inputKey, type = "calc") => {
    if (logic) {
        const filter = logic.filter(statement =>
            statement.trigger &&
            statement.trigger.includes(inputKey) &&
            statement.type === type)
        if (filter[0]) {
            return filter
        }
    }
}

export const execFieldsFunc = (model, formData, statement) => {
    const expr = statement.expression.replace(/\s+/g, ''); // remove spaces
    const [matches, funcName, p1, fieldsStr, p2] = /([a-zA-Z]+)(\()([a-zA-Z\,]+)(\))/.exec(expr)
    // console.log(funcName , fieldsStr)
    switch (funcName) {
        case DATEDIF:
            const [sDate, eDate] = fieldsStr.split(",")
            let result = diffDays(new Date(formData[sDate]), new Date(formData[eDate]))
            if (result < 0) result = `(${Math.abs(result)}-)`
            return `${result.toString()} יום`
    }
}
export const calcFunction = (formData, expr) => {
    const functionList = ["addMonths", "monthDiff", "calcAccountToPay", "calcBillingTotal", "calcTaxValue", "calcIndexedValue"]
    const regex = /func:([a-zA-Z]+)\(([a-zA-Z]+),([a-zA-Z]+),?([a-zA-Z]+)?,?([a-zA-Z]+)?\)/
    const regExArr = regex.exec(expr)
    if (regExArr !== null) {
        const [input, funcName, ...args] = regExArr
        // console.log(input, funcName, ...args)
        if (functionList.includes(funcName)) {
            switch (funcName) {
                case "addMonths":
                    //SIGNATURE = addMonths(monthsToAdd,baseDate=new Date())
                    const monthsToAdd = parseInt(formData[args[0]])
                    const baseDate = formData[args[1]] === "" || formData[args[1]] === null ?
                        new Date() :
                        new Date(formData[args[1]])
                    return addMonths(monthsToAdd, baseDate).toISOString()
                case "monthDiff":
                    //SIGNATURE = monthDiff(sDate,eDate)
                    const sDate = new Date(formData[args[0]])
                    const eDate = new Date(formData[args[1]])
                    return monthDiff(sDate, eDate)
                case "calcIndexedValue":
                    // SIGNATURE = calcIndexedValue(subTotal,indexedPercent)
                    const subTotal2 = parseFloat(formData[args[0]])
                    const indexedPercent2 = parseFloat(formData[args[1]])
                    return subTotal2 * (indexedPercent2 / 100) - subTotal2 //הפרש הצמדה למדד
                case "calcTaxValue":
                    // SIGNATURE = calcTaxValue(subTotal,indexedPercent,vatPercent,taxPercent)
                    const subTotal1 = parseFloat(formData[args[0]])
                    const indexedPercent1 = parseFloat(formData[args[1]])
                    const vatPercent1 = parseFloat(formData[args[2]])
                    const taxPercent1 = parseFloat(formData[args[3]])
                    const totalIncludeVAT =
                        subTotal1 * (indexedPercent1 / 100) //הצמדה למדד
                        + (subTotal1 * (vatPercent1 / 100)) // מעמ+
                    return totalIncludeVAT * (taxPercent1 / 100)// -ניכוי מס
                case "calcAccountToPay":
                    // func:calcAccountToPay(subTotal,totalDelay,delayRelease)
                    const subTotal3 = parseFloat(formData[args[0]])
                    const totalDelay = parseFloat(formData[args[1]])
                    const delayRelease = parseFloat(formData[args[2]])
                    // console.log("calcAccountToPay ",)
                    return subTotal3 - totalDelay + delayRelease
                case "calcBillingTotal":
                    // SIGNATURE = calcBillingTotal(subTotal,indexedPercent,vatPercent,taxPercent)
                    const subTotal = parseFloat(formData[args[0]])
                    const indexedPercent = parseFloat(formData[args[1]])
                    const vatPercent = parseFloat(formData[args[2]])
                    const taxPercent = parseFloat(formData[args[3]])
                    const totalIncludeVAT2 =
                        subTotal * (indexedPercent / 100) //הצמדה למדד
                        + (subTotal * (vatPercent / 100)) // מעמ+
                    return totalIncludeVAT2 - (totalIncludeVAT2 * (taxPercent / 100))// -ניכוי מס
            }
        } else {
            throw `function ${funcName} not found`
        }
    } else {
        throw `regExArr on expression: ${expr} not match`
    }
}

export const calcFieldRes = (doc, statement, inputKey) => {
    //statment.type = "calc"
    // console.log(formData)
    const { docData: formData, headers, model } = doc
    const fieldObj = headers[inputKey]
    const expr = statement.expression.replace(/\s+/g, ''); // remove spaces

    if (expr.startsWith("func:")) {
        return calcFunction(formData, expr)
    }

    const fields = expr.split(/\*|\+|\-|\/|>|<|=|<=|>=|==/);
    const mathOperatorsList = {
        [mathOperators.MULTIPLY]: "multiply",
        [mathOperators.ADD]: "add",
        [mathOperators.SUBTRACT]: "subtract",
        [mathOperators.DIVIDE]: "divide",
        // [mathOperators.ASSIGN]: "assign"
    }
    let result = 0;
    fields.forEach((f, ind) => {
        const strMatch = new RegExp(`(${f})([\*\+\-\/\>\<=]+)([a-zA-Z:0-9]+)`);
        const currExpr = strMatch.exec(expr);
        // console.log(f,currExpr);
        if (currExpr !== null) {
            const f1 = currExpr[1]; //data[f1]
            const o = currExpr[2]
            const f2 = currExpr[3] //data[f2]
            const data = {};

            [f1, f2].forEach(f => {
                if (f.startsWith("number:")) {
                    //signature number:calcVat:100
                    const [_, id, number] = f.split(":");
                    data[f] = parseFloat(number)
                    // } else if (f.startsWith("whatever:")) {
                } else {
                    data[f] = formData[f].value || formData[f]
                }
            })

            let val1 = ind === 0 ? data[f1] : result
            let val2 = data[f2]
            let localRes = 0;
            if (fieldObj && fieldObj.type) {
                const inputObjType = getInputDataType(fieldObj.type)
                val1 = parseToType(val1, inputObjType)
                val2 = parseToType(val2, inputObjType)
            }
            // console.log(data)
            switch (mathOperatorsList[o]) {
                case "multiply":
                    localRes = val1 * val2;
                    break;
                case 'add':
                    localRes = val1 + val2;
                    break;
                case 'subtract':
                    localRes = val1 - val2;
                    break;
                case 'divide':
                    localRes = val1 / val2;
                    break;
            }
            result = localRes;
            // console.log(`step ${ind}: ${f1}[${val1}] ${o} ${f2}[${val2}] = ${result}`)
        }
    })
    return result
}

const compareFieldResult = (formData, statement, fieldObj) => {
    //statment.type ="compare"
    // console.log(formData)
    let result = true;
    if (objType(statement.expression) === O_STRING) {
        const expr = statement.expression.replace(/\s+/g, ''); // remove spaces
        const REG_OPERATORS = /\*|\+|\-|\/|>|<|<=|>=|==/
        const fields = expr.split(REG_OPERATORS);
        // console.log(fields)

        const ralationalOperatorsList = {
            [ralationalOperators.LT]: "lt",
            [ralationalOperators.GT]: "gt",
            [ralationalOperators.LTE]: "lte",
            [ralationalOperators.GTE]: "gte",
            [ralationalOperators.EQ]: "eq",
        }
        const REG_OPERATORS_STR = "[*\+-\/><]|<=|>=|=="
        fields.forEach((f, ind) => {
            const str = `(${f})(${REG_OPERATORS_STR})([_a-zA-Z]+)`
            const strMatch = new RegExp(str);
            const currExpr = strMatch.exec(expr);
            // console.log(expr, str, currExpr);
            if (currExpr !== null) {
                const [input, f1, o, f2] = currExpr
                const data = {}
                const tempArr = [f1, f2]
                tempArr.forEach(_f => {
                    if (_f === "_NOW_") {
                        data[_f] = new Date();
                    } else if (_f in formData) {
                        data[_f] = formData[_f].value || formData[_f]
                    } else {
                        data[_f] = _f
                    }
                })
                // const data = {
                //     [f1]: formData[f1].value || formData[f1],
                //     [f2]: formData[f2].value || formData[f2]
                // }
                let val1 = ind === 0 ? data[f1] : result
                let val2 = data[f2]
                let localRes = 0;
                // if (fieldObj && fieldObj.type) {
                //     const inputObjType = getInputDataType(fieldObj.type)
                //     val1 = parseToType(val1, inputObjType)
                //     val2 = parseToType(val2, inputObjType)
                // }
                // console.log(data)
                switch (ralationalOperatorsList[o]) {
                    case 'lt':
                        if (fieldObj.type === DATE) localRes = dateBefore(new Date(val1), new Date(val2))
                        else localRes = val1 < val2;
                        break;
                    case 'gt':
                        if (fieldObj.type === DATE) localRes = dateAfter(new Date(val1), new Date(val2))
                        else localRes = val1 > val2;
                        break;
                    case 'lte':
                        if (fieldObj.type === STRING) localRes = val1 <= val2;
                        else localRes = parseFloat(val1) <= parseFloat(val2);
                        break;
                    case 'gte':
                        if (fieldObj.type === STRING) localRes = val1 >= val2;
                        else localRes = parseFloat(val1) >= parseFloat(val2);
                        break;
                    case 'eq':
                        if (fieldObj.type === STRING) localRes = val1 === val2;
                        else localRes = parseFloat(val1) === parseFloat(val2);
                        break;
                }
                result = localRes;
                // console.log(`step ${ind}: ${f1}[${val1}] ${o} ${f2}[${val2}] = ${result}`)
            }
        })

    } else if (objType(statement.expression) === O_FUNCTION) {
        result = statement.expression(formData)
    }
    return result
}

/**
 * return parsed value for input data
 * @param {String} type the input type - ex: password,text
 * @param {any} value the input value
 * @param {Object} init the expression to use for init input value
 * @param {Boolean} initMode is first init mode
 */
export const parseValueType = (type, value, init, initMode) => {
    switch (type) {
        case NUMBER:
            if (value) return value
            else return initMode && init ? init : 0
        case DATE:
            if (value) return formatDate(new Date(value))
            else return initMode && init ? formatDate(new Date(init)) : ''
        case SWITCH:
            return value || ""
        default:
            if (value) return value
            else return initMode && init ? init : ""
    }
}

export const validateField = (doc, header, value) => {
    //part 1: input props!
    const { docData, model, headers = doc.model.headers } = doc
    const field = headers[header]
    if (!field) return { valid: true }

    const errorMsgs = []
    const inputAttrs = field.props
    let valid = true;
    if (inputAttrs) {
        inputAttrs.forEach(prop => {
            const k = Object.keys(prop)[0];
            const v = prop[k];
            // console.log(k, v, value)
            if (k === props.MAX && value > v) {
                valid = false;
                errorMsgs.push(` הערך גדול מ${v}`)
            } else if (k === props.MIN && value < v) {
                valid = false;
                errorMsgs.push(` הערך קטן מ${v}`)
            } else if (k === props.PATTERN && value !== null && value !== "") {
                const regex = new RegExp(v)
                const m = regex.exec(String(value))
                if (m === null) {
                    valid = false;
                    errorMsgs.push(` הערך אינו תואם לתבנית הקלט${v}`)
                }
            } else if (k === props.REQUIRED && value.toString().trim().length === 0) {
                valid = false;
                errorMsgs.push("זהו שדה חובה!")
            } else if (k === props.MINLENGTH && value.length < v) {
                valid = false;
                errorMsgs.push(`יש להשתמש במחרוזת טקסט בעלת ${v} אותיות לכל הפחות`)
            } else if ((k === props.UNIQUE && v == true) || getTriggerStatements(model.meta.logic, header, VALID_UNIQUE)) {
                const siblings = doc.getSiblings({ include: { [header]: value }, exclude: { ref: docData.ref } });
                if (siblings.length > 0) {
                    valid = false
                    errorMsgs.push("ערך זה הינו ייחודי , מחרוזת טקסט זו כבר קיימת במסמך אחר")
                }
            }
            //BUG: this one is trigger all the the time
            // else if (props.MAXLENGTH && value.length > v) {
            // console.log("FIXME: maxLength is fire 🔥🔥🔥")
            //         valid = false;
            //         errorMsgs.push(`יש להשתמש במחרוזת טקסט בעלת ${v} אותיות לכל היותר`)
            // }
        })
    }
    // console.log('input attrs is valid ?', valid)

    //---------------------------------------------------
    //part 2: compare statement!
    const statements = getTriggerStatements(model.meta.logic, header, VALID_COMPARE)
    if (statements && docData)
        statements.forEach(statement => {
            const changedFormData = Object.assign({}, docData, { [header]: value })
            const isValid = compareFieldResult(changedFormData, statement, field)
            if (!isValid) errorMsgs.push(statement.msg || "נראה שיש בעיה עם תקינות הנתונים , המערכת תאפס את הקלט")
            valid = valid && isValid
        })
    //---------------------------------------------------
    // console.log('doc compare statements is valid ?', valid)

    return { valid, errorMsgs };
}

const getInputDataType = (inputType = STRING) => {
    switch (inputType) {
        case DATE:
            return O_DATE
        case NUMBER:
            return O_NUMBER
        default:
            return O_STRING
    }
}



export const getConditionalFormattiongClass = (doc, header) => {
    let classStyle = ""
    const Model = doc.model
    const field = doc.headers[header]
    if (Model.meta.conditionalFormatting && Model.meta.conditionalFormatting.find(rule => rule.header === header)) {
        Model.meta.conditionalFormatting
            .forEach(rule => {
                if (rule.header === header) {
                    const isValid = compareFieldResult(doc.docData, rule, field)
                    if (!isValid) {
                        classStyle += rule.classStyle
                    }
                }
            })
    }
    return classStyle
}


export function getDisplayDoc(doc, options) {
    const { param = "title", params = false } = options
    if (params) {
        return params.map(p => {
            if (p.startsWith("^")) return doc.getParent(p.substring(1)) || ""
            return doc.docData[p] || ""
        }).join(" | ")
    } else { //param
        return doc.docData[param]
    }
}
/**
 * @param {any} value value to dispaly
 * @param {String} type the type of value to display
 */
export const getDisplayValue = (value, type, options = {}, docData) => {
    if (objType(value) === O_OBJECT || objType === O_ARRAY) {
        return JSON.stringify(value)
    }
    let result = "---"
    switch (type) {
        case STRING:
            if (value && value.toString()) result = value.toString();
            break;
        case DATE:
            result = formatDateDisplay(new Date(value), options);
            break;
        case NUMBER:
            result = formatNumber(value, options);
            break;
        case PERCENT:
            result = `${formatNumber(value, options)} %`
            break;
        case "diff":
            result = `${formatNumber(Math.abs(value, options))} ${parseFloat(value) > 0 ? "▲" : "▼"}`
            break;
        case CURRENCY:
            result = `${formatNumber(value, options)} ₪`
            break;
        case SWITCH:
            // const findLabel = options.find(opt => opt.id == value) // old version
            if (value !== "") {
                const findLabel = options.filter(opt => Array.isArray(value) ? value.includes(opt.id) : opt.id == value)
                if (findLabel[0]) result = findLabel.map(l => l.text).join(" | ")
                break;
            }
        case LIST:
            if (value !== "") {
                const findDoc = DocModel.getDoc(value)
                if (findDoc) result = getDisplayDoc(findDoc, options)
                break;
            }
        case MULTICHOICE_LIST:
            if (Array.isArray(value) && value[0]) {
                value.forEach((ref, ind) => {
                    if (ref) {
                        const findDoc = DocModel.getDoc(ref)
                        if (result === "---") result = ""
                        if (ind > 0 && result !== "") result += " , "
                        if (findDoc) result += getDisplayDoc(findDoc, options)
                    }
                })
            }
            break;
        case MULTICHOICE_SWITCH:
            if (Array.isArray(value) && value[0]) {
                const findLabel = options.filter(opt => value.includes(opt.id))
                if (findLabel[0]) result = findLabel.map(l => l.text).join(" | ")
            }
            break;
        case DIRECTORY:
            // TODO: load data from directory and get title (THINK: maby need instanceDoc for this)
            if (value !== "") {
                let relativePath = options.relativeTo || docData.colRef.replace(REG_URL_LAST_PART, "") // default relative path is parent path (colRef without modelID)
                if (relativePath) {
                    const docsToFind = value.split("/")
                    console.assert(docsToFind.lenth % 2 !== 0, "invalid direactory path must be even number (doc ref)")
                    result = ""
                    let docRef = ""
                    do {
                        if (docsToFind.length % 2 == 0) {
                            if (docRef.length > 0) {
                                docRef += "/"
                                result += " ◆ "
                            }
                            docRef += docsToFind.shift() //collectionID
                        } else {
                            docRef += `/${docsToFind.shift()}` //docID
                            // console.log("findDoc :", `${relativePath}/${docRef}`);
                            const findDoc = DocModel.getDoc(`${relativePath}/${docRef}`) //one parent ...if multiple THINK:
                            if (findDoc) {
                                //options.paramsArray => [{param:""},params:["",""]]
                                const { paramsArray = [{ param: "title" }] } = options
                                paramsArray.forEach(({ param, params }) => {
                                    result += getDisplayDoc(findDoc, { param, params })
                                })
                            }
                        }
                    }
                    while (docsToFind.length > 0);
                    break;
                }
            }
            result = value // fallback
            break;
        case REF:
            if (value && value !== "") {
                const findDoc = DocModel.getDoc(value)
                if (findDoc) result = getDisplayDoc(findDoc, options)
                break;
            }
        case USER:
            if (value && value !== "") {
                const { param = "displayName", params = false } = options
                const findUser = dataStore[USERS].data.find(doc => doc.docData.docID === value)
                if (findUser) result = getDisplayDoc(findUser, { param, params })
            }
            break;
        default:
            if (value !== "") result = value
    }
    if (options.symbol) result += ` ${options.symbol}`
    return result
}



export const editValue = (oldValue, value, header, docClass) => {
    // console.log(
    //     "edit value => \n",
    //     "\noldValue: ", oldValue,
    //     "\nvalue: ", value,
    //     "\nheader: ", header,
    //     "\ndocClass: ", docClass
    // )

    //THINK: move to class ? 

    //TODO: test if userAllow ?
    if (!isUserAllow(docClass.model.meta.id, UPDATE, Object.assign({}, docClass, { docChanges: { [header]: value } })) && !docClass.isNew) {
        console.error(`invalid UPDATE operation [${docClass.model.meta.id}] - TODO: block user!!! ⛔`, Object.assign({}, docClass, { docChanges: { [header]: value } }));
        return { valid: false, errorMsgs: [DEFAULT_USER_NOT_ALLOW_MSG] }
    } else {
        // //TODO: validateUnique
        const { valid, errorMsgs } = validateField(docClass, header, value);

        docClass.saveLocal({ [header]: value }, { [header]: oldValue })
        return { valid, errorMsgs }
    }
}

export const getInputType = (type) => {
    switch (type) {
        case DATE:
            return "date";
        case NUMBER:
            return "number"
        case PERCENT:
            return "number"
        case CURRENCY:
            return "number"
        default: return "text"
    }
}
export const getParsedValue = (value, type) => {
    if (objType(value) === O_ARRAY || objType(value) === O_OBJECT) {
        return JSON.stringify(value)
    }
    switch (type) {
        case DATE:
            if (value !== "") return formatDate(new Date(value))
            else return ""
        default:
            return value
    }
}