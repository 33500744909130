import m from "mithril"

//DATA
import { MilestoneDoc } from "../../../../data/store/contracts/milestoneClass"
import { CURRENCY, NUMBER, PERCENT } from "../../../../utils/constants/types"

//FUNCTIONS
import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { isValidTotal, confirmSectionChanges, getTotalMilestones, calcTotalUnitMilestonePlan } from "../utils"

//COMPONENTS
import { Card } from "../../../commons/cardLayout/Card"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { InputCell } from "../../../commons/inputCell/InputCell"
import { Icon } from "../../../components/icon/Icon"
import { IconError } from "../../../commons/iconError/IconError"
import { SectionDoc } from "../../../../data/store/contracts/sectionClass"
import { sortDocsBy, uuid } from "../../../../utils/js"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { SECTION_MILESTONES } from "../../../../data/dictionary/routeNames"

export const SectionPreview = node => {

    //SECTION HANDLER

    const handleCopySection = (e, section, milestones) => {
        e.stopPropagation();
        node.state.prompt = {
            title: "הוספת סעיף  - עותק",
            msg: `האם ליצור סעיף חדש עותק של ${section.docData.title} ?`,
            actionText: "כן",
            action: e => {
                try {
                    section.duplicate();
                    node.state.snackbar = { msg: "הסעיף שוכפל בהצלחה!" }
                } catch (err) {
                    node.state.snackbar = { msg: "אירעה שגיאה: \nתיאור:\n" + err, isError: true }
                }
                node.state.prompt = false
            }
        }
    }

    const handleDeleteSection = (e, section) => {
        e.stopPropagation();
        node.state.prompt = {
            title: "מחיקת סעיף",
            msg: `האם למחוק את הסעיף ${section.docData.title} ?`,
            // TODO:
            // actions: [
            //     {
            //         text: "",
            //         action:e=>{}
            //     },
            // ],
            actionText: "כן",
            action: e => {
                try {
                    section.remove()
                    node.state.snackbar = { msg: "הסעיף נמחק!" }
                } catch (error) {
                    console.error(error)
                    node.state.snackbar = { msg: error, isError: true }
                }
                node.state.prompt = false
            }
        }
    }

    // MILESTONE | UNIT

    const addMilestone = (colRef) => {
        new MilestoneDoc({ colRef })
    }

    const addUnit = doc => {
        const oldUnit = doc.docData.itemsCount
        const newUnit = parseInt(oldUnit) + 1
        doc.saveLocal({ "itemsCount": newUnit })
    }

    //VIEW TYPES

    const toggleCollaspeSection = sectionID => {
        const ind = node.state.collaspeSection.indexOf(sectionID)
        if (ind > -1) node.state.collaspeSection.splice(ind, 1)
        else node.state.collaspeSection.push(sectionID)
    }
    const isCollaspeSection = sectionID => node.state.collaspeSection.includes(sectionID)

    // togglePreviewEdit [attrs]

    const milestoneHeaders = {
        unit: { label: "#", info: "יחידה" }
    }

    const sectionHeaders = [
        "title",
        "contractorRef",
        "calculationMethod",
        "calculationType",
        "amountType",
        "itemsCount",
        "itemsStartIndex",
        "itemPrice",
        "totalSum",
        "description",
        // "donePercentage",
    ]

    return {
        editCell: "",
        snackbar: false,
        prompt: false,
        collaspeSection: [],
        view: vnode => {
            const { sectionIndex, wsIndex, section, sectionsArr, parentState } = vnode.attrs
            const isCollaspe = isCollaspeSection(section.docData.docID)
            const isPauschal = section.isPauschal()
            const isPercent = section.isPercent()
            const milestones = section.getChildren(SECTION_MILESTONES).sort(sortDocsBy("index", { type: NUMBER }))
            const totalMilesones = getTotalMilestones(section, milestones, isPercent)
            return [
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.action && m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                ),
                m(Card, {
                    // key: "section_" + sectionIndex,
                    class: `section`,
                    id: section.docData.docID,
                    autofocus: "autofocus",
                    // tabindex: wsIndex + sectionIndex
                },
                    section.isNew && m(".section__flag flag flag--new", "לא נשמר עדיין"),
                    m(".caption", {
                        onclick: e => toggleCollaspeSection(section.docData.docID),
                        class: isCollaspe ? ".caption--collaspe" : ""
                    },
                        section.docData.title
                        , m(Icon, { icon: `icon-triangle-${isCollaspe ? "up" : "down"}`, class: "icon-collaspe" })
                    ),
                    !isCollaspe && [
                        sectionHeaders.map((header, index) => {
                            if (header === "itemsStartIndex" && !isPauschal) return
                            return m(InputCell, {
                                id: `section__${section.docData.ref}__${header}`,
                                // index: wsIndex + sectionIndex,
                                header,
                                doc: section,
                                value: section.docData[header],
                                field: SectionDoc.headers[header],
                                editCell: vnode.state.editCell,
                                parent: vnode
                            })
                        }),
                        (parentState && parentState.loadMilestones[section.docData.ref] === true) ? m(InlineLoaderDots) :
                            (!section.isNew && milestones.length > 0) && m(".section__milestones", {
                                style: `grid-template-columns: repeat(${Object.keys(milestoneHeaders).length},min-content) repeat(${milestones.length},1fr) 1fr;`,
                                key: uuid(),
                            },
                                //headers + titles + totalColumn
                                Object.entries(milestoneHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", header.label)),
                                milestones.map((milestone, msInd) => {
                                    // const id = `${milestone.docData.ref}__${header}`
                                    return m(".section__milestone section__milestone--title", { key: 'ms_title_' + msInd },
                                        // (milestones.length > 1) && m(".action", { onclick: e => handleRemoveMilestone(section, milestones, milestone, isPauschal) }, m(Icon, { icon: "icon-delete" })),
                                        milestone.isNew && m(".flag flag--new", "חדש"),
                                        m(".section__milestone.section__milestone--title", `[${milestone.docData.index}] ${milestone.docData.title}`)
                                    )
                                }),
                                m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"), //totalColumn


                                //weight | price row
                                m(".section__milestone section__milestone--unit", section.docData.itemsCount > 1 ? `X ${section.docData.itemsCount}` : "-"),
                                milestones.map((milestone, index, arr) => {
                                    const isPercent = milestone.isPercent()
                                    const header = isPercent ? "weight" : "price"
                                    const value = milestone.docData[header]
                                    const field = MilestoneDoc.headers[header]
                                    // const id = `${milestone.docData.ref}__${header}`
                                    return m(".section__milestone section__milestone--value", getDisplayValue(value, field.type, field.options))
                                }),
                                m(".section__milestone section__milestone--total section__milestone--columnTotal", {
                                    "data-error": !isValidTotal(section, milestones, isPercent) ? "true" : "false"
                                },
                                    !isValidTotal(section, milestones, isPercent) && m(IconError, { error: 'חייב להגיע ל100%' }),
                                    getDisplayValue(totalMilesones, isPercent ? PERCENT : CURRENCY),
                                ),

                                //unit values
                                section.buildVirtualUnits().map(unit => {
                                    return [
                                        m(".section__milestone section__milestone--unit", isPauschal ? unit : "*"),
                                        milestones.map((milestone, index, arr) => {
                                            const totalUnitMilestone = calcTotalUnitMilestonePlan(section, milestone)
                                            if (isPauschal) {
                                                return m(".input__field.section__milestone", getDisplayValue(totalUnitMilestone, CURRENCY))
                                            } else {
                                                return m(".input__field.section__milestone", getDisplayValue(totalUnitMilestone, CURRENCY))
                                                // return m(".input__field.section__milestone", `${section.docData.amountType}`)
                                            }
                                        }),
                                        m(".section__milestone section__milestone--total section__milestone--columnTotal", {
                                            "data-error": !isValidTotal(section, milestones, isPercent) ? "true" : "false"
                                        },
                                            !isValidTotal(section, milestones, isPercent) && m(IconError, { error: 'חייב להגיע ל100%' }),
                                            getDisplayValue(section.docData.itemPrice, CURRENCY),
                                        ),
                                    ]
                                }),

                                //total rows
                                m(".section__milestone section__milestone--total", "סהכ"),
                                //TODO: create MilestoneDoc.calcTotalPlan()
                                milestones
                                    .map(ms => isPercent ? parseInt(ms.docData.weight) / 100 * section.docData.itemPrice : parseInt(ms.docData.price))
                                    .map((total, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, getDisplayValue(total * section.docData.itemsCount, CURRENCY))),

                                m(".section__milestone section__milestone--total section__milestone--columnTotal", getDisplayValue(parseFloat(section.docData.totalSum), CURRENCY)),
                            ),

                        (Object.keys(section.docChanges).length > 0 || milestones.filter(ms => Object.keys(ms.docChanges).length > 0)[0]) && m("button.button section__save", { onclick: e => confirmSectionChanges(vnode, section, milestones) }, "שמור שינויים"),
                        m(".section__actions",
                            m(".section__action", { onclick: e => handleDeleteSection(e, section) }, "מחק", m(Icon, { icon: "icon-delete" })),
                            m(".section__action", { onclick: e => handleCopySection(e, section, milestones) }, "שכפל", m(Icon, { icon: "icon-copy" })),
                            (!section.isNew) && [
                                //TODO: create sorting stuff
                                // (sectionIndex > 0) && m(".section__action", { onclick: e => section.setIndex(sectionsArr[sectionIndex - 1].docData.ref) }, "למעלה", m(Icon, { icon: "icon-arrow-up" })),
                                // (sectionIndex < sectionsArr.length - 1) && m(".section__action", { onclick: e => section.setIndex(sectionsArr[sectionIndex + 1].docData.ref) }, "למטה", m(Icon, { icon: "icon-arrow-down" })),
                                m(".section__action", { onclick: e => addMilestone(`${section.docData.ref}/milestones`) }, "הוסף אבן דרך", m(Icon, { icon: "icon-plus" })),
                                m(".section__action", { onclick: e => addUnit(section) }, "הוסף יחידה", m(Icon, { icon: "icon-plus" })),
                                m(".section__action", { onclick: e => vnode.attrs.togglePreviewEdit(section.docData.docID) }, "עריכה", m(Icon, { icon: "icon-edit" })),
                            ]
                        ),
                    ],
                ) //END section    
            ]
        }
    }
}