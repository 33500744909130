import m from "mithril"
import { SECTION_MILESTONES, CONTRACT_SECTIONS } from "../../../../data/dictionary/routeNames"
import { ActualDoc } from "../../../../data/store/contractsActuals/actualClass"
import { dataStore } from "../../../../data/store/dataStore"
import { InputCell } from "../../../commons/inputCell/InputCell"

export const PopUpActual = node => {
    return {
        selectedSection: false,
        selectedMilestone: false,
        view: vnode => {
            const { parentNode, newDoc, contractRef } = vnode.attrs
            const { selectedSection, selectedMilestone } = vnode.state
            const sections = dataStore[CONTRACT_SECTIONS].data.filter(doc => doc.docData.colRef === `${contractRef}/sections`)
            console.log(sections);
            return m(".popUp__form",
                dataStore[CONTRACT_SECTIONS].data.filter(doc => doc.docData.colRef === `${contractRef}/sections`).length > 0 && m("select",
                    dataStore[CONTRACT_SECTIONS].data
                        .filter(doc => doc.docData.colRef === `${contractRef}/sections`)
                        .map(doc => m("option", {
                            value: doc.docData.ref,
                            oninput: e => vnode.state.selectedSection = doc,
                        }, doc.docData.title))
                ),
                selectedSection && m(".", "select milestone"),
                (selectedSection && selectedMilestone) && m(".fields",
                    ["title", "unit", "value"].map((header, index) => {
                        const field = ActualDoc.headers[header]
                        const value = newDoc.docData[header]
                        return m(InputCell, {
                            header, value, field, index: index + 50, id: `popUp__${header}`,
                            editCell: parentNode.state.editCell,
                            doc: newDoc,
                            parent: parentNode
                        })
                    })
                ),
                Object.keys(newDoc.docChanges).length > 0 && m("button.button", {
                    onclick: e => {
                        try {
                            newDoc.save();
                            setTimeout(() => { parentNode.state.snackbar = { msg: "השינויים נשמרו" }; m.redraw() }, 2000);
                        } catch (err) {
                            console.error(err)
                            parentNode.state.snackbar = { msg: `אירעה שגיאה , תיאור: ${err}`, isError: true }
                        }
                        parentNode.state.prompt = false
                    }
                }, "שמור שינויים")
            )

        }
    }
}