import m from "mithril"
import { FLOORS } from "../../../data/dictionary/routeNames"
import { CREATE } from "../../../data/store/permissionStore"
import { isUserAllow } from "../../../data/utils/permission"
import { NUMBER } from "../../../utils/constants/types"
import { sortDocsBy } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"
import { Tooltip } from "../../components/tooltip/Tooltip"

export const BuildingDraw = node => {
    return {
        view: vnode => {
            const { building, actions = {}, viewOptions = {} } = vnode.attrs
            const { buildingClick = () => null, floorClick = () => null, addFloor = () => null } = actions
            const { floorAddButton = false } = viewOptions
            return m(".building", { key: building.docData.ref },
                m(".building__title", { onclick: e => buildingClick(e, building) }, building.docData.title),
                m(".building__floors",
                    building.getChildren(FLOORS)
                        .sort(sortDocsBy("index", { order: "desc", type: NUMBER }))
                        .map(floor => {
                            return m(".building__floor", {
                                key: floor.docData.ref,
                                onclick: e => floorClick(e, floor, building)
                            },
                                m("span.title", floor.docData.title),
                                m("span.from", floor.docData.fromIndex),
                                m("span.space", "-"),
                                m("span.to", floor.docData.toIndex),
                                //TODO:
                                // m(IconButton, { icon: "icon-copy", class: "copy", action: e => handleCopyBuilding(e, building) }),
                            )
                        }),
                    (floorAddButton && isUserAllow(FLOORS, CREATE)) && m("button.button add", {
                        'data-tooltip': true,
                        onclick: e => addFloor(e, building)
                    },
                        m(Icon, { icon: "icon-plus" }),
                        m(Tooltip, { text: "הוסף קומה לבניין" })
                    ),
                )
            )
        }
    }
}