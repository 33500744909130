import m from 'mithril'

export const Card = (init) => {
    return {
        view: (vnode) => {
            return (
                m(".card", {
                    style: vnode.attrs.columns ? `grid-template-columns: repeat(${vnode.attrs.columns}, 1fr);` : "",
                    onclick: e => vnode.attrs.action ? vnode.attrs.action(e) : null,
                    class: vnode.attrs.class ? vnode.attrs.class : '',
                    id: vnode.attrs.id
                }, [
                    vnode.children
                        ?
                        vnode.children
                        :
                        m('.card__empty', vnode.attrs.empty || '---')
                ])
            )
        }
    }
}