import m from "mithril"
import { CONFIRMS, CONTRACTS, CONTRACT_ACCOUNTS, CONTRACT_COMMENTS, PROJECTS } from "../../../../data/dictionary/routeNames"
import { CURRENCY, DATE, NUMBER, REF, STRING } from "../../../../utils/constants/types"

import { db } from "../../../../index"

import { Card } from "../../../commons/cardLayout/Card"

import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { findRepeat, getModelID } from "../../../../data/utils/utils"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { ReportLayout } from "../ReportLayout"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { DocModel } from "../../../../data/store/docModel"
import { buildDoc, getChildren, getLastAccount, getParent, getSubChildren } from "../utils"
import { addMonths, diffDays, formatDateDisplay, sortDocsBy ,textToDate} from "../../../../utils/js"
import { confirmAccountFlow } from "../../../../data/store/contractsActuals/accountClass"
import { Tooltip } from "../../../components/tooltip/Tooltip"

export const ProjectConfirmsReport = node => {

    async function loadReportData(ref) {
        try {
            node.state.collectionData = []
            node.state.load = true
            let qry = db.doc(`projects/${node.attrs.projectID}`).collection(CONTRACTS).where("isActive", "==", true)
            const docs = (await qry.get()).docs
            for (const fbDoc of docs) {
                const doc = buildDoc(fbDoc)
                const { ref } = doc.docData
                const project = await getParent(ref)
                if (project.docData.isActive == true) {
                    doc.parent = project
                    //direct children
                    const accounts = await getChildren(CONTRACT_ACCOUNTS, ref)
                    const comments = await getChildren(CONTRACT_COMMENTS, ref)
                    //sub children
                    const confirms = await new Promise(resolve => resolve(getSubChildren(accounts, CONFIRMS)))
                    doc.children = { accounts, confirms, comments }
                    node.state.collectionData = [...node.state.collectionData, doc]
                    m.redraw()
                }
            }
            node.state.load = false
        } catch (err) {
            console.error(err);
        }
    }

    const temp1 = {}
    const getAccountConfirmStatus = (contract, confirmStage) => {
        const account = temp1[contract.docData.ref] ? temp1[contract.docData.ref].account : getLastAccount(contract)
        if (account) {
            temp1[contract.docData.ref] = { account }
            const confirmDoc = contract.children.confirms.filter(doc => doc.docData.id === confirmStage)[0]
            if (confirmDoc) {
                const { confirmBy, confirmAt, confirm: isConfirm, due, id } = confirmDoc.docData
                let txt = "", classStyle = "", additionalInfo = { value: "" }
                const dueDiff = diffDays(addMonths(1, textToDate(account.docData.month, due)), new Date())
                const isDelay = dueDiff > 0
                const isCurrentStage = id === account.docData.stage
                if (isConfirm == true) {
                    // const userConfirmText = UserDoc.getUserByID(confirmBy, "displayName")
                    const confirmAtText = formatDateDisplay(new Date(confirmAt))
                    // txt += `${confirmAtText} [${userConfirmText}]`
                    txt += `${confirmAtText}`
                    classStyle += " active"
                } else {
                    if (isDelay && isCurrentStage) {
                        additionalInfo.value += ` [באיחור של ${parseInt(dueDiff)} ימים]`
                        additionalInfo.isError = true
                    }
                    txt += getDisplayValue(addMonths(1, textToDate(account.docData.month, due)), DATE, { outputFormat: "dd=>mm" })
                    classStyle += " non-active"
                }
                return { value: txt, classStyle, additionalInfo }
            }
        }
        return { value: "---" }
    }

    const reportHeaders = {
        title: {
            label: "שם החוזה",
            nav: doc => {
                return `/#!/app/${doc.docData.ref}`
            },
            prompt: doc => {
                console.log("TODO: prompt something...");
            },
            val: doc => {
                return { value: doc.docData.title }
            }
        },
        contractorRef: {
            label: "קבלן",
            val: doc => { return { value: getDisplayValue(doc.docData.contractorRef, REF) } }
        },
        _vendorSAPcode: {
            label: "קוד בסאפ",
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) {
                    value = vendorDoc.docData.contractorSAPNumber
                }
                return { value }
            }
        },
        _lastAccountMonth: {
            label: "חשבון נוכחי",
            val: doc => {
                let lastAccount = "---"
                const last = getLastAccount(doc)
                if (last) lastAccount = last.docData.month
                return { value: lastAccount }
            },
            type: STRING,
            options: {
                ltr: true
            }
        },
        _accountFlow0: {
            label: confirmAccountFlow[0].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[0].id),
        },
        _accountFlow1: {
            label: confirmAccountFlow[1].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[1].id),
        },
        _accountFlow2: {
            label: confirmAccountFlow[2].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[2].id),
        },
        _accountFlow3: {
            label: confirmAccountFlow[3].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[3].id),
        },
        _accountFlow4: {
            label: confirmAccountFlow[4].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[4].id),
        },
        _accountFlow5: {
            label: confirmAccountFlow[5].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[5].id),
        },
        _accountFlow6: {
            label: confirmAccountFlow[6].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[6].id),
        },
        _commentLog: {
            label: "הערות חשבון",
            type: STRING,
            val: doc => {
                const docs = doc.children.comments
                let len = docs.length
                const result = { value: "---" }
                if (len) {
                    result.value = `${len} 🚩`
                    result.additionalInfo = { value: `\nתקציר:\n${docs.map(d => d.docData.title).join("\n")}` }
                }
                return result
            }
        },
    }

    return {
        collectionData: [],
        history: [],
        prompt: false,
        snackbar: false,
        oninit: async vnode => {
            const ref = `projects/${vnode.attrs.projectID}`
            let findProject
            const findFunc = () => findProject = DocModel.getChild(PROJECTS, ref)
            await findRepeat(findFunc, findProject != undefined)
            if (findProject) {
                node.state.project = findProject
                await loadReportData(ref)
            } else {
                m.route.set("/app/reports/projects")
            }
        },
        view: vnode => {
            const { collectionData = [], load, project } = vnode.state
            return m(ReportLayout, { title: `סטטוס חשבונות לפרוייקט ${project ? ` - "${project.docData.title}"` : ""}` },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Card, { class: "tableCard" },
                    m(".table",
                        //HEADERS
                        m(".table__row.table__headers", { style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);` },
                            Object.entries(reportHeaders).map(([header, { label }]) => m(".table__cell", label))
                        ),
                        load && m(InlineLoaderDots),
                        //DATA
                        collectionData
                            .sort(sortDocsBy("title", { type: STRING }))
                            .map(doc => {
                                const styleColumns = `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`
                                return m(".table__row", { style: styleColumns, key: doc.docData.ref },
                                    Object.entries(reportHeaders).map(([header, { label, val, type = STRING, options = {}, nav, prompt }]) => {
                                        const { value, classStyle = "", additionalInfo = "" } = val(doc)
                                        const displayValue = getDisplayValue(value, type, options)
                                        return m(`${nav ? "a" : "div"}.table__cell ${classStyle}`, {
                                            key: `${doc.docData.ref}/${header}`,
                                            class: nav ? "link" : "",
                                            href: nav ? nav(doc) : "#",
                                            target: "_blank",
                                            "data-header": header,
                                            "data-tooltip": true,
                                            onclick: e => {
                                                if (prompt) {

                                                }
                                            }
                                        },
                                            displayValue,
                                            m(Tooltip, { text: `${label}:\n${displayValue}\r${additionalInfo.value || ""}`, options }),
                                        )
                                    })
                                )
                            }),
                        //TOTALS row
                        (collectionData.length > 0 && project && !load) && m(".table__totals", {
                            style: `grid-template-columns: repeat(${Object.keys(reportHeaders).length},1fr);`,
                        },
                            Object.entries(reportHeaders).map(([header, fieldOptions]) => {
                                if (header === "title") {
                                    return m(".total", `${getDisplayValue(collectionData.length, NUMBER)} שורות`)
                                }
                                if (fieldOptions.totals) {
                                    console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                                    const totalValue = fieldOptions.totals.val(collectionData)
                                    return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                                }
                                if (fieldOptions.type === NUMBER || fieldOptions.type === CURRENCY) {
                                    let total = 0
                                    if (fieldOptions.val) {
                                        total = collectionData.reduce((acc, doc) => {
                                            return acc += parseFloat(fieldOptions.val(doc).value)
                                        }, 0)
                                    } else {
                                        total = ""
                                    }
                                    return m(".total", getDisplayValue(total, fieldOptions.type, fieldOptions))
                                }
                                return m(".total", "---")
                            })
                        )
                    )//END table
                ) //END tableCard
            )
        }
    }
}