import m from 'mithril';

// DATA
import { CONTRACTS, PROJECTS, USERS, VENDORS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { DATE_RANGE } from '../../../utils/constants/filtersTypes';
import { settingsStore } from '../../../data/store/settingsStore';


//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';
import { Icon } from '../../components/icon/Icon';
import { Card } from '../../commons/cardLayout/Card';
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { FilterForm } from './FilterForm';
import { CheckCell } from '../../commons/checkCell/CheckCell';
import { Table } from '../../commons/table/Table';

// FUNCTIONS
import { isSystemMode } from '../../../data/utils/viewUtils';
import { findObjParents } from '../../../utils/js';


export const ReportBuilder = (node) => {

    const SELECTED_HEADERS = "selectedHeaders"
    const SELECTED_MODEL = "selectedModel"
    const APPLY_FILTERS = "applyFilters"
    const APPLY_GROUPING = "applyGrouping"
    const SET_PIVOT = "setPivot"
    const PREVIEW = "preview"

    const toggleSelectedModel = modelID => {
        node.state[SELECTED_MODEL] = modelID
        //reset next
        node.state[SELECTED_HEADERS] = []
        node.state[APPLY_FILTERS] = {
            filterMap: [],
            filters: []
        }
        node.state.preview = false
    }

    const toggleSelectHeader = header => {
        const findIndex = node.state[SELECTED_HEADERS].findIndex(h => h === header)
        if (findIndex > -1) {
            node.state[SELECTED_HEADERS].splice(findIndex, 1)
        } else {
            node.state[SELECTED_HEADERS].push(header)
        }
    }

    const toggleSelectAllHeaders = () => {
        const { selectedModel } = node.state
        if (node.state[SELECTED_HEADERS].length > 0) node.state[SELECTED_HEADERS] = []
        else {
            node.state[SELECTED_HEADERS] = Object.keys(dataStore[selectedModel].model.headers)
        }
    }

    const setNext = () => {
        const current = breadCrumbes.find(item => item.key == node.state.stage)
        if (!current) throw `key ${key} not found`
        current.done = true

        const next = breadCrumbes.find(item => item.index === current.index + 1)
        if (next) {
            node.state.stage = next.key
            if (next.key === PREVIEW) {
                node.state.stage = PREVIEW
                node.state.preview = true
            }
        }
    }


    const toggleSelectedStage = key => {
        const stage = breadCrumbes.find(b => b.key === key)
        if (!stage) throw `key ${key} not found`

        if (key === node.state.stage) {
            node.state.stage = false
        } else {
            node.state.stage = key
        }
    }

    const isStageDisabled = ({ key, done }) => {
        const { stage } = node.state
        if (stage === SELECTED_MODEL && !done && key !== stage) {
            return true
        } else if (stage === SELECTED_HEADERS && node.state[SELECTED_HEADERS].length === 0 && key === APPLY_FILTERS) {
            return true
        }
        return false
    }

    const addFilter = () => {
        node.state.prompt = {
            title: "הוספת פילטר",
            class: "full-width",
            form: () => m(FilterForm, { modelID: node.state[SELECTED_MODEL], parent: node }),
        }
    }

    const addGroup = () => {
        node.state.prompt = {
            title: "הוספת קיבוץ",
            class: "full-width",
            form: () => m("button.button", "TODO")
        }
    }

    const toggleSelectedGroup = parent => {
        const findIndex = node.state[APPLY_GROUPING].selectedGroups.findIndex(h => h.modelID === parent)
        if (findIndex > -1) {
            node.state[APPLY_GROUPING].selectedGroups.splice(findIndex, 1)
        } else {
            const group = { modelID: parent, header: "title" }
            node.state[APPLY_GROUPING].selectedGroups.push(group)
            node.state.prompt = {
                title: "עריכת קיבוץ",
                class: "full-width",
                form: () => {
                    return Object.entries(dataStore[parent].model.headers).map(([header, field]) => {
                        const isSelected = group.header === header
                        return m(".", {
                            class: isSelected ? "header--selected" : "header",
                            onclick: e => group.header = header
                        }, header)
                    })
                }
            }
        }
    }

    const addPivot = () => {
        node.state.prompt = {
            title: "הוספת חלוקה",
            class: "full-width",
            form: () => m("button.button", "TODO")
        }
    }

    const showPreview = () => {
        node.state.preview = true

    }

    const breadCrumbes = [
        {
            key: SELECTED_MODEL,
            index: 1,
            done: false,
            icon: "icon-contracts"
        },
        {
            key: SELECTED_HEADERS,
            index: 2,
            done: false,
            icon: "icon-columns"
        },
        {
            key: APPLY_FILTERS,
            index: 3,
            done: false,
            icon: "icon-filter"
        },
        {
            //TODO: apply_groups - by fields (time,switch,month) || by parents : use function findObjParents(settingsStore.dbTree,modelID)
            key: APPLY_GROUPING,
            index: 4,
            done: false,
            icon: "icon-account_tree"
        },
        {
            //TODO: set_pivot - SUM/COUNT/AVG(field) -> pivot by field [ex: sum(contracts) -> pivot by projectManager]
            key: SET_PIVOT,
            index: 5,
            done: false,
            icon: "icon-pivot_table"
        },
        {
            key: PREVIEW,
            index: 6,
            label: "-",
            done: false,
            icon: "icon-eye"
        },
    ]



    // const select = {
    //     doc: {
    //         docData: {
    //             model: false,
    //             modelHeaders: []
    //         }
    //     },
    //     headers: {
    //         model: {
    //             labal: "בחר מודול",
    //             options: Object.keys(dataStore),
    //             change: val => {
    //                 select.doc.docData.model = val
    //             },
    //             remove: val => {
    //                 select.doc.docData.model = false
    //             }
    //         },
    //         modelHeaders: { label: "בחר שדות" },
    //     }
    // }

    const getFilterDate = (id, logic) => {
        const { applyFilters } = node.state
        const find = applyFilters.filters.find(filter => filter.id === id && filter.logic === logic)
        return find ? find.param : ""
    }

    return {
        snackbar: false,
        prompt: false,
        load: true,
        settings: { navOnAdd: true },
        stage: SELECTED_MODEL,
        selectedModel: false,
        selectedHeaders: [],
        applyFilters: {
            filterMap: [],
            filters: []
        },
        applyGrouping: {
            selectedGroups: [],
            totals: {
                totals: (data) => data.length,
                label: "ספירה"
            }
        },
        setPivot: {},
        preview: false,
        oninit:vnode=>{
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
        },
        view: (vnode) => {
            const { stage, selectedModel, selectedHeaders, applyFilters, preview } = vnode.state
            return (
                m(PageLayout, { class: "reportBuilder", title: `מחולל דוחות` },
                    isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.settings.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    m(".reportBuilder__breadCrumbs",
                        breadCrumbes.map(stageItem => {
                            const { done, label, icon, index, key } = stageItem
                            if (key === PREVIEW) return
                            const isActive = key === stage
                            const isDisabled = isStageDisabled(stageItem)
                            return m(`.breadCrumb ${done ? "done" : ""} ${isActive ? "active" : ""} ${isDisabled ? "disabled" : ""}`, {
                                onclick: e => {
                                    if (!isDisabled) toggleSelectedStage(key)
                                }
                            },
                                icon ? m(Icon, { icon }) : label || index
                            )
                        })
                    ),
                    (stage === SELECTED_MODEL) && m(Card, { class: "reportBuilder__selectModel" },
                        m(".caption", "בחירת מודול"),
                        [PROJECTS, USERS, VENDORS, CONTRACTS].map(modelID => {
                            const isSelected = modelID === selectedModel
                            return m(".", {
                                class: isSelected ? "model--selected" : "model",
                                onclick: e => toggleSelectedModel(modelID)
                            }, modelID)
                        }),
                        selectedModel && m("button.button", { onclick: e => setNext() }, "שלב הבא")
                    ),
                    (stage === SELECTED_HEADERS) && m(Card, { class: "reportBuilder__selectHeaders" },
                        m(".caption", "בחירת שדות להצגה"),
                        Object.entries(dataStore[selectedModel].model.headers).map(([header, field]) => {
                            const isSelected = selectedHeaders.includes(header)
                            return m(".", {
                                class: isSelected ? "header--selected" : "header",
                                onclick: e => toggleSelectHeader(header)
                            }, header)
                        }),
                        m(CheckCell, { label: "בחר הכל", setCheck: () => toggleSelectAllHeaders() }),
                        m("button.button", { onclick: e => setNext() }, "שלב הבא")
                    ),
                    (stage === APPLY_FILTERS) && m(Card, { class: "reportBuilder__applyFilters tableCard" },
                        m(".caption", "פילטרים"),
                        m(".filters",
                            applyFilters.filterMap.map(filter => {
                                if (filter.type === DATE_RANGE) {
                                    return m(".filters__row",
                                        m(".filters__caption filters__caption--open", filter.label),
                                        m(".filters__buttons",
                                            filter.options.map(opt => {
                                                return m(".", getFilterDate(filter.header, opt.logic))
                                            })
                                        ),
                                        m(Icon, { icon: "icon-trash" }),
                                    )
                                } else {
                                    return m(".filter__row", filter.type)
                                }
                            }),
                        ),
                        m(".card__actions",
                            m("button.button button--gray", { onclick: e => addFilter() }, "+ הוסף פילטר"),
                            m("button.button", { onclick: e => setNext() }, "הבא"),
                            !preview && m("button.button", { onclick: e => showPreview() }, "תצוגה מקדימה", m(Icon, { icon: "icon-eye" })),
                        )
                    ),
                    (stage === APPLY_GROUPING) && m(Card, { class: "reportBuilder__applyFilters tableCard" },
                        m(".caption", "קיבוץ לפי"),
                        (findObjParents(settingsStore.dbTree, selectedModel) || []).map(parentModel => {
                            const isSelected = selectedHeaders.includes(parentModel)
                            return m(".", {
                                class: isSelected ? "header--selected" : "header",
                                onclick: e => toggleSelectedGroup(parentModel)
                            }, parentModel)
                        }),
                        m(".card__actions",
                            // m("button.button button--gray", { onclick: e => addGroup() }, "+ הוסף קיבוץ"),
                            m("button.button", { onclick: e => setNext() }, "הבא"),
                            !preview && m("button.button", { onclick: e => showPreview() }, "תצוגה מקדימה", m(Icon, { icon: "icon-eye" })),
                        )
                    ),
                    (stage === SET_PIVOT) && m(Card, { class: "reportBuilder__applyFilters tableCard" },
                        m(".caption", "חלוקה לפי"),
                        Object.entries(dataStore[selectedModel].model.headers).map(([header, field]) => { }),
                        m(".card__actions",
                            m("button.button button--gray", { onclick: e => addPivot() }, "+ הוסף חלוקה"),
                            m("button.button", { onclick: e => setNext() }, "הבא"),
                            !preview && m("button.button", { onclick: e => showPreview() }, "תצוגה מקדימה", m(Icon, { icon: "icon-eye" })),
                        )
                    ),
                    (preview) && m(Card, { class: "tableCard" },
                        m(Table, {
                            tableHeaders: selectedHeaders,
                            modelID: selectedModel,
                            colRef: dataStore[selectedModel].model.meta.routes.collection,
                            filterMap: applyFilters.filterMap,
                            filters: applyFilters.filters,
                        })
                    )
                )
            )
        }
    }
}