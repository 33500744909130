import m from "mithril"
import { dataStore } from "../../../data/store/dataStore"

import { CREATE, REMOVE } from "../../../data/store/permissionStore"
import { FileDoc } from "../../../data/store/commons/fileClass"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { isUserAllow } from "../../../data/utils/permission"
import { isDocMatchTerm, isFiltered } from "../../../data/utils/search"
import { MIME_TYPES } from "../../../utils/constants/mimeTypes"
import { REG_URL_LAST_PART } from "../../../utils/constants/regex"
import { fileStatusTypes, statusTypes } from "../../../utils/constants/statusTypes"
import { CURRENCY, DATE, NUMBER, STRING, SWITCH } from "../../../utils/constants/types"
import { asNumber, objType, preventDefaults, uuid } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { Card } from "../cardLayout/Card"
import { FilterTable } from "../filterTable/FilterTable"
import { Prompt } from "../prompt/Prompt"
import { O_ARRAY, O_FUNCTION, O_OBJECT } from "../../../utils/constants/objTypes"
import { handleRemoveFile, promptFile, saveFile, saveLocalBlob } from "./utils"
import { getAddOptions } from "../../../data/utils/utils"
import { FILES } from "../../../data/dictionary/routeNames"
import { DATE_RANGE } from "../../../utils/constants/filtersTypes"
import { DocModel } from "../../../data/store/docModel"
import { TableCell } from "../table/TableCell"
import { defaultFields } from "../../../data/dictionary/internalFields"
import { DEFAULT_USER_NOT_ALLOW_MSG } from "../../../data/store/settingsStore"


export const TableFiles = node => {


    const defaultTableHeaders = {
        title: {
            label: "שם"
        },
        description: {
            label: "הערות",
        },
        status: {
            label: "סטטוס",
        },
        size: {
            label: "גודל",
        },
        createdAt: {
            label: "נוצר ב",
            type: DATE,
            options: { useHours: true }
        },
        contentType: {
            label: "סוג קובץ",
        }
    }


    const buildDefaultFilterMap = (modelDoc, docHeaders) => {
        const _filterMap = []
        Object.entries(docHeaders).forEach(([header, { type, label, options }]) => {
            if (!header in modelDoc.headers) return
            switch (type) {
                case DATE:
                    _filterMap.push({
                        label, header, type: DATE_RANGE,
                        options: [
                            { id: `${header}:after`, text: `מ`, logic: `after` },
                            { id: `${header}:before`, text: "עד", logic: "before" }
                        ]
                    })
                    break;
                case SWITCH:
                    _filterMap.push({ label, header, type, options })
                    break;
            }
        })
        _filterMap.push({
            label: "תאריך יצירה",
            header: "createdAt",
            type: DATE_RANGE,
            options: [
                { id: `createdAt:after`, text: `מ`, logic: `after` },
                { id: `createdAt:before`, text: "עד", logic: "before" }
            ]
        })
        // console.log("TESTME: use default filterMap : ", _filterMap);
        return _filterMap
    }

    const buildTableHeaders = (modelFields = {}, headers = {}) => {
        const obj = {}
        const type = objType(headers)
        if (type === O_OBJECT) {
            Object.entries(headers).forEach(([header, settingsField = {}]) => {
                obj[header] = Object.assign({}, modelFields[header] || {}, defaultFields[header] || {}, defaultTableHeaders[header] || {}, settingsField || {})
            })
        } else if (type === O_ARRAY) {
            headers.forEach(header => {
                obj[header] = Object.assign({}, modelFields[header] || {}, defaultFields[header] || {}, defaultTableHeaders[header] || {})
            })
        }
        // console.log("table headers obj: ", obj);
        return obj
    }

    const onRowAction = (e, doc) => {
        preventDefaults(e)
        const { actions = { onRow: "prompt" } } = node.attrs
        if (!actions.onRow) {
            console.log("no action is defined on row click");
            return
        }
        if (actions.onRow === "prompt") {
            promptFile(doc, node)
        } else if (actions.onRow === "redirect") {
            m.route.set(`/app/${doc.docData.ref}`)
            // } else if (actions.onRow === "subItems") {
            //     toggleSubItems(doc)
        } else {
            if (objType(actions.onRow) === O_FUNCTION) {
                return actions.onRow(node, doc)
            }
        }
    }

    return {
        prompt: false,
        snackbar: false,
        searchTerm: "",
        tableHeaders: {},
        fileInputID: uuid(),
        // files: [],
        searchTerm: "",
        filters: node.attrs.filters || [{ id: "status", param: "archive" }],
        filterMap: node.attrs.filterMap,
        settings: {},
        loader: false,
        oninit: vnode => {
            const { modelID = FILES, tableHeaders, doc, actions = { onAdd: "redirect" }, viewOptions, filterMap } = vnode.attrs
            const Model = dataStore[modelID].model

            vnode.state.tableHeaders = buildTableHeaders(Model.headers, tableHeaders)

            const parentRef = vnode.attrs.colRef.replace(REG_URL_LAST_PART, "")
            try {
                const { unsubscribe, alreadyListen } = dataStore[vnode.attrs.modelID].listen(parentRef)
            } catch (err) {
                console.error(err);
            }

            if (!filterMap) {
                vnode.state.filterMap = buildDefaultFilterMap(DocModel.getChildModel(modelID), vnode.state.tableHeaders)
            }

            if (actions.onAdd === "redirect") {
                node.state.settings.navOnAdd = true
            } else if (actions.onAdd === "prompt") {
                node.state.settings.openEditDocForm = {
                    formHeaders: Object.keys(vnode.state.tableHeaders)
                }
            }

        },
        view: vnode => {
            const { title, doc: instance, modelID, colRef,
                filterDataOptions = {},
                filterSettings = {},
                addDataOptions = {},
                viewOptions = {},
                searchOptions = ["title"],
            } = vnode.attrs
            const { totals: viewTotals = false } = viewOptions
            const { fileInputID, tableHeaders, filterMap, loader } = vnode.state
            const Model = dataStore[modelID].model
            return m(Card, { class: "tableCard tableFiles no-print" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                loader && m(".", loader.total),
                m(FilterTable, {
                    searchTerm: vnode.state.searchTerm,
                    filters: vnode.state.filters,
                    updateSearchTerm: term => vnode.state.searchTerm = term,
                    filterMap
                }),
                (instance && isUserAllow(modelID, CREATE)) && m(`label.button tableCard__add [for=${fileInputID}]`, m(Icon, { icon: "icon-plus" })/*  "הוספת קבצים" */),
                m(`input.[type=file][hidden]#${fileInputID}[multiple]`, {
                    onchange: e => saveLocalBlob(e.target.files, colRef, modelID, getAddOptions(colRef, filterDataOptions, addDataOptions), vnode),
                    accept: [MIME_TYPES.PDF, MIME_TYPES.IMAGE].join(",")
                }),
                m(".caption tableCard__caption", title),
                m(".table", {
                    class: vnode.state.drag ? "droparea" : "",
                    onclick: e => { e.stopPropagation() },
                    ondragover: e => { vnode.state.drag = true; preventDefaults(e) },
                    ondragenter: e => { vnode.state.drag = true; preventDefaults(e) },
                    ondragleave: e => { vnode.state.drag = false; preventDefaults(e) },
                    ondrop: e => {
                        vnode.state.drag = false;
                        preventDefaults(e);
                        if (!isUserAllow(modelID, CREATE)) return vnode.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
                        saveLocalBlob(e.dataTransfer.files, colRef, modelID, getAddOptions(colRef, filterDataOptions, addDataOptions), vnode)
                    }
                },
                    // HEADERS
                    m('.table__headers table__row', { style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);` },
                        Object.entries(tableHeaders).map(([header, settingsField]) => {
                            // console.log(settingsField);
                            // const label = settingsField.label ? settingsField.label : Model.headers[header].label
                            return m('.table__cell', settingsField.label)
                        }),
                    ),
                    //DATA
                    instance.getChildren(modelID, filterDataOptions, filterSettings).map((doc, index) => {
                        if (isFiltered(vnode, doc) || !isDocMatchTerm(vnode, doc, searchOptions)) return
                        return m(".table__row", {
                            "data-ref": doc.docData.ref,
                            key: uuid(),
                            class: doc.isNew ? "table__row--new" : "",
                            style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);`,
                            onclick: e => onRowAction(e, doc)
                        },
                            Object.entries(tableHeaders).map(([header, settingsField]) => {
                                return m(TableCell, { doc, header, settingsField, parent: vnode })
                                // const defaultField = Model.headers[header] || {}
                                // const type = settingsField.type ? settingsField.type : defaultField.type || STRING
                                // const fieldOptions = settingsField.options ? settingsField.options : defaultField.options || {}
                                // return m(".table__cell", getDisplayValue(doc.docData[header], settingsField.type, settingsField.options, doc))
                            }),
                            m(".table__cell table__cell--actions",
                                /* isUserAllow(modelID, REMOVE) &&  */
                                doc.isNew && m("button.button", { onclick: e => saveFile(e, doc) }, "שמור"),
                                isUserAllow(modelID, REMOVE) && m(".table__action", { onclick: e => handleRemoveFile(e, doc, vnode) }, m(Icon, { icon: "icon-delete" })),
                            )
                        )
                    }),
                    //TOTALS ROW:
                    (instance && viewTotals) && m(".table__totals", {
                        style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);`,
                    },
                        Object.entries(tableHeaders).map(([header, settingsField]) => {
                            const defaultField = Model.headers[header] || {}
                            const type = settingsField.type ? settingsField.type : defaultField.type || STRING
                            const fieldOptions = settingsField.options ? settingsField.options : defaultField.options || {}
                            const docs = instance
                                .getChildren(modelID, filterDataOptions, filterSettings)
                                .filter(doc => !isFiltered(vnode, doc) && isDocMatchTerm(vnode, doc, searchOptions))
                            if (header === "title") {
                                return m(".total", `${getDisplayValue(docs.length, NUMBER)} שורות`)
                            }
                            if (fieldOptions.totals) {
                                console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                                const totalValue = fieldOptions.totals.val(docs.sort(sortDocsBy(sortOptions.param)))
                                return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                            }
                            if (type === NUMBER || type === CURRENCY) {
                                let total = 0
                                if (settingsField.val) {
                                    total = docs.reduce((acc, doc) => acc += asNumber(settingsField.val(doc).value), 0)
                                } else {
                                    total = docs.reduce((acc, doc) => acc += asNumber(doc.docData[header]), 0)
                                }
                                return m(".total", getDisplayValue(total, type, fieldOptions))
                            }
                            return m(".total", "---")
                        })
                    ),
                )
            )
        }
    }
}