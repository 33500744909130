import m from "mithril"
import { CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, GROUPS, WORKSPACES } from "../../../../data/dictionary/routeNames"
import { dataStore } from "../../../../data/store/dataStore"
import { editValue, getDisplayValue } from "../../../../data/utils/inputValidation"
import { auth } from "../../../../index"
import { CONTRACT_SECTIONS_ACTUALS } from "../../../../utils/constants/contractsExportsOptions"
import { PAYMENT_TYPES } from "../../../../utils/constants/paymentTypes"
import { CURRENCY, DATE, NUMBER, PERCENT, REF, USER } from "../../../../utils/constants/types"
import { sortDocsBy, uuid } from "../../../../utils/js"
import { Card } from "../../../commons/cardLayout/Card"
import { InputCell } from "../../../commons/inputCell/InputCell"
import { LocalInput } from "../../../commons/inputCell/LocalInput"
import { Table } from "../../../commons/table/Table"
import { Icon } from "../../../components/icon/Icon"
import { IconButton } from "../../../components/icon/IconButton"
import { InlineLoaderDots } from "../../../components/inlineLoaderDots/InlineLoaderDots"
import { exportReportAPI } from "../../reports/utils"
import { confirmActualChanges, promptExportAccountsReport, sectionHasActualChanges } from "../utils"

// attrs:
// navPageView, 
// navPageView_OPTIONS
// contract, 
// loadContract, 
// currentAccount
// parent

export const ContractTotalActualsTable = node => {

    const navToAdditions = async (modelID = "ws_additionsWs") => {
        const { navPageView, navPageView_OPTIONS } = node.attrs
        if (navPageView && navPageView_OPTIONS) {
            await Promise.resolve(navPageView.setActiveKey(navPageView_OPTIONS.SECTIONS))
            const wsAddEl = document.getElementById("ws_additionsWs")
            if (wsAddEl !== null) wsAddEl.scrollIntoView(true)
            return m.redraw()
        }
    }

    const promptDelayWizard = () => {
        const { contract, currentAccount, parent } = node.attrs
        if (!parent) return
        const localChangesDoc = {
            docData: {
                delayPercentage: contract.docData.delayPercentage
            },
            headers: {
                delayPercentage: {
                    type: PERCENT,
                    label: "% עיכבון",
                    options: {
                        change: (value) => {
                            // currentAccount.edit({ delayPercentage: parseFloat(value) })
                            editValue(currentAccount.docData.delayPercentage, parseFloat(value), "delayPercentage", currentAccount)
                            contract.edit({ delayPercentage: parseFloat(value) })
                        },
                    }
                }
            }
        }
        parent.state.prompt = {
            title: "ניהול עכבון",
            class: "full-width delayWizard",
            form: () => m(".",
                m(Table, {
                    sortOptions: { param: "period", order: "desc", type: NUMBER },
                    title: "פירוט עכבון לפי תקופות חשבון",
                    doc: contract,
                    modelID: CONTRACT_ACCOUNTS,
                    colRef: `${contract.docData.ref}/${CONTRACT_ACCOUNTS}`,
                    tableHeaders: {
                        month: {},
                        subTotal: {},
                        delayPercentage: {},
                        totalDelay: {},
                        delayRelease: {}
                    },
                    viewOptions: { add: false, filters: false, download: false },
                    actions: { add: false, remove: false, copy: false, move: false, enlarge: false },
                }),
                m(".",
                    m(".summery__box", { key: uuid() },
                        m(".summery__label", "סהכ יתרת עיכבון"),
                        m(".summery__total", getDisplayValue(contract.getTotalAccounts("totalDelay") - contract.getTotalAccounts("delayRelease"), CURRENCY)),
                    ),
                    m(".formEdit",
                        m(".caption formEdit__caption", "שינוי הגדרות עיכבון"),
                        m(InputCell, {
                            doc: currentAccount,
                            header: "delayRelease", index: 1,
                            id: `${currentAccount.docData.ref}/delayRelease`,
                            parent,
                            editCell: parent.state.editCell
                        }),
                        m(LocalInput, {
                            doc: localChangesDoc,
                            header: "delayPercentage", index: 2,
                            id: `${currentAccount.docData.ref}/delayPercentage`,
                            parent,
                            editCell: parent.state.editCell
                        }),
                        m(".formEdit__actions",
                            (currentAccount.hasChanges("delayRelease") || (currentAccount.hasChanges("delayPercentage") && contract.hasChanges("delayPercentage"))) && m("button.button", {
                                onclick: async (e) => {
                                    try {
                                        await Promise.resolve(currentAccount.save())
                                        await Promise.resolve(contract.save())
                                    } catch (err) {
                                        console.error(err);
                                    } finally {
                                        m.redraw()
                                    }
                                }
                            }, "שמור שינויים")
                        ),
                    )
                )
            )
        }
    }
    return {
        oninit: vnode => {
            const { contract } = vnode.attrs
            contract.listenToChildren()
        },
        view: vnode => {
            const { contract, loadContract, currentAccount, class: classStyle = "", parent } = vnode.attrs
            const { totalDelay, delayRelease, contractSum, calculationMethod: contractType } = contract.docData
            const { [CONTRACT_SECTIONS]: totalSections, [CONTRACT_ADDITIONS]: totalAdditions, [CONTRACT_SUBTRACTIONS]: totalSubtractions } = contract.pivotTotalActuals()
            const contractTotalActuals = totalSections + totalAdditions - totalSubtractions

            const isOddJobs = contractType === "oddJobs"

            //account data
            const accountRef = currentAccount.docData.ref
            const additions = {
                sum: contract.getTotalAdditionsOrSubtractions(CONTRACT_ADDITIONS),
                current: currentAccount.calcTotalAdditions(),
                history: contract.calcLastTotalAdditionsOrSubtractions(accountRef, CONTRACT_ADDITIONS),
            }
            // console.assert(additions.current + additions.history === totalAdditions)
            const subtractions = {
                sum: contract.getTotalAdditionsOrSubtractions(CONTRACT_SUBTRACTIONS),
                current: currentAccount.calcTotalSubtractions(),
                history: contract.calcLastTotalAdditionsOrSubtractions(accountRef, CONTRACT_SUBTRACTIONS),
            }
            const addSub = {
                current: additions.current - subtractions.current,
                history: additions.history - subtractions.history,
                sum: additions.sum - subtractions.sum,
            }
            const sections = {
                history: contract.calcLastTotalActuals({ accountRef }),
                current: currentAccount.calcTotalSections(),
            }
            sections.sum = sections.history + sections.current
            // console.assert(subtractions.current + subtractions.history === totalSubtractions)
            const contractTotals = {
                history: sections.history + additions.history - subtractions.history,
                current: sections.current + additions.current - subtractions.current,
            }
            // console.assert(contractTotals.current + contractTotals.history === contractTotalActuals)
            
            const totalDelayAccounts = contract.getTotalAccounts("totalDelay") - contract.getTotalAccounts("delayRelease")
            const totalDelayHistoryAccounts = contract.getTotalAccounts("totalDelay", { exclude: { month: currentAccount.docData.month } }) - contract.getTotalAccounts("delayRelease", { exclude: { month: currentAccount.docData.month } })
            
            const delayPercentageCalc = totalDelayAccounts / contractTotalActuals
            const delayPercentageHistoryCalc = totalDelayHistoryAccounts / contractTotals.history
            
            const contractBudget = parseFloat(contractSum) + additions.sum - subtractions.sum

            const delayPayload = totalDelayAccounts > 0 ? delayRelease : 0

            return m(Card, { class: `contract__totals reportTable card--scroll ${classStyle}`, columns: 7 },
            parent && m(IconButton, { action: e => promptExportAccountsReport(vnode.attrs.parent, [CONTRACT_SECTIONS_ACTUALS]), icon: "icon-print" }),
            m("h3.accounting__caption caption no-print", "דוח ביצוע מצטבר לחוזה"),
            m(".printTitle",
            contract && m(".heading heading--1", `דוח ביצוע מצטבר - חוזה "${contract.docData.title}"`),
                    m("span.flag", `נוצר ב: ${getDisplayValue(new Date(), DATE)}`),
                    auth.currentUser && m("span.flag", `נוצר עי: ${getDisplayValue(auth.currentUser.uid, USER)}`),
                ),
                (!parent && contract && currentAccount) && [
                    m(".breadCrumbs",
                        m(".breadCrumbs__link", `פרוייקט: ${currentAccount.getProject("title")}`),
                        m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                        m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                        m(".breadCrumbs__link", `חשבון: ${currentAccount.docData.month}`),
                    ),
                ],
                (contract && contract.getTotalPayments({ filterPayments: { include: { paymentType: PAYMENT_TYPES.DOWN_PAYMENT } } }) > 0) && m(".danger row", `שולמו סכומי מקדמות לקבלן על סך ${getDisplayValue(contract.getTotalPayments({ filterPayments: { include: { paymentType: PAYMENT_TYPES.DOWN_PAYMENT } } }), CURRENCY)}`),
                (loadContract) ?
                    m(InlineLoaderDots) :
                    [
                        m(".cell cell--header", "סעיף"),
                        m(".cell cell--header", "תשלום מצטבר קרן"),
                        m(".cell cell--header bold clickable", { onclick: e => promptDelayWizard() }, "עכבון מצטבר ", m(Icon, { icon: "icon-new-tab" })),
                        m(".cell cell--header", "תקופות קודמות"),
                        m(".cell cell--header", "תקופה נוכחית"),
                        m(".cell cell--header", "סכום הסכם"),
                        m(".cell cell--header", "% ביצוע מהסכם"),
                        dataStore[WORKSPACES].data
                            .filter(ws => ws.docData.colRef === `${contract.docData.ref}/workspaces`)
                            .sort(sortDocsBy("timestamp", { type: NUMBER }))
                            .map((ws, wsIndex) => {
                                if (!ws.hasSections()) return
                                return [
                                    m(".row cell cell--title", ws.docData.title),
                                    dataStore[GROUPS].data
                                        .filter(group => group.docData.colRef === `${ws.docData.ref}/groups` && !group.isEmpty(contract, ws))
                                        .map((group, groupIndex) => {
                                            return [
                                                m(".row cell cell--subtitle", group.docData.title),
                                                dataStore[CONTRACT_SECTIONS].data
                                                    .filter(section => section.docData.colRef === `${contract.docData.ref}/sections` && section.isInWorkspace(ws.docData.docID) && section.isInGroup(group))
                                                    .map(section => {
                                                        const budget = parseFloat(section.docData.totalSum)
                                                        const totalActuals = section.calcTotalActuals()
                                                        const currentActuals = section.calcTotalActuals({ accountRef })

                                                        //TESTME: calc by:  Contract.totalDelay * (Section.currentActuals / Contract.totalActuals)
                                                        const totalDelayCalc = parseFloat(totalActuals * delayPercentageCalc)
                                                        const currentDelayCalc = parseFloat(currentActuals * delayPercentageCalc)
                                                        //OLD:
                                                        // const delayPercentage = delayNumber / 100
                                                        // const totalDelayCalc = parseFloat(totalActuals * delayPercentage)
                                                        // const currentDelayCalc = parseFloat(currentActuals * delayPercentage)

                                                        const lastAccountsActuals = section.calcLastTotalActuals({ accountRef })
                                                        const lastAccountsDelayCalc = parseFloat(lastAccountsActuals * delayPercentageHistoryCalc)
                                                        const percentDone = totalActuals / budget * 100

                                                        const currentRelease = delayRelease * (totalActuals / contractTotalActuals)
                                                        let delayCurrentPayload = totalDelayAccounts>0 ?currentDelayCalc + currentRelease : 0
                                                        const hasPendingChanges = sectionHasActualChanges(section, accountRef)
                                                        
                                                        return [
                                                            //"סעיף"
                                                            m(".cell cell--value first clickable", { onclick: e => m.route.set(`/app/${section.docData.ref}`) }, section.docData.title),
                                                            //"תשלום מצטבר קרן"
                                                            m(".cell cell--value", getDisplayValue(totalActuals, CURRENCY, { isFloat: false })),
                                                            //"עכבון מצטבר"
                                                            m(".cell cell--value", getDisplayValue(totalDelayCalc, CURRENCY, { isFloat: false })),
                                                            //"תקופות קודמות"-  history - history.delay
                                                            m(".cell cell--value", getDisplayValue(lastAccountsActuals - lastAccountsDelayCalc, CURRENCY, { isFloat: false })),
                                                            //"תקופה נוכחית"
                                                            m(".cell cell--value bold", {
                                                                class: hasPendingChanges ? "pendingChanges" : "",
                                                                onclick: e => {
                                                                    if (hasPendingChanges) confirmActualChanges(vnode, section, currentAccount)
                                                                }
                                                            }, getDisplayValue(currentActuals - delayCurrentPayload, CURRENCY, { isFloat: false })),
                                                            //"סכום הסכם"
                                                            m(".cell cell--value", getDisplayValue(budget, CURRENCY)),
                                                            //"% ביצוע הסכם"
                                                            m(".cell cell--value", getDisplayValue(percentDone, PERCENT)),
                                                        ]
                                                    })
                                            ]
                                        }),
                                ]
                            }),

                        //CONTRACT TOTALS
                        [{ accountRef: currentAccount.docData.ref }].map(({ accountRef }) => {
                            return [
                                //סהכ סעיפים
                                m(".row__total--sub", "סהכ סעיפים:"),
                                //מצטבר סעיפים קרן 
                                // m(".row__total--sub", getDisplayValue(totalActuals, CURRENCY)),//OLD:
                                m(".row__total--sub", getDisplayValue(totalSections, CURRENCY)),
                                //"עכבון מצטבר"
                                // m(".row__total--sub", getDisplayValue((totalActuals * delayPercentage), CURRENCY)),//OLD:
                                m(".row__total--sub", getDisplayValue(totalSections * delayPercentageCalc, CURRENCY)),//TESTME:
                                //"תקופות קודמות"
                                // m(".row__total--sub", getDisplayValue(lastActuals - (lastActuals * delayPercentage), CURRENCY)),
                                m(".row__total--sub", getDisplayValue(sections.history - (sections.history * delayPercentageHistoryCalc), CURRENCY)),//TESTME:
                                //"תקופה נוכחית"
                                m(".row__total--sub bold", getDisplayValue(sections.current - (sections.current * delayPercentageCalc) + (delayPayload * (sections.sum / contractTotalActuals)), CURRENCY)),
                                //"סכום הסכם"
                                m(".row__total--sub", getDisplayValue(contractSum, CURRENCY)),
                                //"% ביצוע הסכם"
                                m(".row__total--sub", getDisplayValue((totalSections / contractSum * 100), PERCENT)),

                                //פירוט תוספות וקיזוזים
                                !isOddJobs && m(".row cell cell--title", { onclick: e => navToAdditions() }, "תוספות וקיזוזים"),

                                //פירוט תוספות 
                                //"סעיף"
                                !isOddJobs && m(".cell cell--value first clickable", { onclick: e => navToAdditions(CONTRACT_ADDITIONS) }, "תוספות"),
                                //"מצטבר תוספות קרן"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(totalAdditions, CURRENCY, { isFloat: false })),
                                //"עכבון מצטבר"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(totalAdditions * delayPercentageCalc, CURRENCY, { isFloat: false })),
                                //"תקופות קודמות"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(additions.history - (additions.history * delayPercentageHistoryCalc), CURRENCY, { isFloat: false })),
                                //"תקופה נוכחית"
                                !isOddJobs && m(".cell cell--value bold", {
                                    class: contract.childHasPendingChanges(CONTRACT_ADDITIONS) ? "pending" : "",
                                    onclick: e => {
                                        //TODO: -  create function for this case - find all pending docs and confirm all...
                                        // if (hasPendingChanges) confirmAllAditions(accountRef)
                                    }
                                }, getDisplayValue(parseFloat(additions.current - (additions.current * delayPercentageCalc) + (delayPayload * (additions.sum / contractTotalActuals))), CURRENCY, { isFloat: false })),
                                //"סכום הסכם"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(additions.sum, CURRENCY)),
                                //"% ביצוע הסכם"
                                !isOddJobs && m(".cell cell--value", getDisplayValue((totalAdditions / additions.sum) * 100, PERCENT)),

                                //פירוט קיזוזים
                                //"סעיף"
                                !isOddJobs && m(".cell cell--value first clickable", { onclick: e => navToAdditions(CONTRACT_SUBTRACTIONS) }, "קיזוזים"),
                                //"מצטבר קיזוזים קרן"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(totalSubtractions, CURRENCY, { isFloat: false })),
                                //"עכבון מצטבר"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(totalSubtractions * delayPercentageCalc, CURRENCY, { isFloat: false })),
                                //"תקופות קודמות"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(subtractions.history - (subtractions.history * delayPercentageHistoryCalc), CURRENCY, { isFloat: false })),
                                //"תקופה נוכחית"
                                !isOddJobs && m(".cell cell--value bold", {
                                    class: contract.childHasPendingChanges(CONTRACT_SUBTRACTIONS) ? "pending" : "",
                                    onclick: e => {
                                        //TODO: -  create function for this case - find all pending docs and confirm all...
                                        // if (hasPendingChanges) confirmAllAditions(accountRef)
                                    }
                                }, getDisplayValue(parseFloat(subtractions.current - (subtractions.current * delayPercentageCalc) + (delayPayload * (subtractions.sum / contractTotalActuals))), CURRENCY, { isFloat: false })),
                                //"סכום הסכם"
                                !isOddJobs && m(".cell cell--value", getDisplayValue(subtractions.sum, CURRENCY)),
                                //"% ביצוע הסכם"
                                !isOddJobs && m(".cell cell--value", getDisplayValue((totalSubtractions / subtractions.sum) * 100, PERCENT)),

                                //סעיף
                                !isOddJobs && m(".row__total--sub", "סהכ תוספות וקיזוזים:"),
                                //מצטבר קרן
                                !isOddJobs && m(".row__total--sub", getDisplayValue(totalAdditions - totalSubtractions, CURRENCY)),
                                //"עכבון מצטבר"
                                !isOddJobs && m(".row__total--sub", getDisplayValue((totalAdditions - totalSubtractions) * delayPercentageCalc, CURRENCY)),
                                //"תקופות קודמות"
                                !isOddJobs && m(".row__total--sub", getDisplayValue(parseFloat(addSub.history - (addSub.history * delayPercentageHistoryCalc)), CURRENCY)),
                                //"תקופה נוכחית"
                                !isOddJobs && m(".row__total--sub bold", getDisplayValue(addSub.current - (addSub.current * delayPercentageCalc) + (delayPayload * (addSub.sum / contractTotalActuals)), CURRENCY)),
                                //"סכום הסכם"
                                !isOddJobs && m(".row__total--sub", getDisplayValue(addSub.sum, CURRENCY)),
                                //"% ביצוע הסכם"
                                !isOddJobs && m(".row__total--sub", getDisplayValue((totalAdditions - totalSubtractions) / addSub.sum * 100, PERCENT)),

                                //סהכ כולל - חוזה (סעיפים,תוספות וקיזוזים)
                                //סעיף
                                m(".row__total--main", "סהכ כולל:"),
                                //מצטבר קרן
                                m(".row__total--main", getDisplayValue(contractTotalActuals, CURRENCY)),
                                //"עכבון מצטבר"
                                // m(".row__total--main", getDisplayValue(parseFloat(contractTotalActuals * delayPercentageCalc), CURRENCY)),
                                m(".row__total--main", getDisplayValue(totalDelayAccounts, CURRENCY)),
                                //"תקופות קודמות"
                                // m(".row__total--main", getDisplayValue(parseFloat(contractTotals.history - (contractTotals.history * delayPercentageCalc)), CURRENCY)),
                                m(".row__total--main", getDisplayValue(contractTotals.history - totalDelayHistoryAccounts, CURRENCY)),
                                //"תקופה נוכחית"     
                                m(".row__total--main bold", getDisplayValue(parseFloat(contractTotals.current - (contractTotals.current * delayPercentageCalc) + delayPayload), CURRENCY)),
                                //"סכום הסכם"
                                m(".row__total--main", getDisplayValue(parseFloat(contractBudget), CURRENCY)),
                                //"% ביצוע הסכם"
                                m(".row__total--main", getDisplayValue(parseFloat(contractTotalActuals) / parseFloat(contractBudget) * 100, PERCENT)),

                            ]
                        }),
                    ],
            ) // END TOTALS CARD
        }
    }
}