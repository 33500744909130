import m from 'mithril'
import { Tooltip } from '../tooltip/Tooltip'

export const IconButton = vnode => {
    return {
        view: vnode => {
            const {
                isActive = false,
                iconClass = "",
                class: classStyle = "",
                action = () => null,
                icon,
                tooltip = false
            } = vnode.attrs
            return m(".iconButton", {
                "data-tooltip": tooltip ? true : false,
                class: `${classStyle} ${isActive ? 'icon--active' : ''}`,
                onclick: e => action(e)
            },
                m('svg.icon', { class: iconClass ? iconClass : "" },
                    m('use', { href: `/img/icons.svg#${icon}` })
                ),
                tooltip && m(Tooltip, { text: tooltip })
            )
        }
    }
}