export const STRING = "text";
export const OBJECT = "object";
export const DATE = "date";
export const NUMBER = "number";
export const PERCENT = "percent";
export const CURRENCY = "currency";
export const PASSWORD = "password";
export const EMAIL = "email";
export const LIST = "list";
export const MULTICHOICE_LIST = "multichoiceList"
export const MULTICHOICE_SWITCH = "multichoiceSwitch"
export const DIRECTORY = "directory";
export const REF = "ref";
export const USER = "user";
export const FILE = "file";
export const SWITCH = "switch";
export const CHECK_BOOL = "checkBool";


export const DIFF = "diff";



export const VALID_CALC = "calc"
export const VALID_COMPARE = "compare"
export const VALID_UNIQUE = "unique"