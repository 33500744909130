import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';

// DATA
import { Prompt } from '../../commons/prompt/Prompt';
import { Icon } from '../../components/icon/Icon';
import { Snackbar } from '../../components/snackbar/Snackbar';

export const paths = [
    {
        path: "/settings/companies",
        icon: "home",
        text: "פרטי חברה / חברות"
    },
    {
        path: "/settings/users",
        icon: "contacts",
        text: "משתמשים"
    },
    {
        path: "/settings/budgetChapters",
        icon: "payments",
        text: "פרקים תקציב"
    },
    {
        path: "/settings/fieldSwitchOptions",
        icon: "cog",
        text: "הגדרות שדות בחירה"
    },
]



export const Settings = (node) => {


    return {
        snackbar: false,
        prompt: false,
        load: true,
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            sessionStorage.setItem('lastURL', m.route.get());
        },
        view: (vnode) => {
            return m(PageLayout, { class: "settings", title: "הגדרות" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                ),
                m(".tools__nav",
                    paths.map(nav => {
                        return m(".tools__path", { onclick: e => m.route.set(nav.path) }, m(Icon, { icon: `icon-${nav.icon}` }), nav.text)
                    })
                )
            )
        }
    }
}