import m from "mithril"
import { getDisplayValue } from "../../../data/utils/inputValidation";
import { DATE, NUMBER, STRING, SWITCH } from "../../../utils/constants/types";
import { formatDate, subtractDays } from "../../../utils/js";
import { Card } from "../../commons/cardLayout/Card";
import { InputCell } from "../../commons/inputCell/InputCell";
import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots";

// INFO:
//  https://www.cbs.gov.il/he/Pages/%D7%9E%D7%93%D7%93%D7%99-%D7%9E%D7%97%D7%99%D7%A8%D7%99%D7%9D-%D7%91%D7%90%D7%9E%D7%A6%D7%A2%D7%95%D7%AA-API.aspx


export const IndexedCalculator = node => {

    async function calcValue(date, toDate, chapterID, value = 100) {
        node.state.result = false
        node.state.loadResult = true
        try {
            const reqURL = `https://api.cbs.gov.il/index/data/calculator/${chapterID}?value=${value}&date=${date}&toDate=${toDate}`
            const result = await fetch(reqURL);
            const { answer } = await result.json();
            if (answer && answer.to_value) {
                node.state.result = answer.to_value
            }
        } catch (err) {
            console.error(err);
            node.state.error = err
        } finally {
            node.state.loadResult = false
            m.redraw()
        }
    }

    const headers = {
        date: { label: "התחלה", type: DATE },
        toDate: { label: "סיום", type: DATE },
        chapterID: { label: "פרק", type: SWITCH, options: indexedFactorOptions },
        value: { label: "סכום להצמדה" }
    }

    const indexedFactorOptions = [
        { text: 'מדד המחירים לצרכן', id: '120010' },
        { text: 'מדד מחירי תשומה בבנייה למגורים', id: '200010' },
        { text: 'מדד מחירי תשומה בבנייה למסחר ולמשרדים', id: '800010' },
    ]

    return {
        doc: false,
        oninit: vnode => {
            vnode.state.doc = {}
            vnode.state.doc.date = vnode.attrs.fromDate ? formatDate(new Date(vnode.attrs.fromDate)) : formatDate(subtractDays(new Date(), 365))
            vnode.state.doc.toDate = formatDate(new Date())
            vnode.state.doc.value = parseFloat(vnode.attrs.value || 100)
            vnode.state.doc.chapterID = vnode.attrs.chapterID || 200010
        },
        view: vnode => {
            const { result, loadResult, error, doc } = vnode.state
            const { date, toDate, chapterID, value } = doc
            return m(Card, { class: "indexCalc__info" },
                m(".caption", "מחשבון"),
                doc && ["chapterID", "date", "toDate", "value"].map(header => {
                    return m(".input",
                        m("input.input__field", {
                            // type: headers[header].type || "text",
                            value: doc[header],
                            oninput: e => {
                                vnode.state.doc[header] = e.target.value
                                vnode.state.result = false
                            }
                        }),
                        m("label.input__label", headers[header].label)
                    )
                }),
                doc && m("button.button", { onclick: e => calcValue(date, toDate, chapterID, value) }, "חשב ערך הצמדה"),
                loadResult && m(InlineLoaderDots),
                (!loadResult && result) && m(".info", getDisplayValue(result, NUMBER, { isFloat: true })),
                error && m(".danger", error)
            )
        }
    }
}