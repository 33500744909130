import m from "mithril"

import { FIELDS_SWITCH_OPTIONS } from "../../../data/dictionary/routeNames"
import { DocModel } from "../../../data/store/docModel"

import { PageLayout } from "../../commons/pageLayout/Page"
import { Prompt } from "../../commons/prompt/Prompt"
import { Table } from "../../commons/table/Table"
import { Snackbar } from "../../components/snackbar/Snackbar"

export const SystemFields = vnode => {

    return {
        headers: [
            "title",
            "text",
            "description",
            "index",
            "next",
            "color", //BUG:
            "in"
        ],
        snackbar: false,
        prompt: false,
        view: vnode => {
            return m(PageLayout, {},
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Table, {
                    title: "רשימת שדות מערכת",
                    modelID: FIELDS_SWITCH_OPTIONS,
                    colRef: `settings/system/${FIELDS_SWITCH_OPTIONS}`,
                    doc: DocModel,
                    tableHeaders: vnode.state.headers,
                    searchOptions: ["title", "description", "text"],
                    actions: { onRow: "prompt" },
                })
            )
        }
    }
}