import { NOTIFICATION, USERS } from "../../dictionary/routeNames";
import { STRING, DATE, REF, VALID_COMPARE, SWITCH, LIST } from "../../../utils/constants/types";
import { DocModel } from "../docModel";
import { taskStatsM } from "../../models/app/taskState";
import { auth } from "../../../index";
import { UserDoc } from "../users/userClass";
import { dateAfter, dateBefore, sortDocsBy } from "../../../utils/js";
import { dataStore } from "../dataStore";



export class NotificationDoc extends DocModel {
    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: NotificationDoc,
        });
    };

    static get meta() {
        return {
            id: NOTIFICATION,
            routes: {
                collection: `/${NOTIFICATION}`,
                doc: `/${NOTIFICATION}/:msgID`,
            },
            conditionalFormatting: [
                {
                    header: "due",
                    logic: VALID_COMPARE,
                    expression: (docData) => dateAfter(new Date(docData.due), new Date()) || docData.status === "finish",
                    classStyle: "danger",
                }
            ],
        }
    }


    static get headers() {
        return {
            title: { label: 'נושא', defaultValue: "--התראה--", type: STRING },
            description: { label: 'תאור', defaultValue: "--ללא תיאור--", type: STRING },
            status: { label: "סטטוס", options: taskStatsM.options, type: SWITCH, defaultValue: "active" },
            due: { label: 'לביצוע עד', defaultValue: new Date().toISOString(), type: DATE },
            user: {
                label: 'אחראי', defaultValue: () => `users/${auth.currentUser.uid}`, type: LIST,
                options: {
                    data: dataStore[USERS].data.sort(sortDocsBy("displayName", { type: STRING })),
                    load: () => dataStore[USERS].listen(),
                    model: UserDoc,
                    formHeaders: ["title", "displayName"]
                }
            },
        }
    }
}