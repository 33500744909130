import m from 'mithril'

export const Icon = vnode => {
    return {
        view: vnode => {
            return (
                m('svg.icon', {
                    class: `${vnode.attrs.class || ''} ${vnode.attrs.isActive ? 'icon--active' : ''}`,
                    onclick: e => vnode.attrs.action ? vnode.attrs.action(e) : null
                },
                    m('use', { href: `/img/icons.svg#${vnode.attrs.icon}` })
                )
            )
        }
    }
}