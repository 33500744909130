import m from 'mithril';
import { editValue, getDisplayValue, getInputType, getParsedValue } from '../../../data/utils/inputValidation';
import { NUMBER, PERCENT, CURRENCY, DATE } from '../../../utils/constants/types';
import { Icon } from '../../components/icon/Icon';
import { IconError } from '../iconError/IconError';
import { Prompt } from '../prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { preventDefaults } from '../../../utils/js';
import { Calculator } from '../../pages/calculator/Calculator';
import { DISABLED } from '../../../utils/constants/inputAttrs';
import { setPropetiesToComponent } from '../../../utils/mithril';


export const LocalInput = node => {

    const editLocalValue = (value) => {
        const { doc, header, key, parent } = node.attrs
        parent.state[key || header] = value
        doc.docData[header] = value
        const { options } = doc.headers[header]
        if (options && options.change) {
            return options.change(value)
        }
    }

    const saveEvent = (value) => {
        const { doc, header } = node.attrs
        const { options } = doc.headers[header]
        if (options && options.save) {
            return options.save(value)
        }
    }

    const openCalculator = (e) => {
        preventDefaults(e);
        let isEditable = true
        const { doc, header, key, parent } = node.attrs
        const field = doc.headers[header]
        if (field.props) {
            field.props.forEach(prop => {
                const [[key, value]] = Object.entries(prop)
                if (key === DISABLED && value == true) {
                    isEditable = false
                }
            })
        }
        node.state.prompt = {
            title: "מחשבון",
            form: () => {
                return [
                    m(Calculator, { parent: node }),
                    node.state.tempCalc && m(".", { style: "font-size:1.4rem;border-top: 1px solid gray;" }, getDisplayValue(node.state.tempCalc, NUMBER, { isFloat: true })),
                    (node.state.tempCalc && isEditable) && m("button.button", {
                        style: "max-width:auto;",
                        onclick: e => {
                            const { header, doc } = node.attrs
                            const value = node.state.tempCalc;
                            if (value && !isNaN(parseFloat(value) && value !== 0)) {
                                editLocalValue(value)
                            }
                            node.state.prompt = false
                        }
                    }, "השתמש בתוצאה כערך")
                ]
            }
        }
    }

    return {
        listOpen: false,
        oldValue: "",
        error: false,
        prompt: false,
        searchTerm: "",
        view: vnode => {
            const { doc, header, key, class: styleClass, parent } = vnode.attrs
            const field = doc.headers[header]
            const value = doc.docData[header]
            const { options, ignoreLabel, editToggle = true, tooltip = true } = field
            const id = key || header
            return m(".cell [data-tooltip]", {
                class: styleClass ? styleClass : ""
            },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || "הוספה של רשומה חדשה"),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                ),
                m(IconError, { error: vnode.state.error }),
                tooltip && m(Tooltip, { text: `${!ignoreLabel ? `${field.label}:\n` : ""}${getDisplayValue(value, field.type, field.options, doc.docData)}` }),
                m(".cell__input input ",
                    (parent.state.editCell === id || editToggle == false) ?
                        m(`input.input__field`, {
                            id,
                            // value: getParsedValue(value, field.type),
                            type: getInputType(field.type),
                            oncreate: el => {
                                el.dom.focus();
                                setPropetiesToComponent(el, field.props, doc);
                                // if (field.type !== DATE) {}
                                el.dom.value = getParsedValue(value, field.type)
                                el.dom.select()
                            },
                            onupdate: el => {
                                if (field.type !== DATE) {
                                    el.dom.value = value
                                }
                            },
                            onfocus: e => {
                                e.target.oldVal = e.target.value
                                // e.target.setSelectionRange(0, e.target.value.length)
                            },
                            oninput: e => {
                                const val = editLocalValue(e.target.value);
                                if (val) e.target.value = val
                            },
                            onkeypress: ({ key, target }) => { if (key == "Enter") saveEvent(target.value) },
                            onblur: e => {
                                if (field.type === DATE) {
                                    e.target.value = getParsedValue(value, field.type)
                                }
                                saveEvent(e.target.value)
                            }
                        })
                        :
                        m(".input__field input__field--text cell__field--text", {
                            class: vnode.state.error ? "input__field--error" : "",
                            onfocus: e => parent.state.editCell = id,
                            onclick: e => parent.state.editCell = id
                        },
                            getDisplayValue(value, field.type),
                        ),
                    !ignoreLabel && m(`label.input__label cell__label`, {
                        class: vnode.state.error ? "input__label--error" : "",
                        for: id,
                        onclick: e => {
                            // console.log(field)
                            parent.state.editCell = id;
                            if (vnode.state.error) {
                                vnode.state.showError = !vnode.state.showError
                            }
                        },
                        "data-error": vnode.state.error
                    }, field.label),
                    [NUMBER, PERCENT, CURRENCY].includes(field.type) && m(Icon, { icon: "icon-dots-vertical", class: "menuButton", action: e => openCalculator(e) })
                )
            )
        }
    }
}