import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Card } from '../../../commons/cardLayout/Card';

//DATA
import { PROJECTS, CONTRACTS, CONTRACT_ACCOUNTS, CONFIRMS, ACTUALS } from '../../../../data/dictionary/routeNames';
import { REG_URL_LAST_PART$NQ } from '../../../../utils/constants/regex';
import { dataStore } from '../../../../data/store/dataStore';

// FUNCTIONS
import { Prompt } from '../../../commons/prompt/Prompt';
import { Snackbar } from '../../../components/snackbar/Snackbar';
import { InputCell } from '../../../commons/inputCell/InputCell';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { getDisplayValue } from '../../../../data/utils/inputValidation';
import { Icon } from '../../../components/icon/Icon';
import { preventDefaults, sortDocsBy } from '../../../../utils/js';
import { handleConfirmStage, handleAddAccount, getCurrentAccount } from '../utils';
import { AccountDoc } from '../../../../data/store/contractsActuals/accountClass';
import { MonthSelector } from '../../../commons/confirmBlock/MonthSelect';
import { isDocMatchTerm, isFiltered } from '../../../../data/utils/search';
import { FilterTable } from '../../../commons/filterTable/FilterTable';
import { db } from '../../../../index';
import { ConfirmDoc } from '../../../../data/store/contractsActuals/confirmClass';
import { ActualDoc } from '../../../../data/store/contractsActuals/actualClass';
import { PopUpActual } from './PopUpActual';
import { ContractDoc } from '../../../../data/store/contracts/contractClass';
import { FormEditDoc } from '../../../commons/prompt/FormEditDoc';
import { CURRENCY, NUMBER } from '../../../../utils/constants/types';
import { handleCopy } from '../../../../data/utils/utils';
import { LocalInput } from '../../../commons/inputCell/LocalInput';
import { MAX, MIN } from '../../../../utils/constants/inputAttrs';

export const ContractAdmin = node => {

    const findContract = () => {
        const contract = dataStore[CONTRACTS].data.find(doc => doc.docData.docID === node.attrs.contractID)
        if (contract) {
            node.state.contract = contract
        } else {
            const navToPath = m.route.get().replace(REG_URL_LAST_PART$NQ, "")
            m.route.set(navToPath);
        }
        return dataStore[CONTRACTS].data.find(doc => doc.docData.docID === node.attrs.contractID)
    }

    const promptDoc = (e, doc, headers) => {
        preventDefaults(e)
        node.state.prompt = {
            class: "full-width",
            title: doc.docData.title,
            form: () => m(FormEditDoc, { doc, headers, parent: node })
        }
    }

    const confirmChanges = () => {
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: "האם לשמור את השינויים שבוצעו בחוזה?",
            actions: [
                {
                    action: e => {
                        node.state.snackbar = { msg: "שומר..." };
                        node.state.contract.save();
                        node.state.prompt = false
                        node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                        m.redraw()
                    },
                    text: "שמור"
                }
            ]
        }
    }

    const contractHeaders = [
        'title',
        "contractorRef",
        "isIndexed",
        "indexedFactor",
        'budgetbudgetaryItem',
        'contractSum',
        'paymentDelay',
        'calculationMethod',
        'delayPercentage',
        'sWorkDate',
        'eWorkDate',
        'donePercentage',
        'status',
        'description',
    ]

    const handleSplitAccount = (e, account) => {
        node.state.snackbar = { msg: "פעולה זו לא פעילה עדיין!", isError: true }
        // preventDefaults(e)
        // const splitAccountWizardForm = {
        //     docData: {
        //         subTotal1: account.docData.subTotal,
        //         subTotal2: 0
        //     },
        //     headers: {
        //         subTotal1: {
        //             type: CURRENCY,
        //             label: "סכום חשבון 1",
        //             props: [{ [MAX]: account.docData.subTotal }, { [MIN]: 0 }],
        //             options: {
        //                 change: (value) => {
        //                     splitAccountWizardForm.docData.subTotal2 = parseInt(account.docData.subTotal) - parseInt(value)
        //                 },
        //             }
        //         },
        //         subTotal2: {
        //             type: CURRENCY,
        //             label: "סכום חשבון 2",
        //             options: {
        //                 change: (value) => {
        //                     splitAccountWizardForm.docData.subTotal1 = parseInt(account.docData.subTotal) - parseInt(value)
        //                 },
        //             }
        //         }
        //     }

        // }
        // node.state.prompt = {
        //     title: "אשף פיצול חשבונות",
        //     msg: `האם לפצל את החשבון "${account.docData.month}" לשתי חלקים ?`,
        //     class: "full-width",
        //     form: () => {
        //         return m(".",
        //             m(".row info", `סהכ סכום חשבון מקורי : ${getDisplayValue(account.docData.subTotal, CURRENCY)}`),
        //             m(LocalInput, { doc: splitAccountWizardForm, header: "subTotal1", parent: node }),
        //             m(LocalInput, { doc: splitAccountWizardForm, header: "subTotal2", parent: node }),
        //             m("button.button", {
        //                 onclick: e => {
        //                     try {
        //                         account.splitAccount(splitAccountWizardForm.docData.subTotal1, splitAccountWizardForm.docData.subTotal2)
        //                     } catch (err) {
        //                         node.state.snackbar = { msg: err, isError: true }
        //                     }
        //                 }
        //             }, "פצל")
        //         )
        //     },
        // }
    }


    const handleDeleteAccount = (e, account) => {
        node.state.prompt = {
            title: "מחיקה",
            msg: "מחיקה מלאה של חשבון [אדמין בלבד]",
            actions: [
                {
                    text: "מחק",
                    action: evt => {
                        db.doc(account.docData.ref).delete()
                            .then(() => {
                                m.redraw()
                                node.state.snackbar = { msg: `המסמך נמחק` }
                            })
                            .catch(err => {
                                node.state.snackbar = { msg: `אירעה שגיאה:${err}`, isError: true }
                            })
                        node.state.prompt = false
                    }
                }
            ]
        }
    }
    const handleDeleteConfirm = (e, confirm) => {
        node.state.prompt = {
            title: "מחיקה",
            msg: "מחיקה מלאה של שלב אישור [אדמין בלבד]",
            actions: [
                {
                    text: "מחק",
                    action: evt => {
                        db.doc(confirm.docData.ref).delete()
                            .then(() => {
                                m.redraw()
                                node.state.snackbar = { msg: `המסמך נמחק` }
                            })
                            .catch(err => {
                                node.state.snackbar = { msg: `אירעה שגיאה:${err}`, isError: true }
                            })
                        node.state.prompt = false
                    }

                }
            ]
        }
    }

    const handleAddActual = () => {
        if (node.state.selectedAccount) {
            const newDoc = new ActualDoc({ colRef: `${node.state.selectedAccount.docData.ref}/actuals` }, true);
            const contractRef = node.state.contract.docData.ref
            node.state.prompt = {
                title: "הוסף ביצוע",
                form: () => m(PopUpActual, { parentNode: node, newDoc, contractRef })
            }
        }
    }

    const showEditForm = (doc) => {
        if (doc) {
            node.state.prompt = {
                title: "טופס",
                class: "full-width",
                form: () => {
                    return m(FormEditDoc, { doc, headers: doc.headers, parent: node })
                }
            }
        }
    }

    const defaultAccountsHeaders = ["title", "month", "period", "stage"]
    const defaultConfirmHeaders = [
        "title",
        "index",
        "type",
        "due",
        "role",
        "next",
        "confirm"
    ]
    const defaultActualHeaders = ["title", "unit", "sectionRef", "value"]


    const toggleSelectedAccount = (account) => {
        if (isSelectedAccount(account)) node.state.selectedAccount = ""
        else setSelectedAccount(account)
    }
    const setSelectedAccount = (account) => node.state.selectedAccount = account
    const isSelectedAccount = (account) => node.state.selectedAccount !== "" && node.state.selectedAccount.docData.ref === account.docData.ref

    const getCurrentClass = (confirm) => {
        let classList = ""
        const accountStage = node.state.selectedAccount.docData.stage
        if (confirm.docData.id === accountStage) {
            classList += " table__row--current"
        }
        if (confirm.docData.confirm === true) {
            classList += " table__row--done"
        }
        return classList
    }

    const promptEditConfirm = (doc) => {
        node.state.prompt = {
            title: "עריכה",
            class: "full-width",
            form: () => {
                return m(FormEditDoc, { doc, headers: node.state.confirmHeaders, parent: node })
            }
        }
    }

    const isRemoved = () => node.state.contract && node.state.contract.isRemoved

    return {
        loadContract: true,
        loadSections: true,
        selectedAccount: "",
        selectedMonth: "",
        loadMilestones: {},
        filters: [],
        searchTerm: "",
        accountsHeaders: [...defaultAccountsHeaders],
        confirmHeaders: [...defaultConfirmHeaders],
        actualHeaders: [...defaultActualHeaders],
        prompt: false,
        snackbar: false,
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
            sessionStorage.setItem('lastURL', m.route.get());

            const projectPath = `projects/${vnode.attrs.projectID}`
            dataStore[PROJECTS].listen()
            const { alreadyListen } = dataStore[CONTRACTS].listen(projectPath)
            if (alreadyListen) {
                findContract()
                vnode.state.loadContract = false;
                const contractPath = vnode.state.contract.docData.ref
                const { alreadyListen: isListenToAccounts } = dataStore[CONTRACT_ACCOUNTS].listen(contractPath)
                if (isListenToAccounts) getCurrentAccount(node)
                else setTimeout(() => getCurrentAccount(node), 3000);
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען פרטי חוזה...", loading: true }
                setTimeout(() => {
                    findContract()
                    vnode.state.loadContract = false
                    const contractPath = vnode.state.contract.docData.ref
                    const { alreadyListen: isListenToAccounts } = dataStore[CONTRACT_ACCOUNTS].listen(contractPath)
                    if (isListenToAccounts) getCurrentAccount(node)
                    else setTimeout(() => getCurrentAccount(node), 3000);
                    m.redraw()
                }, 3000)

            }
        },
        view: vnode => {
            return (
                m(PageLayout, {
                    class: "adminContract",
                    title: vnode.state.contract ? vnode.state.contract.docData.title : "חוזה"
                },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.selectMonth && m(MonthSelector, {
                            lastMonth: vnode.state.prompt.selectMonth,
                            contract: vnode.state.contract,
                            type: vnode.state.prompt.type || "newAccount",
                            label: vnode.state.prompt.label || "תקופה חדשה",
                            setSelected: month => vnode.state.selectedMonth = month
                        }),
                        vnode.state.prompt.note && m("textarea.prompt__note [placeholder='הוסף הערות']", {
                            rows: 4,
                            oninput: e => { if (e.target.value.trim() !== "") vnode.state.note = e.target.value }
                        }),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        ),
                    ),
                    (vnode.state.contract && isRemoved()) ?
                        "חוזה זה נמחק" :
                        vnode.state.contract && m(Card, { class: "formCard adminContract__details contract__details" },
                            m("h3.formCard__caption caption", "פרטי החוזה"),
                            vnode.state.loadContract && m(InlineLoaderDots),
                            vnode.state.contract && contractHeaders.map((header, index) => {
                                const value = vnode.state.contract.docData[header]
                                const field = ContractDoc.headers[header]
                                return m(InputCell, {
                                    header, value, field, index, id: `contract_${header}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.contract,
                                    parent: vnode
                                })
                            }),
                            vnode.state.contract && Object.keys(vnode.state.contract.docChanges).length > 0 && m("button.button formCard__save", { onclick: e => confirmChanges() }, "שמור שינויים")
                        ),
                    (vnode.state.contract && !isRemoved()) && [
                        m(Card, { class: "adminContract__accounts tableCard" },
                            m("h3.caption tableCard__caption", "חשבונות"),
                            m(FilterTable, {
                                searchTerm: vnode.state.searchTerm,
                                filters: vnode.state.filters,
                                updateSearchTerm: term => vnode.state.searchTerm = term,
                                // filterMap: []
                            }),
                            m("button.button tableCard__add", { onclick: e => handleAddAccount(vnode) }, m(Icon, { icon: "icon-plus" })),
                            //HEADERS
                            m('.table__headers table__row', { style: `grid-template-columns: repeat(${vnode.state.accountsHeaders.length},1fr);` },
                                vnode.state.accountsHeaders.map(h => m('.table__header table__cell', AccountDoc.headers[h].label)),
                            ),
                            //ROWS
                            vnode.state.contract.getChildren(CONTRACT_ACCOUNTS)
                                .sort(sortDocsBy("period", { type: NUMBER }))
                                .map(doc => {
                                    try {
                                        if (isFiltered(vnode, doc) || !isDocMatchTerm(vnode, doc, ["title"])) return
                                        return m(".table__row", {
                                            "data-ref": doc.docData.ref,
                                            class: isSelectedAccount(doc) ? "table__row--selected" : "",
                                            style: `grid-template-columns: repeat(${vnode.state.accountsHeaders.length},1fr);`,
                                            onclick: e => toggleSelectedAccount(doc)
                                        },
                                            vnode.state.accountsHeaders.map(h => m(".table__cell", getDisplayValue(doc.docData[h], AccountDoc.headers[h].type, AccountDoc.headers[h].options))),
                                            m(".table__cell table__cell--actions",
                                                m(".table__action", { onclick: e => promptDoc(e, doc, AccountDoc.headers) }, m(Icon, { icon: "icon-enlarge" })),
                                                m(".table__action", { onclick: e => handleSplitAccount(e, doc) }, m(Icon, { icon: "icon-merge" })),
                                                m(".table__action", { onclick: e => handleDeleteAccount(e, doc) }, m(Icon, { icon: "icon-delete" })),
                                            )
                                        )
                                    } catch (err) {
                                        console.error(err);
                                    }
                                })
                        ),

                        // TODO: NOTES BLOCK

                        /**************************************
                                TODO: ACTUALS BLOCK
                        **************************************/
                        vnode.state.selectedAccount && m(Card, { class: "adminContract__actuals tableCard" },
                            m("h3.caption tableCard__caption", "ביצוע"),
                            // m(FilterTable, {
                            //     searchTerm: vnode.state.searchTerm,
                            //     filters: vnode.state.filters,
                            //     updateSearchTerm: term => vnode.state.searchTerm = term,
                            //     filterMap: []
                            // }),
                            vnode.state.selectedAccount && m("button.button tableCard__add", { onclick: e => handleAddActual() }, "צור ביצוע"),
                            //HEADERS
                            m('.table__headers table__row', { style: `grid-template-columns: repeat(${vnode.state.actualHeaders.length},1fr);` },
                                vnode.state.actualHeaders.map(h => m('.table__header table__cell', ActualDoc.headers[h].label)),
                            ),
                            //ROWS
                            dataStore[ACTUALS].data
                                .filter(doc => doc.docData.colRef === `${vnode.state.selectedAccount.docData.ref}/actuals`)
                                .map(doc => {
                                    // if (isFiltered(vnode, doc) || !isDocMatchTerm(vnode, doc, ["title"])) return
                                    return m(".table__row", {
                                        // class: getCurrentClass(doc),
                                        onclick: e => showEditForm(doc),
                                        style: `grid-template-columns: repeat(${vnode.state.actualHeaders.length},1fr);`,
                                    },
                                        vnode.state.actualHeaders.map(h => m(".table__cell", getDisplayValue(doc.docData[h], ActualDoc.headers[h].type, ActualDoc.headers[h].options))),
                                        m(".table__cell table__cell--actions",
                                            m(".table__action", {/* onclick: e =>  handleCopyActual(e, doc) */ }, m(Icon, { icon: "icon-copy" })),
                                            m(".table__action", { /* onclick: e => handleDeleteActual(e, doc) */ }, m(Icon, { icon: "icon-delete" })),
                                        )
                                    )
                                })
                        ), //END ACTUALS BLOCK

                        // CONFIRMS BLOCK
                        vnode.state.selectedAccount && m(Card, { class: "adminContract__confirms tableCard" },
                            m("h3.caption tableCard__caption", "אישורים"),
                            // m(FilterTable, {
                            //     searchTerm: vnode.state.searchTerm,
                            //     filters: vnode.state.filters,
                            //     updateSearchTerm: term => vnode.state.searchTerm = term,
                            //     filterMap: []
                            // }),
                            //TESTME: this 
                            // m(Icon, { icon: "icon-plus", action: e => vnode.state.selectedAccount.addConfirms(true) }),
                            
                            m(Icon, { icon: "icon-reload", action: e => vnode.state.selectedAccount.syncConfirms() }),
                            m("button.button tableCard__add", { onclick: e => handleConfirmStage(vnode) },
                                vnode.state.selectedAccount.stage === "start" ? "אתחול תקופה" :
                                    vnode.state.selectedAccount.stage === "finish" ? "תקופה חדשה"
                                        : "אישור שלב"
                            ),
                            //HEADERS
                            m('.table__headers table__row', { style: `grid-template-columns: repeat(${vnode.state.confirmHeaders.length},1fr);` },
                                vnode.state.confirmHeaders.map(h => m('.table__header table__cell', ConfirmDoc.headers[h].label)),
                            ),
                            //ROWS
                            dataStore[CONFIRMS].data
                                .filter(doc => doc.docData.colRef === `${vnode.state.selectedAccount.docData.ref}/confirms`)
                                .sort(sortDocsBy("index", { type: NUMBER }))
                                .map(doc => {
                                    // if (isFiltered(vnode, doc) || !isDocMatchTerm(vnode, doc, ["title"])) return
                                    return m(".table__row", {
                                        onclick: e => promptEditConfirm(doc),
                                        class: getCurrentClass(doc),
                                        style: `grid-template-columns: repeat(${vnode.state.confirmHeaders.length},1fr);`,
                                    },
                                        vnode.state.confirmHeaders.map(h => m(".table__cell", getDisplayValue(doc.docData[h], ConfirmDoc.headers[h].type, ConfirmDoc.headers[h].options))),
                                        m(".table__cell table__cell--actions",
                                            // m(".table__action", {/* onclick: e =>  handleCopy(e, doc) */ }, m(Icon, { icon: "icon-copy" })),
                                            m(".table__action", { onclick: e => handleDeleteConfirm(e, doc) }, m(Icon, { icon: "icon-delete" })),
                                        )
                                    )
                                })
                        ), //END CONFIRMS BLOCK

                    ]
                ) //END pageLayout
            ) //END return
        }
    }
}