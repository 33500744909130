import m from "mithril"
import { DEFAULT_FIELDS } from "../../../data/dictionary/internalFields"
import { NOTIFICATION } from "../../../data/dictionary/routeNames"
import { DocModel } from "../../../data/store/docModel"
import { auth } from "../../../index"
import { PageLayout } from "../../commons/pageLayout/Page"
import { Prompt } from "../../commons/prompt/Prompt"
import { Table } from "../../commons/table/Table"
import { Snackbar } from "../../components/snackbar/Snackbar"

export const Tasks = vnode => {

    return {
        snackbar: false,
        prompt: false,
        oninit: vnode => {
            window.scrollTo(0, 0);
console.log("routeChange",m.route.get())
        },
        view: vnode => {
            return m(PageLayout, {},
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(Table, {
                    title: "התראות מערכת",
                    modelID: NOTIFICATION,
                    colRef: "notification",
                    doc: DocModel,
                    tableHeaders: ["title", "description", "status", "due", DEFAULT_FIELDS.CREATED_AT, DEFAULT_FIELDS.CREATED_BY, "user"],
                    searchOptions: ["title", "description"],
                    actions: { onRow: "prompt" },
                })
            )
        }
    }
}