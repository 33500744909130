import { STRING, NUMBER } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH } from "../../../utils/constants/inputAttrs";
import { BUDGET_CHAPTERS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";

export class BudgetDoc extends DocModel {

    constructor(_data, isNew = true) {
        const data = Object.assign({}, _data)
        if (isNew && !("colRef" in data)) data.colRef = "settings/system/budgetChapters"
        super({
            data,
            isNew,
            model: BudgetDoc,
        });
        if (!this.isNew) this.listenToChildren()
    };
    static get meta() {
        return {
            id: BUDGET_CHAPTERS,
            routes: {
                collection: "settings/system/budgetChapters",
                doc: "settings/system/budgetChapters/:chapterID",
            },
        }
    }
    static get headers() {
        return {
            title: { label: "שם הפרק", defaultValue: "--פרק חדש--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            number: { label: "פרק מספר", defaultValue: "--מספר--", type: STRING },
            description: { label: 'תיאור והערות', defaultValue: "--ללא תיאור--", type: STRING },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
        }
    }
}