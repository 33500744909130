import m from "mithril"
import { buildHeaders } from "../../../../data/CRUD/utils"
import { CONTRACT_COMMENTS, PAYMENTS } from "../../../../data/dictionary/routeNames"
import { ContractDoc } from "../../../../data/store/contracts/contractClass"
import { PaymentDoc } from "../../../../data/store/contractsActuals/paymentClass"
import { DocModel } from "../../../../data/store/docModel"
import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { db } from "../../../../index"
import { paymentChannels, PAYMENT_CHANNELS } from "../../../../utils/constants/paymentChannels"
import { paymentTypes, PAYMENT_TYPES } from "../../../../utils/constants/paymentTypes"
import { CURRENCY, DIFF, PERCENT, SWITCH } from "../../../../utils/constants/types"
import { asNumber, calcPaymentDelay, textToDate } from "../../../../utils/js"
import { CheckCell } from "../../../commons/checkCell/CheckCell"
import { DisplayCell } from "../../../commons/inputCell/DisplayCell"
import { InputCell } from "../../../commons/inputCell/InputCell"
import { Prompt } from "../../../commons/prompt/Prompt"
import { Table } from "../../../commons/table/Table"
import { Icon } from "../../../components/icon/Icon"
import { Snackbar } from "../../../components/snackbar/Snackbar"

export const FormEditPayment = node => {

    const contractHeaders = {
        title: {},
        contractorRef: {},
        _vendorSAPcode: {
            label: "קוד בסאפ",
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) {
                    value = vendorDoc.docData.contractorSAPNumber
                }
                return { value }
            }
        },
        budgetbudgetaryItem: {},
        _taxPercent: {
            label: "אחוז ניכוי",
            val: () => {
                return { value: getDisplayValue(node.attrs.currentAccount.docData.taxPercent, PERCENT, { isFloat: true }) }
            }
        },
        // delayPercentage: {},
        paymentDelay: {},
    }

    const handleAddRows = () => {
        const { numberOfPayments, paymentChannel, currentSum, paymentType } = node.state.mainData
        const { currentAccount, contract } = node.attrs
        const { ref, totalPay, month } = currentAccount.docData
        let rows = parseInt(numberOfPayments)
        const colRef = `${ref}/${PAYMENTS}`
        const sum = parseFloat(currentSum) / parseInt(numberOfPayments)
        const totalPayment = node.attrs.currentAccount.getTotalPayments()
        const paymentDate = calcPaymentDelay(textToDate(month), contract.docData.paymentDelay).toISOString()
        // const paymentDate = addMonths(textToDate(month), contract.docData.paymentDelay).toISOString()
        // console.log(`TODO: calcPaymentDate by adding ${contract.docData.paymentDelay} after today`, paymentData);
        const historyDiff = contract.getTotalAccounts("totalPay", { exclude: { month } }) - contract.getTotalPayments({ filterAccounts: { exclude: { month } } })
        while (rows > 0) {
            // if ((sum + totalPayment) > (totalPay) && paymentType === PAYMENT_TYPES.ONGOING) {
            // node.state.snackbar = { msg: `סכום חורג מעבר למכסת התשלום`, isError: true }
            // } else {
            const newDoc = new PaymentDoc({ colRef })
            newDoc.saveLocal({
                paymentChannel,
                paymentType,
                paymentDate,
                sum
            })
            // }
            rows--;
        }
        // node.state.mainData.currentSum = parseFloat(totalPay - currentSum)
        node.state.mainData.currentSum = 0
    }

    async function handleDeleteRows() {
        try {
            await node.attrs.currentAccount.removePayments([...node.state.selectedRows]);
            node.state.selectedRows = []
            node.state.snackbar = { msg: "המסמכים נמחקו" }
            m.redraw()
        } catch (err) {
            node.state.snackbar = { msg: `אירעה שגיאה בעת מחיקת הנתונים \nתיאור:\n${err}`, isError: true }
            m.redraw()
        }
    }

    async function handeleSavePayments() {
        const { numberOfPayments, paymentChannel, currentSum } = node.state.mainData
        const { ref, totalPay } = node.attrs.currentAccount.docData
        try {
            await node.attrs.currentAccount.savePayments();
            node.state.snackbar = { msg: "השינויים נשמרו בהצלחה" }
            m.redraw()
        } catch (err) {
            node.state.snackbar = { msg: `אירעה שגיאה בשמירת הנתונים \nתיאור:\n${err}`, isError: true }
            m.redraw()
        }
    }
    function resetPayments() {
        node.state.prompt = {
            title: "מחיקת תשלומים",
            msg: "האם למחוק את כל התשלומים בחשבון זה ?",
            actions: [{
                action: async () => {
                    const payments = node.attrs.currentAccount.getChildren(PAYMENTS)
                    try {
                        payments.forEach(async doc => {
                            if (doc.isNew) doc.remove()
                            await db.doc(doc.docData.ref).delete()
                        })
                        node.state.snackbar = { msg: "השינויים נשמרו בהצלחה" }
                        m.redraw()
                    } catch (err) {
                        node.state.snackbar = { msg: `אירעה שגיאה בעת איפוס הנתונים \nתיאור:\n${err}`, isError: true }
                        m.redraw()
                    }
                },
                text: "מחק",
                color: "red",
            },{
                color: "gray",
                text: "ביטול",
                action: e => {
                    node.state.prompt = false
                }
            }]
        }
    }

    function toggleSelectAllRows() {
        if (node.state.selectedRows.length === 0) {
            node.state.selectedRows = []
            node.attrs.currentAccount.getChildren(PAYMENTS).forEach(doc => {
                node.state.selectedRows.push(doc.docData.docID)
            })
        } else {
            node.state.selectedRows = []
        }
    }
    function toggleSelectRow(docID) {
        const selectedIndex = getSelectedRow(docID)
        if (selectedIndex > -1) node.state.selectedRows.splice(selectedIndex, 1)
        else node.state.selectedRows.push(docID)
    }

    function getSelectedRow(docID) {
        return node.state.selectedRows.indexOf(docID)
    }

    const displayPaymentsHeaders = [
        "paymentDate",
        "paymentChannel",
        "paymentType",
        "paymentIdentity",
        "description",
        "sum",
    ]

    const toggleForm = (formKey) => node.state[formKey] = !node.state[formKey]

    const toggleRoundSum = () => {
        if (!node.state.mainData.round) {
            node.state.mainData.fullCurrentSum = parseFloat(node.state.mainData.currentSum)
            node.state.mainData.currentSum = Math.round(parseFloat(node.state.mainData.currentSum))
        } else {
            if (node.state.mainData.fullCurrentSum) {
                node.state.mainData.currentSum = parseFloat(node.state.mainData.fullCurrentSum)
            }
        }
        node.state.mainData.round = !node.state.mainData.round
    }

    const setCurrentSum = (sum) => {
        node.state.mainData.currentSum = sum
        node.state.mainData.fullCurrentSum = sum
        node.state.round = false
    }

    const { currentAccount, contract } = node.attrs
    const vendorHistoryBalance = contract.getHistoryBalance()

    return {
        mainData: {
            currentSum: 0,
            numberOfPayments: 1,
            paymentChannel: PAYMENT_CHANNELS.CHECK,
            round: false,
            paymentType: PAYMENT_TYPES.ONGOING
        },
        editCell: false,
        listOpen: false,
        paymentTypeListOpen: false,
        formOpen: true,
        editBillingOpen: true,
        selectedRows: [],
        prompt: false,
        snackbar: false,
        onint: vnode => {
            vnode.state.mainData.currentSum = contract.getBalance()
        },
        view: vnode => {
            const { mainData, formOpen, editBillingOpen, selectedRows } = vnode.state
            const { paymentType } = mainData
            return m(".popUp__form paymentForm",
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(".paymentForm__details",
                    contract && Object.entries(buildHeaders(ContractDoc.headers, contractHeaders)).map(([header, settingsField], index) => {
                        const value = settingsField.val ? settingsField.val(contract).value : contract.docData[header]
                        return m(DisplayCell, { value, field: settingsField, doc: contract, header, index })
                    }),
                ),
                // m(Table, {
                //     class: "paymentForm__comments",
                //     modelID: CONTRACT_COMMENTS,
                //     doc: contract,
                //     title: "הערות",
                //     colRef: `${contract.docData.ref}/${CONTRACT_COMMENTS}`,
                //     actions: { add: false, onRow: false, copy: false, remove: false, move: false, enlarge: false, },
                //     viewOptions: { filters: false, add: false, nav: false, totals: false, download: false },
                //     tableHeaders: ["title", "description", "accountRef"],
                // }),
                m(".paymentForm__summery summery",

                    //[1] before tax : {beforeVAT , VAT , includeVAT}
                    m(".summery__box--badge-right", "לפני ניכוי"),
                    m(".summery__box--black",
                        m(".summery__label", "סהכ לפני מעמ"),
                        m(".summery__total", getDisplayValue(currentAccount.docData.totalAfterIndexed, CURRENCY)), //TESTME:
                        // m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalAfterIndexed"), CURRENCY)),
                    ),
                    m(".summery__box--black",
                        m(".summery__label", "סהכ מעמ"),
                        m(".summery__total", getDisplayValue(currentAccount.docData.totalVAT, CURRENCY)), //TESTME:
                        // m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalVAT"), CURRENCY)),
                    ),
                    m(".summery__box--black",
                        m(".summery__label", "סהכ כולל מעמ"),
                        m(".summery__total", getDisplayValue(parseFloat(currentAccount.docData.totalAfterIndexed + currentAccount.docData.totalVAT), CURRENCY)) //TESTME:
                        // m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalVAT") + vnode.attrs.contract.getTotalAccounts("totalAfterIndexed"), CURRENCY)),
                    ),

                    //[2] after tax
                    m(".summery__box--badge-right", "לאחר ניכוי"),
                    m(".summery__box--black",
                        m(".summery__label", "סהכ לפני מעמ"),
                        // m(".summery__total", getDisplayValue(parseFloat(currentAccount.docData.totalAfterIndexed - currentAccount.docData.totalTax), CURRENCY)), //TESTME:
                        m(".summery__total", getDisplayValue(parseFloat(currentAccount.docData.totalAfterIndexed) * (100 - (currentAccount.docData.taxPercent)) / 100, CURRENCY)), //
                        // m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalAfterIndexed") - vnode.attrs.contract.getTotalAccounts("totalTax"), CURRENCY)),
                    ),
                    m(".summery__box--black",
                        m(".summery__label", "סהכ מעמ"),
                        // m(".summery__total", getDisplayValue(parseFloat(currentAccount.docData.totalVAT - currentAccount.docData.totalTax), CURRENCY)), //OLD:
                        m(".summery__total", getDisplayValue(parseFloat(currentAccount.docData.totalVAT) * (100 - (currentAccount.docData.taxPercent)) / 100, CURRENCY)), //TESTME:
                        // m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalVAT") - vnode.attrs.contract.getTotalAccounts("totalTax"), CURRENCY)),
                    ),
                    m(".summery__box--black",
                        m(".summery__label", "סהכ כולל מעמ"),
                        m(".summery__total", getDisplayValue(currentAccount.docData.totalPay, CURRENCY)),//TESTME:
                        // m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalPay"), CURRENCY)),
                    ),
                    //[3] grand total

                    // OLD:
                    // m(".summery__box",
                    //     m(".summery__label", "מצטבר לתשלום"),
                    //     m(".summery__total", getDisplayValue(currentAccount.docData.subTotal, CURRENCY)),
                    // ),
                    // currentAccount.getParent().docData.isIndexed && m(".summery__box--black", { onclick: e => setCurrentSum(parseFloat(currentAccount.docData.totalIndexed)) },
                    //     m(".summery__label", "הצמדה"),
                    //     m(".summery__total--multipart",
                    //         m("span", { style: "font:inherit" }, getDisplayValue(parseFloat(currentAccount.docData.totalIndexed - currentAccount.docData.subTotal), DIFF)),
                    //         m("span", { style: "font-weight:500" }, getDisplayValue(currentAccount.docData.indexedPercent, PERCENT, { isFloat: true }))
                    //     ),
                    // ),
                    m("hr"),

                    m(".summery__box",
                        m(".summery__label", "לתשלום - יתרת קבלן"),
                        m(".summery__total", getDisplayValue(vendorHistoryBalance, CURRENCY)),
                    ),
                    m(".summery__box", { onclick: e => setCurrentSum(parseFloat(currentAccount.docData.totalVAT)) },
                        m(".summery__label", "לתשלום מעמ - יתרת קבלן"),
                        m(".summery__total--multipart",
                            m("span", { style: "font:inherit" }, getDisplayValue(currentAccount.docData.totalVAT, CURRENCY)),
                            m("span", { style: "font-weight:500" }, getDisplayValue(currentAccount.docData.vatPercent, PERCENT))
                        ),
                    ),


                    // OLD:
                    // m(".summery__box--black",
                    //     m(".summery__label", "ניכוי מס"),
                    //     m(".summery__total--multipart",
                    //         m("span", { style: "font:inherit" }, getDisplayValue(currentAccount.docData.totalTax, CURRENCY)),
                    //         m("span", { style: "font-weight:500" }, getDisplayValue(currentAccount.docData.taxPercent, PERCENT))
                    //     )
                    // ),
                    // m(".summery__box",
                    //     m(".summery__label", "סהכ תשלום נוכחי"),
                    //     m(".summery__total", getDisplayValue(currentAccount.docData.totalPay, CURRENCY)),
                    // ),
                    // m(".summery__box",
                    //     m(".summery__label", "הפרשים מתקופות קודמות"),
                    //     m(".summery__total", getDisplayValue(vnode.attrs.contract.getTotalAccounts("totalPay", { exclude: { month: currentAccount.docData.month } }) - vnode.attrs.contract.getTotalPayments({ filterAccounts: { exclude: { month: currentAccount.docData.month } } }), CURRENCY)),
                    // ),


                    m(".summery__box--red",
                        m(".summery__label--red-light", "תשלומים נוכחי"),
                        m(".summery__total", getDisplayValue(currentAccount.getTotalPayments(), CURRENCY)),
                    ),
                    m(".summery__box--green", { onclick: e => setCurrentSum(vendorHistoryBalance - currentAccount.getTotalPayments()) },
                        m(".summery__label", "יתרה פתוחה"),
                        m(".summery__total", getDisplayValue(vendorHistoryBalance - currentAccount.getTotalPayments(), CURRENCY)),
                    ),
                ),

                // m(`.caption${editBillingOpen ? "" : "--collaspe"}`, { onclick: e => toggleForm("editBillingOpen") }, "יצירת תשלומים", m(Icon, { icon: `icon-arrow-thin-${editBillingOpen ? "up" : "down"}` })),
                // editBillingOpen && m(AccountingForm, { totalToPay: currentAccount.docData.subTotal, contract: currentAccount.getParent(), currentAccount: currentAccount }),


                m(`.caption${formOpen ? "" : "--collaspe"}`, { /* onclick: e => toggleForm("formOpen") */ }, "יצירת תשלומים", m(Icon, { icon: `icon-arrow-thin-${formOpen ? "up" : "down"}` })),
                formOpen && m(".paymentForm__create",
                    m("label.paymentForm__label input__label cell__label", { for: "totalPay" }, "סכום"),
                    m("label.paymentForm__label input__label cell__label", { for: "numberOfPayments" }, "מספר תשלומים"),
                    m("label.paymentForm__label input__label cell__label", { for: "paymentChannel" }, "בחר אמצעי תשלום"),
                    m("label.paymentForm__label input__label cell__label", { for: "paymentType" }, "סוג תשלום"),
                    m("label.paymentForm__label input__label cell__label", { for: "round", onclick: e => toggleRoundSum() }, "עיגול אגורות"),

                    m(`input#totalPay.input__field[step=any][type=number][min=0][max=${paymentType === PAYMENT_TYPES.ONGOING ? Math.ceil(vendorHistoryBalance) : "any"}]`, {
                        value: mainData.currentSum,
                        oninput: e => { vnode.state.mainData.currentSum = e.target.value }
                    }, "סכום לתשלום"),
                    m("input#numberOfPayments.input__field[type=number][min=1][max=24]", {
                        value: mainData.numberOfPayments,
                        oninput: e => { vnode.state.mainData.numberOfPayments = e.target.value }
                    }, "חלוקה לתשלומים"),
                    m(".input__field cell__field cell__field--select", {
                        onclick: e => vnode.state.listOpen = !vnode.state.listOpen,
                        onfocus: e => vnode.state.oldValue = mainData.paymentChannel,
                        tabindex: 2,
                        id: "paymentChannel"
                    },
                        m(Icon, { icon: `icon-triangle-${vnode.state.listOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                        vnode.state.listOpen && m(".cell__list",
                            paymentChannels.map(opt => {
                                return m(".cell__list-item", {
                                    onclick: e => { vnode.state.mainData.paymentChannel = opt.id }
                                }, opt.text)
                            })
                        ),
                        getDisplayValue(mainData.paymentChannel, SWITCH, paymentChannels)
                    ),

                    m(".input__field cell__field cell__field--select", {
                        onclick: e => vnode.state.paymentTypeListOpen = !vnode.state.paymentTypeListOpen,
                        onfocus: e => vnode.state.oldValue = mainData.paymentType,
                        tabindex: 2,
                        id: "paymentType"
                    },
                        m(Icon, { icon: `icon-triangle-${vnode.state.paymentTypeListOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                        vnode.state.paymentTypeListOpen && m(".cell__list",
                            paymentTypes.map(opt => {
                                return m(".cell__list-item", {
                                    onclick: e => { vnode.state.mainData.paymentType = opt.id }
                                }, opt.text)
                            })
                        ),
                        getDisplayValue(mainData.paymentType, SWITCH, paymentTypes)
                    ),
                    m(CheckCell, { checked: vnode.state.mainData.round, setCheck: e => toggleRoundSum() }),

                    m("button.button", { onclick: e => { handleAddRows() } }, `הוסף ${mainData.numberOfPayments > 1 ? "תשלומים" : "תשלום"}`),
                ),

                //TODO: inlineTable
                m(".paymentForm__table", { style: `display:grid;grid-template-columns:min-content ${displayPaymentsHeaders.map(() => "1fr").join(" ")};` },
                    m(".caption", "פירוט",
                        vnode.state.selectedRows.length > 0 && m(".actions",
                            m(".action", { onclick: e => handleDeleteRows() }, `מחק`),
                            // m(".action", `עדכון תאריך`),
                        ),
                    ),

                    // headers
                    m(".cell cell--header", { onclick: e => toggleSelectAllRows() }, "V"),
                    displayPaymentsHeaders.map(header => {
                        const { label } = PaymentDoc.headers[header]
                        return m(".cell cell--header", label)
                    }),
                    //case empty
                    currentAccount.getChildren(PAYMENTS).length === 0 && [m(".cell cell--value", "-"), displayPaymentsHeaders.map(header => m(".cell cell--value", "---"))],
                    //rows
                    currentAccount.getChildren(PAYMENTS).map((doc, docInd) => {
                        const checked = getSelectedRow(doc.docData.docID) > -1
                        return [
                            m(`.cell cell--select ${checked ? "checked" : ""}`, { onclick: e => toggleSelectRow(doc.docData.docID) }, m(CheckCell, { checked })),
                            displayPaymentsHeaders.map((header, ind) => {
                                const field = PaymentDoc.headers[header];
                                const value = doc.docData[header]
                                const id = `${doc.docData.ref}_${header}`
                                const index = Number(docInd + 1) + Number(ind + 1)
                                return m(InputCell, {
                                    class: `cell cell--value ${checked ? "checked" : ""}`, value, field, id, index, header, doc,
                                    editCell: vnode.state.editCell,
                                    parent: vnode,
                                    options: { ignoreLabel: true }
                                })
                            })
                        ]
                    }),
                ),
                currentAccount.childHasPendingChanges(PAYMENTS) && m(".prompt__actions", { style: "margin-top:2rem;display:grid;grid-template-columns:1fr 1fr 1fr 1fr" },
                    m("button.button", { onclick: e => handeleSavePayments(), style: "grid-column:2" }, "שמור שינויים"),
                    m("button.button button--red", { onclick: e => resetPayments(), style: "grid-column:3" }, "אפס הכל")
                )
            )
        }
    }
}