export const NOT_ACTIVE={ id: "not_active", bgColor: "white", textColor: "black", text: "לא נפתח" }
export const ACTIVE={ id: "active", bgColor: "#efd615", textColor: "white", text: "נפתח לביצוע" }
export const START={ id: "start", bgColor:"#054783", textColor: "white", text: "בביצוע" }
export const DUE={ id: "due", bgColor: "#e65225", textColor: "white", text: "באיחור" }
export const FINISH={ id: "finish", bgColor: "#2abd2c", textColor: "white", text: "בוצע" }

export const taskStatsM = {
    meta: {
        label: "סטטוס משימה",
        listen: false,
    },
    options: [NOT_ACTIVE,ACTIVE,START,DUE,FINISH]
}