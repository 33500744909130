import m from "mithril"
import { dataStore } from "../../../data/store/dataStore"
import { DATE_RANGE } from "../../../utils/constants/filtersTypes"
import { DATE, LIST, SWITCH } from "../../../utils/constants/types"
import { formatDate } from "../../../utils/js"
import { DisplayCell } from "../../commons/inputCell/DisplayCell"
import { LocalSwitch } from "../../commons/inputCell/LocalSwitch"

export const FilterForm = node => {
    const filterDoc = {
        docData: {
            filterHeader: false,
            filterField: false,
            fieldType: false,
            filterMap: false,
            filters: [],
        },
        headers: {
            filterHeader: {
                label: "שדה",
                type: SWITCH,
                change: val => {
                    const field = dataStore[node.attrs.modelID].model.headers[val]
                    filterDoc.docData.filterHeader = val
                    filterDoc.docData.fieldType = field.type
                    filterDoc.docData.filterField = dataStore[node.attrs.modelID].model.headers[val]
                },
                options: Object.entries(dataStore[node.attrs.modelID].model.headers).map(([key, field]) => { return { id: key, text: field.label, field } }).filter(({ field }) => field.type === DATE || field.type === LIST || field.type === SWITCH)
            },
            dateRangeFilter: {
                label: "טווח תאריכים",
                type: SWITCH,
                change: filter => {
                    const { filterHeader, filterField } = filterDoc.docData
                    const type = DATE_RANGE
                    const id = filterHeader
                    const today = new Date()
                    filterDoc.docData.filterMap = {
                        label: filterField.label,
                        header: filterHeader,
                        type,
                        options: [
                            { id: `${id}:after`, text: `מ`, logic: `after` },
                            { id: `${id}:before`, text: "עד", logic: "before" }
                        ]
                    }
                    switch (filter) {
                        case "year":
                            filterDoc.docData.filters = [
                                { id, type, param: formatDate(new Date(today.getFullYear(), 0, 1)), logic: `after` },
                                { id, type, param: formatDate(new Date(today.getFullYear(), 11, 31)), logic: "before" }
                            ]
                            break;
                        case "month":
                            filterDoc.docData.filters = [
                                { id, type, param: formatDate(new Date(today.getFullYear(), today.getMonth(), 1)), logic: `after` },
                                { id, type, param: formatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0)), logic: "before" }
                            ]
                            break;
                        case "week":
                            const dayOfWeek = today.getDay()
                            const startWeek = subtractDays(today, dayOfWeek)
                            filterDoc.docData.filters = [
                                { id, type, param: formatDate(startWeek), logic: `after` },
                                { id, type, param: formatDate(addDays(startWeek, 6)), logic: "before" }
                            ]
                            break;
                        case "today":
                            filterDoc.docData.filters = [
                                { id, type, param: formatDate(subtractDays(today, 1)), logic: "after" },
                                { id, type, param: formatDate(addDays(today, 1)), logic: "before" }
                            ]
                            break;


                    }
                },
                options: [
                    { text: "השנה", id: "year" },
                    { text: "החודש", id: "month" },
                    { text: "השבוע", id: "week" },
                    { text: "היום", id: "today" },
                    { text: "בין תאריכים", id: "custom" },
                ]
            }
        }
    }
    const actions = {
        close: {
            action: (e) => node.attrs.parent.state.prompt = false,
            text: "סגור",
            color: "gray",
        },
        apply: {
            action: (e) => {
                const { filterMap, filters } = filterDoc.docData
                node.attrs.parent.state.applyFilters.filterMap.push(filterMap)
                node.attrs.parent.state.applyFilters.filters.push(...filters)
                node.attrs.parent.state.prompt = false
            },
            text: "הוסף",
        }
    }

    const getFilterDate = (id, logic) => {
        const find = filterDoc.docData.filters.find(filter => filter.id === id && filter.logic === logic)
        return find ? find.param : ""
    }

    return {
        view: vnode => {
            const { modelID } = vnode.attrs
            const { filterHeader, fieldType, filterField, filters, filterMap } = filterDoc.docData
            return m(".filterForm tableCard",
                m(LocalSwitch, { doc: filterDoc, header: "filterHeader" }),
                (filterHeader && fieldType === DATE) && m(LocalSwitch, { doc: filterDoc, header: "dateRangeFilter" }),
                m(".filters",
                    (filterHeader && fieldType === DATE && filterMap) && m(".filters__row",
                        m(".filters__caption filters__caption--open", filterMap.label),
                        m(".filters__buttons",
                            filterMap.options.map(opt => {
                                return m(DisplayCell, { header: "filterField", value: getFilterDate(filterMap.header, opt.logic), field: filterField, doc: filterDoc, classStyle: "filters__input", })
                            })
                        )
                    ),
                ),

                m(".prompt__actions",
                    filterMap && m(`button.button prompt__action ${actions.apply.color ? `button--${actions.apply.color}` : ""}`, { onclick: e => actions.apply.action(e) }, actions.apply.text),
                    m(`button.button prompt__action ${actions.close.color ? `button--${actions.close.color}` : ""}`, { onclick: e => actions.close.action(e) }, actions.close.text),
                )
            )
        }
    }
}