import m from "mithril"
import { PAYMENTS } from "../../../../data/dictionary/routeNames"

import { ContractDoc } from "../../../../data/store/contracts/contractClass"
import { ProjectDoc } from "../../../../data/store/projects/projectClass"
import { VendorDoc } from "../../../../data/store/vendors/vendorClass"
import { CURRENCY, PERCENT } from "../../../../utils/constants/types"
import { Card } from "../../../commons/cardLayout/Card"
import { Table } from "../../../commons/table/Table"
import { DisplayCell } from "../../../commons/inputCell/DisplayCell"
import { Icon } from "../../../components/icon/Icon"

export const paymentDisplayReport = node => {

    const print = () => {
        window.print()
    }
    return {
        view: vnode => {
            const { project, contract, vendor, account } = vnode.attrs
            const { title: projectTitle } = project.docData
            const { title: contractTitle } = contract.docData
            const {
                title: vendorTitle,
                commercialName,
                companyNumber,
                companyType,
                contractorSAPNumber
            } = vendor.docData

            return m(".",
                m(".caption", `דוח הוראות תשלום לקבלן - ${vendorTitle}  - [חשבון ${account.docData.month}]`),
                m(Card, { class: "formEdit" },
                    // projectTitle
                    m(DisplayCell, { header:"title",value: projectTitle, field: ProjectDoc.headers.title, doc: project }),
                    // contractTitle
                    m(DisplayCell, { header:"title",value: contractTitle, field: ContractDoc.headers.title, doc: contract }),
                    // vendorTitle
                    m(DisplayCell, { header:"title",value: vendorTitle, field: VendorDoc.headers.title, doc: vendor }),
                    // commercialName
                    m(DisplayCell, { header:"commercialName",value: commercialName, field: VendorDoc.headers.commercialName, doc: vendor }),
                    // companyNumber
                    m(DisplayCell, { header:"companyNumber",value: companyNumber, field: VendorDoc.headers.companyNumber, doc: vendor }),
                    // companyType
                    m(DisplayCell, { header:"companyType",value: companyType, field: VendorDoc.headers.companyType, doc: vendor }),
                    // contractorSAPNumber
                    m(DisplayCell, { header:"contractorSAPNumber",value: contractorSAPNumber, field: VendorDoc.headers.contractorSAPNumber, doc: vendor }),
                ),
                m(Table, {
                    doc: account,
                    modelID: PAYMENTS,
                    colRef: `${account.docData.ref}/${PAYMENTS}`,
                    actions: { onRow: false, copy: false, remove: false, move: false, enlarge: false },
                    title: "פירוט הוראות תשלום",
                    viewOptions: { filters: false, add: false, nav: false, totals: true, download: false },
                    tableHeaders: {
                        _beforeTax: {
                            label: "סכום לפני ניכוי",
                            val: doc => {
                                return { value: parseFloat(doc.docData.sum) - (parseFloat(doc.docData.sum) * (parseFloat(account.docData.taxPercent) / 100)) }
                            },
                            type: CURRENCY
                        },
                        _taxPercent: {
                            label: "% ניכוי מס",
                            val: doc => {
                                return { value: account.docData.taxPercent }
                            },
                            type: PERCENT
                        },
                        _totalTax: {
                            label: "סכום ניכוי מס",
                            val: doc => {
                                return { value: parseFloat(doc.docData.sum) * (parseFloat(account.docData.taxPercent) / 100) }
                            },
                            type: CURRENCY
                        },
                        paymentChannel: {},
                        sum: {},
                        paymentDate: {},
                    }
                }),
                m("button.button--icon", { onclick: e => print() }, "הדפסה", m(Icon, { icon: "icon-print" })),
            )
        }
    }
}