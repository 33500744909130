import { projectTypes, PUBLIC_SPACE, RESIDENTIAL, ENTREPRENEURSHIP } from "../../utils/constants/projectTypes";
import { CONTRACTS, PROJECTS, VENDORS, VENDOR_CONTACTS, BILLINGS, CONTRACT_SECTIONS, ACTUALS, SETTINGS, FILES, CONTRACT_ACCOUNTS, CONFIRMS, CONTRACT_COMMENTS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, PAYMENTS, NOTIFICATION, APARTMENTS, APARTMENT_MODELS, BUILDINGS, FLOORS, ODD_JOBS } from "../dictionary/routeNames";
import { userRoles, USER_ROLES } from "../../utils/constants/userRoles";

export const CREATE = "create"
export const REMOVE = "remove"
export const UPDATE = "update"
export const READ = "read"

export const permissionTree = {
    [PROJECTS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ]
        },
        [READ]: {
            allowRoles: [
                USER_ROLES.OWNER,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
                USER_ROLES.SYSTEM,
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SECRETARY
            ], // no restriction
            //other roles are restricted else if where is true
            allowRoles_where_or: [{
                notRole: [
                    USER_ROLES.BUDGETER,
                    USER_ROLES.COMPANY_ENGINEER
                ], // all other roles
                not: [{ "doc.docData.projectType": ENTREPRENEURSHIP }]
            }],
            listen_where: [{
                notRole: [
                    USER_ROLES.BUDGETER,
                    USER_ROLES.COMPANY_ENGINEER,
                    USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                    USER_ROLES.ACCOUNTS,
                    USER_ROLES.CEO_FINANCE,
                    USER_ROLES.CEO_OPERATIONS,
                    USER_ROLES.OWNER
                ], // all other roles
                rules: [
                    { field: "projectType", op: "in", value: [RESIDENTIAL, PUBLIC_SPACE] },
                ],
            }]
        }
    },
    [NOTIFICATION]: {
        [READ]: {
            listen_where: [{
                notRole: [], // all other roles
                rules: [
                    { field: "user", op: "==", value: "currentUser" },
                ],
            }]
        }
    },
    [BUILDINGS]: {
        [CREATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [REMOVE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [UPDATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
    },
    [APARTMENTS]: {
        [CREATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [REMOVE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [UPDATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
    },
    [FLOORS]: {
        [CREATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [REMOVE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [UPDATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
    },
    [APARTMENT_MODELS]: {
        [CREATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [REMOVE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
        [UPDATE]: { allowRoles: [USER_ROLES.TENANT_CHANGES_MANAGER] },
    },

    [CONTRACTS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.TENANT_CHANGES_MANAGER
            ]
        },
        [REMOVE]: {
            allowRoles: [USER_ROLES.SYSTEM],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.COMPANY_ENGINEER], //any of roles
                is: [{ "parent.docData.type": RESIDENTIAL }, { "parent.docData.type": PUBLIC_SPACE }]
            }, {
                isRole: [USER_ROLES.BUDGETER],
                is: [{ "parent.docData.type": ENTREPRENEURSHIP }, { "parent.docData.type": PUBLIC_SPACE }]
            }, {
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "doc.docData.calculationMethod": "kitchen" }]
            }]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "doc.docData.calculationMethod": "kitchen" }]
            }, {
                isRole: [USER_ROLES.ACCOUNTS, USER_ROLES.ACCOUNTS_OPERATIONS, USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP], //any of roles
                isFields: [{ key: "progressCommentTags" }]
            }, {
                isRole: [USER_ROLES.CEO_OPERATIONS], //any of roles
                isFields: [{ key: "delayPercentage" }]
            }]
        }
    },
    [CONTRACT_SECTIONS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "parent.docData.calculationMethod": "kitchen" }]
            }]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "parent.docData.calculationMethod": "kitchen" }]
            }]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "parent.docData.calculationMethod": "kitchen" }]
            }]
        }
    },
    [ACTUALS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
            ],
            //TESTME: 👍
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }, {
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "contract.docData.calculationMethod": "kitchen" }]
            }]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
            ],
            //TESTME: 👍
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }, {
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "contract.docData.calculationMethod": "kitchen" }]
            }]
        }
    },
    [CONTRACT_ADDITIONS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
                // USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
            ],
            //TESTME: 👍
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }, {
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "contract.docData.calculationMethod": "kitchen" }]
            }]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
                // USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
            ],
            //TESTME: 👍
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }, {
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "contract.docData.calculationMethod": "kitchen" }]
            }]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //any of roles
                is: [{ "doc.docData.calculationMethod": "kitchen" }]
            }]

        }
    },
    [CONTRACT_SUBTRACTIONS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
                // USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
            ],
            //TESTME: 👍
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
                // USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
            ],
            //TESTME: 👍
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
            ],
        }
    },

    [CONTRACT_COMMENTS]: {
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
            ],
            allowRoles_where_or: [{
                notRole: [], //any of roles
                is: [{ "doc.docData.createdBy": "currentUser" }]
            }]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
            ],
            allowRoles_where_or: [{
                notRole: [], //any of roles
                is: [{ "doc.docData.createdBy": "currentUser" }]
            }]
        },
    },
    //include sections + update status
    [VENDORS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.ACCOUNTS_OPERATIONS,
            ]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.SYSTEM
            ]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.ACCOUNTS_OPERATIONS,
            ]
        }
    },
    [VENDOR_CONTACTS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.ACCOUNTS_OPERATIONS,
            ]
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.ACCOUNTS_OPERATIONS,
            ]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.ACCOUNTS_OPERATIONS,
            ]
        }
    },
    [CONTRACT_ACCOUNTS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.ACCOUNTS,
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }],
        },
        [REMOVE]: { allowRoles: [] },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.CEO_OPERATIONS], //any of roles
                isFields: [{ key: "delayPercentage" }, { key: "delayRelease" }]
            }],
        },
    },
    [CONFIRMS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.AREA_MANAGER,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.CEO_FINANCE,
                USER_ROLES.CEO_OPERATIONS,
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }],
        },
        [REMOVE]: { allowRoles: [] }, // nobody
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                // USER_ROLES.AREA_MANAGER,
                // USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.SYSTEM,
                // USER_ROLES.ACCOUNTS,
                // USER_ROLES.ACCOUNTS_OPERATIONS,
                // USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP
            ],
            allowRoles_where_or: [
                //BUG: doesnt make sense at all --> alwas return true - on roles
                // {
                //     isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //only roles
                //     is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
                // },
                {
                    isRole: [USER_ROLES.TENANT_CHANGES_MANAGER], //only roles
                    is: [{ "contract.docData.calculationMethod": "kitchen" }]
                }, {
                    notRole: [], //any of roles
                    is: [{ "doc.docData.role": "currentRole" }]
                }
            ],
        },
    },
    [PAYMENTS]: {
        [REMOVE]: { allowRoles: [] },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP
            ],
        },
    },
    [SETTINGS]: {
        [READ]: { allowRoles: [USER_ROLES.SYSTEM] }
    },
    [ODD_JOBS]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                // USER_ROLES.AREA_MANAGER, // only if has project role
                // USER_ROLES.PROJECT_MANAGER, // only if has project role
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }],
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
            ]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                USER_ROLES.CEO_OPERATIONS
            ],
            allowRoles_where_and: [
                {
                    isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                    is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
                }
            ]
        },
    },
    [FILES]: {
        [CREATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
                // USER_ROLES.AREA_MANAGER, // only if has project role
                // USER_ROLES.PROJECT_MANAGER, // only if has project role
            ],
            allowRoles_where_or: [{
                isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
            }],
        },
        [REMOVE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
            ]
        },
        [UPDATE]: {
            allowRoles: [
                USER_ROLES.BUDGETER,
                USER_ROLES.COMPANY_ENGINEER,
                USER_ROLES.SYSTEM,
                USER_ROLES.ACCOUNTS,
                USER_ROLES.ACCOUNTS_OPERATIONS,
                USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
            ],
            allowRoles_where_and: [
                {
                    isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                    is: [{ "project.docData.manager": "currentUser" }, { "project.docData.seniorManager": "currentUser" }]
                },
                {
                    isRole: [USER_ROLES.PROJECT_MANAGER, USER_ROLES.AREA_MANAGER], //any of roles
                    notFields: [{ key: "status", currentValue: "_ANY_", toValue: "_ANY_" }], //map fields based on currentValue and userRole
                },
            ]
        },
    },
}