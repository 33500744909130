import m from "mithril"

import { auth } from "../../../index"
export const Logo = vnode => {
    return {
        view: vnode => {
            return (
                m('img.logo', {
                    src: "/img/logo.png",
                    onclick: e => vnode.attrs.action ? vnode.attrs.action() : auth.currentUser !== null ? m.route.set('/app/projects') : null
                })
            )
        }
    }
}