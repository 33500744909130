import m from "mithril"
import { dataStore } from "../../../data/store/dataStore";
import { DEFAULT_DELETE_MSG } from "../../../data/store/settingsStore";
import { MIME_TYPES } from "../../../utils/constants/mimeTypes";
import { preventDefaults, uuid } from "../../../utils/js";
import { FilePrompt } from "./FilePrompt";

export const saveLocalBlob = (files, colRef, modelID, dataOptions = {}, vnode) => {
    const Model = dataStore[modelID].model
    for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        const file = files[i]
        // if (!(file.type === MIME_TYPES.PDF || file.type.startsWith("image"))) return node.state.snackbar = { msg: "file type not supported", isError: true }
        reader.onload = () => {
            const docID = uuid()
            vnode.state.loader = { total: 0 }
            const docData = Object.assign({
                docID,
                colRef,
                ref: `${colRef}/${docID}`,
                title: file.name,
                contentType: file.type,
                size: file.size,
                url: reader.result,
                created: +new Date(),
                file
            },
                dataOptions
            )
            // console.log(dataOptions);
            const newFile = new Model(docData, true)
            // console.log(newFile);
            // m.redraw()
        };
        reader.onprogress = evt => {
            vnode.state.loader = { total: evt.total }
        };
        reader.onloadend = (evt) => {
            console.log("load end")
            vnode.state.loader = false
            m.redraw()
        };
        reader.readAsDataURL(file);
    }
    m.redraw()
}

export const handleRemoveFile = (e, file, node) => {
    preventDefaults(e);
    node.state.prompt = {
        title: file.docData.title,
        msg: "האם אתה בטוח כי ברצונך להסיר את הקובץ ?",
        actions: [
            {
                action: () => {
                    Promise.resolve(file.remove())
                        .then(() => node.state.snackbar = { msg: DEFAULT_DELETE_MSG })
                        .catch(err => node.state.snackbar = { msg: `אירעה שגיאה \n ${err}`, isError: true })
                        .finally(() => {
                            node.state.prompt = false
                            m.redraw()
                        })
                },
                text: "מחק",
                color: "red",
            }
        ]
    }
}

export const promptFile = (file, node) => {
    let headers = ["title"]
    if (node.state.formHeaders) headers = node.state.tableHeaders
    else if (node.state.tableHeaders) headers = node.state.tableHeaders
    node.state.prompt = {
        title: file.docData.title,
        class: "full-width",
        form: () => m(FilePrompt, { file, parent: node, headers }),
    }
}

export const saveFile = async (e, fileDoc) => {
    preventDefaults(e)
    e.target.disabled = true
    // const currInd = node.state.fileDocs.findIndex(({ id }) => id == fileDoc.id);
    // if (currInd === -1) throw "fileDoc not found"
    try {
        await fileDoc.save()
    } catch (err) {

    } finally {
        e.target.disabled = false
        m.redraw()
    }

}