import m from "mithril"
import { STRING, NUMBER, REF, CURRENCY, OBJECT } from "../../../utils/constants/types";

import { DocModel } from "../docModel";
import { ACTUALS, SECTION_MILESTONES } from "../../dictionary/routeNames";
import { db } from "../../../index";
import { STEP } from "../../../utils/constants/inputAttrs";

export class ActualDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ActualDoc,
        });
    };
    remove() {
        if (this.docData.value == 0) super.remove()
        else alert("לא ניתן למחוק 'סעיף ביצוע' הכולל סכום. אפס את הסכום ולאחר מכן נסה שנית")
    }
    async save() {
        try {
            const batch = db.batch()
            // super.batchSave(batch)
            let sectionRef = this.docData.sectionRef
            const isSection = sectionRef.includes(SECTION_MILESTONES)
            if (isSection) sectionRef = sectionRef.split("/").slice(0, -2).join("/") //remove "milestones/:msID"
            const section = DocModel.getDoc(sectionRef)
            if (!section) throw `section ${sectionRef} not found`
            const { currentTotal, calc } = this.calcSubTotal(isSection, section)
            //set Actual.total + _calc
            this.saveLocal({ currentTotal, calc })
            super.batchSave(batch)
            // batch.set(db.doc(this.docData.ref), { currentTotal, calc }, { merge: true })
            //set Section.subTotal
            const sectionTotal = section.calcTotalActuals()
            console.log("[*] - calc Section.total - 🧮", sectionTotal);
            section.saveLocal({ subTotal: sectionTotal })
            section.batchSave(batch)
            // batch.set(db.doc(section.docData.ref), { subTotal: sectionTotal }, { merge: true })
            //set Account.total[additions,subtraction,sections,subTotal]
            const account = this.getParent()
            const { sections: totalSections, additions: totalAdditions, subtractions: totalSubtractions } = account.pivotTotalActuals()
            const accountTotal = totalSections + totalAdditions - totalSubtractions
            account.saveLocal({
                subTotal: accountTotal,
                totalSections,
                totalAdditions,
                totalSubtractions
            })
            console.log("[1] - calc Account.total - 🧮", { accountTotal, totalSections, totalAdditions, totalSubtractions });
            account.batchSave(batch)
            const contract = account.getParent()
            const contractTotal = contract.pivotTotalActuals();
            console.log("[2] - calc Contract.total - 🧮", contractTotal);
            batch.update(db.doc(contract.docData.ref), {
                "totalSections": contractTotal.sections,
                "totalAdditions": contractTotal.additions,
                "totalSubtractions": contractTotal.subtractions,
                "totalDelay": contract.getTotalAccounts("totalDelay"),
                "delayRelease": contract.getTotalAccounts("delayRelease"),
                "currentAccount.totalSections": totalSections,
                "currentAccount.totalAdditions": totalAdditions,
                "currentAccount.totalSubtractions": totalSubtractions,
                "currentAccount.totalDelay": account.docData.totalDelay,
                "currentAccount.delayRelease": account.docData.delayRelease,
            })
            await batch.commit()
        } catch (error) {
            this.abortChanges()
            console.error(error);
        } finally {
            m.redraw()
        }
    }

    calcSubTotal(isSection, section) {
        const calc = {}
        let currentTotal = 0
        let price = 0
        // const modelID = section.docData.colRef.split("/").slice(-1).join("")
        const modelID = section.model.meta.id
        calc._modelID = modelID
        if (isSection) {
            // calc._weight
            // calc._itemPrice
            const isPercent = section.isPercent()
            const isPauschal = section.isPauschal()
            const ms = DocModel.getDoc(this.docData.sectionRef)
            if (!ms) throw "milestone not found"
            if (isPercent) {
                price = (parseFloat(ms.docData.weight) / 100) * parseFloat(section.docData.itemPrice)
                calc._weight = parseFloat(ms.docData.weight)
                calc._itemPrice = parseFloat(section.docData.itemPrice)
            } else {
                price = parseFloat(ms.docData.price)
            }

            if (isPauschal) currentTotal = (parseFloat(this.docData.value) / 100) * price;
            else currentTotal = parseFloat(this.docData.value) * price;
        } else {
            //ADDITION + SUBTRACTION:
            price = parseFloat(section.docData.totalSum)
            currentTotal = price * (parseFloat(this.docData.value) / 100)
        }
        calc._actualValue = this.docData.value
        calc._price = price
        return { currentTotal, calc }
    }



    static get meta() {
        return {
            id: ACTUALS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/accounts/:accountID/actuals",
                doc: "/projects/:projectID/contracts/:contractID/accounts/:accountID/actuals/:actualID",
            }
        }
    }
    /** === EXAMPLE === :
    * new ActualDoc({
    *   colRef:"/projects/[glilYam]/contracts/[sheled]/accounts/[partial1]/actuals",
    *   sectionRef:"/projects/[glilYam]/contracts/[sheled]/sections/[basement]/milestones/[opertaion]",
    *   unit:1,
    *   value:20, // 20% --> price(10,000)*weight(10%)*20%= 200
    * })
    */
    static get headers() {
        return {
            title: { label: 'כותרת', defaultValue: "--דיווח ביצוע--", type: STRING },
            sectionRef: { label: "סעיף/אבן דרך", defaultValue: "", type: REF, options: { params: ["^title", "title"] } }, //ref to section/milestone
            description: { label: 'תיאור והערות', defaultValue: "--ללא תיאור--", type: STRING },
            unit: { label: "יחידה", type: STRING, defaultValue: "1" }, // [puaschal] number unit ---OR--- [apartments] ref to apartemnt
            value: { label: "ביצוע", type: NUMBER, defaultValue: 0 }, //actual value reported --> which need to be calculate to actual price depend on section settings (percent OR number * milestone)
            currentTotal: { label: "סכום מחושב", type: CURRENCY, defaultValue: 0, props: [{ [STEP]: "any" }] }, //actual value reported --> which need to be calculate to actual price depend on section settings (percent OR number * milestone)
            calc: { label: "חישוב", type: OBJECT, defaultValue: "" }, //actual value reported --> which need to be calculate to actual price depend on section settings (percent OR number * milestone)
        }
    }
}