import m from "mithril"

import {Icon} from "../../components/icon/Icon"
import { uuid } from "../../../utils/js"
import { Tooltip } from "../../components/tooltip/Tooltip"

export const Link = node => {
    return {
        view: vnode => {
            return (
                m(`.sidebar__link ${vnode.attrs.isSub ? "sub" : ""}`, {
                    key: uuid(),
                    class: `${vnode.attrs.link.isActive ? 'sidebar__link--active' : ''}`,
                    onclick: e => vnode.attrs.link.pagePath ? m.route.set(vnode.attrs.link.pagePath) : false,
                }, [
                    vnode.attrs.isSub ? null : m(Icon, { icon: vnode.attrs.link.icon, class: "sidebar__icon icon--lg", isActive: vnode.attrs.link.isActive || false }),
                    m(`span.sidebar__text`, vnode.attrs.link.text),
                    m(Tooltip,{text:vnode.attrs.link.text})
                ])
            )
        }
    }
}