
import { SWITCH, STRING, REF, LIST, CURRENCY, VALID_CALC, DATE, CHECK_BOOL, VALID_COMPARE } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, DISABLED, HIDDEN, MAX, MIN } from "../../../utils/constants/inputAttrs";
import { CONTRACT_SECTIONS, FILES, ODD_JOBS, USERS } from "../../dictionary/routeNames";

import { booleanType, oddJobsStatus } from "../../../utils/constants/statusTypes";
import { FileDoc } from "./fileClass";
import { auth, db } from "../../../index";

export class OddJobFile extends FileDoc {

    constructor(data, isNew = true) {
        super(data, isNew, OddJobFile); // call parent constructor
    };
    async save() {
        if (this.docData.confirmSum == 0 && this.docData.status !== "notConfirm") {
            this.edit({
                confirmSum: this.docData.sum,
                balance: 0
            });
        }
        if (this.docData.confirmSum == this.docData.sum && this.docData.status === "donePartial") {
            this.edit({
                status: "done",
            });
        } else if (this.docData.confirmSum != this.docData.sum && this.docData.status === "done") {
            this.edit({
                status: "donePartial",
            });
        }
        if ("status" in this.docChanges) {
            try { //saving with workflow status
                const batch = db.batch()
                super.batchSave(batch);
                this.saveStatusWorkflow(batch)
                await batch.commit()
            } catch (err) {
                console.error(err);
            }

        } else {
            Promise.resolve(super.save());
        }
    }

    saveStatusWorkflow(batch = db.batch()) {
        const statusFlow = [...this.docData.statusFlow, {
            createdAt: new Date().toISOString(),
            createdBy: auth.currentUser.uid,
            totalPay: this.docData.confirmSum,
            confirmStage: this.docData.status
        }]
        batch.update(db.doc(this.docData.ref), { statusFlow })
        console.log(statusFlow, this.docData.ref);
    }

    static get meta() {
        return {
            id: ODD_JOBS,
            logic: [
                { type: VALID_CALC, target: "balance", expression: "sum - confirmSum", trigger: ["sum", "confirmSum"] },
                { type: VALID_COMPARE, expression: "confirmSum <= sum", trigger: ["sum", "confirmSum"], msg: "סכום מאושר צריך להיות זהה או קטן לסכום חשבונית" }
            ],
        }
    }
    static get headers() {
        return {
            title: { label: "שם קבלן", defaultValue: "", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            description: { label: 'הערות', defaultValue: "", },
            statusFlow: { label: 'סבב חתימות', defaultValue: [], },
            status: { label: 'סטטוס', defaultValue: "open", type: SWITCH, options: oddJobsStatus, fieldOptions: { search: false } },
            contentType: { label: "סוג קובץ", defaultValue: "", props: [{ [HIDDEN]: true }] },
            size: { label: "גודל", defaultValue: "", props: [{ [HIDDEN]: true }] },
            url: { label: "URL", defaultValue: "", props: [{ [HIDDEN]: true }] },
            file: { label: "fileBlob", defaultValue: "", props: [{ [HIDDEN]: true }] },
            attachmentID: { label: "מספר חשבונית", defaultValue: "", type: STRING, },
            fileDate: { label: "תאריך חשבונית", defaultValue: "", type: DATE, },
            confirmDate: { label: "תאריך תשלום", defaultValue: "", type: DATE, },
            sum: { label: "סכום לפני מעמ", defaultValue: 0, type: CURRENCY, props: [{ [MIN]: 0 }] },
            confirmSum: { label: "סכום מאושר לפני מעמ", defaultValue: 0, type: CURRENCY, props: [{ [MIN]: 0 }, { [MAX]: doc => doc.docData.sum }] },
            balance: { label: "יתרה", defaultValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },
            // isPay: { label: "האם שולם", defaultValue: false, type: CHECK_BOOL, options: booleanType },
        }
    }
}
