import m from 'mithril';

//DATA
import { EMAIL, PASSWORD } from '../../../utils/constants/types';

//FUNCTIONS
import { auth } from '../../../index'

//COMPONENTS
import { Logo } from '../../components/logo/Logo';
import { Card } from '../../commons/cardLayout/Card';
import { Icon } from '../../components/icon/Icon';
import { settingsStore } from '../../../data/store/settingsStore';
import { dataStore } from '../../../data/store/dataStore';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots';
import { UserDoc } from '../../../data/store/users/userClass';
import { logRouteChangeUser } from '../../../data/utils/utils';

export const LoginPage = node => {

    const handleLogin = async (e) => {
        try {
            e.target.disabled = true;
            node.state.load = true;
            const cred = await auth.signInWithEmailAndPassword(node.state.email, node.state.password)
            await UserDoc.setCurrentUser(true)
            settingsStore.defaultListeners.map(modelID => {
                dataStore[modelID].listen()
            })
            let navToPage = "/app"
            if (node.attrs.ref) navToPage = decodeURI(node.attrs.ref)
            m.redraw()
            m.route.set(navToPage);
        } catch (err) {
            node.state.snackbar = { msg: `אירעה שגיאה , תיאור: \n${err.message || err}`, isError: true }
        } finally {
            e.target.disabled = false;
            node.state.load = false;
            m.redraw()
        }
    }

    return {
        showPass: false,
        email: "",
        password: "",
        load: false,
        firstLoad: true,
        oninit: vnode => {
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get());
            logRouteChangeUser("checkIn", "/login");
            setTimeout(() => {
                vnode.state.firstLoad = false;
                if (auth.currentUser !== null) {
                    const lastURL = sessionStorage.getItem("lastURL");
                    if (lastURL !== null) {
                        m.route.set(lastURL)
                    } else {
                        m.route.set('/app');
                    }
                }
                m.redraw();
            }, 1500);
            // m.redraw()

        },
        view: vnode => {

            return (
                vnode.state.firstLoad ?
                    m('.loader show loader--active', m('.loader__spinner')) :
                    m('.login',
                        vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                        [
                            m(Card,
                                m(Logo, { action: () => null }),
                                m('form.login__form', { onsubmit: handleLogin },
                                    [
                                        m(".input", [
                                            m("input.input__field [required]", { autocomplete: "username", value: vnode.state.email, type: EMAIL, value: vnode.state.email, oninput: e => vnode.state.email = e.target.value }),
                                            m("label.input__label", "אימייל")
                                        ]),
                                        m(".input", [
                                            m("input.input__field [required]", { value: vnode.state.passsword, type: vnode.state.showPass ? "text" : PASSWORD, value: vnode.state.password, autocomplete: "current-password", oninput: e => vnode.state.password = e.target.value }),
                                            m("label.input__label", "סיסמא"),
                                            m(Icon, { class: "show", icon: vnode.state.showPass ? "icon-eye-off" : "icon-eye", action: e => { e.preventDefault(); vnode.state.showPass = !vnode.state.showPass } })
                                        ]),
                                        vnode.state.load ? m(InlineLoaderDots) : m("button.button", { onclick: e => handleLogin(e) }, "התחבר"),
                                    ])
                            )
                        ]
                    )

            )

        }
    }
}
