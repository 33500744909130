import m from "mithril"
import { DEFAULT_CHANGE_MSG } from "../../../data/store/settingsStore";
import { editValue, getInputType, getParsedValue } from "../../../data/utils/inputValidation";
import { DATE } from "../../../utils/constants/types";
import { setPropetiesToComponent } from "../../../utils/mithril";
import { setInputValidaty } from "./utils";


export const InputField = node => {

    const saveEvent = async (value) => {
        const { options, doc, header, parent } = node.attrs
        if (options && options.autoSave) {
            if (doc.hasChanges(header)) {
                try {
                    await Promise.resolve(doc.save())
                    parent.state.snackbar = { msg: DEFAULT_CHANGE_MSG }
                } catch (err) {
                    console.error(err);
                    parent.state.snackbar = { msg: err, isError: true }
                } finally {
                    m.redraw()
                }
            }
        }
    }

    return {
        view: vnode => {
            const { field, id, index, header, doc } = vnode.attrs
            const value = vnode.attrs.doc.docData[header]
            return m(`input.input__field ${vnode.attrs.class ? vnode.attrs.class : ""}`, {
                class: vnode.attrs.error ? "input__field--error" : "",
                id,
                // value: getParsedValue(value, field.type),
                type: getInputType(field.type),
                step: "any",
                tabindex: index + 1,
                oncreate: el => {
                    el.dom.focus();
                    setPropetiesToComponent(el, field.props, doc);
                    // if (field.type !== DATE) {}
                    el.dom.value = getParsedValue(value, field.type)
                    el.dom.select()
                },
                onupdate: el => {
                    if (field.type !== DATE) {
                        el.dom.value = value
                    }
                },
                onfocus: e => {
                    e.target.oldVal = e.target.value
                    // e.target.setSelectionRange(0, e.target.value.length)
                },
                onkeypress: ({ key, target }) => { if (key == "Enter") saveEvent(target.value) },
                oninput: e => {
                    const { valid, errorMsgs = [] } = editValue(e.target.oldVal, e.target.value, header, doc);
                    setInputValidaty(e.target, valid, errorMsgs)
                    if (vnode.attrs.setError) vnode.attrs.setError(errorMsgs.join("\n"))
                },
                onblur: e => {
                    if (field.type === DATE) {
                        e.target.value = getParsedValue(value, field.type)
                    }
                    saveEvent(e.target.value)
                }
            })
        }
    }

}