import m from "mithril"
import { MIME_TYPES } from "../../utils/constants/mimeTypes";
import { uuid } from "../../utils/js";
import { Card } from "../commons/cardLayout/Card";
import { PageLayout } from "../commons/pageLayout/Page";
import { InlineLoaderDots } from "../components/inlineLoaderDots/InlineLoaderDots";

// import * as fs from 'fs'
// import * as path from 'path'
// import * as neatCsv from 'neat-csv'

export const ImportCSV = node => {

    const uploadBlob = (e) => {
        e.target.disabled = true
        node.state.loading = true
        const { files } = e.target
        const reader = new FileReader();
        node.state.files = []
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            reader.onload = () => {
                const fileText = reader.result
                const id = uuid()
                const fileObj = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    data: CSVToArray(fileText, file.type, file.name, id),
                }
                node.state.files.push(fileObj)
                m.redraw()
            };
            reader.readAsText(file);
        }
        e.target.disabled = false
        m.redraw()
    }

    function CSVToArray(text, type, name) {
        const fileType = name.endsWith(".csv") ? "csv" : "xls"
        let result = []
        if (fileType === "xls") {
            const lines = text.split("</Row>");
            lines.forEach(line => {
                // <Cell><Data ss:Type="String">סאמר מלך</Data></Cell>
                const row = []
                const cells = line.matchAll(/<Cell.+<\/Cell>/gm)
                if (cells !== null) {
                    [...cells].forEach(([cell]) => {
                        console.log(cell);
                        if (cell) {
                            const match = cell.match(/([^>]+)<\/Data>/)
                            if (match !== null) {
                                row.push(match[1])
                            } else {
                                row.push("")
                            }
                        } else {
                            console.log("cell not found");
                        }
                    })
                } else {
                    console.log('no cell found in line- ', line);
                }
                result.push(row)
            })
        } else if (fileType === "csv") {
            const lines = text.split(/(?:\r\n|\n)+/).filter(el => el.length != 0);
            const config = {}
            lines.forEach((line, ind) => {
                let lineStr = line.toString()
                if (ind === 0) { //header
                    if (line.split(/"\s"/).length > 0 && line.split(",").length < line.split(/"\s"/).length) {
                        config.delimiter = /"\s"/
                        config.clear = /"/g
                    } else {
                        config.delimiter = ","
                        config.globalClear = /(").+(,).+(")/gm
                    }
                }
                const row = []
                if (config.globalClear) {
                    lineStr = line.replaceAll(config.globalClear, "")
                }
                lineStr.split(config.delimiter).forEach((cell) => {
                    let value = cell.toString()
                    if (config.clear) {
                        value = value.replaceAll(config.clear, "")
                    }
                    row.push(value || " ")
                })
                result.push(row)
            })
        }
        node.state.loading = false
        return result
    }

    return {
        files: [],
        view: vnode => {
            return m(PageLayout,
                m(Card, { class: "importCsv" },
                    m("input[type=file][hidden]#file", {
                        onchange: e => uploadBlob(e),
                        accept: [MIME_TYPES.EXCEL, MIME_TYPES.CSV].join(",")
                    }),
                    m("label[for=file].button", "בחר קובץ"),
                ),
                vnode.state.loading && m(InlineLoaderDots),
                vnode.state.files.map(file => {
                    return m(Card, { class: "importCsv__file" },
                        m("h1.importCsv__caption caption", `${file.name}- [${file.type}]`),
                        (file.data[0]) && m(".table", {
                            style: `
                                    display:grid ;
                                    grid-template-rows:repeat(${file.data.length - 1},1fr);
                                    grid-template-columns:${file.data[0].map(row => "1fr").join(" ")}`,
                        },
                            file.data.map((row, ind) => {
                                return row.map(cell => {
                                    return m(".cell", {
                                        style: `
                                            border:1px solid gray;
                                            ${ind == 0 ? "font-weight:600" : ""}
                                            `
                                    }, cell)
                                })
                            })
                        )
                    )
                })
            )
        }
    }

}