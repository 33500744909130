import m from "mithril"

//COMPONENTS
import { Card } from "../../../commons/cardLayout/Card"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { Icon } from "../../../components/icon/Icon"
import { DisplayCell } from "../../../commons/inputCell/DisplayCell"
import { MilestoneUnitActuals } from "./MilestoneUnitActuals"

//DATA
import { ACTUALS, APARTMENTS, BUILDINGS, CONTRACT_ACCOUNTS, CONTRACT_COMMENTS, FILES, SECTION_MILESTONES } from "../../../../data/dictionary/routeNames"
import { CURRENCY, NUMBER, PERCENT, REF, STRING } from "../../../../utils/constants/types"
import { SectionDoc } from "../../../../data/store/contracts/sectionClass"


//FUNCTIONS
import { editValue, getDisplayValue } from "../../../../data/utils/inputValidation"
import { preventDefaults, sortDocsBy, uuid } from "../../../../utils/js"
import { calcTotalUnitMilestones, confirmActualChanges, getLastTotalUnitMilestone, getTotalMilestones, handleConfirmStage, handleStartPeriod, sectionHasActualChanges, showTotalMsActual, showTotalSectionActual } from "../utils"
import { setInputValidaty } from "../../../commons/inputCell/utils"
import { Nav, setActiveTab } from "../../../commons/nav/Nav"
import { Calculator } from "../../calculator/Calculator"
import { isUserAllow } from "../../../../data/utils/permission"
import { UPDATE } from "../../../../data/store/permissionStore"
import { Tooltip } from "../../../components/tooltip/Tooltip"
import { FilesUpload } from "../../../commons/fileUpload/FilesUpload"
import { REG_URL_LAST_PART } from "../../../../utils/constants/regex"
import { BuildingDraw } from "../../apartments/BuildingDraw"
import { Table } from "../../../commons/table/Table"
import { IconButton } from "../../../components/icon/IconButton"
import { InputCell } from "../../../commons/inputCell/InputCell"
import { LocalInput } from "../../../commons/inputCell/LocalInput"
import { DocModel } from "../../../../data/store/docModel"
import { ContractCommentDoc } from "../../../../data/store/contractsActuals/contractCommentClass"

export const SectionActual = node => {

    const sectionHeaders = [
        "title",
        "contractorRef",
        "calculationMethod",
        "calculationType",
        "amountType",
        "itemsCount",
        "itemsStartIndex",
        "itemPrice",
        "totalSum",
        "description",
        // "donePercentage",
    ]

    const apartmentMilestoneHeaders = {
        unit: { label: "#", info: "יחידה" }
    }
    const milestoneHeaders = {
        unit: { label: "#", info: "יחידה" }
    }
    const milestonePeriodHeaders = {
        period: { label: "#", info: "תקופה" },
        note: { label: "הערות", info: "תיאור והערות" },
    }

    const toggleCollaspe = () => node.state.isCollaspe = !node.state.isCollaspe

    // const toggleApartmentView = () => {
    //     node.state.showAllApartments = !node.state.showAllApartments
    // }

    const promtFloor = (e, floor, building) => {
        preventDefaults(e)
        const { contract, section } = node.attrs
        const project = contract.getParent()
        const buildingFloorPath = floor.getBuildingFloorPath()
        node.state.selectedFloor = floor
        const floorPathText = `מבנה ${building.docData.title} / קומה ${floor.docData.title}`
        node.state.prompt = {
            title: `בחירת דירה משוייכת לחוזה - ${floorPathText}`,
            form: () => {
                return m(".selectedApartment",
                    m(IconButton, { icon: "icon-right", action: e => promptBuildings() }),
                    m(Table, { //TABLE ?
                        title: `דירות בקומה ${floorPathText}`,
                        modelID: APARTMENTS,
                        colRef: `projects/${node.attrs.projectID}/${APARTMENTS}`,
                        doc: project,
                        tableHeaders: ["title", "description", "contractsRefs", "apartmentModel"],
                        actions: {
                            onRow: (_node, doc) => {
                                if (doc.docData.contractsRefs.length === 0) {
                                    doc.edit({ contractsRefs: [...doc.docData.contractsRefs, section.docData.ref] })
                                    Promise.resolve(doc.save())
                                        .then(() => {
                                            node.state.snackbar = { msg: `הדירה קושרה לסעיף זה` }
                                            node.state.prompt = false
                                        })
                                }
                            },
                            copy: false, remove: false, move: true, enlarge: false, redirect: false,
                        },
                        viewOptions: { filters: false, add: false, nav: false, totals: false, download: false },
                        filterDataOptions: { include: { buildingFloorPath } },
                        // searchOptions: ["title", "description"],
                    })
                )
            }
        }
    }
    const promptBuildings = () => {
        const { contract, section } = node.attrs
        const project = contract.getParent()
        const buildingRefs = section.getWorkspaceBuildings()
        node.state.prompt = {
            title: "בחירת דירה משוייכת לחוזה",
            form: () => {
                return m(".buildings",
                    project.getChildren(BUILDINGS)
                        .filter(building => buildingRefs.length === 0 || buildingRefs.includes(building.docData.ref))
                        .map(building => {
                            return m(BuildingDraw, {
                                building,
                                actions: { floorClick: promtFloor }
                            })
                        }),
                )
            }
        }
    }

    const toggleActivePromt = (key, stateKey, navArray) => {
        node.state[stateKey] = key;
        setActiveTab(navArray, key)
    }

    const NAV_SECTION_VIEW = {
        CUMULATIVE: "cumulative",
        PERIOD: "period"
    }
    const navSectionView = [
        {
            key: NAV_SECTION_VIEW.CUMULATIVE,
            label: "מצטבר",
            active: true,
            action: ({ e }) => toggleActivePromt(NAV_SECTION_VIEW.CUMULATIVE, "navSectionView", navSectionView)
        },
        {
            key: NAV_SECTION_VIEW.PERIOD,
            label: "תקופות",
            active: false,
            action: ({ e }) => toggleActivePromt(NAV_SECTION_VIEW.PERIOD, "navSectionView", navSectionView)
        },
    ]

    const navPromtActual = [
        {
            key: "calculator",
            label: "מחשבון",
            active: true,
            action: ({ e }) => toggleActivePromt("calculator", "activePromptTab", navPromtActual)
        },
        {
            key: "history",
            label: "הסטורייה",
            active: false,
            action: ({ e }) => toggleActivePromt("history", "activePromptTab", navPromtActual)
        },
    ]

    const getAccountSectionCommentDoc = (accountRef) => {
        const { section, contract } = node.attrs
        const sectionRef = section.docData.ref
        return contract.getChildren(CONTRACT_COMMENTS, {
            include: {
                accountRef,
                sectionRef,
            }
        })[0]
    }

    const promptActual = (e, milestone, unit, accountRef, elemID, isPeriod) => {
        const isEditable = true //TODO:
        preventDefaults(e)
        node.state.prompt = {
            title: "ביצוע יחידה",
            form: () => {
                return [
                    m(Nav, { class: "", nav: navPromtActual }),
                    (node.state.activePromptTab === undefined || node.state.activePromptTab === "calculator") && [
                        m(Calculator, { parent: node }),
                        node.state.tempCalc && m(".", { style: "font-size:1.4rem;border-top: 1px solid gray;" }, getDisplayValue(node.state.tempCalc, NUMBER, { isFloat: true })),
                        (node.state.tempCalc && isEditable) && m("button.button", {
                            style: "max-width:auto;",
                            onclick: e => {
                                // const { header, doc } = node.attrs
                                const value = node.state.tempCalc;
                                if (value && !isNaN(parseFloat(value) && value !== 0)) {
                                    // editValue(node.state.oldValue, value, header, doc)
                                    // console.log("TESTME: edit actual based on value", value);
                                    const elem = document.getElementById(elemID)
                                    if (elem !== null) {
                                        let actualValue = parseFloat(value)
                                        elem.value = value
                                        const actualDoc = milestone.getActualDocInAccount({ accountRef, unit })
                                        if (isPeriod) {
                                            //actual value = value
                                        } else {
                                            const lastTotalUnitMilestone = getLastTotalUnitMilestone(milestone, { accountRef, unit })
                                            const diffValue = parseFloat(elem.value) - lastTotalUnitMilestone
                                            actualValue = diffValue
                                        }
                                        const { valid, errorMsgs } = editValue(elem.oldVal, actualValue, "value", actualDoc)
                                        e.target.oldVal = e.target.value
                                        setInputValidaty(elem, valid, errorMsgs)
                                    }

                                }
                                node.state.prompt = false
                            }
                        }, "השתמש בתוצאה כערך")
                    ],
                    (node.state.activePromptTab === "history") && m(MilestoneUnitActuals, { milestone, unit, accountRef })
                ]
            }
        }
    }

    return {
        snackbar: false,
        prompt: false,
        isCollaspe: false,
        showAllApartments: false,
        view: vnode => {
            const { isCollaspe, showAllApartments } = vnode.state
            const { section, contract, currentAccount, isCollapsible, isPrompt } = vnode.attrs
            const milestones = section.getChildren(SECTION_MILESTONES, { dataOnly: true }).sort(sortDocsBy("index", { type: NUMBER }))
            const isPauschal = section.isPauschal()
            const isApartment = section.isApartment()
            const isPercent = section.isPercent()
            const totalMilesones = getTotalMilestones(section, milestones, isPercent)
            const accountRef = currentAccount.docData.ref
            const isStart = currentAccount.docData.stage === "start"
            const isFinish = currentAccount.docData.stage === "finish"
            const isAccounting = currentAccount.getCurrentStage().stage.type && currentAccount.getCurrentStage().stage.type === "accounting"
            // const isAccounting = false //
            const currentActiveAccount = contract.getActiveAccount()
            const isOtherMonth = currentActiveAccount && currentActiveAccount.docData.month !== currentAccount.docData.month
            const isUserAllowEdit = isUserAllow(ACTUALS, UPDATE, currentAccount)
            return m(Card, {
                key: section.docData.ref,
                class: `section`,
                id: section.docData.docID,
                autofocus: "autofocus",
                tabindex: 1
            },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode, class: vnode.state.prompt.class || "" },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    vnode.state.prompt.msg && m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                isPrompt && m(".breadCrumbs",
                    m(".breadCrumbs__link", `פרוייקט: ${contract.getParent("title")}`),
                    m(".breadCrumbs__link", `חוזה: ${contract.docData.title}`),
                    m(".breadCrumbs__link", `קבלן: ${getDisplayValue(contract.docData.contractorRef, REF)}`),
                    m(".breadCrumbs__link", `חשבון: ${currentAccount.docData.month}`),
                    m(".breadCrumbs__link", `סעיף: ${section.docData.title}`),
                ),
                isAccounting && m(".danger", { style: "grid-column:1/-1" }, "חשבון בשלב הנהלת חשבונות לא ניתן לערוך חשבון"),
                isStart && m(".danger link", { style: "grid-column:1/-1", onclick: e => handleStartPeriod(vnode, vnode.attrs.currentAccount) }, "לא ניתן לערוך חשבון , יש לאתחל חשבון תחילה"),
                isFinish && m(".danger", { style: "grid-column:1/-1" }, "לא ניתן לערוך חשבון , יש לסיים תקופה ולהתחיל תקופה חדשה"),
                isOtherMonth && m(".info", { style: "grid-column:1/-1" }, "חשבון לצפייה בלבד (תקופה קודמת)"),
                //TODO: remove collaspe options
                isCollapsible && m(".caption", { onclick: e => toggleCollaspe(), class: isCollaspe ? ".caption--collaspe" : "" }, section.docData.title, m(Icon, { icon: `icon-triangle-${isCollaspe ? "up" : "down"}`, class: "icon-collaspe" })),
                !isCollapsible && m(".caption", `${section.docData.title} | חשבון תקופתי ${vnode.attrs.currentAccount.docData.month}`),
                !isCollaspe && [sectionHeaders.map((header, index) => {
                    return m(DisplayCell, {
                        value: section.docData[header],
                        field: SectionDoc.headers[header],
                        doc: section,
                        header
                    })
                }),
                m(Table, {
                    title: "הערות על סעיף",
                    modelID: CONTRACT_COMMENTS,
                    colRef: `${contract.docData.ref}/${CONTRACT_COMMENTS}`,
                    doc: contract,
                    tableHeaders: ["accountRef", "title", "description"],
                    viewOptions: { filters: false, add: true, nav: false, totals: false, download: false },
                    filterDataOptions: { include: { sectionRef: section.docData.ref } },
                    addDataOptions: {
                        accountRef: currentAccount.docData.ref,
                        sectionRef: section.docData.ref
                    },
                    actions: { onRow: "prompt", copy: false, remove: false, move: false, enlarge: false },
                }),

                !isApartment && m(".caption caption--sub", "טבלת ביצוע"),
                !isApartment && (!section.isNew && milestones.length > 0 && (section.docData.itemsCount <= 1 || !section.isPauschal())) && m(Nav, { class: "", nav: navSectionView }),
                //*******************************************************************/
                //PERIOD VIEW 📅
                //*******************************************************************/
                !isApartment && (!section.isNew && milestones.length > 0 && vnode.state.navSectionView === NAV_SECTION_VIEW.PERIOD) && m(".section__milestones", {
                    style: `grid-template-columns: repeat(${Object.keys(milestonePeriodHeaders).length},max-content) [ms-s] repeat(${milestones.length},1fr) [ms-e curr-total-s] 1fr [curr-total-e total-s] 1fr [total-e];`,
                },
                    //headers + titles + currentTotalColumn + totalColumn 
                    Object.entries(milestonePeriodHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", header.label)),
                    milestones.map((milestone, ind) => {
                        return m(".section__milestone section__milestone--title", { key: 'ms_title_' + ind }, milestone.docData.title)
                    }),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סיכום"), //subTotalColumn
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"), //totalColumn

                    //weight|price sub-headers
                    Object.entries(milestonePeriodHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", "-")),
                    milestones.map((milestone, index, arr) => m(".section__milestone section__milestone--value", `${getDisplayValue(milestone.docData[isPercent ? "weight" : "price"], isPercent ? PERCENT : CURRENCY)}${isPercent ? " [" + getDisplayValue(parseFloat(milestone.docData.weight / 100) * section.docData.itemPrice, CURRENCY) + "]" : ""}`)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "-"),//subTotalColumn
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", `${getDisplayValue(totalMilesones, isPercent ? PERCENT : CURRENCY)}${isPercent ? " [" + getDisplayValue(section.docData.itemPrice, CURRENCY) + "]" : ""}`),//totalColumn

                    //period values
                    contract.getChildren(CONTRACT_ACCOUNTS)
                        .sort(sortDocsBy("period", { type: NUMBER }))
                        .map((account, ind, arr) => {
                            const currentAccountRef = account.docData.ref
                            const isCurrentAccount = currentAccountRef === accountRef
                            const AccountSectionCommentDoc = getAccountSectionCommentDoc(currentAccountRef)
                            const [totalUnitMilestones, totalSumUnit] = calcTotalUnitMilestones(section, milestones, false, currentAccountRef, isPercent)
                            console.log(totalUnitMilestones);
                            return [
                                m(".section__milestone section__milestone--unit [dir=ltr]", account.docData.month),
                                contract.hasCommentOnSection(section.docData.ref, currentAccountRef) ?
                                    m(InputCell, {
                                        class: "section__milestone",
                                        options: { ignoreLabel: true, editToggle: false },
                                        settings: { autoSave: true },
                                        doc: AccountSectionCommentDoc, header: "title", index: ind,
                                        id: AccountSectionCommentDoc.docData.ref,
                                        parent: vnode,
                                    }) :
                                    m("button.button--text", {
                                        onclick: e => {
                                            new ContractCommentDoc({
                                                colRef: `${contract.docData.ref}/${CONTRACT_COMMENTS}`,
                                                accountRef: currentAccountRef,
                                                sectionRef: section.docData.ref
                                            }, true)
                                        }
                                    }, "הוסף הערה"),
                                milestones.map((milestone, index, arr) => {
                                    const elemID = `${milestone.docData.ref}/${currentAccountRef}/value`
                                    const last = milestone.getLastTotalActuals({ accountRef })
                                    const currentActual = milestone.getTotalActuals({ accountRef: currentAccountRef })
                                    const percentDone = isPauschal ? parseInt(currentActual) : parseInt(Number(currentActual) / Number(section.docData.itemsCount) * 100)
                                    const isCurrentAccount = currentAccountRef === accountRef
                                    let isAllowEdit = !isStart && !isOtherMonth && !isFinish && !isAccounting && isUserAllowEdit && isCurrentAccount
                                    if (isAllowEdit) {
                                        return m(".section__milestone.section__milestone--actual", {
                                            "data-label": isPauschal ? "%" : section.docData.amountType,
                                            "data-tooltip": true,
                                            style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                        },
                                            m(Tooltip, { text: `${milestone.docData.title}:\n${currentActual}` }),
                                            m("input.input__field", {
                                                key: elemID,
                                                type: "number",
                                                id: elemID,
                                                min: 0,
                                                max: isPauschal ? 100 - last : parseFloat(section.docData.itemsCount) - last,
                                                oncreate: el => el.dom.value = currentActual,
                                                onupdate: el => el.dom.value = currentActual,
                                                onfocus: e => e.target.oldVal = e.target.value,
                                                step: "any",
                                                oninput: e => {
                                                    if (!isNaN(parseInt(e.target.value)) && e.target.value !== e.target.oldVal) {
                                                        //THINK: consts are map scope OR event scope
                                                        const actualDoc = milestone.getActualDocInAccount({ accountRef: currentAccountRef })
                                                        const { valid, errorMsgs } = editValue(e.target.oldVal, e.target.value, "value", actualDoc)
                                                        e.target.oldVal = e.target.value
                                                        setInputValidaty(e.target, valid, errorMsgs)
                                                    }
                                                },
                                            }),
                                            m(Icon, { class: "prompt-btn", icon: "icon-dots-vertical", action: e => promptActual(e, milestone, false, currentAccountRef, elemID, true) })
                                        )
                                    } else {
                                        if (currentActual === 0) return m(".section__milestone.section__milestone--actual", "---")
                                        return m(".section__milestone.section__milestone--actual", {
                                            "data-tooltip": true,
                                            style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                        },
                                            m(Tooltip, { text: `${milestone.docData.title}:\n${currentActual}` }),
                                            getDisplayValue(currentActual, NUMBER, { symbol: isPauschal ? "%" : section.docData.amountType, isFloat: true })
                                        )
                                    }
                                }),
                                m(".section__milestone section__milestone--total section__milestone--columnTotal",
                                    isPauschal ?
                                        `${getDisplayValue(totalUnitMilestones, PERCENT)}` :
                                        "-"
                                        // getDisplayValue(totalUnitMilestones, NUMBER, { symbol: section.docData.amountType }),
                                ), //subTotalColumn

                                m(".section__milestone section__milestone--total section__milestone--columnTotal",
                                    getDisplayValue(section.calcTotalActuals({ accountRef: currentAccountRef }), CURRENCY)
                                ),//total
                            ]
                        }),  //END map periods

                    //total rows:
                    //[amount total]
                    !isPauschal && m(".section__milestone section__milestone--total", "סהכ כמותי"),
                    !isPauschal && m(".section__milestone section__milestone--total", "-"), //note
                    !isPauschal && milestones.map((ms, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, getDisplayValue(ms.getTotalActuals(), NUMBER, { symbol: section.docData.amountType }))),//ms amount total
                    !isPauschal && m(".section__milestone section__milestone--total section__milestone--columnTotal", getDisplayValue(section.getTotalActuals(), NUMBER, { symbol: section.docData.amountType })),//section amount total
                    !isPauschal && m(".section__milestone section__milestone--total section__milestone--columnTotal", "-"), // [cash total column]

                    //[cashTotal]
                    m(".section__milestone section__milestone--total", "סהכ"),
                    m(".section__milestone section__milestone--total", "-"),//note
                    milestones.map((ms, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, `${showTotalMsActual(section, ms)}`)),//ms cash total
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "-"), //[amount total]
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", showTotalSectionActual(section)), //section cash total
                ),

                //*******************************************************************/
                //CUMULATIVE VIEW(DEFAULT)
                //*******************************************************************/
                !isApartment && (!section.isNew && milestones.length > 0 && (vnode.state.navSectionView === NAV_SECTION_VIEW.CUMULATIVE || vnode.state.navSectionView == undefined)) &&
                !isApartment && m(".section__milestones", {
                    style: `grid-template-columns: repeat(${Object.keys(milestoneHeaders).length},max-content) repeat(${milestones.length},1fr) 1fr;`,
                    key: uuid(),
                },
                    //headers + titles + totalColumn
                    Object.entries(milestoneHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", header.label)),
                    milestones.map((milestone, ind) => {
                        return m(".section__milestone section__milestone--title", { key: 'ms_title_' + ind }, milestone.docData.title)
                    }),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"), //totalColumn

                    //weight|price sub-headers
                    Object.entries(milestoneHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", "-")),
                    milestones.map((milestone, index, arr) => m(".section__milestone section__milestone--value", `${getDisplayValue(milestone.docData[isPercent ? "weight" : "price"], isPercent ? PERCENT : CURRENCY)}${isPercent ? " [" + getDisplayValue(parseFloat(milestone.docData.weight / 100) * section.docData.itemPrice, CURRENCY) + "]" : ""}`)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", `${getDisplayValue(totalMilesones, isPercent ? PERCENT : CURRENCY)}${isPercent ? " [" + getDisplayValue(section.docData.itemPrice, CURRENCY) + "]" : ""}`),

                    //unit values
                    section.buildVirtualUnits().map((unit, ind, arr) => {
                        const [totalUnitMilestones, totalSumUnit] = calcTotalUnitMilestones(section, milestones, unit, false, isPercent)
                        return [
                            m(".section__milestone section__milestone--unit", (isPauschal && arr.length > 1) ? `${section.docData.amountType} - ${unit}` : `*`),
                            milestones.map((milestone, index, arr) => {
                                // const elemID = `${milestone.docData.ref}/${unit}/value`
                                const elemID = uuid()
                                const totalActual = milestone.getTotalActuals({ unit })
                                const percentDone = isPauschal ? parseInt(totalActual) : parseInt(Number(totalActual) / Number(section.docData.itemsCount) * 100)
                                let isAllowEdit = !isStart && !isOtherMonth && !isFinish && !isAccounting && isUserAllowEdit && !isApartment
                                if (isAllowEdit) {
                                    return m(".section__milestone.section__milestone--actual", {
                                        "data-label": isPauschal ? "%" : section.docData.amountType,
                                        "data-tooltip": true,
                                        style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                    },
                                        m(Tooltip, { text: `${milestone.docData.title}:\n${totalActual}` }),
                                        m("input.input__field", {
                                            key: elemID,
                                            type: "number",
                                            id: elemID,
                                            min: 0,
                                            max: isPauschal ? 100 : section.docData.itemsCount,
                                            oncreate: el => el.dom.value = totalActual,
                                            onupdate: el => el.dom.value = totalActual,
                                            onfocus: e => e.target.oldVal = e.target.value,
                                            step: "any",
                                            // ondblclick: e => vnode.state.prompt = { title: `פירוט: ${milestone.docData.title} [${unit}]`, showActuals: { milestone, accountRef, unit } },
                                            oninput: e => {
                                                if (!isNaN(parseInt(e.target.value)) && e.target.value !== e.target.oldVal) {
                                                    //THINK: consts are map scope OR event scope
                                                    const actualDoc = milestone.getActualDocInAccount({ accountRef, unit })
                                                    const lastTotalUnitMilestone = getLastTotalUnitMilestone(milestone, { accountRef, unit })
                                                    const diffValue = parseFloat(e.target.value) - lastTotalUnitMilestone
                                                    const { valid, errorMsgs } = editValue(e.target.oldVal, diffValue, "value", actualDoc)
                                                    e.target.oldVal = e.target.value
                                                    setInputValidaty(e.target, valid, errorMsgs)
                                                }
                                            },
                                        }),
                                        m(Icon, { class: "prompt-btn", icon: "icon-dots-vertical", action: e => promptActual(e, milestone, unit, accountRef, elemID) })
                                    )
                                } else {
                                    if (totalActual === 0) return m(".section__milestone.section__milestone--actual", "---")
                                    return m(".section__milestone.section__milestone--actual", {
                                        "data-tooltip": true,
                                        style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                    },
                                        m(Tooltip, { text: `${milestone.docData.title}:\n${totalActual}` }),
                                        getDisplayValue(totalActual, NUMBER, { symbol: isPauschal ? "%" : section.docData.amountType, isFloat: true })
                                    )
                                }
                            }),
                            m(".section__milestone section__milestone--total section__milestone--columnTotal",
                                isPauschal ?
                                    `${getDisplayValue(totalUnitMilestones, PERCENT)} |  ${getDisplayValue(totalSumUnit, CURRENCY)}` :
                                    getDisplayValue(totalUnitMilestones, NUMBER, { symbol: section.docData.amountType }),
                            ),
                        ]
                    }), //END map units

                    //total rows
                    m(".section__milestone section__milestone--total", "סהכ"),
                    milestones.map((ms, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, `${showTotalMsActual(section, ms)}`)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", showTotalSectionActual(section)),
                ),//END cumulative view
                ],

                isApartment && m(".caption caption--sub",
                    "טבלת דירות - ביצוע",
                    // m(CheckCell, { class: "section__toggleTableView", checked: showAllApartments, setCheck: () => toggleApartmentView(), label: `כל הדירות` }),
                ),

                isApartment && m(".section__milestones", {
                    style: `grid-template-columns: repeat(${Object.keys(apartmentMilestoneHeaders).length},max-content) repeat(${milestones.length},1fr) 1fr;`,
                    key: uuid(),
                },
                    //headers + titles + totalColumn
                    Object.entries(apartmentMilestoneHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", header.label)),
                    milestones.map((milestone, ind) => {
                        return m(".section__milestone section__milestone--title", milestone.docData.title)
                    }),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"), //totalColumn

                    //weight|price sub-headers
                    Object.entries(apartmentMilestoneHeaders).map(([key, header]) => m(".section__milestone section__milestone--header", "-")),
                    milestones.map((milestone, index, arr) => m(".section__milestone section__milestone--value", `${getDisplayValue(milestone.docData[isPercent ? "weight" : "price"], isPercent ? PERCENT : CURRENCY)}${isPercent ? " [" + getDisplayValue(parseFloat(milestone.docData.weight / 100) * section.docData.itemPrice, CURRENCY) + "]" : ""}`)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", `${getDisplayValue(totalMilesones, isPercent ? PERCENT : CURRENCY)}${isPercent ? " [" + getDisplayValue(section.docData.itemPrice, CURRENCY) + "]" : ""}`),

                    contract.getParent().getChildren(APARTMENTS)
                        .sort(sortDocsBy("index", { type: NUMBER }))
                        .map(apartment => {
                            // showAllApartments ? {} : { include: { contractsRefs: doc => doc.docData.contractsRefs.includes(section.docData.ref) } }
                            const isMatchToCurrent = apartment.docData.contractsRefs.includes(section.docData.ref)
                            if (!isMatchToCurrent && !showAllApartments) return
                            return [
                                m(".section__milestone section__milestone--unit", `מבנה: ${apartment.getBuilding("title")} - דירה ${apartment.docData.title}`),
                                milestones.map(milestone => {
                                    const unit = apartment.docData.ref
                                    const elemID = `${milestone.docData.ref}/${unit}/value`
                                    const filterSettings = {}
                                    if (isMatchToCurrent) filterSettings.unit = unit
                                    const currentActual = milestone.getTotalActuals(filterSettings)
                                    const percentDone = parseInt(Number(currentActual) / 1 * 100)
                                    let isAllowEdit = isMatchToCurrent && !isStart && !isOtherMonth && !isFinish && !isAccounting && isUserAllowEdit
                                    if (isAllowEdit) {
                                        return m(".section__milestone.section__milestone--actual", {
                                            "data-label": "דירה",
                                            "data-tooltip": true,
                                            style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                        },
                                            m(Tooltip, { text: `${milestone.docData.title}:\n${currentActual}` }),
                                            m("input.input__field", {
                                                key: elemID,
                                                type: "number",
                                                id: elemID,
                                                min: 0,
                                                max: 1,
                                                oncreate: el => el.dom.value = currentActual,
                                                onupdate: el => el.dom.value = currentActual,
                                                onfocus: e => e.target.oldVal = e.target.value,
                                                step: "any",
                                                oninput: e => {
                                                    if (!isNaN(parseInt(e.target.value)) && e.target.value !== e.target.oldVal) {
                                                        //THINK: consts are map scope OR event scope
                                                        const actualDoc = milestone.getActualDocInAccount({ accountRef, unit })
                                                        const { valid, errorMsgs } = editValue(e.target.oldVal, e.target.value, "value", actualDoc)
                                                        e.target.oldVal = e.target.value
                                                        setInputValidaty(e.target, valid, errorMsgs)
                                                    }
                                                },
                                            }),
                                            m(Icon, { class: "prompt-btn", icon: "icon-dots-vertical", action: e => promptActual(e, milestone, apartment.docData.ref, accountRef, elemID, false) })
                                        )
                                    } else {
                                        if (currentActual === 0) return m(".section__milestone.section__milestone--actual", "---")
                                        return m(".section__milestone.section__milestone--actual", {
                                            "data-tooltip": true,
                                            style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                        },
                                            m(Tooltip, { text: `${milestone.docData.title}:\n${currentActual}` }),
                                            getDisplayValue(currentActual, NUMBER, { symbol: "דירה", isFloat: true })
                                        )
                                    }
                                }),
                                m(".section__milestone section__milestone--total section__milestone--columnTotal", "---"),
                            ]
                        }),
                    //total rows:
                    // [amount total]
                    m(".section__milestone section__milestone--total", "סהכ כמותי"),
                    milestones.map((ms, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, getDisplayValue(ms.getTotalActuals(), NUMBER, { symbol: "דירות", isFloat: true }))),//ms amount total
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", getDisplayValue(section.getTotalActuals(), NUMBER, { symbol: "דירות", isFloat: true })),//section amount total

                    //[cashTotal]
                    m(".section__milestone section__milestone--total", "סהכ"),
                    milestones.map((ms, ind) => m(".section__milestone section__milestone--total", { key: "total_" + ind }, `${showTotalMsActual(section, ms)}`)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", showTotalSectionActual(section)),
                    //הוספת דירה מקושרת לסעיף
                    section.getApartments().length < section.docData.itemsCount && m("button.button tableCard__add", {
                        onclick: e => promptBuildings()
                    },
                        m(Icon, { icon: "icon-plus" })
                    )
                ),

                m(FilesUpload, {
                    addDataOptions: { status: "confirm", accountRef: currentAccount.docData.ref },
                    filterDataOptions: { include: { sectionRef: section.docData.ref } },
                    colRef: `${contract.docData.ref}/${FILES}`,
                    modelID: FILES,
                    doc: contract,
                    multiple: true
                }),

                sectionHasActualChanges(section, accountRef) && m(".formCard__actions",
                    m("button.button formCard__save", {
                        onclick: e => confirmActualChanges(vnode, section, currentAccount, () => {
                            if (isPrompt) vnode.attrs.parent.state.prompt = false
                            else m.route.set(m.route.get().replace(REG_URL_LAST_PART, ""))
                        })
                    }, "שמור שינויים"),
                    m("button.button formCard__abort", { onclick: e => section.abortActualChanges({ accountRef: accountRef }) }, "בטל שינויים"),
                )
            ) //END section
        }
    }
}