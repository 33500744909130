export const getPathOptions = (globalPath, resourceType) => {
    const result = {}
    const path = globalPath.startsWith("/") ? globalPath.substring(1) : globalPath
    const split = path.split('/');
    const where = [];
    if (split.indexOf("all") === -1 && split.length === 1) {
        result.path = split[0];
    } else {
        const partialPath = [];
        split.forEach((part, i) => {
            const resourceID = split[i - i % 2];
            // if(resourceType==='COL') level++;
            if (i % 2 === 0) { //col part
                const index = Number(i + 1); // 1 , 3 , 5 etc.
                partialPath.push(split.slice(0, index).join("/"));
            }
            if (i % 2 === 1) { //doc part
                let level = split.length - i;
                if (resourceType === "COL") level++;
                // console.log('level ',level, '-> of ', split.length , " : ", resourceID);
                if (part !== 'all') where.push({ [`${resourceID}#${level}`]: `docID == ${part}` });
            }
            if (i === split.length - 1 && i >= 2) result.isGroup = true
            if (i === split.length - 1) result.path = resourceID;
        })
        result.where = where.reverse();
        result.partialPath = partialPath;
    }
    return result;
}

export const getPathArgs = path => {
    const args = {}
    const [base, query] = path.split("?")
    if (query) {
        const argsArr = query.split("&")
        argsArr.forEach(arg => {
            const [key, value] = arg.split("=")
            if (key && value) {
                args[key] = value
            }
        })
    }
    return args
}