import { DATE_RANGE } from "../../utils/constants/filtersTypes"
import { O_ARRAY, O_OBJECT, O_STRING } from "../../utils/constants/objTypes"
import { LIST } from "../../utils/constants/types"
import { dateBefore, dateAfter, objType } from "../../utils/js"
import { getDisplayDoc } from "./inputValidation"
import { DocModel } from "../store/docModel"

export const isFiltered = (vnode, doc, filtersKey = "filters") => {
    let isFiltered = false
    vnode.state[filtersKey].forEach(filter => {
        const fieldValue = doc.docData[filter.id]
        if (filter.type && filter.type === DATE_RANGE) {
            if (fieldValue === '') {
                isFiltered = isFiltered || false
            } else {
                const currentDate = new Date(fieldValue)
                const paramDate = new Date(filter.param)
                if (filter.logic === "after") {
                    // if date before so its not after
                    isFiltered = isFiltered || dateBefore(currentDate, paramDate)
                } else if (filter.logic === "before") {
                    // if date after so its not before
                    isFiltered = isFiltered || dateAfter(currentDate, paramDate)
                } else throw `${filter.logic} logic not found`
            }
        } else {
            isFiltered = isFiltered || fieldValue === filter.param
        }
    })
    return isFiltered
}


export const isDocMatchTerm = (vnode, doc, fieldsInSearch, searchTerm = "searchTerm") => {
    if (vnode.state[searchTerm].trim().length > 0) {
        let match = false
        fieldsInSearch.forEach((field, ind) => {
            let fieldValue
            if (objType(field) === O_STRING) {
                if (field.startsWith("^")) fieldValue = doc.getParent(field.substring(1))
                else fieldValue = doc.docData[field]
                if (doc.headers[field] && doc.headers[field].type === LIST) {
                    const findDoc = DocModel.getDoc(fieldValue)
                    if (findDoc) fieldValue = findDoc.docData.title
                }
            } else if (objType(field) === O_OBJECT) { //[{header:"",params:["title","other"]}]
                const { header, param, params } = field
                fieldValue = ""
                if (doc.headers[header].type === LIST) {
                    const findDoc = DocModel.getDoc(doc.docData[header])
                    if (findDoc) fieldValue = getDisplayDoc(findDoc, { param, params })
                } else {
                    console.error("object type is not supported with  types other then LIST");
                }
            }
            if (fieldValue === undefined) fieldValue = ""
            match = match || fieldValue.toString().indexOf(vnode.state[searchTerm].trim()) !== -1
        })
        return match
    } else {
        return true
    }
}
export const isListMatchTerm = (vnode, fieldValue) => {
    if (vnode.state.searchTerm.trim().length > 0) {
        let match = false
        match = match || fieldValue.toString().indexOf(vnode.state.searchTerm.trim()) !== -1
        return match
    } else {
        return true
    }
}