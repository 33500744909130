import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../../commons/pageLayout/Page';
import { Prompt } from '../../../commons/prompt/Prompt';
import { Snackbar } from '../../../components/snackbar/Snackbar';
import { Card } from '../../../commons/cardLayout/Card';
import { InlineLoaderDots } from '../../../components/inlineLoaderDots/InlineLoaderDots';
import { InputCell } from '../../../commons/inputCell/InputCell';

//DATA
import { VENDOR_CONTACTS, VENDORS } from '../../../../data/dictionary/routeNames';
import { dataStore } from '../../../../data/store/dataStore';
import { REG_URL_LAST_PART$NQ } from '../../../../utils/constants/regex';
import { ContactDoc } from '../../../../data/store/vendors/contactClass';
import { DisplayCell } from '../../../commons/inputCell/DisplayCell';
import { isUserAllow } from '../../../../data/utils/permission';
import { UPDATE } from '../../../../data/store/permissionStore';

// FUNCTIONS

export const VendorContact = node => {

    const findContact = () => {
        const contact = [].concat(dataStore[VENDOR_CONTACTS].data, dataStore[VENDOR_CONTACTS].new).find(doc => doc.docData.docID === node.attrs.contactID)
        if (contact) {
            node.state.contact = contact
        } else {
            const navToPath = m.route.get().replace(REG_URL_LAST_PART$NQ$NQ, "").replace(REG_URL_LAST_PART$NQ, "")
            m.route.set(navToPath);
            m.redraw()
        }
    }

    const confirmChanges = () => {
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: "האם לשמור את השינויים שבוצעו בחוזה?",
            action: e => {
                node.state.snackbar = { msg: "שומר..." };
                node.state.contact.save();
                node.state.prompt = false
                node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                m.redraw()
            },
            actionText: "שמור"
        }
    }

    const contactHeaders = [
        'title',
        "email",
        "phone",
        "dob",
        "address",
        "role",
        "status",
    ]


    const isRemoved = () => node.state.contact && node.state.contact.isRemoved

    return {
        load: true,
        contactHeaders: [...contactHeaders],
        prompt: false,
        snackbar: false,
        oninit: vnode => {
            const [vendors, vendorID] = m.route.get().substring(5).replace(/\?\w+/, '').split("/")
            const vendorPath = `vendors/${vendorID}`
            dataStore[VENDORS].listen()
            const { alreadyListen } = dataStore[VENDOR_CONTACTS].listen(vendorPath)
            if (alreadyListen) {
                findContact()
                vnode.state.load = false;
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען פרטי איש קשר...", loading: true }
                setTimeout(() => {
                    findContact()
                    vnode.state.load = false
                    m.redraw()
                }, 3000)

            }
        },
        view: vnode => {
            return (
                m(PageLayout, {
                    class: "contact",
                    title: "פרטי איש קשר"
                },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.action && m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                    ),
                    isRemoved() ? "איש קשר זה נמחק" : m(Card, { class: "formCard contact__details" },
                        m("h3.formCard__caption caption", "פרטי איש קשר"),
                        vnode.state.loadContact && m(InlineLoaderDots),
                        (vnode.state.contact && vnode.state.contact.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                        vnode.state.contact && vnode.state.contactHeaders.map((header, index) => {
                            const value = vnode.state.contact.docData[header]
                            const field = ContactDoc.headers[header]
                            if (isUserAllow(VENDOR_CONTACTS, UPDATE)) {
                                return m(InputCell, {
                                    header, value, field, index, id: `contact_${header}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.contact,
                                    parent: vnode
                                })
                            } else {
                                return m(DisplayCell, { header, field, value, doc: vnode.state.contact })
                            }
                        }),
                        vnode.state.contact && Object.keys(vnode.state.contact.docChanges).length > 0 && m("button.button formCard__save", { onclick: e => confirmChanges() }, "שמור שינויים")
                    ),
                ) //END pageLayout
            ) //END return
        }
    }
}