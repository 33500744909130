import m from "mithril"
import { buildHeaders } from "../../../data/CRUD/utils"
import { dataStore } from "../../../data/store/dataStore"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { getAddOptions } from "../../../data/utils/utils"
import { REG_URL_LAST_PART } from "../../../utils/constants/regex"
import { CURRENCY, NUMBER } from "../../../utils/constants/types"
import { sortDocsBy } from "../../../utils/js"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { Tooltip } from "../../components/tooltip/Tooltip"
import { Card } from "../cardLayout/Card"
import { CheckCell } from "../checkCell/CheckCell"
import { FormEditDoc } from "../prompt/FormEditDoc"
import { Prompt } from "../prompt/Prompt"

export const InputCards = node => {

    const promptForm = doc => {
        const { headers } = node.state
        if (doc) {
            node.state.prompt = {
                class: "full-width",
                title: doc.isNew ?
                    "הוספה" :
                    "עריכה",
                form: () => m(FormEditDoc, { doc, headers, parent: node })
            }
        } else {
            const { colRef, modelID, addDataOptions = {}, filterDataOptions = {} } = node.attrs
            const Model = dataStore[modelID].model
            const newDoc = new Model(getAddOptions(colRef, filterDataOptions, addDataOptions))
            node.state.prompt = {
                class: "full-width",
                title: "הוספה",
                form: () => m(FormEditDoc, { doc: newDoc, headers, parent: node })
            }
        }
    }

    return {
        headers: {},
        snackbar: false,
        prompt: false,
        oninit: vnode => {
            const { doc, modelID, headers, colRef } = vnode.attrs
            console.assert(doc && modelID && colRef)
            const Model = dataStore[modelID].model
            node.state.headers = buildHeaders(Model.headers || {}, headers)

            const parentRef = colRef.replace(REG_URL_LAST_PART, "")
            const { unsubscribe, alreadyListen } = dataStore[vnode.attrs.modelID].listen(parentRef)
        },
        view: vnode => {
            const { headers } = node.state
            const { doc: instance, filterDataOptions = {}, filterSettings = {}, addDataOptions = {}, modelID, title } = node.attrs
            return m(Card, { class: "InputCards" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                m(".caption", title || ""),
                m(".InputCards__thumbs",
                    instance.getChildren(modelID, filterDataOptions).map(doc => {
                        return m(".InputCards__thumb",
                            Object.entries(headers).map(([header, field]) => {
                                // m(".InputCards__label", field.label),
                                const { label, type, options, style } = field
                                const displayValue = getDisplayValue(doc.docData[header], type, options)
                                if (displayValue.startsWith("--")) return
                                return m(".InputCards__input", {
                                    "data-tooltip": true,
                                    style: style ? style : "",
                                    onclick: e => {
                                        promptForm(doc)
                                    }
                                },
                                    m(Tooltip, { text: `${label}:\n${displayValue}`, options }),
                                    displayValue
                                )
                            })
                        )
                    }),
                    m(".InputCards__thumb--add", {
                        onclick: e => promptForm()
                    },
                        `+ הוסף ${title || ""}`
                    )
                ),
                //TOTALS ROW:
                m(".InputCards__totals",
                    Object.entries(headers).map(([header, settingsField]) => {
                        const { label, type, options: fieldOptions, totals } = settingsField
                        if (totals) {
                            console.assert(totals.val, { totalsObj: totals })
                            const totalValue = totals.val(instance.getChildren(modelID, filterDataOptions, filterSettings).sort(sortDocsBy(sortOptions.param)))
                            return m(".total",
                                m(".total__label", label),
                                m(".total__value", getDisplayValue(totalValue, totals.type || STRING, fieldOptions))
                            )
                        }
                        if (type === NUMBER || type === CURRENCY) {
                            let total = 0
                            if (settingsField.val) {
                                total = instance.getChildren(modelID, filterDataOptions, filterSettings).reduce((acc, doc) => {
                                    return acc += parseFloat(settingsField.val(doc).value)
                                }, 0)
                            } else {
                                total = instance.getTotalDocs(modelID, filterDataOptions, filterSettings, header)
                            }
                            return m(".total",
                                m(".total__label", label),
                                m(".total__value", getDisplayValue(total, type, fieldOptions))
                            )
                        }
                    })
                ),
            )
        }
    }
}