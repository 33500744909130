import m from "mithril"

import { getConditionalFormattiongClass, getDisplayValue } from "../../../data/utils/inputValidation"
import { O_FUNCTION } from "../../../utils/constants/objTypes"
import { DATE, EMAIL, STRING, SWITCH } from "../../../utils/constants/types"
import { copyToClipboard, objType, preventDefaults } from "../../../utils/js"
import { IconButton } from "../../components/icon/IconButton"
import { Tooltip } from "../../components/tooltip/Tooltip"

export const TableCell = node => {

    const getTooltipOptions = (value, type) => {
        switch (type) {
            case EMAIL: return node.state.options.email = value
            case DATE: return node.state.options.date = value
        }
    }

    const getOptionStyle = (options, value) => {
        const opt = options.find(({ id }) => id === value)
        let style = ""
        if (opt && opt.color) {
            if (opt.color.text) style += `color:${opt.color.text};`
            if (opt.color.bg) style += `background-color:${opt.color.bg};`
        }
        return style
    }

    return {
        options: {},
        label: "",
        displayValue: "",
        oninit: vnode => {
            const { doc, header, settingsField } = vnode.attrs
            const field = settingsField ? settingsField : doc.headers[header]
            vnode.state.field = field

            if (field.options && field.options.load) field.options.load(doc)

            vnode.state.label = field.label || "תיאור"
            const { value, classStyle = "", additionalInfo = { value: "", isError: false } } = field.val ?
                field.val(doc) :
                { value: doc.docData[header], classStyle: getConditionalFormattiongClass(doc, header) }
            const type = field.type ? field.type : field.type || STRING

            vnode.state.classStyle = classStyle
            vnode.state.additionalInfo = additionalInfo
            getTooltipOptions(value, type)
            if (type === SWITCH) {
                vnode.state.style = getOptionStyle(field.options, value)
            }
            vnode.state.value = value
            vnode.state.displayValue = "" + getDisplayValue(value, type, field.options, doc.docData)
        },
        onupdate: vnode => {
            const { doc, header } = vnode.attrs
            const { field } = vnode.state
            vnode.state.displayValue = "" + getDisplayValue(doc.docData[header], field.type, field.options, doc.docData)
        },
        view: vnode => {
            const { doc, header, class: cellStyle, settingsField = {}, parent } = vnode.attrs
            const { options, label, displayValue, classStyle, additionalInfo, style = "", value } = vnode.state
            return m(`.table__cell ${cellStyle}`, {
                class: classStyle,
                style,
                "data-field": `${doc.docData.ref}/${header}`,
                "data-info": additionalInfo.value == "" ? "false" : "true",
                "data-error": additionalInfo.isError ? "true" : "false",
                onclick: async e => {
                    if (settingsField.action) {
                        preventDefaults(e);
                        if (objType(settingsField.action) === O_FUNCTION) {
                            settingsField.action(parent, doc)
                        } else {
                            if (settingsField.action === "copy") {
                                await copyToClipboard(value);
                                parent.state.snackbar = { msg: `תוכן התא - "${value}" הועתק!` };
                                m.redraw()
                            }
                        }
                    }
                }
            },
                m(Tooltip, { text: `${label}:\n${displayValue}\r${additionalInfo.value}`, options }),
                displayValue || "",
                settingsField.action && settingsField.action === "copy" && m(IconButton, { class: "actionFlag", icon: "icon-copy" }),
                settingsField.action && objType(settingsField.action) === O_FUNCTION && m(IconButton, { class: "actionFlag", icon: "icon-new-tab" })
            )
        }
    }
}