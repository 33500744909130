import { auth } from "../../index"
import { DISABLED, HIDDEN } from "../../utils/constants/inputAttrs"
import { DATE, STRING, USER } from "../../utils/constants/types"

export const internalFields = {
    DOC_ID: "docID",
    // REF: "ref",
    LINK: "link"
}

export const DEFAULT_FIELDS = {
    IS_ACTIVE: "isActive",
    CREATED_AT: "createdAt",
    CREATED_BY: "createdBy",
    TIMESTAMP: "timestamp",
}
const _ = DEFAULT_FIELDS
export const defaultFields = {
    [_.IS_ACTIVE]: {
        label: "פעיל",
        type: STRING,
        defaultValue: true,
        props: [{ [DISABLED]: true }, { [HIDDEN]: doc => doc.isNew }]
    },
    [_.CREATED_AT]: {
        label: "תאריך יצירה",
        type: DATE,
        defaultValue: (docData) => new Date().toISOString(),
        props: [{ [DISABLED]: true }, { [HIDDEN]: doc => doc.isNew }]
    },
    [_.CREATED_BY]: {
        label: "נוצר עי",
        type: USER,
        defaultValue: (docData) => auth.currentUser.uid,
        props: [{ [DISABLED]: true }, { [HIDDEN]: doc => doc.isNew }]
    },
    [_.TIMESTAMP]: {
        label: "חותמת זמן",
        defaultValue: (docData) => +new Date(),
        props: [{ [DISABLED]: true }, { [HIDDEN]: doc => doc.isNew }]
    }
}