import m from "mithril";
import { dataStore } from "../../../data/store/dataStore"
import { CONFIRMS, CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, CONTRACT_COMMENTS, CONTRACT_SECTIONS, CONTRACT_SUBTRACTIONS, SECTION_MILESTONES } from "../../../data/dictionary/routeNames"
import { editValue, getDisplayValue } from "../../../data/utils/inputValidation";
import { CURRENCY, PERCENT } from "../../../utils/constants/types";
import { monthDiff, preventDefaults, buildMonthText, textToDate } from "../../../utils/js";
import { AccountDoc } from "../../../data/store/contractsActuals/accountClass";
import { setInputValidaty } from "../../commons/inputCell/utils";
import { ContractCommentDoc } from "../../../data/store/contractsActuals/contractCommentClass";
import { FormEditDoc } from "../../commons/prompt/FormEditDoc"
import { Table } from "../../commons/table/Table";
import { DATE_RANGE } from "../../../utils/constants/filtersTypes";
import { ACCOUNTS_TOTAL, CONTRACT_PAYMENTS, CONTRACT_SECTIONS_ACTUALS, CONTRACT_SECTIONS_PLAN } from "../../../utils/constants/contractsExportsOptions";
import { CheckCell } from "../../commons/checkCell/CheckCell";
import { exportReportAPI } from "../reports/utils";

//PLAN
export const setItemPrice = (section, milestones) => section.saveLocal({ itemPrice: getTotalMilestones(section, milestones) }, {}, true)

export const showTotalSectionPlan = (section) => {
    const totalDisplay = getDisplayValue(parseFloat(section.docData.totalSum), CURRENCY)
    if (section.docData.itemsCount > 1) {
        // const part = parseFloat(section.docData.totalSum) / parseFloat(section.docData.itemsCount)
        const part = parseInt(section.docData.itemPrice)
        const partDisplay = getDisplayValue(part, CURRENCY)
        return `${partDisplay} / ${totalDisplay}`
    } else {
        return totalDisplay
    }
}


export const showTotalMsPlan = (section, totalPlan) => {
    return `${getDisplayValue(totalPlan, CURRENCY)}${section.docData.itemsCount > 1 ? ` / ${getDisplayValue(totalPlan * section.docData.itemsCount, CURRENCY)}` : ""}`
}

export const isValidTotal = (section, milestones, _isPercent) => {
    const isPercent = _isPercent == undefined ? section.isPercent() : _isPercent;
    const totalMilestones = getTotalMilestones(section, milestones, isPercent)
    if (isPercent) {
        return parseFloat(totalMilestones) === 100
    }
    return section.docData.itemPrice == totalMilestones
}

export const getTotalMilestones = (section, milestones, _isPercent) => {
    const isPercent = _isPercent == undefined ? section.isPercent() : _isPercent;
    let result = 0
    milestones.forEach(ms => result += parseFloat(isPercent ? ms.docData.weight : ms.docData.price))
    return result
}

export const calcTotalUnitMilestonePlan = (section, milestone) => {
    const isPauschal = section.isPauschal()
    if (isPauschal) {
        return parseFloat(section.docData.totalSum) * (parseFloat(milestone.docData.weight) / 100) / section.docData.itemsCount
    } else {
        //ignore units
        if (milestone.isPercent()) {
            return parseFloat(section.docData.itemPrice) * (parseFloat(milestone.docData.weight) / 100)
        } else {
            return parseFloat(milestone.docData.price)
        }
    }
}

//prompts:

export const promptExportAccountsReport = (node, assertChecks) => {
    const themes = {
        COLOR: "color",
        BLACK: "black"
    }
    const themesLabels = {
        [themes.COLOR]: "צבעוני",
        [themes.BLACK]: "שחור לבן"
    }
    var activeTheme = themes.BLACK;

    const localCheck = {
        [ACCOUNTS_TOTAL]: true,
        [CONTRACT_PAYMENTS]: true,
        [CONTRACT_SECTIONS_ACTUALS]: false,
        [CONTRACT_SECTIONS_PLAN]: false,
    }
    if (assertChecks) {
        Object.entries(localCheck).forEach(([opt, val]) => {
            if (assertChecks.includes(opt)) localCheck[opt] = true
            else localCheck[opt] = false
        })
    }
    const getKeysIfValues = (obj, condition) => {
        return Object.entries(obj).filter(([k, v]) => v === condition).map(([k, v]) => k)
    }
    const toggleCheckExports = exportID => {
        if (localCheck[exportID] && Object.entries(localCheck).every(([k, v]) => k === exportID ? true : v === false)) return //all others are false
        localCheck[exportID] = !localCheck[exportID]
    }
    const { contract } = node.state
    node.state.prompt = {
        title: "ייצוא דוח הנהלת חשבונות",
        form: () => {
            return m(".", { style: `display: grid; align-items: center; gap: 2rem;` },
                m(".", {
                    style: "grid-column:1/-1",
                    class: `toggleCheck${activeTheme === themes.COLOR ? "--checked" : ""}`,
                    onclick: e => {
                        if (activeTheme === themes.COLOR) activeTheme = themes.BLACK
                        else if (activeTheme === themes.BLACK) activeTheme = themes.COLOR
                    }
                },
                    themesLabels[activeTheme]
                ),
                m(".caption", "בחר דוחות לייצוא"),
                m(CheckCell, {
                    label: "דוח חשבונות מצטבר",
                    checked: localCheck[ACCOUNTS_TOTAL],
                    setCheck: e => toggleCheckExports(ACCOUNTS_TOTAL)
                }),
                m(CheckCell, {
                    label: "דוח פירוט צ'קים",
                    checked: localCheck[CONTRACT_PAYMENTS],
                    setCheck: e => toggleCheckExports(CONTRACT_PAYMENTS)
                }),
                m(CheckCell, {
                    label: "דוח ביצוע מצטבר סעיפים",
                    checked: localCheck[CONTRACT_SECTIONS_ACTUALS],
                    setCheck: e => toggleCheckExports(CONTRACT_SECTIONS_ACTUALS)
                }),
                m(CheckCell, {
                    label: "דוח מבנה חוזה מתוכנן",
                    checked: localCheck[CONTRACT_SECTIONS_PLAN],
                    setCheck: e => toggleCheckExports(CONTRACT_SECTIONS_PLAN)
                }),
                m("button.button", {
                    onclick: async e => {
                        await exportReportAPI(node, contract.getExportData(getKeysIfValues(localCheck, true), activeTheme))
                    }
                }, "ייצא"),
            )
        }
    }
}

export const promptComments = node => {
    const { currentAccount } = node.state
    node.state.prompt = {
        class: "full-width",
        title: "הערות חוזה",
        form: () => {
            let confirmRef = "", accountRef = ""
            if (currentAccount) accountRef = currentAccount.docData.ref
            if (currentAccount && currentAccount.getCurrentStage().stage.id) confirmRef = `${currentAccount.docData.ref}/${CONFIRMS}/${currentAccount.getCurrentStage().stage.id}`
            return m(Table, {
                title: "הערות חוזה",
                modelID: CONTRACT_COMMENTS,
                colRef: `${node.state.contract.docData.ref}/${CONTRACT_COMMENTS}`,
                doc: node.state.contract,
                tableHeaders: ["title", "description", "isPinned"],
                actions: { onAdd: "prompt", onRow: "prompt" },
                sortOptions: { param: "timestamp" },
                searchOptions: ["title", "description"],
                filterDataOptions: { exclude: { sectionRef: doc => doc.docData.sectionRef !== "" } },
                addDataOptions: {
                    accountRef,
                    confirmRef
                },
                filters: [
                    // { id: "accountRef", param: currentAccount.docData.ref }
                ],
                filterMap: [
                    { label: "תאריך יצירה", header: "createdAt", type: DATE_RANGE },
                    { label: "תקופת חשבון", header: "accountRef", options: node.state.contract.getChildren(CONTRACT_ACCOUNTS).map(doc => { return { id: doc.docData.ref, text: doc.docData.month } }) }
                ]
            })
        }
    }
}

export const confirmSectionChanges = async (node, sectionDoc, milestones) => {
    //TODO: test if has actuals
    const detectActuals = await sectionDoc.hasActualsConflict();
    if (detectActuals) {
        node.state.snackbar = { msg: "נמצאו דוחות ביצוע על גבי סעיף זה => לא ניתן לערוך שדות שעלולים להשפיע על הביצוע", isError: true }
        sectionDoc.abortConflicts();
        m.redraw()
        return
    }

    if (!sectionDoc.isPercent()) {
        const totalMilestones = sectionDoc.getTotalDocs(SECTION_MILESTONES, {}, {}, "price")
        if (totalMilestones !== parseFloat(sectionDoc.docData.itemPrice)) {
            sectionDoc.edit({
                itemPrice: totalMilestones,
                totalSum: totalMilestones * sectionDoc.docData.itemsCount
            })
        }
    }

    node.state.prompt = {
        title: "שמירת שינויים",
        msg: "האם לשמור את השינויים שבוצעו בסעיף?",
        action: e => {
            node.state.snackbar = { msg: "שומר..." };
            if (milestones.length === 0) {
                sectionDoc.save(true);
                node.state.snackbar = { msg: "הסעיף נשמר בהצלחה , יש לערוך אבני דרך כדי לסיים את התהליך!" };
            } else {
                const isPercent = sectionDoc.isPercent()
                const isPauschal = sectionDoc.isPauschal()
                if (isValidTotal(sectionDoc, milestones, isPercent)) {
                    milestones.forEach(milestoneDoc => {
                        if (Object.keys(milestoneDoc.docChanges).length > 0)
                            milestoneDoc.save();
                    })
                    sectionDoc.save();
                    node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה!" };
                } else {
                    if (isPercent) {
                        node.state.snackbar = { msg: "לא ניתן לשמור אבני דרך , יש להגיע ל100% על מנת לשמור", isError: true };
                    } else {
                        node.state.snackbar = { msg: "לא ניתן לשמור אבני דרך - סכום אבני הדרך שונה מסכום הסעיף", isError: true };
                    }
                }
            }
            node.state.prompt = false
            m.redraw()
        },
        actionText: "שמור"
    }
    m.redraw()
}

//ACTUAL
export const showTotalSectionActual = (section) => {
    const totalActualsValue = section.calcTotalActuals()
    return `${getDisplayValue(totalActualsValue, CURRENCY)} / ${getDisplayValue(parseFloat(section.docData.totalSum), CURRENCY)}`
}

export const getLastTotalUnitMilestone = (milestone, { accountRef, unit } = {}) => {
    let result = 0
    milestone.getLastActuals({ unit, accountRef }).forEach(act => {
        result += parseFloat(act.docData.value)
    })
    return result
}

export const calcTotalUnitMilestones = (section, milestones, unit, accountRef, _isPercent, _isPauschal) => {
    const isPercent = _isPercent == undefined ? section.isPercent() : _isPercent;
    const isPauschal = _isPauschal == undefined ? section.isPauschal() : _isPauschal;
    let result = 0
    let sum = 0
    milestones.forEach(ms => {
        const totalActuals = ms.getTotalActuals({ unit, accountRef })
        if (totalActuals > 0) {
            const price = isPercent ? (parseFloat(ms.docData.weight) / 100) * parseFloat(section.docData.itemPrice) : parseFloat(ms.docData.price);
            if (isPauschal) {
                sum += (parseFloat(totalActuals) / 100) * price;
            } else {
                result += totalActuals
                sum += parseFloat(totalActuals) * price;
            }
        }
    })
    if (isPauschal) {
        result = parseFloat(sum / section.docData.itemPrice) * 100
    }
    return [result, sum]
}

export const showTotalMsActual = (section, ms) => {
    const isPercent = section.isPercent();
    const isPauschal = section.isPauschal();
    let totalValue = 0;
    const totalActuals = ms.getTotalActuals()
    if (totalActuals > 0) {
        const price = isPercent ? (parseFloat(ms.docData.weight) / 100) * parseFloat(section.docData.itemPrice) : parseFloat(ms.docData.price);
        if (isPauschal) {
            totalValue = (parseFloat(totalActuals) / 100) * price;
        } else {
            totalValue = parseFloat(totalActuals) * price;
        }
    }
    return getDisplayValue(totalValue, CURRENCY)
}





//LISTENERES: 
export const toggleMilestoneLoader = (alreadyListen, sectionRef, vnode) => {
    if (alreadyListen) {
        vnode.state.loadMilestones[sectionRef] = false
    } else {
        setTimeout(() => {
            vnode.state.loadMilestones[sectionRef] = false
            m.redraw()
        }, 1000);
    }
}

export const listenToMilestones = (vnode, alreadyListenToSections) => {
    function load(vnode) {
        const sections = dataStore[CONTRACT_SECTIONS].data.filter(section => section.docData.colRef.startsWith(vnode.state.contract.docData.ref))
        sections.forEach(section => {
            const sectionRef = section.docData.ref
            vnode.state.loadMilestones[sectionRef] = true
            const { alreadyListen } = dataStore[SECTION_MILESTONES].listen(sectionRef)
            toggleMilestoneLoader(alreadyListen, sectionRef, vnode)
        })
    }
    if (alreadyListenToSections) {
        vnode.state.loadSections = false
        load(vnode)
    } else {
        setTimeout(() => {
            vnode.state.loadSections = false;
            load(vnode)
            m.redraw()
        }, 3000);
    }
}

export const toggleCommentView = (vnode) => vnode.state.showCurrentComments = !vnode.state.showCurrentComments
//TOGGLE COLLASPE
export const toggleCollaspeSection = (vnode, sectionID) => {
    const ind = vnode.state.collaspeSection.indexOf(sectionID)
    if (ind > -1) vnode.state.collaspeSection.splice(ind, 1)
    else vnode.state.collaspeSection.push(sectionID)
}
export const isCollaspeSection = (vnode, sectionID) => vnode.state.collaspeSection.includes(sectionID)

export const isActiveGroup = (vnode, group) => vnode.state.activeGroup && group.docData.ref === vnode.state.activeGroup.docData.ref
export const toggleActiveGroup = (vnode, group, forceActive = false) => {
    if (forceActive) {
        vnode.state.activeGroup = group
    } else {
        // toggle
        if (isActiveGroup(vnode, group)) vnode.state.activeGroup = false
        else vnode.state.activeGroup = group
    }
}
export const isActiveWorkspace = (vnode, wsID) => vnode.state.activeWorkspace && wsID === vnode.state.activeWorkspace
export const toggleActiveWorkspace = (vnode, wsID, forceActive = false) => {
    if (forceActive) {
        vnode.state.activeWorkspace = wsID
    } else {
        //toggle
        if (isActiveWorkspace(vnode, wsID)) vnode.state.activeWorkspace = false
        else vnode.state.activeWorkspace = wsID
    }
}

export const sectionHasActualChanges = (section, accountRef) => {
    const actuals = section.getActuals({ accountRef });
    return actuals.length > 0 && actuals.some(act => Object.keys(act.docChanges).length > 0)
}

export const contractHasActualChanges = (contract, accountRef) => {
    const sections = contract.getChildren(CONTRACT_SECTIONS)
    return sections.length > 0 && sections.some(section => sectionHasActualChanges(section, accountRef))
}

//HANDLERS
export const handleAddComment = (vnode) => {
    let accountRef = "", confirmRef = "", stage = ""
    if (vnode.state.currentAccount) {
        accountRef = vnode.state.currentAccount.docData.ref
        stage = vnode.state.currentAccount.docData.stage
        if (stage !== "finish") {
            const currentStage = vnode.state.currentAccount.getCurrentStage()
            confirmRef = `${vnode.state.currentAccount.docData.ref}/${CONFIRMS}/${currentStage.stage.id}`
        }
    }
    const colRef = `${vnode.state.contract.docData.ref}/${CONTRACT_COMMENTS}`
    const doc = new ContractCommentDoc({ colRef });
    doc.saveLocal({ accountRef, confirmRef })
    const headers = ["title", "description", "isPinned"]
    vnode.state.prompt = {
        title: "הערה חדשה",
        class: "full-width",
        form: () => {
            return m(FormEditDoc, { doc, headers, parent: vnode })
        }
    }
}
//HANDLERS
export const togglePinComment = (e, vnode, doc) => {
    preventDefaults(e)
    const { valid, errorMsgs } = editValue(doc.docData.isPinned, !doc.docData.isPinned, "isPinned", doc)
    if (!valid) {
        vnode.state.snackbar = { msg: errorMsgs.join("\n"), isError: true }
    } else {
        doc.save()
    }
}
export const handleEditComment = (vnode, doc) => {
    const headers = ["title", "description", "isPinned"]
    vnode.state.prompt = {
        title: "עריכת הערה",
        class: "full-width",
        form: () => {
            return m(FormEditDoc, { doc, headers, parent: vnode })
        }
    }
}

export const handleConfirmStage = (vnode) => {
    const { stage, ref: accountRef, confirmFlow, month, totalPay, subTotal } = vnode.state.currentAccount.docData
    // pending changes
    if (contractHasActualChanges(vnode.state.contract, accountRef)) {
        vnode.state.snackbar = { msg: `לא ניתן לאשר חשבון , ישנם שינויים שלא נשמרו`, isError: true }
        return
    }
    if (stage === "start") {
        return handleStartPeriod(vnode)
    } else if (stage === "finish") {
        return handleAddAccount(vnode)
    } else {
        const currentStage = confirmFlow.find(({ id }) => id === stage)
        const stageType = currentStage.type
        let displayTotal
        if (stageType === "operation") {
            const totalActuals = vnode.state.contract.calcTotalActuals({ accountRef })
            const totalAddition = vnode.state.contract.calcTotalAdditionsOrSubtractions(accountRef, CONTRACT_ADDITIONS)
            const totalSubtraction = vnode.state.contract.calcTotalAdditionsOrSubtractions(accountRef, CONTRACT_SUBTRACTIONS)
            const delayPercentage = parseFloat(vnode.state.contract.docData.delayPercentage / 100)
            let totalValue = parseFloat(totalActuals + totalAddition - totalSubtraction)
            totalValue -= (totalValue * delayPercentage)
            //#CONFIRM_ACCOUNT_ZERO
            // if (totalValue <= 0) {
            //     vnode.state.snackbar = { msg: `לא ניתן לאשר תקופה עם חשבון בסכום ${getDisplayValue(totalActuals, CURRENCY)}`, isError: true }
            //     return
            // }
            displayTotal = getDisplayValue(totalValue, CURRENCY)
        } else if (stageType === "accounting") {
            const totalCurrentPayments = vnode.state.contract.getTotalPayments({ filterAccounts: { include: { month } } })
            displayTotal = `
            סכום חשבון: ${getDisplayValue(totalPay, CURRENCY)}
            סכום תשלומים: ${getDisplayValue(totalCurrentPayments, CURRENCY)}
            `
        }
        vnode.state.prompt = {
            title: "אישור חשבון",
            msg: `האם לאשר את החשבון הנוכחי על סך ${displayTotal || "--"} ?`,
            actionText: "אשר",
            note: "",
            actions: [
                {
                    text: "אשר",
                    action: async evt => {
                        evt.target.disabled = true
                        vnode.state.prompt = false
                        try {
                            await vnode.state.currentAccount.confirm(true)
                            vnode.state.snackbar = { msg: `אושר בהצלחה` }
                        } catch (err) {
                            vnode.state.snackbar = { msg: `אירעה שגיאה:\n${err}`, isError: true }
                        } finally {
                            evt.target.disabled = false
                        }
                    }
                }
            ]
        }
    }
}

export const handleStartPeriod = (vnode, _currentAccount) => {
    const currentAccount = vnode.state.currentAccount || _currentAccount
    vnode.state.prompt = {
        title: "אתחול תקופה",
        msg: `האם לאתחל את התקופה?`,
        note: "",
        actions: [
            {
                text: "אתחל",
                action: evt => {
                    try {
                        new Promise(resolve => resolve(currentAccount.confirm(true)))
                            .then(() => {
                                vnode.state.snackbar = { msg: `תקופה אותחלה` }
                                m.redraw()
                            })
                    } catch (err) {
                        vnode.state.snackbar = { msg: `אירעה שגיאה:${err}`, isError: true }
                    } finally {
                        m.redraw()
                        vnode.state.prompt = false
                    }
                }
            }
        ]
    }
}

export const handleAddAccount = (vnode) => {
    const lastAccount = vnode.state.contract.getLastAccount()
    if (lastAccount) {
        const { month, period, stage } = lastAccount.docData
        if (stage !== "finish") {
            vnode.state.snackbar = { msg: "לא ניתן להוסיף תקופה באמצע תהליך תקופתי", isError: true }
            return
        }
        const isAfterMonth = monthDiff(new Date(), textToDate(month)) >= 0
        if (isAfterMonth) {
            vnode.state.snackbar = { msg: "לא ניתן להוסיף תקופות אחרי החודש הנוכחי", isError: true }
            return
        }
        vnode.state.prompt = {
            title: "הוספת חשבון תקופתי",
            msg: "יצירת תקופת תשלום חדשה",
            selectMonth: month,
            type: "newAccount",
            note: "",
            actions: [
                {
                    text: "צור",
                    action: evt => {
                        const colRef = `${vnode.state.contract.docData.ref}/accounts`
                        const newAccount = new AccountDoc({
                            colRef,
                            period: period + 1,
                            month: vnode.state.selectedMonth
                        })
                        try {
                            newAccount.remove()
                            Promise.resolve(newAccount.insert(colRef, true))
                                .then(() => {
                                    setTimeout(() => {
                                        getCurrentAccount(vnode, vnode.state.selectedMonth)
                                        m.redraw()
                                    }, 1000);
                                })
                                .then(() => {
                                    m.redraw()
                                })
                        } catch (err) {
                            vnode.state.snackbar = { msg: `אירעה שגיאה:${err}`, isError: true }
                        }
                        vnode.state.prompt = false
                    }
                }
            ]
        }
    } else {
        createFirstAccount(vnode)
    }
}

export function createFirstAccount(vnode) {
    const isAfterMonth = monthDiff(new Date(), new Date(vnode.state.contract.docData.sWorkDate)) >= 0
    if (isAfterMonth) {
        vnode.state.snackbar = { msg: "לא ניתן להוסיף תקופות אחרי החודש הנוכחי", isError: true }
        return
    }
    vnode.state.prompt = {
        title: "יצירת תקופת חשבון ראשונה",
        msg: "יש לבחור תקופה עבורה החשבון הראשון",
        selectMonth: buildMonthText(new Date(vnode.state.contract.docData.sWorkDate)),
        type: "newAccount",
        note: "",
        actions: [
            {
                text: "צור",
                action: evt => {
                    const colRef = `${vnode.state.contract.docData.ref}/accounts`
                    const newAccount = new AccountDoc({
                        colRef,
                        period: 1,
                        month: vnode.state.selectedMonth
                    })
                    try {
                        newAccount.remove()
                        Promise.resolve(newAccount.insert(colRef, true))
                            .then(() => {
                                setTimeout(() => {
                                    getCurrentAccount(vnode, vnode.state.selectedMonth);
                                    m.redraw()
                                }, 1000);
                            }).then(() => {
                                m.redraw()
                            })
                    } catch (err) {
                        vnode.state.snackbar = { msg: `אירעה שגיאה:${err}`, isError: true }
                    }
                    vnode.state.prompt = false
                }
            }
        ]
    }
}

export const getCurrentAccount = (vnode, month) => {
    // setTimeout(() => {
    const contractPath = vnode.state.contract.docData.ref
    const filterAccounts = vnode.state.contract.getChildren(CONTRACT_ACCOUNTS, { dataOnly: true })
    if (filterAccounts.length > 0) {
        const lastAccount = filterAccounts.sort((a, b) => b.docData.period - a.docData.period)[0];
        //case select month
        if (month) {
            const currentAccount = filterAccounts.filter(doc => doc.docData.month === month)[0];
            if (currentAccount) {
                vnode.state.currentAccount = currentAccount;
            } else {
                throw "month not found"
            }
        } else {
            //case default month (last one)
            vnode.state.currentAccount = lastAccount
        }
        // const accountMonth = month ? month : vnode.state.currentAccount.docData.month
        // const { alreadyListen: isListenToBillings } = dataStore[CONTRACT_BILLINGS].listen(contractPath)
        // if (isListenToBillings) getCurrentBilling(vnode, accountMonth)
        // else setTimeout(() => getCurrentBilling(vnode, accountMonth), 3000);
        vnode.state.lastMonth = lastAccount.docData.month;
        m.redraw()
    } else {
        //case empty
        vnode.state.currentAccount = false
        // handleAddAccount(vnode)
    }
    // }, 1000);
}

export function changeAccount(vnode) {
    vnode.state.prompt = {
        title: "החלפת תקופה",
        msg: "החלפה לתקופות קודמות",
        actionText: "אוקיי",
        selectMonth: vnode.state.currentAccount.docData.month,
        label: "עבור לתקופה",
        type: "goToAccount",
        actions: [
            {
                text: "בחר",
                action: evt => {
                    const findAccountByMonth = vnode.state.contract.getChildren(CONTRACT_ACCOUNTS, { dataOnly: true, include: { month: vnode.state.selectedMonth } })[0]
                    if (findAccountByMonth) {
                        vnode.state.currentAccount = findAccountByMonth
                        vnode.state.snackbar = { msg: "מחליף תקופת חשבון..." }
                    } else {
                        vnode.state.snackbar = { msg: "לא נמצא חשבון בחודש זה", isError: true }
                    }
                    vnode.state.prompt = false
                }
            }
        ]
    }
}

export const confirmActualChanges = (vnode, sectionDoc, accountDoc, nextAction) => {

    try {
        const { confirmFlow, stage, ref: accountRef } = accountDoc.docData
        const currentStage = confirmFlow.find(({ id }) => id === stage)
        if (stage === "finish" || stage === "start") {
            return vnode.state.snackbar = { msg: "לא ניתן לעדכן חשבון לפני אתחול תקופה", isError: true }
        }
        const stageType = currentStage.type
        if (stageType === "accounting") {
            return vnode.state.snackbar = { msg: "לא ניתן להוסיף שדות לאחר מעבר לשלב הנהח", isError: true }
        }

        const isPauschal = sectionDoc.docData.calculationType === 'percent'
        const actuals = sectionDoc.getActuals({ accountRef })
        const total = sectionDoc.calcTotalActuals({ forceCalc: true })
        vnode.state.prompt = {
            title: "שמירת שינויים",
            msg: `האם לעדכן את סכום הביצוע ל${getDisplayValue(total, CURRENCY)}?`,
            actions: [
                {
                    action: e => {
                        //TODO: check if valid total (more then 0% + less then 100% + less then section totalSum)
                        const isValidTotal = true
                        if (isValidTotal) {
                            vnode.state.snackbar = { msg: "שומר..." };
                            actuals.forEach(actualDoc => {
                                if (Object.keys(actualDoc.docChanges).length > 0) actualDoc.save();
                            })
                            vnode.state.snackbar = { msg: "הנתונים נשמרו בהצלחה!" };
                        } else {
                            if (isPauschal) {
                                vnode.state.snackbar = { msg: "האחוז לא מסתדר לי", isError: true };
                            } else {
                                vnode.state.snackbar = { msg: "הסכום לא מסתדר לי", isError: true };
                            }
                        }
                        vnode.state.prompt = false
                        m.redraw()
                        if (nextAction) {
                            nextAction()
                        }
                    },
                    text: "שמור"
                },
                {
                    action: e => {
                        actuals.forEach(actualDoc => {
                            if (actualDoc.hasChanges()) actualDoc.abortChanges();
                        })
                        vnode.state.prompt = false
                        if (nextAction) {
                            nextAction()
                        }
                    },
                    color: "gray",
                    text: "ביטול שינויים"
                }
            ]
        }
    } catch (err) {
        console.error(err)
    }
}

export const defaultdDayDisplay = (currentAccount, day) => {
    if (!currentAccount ||
        !currentAccount.docData.month ||
        currentAccount.docData.month === "") {
        return "-- --"
    }
    const resultDate = textToDate(currentAccount.docData.month)
    const months = ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"]
    const monthName = months[(resultDate.getMonth() + 1) % 12] //approval should be one month after period
    return "" + day + " ל" + monthName
}