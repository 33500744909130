import { APARTMENT_MODELS, USERS, VENDORS, COMPANIES, PROJECTS, PROJECT, CONTRACTS, ACTUALS, CONFIRMS, WORKSPACES, GROUPS, CONTRACT_ACCOUNTS, CONTRACT_COMMENTS, CONTRACT_SECTIONS, SECTION_MILESTONES, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, CONTRACT_BILLINGS, PAYMENTS, VENDOR_CONTACTS, USERS_PERMISSION, BUDGET_CHAPTERS, NOTIFICATION, FIELDS_SWITCH_OPTIONS, APARTMENTS, BUILDINGS, FLOORS, VIEW_USERS } from "../dictionary/routeNames";

export const DEFAULT_ADD_TEXT = "צור"
export const DEFAULT_COPY_TEXT = "צור"
export const DEFAULT_DELETE_TEXT = "מחק"
export const DEFAULT_UNDO_TEXT = "בטל"
export const DEFAULT_CHANGE_TEXT = "שמור"

export const DEFAULT_ADD_TITLE = "הוספת רשומה"
export const DEFAULT_ADD_CONTENT_MSG = "האם אתה בטוח כי ברצונך להוסיף רשומה?"

export const DEFAULT_COPY_TITLE = "הוספת רשומה - עותק"
export const DEFAULT_COPY_CONTENT_MSG = "האם אתה בטוח כי ברצונך להוסיף רשומה - עותק של ${title}?"

export const DEFAULT_DELETE_TITLE = "מחיקת רשומה"
export const DEFAULT_DELETE_CONTENT_MSG = "האם למחוק את הרשומה ${title}?"

export const DEFAULT_ADD_MSG = "הרשומה החדשה נוצרה בהצלחה , יש לערוך את פרטי הרשומה ולאחר מכן לשמור"
export const DEFAULT_DELETE_MSG = "הרשומה נמחקה בהצלחה..."
export const DEFAULT_CHANGE_MSG = "הנתונים נשמרו בהצלחה..."

export const DEFAULT_USER_NOT_ALLOW_MSG = "אינך מורשה לבצע פעולה זו"
export const DEFAULT_ACTION_NOT_AVILABLE = "פעולה זו לא אפשרית כרגע..."

export const getDictionaryUI = (modelID, action) => {
    if (settingsStore.dictionaryUI[modelID] && settingsStore.dictionaryUI[modelID][action]) {
        return settingsStore.dictionaryUI[modelID][action]
    }
    return settingsStore.dictionaryUI.default[action]
}

export const settingsStore = {
    listeners: [],
    stores: {
        app: {
            text: "ראשי"
        },
        admin: {
            text: "מנהל מערכת"
        },
        utils: {
            text: "שימושי"
        },
        settings: {
            text: "הגדרות"
        },
    },
    pathBreadCrumbs: [],
    defaultListeners: [
        USERS,
        PROJECTS,
        NOTIFICATION,
        VIEW_USERS,
        // USERS_PERMISSION,
        FIELDS_SWITCH_OPTIONS,
        BUDGET_CHAPTERS,
        VENDORS,
        COMPANIES
    ],
    dbTree: {
        [PROJECTS]: {
            [BUILDINGS]: {
                [APARTMENT_MODELS]: {},
                [FLOORS]: {},
            },
            [APARTMENTS]: {},
            [CONTRACTS]: {
                [WORKSPACES]: {
                    [GROUPS]: {}
                },
                [CONTRACT_ACCOUNTS]: { //THINK: maby use this as a billing doc?
                    [CONFIRMS]: {},
                    [ACTUALS]: {},
                    [PAYMENTS]: {}//TESTME:
                    //payments ?
                },
                [CONTRACT_COMMENTS]: {},
                [CONTRACT_SECTIONS]: {
                    [SECTION_MILESTONES]: {}
                },
                [CONTRACT_ADDITIONS]: {},
                [CONTRACT_SUBTRACTIONS]: {},
                // [CONTRACT_BILLINGS]: {
                //     [PAYMENTS]: {}
                // }
            }
        },
        [USERS]: {
            [USERS_PERMISSION]: {}
        },
        [VENDORS]: {
            [VENDOR_CONTACTS]: {}
        },
        [COMPANIES]: {}
    },
    dictionaryUI: {
        default: {
            add: {
                title: DEFAULT_ADD_TITLE,
                msg: DEFAULT_ADD_CONTENT_MSG,
                actions: {
                    do: { text: DEFAULT_ADD_TEXT, msg: "רשומה חדשה נוצרה" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            copy: {
                title: DEFAULT_COPY_TITLE,
                msg: DEFAULT_ADD_CONTENT_MSG,
                actions: {
                    do: { text: DEFAULT_COPY_TEXT, msg: "רשומה חדשה נוצרה" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            delete: {
                title: DEFAULT_DELETE_TITLE,
                msg: DEFAULT_DELETE_CONTENT_MSG,
                actions: {
                    do: { text: DEFAULT_DELETE_TEXT, msg: "הרשומה נמחקה" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            change: {
                title: "שמירת שינויים",
                msg: "האם לשמור את השינויים שבוצעו על הרשומה",
                actions: {
                    do: { text: DEFAULT_CHANGE_TEXT, msg: DEFAULT_CHANGE_MSG },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            }
        },
        [PROJECTS]: {
            add: {
                title: "הוספת פרוייקט",
                msg: "האם ליצור פרוייקט חדש ?",
                actions: {
                    do: { text: DEFAULT_ADD_TEXT, msg: "פרוייקט חדש נוצר! יש לערוך את הפרוייקט כדי לשמור אותו" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            copy: {
                title: "הוספת פרוייקט  - עותק",
                msg: "האם ליצור פרוייקט חדש עותק של ${title}?",
                actions: {
                    do: { text: DEFAULT_COPY_TEXT, msg: "פרוייקט חדש נוצר! יש לערוך את הפרוייקט כדי לשמור אותו" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            delete: {
                title: "מחיקת פרוייקט",
                msg: "האם למחוק את הפרוייקט ${title}?",
                actions: {
                    do: { text: DEFAULT_DELETE_TEXT, msg: "הפרוייקט נמחק" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            }
        },
        [PROJECT]: {
            change: {
                title: "שמירת שינויים",
                msg: "האם לשמור את השינויים שבוצעו בפרוייקט",
                actions: {
                    do: { text: DEFAULT_CHANGE_TEXT, msg: DEFAULT_CHANGE_MSG },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            }
        },
        [USERS]: {
            change: {
                title: "שמירת שינויים",
                msg: "האם לשמור את השינויים שבוצעו בפרטי המשתמש",
                actions: {
                    do: { text: DEFAULT_CHANGE_TEXT, msg: DEFAULT_CHANGE_MSG },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            }
        },
        [CONTRACTS]: {
            add: {
                title: "הוספת חוזה",
                msg: "האם ליצור חוזה חדש ?",
                actions: {
                    do: { text: DEFAULT_ADD_TEXT, msg: "חוזה חדש נוצר! יש לערוך את החוזה כדי לשמור אותו" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            copy: {
                title: "הוספת חוזה  - עותק",
                msg: "האם ליצור חוזה חדש עותק של ${title}?",
                actions: {
                    do: { text: DEFAULT_COPY_TEXT, msg: "חוזה חדש נוצר! יש לערוך את החוזה כדי לשמור אותו" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            },
            delete: {
                title: "מחיקת חוזה",
                msg: "האם למחוק את החוזה ${title}?",
                actions: {
                    do: { text: DEFAULT_DELETE_TEXT, msg: "החוזה נמחק" },
                    undo: { text: DEFAULT_UNDO_TEXT }
                }
            }
        },
    }
}













//PERMISSION TREE
// rules_version = '2';
// service cloud.firestore {
//   match /databases/{database}/documents {
//   	function allowAll(){
//     	return request.auth != null;
//     }
//     function isMe(){
//     	return request.auth.uid == "BRjKVmVajjg62VO3K3aJGxmmccq1"
//     }
//     function isSelf(uid){
//     	return request.auth.uid == uid
//     }

//   	match /projects/{projectID=**}{
//     	allow read ,write : if allowAll();

//     }
//     match /users/{userID=**} {
//         allow read : if allowAll();
//         allow write : if isSelf(userID);
//         match /usersPermission/{permissionID} {
//         	allow read , write : if isMe();
//         }
//     }
//     match /vendors/{document=**} {
//       allow read ,write : if allowAll();
//   	}
//     match /companies/{companyID=**} {
//       allow read : if allowAll();
//       allow write : if isMe();
//   	}
//   }
// }