import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';
import { Card } from '../../commons/cardLayout/Card';

//DATA
import { PROJECTS, CONTRACTS, PROJECT, FILES, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, CONTRACT_COMMENTS, CONTRACT_ACCOUNTS, ODD_JOBS, USERS } from '../../../data/dictionary/routeNames';
import { ProjectDoc } from '../../../data/store/projects/projectClass';
import { dataStore } from '../../../data/store/dataStore';

// FUNCTIONS
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { InputCell } from '../../commons/inputCell/InputCell';
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots';
import { isSystemMode } from '../../../data/utils/viewUtils';
import { DEFAULT_ACTION_NOT_AVILABLE, DEFAULT_USER_NOT_ALLOW_MSG, settingsStore } from '../../../data/store/settingsStore';
import { isSuperAdmin, isUserAllow } from '../../../data/utils/permission';
import { DisplayCell } from '../../commons/inputCell/DisplayCell';
import { UPDATE } from '../../../data/store/permissionStore';
import { TableFiles } from '../../commons/fileUpload/TableFiles';
import { Table } from '../../commons/table/Table';
import { DocModel } from '../../../data/store/docModel';
import { Nav } from '../../commons/nav/Nav';
import { copyAction, logRouteChangeUser } from "../../../data/utils/utils"
import { addMonths, copyToClipboard, diffDays, formatDateDisplay, objType, preventDefaults, sortDocsBy, textToDate } from '../../../utils/js';
import { CURRENCY, DATE, LIST, MULTICHOICE_LIST, NUMBER, PERCENT, REF, STRING, SWITCH, USER } from '../../../utils/constants/types';
import { getDisplayValue } from '../../../data/utils/inputValidation';
import { confirmAccountFlow } from '../../../data/store/contractsActuals/accountClass';
import { ContractDoc } from '../../../data/store/contracts/contractClass';
import { PROJECT_BUDGET } from '../../../data/dictionary/viewOptions';
import { NavClass } from '../../../data/store/NavClass';
import { PercentClock } from '../../components/percentClock/PercentClock';
import { IconButton } from '../../components/icon/IconButton';
import { auth } from '../../../index';
import { exportReportAPI } from '../reports/utils';
import { O_OBJECT } from '../../../utils/constants/objTypes';
import { OddJobFile } from '../../../data/store/commons/oddJobFileClass';
import { FormEditDoc } from '../../commons/prompt/FormEditDoc';
import { FilesUpload } from '../../commons/fileUpload/FilesUpload';
import { FilePrompt } from '../../commons/fileUpload/FilePrompt';
import { oddJobsStatus } from '../../../utils/constants/statusTypes';
import { USER_ROLES } from '../../../utils/constants/userRoles';
import { EXPORTS_API_SHEET } from '../../../firebase/api';

export const Project = node => {

    const exportProjectAccountsReport = () => {
        const _makeTotalFuncRow = headersFields => {
            return headersFields.map(field => {
                if (field.totals.value) return field.totals.value
                else if (objType(field.totals) === O_OBJECT) return Object.assign({}, field.totals)
                else return "---"
            })
        }
        const headers = {
            title: { label: "חוזה", type: STRING, totals: { func: "COUNTA", textSymbol: "חוזים" } },
            _contractor: { label: "קבלן", type: REF, totals: { func: "COUNTUNIQUE" } },
            _s1: { label: "", type: "space", totals: { value: "" } },
            _subTotal: { label: "חשבון מצטבר", totals: { func: "SUM" } },
            _subTotalHistory: { label: "מצטבר תק' קודמות", totals: { func: "SUM" } },
            _currentSubtractions: { label: "תק' נוכחית קיזוזים", totals: { func: "SUM" } },
            _currentSubTotal: { label: "חשבון נוכחי", totals: { func: "COUNTA" } },
            _currentToPay: { label: "תשלום תק' נוכחית", totals: { func: "SUM" } },
            _s2: { label: "", totals: { value: "" } },
            totalSum: { label: "סכום הסכם", totals: { func: "SUM" } },
            _totalAddSub: { label: "תוספות וקיזוזים", totals: { func: "SUM" } },
            _totalToPay: { label: "יתרה לתשלום", totals: { func: "SUM" } },
            _percentPay: { label: "% ששולם", totals: { func: "AVERAGE", textSymbol: "%" } },
        }
        let fileName = `סטטוס חשבונות - פרוייקט ${node.state.project.docData.title}`
        const tableLayout = "327050836"
        // const reportVars = {}
        // reportVars.user = UserDoc.getCurrentUser().docData.displayName
        const items = []
        node.state.project.getChildren(CONTRACTS)
            .sort(sortDocsBy("title", { type: STRING }))
            .forEach(contract => {
                const { title, contractorRef, totalSections, totalAdditions, totalSubtractions, currentAccount, contractSum, ref: contractRef } = contract.docData;
                const total = { sections: 0, additions: 0, subtractions: 0, total: 0 }
                const history = { sections: 0, additions: 0, subtractions: 0, total: 0 }
                const current = { sections: 0, additions: 0, subtractions: 0, total: 0, month: "--" }
                total.sections = parseFloat(totalSections)
                total.additions = parseFloat(totalAdditions)
                total.subtractions = parseFloat(totalSubtractions)
                total.total = total.sections + total.additions - total.subtractions
                if (currentAccount) {
                    const { totalSections: currentSections, totalAdditions: currentAdditions, totalSubtractions: currentSubtractions, month } = currentAccount;
                    current.sections = parseFloat(currentSections)
                    current.additions = parseFloat(currentAdditions)
                    current.subtractions = parseFloat(currentSubtractions)
                    current.total = current.sections + current.additions - current.subtractions
                    current.month = month
                    history.sections = parseFloat(totalSections) - parseFloat(currentSections)
                    history.additions = parseFloat(totalAdditions) - parseFloat(currentAdditions)
                    history.subtractions = parseFloat(totalSubtractions) - parseFloat(currentSubtractions)
                    history.total = total.total - current.total
                }
                const totalToPay = parseFloat(contractSum) - total.total
                const percentDone = (total.total / parseFloat(contractSum))
                items.push([
                    title,
                    getDisplayValue(contractorRef, REF),
                    "",
                    total.total,
                    history.sections + history.additions - history.subtractions,
                    current.subtractions,
                    `${current.month || ""} \t`,
                    current.total,
                    "",
                    contractSum,
                    parseFloat(totalAdditions - totalSubtractions),
                    totalToPay,
                    percentDone,
                ])
            })
        return {
            // totalLayout,
            tableLayout,
            templeteID: EXPORTS_API_SHEET,
            title: fileName,
            data: {
                // reportVars,
                tables: {
                    [node.state.project.docData.ref]: {
                        title: node.state.project.docData.title,
                        headers: Object.values(headers).map(h => h.label),
                        tableVars: {
                            project: node.state.project.docData.title,
                        },
                        totalsFunc: _makeTotalFuncRow(Object.values(headers)),
                        items
                    }
                }
            }
        }
    }


    const findProject = () => {
        return DocModel.getChild(PROJECTS, `projects/${node.attrs.projectID}`)
    }
    const getAccountConfirmStatus = (contract, confirmStage) => {
        const { currentAccount = {} } = contract.docData
        const { confirms = {} } = currentAccount
        if (currentAccount && confirms && confirms[confirmStage]) {
            const { confirmBy, confirmAt, confirm: isConfirm, due, id } = confirms[confirmStage]
            let txt = "", classStyle = "", additionalInfo = { value: "" }
            const dueDiff = diffDays(addMonths(1, textToDate(currentAccount.month, due)), new Date())
            const isDelay = dueDiff > 0
            let lastStage = "start", lastConfirm = confirms.start
            while (lastConfirm  && lastConfirm.next && lastConfirm.confirm !== false) {
                lastStage = lastConfirm.id
                lastConfirm = confirms[lastConfirm.next]
            }
            const isCurrentStage = id === lastStage
            if (isConfirm == true) {
                // const userConfirmText = UserDoc.getUserByID(confirmBy, "displayName")
                const confirmAtText = formatDateDisplay(new Date(confirmAt), { outputFormat: "dd/mm/yy" })
                // txt += `${confirmAtText} [${userConfirmText}]`
                txt += `${confirmAtText}`
                classStyle += " active"
            } else {
                if (isDelay && isCurrentStage) {
                    additionalInfo.value += ` [באיחור של ${parseInt(dueDiff)} ימים]`
                    additionalInfo.isError = true
                }
                txt += getDisplayValue(addMonths(1, textToDate(currentAccount.month, due)), DATE, { outputFormat: "dd=>mm" })
                classStyle += " non-active"
            }
            return { value: txt, classStyle, additionalInfo }

        }
        return { value: "---" }
    }

    const handleChange = () => {
        const { title, msg, actions: actionsDict } = settingsStore.dictionaryUI[PROJECT].change
        node.state.prompt = {
            title,
            msg,
            actions: [
                {
                    //DO
                    key: "do",
                    text: actionsDict.do.text || "שמור",
                    action: e => {
                        node.state.snackbar = { msg: "שומר..." };
                        node.state.project.save();
                        node.state.prompt = false
                        node.state.snackbar = { msg: actionsDict.do.msg };
                    },
                },
                {
                    //UNDO
                    key: "undo",
                    color: "gray",
                    text: actionsDict.undo.text || "ביטול",
                    action: e => node.state.prompt = false
                }
            ]
        }
    }

    const handleCopyContract = (e, _node, doc, modelID) => {
        //TODO: addDataOptions
        const parentProject = doc.getParent()
        preventDefaults(e)
        _node.state.prompt = {
            title: "שכפול חוזה",
            class: "full-width",
            form: () => {
                return m(".",
                    m(".prompt__msg", `האם לשכפל את החוזה "${doc.docData.title}"?`),
                    m(".prompt__actions",
                        m("button.button prompt__action", { onclick: e => { copyAction(e, _node, doc) } }, "צור"),
                        m("button.button prompt__action button--gray", { onclick: e => { } }, "בטל")
                    ),
                    m(".prompt__otherActions",
                        m(".caption", "או : צור בפרוייקט אחר"),
                        parentProject.getSiblings({ exclude: { ref: parentProject.docData.ref } }).map(project => {
                            return m("button.button", {
                                onclick: e => {
                                    copyAction(e, _node, doc, { colRef: `${project.docData.ref}/${CONTRACTS}` })
                                }
                            }, `צור ב${project.docData.title}`)
                        })
                    )
                )
            }
        }
    }
    const isRemoved = () => node.state.project && node.state.project.isRemoved

    //this is the default headers for this page can be cusomize by user or something so onInit its clone this list and able to modify it later on if neede
    const projectHeaders = [
        "title",
        "address",
        "sDate",
        "numberOfPeriods",
        "eDate",
        "description",
        "manager",
        "seniorManager",
        "numberOfBuildings",
        "basements",
        "numberOfApatrments",
        "executor",
        "entrepreneur",
        "projectType",
        "status"
    ]

    const CONTRACT_VIEWS = {
        DETAILS: "details",
        BUDGET: "budget",
        CONFIRMS: "confirms",
        ACCOUNTS: "accounts",
        ATTACHMENTS: "attachments",
        ODD_JOBS: "oddJobs"
    }

    const navContractView = [
        {
            label: "סטטוס אישורים",
            active: true,
            key: CONTRACT_VIEWS.CONFIRMS,
            action: function () {
                // node.state.snackbar = { msg: "תצוגה זו אינה זמינה עדיין...", isError: true }
                node.state.contractsView = this.key
                navContractView.forEach(el => el.active = false)
                this.active = true
            }
        },
        // {
        //     label: "תקציב פרוייקט",
        //     active: false,
        //     key: CONTRACT_VIEWS.BUDGET,
        //     action: function () {
        //         node.state.contractsView = this.key
        //         navContractView.forEach(el => el.active = false)
        //         this.active = true
        //     }
        // },
        {
            label: "סטטוס חשבונות",
            active: false,
            key: CONTRACT_VIEWS.ACCOUNTS,
            action: function () {
                node.state.contractsView = this.key
                navContractView.forEach(el => el.active = false)
                this.active = true
                // if (isSuperAdmin()) {
                //     node.state.contractsView = this.key
                //     navContractView.forEach(el => el.active = false)
                //     this.active = true
                // } else {
                //     node.state.snackbar = { msg: "תצוגה זו אינה זמינה עדיין...", isError: true }
                // }
            }
        },
        {
            label: "פרטי חוזים",
            active: false,
            key: CONTRACT_VIEWS.DETAILS,
            action: function () {
                node.state.contractsView = this.key
                navContractView.forEach(el => el.active = false)
                this.active = true
            }
        },
        {
            label: "צרופות פרוייקט",
            active: false,
            key: CONTRACT_VIEWS.ATTACHMENTS,
            action: function () {
                node.state.contractsView = this.key
                navContractView.forEach(el => el.active = false)
                this.active = true
            }
        },
        {
            label: "עבודות קטנות",
            active: false,
            key: CONTRACT_VIEWS.ODD_JOBS,
            action: function () {
                node.state.contractsView = this.key
                navContractView.forEach(el => el.active = false)
                this.active = true
                // node.state.snackbar = { msg: DEFAULT_ACTION_NOT_AVILABLE, isError: true }
            }
        },
    ]
    const navProjectViewOptions = {
        OVERVIEW: "overview",
        APARTMENTS: "apartments",
    }
    const navProjectView = new NavClass({
        nav: [
            { label: "דשבורד פרוייקט", key: navProjectViewOptions.OVERVIEW }, //1st element is defaultActive
            {
                label: "אינדקס תכולת פרוייקט",
                key: navProjectViewOptions.APARTMENTS,
                action: () => {
                    m.route.set(`/app/projects/${node.attrs.projectID}/apartments`)
                    m.redraw()
                }
            },
        ],
    })

    const isAllowToSetNext = (file, updateFields) => {
        let isAllow = true
        const currentUser = dataStore[USERS].current
        if (!currentUser) isAllow = false
        else if ([USER_ROLES.AREA_MANAGER, USER_ROLES.PROJECT_MANAGER].includes(currentUser.docData.role) && updateFields.status !== "confirm1") isAllow = false
        else if (currentUser.docData.role === USER_ROLES.CEO_OPERATIONS && updateFields.status !== "confirm2") isAllow = false
        return isAllow
    }

    const setNextStatus = async (file, updateFields) => {

        try {
            //testUserPermission
            const isAllow = isAllowToSetNext(file, updateFields)
            if (!isAllow) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
            //setValues
            file.edit(updateFields);
            await Promise.resolve(file.save());
            node.state.prompt = false;
            m.redraw();
        } catch (err) {
            node.state.snackbar = { msg: "אירעה שגיאה בשמירת הנתונים: \n" + err, isError: true }
            console.error(err)
        }
    }

    const getOddJobStatusNextOptions = currentStatus => oddJobsStatus.filter((status) => status.id !== "open" && status.id !== "archive" && status.id !== "donePartial" && currentStatus.next.includes(status.id))

    const contractsDetailsHeaders = {
        title: {},
        status: {},
        contractorRef: {},
        budgetbudgetaryItem: {},
        contractSum: {},
        calculationMethod: {},
    }
    const contractsConfirmsHeaders = {
        title: {},
        status: {},
        contractorRef: {},
        _vendorSAPcode: {
            label: "קוד בסאפ",
            val: doc => {
                let value = "---"
                const vendorDoc = DocModel.getDoc(doc.docData.contractorRef)
                if (vendorDoc) {
                    value = vendorDoc.docData.contractorSAPNumber
                }
                return { value }
            },
            action: "copy",
        },
        _lastAccountMonth: {
            label: "חשבון נוכחי",
            val: doc => {
                // const activeDoc = doc.getActiveAccount()
                // const activeDoc = doc.getLastAccount()
                // if (activeDoc) {
                //     return { value: activeDoc.docData.month }
                // }
                const { currentAccount: { month = "---" } = {} } = doc.docData
                return { value: month }
                // return { value: "---" }
            },
            type: STRING,
            options: {
                ltr: true
            }
        },
        _accountFlow0: {
            label: confirmAccountFlow[0].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[0].id),
        },
        _accountFlow1: {
            label: confirmAccountFlow[1].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[1].id),
        },
        _accountFlow2: {
            label: confirmAccountFlow[2].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[2].id),
        },
        _accountFlow3: {
            label: confirmAccountFlow[3].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[3].id),
        },
        _accountFlow4: {
            label: confirmAccountFlow[4].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[4].id),
        },
        _accountFlow5: {
            label: confirmAccountFlow[5].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[5].id),
        },
        _accountFlow6: {
            label: confirmAccountFlow[6].text,
            type: STRING,
            val: doc => getAccountConfirmStatus(doc, confirmAccountFlow[6].id),
        },
        progressCommentTags: {
            label: "הערות תהליך",
            // type: STRING,
            // val: doc => {
            //     const docs = doc.getChildren(CONTRACT_COMMENTS)
            //     let len = docs.length
            //     const result = { value: "---" }
            //     if (len) {
            //         result.value = `${len} 🚩`
            //         result.additionalInfo = { value: `\nתקציר:\n${docs.map(d => d.docData.title).join("\n")}` }
            //     }
            //     return result
            // },
            action: (tableNode, doc) => {
                tableNode.state.prompt = {
                    title: "הערות תהליך וחשבונות",
                    class: "full-width",
                    form: () => {
                        return m(".",
                            m(InputCell, { header: "progressCommentTags", doc, settings: { autoSave: true } }),
                            m(Table, {
                                title: "הערות חוזה",
                                modelID: CONTRACT_COMMENTS,
                                colRef: `${doc.docData.ref}/${CONTRACT_COMMENTS}`,
                                doc,
                                tableHeaders: ["title", "description", "isPinned"],
                                actions: { onRow: false },
                                viewOptions: { nav: false, add: false, totals: false, filters: false },
                                sortOptions: { param: "timestamp" },
                                searchOptions: ["title", "description"],
                                filters: [],
                                // filterMap: []
                            })
                        )
                    }
                }
            }
        },
    }
    // const contractsAccountsHeaders = {
    //     title: {},
    //     _lastAccountMonth: {
    //         label: "חשבון נוכחי",
    //         val: doc => {
    //             // const activeDoc = doc.getActiveAccount()
    //             const activeDoc = doc.getLastAccount()
    //             if (activeDoc) {
    //                 return { value: activeDoc.docData.month, classStyle: 'ltr' }
    //             }
    //             return { value: "---" }
    //         },
    //         type: STRING,
    //         options: {
    //             ltr: true
    //         }
    //     },
    //     _totalCumulative: {
    //         label: "חשבון מצטבר",
    //         val: doc => {
    //             return { value: parseFloat(doc.docData.totalSections) + parseFloat(doc.docData.totalAdditions) - parseFloat(doc.docData.totalSubtractions) }
    //         },
    //         type: CURRENCY
    //     },
    //     _totalAdditionSubtractionActual: {
    //         label: "ביצוע - תוספות וקיזוזים",
    //         val: doc => {
    //             const totalAddition = parseFloat(doc.docData.totalAdditions)
    //             const totalSubtraction = parseFloat(doc.docData.totalSubtractions)
    //             return { value: parseFloat(totalAddition - totalSubtraction), additionalInfo: { value: `=[תוספות:${getDisplayValue(totalAddition, NUMBER)}] - [קיזוזים:${getDisplayValue(totalSubtraction, NUMBER)}]` } }
    //         },
    //         type: CURRENCY
    //     },
    //     _totalDelay: {
    //         label: "עיכבון מצטבר",
    //         val: doc => {
    //             const totalSections = parseFloat(doc.docData.totalSections)
    //             const totalAddition = parseFloat(doc.docData.totalAdditions)
    //             const totalSubtraction = parseFloat(doc.docData.totalSubtractions)
    //             const delayPercentage = parseFloat(doc.docData.delayPercentage / 100)
    //             const value = (totalSections + totalAddition - totalSubtraction) * delayPercentage
    //             return { value, additionalInfo: { value: `\n[% עיכבון] - ${getDisplayValue(delayPercentage, PERCENT)}` } }
    //         },
    //         type: CURRENCY
    //     },
    //     _totalToPay: {
    //         label: "לתשלום תק נוכחית",
    //         val: doc => {
    //             const activeDoc = doc.getLastAccount()
    //             if (activeDoc) {
    //                 return { value: activeDoc.docData.totalPay }
    //             }
    //             return { value: "---" }
    //         },
    //         type: CURRENCY
    //     },
    //     _totalContract: {
    //         label: "סכום הסכם",
    //         val: doc => {
    //             // return { value: doc.getTotal() }
    //             return { value: doc.docData.contractSum }
    //         },
    //         type: CURRENCY
    //     },
    //     // _totalAdditionSubrtaction: {
    //     //     label: "תוספות וקיזוזים - סיכום",
    //     //     val: doc => {
    //     //         const totalAddition = doc.getTotalAdditionsOrSubtractions(CONTRACT_ADDITIONS)
    //     //         const totalSubtraction = doc.getTotalAdditionsOrSubtractions(CONTRACT_SUBTRACTIONS)
    //     //         return { value: parseFloat(totalAddition - totalSubtraction), additionalInfo: { value: `\n=[תוספות:${getDisplayValue(totalAddition, NUMBER)}] - [קיזוזים:${getDisplayValue(totalSubtraction, NUMBER)}]` } }
    //     //     },
    //     //     type: CURRENCY
    //     // },
    //     // _donePercentage: {
    //     //     label: "% ביצוע",
    //     //     val: doc => {
    //     //         return { value: doc.getTotalToPayCalculation().percentDone }
    //     //     },
    //     //     type: PERCENT
    //     // }
    // }

    const setCurrentProject = () => {
        node.state.project = findProject();
        if (!node.state.project) {
            m.route.set("/app/projects")
            m.redraw()
        } else {
            if (node.state.project.getProjectType("id") === 'entrepreneurship') {
                node.state.projectHeaders.push("entrepreneur")//TODO: refactor using visibility settings on Project
            }
        }
        node.state.load = false
        m.redraw()
    }

    const promptOddJobFile = (tblNode, file) => {
        const currentStatus = oddJobsStatus.find(status => status.id === file.docData.status);
        const { url, size, created, contentType } = file.docData
        node.state.prompt = {
            title: "עבודות קטנות",
            class: "full-width",
            form: () => m(".filePrompt",
                m(FormEditDoc, {
                    actions: { changes: true, close: false, remove: false },
                    parent: tblNode,
                    title: file.docData.title,
                    doc: file,
                    headers: ["title", "attachmentID", "fileDate", "sum", "description", "confirmSum", "balance", "createdAt", "createdBy"],
                }),
                m("hr"),
                m(".caption", "סטטוס מסמך:"),
                m(DisplayCell, { field: file.headers.status, doc: file, value: file.docData.status, header: "status", options: { ignoreLabel: true } }),
                // m(".", getDisplayValue(file.docData.status, SWITCH, file.headers.status.options)),
                (file.docData.statusFlow && Array.isArray(file.docData.statusFlow) && file.docData.statusFlow.length > 0) && m("ul", { style: "font-size: 2.4rem;" },
                    m(".caption", "הסטוריית אישורים:"),
                    file.docData.statusFlow.map(el => {
                        return m("li", `תאריך: ${getDisplayValue(el.createdAt, DATE)} | גורם מאשר: ${getDisplayValue(el.createdBy, USER)} | שלב: ${getDisplayValue(el.confirmStage, SWITCH, OddJobFile.headers.status.options)} | סכום מאושר: ${getDisplayValue(el.totalPay, CURRENCY)}`)
                    })
                ),
                getOddJobStatusNextOptions(currentStatus).length > 0 && m(".caption", "עדכן סטטוס ל:"),
                m(".", { style: `display: grid;grid-auto-flow: column;justify-content: flex-start;gap: 1rem;` },
                    getOddJobStatusNextOptions(currentStatus)
                        .map(status => {
                            if (status.id === "done") {
                                return m("button.button", {
                                    disabled: !isAllowToSetNext(file, { status: status.id }),
                                    onclick: e => {
                                        let statusID = "done"
                                        if (file.docData.balance != 0) statusID = "donePartial"
                                        setNextStatus(file, {
                                            status: statusID,
                                            confirmDate: new Date().toISOString()
                                        })
                                    }
                                }, "נסגר ושולם")
                            } else return m("button.button", {
                                disabled: !isAllowToSetNext(file, { status: status.id }),
                                onclick: e => setNextStatus(file, { status: status.id })
                            }, status.text)
                        })
                ),
                m("hr"),
                m(".caption", "מסמך"),
                m(".filePrompt__preview",
                    // (url && (contentType === MIME_TYPES.EXCEL || contentType === MIME_TYPES.OLD_EXCEL)) && m("a", m(".link center", "הצג במסך מלא"), m("iframe.showFile", { onclick: e => e.stopPropagation(), title: "תצוגה מקדימה", name: "תצוגה מקדימה", src: `https://view.officeapps.live.com/op/embed.aspx?src=${url}`, width: "400", height: "500", align: "center", allow: "fullscreen", frameborder: "0" })),
                    (url && !file.isNew) && m("a.link center", { href: url, target: "_blank" }, "הצג במסך מלא"),
                    (url && contentType === "application/pdf") && m("iframe.showFile", { onclick: e => e.stopPropagation(), title: "תצוגה מקדימה", name: "תצוגה מקדימה", src: url, width: "750", height: "800", align: "center", allow: "fullscreen", frameborder: "0" }),
                    (url && contentType.startsWith("image/")) && m("img.showFile", { onclick: e => e.stopPropagation(), src: url, alt: "אין תצוגה מקדימה", width: "100%" }),
                    m("span", `size: ${size} | created: ${created}`),
                )
            )
        }
    }

    return {
        load: true,
        prompt: false,
        snackbar: false,
        searchTermContracts: "",
        contractsView: CONTRACT_VIEWS.CONFIRMS,
        projectHeaders: [...projectHeaders],
        settings: { navOnAdd: true },
        oninit: vnode => {
            window.scrollTo(0, 0);
            navProjectView.setNode(vnode)

            const { alreadyListen: isListenToProjects, unsubscribe: unsubscribeProjects } = dataStore[PROJECTS].listen();
            if (isListenToProjects) {
                setCurrentProject()
            } else {
                vnode.state.snackbar = { msg: "טוען..." }
                Promise.resolve(setTimeout(() => setCurrentProject(), 2000))
                    .then(m.redraw())
            }
            const projectPath = `projects/${vnode.attrs.projectID}`
            const { alreadyListen: isListenToContracts, unsubscribe: unsubscribeContracts } = dataStore[CONTRACTS].listen(projectPath)
            sessionStorage.setItem('lastURL', m.route.get());
            console.log("routeChange", m.route.get());
            logRouteChangeUser("checkIn");
        },
        onbeforeremove: vnode => {
            return logRouteChangeUser("checkOut");
        },
        view: vnode => {
            const { contractsView, loadContracts } = vnode.state
            return (
                m(PageLayout, {
                    class: "project",
                    title: vnode.state.project ?
                        `${vnode.state.project.getProjectType("text")} / אינדקס ניהול פרוייקט` :
                        "אינדקס ניהול פרוייקט",
                    module: vnode.state.project ? vnode.state.project.getProjectType("id") : ""
                },
                    vnode.state.project && isSystemMode(vnode) && m(".settingsRow",
                        m(`button.button ${vnode.state.settings.navOnAdd ? "button--red" : ""}`, { onclick: e => vnode.state.settings.navOnAdd = !vnode.state.settings.navOnAdd }, vnode.state.settings.navOnAdd ? "מנווט" : "נשאר"),
                        m(`button.button`, { onclick: e => Promise.resolve(vnode.state.project.toJSON({ clone: true })).then(r => console.log(r)) }, "TO_JSON"),
                        m(`button.button`, { onclick: e => vnode.state.project.exportSheet() }, "DOWNLOAD_SHEET"),
                        m(`button.button`, { onclick: e => console.log(settingsStore.dbTree) }, "showDBTree")
                    ),
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    isRemoved() && m(".", "פרוייקט זה נמחק"),
                    (!isRemoved() && vnode.state.project) && m(Nav, { nav: navProjectView.nav }),
                    m(Card, { class: "formCard project__details no-print" },
                        m("h3.caption formCard__caption", "פרטי הפרוייקט"),
                        vnode.state.load && m(InlineLoaderDots),
                        (vnode.state.project && vnode.state.project.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                        vnode.state.project && vnode.state.projectHeaders.map((header, index) => {
                            const docData = vnode.state.project.docData
                            const value = docData[header]
                            const field = ProjectDoc.headers[header]
                            if (isUserAllow(PROJECTS, UPDATE)) {
                                return m(InputCell, {
                                    header, value, field, index, id: `project__${header}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.project,
                                    parent: vnode
                                })
                            } else {
                                return m(DisplayCell, { header, field, value, doc: vnode.state.project })
                            }
                        }),
                        vnode.state.project && vnode.state.project.hasChanges() && m(".formCard__actions",
                            m("button.button formCard__save", { onclick: e => handleChange() }, "שמור שינויים"),
                            m("button.button formCard__abort", { onclick: e => vnode.state.project.abortChanges() }, "בטל שינויים"),
                        )
                    ),
                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew) && m(Nav, { nav: navContractView, class: "nav--page" }),
                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.BUDGET) && m(Table, {
                        tableHeaders: {
                            budgetRef: {}, sum: {}, description: {}, contractRef: {
                                type: LIST,
                                options: {
                                    data: vnode.state.project.getChildren(CONTRACTS),
                                    load: () => dataStore[CONTRACTS].listen(vnode.state.project.docData.ref),
                                    model: ContractDoc,
                                    addOnFlow: false,
                                }
                            }
                        },
                        sortOptions: { param: "index" },
                        viewOptions: { nav: false, title: false },
                        actions: { onRow: "prompt", onAdd: "prompt", remove: true, copy: true, move: true, add: true },
                        title: "סעיף תקציבי",
                        modelID: PROJECT_BUDGET,
                        colRef: `projects/${vnode.attrs.projectID}/${PROJECT_BUDGET}`,
                        doc: vnode.state.project,
                    }),
                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.CONFIRMS) &&
                    m(".",
                        m(".printTitle",
                            m(".heading heading--1", `דוח אישורים - פרוייקט "${vnode.state.project.docData.title}"`),
                            m("span.flag", `נוצר ב: ${getDisplayValue(new Date(), DATE)}`),
                            auth.currentUser && m("span.flag", `נוצר עי: ${getDisplayValue(auth.currentUser.uid, USER)}`),
                        ),
                        m(".actions", {
                            style: `
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                padding: 1.5rem;`
                        },
                            m(IconButton, { class: "action no-print", icon: "icon-print", action: e => window.print() }),
                        ),
                        m(Table, {
                            title: "סטטוס אישורים",
                            sortOptions: { param: "title", type: STRING },
                            modelID: CONTRACTS,
                            colRef: `${vnode.state.project.docData.ref}/${CONTRACTS}`,
                            doc: vnode.state.project,
                            actions: { add: false, onRow: "redirect", copy: false, remove: false, move: false, enlarge: false },
                            searchOptions: [
                                "title",
                                "description",
                                {
                                    header: "contractorRef",
                                    params: ["title", "contractorSAPNumber"]
                                }],
                            tableHeaders: contractsConfirmsHeaders,
                            viewOptions: { stickyHeaders: true }
                            // filters: vnode.state.filtersContracts,
                        })
                    ),
                    // (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.ACCOUNTS) && m(Table, {
                    //     oninit: node => vnode.state.project.getChildren(CONTRACTS).forEach(contract => contract.listenToChildren([CONTRACT_ACCOUNTS])),
                    //     title: "סטטוס חשבונות",
                    //     modelID: CONTRACTS,
                    //     colRef: `${vnode.state.project.docData.ref}/${CONTRACTS}`,
                    //     doc: vnode.state.project,
                    //     actions: { add: false, onRow: "redirect" },
                    //     viewOptions: { nav: true, filters: true, add: false, totals: true },
                    //     tableHeaders: contractsAccountsHeaders,
                    //     filters: vnode.state.filtersContracts,
                    // }),
                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.ACCOUNTS) && m(Card, { class: "project__accountsReport A4" },
                        m(".printTitle",
                            m(".heading heading--1", `דוח חשבונות - פרוייקט "${vnode.state.project.docData.title}"`),
                            m("span.flag", `נוצר ב: ${getDisplayValue(new Date(), DATE)}`),
                            auth.currentUser && m("span.flag", `נוצר עי: ${getDisplayValue(auth.currentUser.uid, USER)}`),
                        ),
                        m(".actions",
                            m(IconButton, { class: "action", icon: "icon-print", action: e => exportReportAPI(vnode, exportProjectAccountsReport()) }),
                        ),
                        m(".tbl", { style: "grid-template-columns:1fr 1fr 10px 1fr 1fr 1fr 1fr 1fr 10px 1fr 1fr 1fr min-content" },
                            //HEADERS
                            m(".tbl__header", "חוזה"),
                            m(".tbl__header", "קבלן"),

                            m(".tbl__space", ""),

                            m(".tbl__header", "חשבון מצטבר"),
                            m(".tbl__header", "מצטבר תק' קודמות"),
                            m(".tbl__header", "תק' נוכחית קיזוזים"),
                            m(".tbl__header", "חשבון נוכחי"),
                            m(".tbl__header", "תשלום תק' נוכחית"),

                            m(".tbl__space", ""),

                            m(".tbl__header", "סכום הסכם"),
                            m(".tbl__header", "תוספות וקיזוזים"),
                            m(".tbl__header", "יתרה לתשלום"),
                            m(".tbl__header", "% ששולם"),
                            //case empty

                            vnode.state.project.getChildren(CONTRACTS)
                                .sort(sortDocsBy("title", { type: STRING }))
                                .map(contract => {
                                    const { title, contractorRef, totalSections, totalAdditions, totalSubtractions, currentAccount, contractSum, ref: contractRef } = contract.docData;
                                    const total = { sections: 0, additions: 0, subtractions: 0, total: 0 }
                                    const history = { sections: 0, additions: 0, subtractions: 0, total: 0 }
                                    const current = { sections: 0, additions: 0, subtractions: 0, total: 0, month: "--" }
                                    total.sections = parseFloat(totalSections)
                                    total.additions = parseFloat(totalAdditions)
                                    total.subtractions = parseFloat(totalSubtractions)
                                    total.total = total.sections + total.additions - total.subtractions
                                    if (currentAccount) {
                                        const { totalSections: currentSections, totalAdditions: currentAdditions, totalSubtractions: currentSubtractions, month } = currentAccount;
                                        current.sections = parseFloat(currentSections)
                                        current.additions = parseFloat(currentAdditions)
                                        current.subtractions = parseFloat(currentSubtractions)
                                        current.total = current.sections + current.additions - current.subtractions
                                        current.month = month
                                        history.sections = parseFloat(totalSections) - parseFloat(currentSections)
                                        history.additions = parseFloat(totalAdditions) - parseFloat(currentAdditions)
                                        history.subtractions = parseFloat(totalSubtractions) - parseFloat(currentSubtractions)
                                        history.total = total.total - current.total
                                    }
                                    const totalToPay = parseFloat(contractSum) - total.total
                                    const percentDone = (total.total / parseFloat(contractSum)) * 100
                                    debugger
                                    return [
                                        m("a.tbl__cell cell link", { href: `/#!/app/${contractRef}`, target: "_blank" }, title),
                                        m("a.tbl__cell cell link", { href: `/#!/app/${contractorRef}`, target: "_blank" }, getDisplayValue(contractorRef, REF, ContractDoc.headers.contractorRef.options)),

                                        m(".tbl__space"),

                                        m(".tbl__cell cell", getDisplayValue(total.total, CURRENCY)),
                                        m(".tbl__cell cell", getDisplayValue(history.sections + history.additions - history.subtractions, CURRENCY)),
                                        m(".tbl__cell cell", getDisplayValue(current.subtractions, CURRENCY)),
                                        m(".tbl__cell cell [dir=ltr]", current.month),
                                        m(".tbl__cell cell", getDisplayValue(current.total, CURRENCY)),

                                        m(".tbl__space"),

                                        m(".tbl__cell cell", getDisplayValue(contractSum, CURRENCY)),
                                        m(".tbl__cell cell", getDisplayValue(parseFloat(totalAdditions - totalSubtractions), CURRENCY)),
                                        m(".tbl__cell cell", getDisplayValue(totalToPay, CURRENCY)),
                                        // m(".tbl__cell cell", getDisplayValue(percentDone, PERCENT)),
                                        m(".tbl__cell cell",
                                            m(PercentClock, {
                                                fill: "#2349a94d",
                                                stroke: parseFloat(percentDone) > 0 ? "#234aa9" : "var(--color-red)",
                                                text: "",
                                                value: parseFloat(percentDone),
                                                size: "sm"
                                            })
                                        ),
                                    ]
                                })
                        ),
                    ),
                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.DETAILS) && m(Table, {
                        title: "טבלת חוזים",
                        modelID: CONTRACTS,
                        colRef: `${vnode.state.project.docData.ref}/${CONTRACTS}`,
                        doc: vnode.state.project,
                        tableHeaders: contractsDetailsHeaders,
                        searchOptions: [
                            "title",
                            "description",
                            {
                                header: "contractorRef",
                                params: ["title", "contractorSAPNumber"]
                            }],
                        // filters: vnode.state.filtersContracts,
                        actions: {
                            onAdd: "redirect", onRow: "redirect", add: true,
                            handleCopy: handleCopyContract,//TODO:TESTME: 
                        },
                        sortOptions: { param: "title", type: STRING },
                        addDataOptions: { status: "plan" },
                    }),

                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.ODD_JOBS) && m(TableFiles, {
                        colRef: `${vnode.state.project.docData.ref}/${ODD_JOBS}`,
                        filterDataOptions: { include: { colRef: `${vnode.state.project.docData.ref}/${ODD_JOBS}` } },
                        filters: [{ id: "status", param: "archive" }, { id: "status", param: "done" }, { id: "status", param: "notConfirm" }],
                        title: "עבודות קטנות",
                        doc: vnode.state.project,
                        viewOptions: { totals: true },
                        modelID: ODD_JOBS,
                        actions: {
                            onRow: (tblNode, file) => promptOddJobFile(tblNode, file),
                            // onRow: "prompt",
                            // onAdd: "prompt",
                            add: true
                        },
                        tableHeaders: {
                            title: {}, attachmentID: {}, fileDate: {}, sum: {}, description: {}, status: {}, confirmSum: {}, balance: {}, confirmDate: { label: "שולם" }
                        }
                    }),
                    (vnode.state.project && !isRemoved() && !loadContracts && !vnode.state.project.isNew && contractsView === CONTRACT_VIEWS.ATTACHMENTS) && m(TableFiles, {
                        colRef: `${vnode.state.project.docData.ref}/${FILES}`,
                        filterDataOptions: { include: { colRef: `${vnode.state.project.docData.ref}/${FILES}` } },
                        title: "צרופות פרוייקט",
                        doc: vnode.state.project,
                        modelID: FILES,
                        actions: { onRow: "prompt", onAdd: "prompt", add: true },
                        tableHeaders: ["title", "description", "status", "createdAt", "createdBy"]
                    }),
                )
            )
        }
    }
}