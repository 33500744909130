import m from "mithril"
// import print from "printJS"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { auth } from "../../../index"
import { DATE, USER } from "../../../utils/constants/types"
import { uuid } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"
import { Logo } from "../../components/logo/Logo"

export const ReportLayout = node => {
    const printPage = () => {
        if (node.attrs.printAction) node.attrs.printAction()
        else window.print()
    }
    
    return {
        oncreate: vnode => {
            vnode.dom.id = uuid()
        },
        view: vnode => {
            const { print = true } = vnode.attrs
            return m("div.layout--reports", { style: "min-height:100vh" },
                m('.page.show', { style: "grid-template-columns: auto;" },
                    m('main.content reports',
                        m(".reports__title",
                            m(Logo, { action: () => m.route.set("/app/reports") }),
                            m(".heading heading--1", vnode.attrs.title || "דוח ללא שם"),
                            m("span.flag print-only", `נוצר ב: ${getDisplayValue(new Date(), DATE)}`),
                            auth.currentUser && m("span.flag print-only", `נוצר עי: ${getDisplayValue(auth.currentUser.uid, USER)}`),
                            print && m(Icon, { icon: "icon-print", action: e => printPage() })
                        ),
                        m('.reports__report', { class: vnode.attrs.class || "" }, vnode.children),
                    )
                ),
                //     m("footer.footer", { style: "position: absolute;bottom: 0;" },
                //         m("p.copy",
                //             `${String.fromCharCode(169)} כל הזכויות שמורות לביקונט בע"מ 2020
                // [version:${APP_VERSION}] `,
                //             m.trust("&beta;")
                //         ),
                //         m("a.bug", {
                //             target: "_blank",
                //             href: "https://forms.monday.com/forms/17766a3159a34dfe4dad302678ec99cf"
                //         }, m(Icon, { icon: "icon-bug" }))
                //     )
            )
        }
    }
}