import m from "mithril"
import { dataStore } from "../../../data/store/dataStore"

import { CREATE, REMOVE, UPDATE } from "../../../data/store/permissionStore"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { isSuperAdmin, isUserAllow } from "../../../data/utils/permission"
import { isDocMatchTerm, isFiltered } from "../../../data/utils/search"
import { buildSheetXMLTable } from "../../../data/utils/utils"
import { REG_URL_FIRST_PART, REG_URL_LAST_PART } from "../../../utils/constants/regex"
import { CHECK_BOOL, CURRENCY, DATE, DIRECTORY, LIST, NUMBER, PERCENT, STRING, SWITCH } from "../../../utils/constants/types"
import { Icon } from "../../components/icon/Icon"
import { Snackbar } from "../../components/snackbar/Snackbar"
import { Card } from "../cardLayout/Card"
import { FilterTable } from "../filterTable/FilterTable"
import { Prompt } from "../prompt/Prompt"
import { FormEditDoc } from "../prompt/FormEditDoc"
import { db } from "../../../index"
import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots"
import { handleAdd, handleCopy, handleDelete, pathToRoutes, getAddOptions } from "../../../data/utils/utils"
import { asNumber, objType, preventDefaults, sortBy, sortDocsBy, uuid } from "../../../utils/js"
import { Nav } from "../nav/Nav"
import { LocalSwitch } from "../inputCell/LocalSwitch"
import { TableCell } from "./TableCell"
import { CheckCell } from "../checkCell/CheckCell"
import { settingsStore } from "../../../data/store/settingsStore"
import { O_ARRAY, O_FUNCTION, O_OBJECT } from "../../../utils/constants/objTypes"
import { DocModel } from "../../../data/store/docModel"
import { DATE_RANGE } from "../../../utils/constants/filtersTypes"
import { FullPageLayout } from "../pageLayout/FullPage"
import { defaultFields, internalFields } from "../../../data/dictionary/internalFields"


export const Table = node => {

    // const printTable = (e) => {
    //     const { title, modelID, colRef, doc, filterDataOptions } = node.attrs
    //     const actions = { onRow: false, remove: false, copy: false, move: false, enlarge: false, add: false, redirect: false }
    //     const viewOptions = { nav: false, filters: false, search: false, add: false, totals: true, download: false, print: false, title: false }

    //     const { tableHeaders } = node.state

    //     preventDefaults(e)
    //     node.state.prompt = {
    //         title,
    //         form: () => m(FullPageLayout, {
    //             title,
    //             viewOptions: { breadCrumbs: false, print: true, arrowBack: false },
    //             actions: { back: m.route.get() }
    //         },
    //             m(Table, { modelID, colRef, doc, actions, viewOptions, filterDataOptions, tableHeaders })
    //         )
    //     }
    // }

    const moveUp = async (e, doc) => {
        preventDefaults(e)
        try {
            const replaceWithBefore = doc.getBefore("index")
            if (replaceWithBefore) {
                await doc.setIndex(replaceWithBefore.docData.ref, "before")
                m.redraw()
            }
        } catch (err) {
            console.error(err)
        }
    }
    const moveDown = async (e, doc) => {
        preventDefaults(e)
        try {
            const replaceWithAfter = doc.getAfter("index")
            if (replaceWithAfter) {
                await doc.setIndex(replaceWithAfter.docData.ref, "after")
                m.redraw()
            }
        } catch (err) {
            console.error(err)
        }
    }

    const promptDoc = (e, doc) => {
        preventDefaults(e)
        const { tableHeaders } = node.state
        node.state.prompt = {
            class: "full-width",
            title: doc.docData.title,
            form: () => m(FormEditDoc, { doc, headers: tableHeaders, parent: node })
        }
    }

    const tableAddAction = (e) => {
        const { modelID, colRef, doc, actions = {}, filterDataOptions = {}, addDataOptions = {} } = node.attrs
        const { onAdd = "redirect" } = actions
        const Model = dataStore[modelID].model
        if (objType(onAdd) === O_FUNCTION) {
            return actions.onAdd(node, doc)
        } else {
            return handleAdd(e, node, Model, modelID, getAddOptions(colRef, filterDataOptions, addDataOptions))
        }
    }

    const addDocToCollection = (doc, isLast = false) => {
        let parent = doc.ref.parent
        const docData = Object.assign({},
            doc.data(),
            {
                colRef: parent.path,
                ref: doc.ref.path,
                docID: doc.id
            }
        )
        if (isLast) {
            node.state.collection.push(docData)
        } else {
            node.state.docsFilters.push(docData)
        }
        m.redraw()
    }

    const groupsByParent = () => {
        const { doc: instance, modelID } = node.attrs
        const result = []
        instance.getChildren(node.attrs.modelID, node.attrs.filterDataOptions || {}, node.attrs.filterSettings || {}).forEach(doc => {
            const parent = doc.getParent()
            const colRef = `${parent.docData.ref}/${modelID}`
            const findIndex = result.findIndex(group => group.fieldValue == colRef)
            if (findIndex === -1) {
                const displayValue = getDisplayValue(parent.docData.title, STRING, instance.docData)
                let label = displayValue
                const addGroup = {
                    groupID: uuid(),
                    displayValue,
                    label,
                    fieldValue: colRef,
                    total: 1
                }
                result.push(addGroup)
            } else {
                result[findIndex].total += 1
            }
        })
        return result
    }

    const groupDataBy = (headerGroup, type = "count", headerTotal, isParent) => {
        const { totalDoc } = node.state
        const { doc: instance } = node.attrs
        const result = []
        instance.getChildren(node.attrs.modelID, node.attrs.filterDataOptions || {}, node.attrs.filterSettings || {})
            .forEach(_doc => {
                let doc = _doc
                if (isParent) doc = _doc.getParent()
                if (!doc) throw 'doc not exist'
                const Model = doc.model
                const findIndex = result.findIndex(group => group.fieldValue == doc.docData[headerGroup])
                if (findIndex === -1) {
                    const displayHeader = headerGroup === "ref" ? "title" : headerGroup
                    // console.log(headerGroup, displayHeader);
                    const displayValue = getDisplayValue(doc.docData[displayHeader], Model.headers[displayHeader].type, Model.headers[displayHeader].options, doc.docData)
                    let label = displayValue
                    if (headerTotal && type === "sum") label += " | " + getDisplayValue(totalDoc.docData.totalBy, totalDoc.headers.totalBy.type, totalDoc.headers.totalBy.options, totalDoc.docData)
                    const addGroup = {
                        groupID: uuid(),
                        displayValue,
                        label,
                        fieldValue: doc.docData[headerGroup],
                        total: type === "count" ? 1 : asNumber(doc.docData[headerTotal])
                    }
                    result.push(addGroup)
                } else {
                    result[findIndex].total += type === "count" ? 1 : asNumber(doc.docData[headerTotal])
                }
            })
        result.sort(sortBy("total", "desc"))
        let max
        result.forEach((group, ind) => {
            if (ind === 0) {
                max = asNumber(group.total)
                group.percent = 100
            } else {
                group.percent = (group.total / max) * 100
            }
        })
        return result
    }

    const getData = async () => {
        try {
            node.state.load = true
            // await new Promise(resolve => setTimeout(resolve, 1000));
            // node.state.collection.push({ "title": "נסיון" })
            const routesCalls = pathToRoutes(node.attrs.colRef)
            // console.log(routesCalls);
            routesCalls.forEach(async (part, ind) => {
                const isLast = ind === routesCalls.length - 1
                const { collectionID, docID, type, where, level } = part
                if (type === "doc") {
                    // console.log(" GET DOC", part);
                    const doc = await db.doc(`${collectionID}/${docID}`).get()
                    addDocToCollection(doc, isLast)
                } else if (type === "collection") {
                    // console.log("GET COLLECTION", part);
                    const docs = (await db.collection(collectionID).get()).docs
                    docs.forEach(doc => addDocToCollection(doc, isLast))
                } else if (type === "collectionGroup") {
                    // console.log("GET ALL DOCS (collectionGroup)", part);
                    const docs = (await db.collectionGroup(collectionID).get()).docs
                    docs.forEach(doc => addDocToCollection(doc, isLast))
                }
            })
            m.redraw()
        } catch (err) {
            console.error(err)
        } finally {
            console.log("collection \n", node.state.collection);
            console.log("docsFilters \n", node.state.docsFilters);
            node.state.load = false
            m.redraw()
        }
    }

    const navTableViews = [
        {
            label: "פירוט",
            active: true,
            key: "data",
            action: function () {
                node.state.tableView = this.key
                navTableViews.forEach(el => el.active = false)
                this.active = true
            }
        },
        {
            label: "סיכום",
            active: false,
            key: "totals",
            action: function () {
                node.state.tableView = this.key
                navTableViews.forEach(el => el.active = false)
                this.active = true
            }
        },
    ]

    const updateTotalDocByParent = (modelID) => {
        node.state.totalDoc.headers.groupBy.options = getHeadersOptions(modelID, "groupBy")
        node.state.totalDoc.headers.totalBy.options = getHeadersOptions(modelID, "totalBy")
        node.state.totalDoc.headers.pivotBy.options = getHeadersOptions(modelID, "pivotBy")
    }

    const getHeadersOptions = (modelID, key, byParent = false) => {
        const Model = dataStore[modelID].model
        const fieldTypes = []
        switch (key) {
            //REF field should added to groupBy
            case "groupBy": fieldTypes.push(LIST, SWITCH, DIRECTORY); break;
            case "totalBy": fieldTypes.push(NUMBER, CURRENCY, PERCENT); break;
            case "pivotBy": fieldTypes.push(DATE); break;
        }
        return Object.entries(Model.headers)
            .map(([key, field]) => {
                if (fieldTypes.includes(field.type)) {
                    return {
                        id: key,
                        text: field.label,
                        field
                    }
                }
            })
            // .concat(byParent ? {
            //     id: "ref",
            //     text: "*",
            //     field: Model.headers.title
            // } : {})
            .filter(el => el)//remove undefind
    }

    const setTotalDoc = () => {
        const { modelID, aggregationOptions = {} } = node.attrs
        const {
            aggregationType = "count",
            groupBy = "",
            pivotBy = "",
            byParent = false
        } = aggregationOptions

        node.state.totalDoc = {
            docData: { aggregationType, groupBy, pivotBy, byParent },
            headers: {
                byParent: {
                    label: "קיבוץ לפי שדה הורה",
                    type: CHECK_BOOL
                },
                aggregationType: {
                    label: "סוג סיכום",
                    options: [
                        { id: "count", text: "כמות" },
                        { id: "sum", text: "סכום" },
                        // { id: "average", text: "ממוצע" },
                    ],
                    type: SWITCH,
                    change: val => node.state.totalDoc.docData.aggregationType = val,
                    remove: () => node.state.totalDoc.docData.aggregationType = "",
                },
                totalBy: {
                    label: "סיכום לפי",
                    type: SWITCH,
                    change: val => node.state.totalDoc.docData.totalBy = val,
                    remove: () => node.state.totalDoc.docData.totalBy = "",
                    options: getHeadersOptions(modelID, "totalBy")
                },
                groupBy: {
                    label: "קיבוץ לפי",
                    type: SWITCH,
                    change: val => node.state.totalDoc.docData.groupBy = val,
                    remove: () => node.state.totalDoc.docData.groupBy = "",
                    options: getHeadersOptions(modelID, "groupBy")
                },
                pivotBy: {
                    label: "חלק לפי",
                    type: SWITCH,
                    change: val => node.state.totalDoc.docData.pivotBy = val,
                    remove: () => node.state.totalDoc.docData.pivotBy = "",
                    options: getHeadersOptions(modelID, "pivotBy")
                }
            }
        }
    }

    function getRawData(modelID, instance, filterDataOptions, sortOptions, totalDocData) {
        const { aggregationType, groupBy, totalBy, byParent } = totalDocData
        const result = []
        if (byParent) {
            if (groupBy !== "") {
                const groups = groupDataBy(groupBy, aggregationType, totalBy, byParent)
                groups.forEach(group => {
                    const groupChildren = instance.getChildren(modelID, { parentInclude: { [groupBy]: group.fieldValue } }, node.attrs.filterSettings || {})
                    result.push({ docData: { docType: "group", group, docID: uuid() } }, ...groupChildren)
                })
            } else {
                const parents = groupsByParent()
                parents.forEach(parent => {
                    const groupChildren = instance.getChildren(modelID, { include: { colRef: parent.fieldValue } }, node.attrs.filterSettings || {})
                    result.push({ docData: { docType: "group", group: parent, docID: uuid() } }, ...groupChildren)
                })
            }
        } else if (aggregationType !== "" && groupBy !== "" && (aggregationType === "sum" ? totalBy !== "" : true)) {
            const groups = groupDataBy(groupBy, aggregationType, totalBy)
            groups.forEach(group => {
                const groupChildren = instance.getChildren(modelID, { include: { [groupBy]: group.fieldValue } }, node.attrs.filterSettings || {})
                result.push({ docData: { docType: "group", group, docID: uuid() } }, ...groupChildren)
            })
        } else {
            return instance.getChildren(modelID, node.attrs.filterDataOptions || {}, node.attrs.filterSettings || {})
                .sort(sortDocsBy(sortOptions.param, { param: sortOptions.param, type: sortOptions.type }))
        }
        return result
    }

    // const getItemChildrenModels = () => {
    //     const { modelID, subItemsModelID } = node.attrs
    //     const directChildren = []
    //     const findDirectChildren = (obj, key) => {
    //         for (const [prop, subObj] of Object.entries(obj)) {
    //             if (prop === key) return directChildren.push(...Object.keys(subObj))
    //             findDirectChildren(subObj, key)
    //         }
    //     }
    //     findDirectChildren(settingsStore.dbTree, modelID)
    //     return directChildren
    // }
    // const toggleSubItems = doc => {
    //     const { subItems = [] } = node.state
    //     const findIndex = subItems.findIndex(el => el.docRef === doc.docData.ref)
    //     if (findIndex === -1) {
    //         //add to state
    //         node.state.subItems.push({
    //             docRef: doc.docData.ref,
    //             // subModelID:undefined
    //         })
    //     } else {
    //         //remove from state
    //         node.state.subItems.splice(findIndex, 1)
    //     }
    //     console.log(subItems);
    // }

    // const toggleSubGroup = (e, docRef, subModelID) => {
    //     preventDefaults(e)
    //     const { subItems = [] } = node.state
    //     const findSubItemInd = subItems.findIndex(el => el.docRef === docRef)
    //     if (findSubItemInd > -1) {
    //         if (subItems[findSubItemInd].subModelID === subModelID) {
    //             subItems[findSubItemInd].subModelID = false
    //         } else {
    //             subItems[findSubItemInd].subModelID = subModelID
    //         }
    //     }
    // }


    const buildDefaultFilterMap = (modelDoc, docHeaders) => {
        const _filterMap = []
        Object.entries(docHeaders).forEach(([header, { type, label, options }]) => {
            if (!header in modelDoc.headers) return
            switch (type) {
                case DATE:
                    _filterMap.push({
                        label, header, type: DATE_RANGE,
                        options: [
                            { id: `${header}:after`, text: `מ`, logic: `after` },
                            { id: `${header}:before`, text: "עד", logic: "before" }
                        ]
                    })
                    break;
                case SWITCH:
                    _filterMap.push({ label, header, type, options })
                    break;
                //TODO: LIST,DIRECTORY,REF...
            }
        })
        _filterMap.push({
            label: "תאריך יצירה",
            header: "createdAt",
            type: DATE_RANGE,
            options: [
                { id: `createdAt:after`, text: `מ`, logic: `after` },
                { id: `createdAt:before`, text: "עד", logic: "before" }
            ]
        })
        return _filterMap
    }

    const buildTableHeaders = (modelFields, headers) => {
        const obj = {}
        const type = objType(headers)
        if (type === O_OBJECT) {
            Object.entries(headers).forEach(([header, settingsField]) => {
                obj[header] = Object.assign(modelFields[header] || {}, settingsField || {}, defaultFields[header] || {})
            })
        } else if (type === O_ARRAY) {
            headers.forEach(header => {
                obj[header] = Object.assign({}, modelFields[header] || {}, defaultFields[header] || {})
            })
        }
        // console.log(obj);
        return obj
    }
    const exprtTableData = async () => {
        const { doc: instance, modelID, title = node.attrs.colRef, filterDataOptions = {}, filterSettings = {}, sortOptions = { param: "timestamp" } } = node.attrs
        const { tableHeaders } = node.state

        const fileName = `${title}_download_${+new Date()}.xls`;

        const data = instance.getChildren(modelID, filterDataOptions, filterSettings)
            .sort(sortDocsBy(sortOptions.param))

        const XML = buildSheetXMLTable(title, tableHeaders, data)
        const blob = new Blob([XML], { type: 'text/plain' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName;
        link.dataset.downloadurl = ['text/plain', link.download, link.href].join(':');
        link.draggable = true;
        link.classList.add('dragout');
        link.click();
    }

    const onRowAction = (e, doc) => {
        preventDefaults(e)
        const { actions = { onRow: "prompt" } } = node.attrs
        if (!actions.onRow) return
        if (actions.onRow === "prompt") {
            promptDoc(e, doc)
        } else if (actions.onRow === "redirect") {
            let storePart = m.route.get().match(REG_URL_FIRST_PART)[0]
            if (storePart === "/settings/" && doc.docData.ref.startsWith("settings")) storePart = "/" //settings is part of the path
            const path = `${storePart}${doc.docData.ref}`
            m.route.set(path)
            // } else if (actions.onRow === "subItems") {
            //     toggleSubItems(doc)
        } else {
            if (objType(actions.onRow) === O_FUNCTION) {
                return actions.onRow(node, doc)
            }
        }
    }

    const handleSaveDoc = async (e, doc) => {
        preventDefaults(e)
        e.target.disabled = true
        try {
            await doc.save()
        } catch (err) {
            node.state.snackbar = { msg: err, isError: true }
            console.error(err);
        } finally {
            e.target.disabled = false
            m.redraw()
        }
    }

    const defatulTableHeaders = { title: { label: "שם" } }

    return {
        prompt: false,
        snackbar: false,
        searchTerm: "",
        tableView: "data",
        tableHeaders: {},
        filters: node.attrs.filters || [],
        filterMap: node.attrs.filterMap,
        collection: [],
        docsFilters: [],
        subItems: [],
        load: false,
        totalDoc: {},
        settings: {},
        oninit: async vnode => {
            const { modelID, colRef, tableHeaders, doc, actions = { onAdd: "redirect" }, filterMap } = vnode.attrs
            const Model = dataStore[modelID].model
            if (vnode.attrs.tableHeaders) vnode.state.tableHeaders = buildTableHeaders(Model.headers, tableHeaders)
            else vnode.state.tableHeaders = defatulTableHeaders

            const parentRef = colRef.replace(REG_URL_LAST_PART, "")
            const { unsubscribe, alreadyListen } = dataStore[modelID].listen(parentRef)

            if (actions.onAdd === "redirect") {
                node.state.settings.navOnAdd = true
            } else if (actions.onAdd === "prompt") {
                let formHeaders = vnode.state.tableHeaders
                if (node.attrs.formHeaders) formHeaders = vnode.attrs.formHeaders
                node.state.settings.openEditDocForm = { formHeaders }
            }
            if (!filterMap) {
                vnode.state.filterMap = buildDefaultFilterMap(DocModel.getChildModel(modelID), vnode.state.tableHeaders)
            }

            setTotalDoc()

            if (!doc) {
                getData()
            }
        },
        view: vnode => {
            const {
                title,
                colRef,
                doc: instance,
                modelID,
                filterDataOptions = {},
                filterSettings = {},
                addDataOptions = {},
                searchOptions = ["title"],
                sortOptions = { param: "timestamp", type: NUMBER },
                // viewOptions = { nav: true, filters: true, search: true, title: true, totals: true, add: true },
                viewOptions = {},
                // actions = { onAdd: "redirect", onRow: "redirect", remove: true, copy: true, move: true, enlarge: true },
                actions = {},
            } = vnode.attrs
            const { nav: viewNav = false, filters: viewFilters = true, search: viewSearch = true, title: viewTitle = true, totals: viewTotals = true, add: viewAdd = true, download: viewDownload = true, stickyHeaders = false } = viewOptions
            const { remove: actions_remove = true, copy: actions_copy = true, move: actions_move = true, enlarge: actions_enlarge = true, add: actions_add = true, redirect: actions_redirect = false } = actions
            const { handleCopy: copyAction } = actions
            const { tableHeaders, collection, load, filters, filterMap, totalDoc, tableView, subItems } = vnode.state
            const Model = dataStore[modelID].model
            return m(Card, { class: `tableCard ${vnode.attrs.class || ""}` },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                viewNav && m(Nav, { nav: navTableViews }),
                (tableView === "totals") && m(".tableCard__totalsFunc",
                    isSuperAdmin() && colRef.split("/").length > 2 && instance.getParent() && m(CheckCell, {
                        checked: node.state.totalDoc.docData.byParent === true,
                        setCheck: () => {
                            node.state.totalDoc.docData.byParent = !totalDoc.docData.byParent
                            if (totalDoc.docData.byParent) {
                                const [col, doc, colParent, ...rest] = colRef.split("/").reverse()
                                updateTotalDocByParent(colParent)
                            } else {
                                updateTotalDocByParent(modelID)
                            }
                        },
                        label: node.state.totalDoc.headers.byParent.label
                    }),
                    m(LocalSwitch, { doc: totalDoc, header: "aggregationType" }),
                    (totalDoc.docData.aggregationType === "sum" ||
                        totalDoc.docData.aggregationType === "average") && m(LocalSwitch, { doc: totalDoc, header: "totalBy" }),
                    m(LocalSwitch, { doc: totalDoc, header: "groupBy" }),
                    // m(LocalSwitch, { doc: totalDoc, header: "pivotBy" }),
                ),
                (tableView === "totals") && m(".tableCard__summery", {
                    style: `display: grid;gap: 1rem;`
                },
                    [totalDoc].map(({ docData, headers }) => {
                        if (docData.aggregationType === "count") {
                            if (docData.groupBy === "") {
                                return m(".summery__box", {
                                    onclick: e => node.state.tableView = "data"
                                },
                                    m(".summery__label", "כמות שורות"),
                                    m(".summery__total", getDisplayValue(instance.getChildren(modelID, filterDataOptions, filterSettings).length, NUMBER)),
                                )
                            } else {
                                return groupDataBy(docData.groupBy, "count", docData.totalBy, docData.byParent).map(group => {
                                    return m(".summery__box", {
                                        style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${100 - group.percent}%, var(--color-active) 0);background-repeat: no-repeat;`
                                    },
                                        m(".summery__label", group.label),
                                        m(".summery__total", getDisplayValue(group.total, NUMBER)),
                                    )
                                })
                            }
                        } else if (docData.aggregationType === "sum" && docData.totalBy !== "") {
                            if (docData.groupBy === "") {
                                return m(".summery__box",
                                    m(".summery__label", getDisplayValue(docData.totalBy, totalDoc.headers.totalBy.type, totalDoc.headers.totalBy.options, totalDoc.docData)),
                                    m(".summery__total", getDisplayValue(instance.getTotalDocs(modelID, filterDataOptions, filterSettings, docData.totalBy), NUMBER)),
                                )
                            } else {
                                return groupDataBy(docData.groupBy, "sum", docData.totalBy, docData.byParent).map(group => {
                                    return m(".summery__box", {
                                        style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${100 - group.percent}%, var(--color-active) 0);background-repeat: no-repeat;`
                                    },
                                        m(".summery__label", group.label),
                                        m(".summery__total", getDisplayValue(group.total, NUMBER)),
                                    )
                                })
                            }
                        }
                    })
                ),
                viewFilters && m(FilterTable, {
                    searchTerm: vnode.state.searchTerm,
                    filters,
                    updateSearchTerm: term => vnode.state.searchTerm = term,
                    filterMap
                }),
                (viewAdd && actions_add && instance && isUserAllow(modelID, CREATE)) && m("button.button tableCard__add", { onclick: e => tableAddAction(e) }, m(Icon, { icon: "icon-plus" }) /* "הוספת רשומה" */),
                (viewTitle) && m(".caption tableCard__caption", title || "טבלה"),
                // (viewDownload) && m(Icon, { icon: "icon-print", class: "table__print", action: e => printTable(e) }),
                (viewDownload) && m(Icon, { icon: "icon-download", class: "table__download", action: e => exprtTableData() }),
                m(".table",
                    // HEADERS
                    m('.table__headers table__row', {
                        oncreate: el => {
                            if (stickyHeaders) {
                                document.addEventListener("scroll", () => {
                                    if (el.dom.getBoundingClientRect().top <= 0) el.dom.classList.add("sticky")
                                    else el.dom.classList.remove("sticky")
                                })
                            }
                        },
                        key: `headers`,
                        style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);`,
                        // class: stickyHeaders ? "sticky" : "",
                    },
                        Object.entries(tableHeaders).map(([header, settingsField]) => {
                            const label = settingsField.label ? settingsField.label : Model.headers[header].label
                            return m('.table__cell', {
                                key: `headers/${header}`
                            }, label)
                        }),
                    ),
                    //CASE EMPTY
                    instance && instance.getChildren(modelID, filterDataOptions, filterSettings).length === 0 && m('.table__row', { style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);` }, Object.keys(tableHeaders).map(header => m(".cell cell--value", "---"))),

                    //DATA
                    instance && getRawData(modelID, instance, filterDataOptions, sortOptions, totalDoc.docData)
                        .map((doc, ind, arr) => {
                            if (doc.docData.docType === "group") {
                                return m(".table__row--group", {
                                    key: doc.docData.docID
                                }, doc.docData.group.displayValue)
                            }
                            if (isFiltered(vnode, doc) || !isDocMatchTerm(vnode, doc, searchOptions)) return
                            const { index: indexField } = doc.docData
                            // const docSubItems = subItems.find(({ docRef }) => docRef === doc.docData.ref)
                            // const subItemsModel = docSubItems ? docSubItems.subModelID : false
                            return m(".table__row", {
                                "data-ref": doc.docData.ref,
                                key: uuid(),
                                // key: doc.docData.ref,
                                class: doc.isNew ? "table__row--new" : "",
                                style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);`,
                                onclick: e => onRowAction(e, doc)
                            },
                                Object.entries(tableHeaders).map(([header, settingsField]) => m(TableCell, {
                                    parent: vnode,
                                    key: `${doc.docData.ref}/${header}`,
                                    doc, header, settingsField
                                })),
                                m(".table__cell table__cell--actions",
                                    doc.isNew && m("button.button", { onclick: e => handleSaveDoc(e, doc) }, "שמור"),
                                    (actions_move && indexField && !doc.isFirst("index", filterDataOptions)) && isUserAllow(modelID, UPDATE, doc) && m(".table__action", { onclick: e => moveUp(e, doc) }, m(Icon, { icon: "icon-arrow-up" })),
                                    (actions_move && indexField && !doc.isLast("index", filterDataOptions)) && isUserAllow(modelID, UPDATE, doc) && m(".table__action", { onclick: e => moveDown(e, doc) }, m(Icon, { icon: "icon-arrow-down" })),
                                    (actions_redirect && isSuperAdmin()) && m(".table__action", { onclick: e => m.route.set(`/app/${doc.docData.ref}`) }, m(Icon, { icon: "icon-new-tab" })),
                                    (actions_enlarge && isSuperAdmin()) && m(".table__action", { onclick: e => promptDoc(e, doc) }, m(Icon, { icon: "icon-enlarge" })),
                                    (actions_copy && isUserAllow(modelID, CREATE, doc)) && m(".table__action", { onclick: e => copyAction ? copyAction(e, vnode, doc, modelID) : handleCopy(e, vnode, doc, modelID) }, m(Icon, { icon: "icon-copy" })),
                                    (actions_remove && isUserAllow(modelID, REMOVE, doc)) && m(".table__action", { onclick: e => handleDelete(e, vnode, doc, modelID) }, m(Icon, { icon: "icon-delete" })),
                                ),
                                // docSubItems && m(".nav",
                                //     getItemChildrenModels().map(subModelID => {
                                //         return m(".nav__link", {
                                //             key: `${doc.docData.ref}/subGroup/${subModelID}`,
                                //             class: subItemsModel === subModelID ? "nav__link--active" : "",
                                //             onclick: e => toggleSubGroup(e, doc.docData.ref, subModelID)
                                //         }, subModelID)
                                //     })
                                // )
                            )
                            // // SUBITEMS_HEADERS
                            // (subItemsModel) && m('.table__headers table__row--subGroup', {
                            //     key: `${doc.docData.ref}/subGroup/${subModelID}/headers`,
                            //     style: `grid-template-columns: repeat(${Object.keys(dataStore[subItemsModel].model.headers).length},1fr);`
                            // },
                            //     Object.entries(dataStore[subItemsModel].model.headers).map(([header, settingsField]) => {
                            //         const label = settingsField.label ? settingsField.label : Model.headers[header].label
                            //         return m('.table__cell', { key: `${doc.docData.ref}/subGroup/${subModelID}/headers/${header}` }, label)
                            //     }),
                            // ),
                            // // SUBITEMS_DATA
                            // (subItemsModel) && doc.getChildren(subItemsModel).map(doc => {
                            //     return m(".table__row--subGroup", {
                            //         key: doc.docData.ref,
                            //         style: `grid-template-columns: repeat(${Object.keys(dataStore[subItemsModel].model.headers).length},1fr);`
                            //     },
                            //         Object.entries(dataStore[subItemsModel].model.headers).map(([header, settingsField]) => m(TableCell, { key: `${doc.docData.ref}/${header}`, doc, header, settingsField,parent:vnode })))
                            // }),
                        }),

                    //TOTALS ROW:
                    (instance && viewTotals) && m(".table__totals", {
                        style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);`,
                    },
                        Object.entries(tableHeaders).map(([header, settingsField]) => {
                            const defaultField = Model.headers[header] || {}
                            const type = settingsField.type ? settingsField.type : defaultField.type || STRING
                            const fieldOptions = settingsField.options ? settingsField.options : defaultField.options || {}
                            const docs = instance
                                .getChildren(modelID, filterDataOptions, filterSettings)
                                .filter(doc => !isFiltered(vnode, doc) && isDocMatchTerm(vnode, doc, searchOptions))
                            if (header === "title") {
                                return m(".total", `${getDisplayValue(docs.length, NUMBER)} שורות`)
                            }
                            if (fieldOptions.totals) {
                                console.assert(fieldOptions.totals.val, { totalsObj: fieldOptions.totals })
                                const totalValue = fieldOptions.totals.val(docs.sort(sortDocsBy(sortOptions.param)))
                                return m(".total", getDisplayValue(totalValue, fieldOptions.totals.type || STRING))
                            }
                            if (type === NUMBER || type === CURRENCY) {
                                let total = 0
                                if (settingsField.val) {
                                    total = docs.reduce((acc, doc) => acc += asNumber(settingsField.val(doc).value), 0)
                                } else {
                                    total = docs.reduce((acc, doc) => acc += asNumber(doc.docData[header]), 0)
                                }
                                return m(".total", getDisplayValue(total, type, fieldOptions))
                            }
                            return m(".total", "---")
                        })
                    ),
                    load && m(InlineLoaderDots),
                    //GET DATA
                    !instance && collection.map((docData, ind) => {
                        // if (docData.docType === "group") {
                        //     return m(".table__row--group", { key: ind }, docData.group.displayValue)
                        // }
                        return m(".table__row", {
                            style: `grid-template-columns: repeat(${Object.keys(tableHeaders).length},1fr);`,
                        },
                            Object.entries(tableHeaders).map(([header, settingsField]) => {
                                const defaultField = Model.headers[header] || {}
                                const type = settingsField.type ? settingsField.type : defaultField.type || STRING
                                const fieldOptions = settingsField.options ? settingsField.options : defaultField.options || {}
                                return m(".table__cell", getDisplayValue(docData[header], type, fieldOptions, docData))
                            }),
                        )
                    })
                )
            )
        }
    }
}