import m from "mithril"
import { DocModel } from "../../../data/store/docModel";
import { getDisplayValue } from "../../../data/utils/inputValidation";
import { DATE, NUMBER, STRING, SWITCH } from "../../../utils/constants/types";
import { Card } from "../../commons/cardLayout/Card";
import { InputCell } from "../../commons/inputCell/InputCell";
import { PageLayout } from "../../commons/pageLayout/Page";
import { InlineLoaderDots } from "../../components/inlineLoaderDots/InlineLoaderDots";

// INFO:
//  https://www.cbs.gov.il/he/Pages/%D7%9E%D7%93%D7%93%D7%99-%D7%9E%D7%97%D7%99%D7%A8%D7%99%D7%9D-%D7%91%D7%90%D7%9E%D7%A6%D7%A2%D7%95%D7%AA-API.aspx


export const IndexedCalculatorPage = node => {

    async function calcValue(date, toDate, chapterID, value = 100) {
        node.state.result = false
        node.state.loadResult = true
        try {
            const reqURL = `https://api.cbs.gov.il/index/data/calculator/${chapterID}?value=${value}&date=${date}&toDate=${toDate}`
            const result = await fetch(reqURL);
            const { answer } = await result.json();
            if (answer && answer.to_value) {
                node.state.result = answer.to_value
            }
        } catch (err) {
            console.error(err);
            node.state.error = err
        } finally {
            node.state.loadResult = false
            m.redraw()
        }
    }

    const fetchChapters = async () => {
        const result = await fetch("https://api.cbs.gov.il/index/catalog/catalog?format=json&download=false");
        const { chapters } = await result.json();
        if (chapters) {
            chapters.forEach(chapter => node.state.chapters.data.push(chapter));
        } else {
            node.state.chapters.data.push({ chapterName: "no data found" });
        }
        node.state.chapters.loading = false;
        m.redraw();
    }

    const doc = {
        date: "2020-01-01",
        toDate: "2020-12-01",
        value: 100,
        chapterID: 200010,
    }
    const headers = {
        date: { label: "התחלה", type: DATE },
        toDate: { label: "סיום", type: DATE },
        chapterID: { label: "פרק", type: SWITCH, options: indexedFactorOptions },
        value: { label: "סכום להצמדה" }
    }

    const indexedFactorOptions = [
        { text: 'מדד המחירים לצרכן', id: '120010' },
        { text: 'מדד מחירי תשומה בבנייה למגורים', id: '200010' },
        { text: 'מדד מחירי תשומה בבנייה למסחר ולמשרדים', id: '800010' },
    ]

    return {
        chapters: { data: [], loading: true },
        selectedChapter: 200010,
        oninit: vnode => {
            fetchChapters()
        },

        view: vnode => {
            const { result, loadResult, selectedChapter, error } = vnode.state
            const { date, toDate, chapterID, value } = doc
            return m(PageLayout, { class: "indexCalc" },
                m(Card, { class: "indexCalc__chapters" },
                    m(".caption", "רשימת פרקים"),
                    vnode.state.chapters.loading && m(InlineLoaderDots),
                    vnode.state.chapters.data.map(({ chapterName, mainCode }) => {
                        return m(`.indexCalc__chapter${mainCode === selectedChapter ? "--selected" : ""}`, {
                            onclick: e => {
                                if (mainCode && mainCode !== null) {
                                    vnode.state.selectedChapter = mainCode
                                    doc.chapterID = mainCode
                                    vnode.state.result = false
                                }
                            }
                        }, `#${mainCode} - ${chapterName}`)
                    }),
                ),
                m(Card, { class: "indexCalc__info" },
                    m(".caption", "מחשבון"),
                    doc && ["chapterID", "date", "toDate", "value"].map(header => {
                        return m(".input",
                            m("input.input__field", {
                                value: doc[header],
                                oninput: e => {
                                    doc[header] = e.target.value
                                    vnode.state.result = false
                                }
                            }),
                            m("label.input__label", headers[header].label)
                        )
                    }),
                    m("button.button", { onclick: e => calcValue(date, toDate, chapterID, value) }, "חשב ערך הצמדה"),
                    loadResult && m(InlineLoaderDots),
                    (!loadResult && result) && m(".info", getDisplayValue(result, NUMBER, { isFloat: true })),
                    error && m(".danger", error)
                )
            )
        }
    }
}