import { CURRENCY, DATE, STRING } from "../../utils/constants/types"
import { addMonths, buildMonthText, diffDays, formatDateDisplay, monthDiff, textToDate } from "../../utils/js"
import { CONTRACT_SECTIONS, PROJECTS } from "../dictionary/routeNames"
import { confirmAccountFlow } from "../store/contractsActuals/accountClass"
import { getDisplayValue } from "../utils/inputValidation"
import { getModelID } from "../utils/utils"


export const confirmHeaders = {
    headers: {
        title: { label: "חוזה", classStyle: "link", href: ref => `/#!/app/${ref}` },
        "currentAccount.month": {
            classStyle: "ltr",
            val: docData => getContractAccountMonth(docData),
            label: "חשבון אחרון"
        },
        _diff: {
            val: docData => getAccountDiff(docData),
            label: "🕣"
        },
        _accountFlow0: {
            label: confirmAccountFlow[0].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[0].id),
        },
        _accountFlow1: {
            label: confirmAccountFlow[1].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[1].id),
        },
        _accountFlow2: {
            label: confirmAccountFlow[2].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[2].id),
        },
        _accountFlow3: {
            label: confirmAccountFlow[3].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[3].id),
        },
        _accountFlow4: {
            label: confirmAccountFlow[4].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[4].id),
        },
        _accountFlow5: {
            label: confirmAccountFlow[5].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[5].id),
        },
        _accountFlow6: {
            label: confirmAccountFlow[6].text,
            type: STRING,
            val: docData => getAccountConfirmStatus(docData, confirmAccountFlow[6].id),
        },
    },
    totals: {
        title: {
            val: (projectRef, contractsRawData) => `${Object.keys(contractsRawData).length} חוזים`,
            func: "COUNTA"
        },
        period: {},
        _diff: {},
        _accountFlow0: {},
        _accountFlow1: {},
        _accountFlow2: {},
        _accountFlow3: {},
        _accountFlow4: {},
        _accountFlow5: {},
        _accountFlow6: {},
    },
}

export const getSubTotalAccounts = (reportData, contractRef, field, action = "sum") => {
    let result = 0
    const projectRef = contractRef.split("/").slice(0, 2).join("/")
    // TODO: add some client filters if needed [acocunt filtering..]
    for (const accountRef in reportData.projects[projectRef].contracts[contractRef].accounts) {
        let subTotal = parseFloat(reportData.projects[projectRef].contracts[contractRef].accounts[accountRef].docData[field])
        if (action === "sum") {
            if (isNaN(subTotal)) subTotal = 0
            result += subTotal
        } else if (action === "count") result++
    }
    return result
}

export const getAccount = (reportData, accountRef, param) => {
    const contractRef = accountRef.split("/").slice(0, 4).join("/")
    const projectRef = accountRef.split("/").slice(0, 2).join("/")
    const account = reportData.projects[projectRef].contracts[contractRef].accounts[accountRef]
    if (account && account.docData) {
        if (param && param in account.docData) return account.docData[param]
        else return account
    }
}

export const getSubTotalContracts = (reportData, projectRef, field, action = "sum") => {
    // TODO: add some client filters if needed [acocunt filtering..]
    if (field in reportData.projects[projectRef]) return reportData.projects[projectRef][field]
    else if (field === "subTotal") {
        const {
            totalSections,
            totalAdditions,
            totalSubtractions,
        } = reportData.projects[projectRef]
        return totalSections + totalAdditions - totalSubtractions
    } else {
        let result = 0
        for (const contractRef in reportData.projects[projectRef].contracts) {
            if (action === "sum") {
                let subTotal = parseFloat(reportData.projects[projectRef].contracts[contractRef][field])
                if (isNaN(subTotal)) subTotal = 0
                result += subTotal
            } else if (action === "count") {
                result += 1
            }
        }
        return result
    }
}

export const getTotalPayments = (reportData, ref, action = "sum") => {
    let result = 0
    const months = {}
    const scope = getModelID(ref)
    const projectRef = ref.split("/").slice(0, 2).join("/")
    // TODO: add some client filters if needed [acocunt filtering.. or event payment filtering]
    for (const contractRef in reportData.projects[projectRef].contracts) {
        if (scope === PROJECTS || ref === contractRef) {
            for (const accountRef in reportData.projects[projectRef].contracts[contractRef].accounts) {
                const payments = reportData.projects[projectRef].contracts[contractRef].accounts[accountRef].payments
                payments.forEach(payment => {
                    if (action === "sum") result += parseFloat(payment.docData.sum)
                    else if (action === "count") result++
                    else if (action === "aggByMonth") {
                        const paymentData = new Date(payment.docData.paymentDate)
                        const monthYear = buildMonthText(paymentData)
                        if (!months[monthYear]) months[monthYear] = {
                            count: 0,
                            sum: 0,
                            index: paymentData.setDate(0)
                        }
                        months[monthYear].count += 1
                        months[monthYear].sum += parseFloat(payment.docData.sum)
                    }
                })
            }
        }
    }
    if (action === "aggByMonth") {
        result = Object.entries(months)
            .sort(sortEntries("index"))
            .map(([month, agg]) => (agg.count && agg.sum) ?
                `${month}: ${getDisplayValue(agg.sum, CURRENCY)}`
                : ""
            ).join("\t")
    }
    return result
}

export const getAccountConfirmStatus = (contractData, confirmStage) => {
    const { currentAccount = {} } = contractData
    const { confirms = {} } = currentAccount
    if (currentAccount && confirms && confirms[confirmStage]) {
        const { confirmBy, confirmAt, confirm: isConfirm, due, id } = confirms[confirmStage]
        let txt = "", classStyle = "", additionalInfo = { value: "" }
        const dueDiff = diffDays(addMonths(1, textToDate(currentAccount.month, due)), new Date())
        const isDelay = dueDiff > 0
        let lastStage = "start", lastConfirm = confirms.start
        while (lastConfirm.next && lastConfirm.confirm !== false) {
            lastConfirm = confirms[lastConfirm.next]
            lastStage = lastConfirm.id
        }
        const isCurrentStage = id === lastStage
        if (isConfirm == true) {
            // const userConfirmText = UserDoc.getUserByID(confirmBy, "displayName")
            const confirmAtText = formatDateDisplay(new Date(confirmAt))
            // txt += `${confirmAtText} [${userConfirmText}]`
            txt += `${confirmAtText}`
            classStyle += " active"
        } else {
            if (isDelay && !isCurrentStage) classStyle += " pendingChanges"
            if (isDelay && isCurrentStage) {
                additionalInfo.value += ` [באיחור של ${parseInt(dueDiff)} ימים]`
                additionalInfo.isError = true
                classStyle += " pending"
            }
            txt += getDisplayValue(addMonths(1, textToDate(currentAccount.month, due)), DATE, { outputFormat: "dd=>mm" })
            classStyle += " non-active"
        }
        return { value: txt, classStyle, additionalInfo }
    }
    return { value: "---" }
}

export const getContractAccountMonth = (contractData) => {
    let classStyle = "", additionalInfo = { value: "" }
    const { currentAccount = {} } = contractData
    if (currentAccount.month) {
        const dueDiff = diffDays(addMonths(1, textToDate(currentAccount.month)), new Date());
        const isDelay = dueDiff > 0;
        // if (isDelay) {
        //     additionalInfo.value += ` [באיחור של ${parseInt(dueDiff)} ימים]`
        //     additionalInfo.isError = true
        // }
    }
    return { value: currentAccount.month || "---", classStyle, additionalInfo }
}


export const getAccountDiff = (contractData) => {
    let classStyle = "", additionalInfo = { value: "" }, result = "---"
    const { currentAccount = {} } = contractData
    if (currentAccount.month) {
        const dueDiffDays = diffDays(addMonths(1, textToDate(currentAccount.month)), new Date());
        const dueDiffMonths = monthDiff(addMonths(1, textToDate(currentAccount.month)), new Date());
        const isDelay = dueDiffDays > 0;
        if (isDelay) {
            if (dueDiffMonths > 0) result = `${parseInt(dueDiffMonths)}M`
            else result = `${dueDiffDays} D`
            classStyle += 'danger'
        }
    }
    return { value: result, classStyle, additionalInfo }
}