import m from "mithril"
import { ACTUALS } from "../../../../data/dictionary/routeNames"
import { getDisplayValue } from "../../../../data/utils/inputValidation"
import { NUMBER, PERCENT } from "../../../../utils/constants/types"
import { sortDocsBy } from "../../../../utils/js"

export const MilestoneUnitActuals = node => {
    const isCurrentAccount = actual => actual.docData.colRef === `${node.attrs.accountRef}/${ACTUALS}`

    return {
        view: vnode => {
            const { milestone, accountRef, unit } = vnode.attrs
            const isPauschal = milestone.isPauschal()
            return m(".actuals",
                m(".actuals__cell actuals__cell--header", "חודש"),
                m(".actuals__cell actuals__cell--header", "סהכ"),
                milestone.getActuals({ unit }).length === 0 && [
                    m(".actuals__cell actuals__cell--value", "---"),
                    m(".actuals__cell actuals__cell--value", "---"),
                ],
                milestone.getActuals({ unit })
                    .sort(sortDocsBy("timestamp",{type:NUMBER}))
                    .map(actual => {
                        const isCurrent = isCurrentAccount(actual)
                        return [
                            m(".actuals__cell actuals__cell--value", { class: isCurrent ? "current" : "" }, actual.getParent("month")),
                            m(".actuals__cell actuals__cell--value", { class: isCurrent ? "current" : "" }, getDisplayValue(actual.docData.value, isPauschal ? PERCENT : milestone.getAmountType())),
                        ]
                    })
            )
        }
    }
}