import m from "mithril"

import { WORKSPACES, GROUPS, SECTION_MILESTONES, CONTRACT_SECTIONS, BUILDINGS } from "../../dictionary/routeNames";
import { STRING, NUMBER, MULTICHOICE_LIST } from "../../../utils/constants/types";

import { DocModel } from "../docModel";
import { GroupDoc } from "./groupClass";

import { db } from "../../../index";
import { BuildingDoc } from "../projectBuildings/BuildingClass";

export class WorkspaceDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: WorkspaceDoc,
        });
        if (!this.isNew) this.listenToChildren()
    };

    getSections() {
        const contract = this.getParent()
        return contract.getChildren(CONTRACT_SECTIONS).filter(doc => doc.docData.workspacePath.startsWith(`workspaces/${this.docData.docID}`))
    }

    hasSections() {
        return this.getSections().length > 0
    }

    async insert(colPath, forceInsert) {
        try {
            const batch = db.batch()
            super.batchInsert(batch)
            //creates the default group
            const defaultGruop = new GroupDoc({
                colRef: `${this.docData.ref}/groups`,
                docID: "default",
                ref: `${this.docData.ref}/groups/default`,
                title: "סעיפים",
                description: "",
                itemsName: "",
                itemsCount: 1,
                isActive: true,
            })
            defaultGruop.batchSave(batch)

            await batch.commit()
        } catch (err) {
            console.error(err)
        } finally {
            m.redraw()
        }
    }

    toJSON({ clone } = {}) {
        const json = {}
        const cloneWs = this.cloneDocData({ clone })
        json.data = cloneWs
        json.children = {}

        const groups = []
        const sections = []
        const milestones = []
        this.getChildren(GROUPS).forEach(group => {
            const cloneGroup = group.cloneDocData({ clone, newParentRef: cloneWs.ref })
            groups.push(cloneGroup)
            group.getSections().forEach(section => {
                const clonSection = section.cloneDocData()
                clonSection.workspacePath = `workspaces/${cloneWs.docID}/groups/${cloneGroup.docID}`
                sections.push(clonSection)
                section.getChildren(SECTION_MILESTONES, { dataOnly: true })
                    .forEach(ms => {
                        const cloneMilestone = ms.cloneDocData({ clone, newParentRef: clonSection.ref })
                        milestones.push(cloneMilestone)
                    })
            })
        })
        json.children = { groups, sections, milestones }
        return json
    }

    static get meta() {
        return {
            id: WORKSPACES,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/workspaces",
                doc: "/projects/:projectID/contracts/:contractID/workspaces/:workspaceID",
            }
        }
    }
    static get headers() {
        return {
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
            title: { label: 'איזור עבודה', defaultValue: "איזור עבודה", type: STRING },
            description: { label: 'תאור', defaultValue: "--ללא תיאור--", type: STRING },
            itemsName: { label: 'שם יחידה', defaultValue: "--ללא שם--" },
            itemsCount: { label: 'כמות יחידות', defaultValue: 1, type: NUMBER },
            buildingRef: {
                label: "שיוך למבנה",
                type: MULTICHOICE_LIST,
                defaultValue: [],
                options: {
                    getData: (doc) => {
                        const project = doc.getProject()
                        return project.getChildren(BUILDINGS)
                    },
                    model: BuildingDoc,
                    addOnFlow: false,
                    formHeaders: ["title"],
                    params: ["title"]
                }
            },
        }
    }
}