import m from "mithril"

import { STRING, NUMBER, MULTICHOICE_LIST, VALID_UNIQUE, DIRECTORY, LIST } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, UNIQUE } from "../../../utils/constants/inputAttrs";
import { APARTMENTS, APARTMENT_MODELS, CONTRACTS, CONTRACT_SECTIONS } from "../../dictionary/routeNames";

import { db } from "../../../index";
import { DocModel } from "../docModel";
import { SectionDoc } from "../contracts/sectionClass";
import { ApartmentModelDoc } from "./apartmentModelClass";
import { dataStore } from "../dataStore";

export class ApartmentDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ApartmentDoc,
        });
        if (!this.isNew) this.listenToChildren()
        if (this.isNew && !("title" in data)) {
            this.autoTitle()
        }
    };
    async save() {
        //TODO: onSave : doc.changes.contractsRefs => increase amount in contract based on total amount of apartemnts
        await Promise.resolve(super.save())
        if ("contractsRefs" in this.docChanges) {
            await Promise.resolve(this.setContractSectionItemsCount())
        }
    }
    setContractSectionItemsCount() {
        try {
            //TESTME:
            const project = this.getParent()
            this.docChanges.contractsRefs.forEach(async sectionRef => {
                if (sectionRef) {
                    let projectApartemntsInSection = 0
                    project.getChildren(APARTMENTS, {
                        include: { sectionRef: doc => doc.docData.contractsRefs.includes(this.docData.sectionRef) }
                    }).forEach(doc => ++projectApartemntsInSection)
                    // const contractRef = sectionRef.split("/").slice(0, 4).join("/")
                    const sectionDBRef = db.doc(sectionRef)
                    const section = (await sectionDBRef.get()).data()
                    if (parseFloat(section.itemsCount) < projectApartemntsInSection) {
                        sectionDBRef.set({ itemsCount: projectApartemntsInSection }, { merge: true })
                    }
                }
            })
        } catch (err) {
            console.error("Error on setContractSectionItemsCount : ", err);
        }
    }
    async remove() {
        try {
            const project = this.getProject()
            if (!project) throw "project not found"

            await Promise.resolve(super.remove())
            const batch = db.batch()
            //[1] UPDATE BUILDING
            const building = this.getBuilding()
            if (building) {
                batch.set(db.doc(building.docData.ref), { apartmentsCount: parseInt(building.docData.apartmentsCount) - 1 }, { merge: true })
            }
            await Promise.resolve(batch.commit())
            await Promise.resolve(project.updateTotalApartments())
        } catch (err) {
            console.error('Error on ApartmentDoc.remove(): ', err);
        } finally {
            m.redraw()
        }
    }

    getBuildingRef() {
        const projectRef = this.docData.ref.split("/").slice(0, 2).join("/")
        const buildingPath = this.docData.buildingFloorPath.split("/").slice(0, 2).join("/")
        return `${projectRef}/${buildingPath}`
    }
    getFloorRef() {
        const projectRef = this.docData.ref.split("/").slice(0, 2).join("/")
        return `${projectRef}/${this.docData.buildingFloorPath}`
    }
    getFloor(param) {
        const floor = DocModel.getDoc(this.getFloorRef())
        if (floor) {
            if (param) return floor.docData[param]
            return floor
        }
    }
    getBuilding(param) {
        const building = DocModel.getDoc(this.getBuildingRef())
        if (building && param) return building.docData[param]
        return building
    }
    autoTitle() {
        this.docData.title = this.docData.index
    }
    static get meta() {
        return {
            id: APARTMENTS,
            routes: {
                collection: "projects/:projectID/apartments",
                doc: "projects/:projectID/apartments/:apartmentID",
            },
            // logic: [
            //THINK:TODO: unique should be only on same building - maby contcat title_building_ref
            //     { type: VALID_UNIQUE, expression: "title", trigger: ["title"], msg: "ערך כבר קיים , שדה זה הינו ייחודי!" },
            // ],
        }
    }
    static get headers() {
        return {
            title: { label: "דירה", defaultValue: "1", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "1" }] },
            description: { label: 'תיאור והערות', defaultValue: "--ללא תיאור--", type: STRING },
            contractsRefs: {
                label: "קישור לחוזים",
                type: MULTICHOICE_LIST,
                defaultValue: [],
                options: {
                    getData: (doc) => {
                        const project = doc.getParent()
                        const building = doc.getBuilding()
                        if (project && building) {
                            Promise.resolve(project.getChildren(CONTRACTS).forEach(doc => doc.listenToChildren()))
                            return project.getChildren(CONTRACT_SECTIONS, {
                                include: {
                                    calculationMethod: "apartments",
                                    workspacePath: doc => {
                                        const wsBuildings = doc.getWorkspaceBuildings()
                                        if (wsBuildings.length === 0) return true
                                        else return wsBuildings.includes(building.docData.ref)
                                    },
                                },
                            }, { subChild: true })
                        }
                        return []
                    },
                    model: SectionDoc,
                    addOnFlow: false,
                    formHeaders: ["title"],
                    params: ["^title", "title"]
                }
            },
            apartmentModel: {
                label: "דגם דירה",
                type: LIST,
                options: {
                    getData: (doc) => {
                        const building = doc.getBuilding()
                        return building.getChildren(APARTMENT_MODELS)
                    },
                    model: ApartmentModelDoc,
                    addOnFlow: true,
                    addDataOptions: doc => {
                        const building = doc.getBuilding()
                        return {
                            colRef: `${building.docData.ref}/${APARTMENT_MODELS}`
                        }
                    },
                    formHeaders: ["title", "description"],
                    params: ["title"]
                }

            },
            buildingFloorPath: { label: "מבנה-קומה", type: DIRECTORY, defaultValue: "" },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
        }
    }
}