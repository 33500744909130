import m from 'mithril'
import { getDisplayValue } from '../../../data/utils/inputValidation'
import { CURRENCY, NUMBER } from '../../../utils/constants/types'
import { PercentClock } from '../../components/percentClock/PercentClock'
import { Tooltip } from '../../components/tooltip/Tooltip'
import { Card } from '../cardLayout/Card'


const defaultlColors = ["#ac0", "#d44", "#fc3", "#33f", "#900", "#f0f"]
export const ColumnChart = (node) => {

    const normalizeNumbers = defaultMax => {
        const parts = parseFloat(defaultMax) / 10
        const min = increaseDigitNumber(parts)
        const max = min * 10
        return { min, max }
    }
    const increaseDigitNumber = number => {
        let result = ""
        const strN = parseInt(number).toString()
        strN.split("").forEach((d, ind) => {
            if (ind === 0) {
                result += `${parseInt(d) + 1}`
            } else {
                result += 0
            }
        });
        return parseInt(result)
    }
    const toggleHover = (key, active) => {
        if (active) {
            node.state.activeHover = key
        } else {
            node.state.activeHover = false
        }
    }

    return {
        oninit: vnode => {
            const { data } = vnode.attrs
            vnode.state.colors = data.length > defaultlColors.length ?
                data.map((_, ind) => {
                    let mainColor, lightColor
                    if (ind > defaultlColors.length - 1) mainColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                    else mainColor = defaultlColors[ind]
                    lightColor = mainColor.replace(/^(#[a-zA-Z\d]{3,6})([a-zA-Z\d]{0,2})/, "$180")
                    return { mainColor, lightColor }
                }) :
                [...defaultlColors].map(color => {
                    return {
                        mainColor: color,
                        lightColor: color.replace(/^(#[a-zA-Z\d]{3,6})([a-zA-Z\d]{0,2})/, "$18")
                    }
                })
        },
        view: (vnode) => {
            const { title = "סיכום", data = [], valueType = NUMBER } = vnode.attrs
            const { colors } = vnode.state
            const doneTotal = data.reduce((acc, { done }) => acc + parseFloat(done), 0)
            // const subTotal = data.reduce((acc, { total }) => acc + parseFloat(total), 0)
            const { max, min } = normalizeNumbers(data.reduce((acc, { total }) => {
                if (parseFloat(total) > acc) return total
                else return acc
            }, 0))
            return m(Card, { class: "chart" },
                title && m(".caption", title),
                m(".chart__captions",
                    data.map((el, ind) => {
                        return m(".caption", {
                            style: `grid-row:${1 + ind}`,
                            onmouseenter: () => toggleHover(el.key, true),
                            onmouseleave: () => toggleHover(el.key, false)
                        },
                            m(PercentClock, {
                                fill: colors[ind].lightColor,
                                stroke: colors[ind].mainColor,
                                text: "בוצע",
                                value: parseInt(parseFloat(Number(el.done) / Number(el.total)) * 100)
                            }),
                            // m(".caption__color", { style: `background-color:${colors[ind].mainColor};` }),
                            m(".caption__text", el.title),
                            m(".caption__value",
                                m("span.done", getDisplayValue(el.done, valueType)),
                                m("span.total", getDisplayValue(el.total, valueType)),
                            ),
                        )
                    })
                ),
                m(".columnChart",
                    m(".columnChart__min", getDisplayValue(min, valueType)),
                    m(".columnChart__max", getDisplayValue(max, valueType)),
                    data.map((el, ind) => {
                        const { mainColor, lightColor } = colors[ind]
                        return m('.columnChart__col', {
                            key: ind,
                            "data-hover": node.state.activeHover === el.key,
                            "data-tooltip": true,
                            "data-total": el.total,
                            "data-done": el.done,
                            "data-percent-done": parseFloat(el.done / el.total) * 100,
                            "data-percent-total": parseFloat(el.total / max) * 100,
                            style: `
                                height:${parseInt(parseFloat(el.total / max) * 30)}rem;
                                ${(el.total && el.done) ?
                                    `background-image:linear-gradient(to top, ${mainColor} ${parseInt(parseFloat(Number(el.done) / Number(el.total)) * 100)}% , ${lightColor} 0);background-repeat: no-repeat;` :
                                    `background-color:${mainColor};`}
                                `
                        },
                            m(Tooltip, { text: `${el.title}:\n${getDisplayValue(el.total, valueType)}` }),
                        )
                    })
                ),
                // (subTotal && !isNaN(subTotal)) && m(".total", {
                //     style: `grid-area: none / 1 / none / -1;
                //     justify-self: center;
                //     width: 100%;
                //     font-weight: 400;
                //     background-color: var(--color-bg-layout);
                //     color: var(--color-active);
                //     border-top: 1px solid var(--color-active);
                //     padding: 1rem;
                //     display: flex;
                //     gap: 1rem;
                //     align-items: center;
                //     justify-content: center;`
                // },
                //     m(".", `:סה"כ`),
                //     m(".", { style: "font-size:1.6rem" }, getDisplayValue(doneTotal, CURRENCY)),
                //     m(".", "/"),
                //     m(".", { style: "font-weight:600" }, getDisplayValue(subTotal, CURRENCY)),
                // ),
            )
        }
    }
}