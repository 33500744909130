import m from "mithril"

//DATA
import { CURRENCY, PERCENT } from "../../../../utils/constants/types"
import { CONTRACT_ACCOUNTS, CONTRACT_ADDITIONS, FILES, USERS } from "../../../../data/dictionary/routeNames"
import { DEFAULT_ACTION_NOT_AVILABLE } from "../../../../data/store/settingsStore"
import { REMOVE, UPDATE } from "../../../../data/store/permissionStore"

//FUNCTIONS
import { editValue, getDisplayValue } from "../../../../data/utils/inputValidation"
import { setInputValidaty } from "../../../commons/inputCell/utils"
import { preventDefaults, uuid } from "../../../../utils/js"
import { isUserAllow } from "../../../../data/utils/permission"
import { handleConfirmStage, handleStartPeriod, sectionHasActualChanges, showTotalSectionActual } from "../utils"

//COMPONENTS
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { Prompt } from "../../../commons/prompt/Prompt"
import { Card } from "../../../commons/cardLayout/Card"
import { DisplayCell } from "../../../commons/inputCell/DisplayCell"
import { Tooltip } from "../../../components/tooltip/Tooltip"
import { Nav, setActiveTab } from "../../../commons/nav/Nav"
import { MilestoneUnitActuals } from "./MilestoneUnitActuals"
import { Calculator } from "../../calculator/Calculator"
import { Icon } from "../../../components/icon/Icon"
import { FilesUpload } from "../../../commons/fileUpload/FilesUpload"
import { User } from "../../userPage/UserPage"
import { Users } from "../../users/Users"
import { dataStore } from "../../../../data/store/dataStore"
import { userRoles, USER_ROLES } from "../../../../utils/constants/userRoles"


export const AdditionSection = node => {

    const handleRemoveAddition = () => {
        const { doc: section, currentAccount } = node.attrs
        const totalValue = section.calcTotalActualsValue()
        debugger
        if (totalValue !== 0) return node.state.prompt = {
            title: "שגיאה",
            msg: `לא ניתן להסיר סעיף ביצוע הכולל סכום. \n הסעיף כולל ביצוע בסך ${getDisplayValue(totalValue, CURRENCY)}\n אפס את סכום הביצוע ונסה שנית`
        }

        const accountRef = currentAccount.docData.ref
        let str = ""
        str += `האם ברצונך להסיר את סעיף ביצוע "${section.docData.title}"\n`
        const actuals = section.getActuals(accountRef)
        if (actuals.length > 0) {
            str += "האם ברצונך להסיר את דיווח ביצוע :\n"
            actuals.forEach(doc => str += `"${doc.docData.title}" - ${doc.docData.value}%\n`)
        }
        const files = section.getFiles()
        if (files.length > 0) {
            str += "האם ברצונך להסיר את הקבצים הבאים :\n"
            files.forEach(doc => str += `"${doc.docData.title}"`)
        }
        node.state.prompt = {
            title: "מחיקת סעיף ביצוע",
            msg: str,
            actions: [{
                text: "הסר הכל",
                color: "red",
                action: async e => {
                    try {
                        for (const doc of actuals) {

                            await doc.remove()
                        }
                        for (const doc of files) {
                            await doc.remove()
                        }
                        await section.remove()
                        node.state.prompt = false
                        closeSection()
                        m.redraw()
                    } catch (err) {
                        node.state.prompt = false
                        console.error(err);
                        node.state.snackbar = { msg: err, isError: true }
                    }
                }
            }]
        }
        // node.state.snackbar = { msg: DEFAULT_ACTION_NOT_AVILABLE, isError: true }
    }
    const abortActualAdditionChanges = () => {
        const { doc: section, currentAccount } = node.attrs
        section.getActuals({ accountRef: currentAccount.docData.ref }).forEach(actualDoc => {
            if (actualDoc.hasChanges()) {
                actualDoc.abortChanges()
            };
        })
        if (section.hasChanges()) section.abortChanges()
        m.redraw()
    }

    const confirmActualAdditionChanges = () => {
        const { doc, currentAccount, Model } = node.attrs
        const accountRef = currentAccount.docData.ref
        const actuals = doc.getActuals({ accountRef })
        const total = doc.calcTotalActuals({ forceCalc: true })
        const isAddition = Model.meta.id === CONTRACT_ADDITIONS
        let msg
        if (isAddition) {
            msg = `האם לעדכן את סעיף התוספת וסכום הביצוע ל${getDisplayValue(total, CURRENCY)}?`
        } else {
            msg = `האם לעדכן את סעיף הקיזוז ל ( - ${getDisplayValue(total, CURRENCY)} )?`
        }
        node.state.prompt = {
            title: "שמירת שינויים",
            msg,
            actions: [
                {
                    action: e => {
                        //TODO: check if valid total (more then 0% + less then 100% + less then section totalSum)
                        const isValidTotal = true
                        if (isValidTotal) {
                            node.state.snackbar = { msg: "שומר..." };
                            try {
                                new Promise(resolve => {
                                    resolve(doc.save())
                                })
                                    .then(() => {
                                        actuals.forEach(actualDoc => {
                                            if (Object.keys(actualDoc.docChanges).length > 0) actualDoc.save();
                                        })
                                    })
                                    .then(() => {
                                        node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה!" };
                                    })
                            } catch (err) {
                                node.state.snackbar = { msg: `אירעה שגיאה במהלך השמירה, תיאור: ${err}`, isError: true };
                            }
                        } else {
                            node.state.snackbar = { msg: "הסכום לא מסתדר לי", isError: true };
                        }
                        node.state.prompt = false
                        m.redraw()
                    },
                    text: "שמור"
                }
            ]
        }
    }
    const toggleActivePromt = (key, stateKey, navArray) => {
        node.state[stateKey] = key;
        setActiveTab(navArray, key)
    }

    const NAV_CONTRACT_VIEW = {
        SECTIONS: "sections",
        PERIODS: "periods"
    }
    const NAV_SECTION_VIEW = {
        CUMULATIVE: "cumulative",
        PERIOD: "period"
    }
    const isView = (viewKey) => {
        if (node.state.navSectionView) {
            return node.state.navSectionView === viewKey
        } else {
            return viewKey === NAV_SECTION_VIEW.CUMULATIVE
        }
    }
    const navSectionView = [
        {
            key: NAV_SECTION_VIEW.CUMULATIVE,
            label: "מצטבר",
            active: true,
            action: ({ e }) => toggleActivePromt(NAV_SECTION_VIEW.CUMULATIVE, "navSectionView", navSectionView)
        },
        {
            key: NAV_SECTION_VIEW.PERIOD,
            label: "תקופות",
            active: false,
            action: ({ e }) => toggleActivePromt(NAV_SECTION_VIEW.PERIOD, "navSectionView", navSectionView)
        },
    ]

    const navPromptActual = [
        {
            key: "calculator",
            label: "מחשבון",
            active: true,
            action: ({ e }) => toggleActivePromt("calculator", "activePromptTab", navPromptActual)
        },
        {
            key: "history",
            label: "הסטורייה",
            active: false,
            action: ({ e }) => toggleActivePromt("history", "activePromptTab", navPromptActual)
        },
    ]

    const promptActual = (e) => {
        const { doc: section, currentAccount } = node.attrs
        const accountRef = currentAccount.docData.ref
        preventDefaults(e)
        node.state.prompt = {
            title: "ביצוע יחידה",
            form: () => {
                return [
                    m(Nav, { class: "", nav: navPromptActual }),
                    (node.state.activePromptTab === undefined || node.state.activePromptTab === "calculator") && m(Calculator, { parent: node }),
                    (node.state.activePromptTab === "history") && m(MilestoneUnitActuals, { milestone: section, accountRef })
                ]
            }
        }
    }

    const closeSection = () => {
        const { parent } = node.attrs
        if (parent) parent.state.prompt = false
    }

    const sectionHeaders = [
        "title",
        "createdBy",
        "index",
        "description",
        "amountType",
        "itemsCount",
        "itemPrice",
        "totalSum",
    ]
    return {
        prompt: false,
        snackbar: false,
        view: vnode => {
            const { doc: section, currentAccount, contract, Model } = vnode.attrs
            let donePercentage = section.docData.donePercentage
            if (isView(NAV_SECTION_VIEW.PERIOD)) {
                donePercentage = 0
            }
            const accountRef = currentAccount.docData.ref
            const isStart = currentAccount.docData.stage === "start"
            const isFinish = currentAccount.docData.stage === "finish"
            const isAccounting = currentAccount.getCurrentStage().stage.type && currentAccount.getCurrentStage().stage.type === "accounting"
            const currentActiveAccount = contract.getActiveAccount()
            const isOtherMonth = currentActiveAccount && currentActiveAccount.docData.month !== currentAccount.docData.month
            const isCurrentMonth = accountRef === accountRef
            const isUserAllowEdit = isUserAllow(Model.meta.id, UPDATE, currentAccount)
            const isAllowEdit = (!isStart && !isOtherMonth && !isFinish && isCurrentMonth && isUserAllowEdit && !isAccounting) ||
                 (dataStore[USERS].current && dataStore[USERS].current.docData.role === USER_ROLES.ACCOUNTS_OPERATIONS && !isFinish)

            return m(Card, {
                key: section.docData.ref,
                class: `section fancy sectionActual`,
                id: section.docData.docID,
                autofocus: "autofocus",
                tabindex: 1
            },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode, class: vnode.state.prompt.class || "" },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    vnode.state.prompt.msg && m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),
                isAccounting && m(".danger", { style: "grid-column:1/-1" }, "חשבון בשלב הנהלת חשבונות לא ניתן לערוך חשבון"),
                isStart && m(".danger", { style: "grid-column:1/-1", onclick: e => handleStartPeriod(vnode, vnode.attrs.currentAccount) }, "לא ניתן לערוך חשבון , יש לאתחל חשבון תחילה"),
                isFinish && m(".danger", { style: "grid-column:1/-1" }, "לא ניתן לערוך חשבון , יש לסיים תקופה ולהתחיל תקופה חדשה"),
                isOtherMonth && m(".info", { style: "grid-column:1/-1" }, "חשבון לצפייה בלבד (תקופה קודמת)"),

                sectionHeaders.map((header, index) => {
                    return m(DisplayCell, {
                        value: section.docData[header],
                        field: section.headers[header],
                        doc: section,
                        header
                    })
                }),

                m(".caption caption--sub", "טבלת ביצוע"),

                //nav views
                !section.isNew && m(Nav, { class: "", nav: navSectionView }),

                //*******************************************************************/
                //PERIOD VIEW
                //*******************************************************************/

                (!section.isNew && isView(NAV_SECTION_VIEW.PERIOD)) && m(".section__milestones", {
                    style: `grid-template-columns: max-content 1fr 1fr 1fr`,
                    key: section.docData.ref,
                },
                    //headers
                    m(".section__milestone section__milestone--header", "תקופה"),
                    m(".section__milestone section__milestone--title", "אחוז ביצוע"),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ תקופה"),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"),

                    //period values
                    contract.getChildren(CONTRACT_ACCOUNTS).map((account, ind, arr) => {
                        const currentAccountRef = account.docData.ref
                        const currentActual = section.getTotalActuals(currentAccountRef) || 0
                        const percentDone = parseInt(currentActual)
                        const last = section.getLastActualsValue({ accountRef })
                        // const diffValue = parseFloat(currentActual - last)
                        return [
                            m(".section__milestone section__milestone--unit [dir=ltr]", account.docData.month),
                            isAllowEdit ?
                                m(".section__milestone.section__milestone--actual", {
                                    "data-label": "%",
                                    "data-tooltip": true,
                                    style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                },
                                    m(Tooltip, { text: `${section.docData.title}:\n${currentActual}` }),
                                    m("input.input__field", {
                                        key: section.docData.ref,
                                        type: "number",
                                        id: `${section.docData.ref}/${currentAccountRef}/value`,
                                        min: 0,
                                        max: 100 - parseInt(last),
                                        onupdate: el => el.dom.value = parseFloat(currentActual),
                                        oncreate: el => el.dom.value = parseFloat(currentActual),
                                        onfocus: e => e.target.oldVal = e.target.value,
                                        step: "any",
                                        oninput: e => {
                                            if (!isNaN(parseInt(e.target.value)) && e.target.value !== e.target.oldVal) {
                                                const actualDoc = section.getActualDocInAccount({ accountRef })
                                                const last = section.getLastActualsValue({ accountRef })
                                                const totalValue = parseFloat(e.target.value) + last
                                                // if (totalValue > 100) return false
                                                const { valid: validSection, errorMsgs: errorMsgsSection } = editValue(e.target.oldVal, totalValue, "donePercentage", section)
                                                const { valid, errorMsgs } = editValue(e.target.oldVal, e.target.value, "value", actualDoc)
                                                e.target.oldVal = e.target.value
                                                console.log(validSection, valid, [...errorMsgs, ...errorMsgsSection]);
                                                setInputValidaty(e.target, valid && validSection, [...errorMsgs, ...errorMsgsSection])
                                            }
                                        },
                                    }),
                                    m(Icon, { class: "prompt-btn", icon: "icon-dots-vertical", action: e => promptActual(e, section, false, currentAccountRef) })
                                ) :
                                //display only
                                m(".section__milestone.section__milestone--actual", {
                                    "data-tooltip": true,
                                    style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${percentDone}%, white 0);background-repeat: no-repeat;`
                                },
                                    m(Tooltip, { text: `${section.docData.title}:\n${currentActual}` }),
                                    getDisplayValue(currentActual, PERCENT, { isFloat: true })
                                ),

                            m(".section__milestone section__milestone--total section__milestone--columnTotal",
                                getDisplayValue(currentActual, PERCENT, { isFloat: true })
                            ), //currentTotal

                            m(".section__milestone section__milestone--total section__milestone--columnTotal",
                                getDisplayValue(section.docData.totalSum * (parseFloat(currentActual) / 100), CURRENCY),
                            ),//total
                        ]
                    }),

                    //total row
                    m(".section__milestone section__milestone--total", "סהכ"),
                    m(".section__milestone section__milestone--total", getDisplayValue(section.docData.totalSum * (parseFloat(section.docData.donePercentage) / 100), CURRENCY)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", getDisplayValue(section.docData.donePercentage, PERCENT)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", showTotalSectionActual(section)),
                ), //END PERIOD VIEW

                //*******************************************************************/
                //CUMULATIVE VIEW(DEFAULT)
                //*******************************************************************/

                (!section.isNew && isView(NAV_SECTION_VIEW.CUMULATIVE)) && m(".section__milestones", {
                    style: `grid-template-columns: max-content 1fr 1fr;`,
                    key: section.docData.ref,
                },
                    //headers
                    m(".section__milestone section__milestone--header", "יח"),
                    m(".section__milestone section__milestone--title", "אחוז ביצוע"),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", "סהכ"),

                    //row
                    m(".section__milestone section__milestone--unit", "*"),
                    m(".section__milestone.section__milestone--actual", {
                        "data-label": "%",
                        "data-tooltip": true,
                        style: `background-image: linear-gradient(90deg, var(--color-active-trs) ${donePercentage}%, white 0);background-repeat: no-repeat;`

                    },
                        m(Tooltip, { text: `% ביצוע:\n${getDisplayValue(donePercentage, PERCENT)}` }),
                        isAllowEdit ?
                            m("input.input__field", {
                                key: uuid(),
                                type: "number",
                                min: 0, max: 100,
                                onupdate: el => el.dom.value = donePercentage, // calc all cumulative actuals
                                oncreate: el => el.dom.value = donePercentage, // calc all cumulative actuals
                                onfocus: e => e.target.oldVal = e.target.value,
                                oninput: e => {
                                    if (!isNaN(parseInt(e.target.value)) && e.target.value !== e.target.oldVal) {
                                        const { valid: validTotal, errorMsgs: errors } = editValue(e.target.oldVal, parseFloat(e.target.value), "donePercentage", section)
                                        setInputValidaty(e.target, validTotal, errors)
                                        if (validTotal) {
                                            const actualDoc = section.getActualDocInAccount({ accountRef })
                                            const last = section.getLastActualsValue({ accountRef })
                                            const diffValue = parseFloat(e.target.value - last)
                                            // console.log(actualDoc, " lastTotal ", last, "diffValue: ", diffValue);
                                            const { valid, errorMsgs } = editValue(e.target.oldVal, diffValue, "value", actualDoc)
                                            e.target.oldVal = e.target.value
                                            setInputValidaty(e.target, valid, errorMsgs)
                                        }
                                    }
                                },
                            }) :
                            //display only
                            m(".input__field", getDisplayValue(donePercentage, PERCENT, { isFloat: true })),
                        m(Icon, { class: "prompt-btn", icon: "icon-dots-vertical", action: e => promptActual(e) }),
                    ),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal",
                        getDisplayValue(section.docData.donePercentage, PERCENT),
                    ),
                    //total row
                    m(".section__milestone section__milestone--total", "סהכ"),
                    m(".section__milestone section__milestone--total", getDisplayValue(section.docData.totalSum * (parseFloat(section.docData.donePercentage) / 100), CURRENCY)),
                    m(".section__milestone section__milestone--total section__milestone--columnTotal", showTotalSectionActual(section)),
                ),//END milestones 

                m(FilesUpload, {
                    addDataOptions: { status: "confirm", accountRef: currentAccount.docData.ref },
                    filterDataOptions: { include: { sectionRef: section.docData.ref } },
                    colRef: `${contract.docData.ref}/${FILES}`,
                    modelID: FILES,
                    doc: contract
                }),

                m(".formCard__actions",
                    sectionHasActualChanges(section, accountRef) && m("button.button formCard__save", { onclick: e => confirmActualAdditionChanges() }, "שמור שינויים"),
                    sectionHasActualChanges(section, accountRef) && m("button.button formCard__abort", { onclick: e => abortActualAdditionChanges() }, "בטל שינויים"),
                    isUserAllow(Model.meta.id, REMOVE, section) && m(".button.button button--red", { onclick: e => handleRemoveAddition() }, "מחק סעיף")
                ),
            )
        }
    }
}