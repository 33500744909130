import m from "mithril"

import { projectTypes, RESIDENTIAL } from "../../../utils/constants/projectTypes";
import { SWITCH, DATE, STRING, NUMBER, VALID_CALC, VALID_COMPARE, LIST, CURRENCY, PERCENT } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH, MIN, MAX, DISABLED } from "../../../utils/constants/inputAttrs";
import { PROJECTS, USERS, COMPANIES, CONTRACTS, SECTION_MILESTONES, GROUPS, WORKSPACES, CONTRACT_SECTIONS, BUDGET_ITEMS, APARTMENTS } from "../../dictionary/routeNames";
import { userRoles, USER_ROLES } from "../../../utils/constants/userRoles"

import { addDays } from "../../../utils/js";
import { DocModel } from "../docModel";
import { dataStore } from "../dataStore";
import { statusTypes } from "../../../utils/constants/statusTypes";
import { UserDoc } from "../users/userClass";
import { CompanyDoc } from "../companies/companyClass";
import { auth, db } from "../../../index";
import { buildCloneDBDoc } from "../../CRUD/utils";
import { PROJECT_BUDGET } from "../../dictionary/viewOptions";
import { apiBaseUrl, apiBaseUrl_DEV } from "../../../firebase/api";


export class ProjectDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: ProjectDoc,
        });
        if (!isNew) {
            this.listenToChildren()
        }
    };

    //TODO:
    async cloneBudget() {
        try {
            const budget = [...dataStore[BUDGET_ITEMS].data]
            const batch = db.batch()
            const colRef = `${this.docData.ref}/${PROJECT_BUDGET}`
            budget.forEach(async doc => {
                const docRef = `${colRef}`
            })
            await batch.commit()
        } catch (err) {

        } finally {
            m.redraw()
        }
    }
    async updateTotalApartments() {
        try {
            const allDocs = (await db.doc(this.docData.ref).collection(APARTMENTS).where("isActive", "==", true).get()).docs
            this.saveLocal({ numberOfApatrments: allDocs.length })
            await Promise.resolve(this.save())
            m.redraw()
        } catch (err) {
            console.error("Error on Project.updateTotalApartments() ", err)
        }
    }
    async setTotal(batch) {
        try {
            const url = `${apiBaseUrl}/v1/projects/totals`
            console.log(`POST API-v1 ${url}`);
            const apiHeaders = {
                "Authorization": `Bearer ${await auth.currentUser.getIdToken()}`,
                "Content-Type": "application/json",
            }
            const requestOptions = {
                method: 'POST',
                headers: apiHeaders,
                body: JSON.stringify({
                    "where": {
                        "project": this.docData.ref
                    }
                })
            };
            const response = await fetch(url, requestOptions)
            const result = await response.json()
            let subTotal = 0, donePercentage = 0
            console.log(result)
            subTotal = result.pivotData.projects[this.docData.ref].subTotalFinish
            donePercentage = subTotal / parseFloat(this.docData.totalSum) * 100
            if (batch) {
                return batch.update(db.doc(this.docData.ref), { donePercentage, subTotal })
            } else {
                this.saveLocal({ donePercentage, subTotal })
                await Promise.resolve(this.save())
            }
        } catch (error) {
            console.error(err);
            throw Error(err)
        }
        // let subTotal = 0
        // //TODO: test if listen to accounts on project-contracts [else] get from DB
        // this.getChildren(CONTRACT_ACCOUNTS, { include: { stage: "finish", } }, { subChild: true }).forEach(doc => {
        //     subTotal += parseFloat(doc.docData.subTotal)
        // })
        // return subTotal
    }

    getProjectType(param) {
        const projectType = projectTypes.find(p => p.id === this.docData.projectType)
        if (param) {
            if (projectType) return projectType[param] || ""
            else return ''
        }
        return projectType
    }

    async duplicate() {
        try {
            const url = `${apiBaseUrl}/v1/projects/totals`
            // const url = `${apiBaseUrl_DEV}/v1/projects/copy`
            console.log(`POST API-v1 ${url}`);
            const apiHeaders = {
                "Authorization": `Bearer ${await auth.currentUser.getIdToken()}`,
                "Content-Type": "application/json",
            }
            const requestOptions = {
                method: 'POST',
                headers: apiHeaders,
                body: JSON.stringify({
                    "docData": this.docData,
                    "addDataOptions": { title: `עותק של ${this.docData.title}` }
                })
            };
            const response = await fetch(url, requestOptions)
            const result = await response.json();
            console.log(result);
        } catch (err) {
            console.error(err);
        }
    }

    async toJSON({ clone } = {}) {
        const json = {}
        const cloneProject = this.cloneDocData({ clone, resets: { donePercentage: 0, subTotal: 0 } })
        json.data = cloneProject
        json.children = {}

        const contracts = []
        const workspaces = []
        const groups = []
        const sections = []
        const milestones = []

        // GET contracts
        const contractsCollection = (await db.collection(`${this.docData.ref}/${CONTRACTS}`).get()).docs
        const contractsColRef = `${cloneProject.ref}/${CONTRACTS}`
        //TODO: change to for..of
        for (const contract of contractsCollection) {
            const cloneContract = buildCloneDBDoc(contractsColRef, contract, {
                status: "plan",
                currentAccount: {},
                subTotalContract: 0,
                totalSection: 0,
                totalAdditions: 0,
                totalDelay: 0,
                totalSubtraction: 0,
            })
            contracts.push(cloneContract)
            // GET workspaces
            const workspacesColRef = `${cloneContract.ref}/${WORKSPACES}`
            const workspacesCollection = (await db.collection(`${contract.ref.path}/${WORKSPACES}`).get()).docs
            for (const ws of workspacesCollection) {
                const cloneWs = buildCloneDBDoc(workspacesColRef, ws)
                workspaces.push(cloneWs)
                // GET groups
                const groupsColRef = `${cloneWs.ref}/${GROUPS}`
                const groupsCollection = (await db.collection(`${ws.ref.path}/${GROUPS}`).get()).docs
                for (const group of groupsCollection) {
                    const cloneGroup = buildCloneDBDoc(groupsColRef, group)
                    groups.push(cloneGroup)
                    // GET sections
                    const sectionsColRef = `${cloneContract.ref}/${CONTRACT_SECTIONS}`
                    const sectionsCollection = (await db.collection(`${contract.ref.path}/${CONTRACT_SECTIONS}`).get()).docs
                    for (const section of sectionsCollection) {
                        const cloneSection = buildCloneDBDoc(sectionsColRef, section, { subTotal: 0 })
                        cloneSection.workspacePath = `workspaces/${cloneWs.docID}/groups/${cloneGroup.docID}`
                        sections.push(cloneSection)
                        // GET milestones
                        const milestonesColRef = `${cloneSection.ref}/${SECTION_MILESTONES}`
                        const msCollection = (await db.collection(`${section.ref.path}/${SECTION_MILESTONES}`).get()).docs
                        msCollection.forEach(ms => milestones.push(buildCloneDBDoc(milestonesColRef, ms)))
                    }
                }
            }
        }
        json.children = { [CONTRACTS]: contracts, [WORKSPACES]: workspaces, [GROUPS]: groups, [CONTRACT_SECTIONS]: sections, [SECTION_MILESTONES]: milestones }
        return json
    }

    static get meta() {
        return {
            id: PROJECTS,
            routes: {
                collection: "/projects",
                doc: "/projects/:projectID",
            },
            logic: [
                { type: VALID_CALC, target: "eDate", expression: "func:addMonths(numberOfPeriods,sDate)", trigger: ["numberOfPeriods"] },
                { type: VALID_CALC, target: "numberOfPeriods", expression: "func:monthDiff(sDate,eDate)", trigger: ["sDate", "eDate"] },
                // { type: VALID_UNIQUE, expression: "title", trigger: ["title"], msg: "ערך כבר קיים , שדה זה הינו ייחודי!" },
                { type: VALID_COMPARE, expression: "sDate < eDate", trigger: ["sDate", "eDate"], msg: "תאריך ההתחלה חייב להיות לפני תאריך הסיום!!!" }
            ],
            // formGroups: [
            //     { fields: ["title", "projectType"] },
            //     { fields: ["address"] },
            //     { fields: ["sDate", "eDate"], data: { label: "תקופה", expression: `${DATEDIF}(sDate,eDate)` } },
            //     { fields: ["description"] },
            // ]
        }
    }
    static get headers() {
        return {
            title: { label: "פרוייקט", defaultValue: "--פרוייקט חדש--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            address: { label: 'מיקום', defaultValue: "--ללא כתובת--", },
            sDate: { label: "תחילת פרוייקט", defaultValue: new Date().toISOString(), type: DATE },
            numberOfPeriods: { label: 'מס תקופות מתוכנן', defaultValue: 12, type: NUMBER, props: [{ [MIN]: 6 }, { [MAX]: 100 }] },
            eDate: { label: "סיום מתוכנן", defaultValue: addDays(new Date(), 365).toISOString(), type: DATE },
            description: { label: 'תאור הפרוייקט', defaultValue: "", },
            manager: {
                label: 'מנהל ביצוע',
                defaultValue: null,
                type: LIST,
                options: {
                    data: dataStore[USERS].data.filter(user => user.docData.role === USER_ROLES.AREA_MANAGER || user.docData.role === USER_ROLES.PROJECT_MANAGER),
                    load: (path) => dataStore[USERS].listen(path),
                    model: UserDoc,
                    addOnFlow: false,
                    formHeaders: ["title", "displayName", "email", "phone"]
                    // param:"title"
                }
            },
            seniorManager: {
                label: 'מנהל פרוייקט בכיר',
                defaultValue: null,
                type: LIST,
                options: {
                    data: dataStore[USERS].data.filter(user => user.docData.role === USER_ROLES.AREA_MANAGER || user.docData.role === USER_ROLES.PROJECT_MANAGER),
                    load: (path) => dataStore[USERS].listen(path),
                    model: UserDoc,
                    addOnFlow: false,
                    formHeaders: ["title", "displayName", "email", "phone"]
                    // param:"title"
                }
            },
            entrepreneur: {
                label: 'חברה יזמית',
                defaultValue: null,
                type: LIST,
                options: {
                    data: dataStore[COMPANIES].data,
                    load: () => dataStore[COMPANIES].listen(),
                    model: CompanyDoc,
                    addOnFlow: false,
                    formHeaders: ["title", "commercialName", "companyNumber", "companyType", "phone", "email", "taxesEndDate", "address"]
                    // param:"title"
                }
            },
            executor: {
                label: 'חברה מבצעת',
                defaultValue: null,
                type: LIST,
                options: {
                    data: dataStore[COMPANIES].data,
                    load: () => dataStore[COMPANIES].listen(),
                    model: CompanyDoc,
                    addOnFlow: false,
                    formHeaders: ["title", "commercialName", "companyNumber", "companyType", "phone", "email", "taxesEndDate", "address"]
                    // param:"title"
                }
            },
            numberOfBuildings: { label: 'מספר בניינים', defaultValue: 1, type: NUMBER, props: [{ [MIN]: 1 }, { [MAX]: 20 }] },
            basements: { label: 'מרתפים', defaultValue: 0, type: NUMBER, props: [{ [MIN]: 0 }, { [MAX]: 10 }] },
            numberOfApatrments: { label: 'מספר דירות', defaultValue: 0, type: NUMBER, props: [{ [MIN]: 0 }, { [MAX]: 10000 }] },
            projectType: { label: "סוג פרוייקט", defaultValue: RESIDENTIAL, type: SWITCH, options: projectTypes, fieldOptions: { search: false } },
            status: { label: 'סטטוס', defaultValue: "active", type: SWITCH, options: statusTypes, fieldOptions: { search: false } },
            totalSum: { label: "סכום הסכם", defatulValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },
            subTotal: { label: "סכום מצטבר מאושר", defatulValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },
            donePercentage: { label: 'הושלם', defaultValue: 0, type: PERCENT, props: [{ [DISABLED]: true }] },
        }
    }
}