import m from 'mithril';

//COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';

// DATA
import { Prompt } from '../../commons/prompt/Prompt';
import { Icon } from '../../components/icon/Icon';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { projectTypes } from '../../../utils/constants/projectTypes';
import { isUserAllow } from '../../../data/utils/permission';
import { PROJECTS, USERS } from '../../../data/dictionary/routeNames';
import { READ } from '../../../data/store/permissionStore';
import { dataStore } from '../../../data/store/dataStore';
import { logRouteChangeUser } from '../../../data/utils/utils';

// FUNCTIONS
export const Splash = (node) => {

    return {
        snackbar: false,
        prompt: false,
        load: true,
        oninit: async vnode => {
            window.scrollTo(0, 0);
            console.log("routeChange", m.route.get())
            sessionStorage.setItem('lastURL', m.route.get());
            await logRouteChangeUser( "checkIn");
        },
        // onbeforeremove: vnode => {
        //     return logRouteChangeUser( "checkOut");
        // },
        onremove: vnode => {
            console.log("remove '/app'")
            return logRouteChangeUser("checkOut");
        },
        view: (vnode) => {
            return m(PageLayout, { class: "splash", title: "ראשי" },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    m("button.button prompt__action", { onclick: e => vnode.state.prompt.action(e) }, vnode.state.prompt.actionText || "כן"),
                ),
                m(".splash__nav",
                    dataStore[USERS].current && projectTypes.map(_projectType => {
                        if (!isUserAllow(PROJECTS, READ, { docData: { projectType: _projectType.id } })) return
                        return m(".splash__elem", { onclick: e => m.route.set("/app/projects", { projectType: _projectType.id }) }, m(Icon, { icon: `icon-${_projectType.id}` }), _projectType.text)
                    })
                )
            )
        }
    }
}