export const USER_ROLES = {
    "SYSTEM": "system",
    "OWNER": "owner",
    "CEO_OPERATIONS": "ceoOperations",
    "CEO_FINANCE": "ceoFinance",
    "COMPANY_ENGINEER": "companyEngineer",
    "BUDGETER": "budgeter",
    "SECRETARY": "secretary",
    "ACCOUNTS_OPERATIONS": "accountsOperations",
    "ACCOUNTS_ENTREPRENEURSHIP": "accountsEntrepreneurship",
    "ACCOUNTS": "accounts",
    "AREA_MANAGER": "areaManager",
    "PROJECT_MANAGER": "projectManager",
    "SITE_MANAGER": "siteManager",
    "TENANT_CHANGES_MANAGER": "tenantChangesManager",
    "TENANT_CHANGES_COORDINATOR": "tenantChangesCoordinator",
    "INHOUSE_TENANT_CHANGES_COORDINATOR": "inhouseTenantChangesCoordinator",
    //רכש , הנהח חיצוני , מנהל תיאום תכנון חיצוני
    "PROCUREMENT": "procurement",
    "ACCOUNTS_OUTSOURCE": "accountsOutsource",
    "OUTSOURCE_COORDINATOR": "outsourceCoordinator",

}
export const userRoles = [
    {
        text: 'מערכת',
        id: USER_ROLES.SYSTEM,
    },
    {
        text: 'יו"ר',
        id: USER_ROLES.OWNER,
    },
    {
        text: "מנכל משותף - תפעול",
        id: USER_ROLES.CEO_OPERATIONS,
    },
    {
        text: "מנכל משותף - כספים",
        id: USER_ROLES.CEO_FINANCE,
    },
    {
        text: "מהנדס חברה",
        id: USER_ROLES.COMPANY_ENGINEER,
    },
    {
        text: "חשבת",
        id: USER_ROLES.BUDGETER,
    },
    {
        text: "מזכירה",
        id: USER_ROLES.SECRETARY,
    },
    {
        text: "הנהלת חשבונות- ביצוע",
        id: USER_ROLES.ACCOUNTS_OPERATIONS,
    },
    {
        text: "הנהלת חשבונות- יזמות",
        id: USER_ROLES.ACCOUNTS_ENTREPRENEURSHIP,
    },
    {
        text: "הנהלת חשבונות",
        id: USER_ROLES.ACCOUNTS,
    },
    {
        text: "מנהל פרוייקט בכיר",
        id: USER_ROLES.AREA_MANAGER,
    },
    {
        text: "מנהל ביצוע",
        id: USER_ROLES.PROJECT_MANAGER,
    },
    {
        text: "מנהל עבודה",
        id: USER_ROLES.SITE_MANAGER,
    },
    {
        text: "מנהל/ת שינויי דיירים",
        id: USER_ROLES.TENANT_CHANGES_MANAGER,
    },
    {
        text: "מתאמ/ת שינויי דיירים",
        id: USER_ROLES.TENANT_CHANGES_COORDINATOR,
    },
    {
        text: "מתאמ/ת תכנון פנימי",
        id: USER_ROLES.INHOUSE_TENANT_CHANGES_COORDINATOR,
    },
    {
        text: "רכש",
        id: USER_ROLES,
    },
    {
        text: "מנהל תיאום תכנון חיצוני",
        id: USER_ROLES.OUTSOURCE_COORDINATOR,
    },
    {
        text: "הנהלת חשבונות חיצוני",
        id: USER_ROLES.ACCOUNTS_OUTSOURCE,
    },
]