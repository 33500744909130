import m from "mithril"
import { Icon } from "../../components/icon/Icon"

export const IconError = node => {
    function toggleShow() {
        node.state.showError = !node.state.showError
    }
    return {
        showError: false,
        view: vnode => {
            return vnode.attrs.error ?
                m(".errorFlag",
                    m(Icon, {
                        class: "errorFlag__icon-error",
                        icon: "icon-alert",
                        action: e => toggleShow()
                    }),
                    vnode.state.showError === true && m(".errorFlag__msg", m("span.errorFlag__close", { onclick: e => toggleShow() }, "x"), vnode.attrs.error)
                ) : null
        }
    }
}