import m from 'mithril'

//DATA
import { O_FUNCTION, O_STRING } from '../../../utils/constants/objTypes'
import { REG_URL_LAST_PART } from '../../../utils/constants/regex'

//FUNCTIONS
import { objType, preventDefaults } from '../../../utils/js'

//COMPONENTS
import { IconButton } from '../../components/icon/IconButton'
import { BreadCrumbs } from '../breadCrumbs/BreadCrumbs'

/**
 * @param {any} viewOptions {breadCrumbs=true}
 * @param {any} actions {print:Boolean = false , back:String|Function|undefind = undefind}
 */
export const FullPageLayout = (node) => {
    const backAction = e => {
        preventDefaults(e)
        const { back } = node.attrs
        if (back) {
            if (objType(back) === O_FUNCTION) back()
            else if (objType(back) === O_STRING) m.route.set(back)
            else throw "back action type not match Function || String"
        } else {
            m.route.set(m.route.get().replace(REG_URL_LAST_PART, ""))
        }
    }
    return {
        view: (vnode) => {
            const { viewOptions = {}, actions = {} } = vnode.attrs
            const { breadCrumbs = true, arrowBack = true } = viewOptions
            const { print = false } = actions
            return m('div.layout--fullPage',
                m('.page.show', { style: "grid-template-columns: auto;" },
                    m('main.content fullPage', { class: vnode.attrs.class || "" },
                        m(".fullPage__title",
                            breadCrumbs && m(BreadCrumbs),
                            arrowBack && m(IconButton, { class: "arrow", icon: "icon-right", action: e => backAction(e) }),
                            m(".heading heading--1", vnode.attrs.title || ""),
                            print && m(Icon, { icon: "icon-print", action: e => window.print() })
                        ),
                        vnode.children
                    ),
                ),
            )
        }
    }
}
