import m from 'mithril';

// COMPONENTS
import { PageLayout } from '../../commons/pageLayout/Page';
import { Prompt } from '../../commons/prompt/Prompt';
import { Snackbar } from '../../components/snackbar/Snackbar';
import { Card } from '../../commons/cardLayout/Card';
import { InlineLoaderDots } from '../../components/inlineLoaderDots/InlineLoaderDots';
import { InputCell } from '../../commons/inputCell/InputCell';

//DATA
import { BUDGET_CHAPTERS, BUDGET_ITEMS } from '../../../data/dictionary/routeNames';
import { dataStore } from '../../../data/store/dataStore';
import { REG_URL_LAST_PART$NQ } from '../../../utils/constants/regex';
import { DisplayCell } from '../../commons/inputCell/DisplayCell';
import { isUserAllow } from '../../../data/utils/permission';
import { CREATE, REMOVE, UPDATE } from '../../../data/store/permissionStore';
import { BudgetDoc } from '../../../data/store/budget/budgetClass';
import { BudgetItemDoc } from '../../../data/store/budget/budgetitemClass';
import { FilterTable } from '../../commons/filterTable/FilterTable';
import { isDocMatchTerm, isFiltered } from '../../../data/utils/search';
import { getDisplayValue } from '../../../data/utils/inputValidation';
import { handleAdd, handleCopy, handleDelete } from '../../../data/utils/utils';
import { Icon } from '../../components/icon/Icon';
import { FormEditDoc } from '../../commons/prompt/FormEditDoc';
import { sortDocsBy } from '../../../utils/js';
import { Table } from '../../commons/table/Table';

// FUNCTIONS

export const BudgetChapter = node => {

    const findChapter = () => {
        const budgetChapter = [].concat(dataStore[BUDGET_CHAPTERS].data, dataStore[BUDGET_CHAPTERS].new).find(doc => doc.docData.docID === node.attrs.chapterID)
        if (budgetChapter) {
            node.state.budgetChapter = budgetChapter
        } else {
            const navToPath = m.route.get().replace(REG_URL_LAST_PART$NQ$NQ, "").replace(REG_URL_LAST_PART$NQ, "")
            m.route.set(navToPath);
            m.redraw()
        }
    }

    const confirmChanges = (e) => {
        e.target.disabled = true
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: "האם לשמור את השינויים שבוצעו?",
            action: async e => {
                node.state.snackbar = { msg: "שומר..." };
                await node.state.budgetChapter.save();
                e.target.disabled = false
                node.state.prompt = false
                node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" };
                m.redraw()
            },
            actionText: "שמור"
        }
    }

    const budgetHeaders = [
        "number",
        'title',
        "description",
    ]
    const budgetItemsHeaders = [
        "number",
        'title',
        "description",
    ]


    const isRemoved = () => node.state.budgetChapter && node.state.budgetChapter.isRemoved

    return {
        load: true,
        budgetHeaders: [...budgetHeaders],
        budgetItemsHeaders: [...budgetItemsHeaders],
        settings: {
            openEditDocForm: {
                formHeaders: ["number", "title", "description"]
            },
        },
        prompt: false,
        snackbar: false,
        oninit: vnode => {
            const { alreadyListen: isListenToParent } = dataStore[BUDGET_CHAPTERS].listen()
            // console.log("TODO:make sure that its listen");
            const { alreadyListen: isListenToChildren } = dataStore[BUDGET_ITEMS].listen(`settings/system/budgetChapters/${vnode.attrs.chapterID}`)
            if (isListenToParent) {
                findChapter()
                vnode.state.load = false;
                // console.log(dataStore[BUDGET_ITEMS].data);
                m.redraw()
            } else {
                vnode.state.snackbar = { msg: "טוען..." }
                setTimeout(() => {
                    findChapter()
                    vnode.state.load = false
                    m.redraw()
                }, 3000)

            }
        },
        view: vnode => {
            return (
                m(PageLayout, {
                    class: "budgetChapter",
                    title: "פרטי פרק תקציב"
                },
                    vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                    m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                        m(".prompt__title", vnode.state.prompt.title || ""),
                        m(".prompt__msg", vnode.state.prompt.msg || ""),
                        vnode.state.prompt.form && vnode.state.prompt.form(),
                        vnode.state.prompt.actions && m(".prompt__actions",
                            vnode.state.prompt.actions.map(({ action, color, text }) => {
                                return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                            })
                        )
                    ),
                    isRemoved() ? "פרק תקציב זה נמחק" : m(Card, { class: "formCard budgetChapter__details" },
                        m("h3.formCard__caption caption", "פרטי פרק תקציב"),
                        vnode.state.loadContact && m(InlineLoaderDots),
                        (vnode.state.budgetChapter && vnode.state.budgetChapter.isNew) && m(".formCard__flag flag flag--new", "לא נשמר עדיין"),
                        vnode.state.budgetChapter && vnode.state.budgetHeaders.map((header, index) => {
                            const value = vnode.state.budgetChapter.docData[header]
                            const field = BudgetDoc.headers[header]
                            if (isUserAllow(BUDGET_CHAPTERS, UPDATE)) {
                                return m(InputCell, {
                                    header, field, index, id: `chapter_${header}`,
                                    editCell: vnode.state.editCell,
                                    doc: vnode.state.budgetChapter,
                                    parent: vnode
                                })
                            } else {
                                return m(DisplayCell, { field, value, doc: vnode.state.budgetChapter, header })
                            }
                        }),
                        vnode.state.budgetChapter && Object.keys(vnode.state.budgetChapter.docChanges).length > 0 && m("button.button formCard__save", { onclick: e => confirmChanges(e) }, "שמור שינויים")
                    ),

                    (vnode.state.budgetChapter && !isRemoved() && !vnode.state.budgetChapter.isNew) && m(Table, {
                        title: `פרק תקציב - סעיפים`,
                        modelID: BUDGET_ITEMS,
                        colRef: `${vnode.state.budgetChapter.docData.ref}/${BUDGET_ITEMS}`,
                        doc: vnode.state.budgetChapter,
                        tableHeaders: vnode.state.budgetItemsHeaders,
                        searchOptions: ["title", "number", "description"],
                    }) //END budget table

                    // (vnode.state.budgetChapter && !isRemoved() && !vnode.state.budgetChapter.isNew) && m(Card, { class: "project__contracts tableCard" },
                    //     m(FilterTable, {
                    //         modelID: BUDGET_ITEMS,
                    //         searchTerm: vnode.state.searchTermBudgetItems,
                    //         filters: vnode.state.filterBudgetItems,
                    //         updateSearchTerm: term => vnode.state.searchTermBudgetItems = term,
                    //         // filterMap: []
                    //     }),
                    //     isUserAllow(BUDGET_ITEMS, CREATE) && m("button.button tableCard__add", { onclick: e => handleAdd(e, vnode, BudgetItemDoc, BUDGET_ITEMS, { parent: vnode.state.budgetChapter }) }, "הוסף סעיף"),
                    //     m("h3.caption tableCard__caption", "פרטי סעיפים"),
                    //     (vnode.state.loadContracts || !vnode.state.budgetChapter) ? m(InlineLoaderDots) :
                    //         [].concat(dataStore[BUDGET_ITEMS].data, dataStore[BUDGET_ITEMS].new).length === 0 ?
                    //             m(".", "לא נמצאו סעיפים , כדאי להתחיל להוסיף!")
                    //             :
                    //             m(".table",
                    //                 //HEADERS
                    //                 m('.table__headers table__row', { style: `grid-template-columns: repeat(${vnode.state.budgetItemsHeaders.length},1fr);` },
                    //                     vnode.state.budgetItemsHeaders.map(h => m('.table__cell', BudgetItemDoc.headers[h].label)),
                    //                 ),
                    //                 //DATA
                    //                 vnode.state.budgetChapter && vnode.state.budgetChapter.getChildren(BUDGET_ITEMS)
                    //                     .sort(sortDocsBy("index",{type:NUMBER}))
                    //                     .map((doc, index) => {
                    //                         if (isFiltered(vnode, doc, "filterBudgetItems") || !isDocMatchTerm(vnode, doc, ["title"], "searchTermBudgetItems")) return
                    //                         return m(".table__row", {
                    //                             class: doc.isNew ? "table__row--new" : "",
                    //                             style: `grid-template-columns: repeat(${vnode.state.budgetItemsHeaders.length},1fr);`,
                    //                             onclick: e => {
                    //                                 vnode.state.prompt = {
                    //                                     title: "עריכה",
                    //                                     class: "full-width",
                    //                                     form: () => {
                    //                                         return m(FormEditDoc, { doc, headers: vnode.state.budgetItemsHeaders, parent: vnode })
                    //                                     }
                    //                                 }
                    //                             }
                    //                         },
                    //                             vnode.state.budgetItemsHeaders.map(h => m(".table__cell", getDisplayValue(doc.docData[h], BudgetItemDoc.headers[h].type, BudgetItemDoc.headers[h].options, doc.docData))),
                    //                             m(".table__cell table__cell--actions",
                    //                                 isUserAllow(BUDGET_ITEMS, CREATE) && m(".table__action", { onclick: e => handleCopy(e, vnode, doc, BUDGET_ITEMS) }, m(Icon, { icon: "icon-copy" })),
                    //                                 isUserAllow(BUDGET_ITEMS, REMOVE) && m(".table__action", { onclick: e => handleDelete(e, vnode, doc, BUDGET_ITEMS) }, m(Icon, { icon: "icon-delete" })),
                    //                             )
                    //                         )
                    //                     }),
                    //             )
                    // ), //END budget table

                ) //END pageLayout
            ) //END return
        }
    }
}