import { STRING, NUMBER } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH } from "../../../utils/constants/inputAttrs";
import { BUDGET_ITEMS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";


const indexGrowFactor = 10

export class BudgetItemDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: BudgetItemDoc,
        });
        if (this.isNew) {
            this.autoIndex()
        }
    };
    autoIndex() {
        const parentNumber = this.getParent("number")
        if (parentNumber) {
            const last = this.getLast("index", { exclude: { docID: this.docData.docID } })
            if (!last) this.docData.number = `${parentNumber}.${indexGrowFactor}`
            else {
                const { number } = last.docData
                const [parent, ...self] = number.split(".")
                const parentFirstPart = number.toString().replace(/\.\d+$/,"")
                const lastPart = parseFloat(self[self.length-1]) + indexGrowFactor
                this.docData.number = `${parentFirstPart}.${lastPart}`
            }
        }
    }
    static get meta() {
        return {
            id: BUDGET_ITEMS,
            routes: {
                collection: "settings/system/budgetChapters/:chapterID/budgetItems/:budgetItemID",
                doc: "settings/system/budgetChapters/:chapterID/budgetItems",
            },
        }
    }
    static get headers() {
        return {
            title: { label: "שם הסעיף", defaultValue: "--פרק חדש--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            number: { label: "מספר סעיף", defaultValue: "--מספר--", type: STRING },
            description: { label: "תיאור והערות", defaultValue: "--ללא תיאור--", type: STRING },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
        }
    }
}