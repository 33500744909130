export const REG_DOC_ID = "[a-zA-Z0-9_]{4,}"

//PATTERNS
export const REG_EMAIL_PATTERN = "[^@]+@[^\.]+\..+";
export const REG_PHONE_PATTERN = "^[0-9]{2,3}\-[0-9]{7}$"
export const REG_PASSWORD_PATTERN = "[a-z0-9A-Z\$\@]{7,}"
export const REG_COMPANY_NUMBER_PATTERN = "^[0-9]{9}$"
export const REG_IS_DATE = /\d\d\d\d-\d\d-\d\d/

//PATH HELPERS
export const REG_URL_LAST_PART$NQ = /\/\w+(\?.+)*$/
export const REG_URL_QUERY_PART = /\?.+/
export const REG_URL_FIRST_PART = /^\/?\w+\//
export const REG_URL_LAST_PART = /\/\w+$/