import m from "mithril"
import { getDisplayValue } from "../../../data/utils/inputValidation"
import { isListMatchTerm } from "../../../data/utils/search"
import { SWITCH } from "../../../utils/constants/types"
import { preventDefaults } from "../../../utils/js"
import { Icon } from "../../components/icon/Icon"

export const LocalSwitch = node => {

    const searchItem = (e) => {
        preventDefaults(e)
        node.state.searchTerm = e.target.value
    }
    const editLocalValue = (value) => {
        node.state.value = value
        const { doc, header, key/* , parent */ } = node.attrs
        // parent.state[key || header] = value
        const { change } = doc.headers[header]
        if (change) {
            return change(value)
        }
    }

    const removeLocalValue = (value) => {
        node.state.value = ""
        const { doc, header, key/* , parent */ } = node.attrs
        // parent.state[key || header] = null

        const { remove } = doc.headers[header]
        if (remove) {
            return remove(value)
        }
    }

    return {
        listOpen: false,
        searchTerm: "",
        value: "",
        oninit: vnode => {
            const { doc, header, key } = vnode.attrs
            vnode.state.value = doc.docData[header || key]
        },
        view: vnode => {
            const { doc, header, key, class: styleClass } = vnode.attrs
            const field = doc.headers[header]
            const { options, ignoreLabel } = field
            const { value } = vnode.state
            const id = key || header
            return m(".cell__input input input--select", {
                class: styleClass ? styleClass : ""
            },
                m(".input__field cell__field cell__field--select", {
                    onclick: e => vnode.state.listOpen = !vnode.state.listOpen,
                },
                    m(Icon, { icon: `icon-triangle-${vnode.state.listOpen ? "up" : "down"}`, class: "cell__list-icon" }),
                    vnode.state.listOpen && m(".cell__list",
                        m(".cell__search", { onclick: e => preventDefaults(e) },
                            m("input[autofocus][placeholder='חיפוש']", { value: vnode.state.searchTerm, oninput: e => searchItem(e) }),
                            m(Icon, { icon: "icon-search" })
                        ),
                        options.map(opt => {
                            if (!isListMatchTerm(vnode, opt, ["title"])) return
                            return m(".cell__list-item", {
                                onclick: e => editLocalValue(opt.id)
                            }, getDisplayValue(opt.id, SWITCH, options))
                        }),
                    ),
                    getDisplayValue(value, SWITCH, options, doc.docData),
                    value !== null && m(Icon, {
                        icon: `icon-x`, class: "icon--remove", action: e => {
                            e.stopPropagation()
                            removeLocalValue(value)
                        }
                    }),
                ),
                !ignoreLabel && m("label.input__label cell__label", { for: id }, field.label)
            )
        }
    }
}
