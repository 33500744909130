import m from 'mithril';
import { buildHeaders } from '../../../data/CRUD/utils';
import { REMOVE } from '../../../data/store/permissionStore';
import { isUserAllow } from '../../../data/utils/permission';
import { O_FUNCTION } from '../../../utils/constants/objTypes';
import { objType, randomIndex } from '../../../utils/js';

// COMPONENTS
import { Snackbar } from '../../components/snackbar/Snackbar';
import { InputCell } from '../inputCell/InputCell';

//DATA

// FUNCTIONS

export const FormEditDoc = node => {

    const saveChanges = async (e) => {
        const { parent, doc, actions } = node.attrs
        try {
            e.target.disabled = true
            node.state.lock = true
            await Promise.resolve(doc.save())
            node.state.snackbar = { msg: "הנתונים נשמרו בהצלחה" }
            await Promise.resolve(setTimeout(() => {
                if (actions && actions.onSave === "open") { }//SKIP
                else if (actions && actions.onSave && objType(actions.onSave) === O_FUNCTION) actions.onSave(doc)
                else if (parent) parent.state.prompt = false
                m.redraw()
                node.state.lock = false
            }, 1000))
        } catch (err) {
            node.state.snackbar = { msg: "אירעה שגיאה בשמירת הנתונים - תיאור: \n" + err, isError: true }
            node.state.lock = false
        } finally {
            e.target.disabled = false
        }
    }

    const deleteDoc = () => {
        try {
            node.state.lock = true
            node.attrs.doc.remove()
            node.state.snackbar = { msg: "המסמך נמחק" }
            setTimeout(() => {
                node.attrs.parent.state.prompt = false
                m.redraw()
                node.state.lock = false
            }, 1000);
        } catch (err) {
            node.state.snackbar = { msg: "אירעה שגיאה - תיאור: \n" + err, isError: true }
        }
    }

    const isRemoved = () => node.attrs.doc && node.attrs.doc.isRemoved

    return {
        snackbar: false,
        lock: false,
        headers: {},
        oninit: vnode => {
            const { doc, headers } = vnode.attrs
            vnode.state.headers = buildHeaders(doc.headers, headers)
        },
        view: vnode => {
            const { doc, actions = {}, parent, formStyleAreas } = vnode.attrs
            const { changes = true, close = true, remove = true } = actions
            const { lock, headers } = vnode.state
            return m(".formEdit", {
                "data-ref": doc.docData.ref,
                style: formStyleAreas ? `grid-template-areas: ${formStyleAreas} ` : ""
            },
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                isRemoved() ? m(".pending", "רשומה זו נמחקה") : [
                    m("h3.formEdit__caption caption", node.attrs.title || "פרטים"),
                    // vnode.state.loadDoc && m(InlineLoaderDots),
                    (doc && doc.isNew) && m(".formEdit__flag flag flag--new", "לא נשמר עדיין"),
                    doc && Object.entries(headers).map(([header, field], index) => {
                        const value = doc.docData[header]
                        return m(InputCell, {
                            cellStyle: formStyleAreas ? `grid-area:${header};` : "",
                            header, value, field,
                            index: randomIndex(index),
                            id: `${doc.docData.ref}/${header}`,
                            editCell: vnode.state.editCell,
                            doc,
                            parent: vnode
                        })
                    }),
                    (doc && !lock) && [
                        m(".formEdit__actions",
                            (changes && doc.hasChanges() || doc.isNew) && m("button.button", { onclick: e => saveChanges(e) }, `שמור${doc.isNew ? "" : " שינויים"}`),
                            (parent && close) && m("button.button button--gray", { onclick: e => parent.state.prompt = false }, "סגור"),
                            (remove && doc.isNew || isUserAllow(doc.model.meta.id, REMOVE, doc)) && m("button.button button--red", { onclick: e => deleteDoc() }, "מחק"),
                        )
                    ],
                    //TABLE OR SOMETHING
                    vnode.children,
                ]
            )
        }
    }
}