import m from "mithril"

import { DATE, STRING, NUMBER, PERCENT, CURRENCY, VALID_CALC } from "../../../utils/constants/types";
import { CONTRACT_ACCOUNTS, CONFIRMS, PAYMENTS, ACTUALS, CONTRACT_ADDITIONS, CONTRACT_SUBTRACTIONS, CONTRACT_SECTIONS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";
import { db, auth } from "../../../index";
import { dataStore } from "../dataStore";
import { USER_ROLES as ur } from "../../../utils/constants/userRoles";
import { UserDoc } from "../users/userClass";
import { buildInsertDoc } from "../../CRUD/utils";
import { isSuperAdmin, isUserAllow } from "../../utils/permission";
import { UPDATE } from "../permissionStore";
import { DISABLED, MAX, MIN, STEP } from "../../../utils/constants/inputAttrs";
import { internalFields } from "../../dictionary/internalFields";
import { editValue } from "../../utils/inputValidation";


export const OPERATION = "operation"
export const ACCOUNTING = "accounting"
export const confirmAccountFlow = [
    { id: "start", role: [ur.PROJECT_MANAGER, ur.AREA_MANAGER], type: "operation", next: "projectManager", text: "אתחול ועדכון", due: 10 },
    { id: "projectManager", role: [ur.PROJECT_MANAGER, ur.AREA_MANAGER], type: "operation", next: "manager", text: "אישור מ.פרוייקט", due: 10 },
    { id: "manager", role: [ur.COMPANY_ENGINEER, ur.CEO_OPERATIONS], type: "operation", next: "accounts", text: "אישור מהנדס חברה / מנכל", due: 15 },
    // { id: "ceo", role: [ur.CEO_OPERATIONS], type: "operation", next: "accounts", text: 'אישור מנכ"ל', due: 15 },
    { id: "accounts", role: [ur.ACCOUNTS_OPERATIONS, ur.ACCOUNTS_ENTREPRENEURSHIP], type: "accounting", next: "finance", text: 'אישור הנה"ח', due: 18 },
    { id: "finance", role: [ur.BUDGETER, ur.CEO_FINANCE], type: "accounting", next: "SAP", text: 'אישור כספים', due: 20 },
    { id: "SAP", role: [ur.ACCOUNTS_OPERATIONS, ur.ACCOUNTS_ENTREPRENEURSHIP], type: "accounting", next: "billing", text: 'הזנה לסאפ', due: 22 },
    { id: "billing", role: [ur.ACCOUNTS_OPERATIONS, ur.ACCOUNTS_ENTREPRENEURSHIP], type: "accounting", next: false, text: "צ'ק מאושר", due: 23 },
]

export class AccountDoc extends DocModel {
    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: AccountDoc,
        });
        if (this.isNew) {
            //set delayPercentage as parant (contract)
            let taxPercent = 0, delayPercentage = 0, vendor
            const contract = this.getParent()
            if (contract) {
                delayPercentage = contract.docData.delayPercentage
                vendor = DocModel.getDoc(contract.docData.contractorRef)
                if (vendor) taxPercent = vendor.docData.taxPercent
                if (contract.docData.calculationMethod === "oddJobs") {
                    this.edit({
                        confirmFlow: [
                            { id: "start", role: [ur.PROJECT_MANAGER, ur.AREA_MANAGER], type: "operation", next: "projectManager", text: "אתחול ועדכון", due: 10 },
                            { id: "projectManager", role: [ur.PROJECT_MANAGER, ur.AREA_MANAGER, ur.ACCOUNTS_OPERATIONS, ur.ACCOUNTS_ENTREPRENEURSHIP], type: "operation", next: "billing", text: "אישור מ.פרוייקט", due: 10 },
                            { id: "billing", role: [ur.ACCOUNTS_OPERATIONS, ur.ACCOUNTS_ENTREPRENEURSHIP], type: "operation", next: false, text: "צ'ק מאושר", due: 23 },
                        ]
                    })
                }
            }
            this.edit({ delayPercentage })
            this.edit({ title: this.docData.month })
            this.edit({ taxPercent })
        }
        //listen to confirms
        if (!this.isNew) this.listenToChildren()
    };

    async save() {
        if ("totalDelay" in this.docChanges || "delayRelease" in this.docChanges) {
            try {
                const batch = db.batch();
                // [-] set account changes
                super.batchSave(batch);
                // [-] set total contract
                const contract = this.getParent()
                if (!contract) throw "contract not found!"
                batch.update(db.doc(contract.docData.ref), {
                    "currentAccount.totalDelay": parseFloat(this.docData.totalDelay),
                    "currentAccount.delayRelease": parseFloat(this.docData.delayRelease),
                    "totalDelay": contract.getTotalAccounts("totalDelay"),
                    "delayRelease": contract.getTotalAccounts("delayRelease"),
                })
                console.log("TESTME: update contract: current[delay,delayRelease],  total[delay,delayRelease]")
                await batch.commit();
                m.redraw();
            } catch (err) {
                console.error(err)
                this.abortChanges()
            }
        } else {
            await Promise.resolve(super.save())
        }
    }

    async insert(colRef) {
        if (this.docData.month === "") throw "must have month!!!"
        if (this.docData.period === -1) throw "must have period number!!!"
        const otherAccounts = this.getSiblings({ include: { month: this.docData.month } })
        if (otherAccounts.length > 1) throw "month already exists!"
        const confirms = {}
        try {
            await Promise.resolve(super.insert(colRef))
            await this.addConfirms()
            this.docData.confirmFlow.forEach(({ id }) => confirms[id] = this.getConfirmData(false))
            await Promise.resolve(this.addToContractStats({
                currentAccount: {
                    ref: this.docData.ref,
                    month: this.docData.month,
                    period: this.docData.period,
                    stage: this.docData.stage,
                    type: "start",
                    confirms,
                    // totalSections,
                    // totalAdditions,
                    // totalSubtractions,
                    totalDelay: this.docData.totalDelay,
                    delayRelease: this.docData.delayRelease,
                }
            }))
            this.listenToChildren()
        } catch (err) {
            console.error(err);
        } finally {
            m.redraw()
        }
    }
    async syncConfirms() {
        try {
            const confirms = {}
            this.docData.confirmFlow.forEach(({ id, role, due, text, next, type }, index) => {
                let confirm = false
                const exist = this.getConfirm(id)
                if (exist) confirm = exist.docData.confirm
                const confirmData = Object.assign({
                    role, next, id, due, type,
                    title: text,
                    index: index + 1,
                }, this.getConfirmData(confirm))
                confirms[id] = confirmData
            })
            await Promise.resolve(this.addToContractStats({
                currentAccount: {
                    ref: this.docData.ref,
                    month: this.docData.month,
                    period: this.docData.period,
                    stage: this.docData.stage,
                    type: "start",
                    confirms,
                    totalSections: this.docData.totalSections,
                    totalAdditions: this.docData.totalAdditions,
                    totalSubtractions: this.docData.totalSubtractions,
                    totalDelay: this.docData.totalDelay,
                    delayRelease: this.docData.delayRelease,
                }
            }))
        } catch (err) {
            console.error(err);
        } finally {
            m.redraw()
        }
    }

    // async splitAccount(subTotal1, subTotal2) {
    //     if (parseInt(subTotal1) + parseInt(subTotal2) !== parseInt(this.docData.subTotal)) throw "invalid requst subTotal1+subTotal2 should match Account.docData.subTotal"
    //     let newAccount
    //     try {
    //         const batch = db.batch()
    //         editValue(this.docData.subTotal, subTotal1, "subTotal", this)
    //         newAccount = new AccountDoc({
    //             colRef: this.docData.colRef,
    //             title: `${this.docData.month} - 1`,
    //             month: `${this.docData.month} - 1`,
    //             period: parseFloat(this.docData.period) + 0.5,
    //             isActive: true
    //         })
    //         editValue(this.docData.subTotal, subTotal2, "subTotal", newAccount)
    //         this.batchSave(batch)
    //         newAccount.batchInsert(batch)
    //         await batch.commit()
    //         m.redraw()
    //     } catch (err) {
    //         this.abortChanges()
    //         if (newAccount) newAccount.remove()
    //         throw err
    //     }
    // }


    //UTILS

    getContractRef() {
        return this.docData.ref.split("/").slice(0, 4).join("/")
    }

    addToContractStats(data) {
        const contract = this.getParent()
        if (contract) {
            const ref = db.doc(contract.docData.ref)
            return ref.set(data, { merge: true })
        }
    }

    async setCurrentIndexedPercent(fromDate, chapterID = 200010) {
        // const chapterID = this.getParent(indexedChapter)
        const toDate = new Date().toISOString().substring(0, 10);
        const url = `https://api.cbs.gov.il/index/data/calculator/${chapterID}?value=100&date=${fromDate}&toDate=${toDate}`
        // console.log(url);
        const res = await fetch(url)
        const result = await res.json()
        if (result.Message) {
            console.error(result.Message)
        } else {
            if (result.answer) {
                this.docData.indexedPercent = parseFloat(result.answer.to_value)
                m.redraw()
            }
        }
    }

    //BILLINGS
    calcTotalActuals() {
        let total = 0
        this.getChildren(ACTUALS).forEach(act => total += parseFloat(act.docData.currentTotal))
        return total
    }

    //NEW= TESTME: one function to control them all
    pivotTotalActuals() {
        const total = { [CONTRACT_SECTIONS]: 0, [CONTRACT_ADDITIONS]: 0, [CONTRACT_SUBTRACTIONS]: 0 }
        this.getChildren(ACTUALS).forEach(act => {
            const modelID = act.docData.sectionRef.split("/").slice(4, 5).join()
            total[modelID] += parseFloat(act.docData.currentTotal || 0)
        })
        return total
    }
    calcTotalSections() {
        let total = 0
        this.getChildren(ACTUALS)
            .filter(doc => doc.docData.sectionRef.includes(CONTRACT_SECTIONS))
            .forEach(act => total += parseFloat(act.docData.currentTotal))
        return total
    }
    calcTotalAdditions() {
        let total = 0
        this.getChildren(ACTUALS)
            .filter(doc => doc.docData.sectionRef.includes(CONTRACT_ADDITIONS))
            .forEach(act => total += parseFloat(act.docData.currentTotal))
        return total
    }
    calcTotalSubtractions() {
        let total = 0
        this.getChildren(ACTUALS)
            .filter(doc => doc.docData.sectionRef.includes(CONTRACT_SUBTRACTIONS))
            .forEach(act => total += parseFloat(act.docData.currentTotal))
        return total
    }

    getLastBillingDate() {
        const filterAccounts = this.getSiblings()
        if (filterAccounts.length === 0) return
        else {
            const [current, last] = filterAccounts.sort((a, b) => b.docData.timestamp - a.docData.timestamp);
            if (last) return last.docData.billingDate
            else return this.getParent("sWorkDate") //contract start work date
        }
    }


    //PAYMENTS
    async removePayments(docsToRemove = []) {
        if (docsToRemove.length > 0) {
            const batch = db.batch()
            const payments = this.getChildren(PAYMENTS)
            try {
                payments.forEach(doc => {
                    if (docsToRemove.includes(doc.docData.docID)) {
                        if (doc.isNew) {
                            doc.remove()
                        } else {
                            const docRef = db.doc(doc.docData.ref)
                            batch.delete(docRef)
                        }
                        doc.docChanges = {}
                    }
                });
                await batch.commit()
                m.redraw()
            } catch (err) {
                throw err
            }
        }
    }
    getTotalPayments(options = {}) {
        const payments = this.getChildren(PAYMENTS, options);
        let total = 0
        payments.forEach(payment => {
            total += parseFloat(payment.docData.sum)
        })
        return total
    }
    async savePayments() {
        const payments = this.getChildren(PAYMENTS);
        const batch = db.batch();
        try {
            payments.forEach(doc => {
                if (doc.isNew) {
                    const dataToAdd = buildInsertDoc(doc.docData)
                    const docRef = db.doc(doc.docData.ref)
                    batch.set(docRef, dataToAdd, { merge: true })
                    doc.docChanges = {}
                    doc.isNew = false
                } else {
                    const dataToSave = Object.assign({},
                        doc.docChanges,
                        {
                            lastUpdate: new Date().toISOString(),
                            lastUpdatedBy: auth.currentUser.uid
                        })
                    Object.entries(internalFields).forEach(([_k, _v]) => delete dataToSave[_v])
                    const docRef = db.doc(doc.docData.ref)
                    batch.set(docRef, dataToSave, { merge: true })
                    doc.docChanges = {}
                }
            })
            await batch.commit()
        } catch (err) {
            throw err
        }
    }

    //CONFIRMS
    async addConfirms(useDefault = false) {
        try {
            const batch = db.batch()
            const colRef = `${this.docData.ref}/confirms`
            const confirmFlow = (useDefault || !this.docData.confirmFlow) ? confirmAccountFlow : this.docData.confirmFlow
            confirmFlow.forEach((confirmStage, index) => {
                const { id, role, due, text, next, type } = confirmStage
                let confirm = false
                const exist = this.getConfirm(id)
                if (exist) confirm = exist.docData.confirm
                const newConfirm = {
                    role, next, id, due, type,
                    title: text,
                    index: index + 1,
                    confirm: confirm || false,
                }
                const ref = db.collection(colRef).doc(id)
                batch.set(ref, buildInsertDoc(newConfirm), { merge: true });
            })
            const accountRef = db.doc(this.docData.ref)
            batch.set(accountRef, { confirmFlow }, { merge: true })
            await batch.commit()
        } catch (err) {
            console.error(err)
        } finally {
            m.redraw()
        }
    }

    async unConfirm(_ConfirmDoc) {
        if (isSuperAdmin()) {
            try {
                let ConfirmDoc = _ConfirmDoc
                if (!ConfirmDoc) ConfirmDoc = this.getConfirmDoc()
                const stage = ConfirmDoc.docData.id
                const batch = db.batch()
                const unConfirmData = this.getConfirmData(ConfirmDoc.docData.confirm)
                batch.set(db.doc(ConfirmDoc.docData.ref), unConfirmData, { merge: true })
                const confirms = {}
                confirms[`currentAccount.confirms.${stage}`] = unConfirmData
                while (ConfirmDoc.docData.next) {
                    ConfirmDoc = this.getChildByID(CONFIRMS, ConfirmDoc.docData.next)
                    const notConfirmData = this.getConfirmData(false)
                    batch.set(db.doc(ConfirmDoc.docData.ref), notConfirmData, { merge: true })
                    confirms[`currentAccount.confirms.${ConfirmDoc.docData.id}`] = notConfirmData
                }
                //account
                batch.set(db.doc(this.docData.ref), { stage }, { merge: true })
                this.docData.stage = stage
                //contract
                const contractRef = db.doc(this.docData.ref.split("/").slice(0, 4).join("/"))
                for (const [key, val] of Object.entries(confirms)) batch.update(contractRef, { [key]: val })
                batch.update(contractRef, {
                    currentAccount: {
                        stage,
                        type: ConfirmDoc.docData.type
                    }
                })
                const printMsg = ["%cRESET Confirms:", "background-color:#e6b800;color:#f0f0f0;", confirms]
                console.log(...printMsg);
                await batch.commit()
            } catch (err) {
                console.error(err);
            } finally {
                m.redraw()
            }
        }

    }

    getConfirmDoc(_confirmID) {
        let ConfirmDoc
        if (_confirmID) ConfirmDoc = this.getChildByID(CONFIRMS, _confirmID)
        else {
            ConfirmDoc = this.getChildByID(CONFIRMS, "start")
            if (ConfirmDoc.docData.id === "start" && ConfirmDoc.docData.confirm == false) { } //SKIP
            else while (ConfirmDoc.docData.confirm == true && ConfirmDoc.docData.next) ConfirmDoc = this.getChildByID(CONFIRMS, ConfirmDoc.docData.next)
        }
        return ConfirmDoc
    }

    async confirm(_confirmValue, _confirmID) {
        const errors = []
        const ConfirmDoc = this.getConfirmDoc(_confirmID)
        const confirmID = ConfirmDoc.docData.id
        const confirmValue = _confirmValue !== undefined ? _confirmValue : ConfirmDoc.docData.confirm
        if (ConfirmDoc && (confirmValue != true)) return this.unConfirm(ConfirmDoc)
        if (!ConfirmDoc) errors.push(`confirmDoc ${confirmID} not found`)
        if (confirmID !== this.docData.stage && confirmValue == true) errors.push(`invalid confirm ${confirmID} !== ${this.docData.stage}`)
        if (errors.length) {
            ConfirmDoc.abortChanges()
            throw errors.join("\n")
        }

        else {
            const { stage, index } = this.getCurrentStage()

            if (stage.id === "finish") throw "should create new period!!!"
            const { next, id, type } = stage
            // this.edit({ stage: next || "finish" })//TESTME:

            const uid = auth.currentUser.uid
            const currentUser = UserDoc.getCurrentUser()
            const GOLAN = "E9EZpCPNoQhgtsYQCayqBtgCaxp2"
            const SHLOMO = "BRjKVmVajjg62VO3K3aJGxmmccq1"
            const NITZA = "S1q6bPB0KvRi2o62D0G4b43MkPr2"
            const admins = [GOLAN, SHLOMO, NITZA]
            if (!admins.includes(uid) && (!isUserAllow(CONFIRMS, UPDATE, ConfirmDoc))) {
                throw "אין לך הרשאה מתאימה לאשר חשבון זה"
            }
            // debugger
            if(!isUserAllow(CONFIRMS,UPDATE,ConfirmDoc)){
                throw "אין לך הרשאה מתאימה לאשר חשבון זה"
            }
            const colRef = `${this.docData.ref}/confirms`
            const timerMsg = `UPDATE-confirm ⏲ ${colRef} - ${id}`
            try {
                console.time(timerMsg)
                const batch = db.batch()
                const confirmRef = db.doc(`${colRef}/${id}`)
                // console.log("Set ConfirmDoc to true {confirm:true,confirmAt..,confirmBy...} ", `${colRef}/${id}`);
                const confirmData = this.getConfirmData(true)
                batch.set(confirmRef, confirmData, { merge: true })
                const accountRef = db.doc(this.docData.ref)
                this.docData.stage = next || "finish"
                batch.set(accountRef, { stage: next || "finish" }, { merge: true })
                batch.set(db.doc(this.getContractRef()), {
                    currentAccount: {
                        ref: this.docData.ref,
                        month: this.docData.month,
                        period: this.docData.period,
                        stage: this.docData.stage,
                        type
                    },
                }, { merge: true })
                batch.update(db.doc(this.getContractRef()), { [`currentAccount.confirms.${id}`]: confirmData })
                console.log("⚡⚡⚡ after Confirm.true ---> Set Account.currentStage to next =>  ", next, " accountRef: ", this.docData.ref, " \nand contractData stage");
                if (this.docData.stage === "finish") {
                    const project = this.getProject()
                    await Promise.resolve(project.setTotal(batch))
                    // let donePercentage = 0, projectSubTotal = 0
                    // if (project) {
                    //     project.getChildren(CONTRACT_ACCOUNTS, { include: { stage: "finish", } }, { subChild: true }).forEach(doc => {
                    //         projectSubTotal += parseFloat(doc.docData.subTotal)
                    //     })
                    //     donePercentage = projectSubTotal / parseFloat(project.docData.totalSum) * 100
                    //     console.log("⚡⚡⚡ after finish => set project total to=[", projectSubTotal, "] totalDone=[", donePercentage, "]");
                    //     batch.update(db.doc(project.docData.ref), { subTotal: projectSubTotal, donePercentage })
                    // }
                }
                await batch.commit()
                m.redraw()
                console.timeEnd(timerMsg)
            } catch (err) {
                ConfirmDoc.abortChanges();
                this.abortChanges();
                console.error(err);
            }
        }
    }

    getConfirmData(confirmValue) {
        const options = {}
        if (confirmValue == true) {
            options.confirmAt = new Date().toISOString()
            options.confirmBy = auth.currentUser.uid
            options.confirm = true
        } else if (confirmValue == false) {
            options.confirm = false
        } else if (confirmValue == "hold") {
            options.holdAt = new Date().toISOString()
            options.holdBy = auth.currentUser.uid
            options.confirm = "hold"
        } else if (confirmValue == "reject") {
            options.rejectedAt = new Date().toISOString()
            options.rejectedBy = auth.currentUser.uid
            options.confirm = "reject"
        } else {
            console.error("unknown option for cofirm field");
        }
        return options
    }

    getLastConfirm() {
        const current = this.getCurrentConfirm()
        if (current) {
            const { index } = current.docData
            if (index === 1) return
            return this.getChildren(CONFIRMS, { dataOnly: true, include: { index: index - 1 } })[0]
        } else {
            console.error("current confirm not found => at AccountDoc.getLastConfirm()")
        }
    }

    getConfirm(stage) {
        const filter = dataStore[CONFIRMS].data.filter(doc => doc.docData.colRef === `${this.docData.ref}/confirms` && doc.docData.id === stage)
        return filter[0]
    }
    getCurrentConfirm() {
        //THINK: user getConfirmDoc using while next...
        const { stage } = this.docData
        return this.getChildren(CONFIRMS, { dataOnly: true, include: { id: stage } })[0]
    }

    getFlow(type) {
        if (type) {
            return this.docData.confirmFlow.filter(item => item.type === type)
        } else {
            return this.docData.confirmFlow
        }
    }

    getCurrentStage(_stageID) {
        const stageID = _stageID || this.docData.stage
        if (stageID && stageID !== "finish") {
            const index = this.docData.confirmFlow.findIndex(conf => conf.id === stageID);
            if (index == -1) {
                console.error(stageID, this.docData.confirmFlow);
                throw `cannot find ${stageID} in flow settings`
            }
            return {
                stage: this.docData.confirmFlow[index],
                index
            }
        } else {
            // return { stage: "finish" }
            //TESTME: use this pattern:
            return { stage: { id: "finish", text: "הסתיים", type: "accounting" } }
        }
    }

    static get meta() {
        return {
            id: CONTRACT_ACCOUNTS,
            routes: {
                collection: "/projects/:projectID/contracts/:contractID/accounts",
                doc: "/projects/:projectID/contracts/:contractID/accounts/:accountID",
            },
            logic: [
                { type: VALID_CALC, target: "totalDelay", expression: "delayPercentage / number:a:100 *  subTotal", trigger: ["delayPercentage", "subTotal"] },
                // subTotal - totalDelay + delayRelease
                { type: VALID_CALC, target: "totalAccountToPay", expression: "func:calcAccountToPay(subTotal,totalDelay,delayRelease)", trigger: ["delayPercentage", "subTotal", "totalAccountToPay"] },
                { type: VALID_CALC, target: "totalIndexed", expression: "func:calcIndexedValue(totalAccountToPay,indexedPercent)", trigger: ["delayPercentage", "indexedPercent", "subTotal", "totalAccountToPay"] },
                { type: VALID_CALC, target: "totalAfterIndexed", expression: "indexedPercent / number:a:100 * totalAccountToPay", trigger: ["delayPercentage", "indexedPercent", "subTotal", "totalAccountToPay"] },
                { type: VALID_CALC, target: "totalVAT", expression: "vatPercent / number:a:100 *  totalAccountToPay", trigger: ["delayPercentage", "vatPercent", "subTotal", "totalAccountToPay"] },
                { type: VALID_CALC, target: "totalTax", expression: "func:calcTaxValue(totalAccountToPay,indexedPercent,vatPercent,taxPercent)", trigger: ["delayPercentage", "taxPercent", "subTotal", "totalAccountToPay"] },
                { type: VALID_CALC, target: "totalPay", expression: "func:calcBillingTotal(totalAccountToPay,indexedPercent,vatPercent,taxPercent)", trigger: ["delayPercentage", "indexedPercent", "vatPercent", "taxPercent", "subTotal", "totalAccountToPay"] },
            ],
        }
    }
    static get headers() {
        return {
            title: { label: 'כותרת', defaultValue: "--חשבון תקופתי--", type: STRING },
            month: { label: "חודש", defaultValue: "", type: STRING },
            period: { label: "מס תקופה", defaultValue: -1, type: NUMBER },
            confirmFlow: { label: "סבב אישורים", defaultValue: confirmAccountFlow },
            stage: { label: "שלב נוכחי", defaultValue: "start" },
            description: { label: 'תיאור והערות', defaultValue: "", type: STRING },
            billingDate: { label: "תאריך עריכת תשלום", defaultValue: new Date().toISOString(), type: DATE },

            totalSections: { label: "סהכ סעיפים", defaultValue: 0, type: CURRENCY, options: { isFloat: false } },
            totalAdditions: { label: "סהכ תוספות", defaultValue: 0, type: CURRENCY, options: { isFloat: false } },
            totalSubtractions: { label: "סהכ קיזוזים", defaultValue: 0, type: CURRENCY, options: { isFloat: false } },
            subTotal: { label: "סכום מצטבר לתשלום", defaultValue: 0, type: CURRENCY, options: { isFloat: false } },

            delayPercentage: { label: 'אחוז עיכבון', defaultValue: 0, type: PERCENT, props: [{ [MIN]: 0 }, { [MAX]: 5 }, { [STEP]: "any" }], options: { isFloat: true } },
            totalDelay: { label: "סכום עיכבון", defaultValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },

            delayRelease: { label: "סכום שחרור עכבון", defaultValue: 0, type: CURRENCY, options: { isFloat: false }, props: [{ [STEP]: "any" }] },
            totalAccountToPay: { label: "לתשלום", defaultValue: 0, type: CURRENCY, options: { isFloat: false } },


            indexedPercent: { label: "% הצמדה", defaultValue: 100, type: PERCENT, props: [{ [MIN]: 90 }, { [MAX]: 110 }, { [STEP]: 0.01 }] },
            totalIndexed: { label: "סכום הצמדה", defaultValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },
            totalAfterIndexed: { label: "סכום לאחר הצמדה", defaultValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }] },

            vatPercent: { label: "% מעמ", defaultValue: 17, type: PERCENT, props: [{ [MIN]: 0 }, { [MAX]: 20 }] },
            totalVAT: { label: "סכום מעמ", defaultValue: 0, type: CURRENCY },

            taxPercent: { label: "% ניכוי מס", defaultValue: 0, type: PERCENT, props: [{ [MIN]: 0 }, { [MAX]: 50 }] },
            totalTax: { label: "סכום ניכוי מס", defaultValue: 0, type: CURRENCY },

            totalPay: { label: "סכום סופי לתשלום", defaultValue: 0, type: CURRENCY, props: [{ [DISABLED]: true }], options: { isFloat: false } },
        }
    }
}