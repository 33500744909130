import { STRING, NUMBER, OBJECT } from "../../../utils/constants/types";
import { REQUIRED, MINLENGTH } from "../../../utils/constants/inputAttrs";
import { FIELDS_SWITCH_OPTIONS } from "../../dictionary/routeNames";

import { DocModel } from "../docModel";


export class FieldSwitchOptDoc extends DocModel {

    constructor(data, isNew = true) {
        super({
            data,
            isNew,
            model: FieldSwitchOptDoc,
        });
    };
    static get meta() {
        return {
            id: FIELDS_SWITCH_OPTIONS,
            routes: {
                collection: `settings/system/${FIELDS_SWITCH_OPTIONS}`,
                doc: `settings/system/${FIELDS_SWITCH_OPTIONS}/:optionID`,
            },
        }
    }
    static get headers() {
        return {
            title: { label: "כותרת", defaultValue: "--כותרת--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            text: { label: "תווית שדה", defaultValue: "--שדה--", type: STRING, props: [{ [REQUIRED]: true }, { [MINLENGTH]: "4" }] },
            description: { label: "תיאור והערות", defaultValue: "--ללא תיאור--", type: STRING },
            index: { label: "מיון", defaultValue: 1, type: NUMBER },
            next: { label: "אפשרות הבאה", defaultValue: [], type: OBJECT },
            color: { label: "צבע", defaultValue: {}, type: OBJECT },
            in: { label: "מודולים", defaultValue: [], type: OBJECT },
        }
    }
}