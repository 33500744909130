import m from "mithril"
import { CONTRACT_ACCOUNTS } from "../../../../data/dictionary/routeNames"
import { DocModel } from "../../../../data/store/docModel"
import { UPDATE } from "../../../../data/store/permissionStore"
import { DEFAULT_CHANGE_MSG, DEFAULT_USER_NOT_ALLOW_MSG } from "../../../../data/store/settingsStore"

import { editValue, getDisplayValue } from "../../../../data/utils/inputValidation"
import { isUserAllow } from "../../../../data/utils/permission"
import { db } from "../../../../index"
import { CURRENCY, DATE, DIFF, PERCENT, REF } from "../../../../utils/constants/types"
import { diffDays, formatDateDisplay2, isDue, preventDefaults } from "../../../../utils/js"
import { DisplayCell } from "../../../commons/inputCell/DisplayCell"
import { Prompt } from "../../../commons/prompt/Prompt"
import { Icon } from "../../../components/icon/Icon"
import { Snackbar } from "../../../components/snackbar/Snackbar"
import { IndexedCalculator } from "../../indexedCalculator/IndexedCalculator"

export const AccountingForm = node => {

    const getContactorTaxPercent = contractorRef => {
        const vendor = DocModel.getDoc(contractorRef)
        if (vendor) {
            const { taxPercent, taxesEndDate } = vendor.docData
            const isDelay = isDue(new Date(taxesEndDate))
            const isAboutToDue = diffDays(new Date(), new Date(taxesEndDate)) < 45
            const isDiffFromCurrentTax = taxPercent != node.attrs.account.docData.taxPercent
            return { taxPercent, taxesEndDate, isDelay, isAboutToDue, isDiffFromCurrentTax }
        }
        return null
    }

    const openIndexedCalculator = e => {
        preventDefaults(e);
        const fromDate = node.attrs.account.getLastBillingDate()
        const chapterID = node.attrs.account.getParent("indexedFactor")
        const value = 100
        // const value = node.attrs.account.docData.totalAccountToPay
        node.state.prompt = {
            title: "מחשבון הצמדה למדד",
            form: () => {
                return [
                    m(IndexedCalculator, { parent: node, fromDate, value, chapterID }),
                    node.state.tempCalc && m(".", { style: "font-size:1.4rem;border-top: 1px solid gray;" }, getDisplayValue(node.state.tempCalc, NUMBER, { isFloat: true })),
                    (node.state.tempCalc) && m("button.button", {
                        style: "max-width:auto;",
                        onclick: e => {
                            const header = "indexedPercent"
                            const value = node.state.tempCalc;
                            const doc = node.attrs.account
                            if (value && !isNaN(parseFloat(value) && value !== 0)) {
                                editValue('', value, header, doc)
                            }
                            node.state.prompt = false
                        }
                    }, "השתמש בתוצאה כערך")
                ]
            }
        }
    }

    const getIndexedDiff = () => {
        const { subTotal, indexedPercent, totalIndexed } = node.attrs.account.docData
        return subTotal * (indexedPercent / 100) - subTotal
    }

    const matchTaxPercentWizard = (contract, account) => {
        // return node.state.prompt = { msg: "", isError: true }
        const vendor = DocModel.getDoc(contract.docData.contractorRef)
        if (!vendor) return node.state.snackbar = { msg: "קבלן לא נמצא", isError: true }
        const adjustTaxPercent = async (e, taxPercent) => {
            try {
                const batch = db.batch()
                const { valid: validVendor, errorMsgs: errorMsgsVendor } = editValue({}, taxPercent, "taxPercent", vendor)
                const { valid: validAccount, errorMsgs: errorMsgsAccount } = editValue({}, taxPercent, "taxPercent", account)
                if (!validVendor || !validAccount) throw Error([].concat(errorMsgsVendor, errorMsgsAccount).join("\n"))
                vendor.batchSave(batch)
                account.batchSave(batch)
                await batch.commit()
                node.state.snackbar = { msg: DEFAULT_CHANGE_MSG }
                node.state.prompt = false
            } catch (err) {
                console.error(err);
                node.state.snackbar = { msg: err, isError: true }
            } finally {
                m.redraw()
            }
        }
        node.state.prompt = {
            form: () => {
                return m(".",
                    m(".formEdit", {
                        style: `display:grid;
                            grid-template-columns:1fr 1fr;
                            align-items: end;`
                    },
                        m(".caption formEdit__caption", "שינוי הגדרות ניכוי מס"),
                        m(DisplayCell, {
                            doc: vendor,
                            field: Object.assign({}, vendor.headers.taxPercent, { label: "ניכוי מס קבלן" }),
                            header: "taxPercent",
                            value: vendor.docData.taxPercent,
                        }),
                        m("button.button", { onclick: e => adjustTaxPercent(e, vendor.docData.taxPercent), style: "grid-column:2;margin:0" }, "התאם חשבון לפי קבלן"),
                        m(DisplayCell, {
                            doc: contract,
                            field: Object.assign({}, account.headers.taxPercent, { label: "ניכוי מס חשבון נוכחי" }),
                            header: "taxPercent",
                            value: account.docData.taxPercent,
                        }),
                        m("button.button", { onclick: e => adjustTaxPercent(e, account.docData.taxPercent), style: "grid-column:2;margin:0" }, "התאם קבלן לפי חשבון"),
                    )
                )
            }
        }
    }

    const handleSaveAccount = (e) => {
        e.target.disabled = true
        const allow = isUserAllow(CONTRACT_ACCOUNTS, UPDATE)
        if (!allow) return node.state.snackbar = { msg: DEFAULT_USER_NOT_ALLOW_MSG, isError: true }
        node.state.prompt = {
            title: "שמירת שינויים",
            msg: `האם לשמור את החשבון לתשלום`,
            actions: [
                {
                    text: "שמור",
                    action: async (e) => {
                        try {
                            await node.attrs.account.save();
                            node.state.snackbar = { msg: `כל השינויים נשמרו` }
                        } catch (err) {
                            node.state.snackbar = { msg: `אירעה שגיאה בעת שמירת המסמך \n תיאור: ${err}`, isError: true }
                        } finally {
                            m.redraw()
                            e.target.disabled = false
                        }
                        node.state.prompt = false
                    },
                }
            ]
        }
    }

    return {
        prompt: false,
        snackbar: false,
        view: vnode => {
            const { contract, account } = vnode.attrs
            // const total = vnode.attrs.contract.getTotalAccounts("totalPay") - vnode.attrs.contract.getTotalPayments()
            return m(".accounting accounting__calc",
                vnode.state.snackbar && m(Snackbar, { snackbar: vnode.state.snackbar, parent: vnode }),
                m(Prompt, { prompt: vnode.state.prompt, parent: vnode },
                    m(".prompt__title", vnode.state.prompt.title || ""),
                    m(".prompt__msg", vnode.state.prompt.msg || ""),
                    vnode.state.prompt.form && vnode.state.prompt.form(),
                    vnode.state.prompt.actions && m(".prompt__actions",
                        vnode.state.prompt.actions.map(({ action, color, text }) => {
                            return m(`button.button prompt__action ${color ? `button--${color}` : ""}`, { onclick: e => action(e) }, text || "כן")
                        })
                    )
                ),

                [getContactorTaxPercent(contract.docData.contractorRef)]
                    .filter(el => el)
                    .map(vendorData => {
                        let delayText = '', notMatchText = ''
                        if (vendorData.isDelay) delayText = `פג תוקף ניכוי מס - ${formatDateDisplay2(new Date(vendorData.taxesEndDate))} (${getDisplayValue(vendorData.taxPercent, PERCENT)})`
                        else if (vendorData.isAboutToDue) delayText = `תוקף ניכוי מס במקור עומד לפוג בקרוב - ${formatDateDisplay2(new Date(vendorData.taxesEndDate))} (${getDisplayValue(vendorData.taxPercent, PERCENT)})`
                        if (vendorData.isDiffFromCurrentTax) notMatchText = "אי התאמה בין ניכוי מס בחשבון לניכוי מס אצל קבלן - [לחץ כאן לאיפוס]"
                        if (delayText || notMatchText) {
                            return m(".row", { style: "margin:1rem" },
                                delayText && m("a.row info link", { href: `/#!/app/${contract.docData.contractorRef}`, target: "_blank" }, delayText),
                                notMatchText && m(".row danger link", { onclick: e => matchTaxPercentWizard(contract, account) }, notMatchText)
                                // notMatchText && m(".row danger", { style: "margin:1rem" }, notMatchText)
                            )
                        }
                    }),

                m(".row accounting__row", { style: "margin-bottom:1rem" },
                    m(".label", "חשבון נוכחי"),
                    m(".input__field subtotal span--2", getDisplayValue(account.docData.totalAccountToPay, CURRENCY, { isFloat: true }))
                ),
                contract.docData.isIndexed && m(".row accounting__row",
                    m(".label", "% הצמדה"),
                    m(".input",
                        m(Icon, { icon: "icon-dots-vertical", class: "openCalculator", action: e => openIndexedCalculator(e) }),
                        m("input.input__field[type=number][min=100][step=0.01]", {
                            value: account.docData.indexedPercent,
                            oninput: e => { editValue({}, e.target.value, "indexedPercent", account) }
                        }),
                        m("span.input__sign", "%")
                    ),
                    m(".input__field subtotal",
                        getDisplayValue(account.docData.totalIndexed, CURRENCY, { isFloat: true }, account.docData),
                        account.docData.indexedPercent != 100 && m("span.subtotal__diff", {
                            class: account.docData.indexedPercent > 100 ? "subtotal--positive" : "subtotal--negative"
                        }, getDisplayValue(getIndexedDiff(), DIFF))
                    ),
                ),
                m(".row accounting__row",
                    m(".label", "מעמ"),
                    m(".input",
                        m("input.input__field[type=number][min=0][step=1]", {
                            value: account.docData.vatPercent,
                            oninput: e => { editValue({}, e.target.value, "vatPercent", account) }
                            // oninput: e => { account.vatPercent = e.target.value }
                        }),
                        m("span.input__sign", "%")
                    ),
                    m(".input__field subtotal", {
                        class: account.docData.vatPercent > 0 ? "subtotal--positive" : "",
                    }, getDisplayValue(account.docData.totalVAT, CURRENCY, { isFloat: true })),

                ),
                m(".row accounting__row",
                    m(".label", "ניכוי מס במקור"),
                    m(".input",
                        m("input.input__field[type=number][min=0][step=1][max=50][required]", {
                            value: account.docData.taxPercent,
                            oninput: e => {
                                if (e.target.value !== "") editValue({}, e.target.value, "taxPercent", account)
                            }
                            // oninput: e => { account.taxPercent = e.target.value }
                        }),
                        m("span.input__sign", "%")
                    ),
                    m(".input__field subtotal", {
                        class: account.docData.taxPercent > 0 ? "subtotal--negative" : "",
                    }
                        , getDisplayValue(account.docData.totalTax, CURRENCY, { isFloat: true })),
                ),

                m(".row accounting__row", { style: "margin-top:1rem;font-weight:500" },
                    m(".label", "סהכ חשבון נוכחי"),
                    m(".input__field subtotal span--2  bold center", getDisplayValue(account.docData.totalPay, CURRENCY, { isFloat: true }))
                ),
                account.hasChanges() && m(`button.button ${account.isNew ? "button--red" : ""}`, { onclick: e => handleSaveAccount(e) }, "שמור")
            )
        }
    }
}