import { auth } from "../../index"
import { HIDDEN } from "../../utils/constants/inputAttrs"
import { O_ARRAY, O_FUNCTION } from "../../utils/constants/objTypes"
import { objType } from "../../utils/js"
import { PAYMENTS, CONTRACT_SECTIONS, USERS } from "../dictionary/routeNames"
import { dataStore } from "../store/dataStore"
import { permissionTree } from "../store/permissionStore"

export function isUserAllow(modelID, action, data) {
    if (isSuperAdmin()) return true
    // console.log("[1] not super admin ❕");

    const currentUser = dataStore[USERS].current
    // console.log("[2] has currentUser? 👍", currentUser);
    if (!currentUser) return false

    if (data && data.isNew) return true

    let hasPermission = true
    const { role } = currentUser.docData
    if (permissionTree[modelID] && permissionTree[modelID][action]) hasPermission = testPermission(permissionTree[modelID][action], role, data)
    return hasPermission
}

export const isFieldEditable = (field, data) => {
    if (isSuperAdmin()) return true

    if (!field) console.log("%cTHINK:", "background-color:#c62168;color:#f0f0f0;");

    const currentUser = dataStore[USERS].current
    if (!currentUser) return false

    let hasPermission = true
    const { role } = currentUser.docData
    if (field && field.permission) hasPermission = testPermission(field.permission, role, data)
    return hasPermission
}

export const isFieldVisible = (field, data, header) => {
    // if (isSuperAdmin()) return true

    if (!field) console.log("%cTHINK:", "background-color:#c62168;color:#f0f0f0;");

    const currentUser = dataStore[USERS].current
    if (!currentUser) return false

    let isVisible = true
    const { role } = currentUser.docData
    if (field && field.visibleLogic) isVisible = testPermission(field.visibleLogic, role, data)
    if (data && data.model.meta.visibleLogic) isVisible = testVisibilty(data, header, data.model.meta.visibleLogic)
    return isVisible
}
const testVisibilty = (doc, header, rules) => {
    if (doc && header) {
        for (const rule of rules) { // use AND logic to hide
            const { is, not, fields } = rule
            const logics = is || not
            if (!logics || !fields) break;
            if (logics) {
                const isTested = logics.every(logic => { // all logics shuld return true -> to be tested rule
                    const [logicKey, _logicValue] = Object.entries(logic)[0]
                    const [_doc, _store, _param] = logicKey.split(".")
                    const value = doc[_store][_param]
                    return (is ? (value === _logicValue) : value !== _logicValue)
                })
                if (isTested) {
                    let includes = fields.includes(header)
                    if (includes) {
                        // console.log(`%cTODO: hide field =>%c ${header}`, "background-color:#e6b800;color:#f0f0f0;", "color:red;")
                        return false
                    }
                }
            }
        }
    }
    return true
}

export function matchPermissionListeners(permissionRules, role) {
    const filters = []
    permissionRules.forEach(({ isRole, notRole, rules }) => {
        const roles = isRole || notRole
        let isTestedRole
        if (isRole) isTestedRole = roles.includes(role)
        else if (notRole) isTestedRole = roles.includes(role) === false;

        if (!isTestedRole) return
        rules.forEach(rule => {
            //TODO: parse rule logicValue if any...
            filters.push(rule)
        })
    })
    return filters
}

export function testPermission(permissionObj, role, data) {
    let hasPermission = true
    const { allowRoles, allowRoles_where_or, allowRoles_where_and } = permissionObj
    if (allowRoles) hasPermission = allowRoles.includes(role)
    else hasPermission = false
    // console.log(`[3] allowRoles? ${hasPermission ? "👍" : "❕"}`, hasPermission);
    if (allowRoles_where_or) hasPermission = hasPermission || allowRoles_where_or.some(statement => testLogicData(statement, role, data))
    // console.log(`[4] allowRoles_where_or? ${hasPermission ? "👍" : "❕"}`, hasPermission);
    if (allowRoles_where_and) hasPermission = hasPermission || allowRoles_where_and.every(statement => testLogicData(statement, role, data))
    // console.log(`[5] allowRoles_where_and? ${hasPermission ? "👍" : "❕"}`, hasPermission);
    return hasPermission
}


export function testLogicData(statement, role, data) {

    // console.log(data instanceof Object && data.prototype instanceof DocModel);
    const { isRole, notRole, is, not, isFields, notFields } = statement
    const roles = isRole || notRole
    let isTestedRole
    if (isRole) isTestedRole = roles.includes(role)
    else if (notRole) isTestedRole = roles.includes(role) === false;

    if (!isTestedRole) return false

    if (!data) return true
    const logics = is || not// [{},...]
    const fields = isFields || notFields
    if (logics) {
        return logics.some(logic => {
            let doc, value, logicValue
            const [logicKey, _logicValue] = Object.entries(logic)[0]
            const [_doc, _store, _param] = logicKey.split(".")

            //test "value" ...
            if (_doc === "parent") doc = data.getParent()
            else if (_doc === "project") doc = getProjectByRef(data.docData.ref)
            else if (_doc === "contract") doc = data.getContract()
            else doc = data
            value = (_store === "docData") ? doc.docData[_param] : doc[_store]

            //... VS "logicValue" :
            if (_logicValue === "currentUser") {
                if (value && !value.startsWith("users/")) value = `users/${value}`
                logicValue = `users/${auth.currentUser.uid}`
            }
            else if (_logicValue === "currentRole") logicValue = role
            else logicValue = _logicValue
            //TODO: (objType(_logicValue) === O_FUNCTION) logicValue = _logicValue

            // if (_param === "calculationMethod" && is && _doc === "parent") {
            //     console.assert(false) 
            // }

            //calc statement
            if (is) {
                if (objType(value) === O_ARRAY) return value.includes(logicValue)
                return value === logicValue
            } else if (not) {
                if (objType(value) === O_ARRAY) return !value.includes(logicValue)
                return value !== logicValue
            }
            else throw "logic not found"

        })
    } else if (fields) {
        const fieldsMatch = fields.some(field => {
            const { key, currentValue, toValue } = field
            console.log("TODO: test fields changes based on value", key, currentValue, toValue, data.docData[key]);
            if (data.docChanges && key in data.docChanges) return true
        })
        if (isFields) return fieldsMatch
        else if (notFields) return !fieldsMatch
    } else {
        throw `logicType not found`
    }
}

export function isSuperAdmin() {
    const shlomo = "BRjKVmVajjg62VO3K3aJGxmmccq1"
    return auth.currentUser !== null && auth.currentUser.uid == shlomo
}

function getProjectByRef(ref, param) {
    const [p, pID] = ref.split("/")
    const project = dataStore.projects.data.find(doc => doc.docData.docID === pID)
    if (project) {
        if (param) return project.docData[param]
        return project
    } else {
        throw `project ${pID} not found!!!`
    }
}