import m from "mithril";
import { isOverflowY } from "../../../utils/js";
import { IconButton } from "../../components/icon/IconButton";


export const Prompt = node => {
    return {
        view: vnode => {
            const { isVisible = true } = vnode.state
            return vnode.attrs.prompt ?
                m(".prompt print", {
                    onclick: e => vnode.attrs.parent.state.prompt = false
                },
                    m(`.prompt__box ${isVisible ? "" : "hide"}`, {
                        oncreate: el => {
                            if (isOverflowY(el.dom)) {
                                el.dom.style.cssText = "overflow-y:scroll";
                            } else {
                                el.dom.style.cssText = "overflow-y:hidden";
                            }
                        },
                        onclick: e => e.stopPropagation(),
                        class: vnode.attrs.prompt.class || ""
                    }, [
                        m(".topActions",
                            m(IconButton, { /* tooltip: "סגור", */ icon: "icon-x", class: "close", action: e => vnode.attrs.parent.state.prompt = false }),
                            m(IconButton, { /* tooltip: "הדפסה", */ icon: "icon-print", class: "no-print", action: e => window.print() }),
                            m(IconButton, { /* tooltip: "הצג/הסתר", */ icon: isVisible ? "icon-eye-off" : "icon-eye", class: "no-print", action: e => vnode.state.isVisible = !isVisible }),
                            // m(IconButton, { tooltip: "הגדלה", icon: "icon-enlarge", class: "no-print", action: e => null }),
                        ),
                        vnode.children
                    ])
                ) :
                null
        }
    }
}

// style: (vnode.state.elem && isOverflowY(vnode.state.elem)) ? "overflow-y:scroll" : "overflow-y:hidden",
// oncreate: el => vnode.state.elem = el,